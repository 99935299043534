import React from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography,
} from 'sunwise-ui';

import { getTranslatedAddonLabel } from 'common/utils/helpers';

const UsageSubTableV4 = ({
    subscription,
    activeUsers,
    addons,
    isOpen,
    toggleOpen,
}) => {
    const { t } = useTranslation();
    const { credits, product_type } = subscription || {};

    const includedCredits = get(credits, 'included_credits_period', 0);
    const usedCredits = get(credits, 'used_credits_period', 0);
    const availableCreditsPeriod = get(credits, 'available_credits_period', 0);
    const availablePrepaidCredits = get(
        credits,
        'available_prepaid_credits',
        0,
    );
    const prepaidCredits = get(credits, 'prepaid_credits', 0);
    const usedPrepaidCredits = get(credits, 'used_prepaid_credits', 0);
    const additionalCredits = get(credits, 'pending_payment_credits', 0);

    const maxUsers = get(credits, 'maximum_users', 0);
    const availableProjects = get(credits, 'available_projects', 0);
    const includedProjects = get(credits, 'maximum_current', 0);
    const additionalProjects = get(credits, 'available_purchased', 0);

    const totalAvailable = availableCreditsPeriod + availablePrepaidCredits;
    const totalUsedProjects =
        includedProjects + additionalProjects - availableProjects;

    const isSales = product_type === 'sales';

    return (
        <Table sx={{ mt: 1 }}>
            <TableBody
                sx={{
                    '& .MuiTableCell-root': {
                        paddingTop: 1,
                        paddingBottom: 1,
                    },
                }}
            >
                {isSales && (
                    <TableRow>
                        <TableCell>
                            <Typography variant="body2">
                                {t('Active users')}: {activeUsers}/{maxUsers}
                            </Typography>
                        </TableCell>
                    </TableRow>
                )}
                <TableRow>
                    <TableCell>
                        <Typography variant="body2">
                            {isSales
                                ? t('Available projects')
                                : t('Available reports')}
                            : {isSales ? availableProjects : totalAvailable}
                        </Typography>
                    </TableCell>
                    <TableCell align="right">
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={toggleOpen}
                        >
                            {isOpen ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={2}
                    >
                        <Collapse in={isOpen} timeout="auto" unmountOnExit>
                            <Table size="small">
                                <TableBody
                                    sx={{
                                        '& .MuiTableCell-root': {
                                            paddingX: 0,
                                            paddingTop: 1,
                                            paddingBottom: 1,
                                        },
                                    }}
                                >
                                    {isSales ? (
                                        <>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="body2">
                                                        {t('Projects included')}
                                                        : {includedProjects}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            {additionalProjects > 0 && (
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="body2">
                                                            {t(
                                                                'Additional projects',
                                                            )}
                                                            :{' '}
                                                            {additionalProjects}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="body2">
                                                        {t('Used Projects')}:{' '}
                                                        {totalUsedProjects}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    ) : (
                                        <>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="body2">
                                                        {t('Included reports')}:{' '}
                                                        {includedCredits}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            {prepaidCredits > 0 && (
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="body2">
                                                            {t(
                                                                'Additional reports purchased',
                                                            )}
                                                            : {prepaidCredits}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="body2">
                                                        {t('Reports used')}:{' '}
                                                        {usedCredits +
                                                            usedPrepaidCredits}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            {additionalCredits > 0 && (
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="body2">
                                                            {t(
                                                                'Reports by use',
                                                            )}
                                                            :{' '}
                                                            {additionalCredits}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </>
                                    )}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
                {isSales && !isEmpty(addons) && (
                    <TableRow>
                        <TableCell colSpan={2}>
                            <Box display="flex" flexDirection="column" my={3}>
                                <Typography fontWeight="bold" variant="body2">
                                    {t('Add-Ons')}
                                </Typography>
                                <Box ml={3} mt={1}>
                                    {addons.map((addon) => (
                                        <Typography variant="body2" key={addon}>
                                            {getTranslatedAddonLabel(addon)}
                                        </Typography>
                                    ))}
                                </Box>
                            </Box>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

UsageSubTableV4.propTypes = {
    subscription: PropTypes.object,
    activeUsers: PropTypes.number,
    addons: PropTypes.array,
    isOpen: PropTypes.bool,
    toggleOpen: PropTypes.func,
};

export default UsageSubTableV4;
