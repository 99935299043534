import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert, Grid } from 'sunwise-ui';

const MapWarning = ({ visible }) => {
    const { t } = useTranslation();
    if (!visible) return null;
    return (
        <Grid container>
            <Grid size={18}>
                <Alert severity="warning">
                    {t('Enter or update the location coordinates of the map')}
                </Alert>
            </Grid>
        </Grid>
    );
};

MapWarning.propTypes = {
    visible: PropTypes.bool,
};

export default MapWarning;
