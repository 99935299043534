import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Grid } from 'sunwise-ui';

import ChartJs from 'common/components/ChartJs';
import PlaceholderProccessView from 'common/modules/loadingView/PlaceholderProccessView';

import { getDonutChartJsConfig } from '../helpers';

const DonutChart = ({
    currencyIso,
    currencyLocale,
    isFetching,
    priceByWatt,
    systemSize,
    totalAccessories,
    totalAdditionals,
    totalEnergyStorage,
    totalInverters,
    totalPanels,
    totalStructure,
    totalWorkforce,
    typeChange,
}) => {
    const theme = useTheme();

    if (isFetching)
        return (
            <PlaceholderProccessView
                wrapperHeight="250px"
                text="Preparing graph"
            />
        );

    const series = [
        totalPanels,
        totalInverters,
        totalStructure,
        totalWorkforce,
        totalAccessories,
        totalAdditionals,
        totalEnergyStorage,
    ];

    const { data, options, plugins } = getDonutChartJsConfig({
        currencyIso,
        currencyLocale,
        priceByWatt,
        series,
        systemSize,
        theme,
        typeChange,
    });

    return (
        <Grid container justifyContent="center">
            <Grid size={{ lg: 7, md: 8, xs: 18 }}>
                <ChartJs
                    data={data}
                    height="350px"
                    options={options}
                    plugins={plugins}
                    type="doughnut"
                />
            </Grid>
        </Grid>
    );
};

DonutChart.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    isFetching: PropTypes.bool,
    priceByWatt: PropTypes.number,
    systemSize: PropTypes.number,
    totalAccessories: PropTypes.number,
    totalAdditionals: PropTypes.number,
    totalEnergyStorage: PropTypes.number,
    totalInverters: PropTypes.number,
    totalPanels: PropTypes.number,
    totalStructure: PropTypes.number,
    totalWorkforce: PropTypes.number,
    typeChange: PropTypes.number,
};

export default DonutChart;
