import React, { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { Chip } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ZapForm } from 'common/modules/zap/components/';

import * as actions from '../actions';
import * as selectors from '../selectors';

import FormAccordion from './FormAccordion';

const ZapSection = ({
    addRpu,
    cfeUser,
    defaultCfeUser,
    disabled,
    isSaving,
    onLink,
    rpuData,
    serviceNumber,
    zapRpuId,
}) => {
    const [editMode, setEditMode] = useState(false);
    const { t } = useTranslation();

    return (
        <FormAccordion defaultExpanded hasFormErrors={false} title="LISA">
            {zapRpuId && !editMode ? (
                <Chip
                    color="success"
                    label={cfeUser}
                    deleteIcon={<EditIcon fontSize="small" />}
                    onDelete={() => setEditMode(true)}
                    sx={{ '& .MuiChip-deleteIcon': { fontSize: '16px' } }}
                />
            ) : (
                <ZapForm
                    addRpu={(values) => addRpu(values, onLink)}
                    addRpuText={t('Link integration')}
                    disabled={disabled}
                    hiddenFields={['rpu_id']}
                    initialValues={
                        rpuData || {
                            cfe_user: cfeUser || defaultCfeUser || '',
                            service_number: serviceNumber || '',
                        }
                    }
                    isLoading={isSaving}
                    variant="standard"
                    {...(zapRpuId && { onCancel: () => setEditMode(false) })}
                />
            )}
        </FormAccordion>
    );
};

const mapStateToProps = createStructuredSelector({
    defaultCfeUser: selectors.getCustomerConsumptionName,
    isSaving: selectors.getZapRpuIsLoading,
    rpuData: selectors.getZapRpuData,
});

const mapDispatchToProps = (dispatch) => ({
    addRpu: (data, successCallback) =>
        dispatch(actions.addZapRpu(data, successCallback)),
});

ZapSection.propTypes = {
    addRpu: PropTypes.func,
    cfeUser: PropTypes.string,
    defaultCfeUser: PropTypes.string,
    disabled: PropTypes.bool,
    isSaving: PropTypes.bool,
    onLink: PropTypes.func,
    rpuData: PropTypes.object,
    serviceNumber: PropTypes.string,
    zapRpuId: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(ZapSection);
