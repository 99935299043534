import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, UiDialog as Dialog } from 'sunwise-ui';

import IcoFinancial from 'resources/icoFinancial.svg?react';

import * as actions from '../actions';
import * as selectors from '../selectors';

import Financials from './Financials';
import FooterButtons from './FooterButtons';
import TermsContent from './TermsContent';

const StyledIcon = styled(IcoFinancial)`
    height: 26px;
    width: 26px;
    path {
        fill: ${({ theme }) => theme.palette.primary.main};
    }
`;

const SimulateModal = ({
    closeModal,
    fetchFinancials,
    fetchRequirements,
    financials,
    isFetchingFinancials,
    isFetchingRequirements,
    isSimulatingCredit,
    isOpenModal,
    proposalId,
    requirements,
    simulateCredit,
}) => {
    const { t } = useTranslation();

    const [modalStep, setModalStep] = useState(1);
    const [selectedFinancier, setSelectedFinancier] = useState(null);

    useEffect(() => {
        if (modalStep === 2) fetchRequirements(selectedFinancier.id);
    }, [modalStep]);

    const hasAlliance = get(selectedFinancier, 'has_alliance', false);

    const handleSelectFinancier = (financier) =>
        setSelectedFinancier(financier);
    const handleSimulateCredit = () =>
        simulateCredit({
            allianceId: selectedFinancier?.id,
            proposalId,
        });
    const handleSave = () => {
        if (hasAlliance) handleSimulateCredit();
        else setModalStep(2);
    };
    const handleCancel = () => {
        if (modalStep === 1) closeModal();
        else setModalStep(1);
    };

    const handleOnEnterModal = () => fetchFinancials();

    return (
        <Dialog
            onClose={() => closeModal()}
            onEnter={handleOnEnterModal}
            onExited={() => setModalStep(1)}
            open={isOpenModal}
            title={
                <Box alignItems="center" display="flex" gap={1}>
                    <StyledIcon />
                    {t('Financing')}
                </Box>
            }
        >
            {modalStep === 1 && (
                <Financials
                    financials={financials}
                    handleOnClick={handleSelectFinancier}
                    isFetching={isFetchingFinancials}
                    selectedFinancier={selectedFinancier}
                />
            )}

            {modalStep === 2 && (
                <TermsContent
                    isFetching={isFetchingRequirements}
                    requirements={requirements}
                />
            )}
            <FooterButtons
                handleClickAcceptTerms={handleSimulateCredit}
                handleClickCancel={handleCancel}
                handleClickSave={handleSave}
                isSaving={isSimulatingCredit}
                requireAccepTerms={modalStep === 2}
                selectedFinancierId={selectedFinancier?.id}
            />
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    financials: selectors.getFinancialsData,
    isFetchingFinancials: selectors.getIsFetchingFinancials,
    isFetchingRequirements: selectors.getRequirementsIsFetching,
    isOpenModal: selectors.getIsOpenSimulateModal,
    isSimulatingCredit: selectors.getIsSimulatingCredit,
    requirements: selectors.getRequirementsData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchFinancials: () => dispatch(actions.fetchFinancials()),
    fetchRequirements: (id) => dispatch(actions.fetchRequirements(id)),
    simulateCredit: (data) => dispatch(actions.simulateCredit(data)),
});

SimulateModal.propTypes = {
    closeModal: PropTypes.func,
    fetchFinancials: PropTypes.func,
    fetchRequirements: PropTypes.func,
    financials: PropTypes.array,
    isFetchingFinancials: PropTypes.bool,
    isFetchingRequirements: PropTypes.bool,
    isSimulatingCredit: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    proposalId: PropTypes.string,
    requirements: PropTypes.object,
    simulateCredit: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(SimulateModal);
