import React, { useEffect, useRef, useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { push } from 'connected-react-router';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import { CommandBar, PaymentDueBanner } from 'common/components';
import { useOrientation } from 'common/hooks';
import alerts from 'common/modules/alerts';
import * as multiBranchesActions from 'common/modules/multiBranches/actions';
import * as multiBranchesSelectors from 'common/modules/multiBranches/selectors';
import releaseNotification from 'common/modules/releaseNotification';
import { getOppositeCurrency } from 'common/utils/helpers';
import {
    getIsMultibranchesAccount,
    getMainBranchOffice,
} from 'common/utils/helpers/session';

import * as companyGeneralActions from '../../../modules/companyGeneral/actions';
import * as companyGeneralSelectors from '../../../modules/companyGeneral/selectors';
import * as multiCurrencyActions from '../../../modules/multiCurrency/actions';
import * as multiCurrencySelectors from '../../../modules/multiCurrency/selectors';
import * as notificationsActions from '../../../modules/notifications/actions';
import * as notificationsSelectors from '../../../modules/notifications/selectors';
import * as profileActions from '../../../modules/profile/actions';
import * as profileSelectors from '../../../modules/profile/selectors';
import * as profileConfigurationActions from '../../../modules/profileConfiguration/actions';

import Footer from './Footer';
import SideBar from './SideBar';
import TopBar from './TopBar';

const DefaultLayout = ({
    branchSelected,
    children,
    currencies,
    fetchBranches,
    fetchCompanyData,
    fetchCurrencies,
    fetchNavNotifications,
    fetchUserSettings,
    isFetchingBranches,
    isFetchingCompany,
    isFetchingCurrencies,
    isFetchingNavNotifications,
    isFetchingUserSettings,
    match,
    multiBranchMode,
    notificationsQuantity,
    profileValues,
    redirect,
    saveConfig,
    selectBranch,
    selectedTheme,
    setTheme,
    userPreferences,
}) => {
    const [drawerSize, setDrawerSize] = useState('normal');
    const [mobileOpen, setMobileOpen] = useState(false);
    const isMultiBranches = getIsMultibranchesAccount();
    const mainRef = useRef(null);
    const searchFieldRef = useRef(null);
    const { isPortrait } = useOrientation();

    useEffect(() => {
        fetchUserSettings();
        fetchNavNotifications();
        fetchCurrencies();
        fetchCompanyData();
        if (isMultiBranches) fetchBranches();
        else if (isNull(branchSelected)) selectBranch(getMainBranchOffice());
        const selectedDrawerSize = localStorage.getItem('selectedDrawerSize');
        if (selectedDrawerSize) setDrawerSize(selectedDrawerSize);
    }, []);

    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

    const toggleDrawerSize = () => {
        const newDrawerSize = drawerSize === 'normal' ? 'small' : 'normal';
        setDrawerSize(newDrawerSize);
        saveConfig({ expanded_sidebar: drawerSize === 'small' });
        localStorage.setItem('selectedDrawerSize', newDrawerSize);
    };

    const oppositeCurrency = getOppositeCurrency(currencies);
    const isFetching =
        isFetchingBranches ||
        isFetchingCompany ||
        isFetchingCurrencies ||
        isFetchingNavNotifications ||
        isFetchingUserSettings;

    const childrenWithProps = React.Children.map(children, (child) => {
        // Verifica si el child es un elemento React válido
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { drawerSize });
        }
        return child;
    });

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <SideBar
                drawerSize={drawerSize}
                handleDrawerToggle={handleDrawerToggle}
                isFetchingUserSettings={isFetchingUserSettings}
                match={match}
                mobileOpen={mobileOpen}
                multiBranchMode={multiBranchMode}
                oppositeCurrency={oppositeCurrency}
                profileValues={profileValues}
                redirect={redirect}
                selectedTheme={selectedTheme}
                setTheme={setTheme}
                toggleDrawerSize={toggleDrawerSize}
                userPreferences={userPreferences}
            />

            <Box
                component="main"
                ref={mainRef}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    ml: { md: '86px' },
                    pt: { xs: isPortrait ? '52px' : 0, md: 0 },
                    width: { md: 'calc(100% - 86px)' },
                    ...(drawerSize === 'small' && {
                        ml: { md: '50px' },
                        width: { md: 'calc(100% - 50px)' },
                    }),
                }}
            >
                <CommandBar searchFieldRef={searchFieldRef}>
                    <TopBar
                        handleDrawerToggle={handleDrawerToggle}
                        multiBranchMode={multiBranchMode}
                        notificationsQuantity={notificationsQuantity}
                        oppositeCurrency={oppositeCurrency}
                        redirect={redirect}
                        searchFieldRef={searchFieldRef}
                    />
                </CommandBar>

                <PaymentDueBanner />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                    }}
                >
                    {isFetching && (
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                    )}
                    {isFetchingUserSettings ? null : childrenWithProps}
                </Box>

                <Footer />
            </Box>
            <alerts.Container />
            <releaseNotification.Container />
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    branchSelected: multiBranchesSelectors.getBranchSelected,
    currencies: multiCurrencySelectors.getDataFetchCurrencies,
    isFetchingBranches: multiBranchesSelectors.getIsFetchingBranches,
    isFetchingCompany: companyGeneralSelectors.getIsFetching,
    isFetchingCurrencies: multiCurrencySelectors.getIsFetchingCurrencies,
    isFetchingNavNotifications:
        notificationsSelectors.getIsFetchingNavNotifications,
    isFetchingUserSettings: profileSelectors.getIsFetching,
    notificationsQuantity: notificationsSelectors.getNavNotificationsNoReaded,
    profileValues: profileSelectors.getCurrentValues,
    selectedTheme: profileSelectors.getTheme,
    userPreferences: profileSelectors.getPreferences,
});

const mapDispatchToProps = (dispatch) => ({
    fetchBranches: () => dispatch(multiBranchesActions.fetchBranches()),
    fetchCurrencies: () => dispatch(multiCurrencyActions.filterItems()),
    fetchCompanyData: () => dispatch(companyGeneralActions.fetchCompanyData()),
    fetchNavNotifications: () =>
        dispatch(notificationsActions.fetchNavNotifications()),
    fetchUserSettings: () => dispatch(profileActions.fetchProfileData()),
    redirect: (link) => dispatch(push(link)),
    saveConfig: (config) =>
        dispatch(profileConfigurationActions.saveConfig(config)),
    selectBranch: (branchId) =>
        dispatch(multiBranchesActions.selectBranch(branchId)),
    setTheme: (value) => dispatch(profileActions.setTheme(value)),
});

DefaultLayout.propTypes = {
    branchSelected: PropTypes.string,
    children: PropTypes.object,
    currencies: PropTypes.array,
    fetchBranches: PropTypes.func,
    fetchCompanyData: PropTypes.func,
    fetchCurrencies: PropTypes.func,
    fetchNavNotifications: PropTypes.func,
    fetchUserSettings: PropTypes.func,
    isFetchingBranches: PropTypes.bool,
    isFetchingCompany: PropTypes.bool,
    isFetchingCurrencies: PropTypes.bool,
    isFetchingNavNotifications: PropTypes.bool,
    isFetchingUserSettings: PropTypes.bool,
    match: PropTypes.object,
    multiBranchMode: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    notificationsQuantity: PropTypes.number,
    profileValues: PropTypes.object,
    redirect: PropTypes.func,
    saveConfig: PropTypes.func,
    selectBranch: PropTypes.func,
    selectedTheme: PropTypes.string,
    setTheme: PropTypes.func,
    userPreferences: PropTypes.object,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(DefaultLayout);
