import { addRpu } from 'common/api/zap/rpus';

import authenticate from './authenticate';
import connectSocket from './connectSocket';

const DOCUMENT_TYPES = {
    ALL: -1,
    PDF: 0,
    XML: 1,
};

export default ({
        cfeUser,
        connectSocket: connect = true,
        credentialId,
        documentType = DOCUMENT_TYPES.ALL,
        fetchPeriods = true,
        lastPayment,
        maxPeriods = 12,
        serviceNumber,
    }) =>
    (dispatch) =>
        authenticate((token) => {
            if (connect) dispatch(connectSocket(token));

            return addRpu({
                rpu_create: {
                    cfe_user: cfeUser,
                    credential_id: credentialId,
                    last_payment: lastPayment,
                    service_number: serviceNumber,
                },
                download_directives: {
                    document_type: documentType,
                    max_periods: maxPeriods,
                },
                fetch_periods: fetchPeriods,
            }).then((response) => response?.data?.rpu);
        });
