import React from 'react';

import BarChartIcon from '@mui/icons-material/BarChart';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import WebIcon from '@mui/icons-material/Web';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import { CheckAccess } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import { useBreakpoint } from 'common/hooks';
import {
    getSubscriptionStatus,
    getSubscriptionsStatus,
} from 'common/utils/helpers';

import ZapierIcon from 'resources/zapier_icon.svg?react';

import * as companyGeneralSelectors from '../../../modules/companyGeneral/selectors';
import * as profileSelectors from '../../../modules/profile/selectors';

import { handleOnMouseHover } from './helpers';
import StyledListItemButton from './StyledListItemButton';
import StyledStyledListItemText from './StyledStyledListItemText';
import UpgradeIcon from './UpgradeIcon';

const IntegrationsOptions = ({
    companyIsFromMexico,
    companyIsFromSpain,
    handleClose,
    handleDrawerToggle,
    match,
    mobileOpen,
    redirect,
    subscription,
    subscriptions,
    subscriptionVersion,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const { allHasPaymentDue, isMultipleSubscription } =
        getSubscriptionsStatus(subscriptions);
    const { isActive, isPaymentDue } = getSubscriptionStatus(subscription);

    const isDisabledV4 =
        subscriptionVersion === 'v4' && isActive && isPaymentDue;
    const isDisabledV5 =
        (subscriptionVersion === 'v5' &&
            !isMultipleSubscription &&
            isActive &&
            isPaymentDue) ||
        (subscriptionVersion === 'v5' &&
            isMultipleSubscription &&
            allHasPaymentDue);

    const showSupplierIntegration = companyIsFromMexico || companyIsFromSpain;

    const onMouseLeave = (e) =>
        handleOnMouseHover({ e, breakpoint, handleClose });

    return (
        <Box onMouseLeave={onMouseLeave}>
            <CheckAccess
                requiredPermissions={[
                    PERMISSION_LIST.EMBEDDED_PROPOSAL_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/web-quoter');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={/^\/web-quoter/i.test(match.path)}
                    variant="horizontal"
                >
                    <WebIcon />
                    <StyledStyledListItemText primary={t('Web quoter')} />
                    <UpgradeIcon moduleName="Web-Quoter,Cotizador-Web" />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[PERMISSION_LIST.REPORTS_PERMISSION]}
            >
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/monitoring-credentials');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/monitoring-credentials' === match.url}
                    variant="horizontal"
                >
                    <BarChartIcon />
                    <StyledStyledListItemText primary={t('Monitoring')} />
                </StyledListItemButton>
            </CheckAccess>

            {showSupplierIntegration && (
                <CheckAccess
                    requiredPermissions={[PERMISSION_LIST.SUPPLIERS_PERMISSION]}
                >
                    <StyledListItemButton
                        disabled={isDisabledV4 || isDisabledV5}
                        onClick={() => {
                            redirect('/suppliers');
                            handleClose();
                            if (mobileOpen) handleDrawerToggle();
                        }}
                        selected={'/suppliers' === match.url}
                        variant="horizontal"
                    >
                        <ElectricBoltIcon />
                        <StyledStyledListItemText
                            primary={t('Supplier', { count: 2 })}
                        />
                    </StyledListItemButton>
                </CheckAccess>
            )}

            <CheckAccess
                requiredPermissions={[PERMISSION_LIST.ZAPIER_PERMISSION]}
            >
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/zapier');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/zapier' === match.url}
                    variant="horizontal"
                >
                    <ZapierIcon />
                    <StyledStyledListItemText primary="Zapier" />
                </StyledListItemButton>
            </CheckAccess>
        </Box>
    );
};

IntegrationsOptions.propTypes = {
    companyIsFromMexico: PropTypes.bool,
    companyIsFromSpain: PropTypes.bool,
    handleClose: PropTypes.func,
    handleDrawerToggle: PropTypes.func,
    match: PropTypes.object,
    mobileOpen: PropTypes.bool,
    redirect: PropTypes.func,
    subscription: PropTypes.object,
    subscriptions: PropTypes.array,
    subscriptionVersion: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    companyIsFromMexico: companyGeneralSelectors.getCompanyIsFromMexico,
    companyIsFromSpain: companyGeneralSelectors.getCompanyIsFromSpain,
    subscription: profileSelectors.getMainSubscription,
    subscriptions: profileSelectors.getSubscriptions,
    subscriptionVersion: profileSelectors.getSubscriptionVersion,
});

export default connect(mapStateToProps, null)(IntegrationsOptions);
