import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography } from 'sunwise-ui';

const AvailableProjects = ({ subscription, subscriptionVersion }) => {
    const { t } = useTranslation();

    const availableProjects = get(
        subscription,
        'credits.available_projects',
        0,
    );

    const showPurchase = availableProjects <= 5;

    if (subscriptionVersion === 'v5') return null;

    return (
        <Box alignItems="center" display="flex" gap={2} mb={2} pt={1}>
            <Typography
                variant="body2"
                sx={{
                    color: showPurchase ? 'rgba(218, 30, 40, 1)' : 'inherit',
                }}
            >
                {t('Available projects')}: {availableProjects}
            </Typography>

            {showPurchase && (
                <Typography variant="body2">
                    <Link
                        color="secondary"
                        component={RouterLink}
                        to="/profile/subscription?open_section=edit_subscription"
                    >
                        {t('Purchase additional projects')}
                    </Link>
                </Typography>
            )}
        </Box>
    );
};

AvailableProjects.propTypes = {
    subscription: PropTypes.object,
    subscriptionVersion: PropTypes.string,
};

export default AvailableProjects;
