import { getFilters } from 'common/api/boty/reports';

import {
    FETCH_FILTERS,
    FETCH_FILTERS_FAILURE,
    FETCH_FILTERS_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default () => (dispatch) => {
    dispatch(actions[FETCH_FILTERS]());

    getFilters()
        .then((response) => {
            dispatch(actions[FETCH_FILTERS_SUCCESS](response?.data?.data));
        })
        .catch((error) => dispatch(actions[FETCH_FILTERS_FAILURE](error)));
};
