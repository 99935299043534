import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Card } from 'sunwise-ui';

import ChartJs from 'common/components/ChartJs';
import DropdownButton from 'common/components/DropdownButton';

const StyledBox = styled(Box)`
    filter: url('#blur');
    filter: blur(3px);

    &:after {
        background-color: rgb(0 0 0 / 25%);
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
    }
`;

const NoDataIntake = ({
    canModify,
    handleOpenImportConsumptionsModal,
    onClick,
}) => {
    const { t } = useTranslation();
    const CHART_COLORS = ['#008ffb', '#00e396'];
    const data = {
        datasets: [
            {
                backgroundColor: CHART_COLORS[0],
                data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 44, 55, 57],
                label: '',
            },
            {
                backgroundColor: CHART_COLORS[1],
                data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 76, 85, 101],
                label: '',
            },
        ],
        labels: new Array(12).fill('---'),
    };
    const options = {
        plugins: {
            legend: { display: false },
        },
        scales: {
            x: { grid: { display: false }, ticks: { display: false } },
            y: { grid: { display: false }, ticks: { display: false } },
        },
    };

    return (
        <Card sx={{ mb: 2, position: 'relative' }}>
            <StyledBox>
                <Card.Body>
                    <ChartJs data={data} height="250px" options={options} />
                </Card.Body>
            </StyledBox>

            {canModify && (
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: 1,
                        height: '100%',
                        justifyContent: 'center',
                        left: 0,
                        maxWidth: { xs: '100%', md: '500px' },
                        mx: 'auto',
                        p: 2,
                        position: 'absolute',
                        right: 0,
                        top: 0,
                    }}
                >
                    <DropdownButton
                        dataTestId="no-data-intake-dropdown"
                        options={[
                            {
                                icon: <AddIcon />,
                                label: t('Add consumption', { count: 2 }),
                                onClick: onClick,
                            },
                            {
                                icon: <UploadIcon />,
                                label: t('Import project'),
                                onClick: handleOpenImportConsumptionsModal,
                            },
                        ]}
                    />
                </Box>
            )}
        </Card>
    );
};

NoDataIntake.propTypes = {
    canModify: PropTypes.bool,
    handleOpenImportConsumptionsModal: PropTypes.func,
    onClick: PropTypes.func,
};

export default NoDataIntake;
