import i18next from 'i18next';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getTrackings = createSelector(
    getModel,
    (model) => model.trackings,
);

export const getDataTrackings = createSelector(
    getTrackings,
    (trackings) => trackings.data || [],
);

export const getIsLoadingTrackings = createSelector(
    getTrackings,
    (trackings) => trackings.isLoading,
);

export const getFilterTypeTrackings = createSelector(
    getTrackings,
    (trackings) => trackings.filterType,
);

export const getTrackingsNext = createSelector(
    getModel,
    (model) => model.trackingsNext,
);

export const getDataTrackingsNext = createSelector(
    getTrackingsNext,
    (trackings) => trackings.data || [],
);

export const getIsLoadingTrackingsNext = createSelector(
    getTrackingsNext,
    (trackings) => trackings.isLoading,
);

export const getContactId = createSelector(
    getModel,
    (model) => model.contactId,
);

export const getIsVisibleForm = createSelector(
    getModel,
    (model) => model.isVisibleForm,
);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);

export const getSaveTracking = createSelector(
    getModel,
    (model) => model.saveTracking,
);

export const getIsSavingTracking = createSelector(
    getSaveTracking,
    (saveTracking) => saveTracking.isLoading,
);

export const getErrors = createSelector(
    getSaveTracking,
    (saveTracking) => saveTracking.errors,
);

export const getElementToDelete = createSelector(
    getModel,
    (model) => model.elementToDelete,
);

export const getIsOpenConfirmModal = createSelector(
    getModel,
    (model) => model.isOpenConfirmModal,
);

export const getDataConfirmModal = createSelector(
    getModel,
    (model) => model.dataConfirmModal || {},
);

export const getTrackingPurpose = createSelector(
    getModel,
    (model) => model.trackingPurpose,
);

export const getTrackingPurposeToSelect = createSelector(
    getTrackingPurpose,
    (trackingsPurposes) => getTrackingPurposeToDropdown(trackingsPurposes),
);

const getTrackingPurposeToDropdown = (trackingsPurposes) => {
    let list = trackingsPurposes.data
        ? trackingsPurposes.data.map((trackingPurpose) => ({
              value: trackingPurpose.id,
              label: trackingPurpose.name,
          }))
        : [];

    list.unshift({
        disabled: true,
        value: '',
        label: i18next.t('Select a goal'),
    });

    return list;
};

export const getUsers = createSelector(getModel, (model) => model.users);

export const getUsersData = createSelector(getUsers, (model) => model.data);

export const getComments = createSelector(getModel, (model) => model.comments);

export const getIsLoadingComments = createSelector(
    getComments,
    (comments) => comments.isLoading,
);

export const getCommentsData = createSelector(
    getComments,
    (comments) => comments.data,
);

export const getErrorsComments = createSelector(
    getComments,
    (comments) => comments.error,
);
