import React from 'react';

import LockIcon from '@mui/icons-material/Lock';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Typography } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import {
    getPaymentDueModules,
    getSubscriptionsModules,
} from 'common/utils/helpers';

import * as profileSelectors from '../../../modules/profile/selectors';

import LandingV5 from './components/LandingV5';

const Container = ({
    addonName = '',
    addons,
    canModify,
    children,
    flluidView = false,
    isAftersalesSubscribed,
    minHeight = '100%',
    subscriptions,
    subscriptionVersion,
    url,
}) => {
    const { t } = useTranslation();
    const modules = getSubscriptionsModules(
        addons,
        isAftersalesSubscribed,
        subscriptions,
        subscriptionVersion,
    );
    const paymentDuelModules = getPaymentDueModules(subscriptions);

    // Convertir addonName en un array, eliminando espacios en blanco
    const addonNamesArray = addonName.split(',').map((name) => name.trim());

    // Verificar si alguno de los addonNames está en paymentDuelModules
    const isPaymentDue = addonNamesArray.some((name) =>
        paymentDuelModules.includes(name),
    );

    if (isPaymentDue)
        return (
            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                gap={2}
                height={300}
                justifyContent="center"
            >
                <LockIcon fontSize="large" />
                <Typography variant="body2">
                    {t(
                        'This section is blocked due to a pending subscription payment',
                    )}
                </Typography>
            </Box>
        );

    // Verificar si alguno de los addonNames está en modules
    const hasAccess = addonNamesArray.some((name) => modules.includes(name));

    if (hasAccess) return children;

    return (
        <LandingV5
            addonName={addonName}
            canModify={canModify}
            flluidView={flluidView}
            minHeight={minHeight}
            url={url}
        />
    );
};

Container.propTypes = {
    addonName: PropTypes.string,
    addons: PropTypes.array,
    canModify: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    flluidView: PropTypes.bool,
    isAftersalesSubscribed: PropTypes.bool,
    minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    subscriptions: PropTypes.array,
    subscriptionVersion: PropTypes.string,
    url: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    addons: profileSelectors.getAddons,
    isAftersalesSubscribed: profileSelectors.getIsAftersalesSubscribed,
    subscriptions: profileSelectors.getSubscriptions,
    subscriptionVersion: profileSelectors.getSubscriptionVersion,
});

export default compose(
    connect(mapStateToProps, null),
    withPermissions(PERMISSION_LIST.SUBSCRIPTION_PERMISSION),
)(Container);
