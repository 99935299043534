import React, { useState, useEffect } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Alert, Box, Button, IconButton, Tooltip } from 'sunwise-ui';

import IcoAlliance from 'resources/iconAlliance.svg?react';

import * as proposalGeneratorAddAllianceActions from '../../proposalGeneratorAddAlliance/actions';
import SimulateModal from '../../proposalGeneratorAddAlliance/components/SimulateModal';

const StyledIcon = styled(IcoAlliance)`
    height: 26px;
    width: 26px;
    path {
        fill: ${({ theme }) => theme.palette.primary.main};
    }
`;

const FinancingBanner = ({
    financing,
    isCertifiedRate,
    openSimulateModal,
    proposalId,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    // Lista de mensajes
    const messages = [
        t(
            "Present with Sunwise's financing partners, get a no obligation quote",
        ),
        t('Earn more by closing projects with integrated financing options'),
        t('Proven. double your closings by offering financing'),
        t('Close more. Earn more. All with Sunwise financing partners'),
        t('Want to sell more? Offer financing on every proposal'),
        t('Financing is closing. Integrate flexible payment options today'),
        t('Offer complete solutions. Energy + Financing in one step'),
        t('Add value to your proposal. Quote financing from Sunwise'),
        t(
            'Improve your closing rate by integrating financing into every proposal',
        ),
        t('Make your proposals more competitive with financing solutions'),
        t(
            "Incorporate financing into your projects and make your customer's decision easier",
        ),
    ];

    // Estado para el índice actual
    const [currentIndex, setCurrentIndex] = useState(0);
    // Estado persistente para los índices usados con localStorage
    const [usedIndices, setUsedIndices] = useState(() => {
        const stored = localStorage.getItem('bannerUsedIndices');
        return stored ? JSON.parse(stored) : [];
    });

    // Función para obtener un nuevo mensaje aleatorio sin repetir
    const getRandomMessage = () => {
        let newIndex;
        if (usedIndices.length === messages.length) {
            // Si ya se usaron todos, reiniciar
            localStorage.setItem('bannerUsedIndices', JSON.stringify([]));
            setUsedIndices([]);
            newIndex = Math.floor(Math.random() * messages.length);
        } else {
            do {
                newIndex = Math.floor(Math.random() * messages.length);
            } while (usedIndices.includes(newIndex));
        }
        setCurrentIndex(newIndex);
        setUsedIndices((prev) => {
            const newIndices = [...prev, newIndex];
            localStorage.setItem(
                'bannerUsedIndices',
                JSON.stringify(newIndices),
            );
            return newIndices;
        });
    };

    // Ejecutar al montar el componente (carga o cambio de ruta)
    useEffect(() => {
        getRandomMessage();
    }, []); // Array vacío asegura que solo se ejecute una vez al montar

    const renderButton = (
        <>
            <Button
                color="secondary"
                onClick={() => openSimulateModal(true)}
                size="small"
                variant="outlined"
                sx={{ display: { xs: 'none', md: 'inline-flex' } }} // Oculto en móviles (xs), visible desde md
            >
                {t('See options')}
            </Button>
            <Tooltip title={t('See options')}>
                <IconButton
                    onClick={() => openSimulateModal(true)}
                    sx={{ display: { xs: 'inline-flex', md: 'none' } }} // Visible en móviles (xs), oculto desde md
                >
                    <VisibilityIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </>
    );

    if (!isEmpty(financing) || !isCertifiedRate) return null;

    return (
        <>
            <Box display="flex" justifyContent="center" my={1}>
                <Alert
                    action={renderButton}
                    icon={<StyledIcon />}
                    severity="success"
                    sx={{
                        backgroundColor: isDarkMode ? '#1c2a35' : '#ebf2f9',
                        mb: 0,
                        '& .MuiAlert-message': {
                            color: isDarkMode ? '#fff' : '#000000',
                        },
                        width: { xs: '460px', md: '793.7px' },
                    }}
                >
                    <Typography
                        fontWeight={700}
                        sx={{ textAlign: 'center' }}
                        variant="body2"
                    >
                        {messages[currentIndex]}
                    </Typography>
                </Alert>
            </Box>
            <SimulateModal
                closeModal={() => openSimulateModal(false)}
                proposalId={proposalId}
            />
        </>
    );
};

FinancingBanner.propTypes = {
    financing: PropTypes.object,
    isCertifiedRate: PropTypes.bool,
    openSimulateModal: PropTypes.func,
    proposalId: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
    openSimulateModal: (value) =>
        dispatch(
            proposalGeneratorAddAllianceActions.setIsOpenSimulateModal(value),
        ),
});

export default connect(null, mapDispatchToProps)(FinancingBanner);
