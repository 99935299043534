import { getCupsInfoByDate } from 'common/api/v1/cups';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    FETCH_CUPS_INFO,
    FETCH_CUPS_INFO_FAILURE,
    FETCH_CUPS_INFO_SUCCESS,
} from '../actionTypes';
import { UPLOAD_ORIGINS } from '../constants';
import { actions } from '../reducer';

import handleIntegrationsInfo from './handleIntegrationsInfo';

export default ({ cups, getValues, onFailed }) =>
    (dispatch) => {
        const final_date = getValues('final_date');
        const initial_date = getValues('initial_date');
        const rate = getValues('rate');

        if (!cups || !final_date || !initial_date || !rate) return;

        dispatch(actions[FETCH_CUPS_INFO]());

        getCupsInfoByDate({ cups, final_date, initial_date, rate })
            .then((response) => {
                const data = response?.data?.data;
                dispatch(actions[FETCH_CUPS_INFO_SUCCESS](data));

                dispatch(
                    handleIntegrationsInfo({
                        data: { values: data },
                        finalDate: final_date,
                        getValues,
                        initialDate: initial_date,
                        uploadOrigin: UPLOAD_ORIGINS.CUPS,
                    }),
                );
            })
            .catch((error) => {
                dispatch(
                    actions[FETCH_CUPS_INFO_FAILURE](
                        error?.response?.data?.detail,
                    ),
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
                if (onFailed) onFailed();
            });
    };
