import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TabsSystem from 'common/components/TabsSystem';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import RestrictedAccessLanding from 'common/modules/restrictedAccessLanding/Container';

import accessoriesConfiguration from '../accessoriesConfiguration';
import additionalConfiguration from '../additionalConfiguration';
import energyBackupConfiguration from '../energyBackupConfiguration';
import invertersConfiguration from '../invertersConfiguration';
import panelConfiguration from '../panelConfiguration';
import structureConfiguration from '../structureConfiguration';
import workforceConfiguration from '../workforceConfiguration';

const getTabs = (branchSelected, t) => [
    {
        content: (
            <panelConfiguration.Container branchSelected={branchSelected} />
        ),
        id: 'panels',
        label: t('Panel', { count: 2 }),
        requiredPermissions: [PERMISSION_LIST.PANELS_PERMISSION],
    },
    {
        content: (
            <invertersConfiguration.Container branchSelected={branchSelected} />
        ),
        id: 'inverters',
        label: t('Inverter', { count: 2 }),
        requiredPermissions: [PERMISSION_LIST.INVERTERS_PERMISSION],
    },
    {
        content: (
            <energyBackupConfiguration.Container
                branchSelected={branchSelected}
            />
        ),
        id: 'storage',
        label: t('Storage'),
        requiredPermissions: [PERMISSION_LIST.ENERGY_BACKUP_PERMISSION],
    },
    {
        content: (
            <accessoriesConfiguration.Container
                branchSelected={branchSelected}
            />
        ),
        id: 'accessories',
        label: t('Accessory', { count: 2 }),
        requiredPermissions: [PERMISSION_LIST.ACCESORIES_PERMISSION],
    },
    {
        content: (
            <structureConfiguration.Container branchSelected={branchSelected} />
        ),
        id: 'structures',
        label: t('Structure', { count: 2 }),
        requiredPermissions: [PERMISSION_LIST.STRUCTURES_PERMISSION],
    },
    {
        content: (
            <workforceConfiguration.Container branchSelected={branchSelected} />
        ),
        id: 'workforce',
        label: t('Workforce and electrical equipment'),
        requiredPermissions: [PERMISSION_LIST.WORKFORCE_PERMISSION],
    },
    {
        content: (
            <additionalConfiguration.Container
                branchSelected={branchSelected}
            />
        ),
        id: 'additionals',
        label: t('Additional', { count: 2 }),
        requiredPermissions: [PERMISSION_LIST.ADITIONALS_PERMISSION],
    },
];

const Products = ({ branchSelected }) => {
    const { t } = useTranslation();
    return (
        <RestrictedAccessLanding
            addonName="Products"
            url="https://somos.sunwise.io/sunwise-sales"
        >
            <TabsSystem
                baseUrl="products"
                tabs={getTabs(branchSelected, t)}
                title={t('Product', { count: 2 })}
            />
        </RestrictedAccessLanding>
    );
};

Products.propTypes = { branchSelected: PropTypes.string };

export default Products;
