import React, { useEffect, useState } from 'react';

import { useTheme } from '@mui/material';
import { get, isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Tab, Tabs, TabPanel } from 'sunwise-ui';

import AccordionCard from 'common/components/accordions/AccordionCard';
import { IconFinancial } from 'common/components/icons';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import {
    getCountryCurrencyIso,
    getCountryCurrencySymbol,
} from 'common/utils/helpers/session';

import * as proposalGeneratorConfigurationModalActions from '../proposalGeneratorConfigurationModal/actions';
import * as proposalGeneratorQuoteSelectors from '../proposalGeneratorQuote/selectors';
import * as proposalGeneratorRateConfigurationSelectors from '../proposalGeneratorRateConfiguration/selectors';

import * as actions from './actions';
import AllianceFormModal from './components/alliances/AllianceFormModal';
import AllianceList from './components/alliances/AllianceList';
import AllianceReview from './components/alliances/AllianceReview';
import CardList from './components/CardList';
import CardModal from './components/CardModal';
import FinancialReview from './components/FinancialReview';
import FinancingTitle from './components/FinancingTitle';
import { ALLIANCES_TAB, FINANCIAL_PRODUCTS_TAB } from './constants';
import { getFinancierProductSelected } from './helpers';
import * as selectors from './selectors';

const Container = ({
    allianceProducts,
    alliances,
    contact,
    currencyIso,
    currencyLocale,
    currencySymbol,
    deleteFinancing,
    fetchAllianceProducts,
    fetchOfferComplements,
    fetchOfferDetails,
    financialProductParameters,
    financialProducts,
    financierInstaller,
    getPermissionsByCode,
    handleClickCancel,
    handleClickCloseAllianceModal,
    hasFinancing,
    hasFinancingRequest,
    hasOwnFinancing,
    history,
    initialize,
    isArchivedProposal,
    isFetchingAllianceProducts,
    isFetchingFinancialProductsPlan,
    isFetchingFinancierInstallers,
    isGeneratedProposal,
    isLoading,
    isLocked = false,
    isOpenAllianceModal,
    isOpenModal,
    isSaving,
    location,
    openAllianceModal,
    productsTotals,
    proposalId,
    rateComplements,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [selectedTab, setSelectedTab] = useState(ALLIANCES_TAB);
    const [alliance, setAlliance] = useState({});
    const [ownFinancialProduct, setOwnFinancialProduct] = useState({});
    const [financierType, setFinancierType] = useState(null);
    const [editionMode, setEditionMode] = useState(false);
    const { total } = productsTotals;
    const { search: locationSearch = '' } = location;
    const searchParams = new URLSearchParams(locationSearch);
    const allianceId = searchParams.get('alliance_id');

    useEffect(() => {
        if (!isNull(financierInstaller)) {
            setAlliance(financierInstaller);
            setFinancierType(get(financierInstaller, 'financier.type', null));
            if (hasFinancing)
                fetchAllianceProducts(financierInstaller?.id, proposalId);
        }
    }, [financierInstaller]);

    useEffect(() => {
        if (hasOwnFinancing) {
            const product = getFinancierProductSelected(
                financialProductParameters,
                financialProducts,
            );
            setOwnFinancialProduct({
                ...product,
                financingId: financialProductParameters?.id,
            });
        }
    }, [financialProducts, financialProductParameters, hasOwnFinancing]);

    const handleClickAlliance = (item, edition = false) => {
        fetchAllianceProducts(item.id, proposalId);
        setAlliance(item);
        setFinancierType(get(item, 'financier.type', null));
        setEditionMode(edition);
        openAllianceModal(true);
    };

    const handleClickFinancialProduct = (values, edition = false) => {
        initialize({ ...values, total });
        setEditionMode(edition);
    };

    useEffect(() => {
        if (allianceId && !isEmpty(alliances)) {
            const alliance = alliances.find(
                ({ financier }) => financier.id === allianceId,
            );
            if (alliance) handleClickAlliance(alliance);
        }
    }, [alliances, allianceId]);

    const { canModify: canModifyFinancing } = getPermissionsByCode(
        PERMISSION_LIST.FINANCING_PERMISSION,
    );

    const { canModify: canModifyAlliances, canView: canViewAlliances } =
        getPermissionsByCode(
            PERMISSION_LIST.PROPOSAL_ALLIANCE_PRODUCT_PERMISSION,
        );

    const { canModify: canModifyContacts } = getPermissionsByCode(
        PERMISSION_LIST.CONTACTS_PERMISSION,
    );

    const isLoadingAlliances =
        isFetchingAllianceProducts ||
        isFetchingFinancierInstallers ||
        isLoading ||
        isSaving;

    const isLoadingFinancialProducts =
        isFetchingFinancialProductsPlan || isLoading || isSaving;

    const isLockedAllianceForm =
        !canModifyAlliances || isLoadingAlliances || isLocked;

    const isLockedFinancialProductForm =
        !canModifyFinancing || isLoadingFinancialProducts || isLocked;

    const isLoadingForm = isLoadingAlliances || isLoadingFinancialProducts;

    const showAlliancesTab = canViewAlliances && !isArchivedProposal;

    const showOwnFinancialProducts =
        !isEmpty(financialProducts) && !isArchivedProposal;

    useEffect(() => {
        if (showAlliancesTab) setSelectedTab(ALLIANCES_TAB);
        else if (showOwnFinancialProducts)
            setSelectedTab(FINANCIAL_PRODUCTS_TAB);
    }, [showAlliancesTab, showOwnFinancialProducts]);

    const handleChange = (_, newValue) => setSelectedTab(newValue);

    const handleClickCancellAllianceModal = () => {
        const params = new URLSearchParams(locationSearch);
        params.delete('alliance_id');
        history.push({ search: params.toString() });
        handleClickCloseAllianceModal();
    };

    return (
        <>
            <AccordionCard
                cardId="proposal_generator_financing_card"
                classNameCard="__userguiding_proposal_generator_financing_insurance"
                customTitle={
                    <FinancingTitle
                        svgIcon={
                            <IconFinancial color={theme.palette.primary.main} />
                        }
                        title={t('Financing', { count: 2 })}
                    />
                }
                defaultExpanded={true}
                isLoading={isLoadingForm}
                sxAccordionDetails={{ pt: 0 }}
            >
                <Box hidden={hasFinancing}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selectedTab} onChange={handleChange}>
                            {showAlliancesTab && (
                                <Tab
                                    id={`tab-0`}
                                    label={t('Alliance', { count: 2 })}
                                    value={ALLIANCES_TAB}
                                />
                            )}

                            {showOwnFinancialProducts && (
                                <Tab
                                    id={`tab-1`}
                                    label={t('Own', { context: 'male' })}
                                    value={FINANCIAL_PRODUCTS_TAB}
                                />
                            )}
                        </Tabs>
                    </Box>
                    <TabPanel selectedTab={selectedTab} value={ALLIANCES_TAB}>
                        <AllianceList
                            handleOnClikAlliance={handleClickAlliance}
                            isLocked={isLockedAllianceForm}
                            items={alliances}
                        />
                    </TabPanel>
                    <TabPanel
                        selectedTab={selectedTab}
                        value={FINANCIAL_PRODUCTS_TAB}
                    >
                        <CardList
                            handleClickFinancialProduct={
                                handleClickFinancialProduct
                            }
                            isLocked={isLockedFinancialProductForm}
                            items={financialProducts}
                        />
                    </TabPanel>
                </Box>
                <Box hidden={!hasFinancing} py={2}>
                    <AllianceReview
                        alliance={alliance}
                        currencyIso={currencyIso}
                        currencyLocale={currencyLocale}
                        financialProduct={financialProductParameters}
                        financierType={financierType}
                        handleClickAlliance={handleClickAlliance}
                        handleClickDeleteAlliance={deleteFinancing}
                        hasFinancingRequest={hasFinancingRequest}
                        isGeneratedProposal={isGeneratedProposal}
                        isLoading={isLoading}
                        isLocked={isLockedAllianceForm}
                        proposalId={proposalId}
                        show={hasFinancing && !isNull(financierInstaller)}
                    />
                    <FinancialReview
                        currencyLocale={currencyLocale}
                        currencyIso={currencyIso}
                        financialProductParameters={financialProductParameters}
                        handleClickDeleteOwnFinancing={deleteFinancing}
                        handleClickFinancialProduct={
                            handleClickFinancialProduct
                        }
                        isLoading={isLoading}
                        isLocked={isLockedFinancialProductForm}
                        ownFinancialProduct={ownFinancialProduct}
                        proposalId={proposalId}
                        show={hasOwnFinancing}
                    />
                </Box>
            </AccordionCard>

            <CardModal
                currencyIso={getCountryCurrencyIso()}
                currencySymbol={getCountryCurrencySymbol()}
                editionMode={editionMode}
                handleClickCancel={handleClickCancel}
                isLoading={isLoadingFinancialProducts}
                isLocked={isLockedFinancialProductForm}
                isOpenModal={isOpenModal}
                isSaving={isSaving}
                ownFinancialProduct={ownFinancialProduct}
                proposalId={proposalId}
                total={total}
            />

            <AllianceFormModal
                alliance={alliance}
                allianceProducts={allianceProducts}
                canModifyContacts={canModifyContacts}
                contact={contact}
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                editionMode={editionMode}
                fetchOfferComplements={fetchOfferComplements}
                fetchOfferDetails={fetchOfferDetails}
                financialProduct={financialProductParameters}
                financierType={financierType}
                handleClickCancel={handleClickCancellAllianceModal}
                isLoading={isLoadingAlliances}
                isLocked={isLockedAllianceForm}
                isOpenModal={isOpenAllianceModal}
                isSaving={isSaving}
                proposalId={proposalId}
                rateComplements={rateComplements}
                total={total}
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    allianceProducts: selectors.getAllianceProductsData,
    alliances: selectors.getFinancierInstallersData,
    financialProducts: selectors.getFinancialProductsPlanData,
    isFetchingAllianceProducts: selectors.getIsFetchingAllianceProducts,
    isFetchingFinancialProductsPlan:
        selectors.getIsFetchingFinancialProductsPlan,
    isFetchingFinancierInstallers: selectors.getIsFetchingFinancierInstallers,
    isOpenAllianceModal: selectors.getIsOpenAllianceModal,
    isOpenModal: selectors.getIsOpenModal,
    isSaving: selectors.getIsSaving,
    productsTotals: proposalGeneratorQuoteSelectors.getProductsTotals,
    rateComplements:
        proposalGeneratorRateConfigurationSelectors.getRateComplements,
});

const mapDispatchToProps = (dispatch) => ({
    deleteFinancing: (id, proposalId) =>
        dispatch(actions.deleteFinancing(id, proposalId)),
    fetchAllianceProducts: (allianceId, proposalId) =>
        dispatch(
            actions.fetchAllianceFinancialProducts(allianceId, proposalId),
        ),
    fetchOfferComplements: (proposalId) =>
        dispatch(
            proposalGeneratorConfigurationModalActions.fetchOfferComplements(
                proposalId,
            ),
        ),
    handleClickCancel: () => dispatch(actions.closeModal()),
    handleClickCloseAllianceModal: () => dispatch(actions.closeAllianceModal()),
    initialize: (values) => dispatch(actions.initialValues(values)),
    openAllianceModal: (value) =>
        dispatch(actions.setIsOpenAllianceModal(value)),
});

Container.propTypes = {
    allianceProducts: PropTypes.array,
    alliances: PropTypes.array,
    contact: PropTypes.object,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    deleteFinancing: PropTypes.func,
    fetchAllianceProducts: PropTypes.func,
    fetchOfferComplements: PropTypes.func,
    fetchOfferDetails: PropTypes.func,
    financialProductParameters: PropTypes.object,
    financialProducts: PropTypes.array,
    financierInstaller: PropTypes.object,
    getPermissionsByCode: PropTypes.func,
    handleClickCancel: PropTypes.func,
    handleClickCloseAllianceModal: PropTypes.func,
    hasFinancing: PropTypes.bool,
    hasFinancingRequest: PropTypes.bool,
    hasOwnFinancing: PropTypes.bool,
    history: PropTypes.object,
    initialize: PropTypes.func,
    isArchivedProposal: PropTypes.bool,
    isFetchingAllianceProducts: PropTypes.bool,
    isFetchingFinancialProductsPlan: PropTypes.bool,
    isFetchingFinancierInstallers: PropTypes.bool,
    isGeneratedProposal: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isOpenAllianceModal: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    isSaving: PropTypes.bool,
    location: PropTypes.object,
    openAllianceModal: PropTypes.func,
    productsTotals: PropTypes.object,
    proposalId: PropTypes.string,
    rateComplements: PropTypes.object,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions([
        PERMISSION_LIST.CONTACTS_PERMISSION,
        PERMISSION_LIST.FINANCING_PERMISSION,
        PERMISSION_LIST.PROPOSAL_ALLIANCE_PRODUCT_PERMISSION,
    ]),
    withRouter,
)(Container);
