import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

// CONFIRM SUBSCRIPTION
export const getConfirmSubscription = createSelector(
    getModel,
    (model) => model.confirmSubscription,
);

export const getIsSavingSubscription = createSelector(
    getConfirmSubscription,
    (value) => value.isSaving,
);
