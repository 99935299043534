import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';

import { PREFIX } from 'common/sockets/constants';
import { generateSocketActions } from 'common/sockets/helpers';

import {
    FETCH_BILLING_INFORMATION,
    FETCH_BILLING_INFORMATION_FAILURE,
    FETCH_BILLING_INFORMATION_SUCCESS,
    FETCH_PAYMENT_SOURCES,
    FETCH_PAYMENT_SOURCES_FAILURE,
    FETCH_PAYMENT_SOURCES_SUCCESS,
    FETCH_SUBSCRIPTION,
    FETCH_SUBSCRIPTION_FAILURE,
    FETCH_SUBSCRIPTION_SUCCESS,
    PAY_INVOICES,
    PAY_INVOICES_FAILURE,
    PAY_INVOICES_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const {
    BROKEN_SOCKET,
    CLOSED_SOCKET,
    ERROR_SOCKET,
    MESSAGE_SOCKET,
    OPEN_SOCKET,
    RECONNECTED,
} = generateSocketActions(PREFIX);

const INITIAL_STATE = {
    billingInformation: {
        data: {},
        errors: null,
        isFetching: false,
    },
    payInvoices: {
        data: [],
        errors: [],
        isFetching: false,
    },
    paymentSources: {
        data: [],
        errors: null,
        isFetching: false,
    },
    socketConnected: false,
    socketMessage: {},
    socketError: null,
    subscription: {
        data: {},
        errors: null,
        isFetching: false,
    },
};

const profileSubscriptionsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_BILLING_INFORMATION]: (state) => {
            state.billingInformation = {
                ...state.billingInformation,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_BILLING_INFORMATION_FAILURE]: (state, action) => {
            state.billingInformation = {
                ...state.billingInformation,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_BILLING_INFORMATION_SUCCESS]: (state, action) => {
            state.billingInformation = {
                ...state.billingInformation,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PAYMENT_SOURCES]: (state) => {
            state.paymentSources = {
                ...state.paymentSources,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_PAYMENT_SOURCES_FAILURE]: (state, action) => {
            state.paymentSources = {
                ...state.paymentSources,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PAYMENT_SOURCES_SUCCESS]: (state, action) => {
            state.paymentSources = {
                ...state.paymentSources,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SUBSCRIPTION]: (state) => {
            state.subscription.isFetching = true;
        },
        [FETCH_SUBSCRIPTION_FAILURE]: (state, action) => {
            state.subscription = {
                ...state.subscription,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SUBSCRIPTION_SUCCESS]: (state, action) => {
            state.subscription = {
                ...state.subscription,
                data: action.payload,
                isFetching: false,
            };
            state.payInvoices.errors = [];
        },
        [PAY_INVOICES]: (state) => {
            state.payInvoices = {
                ...state.payInvoices,
                errors: [],
                isFetching: true,
            };
        },
        [PAY_INVOICES_FAILURE]: (state, action) => {
            state.payInvoices = {
                ...state.payInvoices,
                errors: action.payload,
                isFetching: false,
            };
        },
        [PAY_INVOICES_SUCCESS]: (state, action) => {
            state.payInvoices = {
                ...state.payInvoices,
                data: action.payload,
                isFetching: false,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(BROKEN_SOCKET, (state) => {
                state.socketError = i18next.t(
                    'Error sending data. Restart your session or try again later',
                );
            })
            .addCase(ERROR_SOCKET, (state) => {
                state.socketError = i18next.t('Server error. Try again later');
            })
            .addCase(RECONNECTED, (state) => {
                state.socketError = null;
            })
            .addCase(CLOSED_SOCKET, (state) => {
                state.socketConnected = false;
            })
            .addCase(OPEN_SOCKET, (state) => {
                state.socketConnected = true;
            })
            .addCase(MESSAGE_SOCKET, (state, action) => {
                const { message = {} } = JSON.parse(action?.payload?.message);
                state.socketMessage = message;
            });
    },
});

export const profileSubscriptionsActions = profileSubscriptionsSlice.actions;

export default profileSubscriptionsSlice.reducer;
