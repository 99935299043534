import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import {
    ReactHookFormIntlNumberInput,
    ReactHookFormIntlNumberInputConsumption,
} from 'common/components/form/bootstrap';
import { HIGH_TENSION_RATES } from 'common/constants/rates';

import { getSectionHasErrors } from '../../helpers';

import FormAccordion from './FormAccordion';

const ContractedDemandFields = ({ control, disabled, errors, rate }) => {
    const fieldsKeys = useWatch({ control, name: 'fields_keys' });
    const { t } = useTranslation();

    if (!rate) return null;

    const hasUniqueField =
        rate.certified && HIGH_TENSION_RATES.includes(rate.name);

    if (!hasUniqueField && !fieldsKeys?.hourly_contracted_demand?.length)
        return null;

    return (
        <FormAccordion
            hasFormErrors={getSectionHasErrors(errors, [
                'contracted_demand',
                'hourly_contracted_demand',
            ])}
            title={t('Contracted demand')}
        >
            <Grid container>
                {hasUniqueField && (
                    <Grid size={{ lg: 6, xs: 18 }}>
                        <ReactHookFormIntlNumberInput
                            allowNegativeValue={false}
                            append="kW"
                            control={control}
                            decimalsLimit={2}
                            disabled={disabled}
                            fullWidth
                            label={t('Contracted demand')}
                            name="contracted_demand"
                            variant="standard"
                        />
                    </Grid>
                )}
                {!hasUniqueField &&
                    fieldsKeys.hourly_contracted_demand.map((key) => (
                        <Grid
                            key={`contracted-demand-${key}`}
                            size={{ lg: 'grow', sx: 18 }}
                        >
                            <ReactHookFormIntlNumberInputConsumption
                                append="kW"
                                control={control}
                                decimalsLimit={2}
                                disabled={disabled}
                                fullWidth
                                name={`hourly_contracted_demand.${key}`}
                                variant="standard"
                            />
                        </Grid>
                    ))}
            </Grid>
        </FormAccordion>
    );
};

ContractedDemandFields.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    rate: PropTypes.object,
};

export default ContractedDemandFields;
