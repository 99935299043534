import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Grid, TextField } from 'sunwise-ui';

import { AutocompleteField, InputSearch } from 'common/components';

const FiltersProductsCard = ({
    filterData,
    handleOnChangeMaxPower,
    handleOnChangeSearch,
    handleOnSelectBrand,
    maxPowerLabel,
    productBrands,
}) => {
    const { t } = useTranslation();

    const getBrandOptions = () => {
        return productBrands.map(({ name }) => ({
            value: name,
            label: name,
        }));
    };

    return (
        <Card>
            <Card.Body>
                <Grid container alignItems="end" justifyContent="flex-end">
                    <Grid size={{ lg: 12, md: 10, xs: 18 }}>
                        <InputSearch
                            placeholder={t('Filter by name')}
                            onChange={handleOnChangeSearch}
                            value={filterData?.searchText}
                        />
                    </Grid>
                    <Grid size={{ lg: 4, md: 4, xs: 18 }}>
                        <AutocompleteField
                            label={t('Brand')}
                            options={getBrandOptions()}
                            onChange={(value) => handleOnSelectBrand(value)}
                            value={filterData?.brandName}
                        />
                    </Grid>
                    <Grid size={{ lg: 2, md: 3, xs: 18 }}>
                        <TextField
                            label={maxPowerLabel}
                            onChange={(e) => handleOnChangeMaxPower(e)}
                            value={filterData?.maxPower}
                            variant="standard"
                        />
                    </Grid>
                </Grid>
            </Card.Body>
        </Card>
    );
};

FiltersProductsCard.defaultProps = {
    maxPowerLabel: 'kW',
};

FiltersProductsCard.propTypes = {
    filterData: PropTypes.object,
    handleOnChangeMaxPower: PropTypes.func,
    handleOnChangeSearch: PropTypes.func,
    handleOnSelectBrand: PropTypes.func,
    maxPowerLabel: PropTypes.string,
    productBrands: PropTypes.array,
};

export default FiltersProductsCard;
