import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';

import { handleCloseDrawer } from '../helpers';

import EditionButtons from './EditionButtons';
import ElectricBillCard from './ElectricBillCard';
import ReportCard from './ReportCard';
import ShareCard from './ShareCard';

const SupportMenuContent = ({
    canModify,
    downloadFormat,
    editionDisabled,
    handleClickDocumentItem,
    handleClickDownload,
    handleClickEditButton,
    handleClickElectricBillItem,
    handleOnRegenerateTemplate,
    handleUpdateTemplate,
    isDownloading,
    isUpdatingCustomTemplate,
    reportDetails,
    reportId,
    reportName,
    restorePages,
    selectedDocument,
    setDownloadFormat,
    setEditionDisabled,
    swipeableDrawerRef,
}) => {
    const electricBills = get(reportDetails, 'report_files', []);
    return (
        <>
            <EditionButtons
                canModify={canModify}
                editionDisabled={editionDisabled}
                handleClickCancel={() => {
                    restorePages();
                    setEditionDisabled(true);
                    handleCloseDrawer(swipeableDrawerRef);
                }}
                handleClickSave={() => {
                    handleUpdateTemplate();
                    handleCloseDrawer(swipeableDrawerRef);
                }}
                isUpdatingCustomTemplate={isUpdatingCustomTemplate}
            />

            <ShareCard
                canModify={canModify}
                downloadFormat={downloadFormat}
                handleClickDownload={handleClickDownload}
                isDisabled={
                    !editionDisabled ||
                    isUpdatingCustomTemplate ||
                    isDownloading
                }
                isEnablePermalink={get(reportDetails, 'hasPermalink', false)}
                permalink={get(reportDetails, 'permalink', null)}
                reportName={reportName}
                selectedDocument={selectedDocument}
                setDownloadFormat={setDownloadFormat}
            />

            <ReportCard
                canModify={canModify}
                handleClickDocumentItem={handleClickDocumentItem}
                handleClickEditButton={handleClickEditButton}
                handleOnRegenerateTemplate={handleOnRegenerateTemplate}
                isDisabled={
                    !editionDisabled ||
                    isUpdatingCustomTemplate ||
                    isDownloading
                }
                reportId={reportId}
                reportName={reportName}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />

            <ElectricBillCard
                electricBills={electricBills}
                handleClickElectricBillItem={handleClickElectricBillItem}
                isDisabled={
                    !editionDisabled ||
                    isUpdatingCustomTemplate ||
                    isDownloading
                }
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />
        </>
    );
};

SupportMenuContent.propTypes = {
    canModify: PropTypes.bool,
    downloadFormat: PropTypes.number,
    editionDisabled: PropTypes.bool,
    handleClickDocumentItem: PropTypes.func,
    handleClickDownload: PropTypes.func,
    handleClickEditButton: PropTypes.func,
    handleClickElectricBillItem: PropTypes.func,
    handleOnRegenerateTemplate: PropTypes.func,
    handleUpdateTemplate: PropTypes.func,
    isDownloading: PropTypes.bool,
    isUpdatingCustomTemplate: PropTypes.bool,
    reportDetails: PropTypes.object,
    reportId: PropTypes.string,
    reportName: PropTypes.string,
    restorePages: PropTypes.func,
    selectedDocument: PropTypes.object,
    setDownloadFormat: PropTypes.func,
    setEditionDisabled: PropTypes.func,
    swipeableDrawerRef: PropTypes.object,
};

export default SupportMenuContent;
