import React from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
} from 'sunwise-ui';

import { getDownloadOptions } from '../helpers';

const DownloadFormatDrowpdown = ({
    downloadFormat,
    handleClickDownload,
    handleClickDownloadFormat,
    isDisabled,
    selectedDocument,
}) => {
    const { t } = useTranslation();
    const downloadOptions = getDownloadOptions(selectedDocument);

    return (
        <Box alignItems="flex-end" display="flex" gap={1} mt={3}>
            <FormControl fullWidth>
                <InputLabel id="format-select-label">
                    {t('Download format', { context: 'noun' })}
                </InputLabel>
                <Select
                    disabled={isDisabled}
                    id="report-download-format"
                    onChange={handleClickDownloadFormat}
                    sx={{ fontSize: '0.875rem' }}
                    value={downloadFormat}
                    variant="standard"
                >
                    {downloadOptions.map((option) => (
                        <MenuItem
                            disabled={option.disabled}
                            key={`download-item-${option.value}`}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <IconButton
                color="primary"
                disabled={!downloadFormat || isDisabled}
                onClick={handleClickDownload}
            >
                <DownloadIcon />
            </IconButton>
        </Box>
    );
};

DownloadFormatDrowpdown.propTypes = {
    downloadFormat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    handleClickDownload: PropTypes.func,
    handleClickDownloadFormat: PropTypes.func,
    isDisabled: PropTypes.bool,
    selectedDocument: PropTypes.object,
};

export default DownloadFormatDrowpdown;
