import React from 'react';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from 'sunwise-ui';

import ChartJs from 'common/components/ChartJs';
import KeyIndicator from 'common/components/KeyIndicator';
import { numberFormat } from 'common/utils/helpers';
import { getTooltipDateRange } from 'common/utils/helpersChart';

import { getEconomicChartSettings } from '../../projectConsumptionCard/helpers';

const EconomicChart = ({
    countryCurrencyIso,
    countryCurrencyLocale,
    countryCurrencySymbol,
    indicatorText = false,
    infoPriceConsumtion,
    monthskwh,
}) => {
    const { t } = useTranslation();
    const categories = monthskwh.map((month) => month.label.toUpperCase());
    const chartConfig = getEconomicChartSettings(
        infoPriceConsumtion,
        categories,
        countryCurrencyIso,
        countryCurrencyLocale,
        countryCurrencySymbol,
        monthskwh,
    );

    const data = {
        datasets: chartConfig.series.map(({ data, name }, index) => {
            const colorIndex = index % chartConfig.options.colors.length;
            return {
                backgroundColor: chartConfig.options.colors[colorIndex],
                data,
                label: name,
            };
        }),
        labels: categories,
    };
    const options = {
        plugins: {
            tooltip: {
                callbacks: {
                    title: (context) => {
                        const ctx = context[0];
                        const values = ctx.parsed._stacks.y._visualValues;
                        const _metasets = ctx.chart._metasets;
                        const valuesLength = Object.keys(values).length - 1;
                        let total = 0;

                        for (let i = 0; i <= valuesLength; i++) {
                            if (_metasets[i].hidden) continue;
                            total += values[i];
                        }

                        const formattedTotal = numberFormat(total, {
                            currency: countryCurrencyIso,
                            locale: countryCurrencyLocale,
                            style: 'currency',
                        });

                        return `${getTooltipDateRange({
                            index: ctx.dataIndex,
                            seriesEnergy: monthskwh,
                        })} - ${formattedTotal}`;
                    },
                    label: (context) => {
                        return (
                            context.dataset.label +
                            ': ' +
                            numberFormat(context.parsed.y, {
                                currency: countryCurrencyIso,
                                locale: countryCurrencyLocale,
                                style: 'currency',
                            })
                        );
                    },
                },
            },
        },
        scales: {
            x: { grid: { drawOnChartArea: false }, stacked: true },
            y: {
                stacked: true,
                ticks: {
                    callback: (val) =>
                        numberFormat(val, {
                            currency: countryCurrencyIso,
                            locale: countryCurrencyLocale,
                            style: 'currency',
                            symbol: countryCurrencySymbol,
                        }),
                },
            },
        },
    };
    const getAnnualCost = (infoPriceConsumtion) => {
        return infoPriceConsumtion.reduce((acc, cur) => {
            let _acc = 0;
            if (!cur.value) return acc + _acc;
            for (const [, value] of Object.entries(cur.value)) {
                _acc += value;
            }
            return acc + _acc;
        }, 0);
    };

    return (
        <>
            <ChartJs data={data} options={options} />

            {indicatorText ? (
                <Grid container justifyContent="center">
                    <Grid size="grow" textAlign="center">
                        <Typography variant="body2">
                            {t('Annual cost')}
                        </Typography>
                        <Typography variant="body2" fontWeight="bold">
                            {numberFormat(getAnnualCost(infoPriceConsumtion), {
                                currency: countryCurrencyIso,
                                decimals: 2,
                                locale: countryCurrencyLocale,
                                style: 'currency',
                            })}
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <KeyIndicator
                    svgIcon={<AttachMoneyIcon />}
                    title={t('Annual cost')}
                    value={numberFormat(getAnnualCost(infoPriceConsumtion), {
                        currency: countryCurrencyIso,
                        decimals: 2,
                        locale: countryCurrencyLocale,
                        style: 'currency',
                    })}
                />
            )}
        </>
    );
};

EconomicChart.propTypes = {
    countryCurrencyIso: PropTypes.string,
    countryCurrencyLocale: PropTypes.string,
    countryCurrencySymbol: PropTypes.string,
    indicatorText: PropTypes.bool,
    infoPriceConsumtion: PropTypes.array,
    monthskwh: PropTypes.array,
};

export default EconomicChart;
