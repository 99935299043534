import React from 'react';

import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Skeleton, Typography } from 'sunwise-ui';

import { generateMapUrl } from 'common/utils/helpers';

import WorldMap from 'resources/world-map.png';

const WrapperImg = styled(Box)`
    align-items: center;
    background-position: center;
    background-size: cover;
    display: flex;
    height: 400px;
    justify-content: center;
    position: relative;
    width: 100%;
`;

const ProjectLocation = ({ hasLocation, isFetching, location }) => {
    const { t } = useTranslation();

    if (isFetching)
        return (
            <Grid size={{ md: 9, xs: 18 }}>
                <Skeleton variant="rounded" height={400} />
            </Grid>
        );

    if (hasLocation)
        return (
            <Grid size={{ md: 9, xs: 18 }}>
                <Typography fontWeight="bold" variant="body2">
                    {location?.description}
                </Typography>
                <WrapperImg
                    sx={{
                        backgroundImage: `url(${generateMapUrl({
                            lat: get(location, 'latitude', ''),
                            lng: get(location, 'longitude', ''),
                        })})`,
                    }}
                />
            </Grid>
        );

    return (
        <Grid size={{ md: 9, xs: 18 }}>
            <WrapperImg
                sx={{
                    backgroundImage: `url(${WorldMap})`,
                }}
            >
                <Typography fontWeight="bold" variant="body2">
                    {t('No location')}
                </Typography>
            </WrapperImg>
        </Grid>
    );
};

ProjectLocation.propTypes = {
    hasLocation: PropTypes.bool,
    isFetching: PropTypes.bool,
    location: PropTypes.object,
};

export default ProjectLocation;
