import i18next from 'i18next';
import { get, isNull } from 'lodash';

import { SUBSCRIPTION_STATUS } from 'common/constants';
import { formatDate, parseDate } from 'common/utils/dates';

import { SOCKET_SUBSCRIPTION_EVENTS } from './constants';

export const formatPeriodDate = (date) => {
    return formatDate(parseDate(date, 'dd/M/yyyy HH:mm:ss'), 'dd MMMM yyyy');
};

export const getBuyButtonText = (product_type, version) => {
    if (version === 'v4') return i18next.t('Buy additionals');
    switch (product_type) {
        case 'aftersales':
            return i18next.t('Buy reports');
        case 'bundle':
            return i18next.t('Buy proposals/reports');
        default:
            return i18next.t('Buy proposals');
    }
};

export const getProductNameText = (product_type) => {
    switch (product_type) {
        case 'aftersales':
            return 'AfterSales';
        case 'bundle':
            return 'Bundle';
        default:
            return 'Sales';
    }
};

export const getCallbacks = (fetchUserSettings, resetLocation) => ({
    close: () => resetLocation(),
    loaded: () => {},
    subscriptionChanged: () => {
        fetchUserSettings();
    },
    subscriptionCancelled: () => {
        fetchUserSettings();
    },
    scheduledCancellationRemoved: () => {
        fetchUserSettings();
    },
    subscriptionReactivated: () => {
        fetchUserSettings();
    },
    visit: () => {},
});

export const getStatusDetails = (status) => {
    switch (status) {
        case SUBSCRIPTION_STATUS.ACTIVE:
            return i18next.t('Active', { context: 'female' });
        case SUBSCRIPTION_STATUS.FUTURE:
            return i18next.t('Future');
        case SUBSCRIPTION_STATUS.INTRIAL:
            return i18next.t('In trial period');
        case SUBSCRIPTION_STATUS.NON_RENEWING:
            return i18next.t('Non renewing');
        case SUBSCRIPTION_STATUS.PAUSED:
            return i18next.t('Paused');
        case SUBSCRIPTION_STATUS.CANCELLED:
            return i18next.t('Cancelled');
        case SUBSCRIPTION_STATUS.NO_SUBSCRIPTION:
            return i18next.t('Without subscription');
        default:
            return i18next.t('Inactive');
    }
};

export const getStatusName = (hasPaymentDue, subscription) => {
    if (hasPaymentDue) return i18next.t('Payment due');
    return getStatusDetails(get(subscription, 'status.key', null));
};

export const handleOpenPortal = (
    callbacks,
    cbInstance,
    sectionType,
    subscriptionId,
) => {
    if (isNull(cbInstance) || isNull(subscriptionId)) return;
    const cbPortal = cbInstance.createChargebeePortal();
    if (sectionType)
        cbPortal.open(callbacks, {
            sectionType,
            params: {
                subscriptionId,
            },
        });
    else cbPortal.open(callbacks);
};

export const handleSocketEvents = (
    cbInstance,
    event,
    fetchBillingInformation,
    fetchPaymentSources,
    fetchUserSettings,
    setEvent,
) => {
    switch (event) {
        case SOCKET_SUBSCRIPTION_EVENTS.UPDATE_BILLING:
            fetchBillingInformation();
            break;
        case SOCKET_SUBSCRIPTION_EVENTS.UPDATE_CUSTOMER:
            fetchUserSettings();
            break;
        case SOCKET_SUBSCRIPTION_EVENTS.UPDATE_PAYMENT_SOURCES:
            fetchPaymentSources();
            break;
        case SOCKET_SUBSCRIPTION_EVENTS.UPDATE_SUBSCRIPTION:
            fetchUserSettings();
            break;
        default:
            break;
    }
    if (cbInstance) cbInstance.closeAll();
    setEvent(null);
};
