import React, { useEffect, useMemo, useState } from 'react';

import {
    Alert,
    Box,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Typography,
} from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ChartJs from 'common/components/ChartJs';
import { numberFormat } from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import {
    getChartConfig,
    getGroupedDayLabel,
    groupEnergyIndexesByDay,
} from '../../helpers';

import FormBottomActions from './FormBottomActions';

export const CHART_OPTIONS = {
    PERIOD: 0,
    DAILY: 1,
};

const CHART_OPTIONS_CONFIG = {
    [CHART_OPTIONS.PERIOD]: { label: 'Period' },
    [CHART_OPTIONS.DAILY]: { label: 'Daily' },
};

const optionsToSelect = Object.values(CHART_OPTIONS);

const MonitoringStep = ({
    control,
    disabled,
    handleClickBack,
    handleClickNext,
    isFetchingMeasuredEnergy,
    measuredEnergy,
    readOnly,
}) => {
    const [chartConfig, setChartConfig] = useState({
        data: { datasets: [] },
        options: {},
        totals: {},
    });
    const [chartControls, setChartControls] = useState({ option: 0, day: 0 });
    const { t } = useTranslation();
    const hasTimestampGaps = useWatch({ control, name: 'hasTimestampGaps' });
    const countryCurrencyLocale = getCountryCurrencyLocale();

    const changeChartControls = (newControls) => {
        if (!newControls) return;
        setChartControls((prevState) => ({ ...prevState, ...newControls }));
    };

    const groupedIndexes = useMemo(
        () => groupEnergyIndexesByDay(measuredEnergy?.timestamp),
        [measuredEnergy?.timestamp],
    );

    const dayOptions = useMemo(() => {
        if (!groupedIndexes?.length || !measuredEnergy?.timestamp?.length)
            return [];

        const days = [];

        for (let dayIndex = 0; dayIndex <= groupedIndexes.length; dayIndex++) {
            const dayLabel = getGroupedDayLabel({
                data: measuredEnergy?.timestamp,
                indexes: groupedIndexes[dayIndex],
            });

            if (!dayLabel) continue;

            days.push({ value: dayIndex, label: dayLabel });
        }

        return days;
    }, [groupedIndexes, measuredEnergy?.timestamp]);

    useEffect(() => {
        setChartConfig(
            getChartConfig({
                ...chartControls,
                groupedIndexes,
                measuredEnergy,
            }),
        );
    }, [chartControls, groupedIndexes, measuredEnergy]);

    return (
        <>
            {hasTimestampGaps && (
                <Alert severity="warning" sx={{ mb: 2 }}>
                    {t(
                        'There are gaps in the monitoring data. This can be due to missing data or data that is not available for the selected time range',
                    )}
                </Alert>
            )}

            <Typography variant="subtitle2">
                {`${t('Select the concepts to use for the calculation')} (${t('Previous data will be overwritten')}):`}
            </Typography>

            <Box
                alignItems="center"
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }}
                flexWrap="wrap"
                gap={1}
                mb={2}
                mt={1}
            >
                {isFetchingMeasuredEnergy
                    ? Array.from({ length: 5 }).map((_, index) => (
                          <Skeleton
                              height={30}
                              key={index}
                              sx={{ width: { xs: '100%', md: '200px' } }}
                              variant="rounded"
                          />
                      ))
                    : chartConfig?.data?.datasets?.map((dataset) => (
                          <Controller
                              control={control}
                              defaultValue={false}
                              key={dataset.key}
                              name={`monitoring.${dataset.key}`}
                              render={({ field }) => (
                                  <Chip
                                      color="primary"
                                      disabled={disabled}
                                      key={dataset.key}
                                      label={`${dataset.label} (${numberFormat(
                                          chartConfig.totals?.[dataset.key] ||
                                              0,
                                          {
                                              decimals: 2,
                                              locale: countryCurrencyLocale,
                                              style: 'decimal',
                                              unit: 'kWh',
                                          },
                                      )})`}
                                      onClick={() =>
                                          !disabled &&
                                          field.onChange(!field.value)
                                      }
                                      onDelete={
                                          field.value && !disabled
                                              ? () => field.onChange(false)
                                              : null
                                      }
                                      sx={{ width: { xs: '100%', md: 'auto' } }}
                                      variant={
                                          field.value ? 'filled' : 'outlined'
                                      }
                                  />
                              )}
                          />
                      ))}
            </Box>

            <Typography variant="subtitle2" sx={{ my: 2 }}>
                {t('Measurement source', { count: 2 })}:
            </Typography>

            <Box display="flex" flexWrap="wrap" gap="16px">
                <FormControl size="small">
                    <InputLabel>{t('Option')}</InputLabel>
                    <Select
                        disabled={isFetchingMeasuredEnergy}
                        label={t('Option')}
                        onChange={(e) =>
                            changeChartControls({
                                option: e.target.value,
                            })
                        }
                        value={chartControls.option}
                    >
                        {optionsToSelect.map((value) => {
                            const config = CHART_OPTIONS_CONFIG[value];
                            if (!config) return null;
                            return (
                                <MenuItem key={value} value={value}>
                                    {t(config.label)}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                {chartControls.option === CHART_OPTIONS.DAILY && (
                    <FormControl size="small">
                        <InputLabel>{t('Day')}</InputLabel>
                        <Select
                            disabled={isFetchingMeasuredEnergy}
                            label={t('Day')}
                            onChange={(e) =>
                                changeChartControls({
                                    day: e.target.value,
                                })
                            }
                            value={chartControls.day}
                        >
                            {dayOptions.map(({ label, value }) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </Box>

            {isFetchingMeasuredEnergy ? (
                <Skeleton height="300px" variant="rounded" />
            ) : (
                <ChartJs
                    data={chartConfig?.data}
                    height="300px"
                    options={chartConfig?.options}
                    type="line"
                />
            )}

            <FormBottomActions
                disabled={disabled && !readOnly}
                handleClickBack={handleClickBack}
                handleClickNext={handleClickNext}
            />
        </>
    );
};

MonitoringStep.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    handleClickBack: PropTypes.func,
    handleClickNext: PropTypes.func,
    isFetchingMeasuredEnergy: PropTypes.bool,
    measuredEnergy: PropTypes.object,
    readOnly: PropTypes.bool,
};

export default MonitoringStep;
