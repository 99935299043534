import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from 'sunwise-ui';

import { handleFileURL } from 'common/utils/helpers';

import EmptyAlliances from 'resources/EmptyAlliances.png';

import StyledButton from './StyledButton';

const Financiers = ({ financiers, handleOnClick, isFetching }) => {
    const { t } = useTranslation();

    if (isFetching) {
        return (
            <Typography variant="caption">
                {t('Loading alliance', { count: 2 }).concat('...')}
            </Typography>
        );
    }

    if (financiers.length === 0) {
        return <img alt="" src={EmptyAlliances} />;
    }

    return (
        <Box
            display="flex"
            justifyContent="space-around"
            gap="16px"
            flexWrap="wrap"
        >
            {Array.isArray(financiers) &&
                financiers.length > 0 &&
                financiers.map((financier) => (
                    <Button
                        key={`financier-${financier.id}`}
                        onClick={() => handleOnClick(financier)}
                        variant="default"
                    >
                        <Box>
                            <StyledButton>
                                <picture>
                                    <img
                                        alt=""
                                        src={handleFileURL(
                                            financier.image,
                                            import.meta.env.VITE_S3_MEDIA_PATH,
                                        )}
                                    />
                                </picture>
                            </StyledButton>
                            {financier.name}
                        </Box>
                    </Button>
                ))}
        </Box>
    );
};

Financiers.propTypes = {
    financiers: PropTypes.array,
    handleOnClick: PropTypes.func,
    isFetching: PropTypes.bool,
};

export default Financiers;
