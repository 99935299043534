import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import ArticleIcon from '@mui/icons-material/Article';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { types } from 'sunwise-template-core';
import {
    Button,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
} from 'sunwise-ui';

import * as actions from '../actions';

import MenuOptions from './MenuOptions';
import SideMenuCard from './SideMenuCard';

const SmartDocumentsCard = ({
    canModify,
    handleClickDocumentItem,
    handleClickEditButton,
    handleClickPrepareRegenerateSmartDocumentTemplate,
    isApproved,
    isDisabled,
    isHidden,
    isReadOnly,
    offerSmartDocuments,
    prepareOfferSmartDocumentCreate,
    prepareOfferSmartDocumentDelete,
    prepareOfferSmartDocumentDuplicate,
    prepareOfferSmartDocumentRename,
    projectId,
    proposalDocumentDefault,
    proposalId,
    selectedDocumentId,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    const handleOnClickCreate = () =>
        prepareOfferSmartDocumentCreate({ projectId, proposalId });

    const disabled = isDisabled || isApproved || !canModify || isReadOnly;

    if (isHidden) return null;

    return (
        <SideMenuCard
            icon={<ArticleIcon sx={{ color: 'primary.main' }} />}
            title={t('Smart document', { count: 2 })}
        >
            <List disablePadding>
                {offerSmartDocuments.map((document) => (
                    <ListItem
                        disabled={
                            isDisabled ||
                            document.id === selectedDocumentId ||
                            isReadOnly
                        }
                        disablePadding
                        key={`smart-document-${document.id}`}
                        secondaryAction={
                            <MenuOptions
                                canModify={canModify}
                                document={document}
                                handleClickDocumentItem={
                                    handleClickDocumentItem
                                }
                                handleClickEditButton={handleClickEditButton}
                                handleClickPrepareRegenerateSmartDocumentTemplate={
                                    handleClickPrepareRegenerateSmartDocumentTemplate
                                }
                                isApproved={isApproved}
                                isDisabled={isDisabled}
                                prepareOfferSmartDocumentDelete={
                                    prepareOfferSmartDocumentDelete
                                }
                                prepareOfferSmartDocumentDuplicate={
                                    prepareOfferSmartDocumentDuplicate
                                }
                                prepareOfferSmartDocumentRename={
                                    prepareOfferSmartDocumentRename
                                }
                                proposalDocumentDefault={
                                    proposalDocumentDefault
                                }
                                proposalId={proposalId}
                                selectedDocumentId={selectedDocumentId}
                            />
                        }
                    >
                        <ListItemButton
                            onClick={() =>
                                handleClickDocumentItem({
                                    id: document.id,
                                    name: document.name,
                                    type: types.SMART_DOCUMENTS_TYPE,
                                })
                            }
                            selected={document.id === selectedDocumentId}
                            sx={{
                                borderRadius: '8px',
                                backgroundColor:
                                    document.id === selectedDocumentId
                                        ? isDarkMode
                                            ? '#000'
                                            : 'rgba(31, 60, 83, 0.13)'
                                        : 'transparent',
                                '&.Mui-selected': {
                                    backgroundColor: isDarkMode
                                        ? '#000'
                                        : 'rgba(31, 60, 83, 0.13) !important',
                                },
                            }}
                        >
                            <ListItemText
                                primary={document.name}
                                sx={{
                                    '.MuiListItemText-primary': {
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>

            <Grid container spacing={0} mt={1}>
                <Grid size={{ xs: 'grow' }} sx={{ textAlign: 'center' }}>
                    <Button
                        disabled={disabled}
                        fullWidth
                        endIcon={<AddIcon />}
                        onClick={handleOnClickCreate}
                        type="button"
                        variant="outlined"
                    >
                        {t('Add')}
                    </Button>
                </Grid>
            </Grid>
        </SideMenuCard>
    );
};

const mapDispatchToProps = (dispatch) => ({
    prepareOfferSmartDocumentCreate: ({ projectId, proposalId } = {}) =>
        dispatch(
            actions.prepareOfferSmartDocumentCreate({ projectId, proposalId }),
        ),
    prepareOfferSmartDocumentDelete: (
        handleClickDocumentItem,
        proposalDocumentDefault,
        proposalId,
        smartDocumentId,
    ) =>
        dispatch(
            actions.prepareOfferSmartDocumentDelete(
                handleClickDocumentItem,
                proposalDocumentDefault,
                proposalId,
                smartDocumentId,
            ),
        ),
    prepareOfferSmartDocumentDuplicate: (smartDocumentName, smartDocumentId) =>
        dispatch(
            actions.prepareOfferSmartDocumentDuplicate(
                smartDocumentName,
                smartDocumentId,
            ),
        ),
    prepareOfferSmartDocumentRename: (smartDocumentName, smartDocumentId) =>
        dispatch(
            actions.prepareOfferSmartDocumentRename(
                smartDocumentName,
                smartDocumentId,
            ),
        ),
});

SmartDocumentsCard.propTypes = {
    canModify: PropTypes.bool,
    handleClickDocumentItem: PropTypes.func,
    handleClickEditButton: PropTypes.func,
    handleClickPrepareRegenerateSmartDocumentTemplate: PropTypes.func,
    isApproved: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isHidden: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    offerSmartDocuments: PropTypes.array,
    prepareOfferSmartDocumentCreate: PropTypes.func,
    prepareOfferSmartDocumentDelete: PropTypes.func,
    prepareOfferSmartDocumentDuplicate: PropTypes.func,
    prepareOfferSmartDocumentRename: PropTypes.func,
    projectId: PropTypes.string,
    proposalDocumentDefault: PropTypes.object,
    proposalId: PropTypes.string,
    selectedDocumentId: PropTypes.string,
};

export default connect(null, mapDispatchToProps)(SmartDocumentsCard);
