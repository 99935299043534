import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    Container as MaterialContainer,
    Grid,
    UiDialog as Dialog,
} from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import * as customColumnsSelectors from 'common/modules/customColumns/selectors';
import MasterButton from 'common/modules/masterButton';

import * as trackingsActions from '../trackings/actions';
import * as trackingsSelectors from '../trackings/selectors';

import * as actions from './actions';
import ListItems from './components/ListItems';
import TrackingForm from './components/TrackingForm';
import * as selectors from './selectors';

const Container = ({
    canModify,
    contactsTrackings,
    fetchUsers,
    filterTrackings,
    handleClickBulkItems,
    handleClickUpdateTracking,
    handleCloseModalTracking,
    isFetching,
    isFetchingTableSettings,
    isOpenModalTracking,
    paginationItems,
    tableSettings,
    users,
}) => {
    const { t } = useTranslation();
    const [filterData, setFilterData] = useState({
        completed: 'False',
        orderBy: 'notification_at',
        page: 0,
        pageSize: 25,
        sortBy: 'asc',
        userId: '',
    });
    useEffect(() => {
        filterTrackings(filterData);
        fetchUsers();
    }, []);

    return (
        <MaterialContainer maxWidth={false} sx={{ mb: 2 }}>
            <HeaderContainer>
                <Grid size={{ md: 12, xs: 18 }}>
                    <TitleIcon title={t('Follow-up', { count: 2 })} />
                </Grid>
                <Grid size={{ md: 6, xs: 19 }} textAlign="right">
                    <MasterButton.Container
                        defaultButton="create-tracking"
                        masterButtonMode={false}
                        onSucccess={() => filterTrackings(filterData)}
                    />
                </Grid>
            </HeaderContainer>
            <Grid container>
                <Grid size={18}>
                    <ListItems
                        canModify={canModify}
                        filterData={filterData}
                        filterItems={filterTrackings}
                        handleClickBulkItems={handleClickBulkItems}
                        handleClickUpdateTracking={handleClickUpdateTracking}
                        isFetching={isFetching || isFetchingTableSettings}
                        isOpenModalTracking={isOpenModalTracking}
                        items={contactsTrackings}
                        paginationData={paginationItems}
                        setFilterData={setFilterData}
                        tableSettings={tableSettings}
                        users={users}
                    />
                </Grid>
            </Grid>
            <Dialog
                onClose={() => handleCloseModalTracking()}
                open={isOpenModalTracking}
                size="sm"
                title={t('Remember again')}
            >
                <TrackingForm filterData={filterData} />
            </Dialog>
        </MaterialContainer>
    );
};

Container.propTypes = {
    canModify: PropTypes.bool,
    contactsTrackings: PropTypes.array,
    fetchUsers: PropTypes.func,
    filterTrackings: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickUpdateTracking: PropTypes.func,
    handleCloseModalTracking: PropTypes.func,
    isFetching: PropTypes.bool,
    isFetchingTableSettings: PropTypes.bool,
    isOpenModalTracking: PropTypes.bool,
    paginationItems: PropTypes.object,
    tableSettings: PropTypes.array,
    users: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    contactsTrackings: selectors.getContactsTrackingData,
    isFetching: selectors.getIsFetchingContactsTrackings,
    isFetchingTableSettings: customColumnsSelectors.getIsFetchingTableSettings,
    isOpenModalTracking: selectors.getIsOpenModalTracking,
    paginationItems: selectors.getContactsTrackingPagination,
    tableSettings: customColumnsSelectors.getTableSettingsData,
    users: trackingsSelectors.getUsersData,
});

const mapDispatchToProps = (dispatch) => ({
    filterTrackings: (filterData) =>
        dispatch(actions.filterTrackings(filterData)),
    fetchUsers: () => dispatch(trackingsActions.fetchUsers()),
    handleClickBulkItems: (ids, callback, value) =>
        dispatch(actions.bulkItems(ids, callback, value)),
    handleClickUpdateTracking: (ids, callback) =>
        dispatch(actions.prepareUpdateTracking(ids, callback)),
    handleCloseModalTracking: () => dispatch(actions.closeModalTracking()),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.TRACKING_CONTACTS_PERMISSION),
)(Container);
