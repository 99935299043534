import i18next from 'i18next';
import get from 'lodash/get';

import { createSegment } from 'common/api/v1/segmentsLayout';
import {
    solarCharacterization,
    SEGMENT_COLOR,
    SEGMENT_STROKE_COLOR,
} from 'common/components/maps/constants';
import {
    getPolygonCoordinates,
    getSegmentArea,
    getNewSolarModules,
    showReponseErrorsAsAlert,
} from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    CREATE_SEGMENT,
    CREATE_SEGMENT_FAILURE,
    CREATE_SEGMENT_SUCCESS,
    UPDATE_SOLAR_MODULES_DESIGN_NUMBER,
} from '../actionTypes';
import { POLYGON_MAP, POLYGON_OBSTACLE, POLYGON_SEGMENT } from '../constants';
import {
    countPanelPerSegmentsById,
    checkCallback,
    checkCallbackError,
    getModulesGroups,
    getNewModules,
    getObstaceZIndex,
    getQuantityEnabledModules,
} from '../helpers';
import { panelLayoutV2Actions } from '../reducer';
import * as selectors from '../selectors';

import fetchOfferPanel from './fetchOfferPanel';
import handleCollition from './handleCollition';
import prepareEditSegment from './prepareEditSegment';
import selectSegment from './selectSegment';
import updateSegments from './updateSegments';

const buildSegmentPayload = ({ getState, config }) => {
    const state = getState();
    const offerPanels = selectors.getOfferPanelsData(state);
    const parentFieldSegment = selectors.getParentFieldSegmentData(state);
    const {
        location: { latitude },
    } = parentFieldSegment;
    const segments = selectors.getSegmentsData(state);
    const segmentFillMethod = selectors.getSegmentFillMethod(state);

    const { commercialOfferId, google, mapRef, polygon } = config;
    const mapValue = mapRef.current.self();

    const countSegments = segments.filter(
        (segment) => segment.type === POLYGON_SEGMENT,
    ).length;

    const name = `${i18next.t('Segment')} ${countSegments + 1}`;

    let defaultOfferPanelValues = {
        color: SEGMENT_COLOR,
        id: null,
        stroke_color: SEGMENT_STROKE_COLOR,
    };
    let module = null;

    if (segmentFillMethod === false) {
        const offerPanelFiltered = offerPanels.find((item) => {
            const panelPerSegmentsById = countPanelPerSegmentsById(
                item.id,
                segments,
            );
            return panelPerSegmentsById < item.quantity;
        });

        if (offerPanelFiltered) {
            module = offerPanelFiltered;
            defaultOfferPanelValues = {
                ...defaultOfferPanelValues,
                ...module,
                assigned: countPanelPerSegmentsById(module.id, segments),
            };
        }
    } else {
        if (offerPanels[0]) {
            module = offerPanels[0];
            defaultOfferPanelValues = {
                ...defaultOfferPanelValues,
                ...module,
                assigned: 0,
                quantity: Infinity,
            };
        }
    }

    const { assigned, color, id, quantity, stroke_color } =
        defaultOfferPanelValues;

    const obstacles = segments.filter(
        (segment) => segment.type === POLYGON_OBSTACLE,
    );

    const { boxes, grouped } = getNewModules({
        data: {
            ...solarCharacterization,
            assigned,
            polygon: JSON.stringify(polygon),
            quantity,
        },
        google,
        map: mapValue,
        module,
        obstacles,
    });

    const grouped_cells = grouped;
    const modules_groups = getModulesGroups(grouped);

    const solar_modules = boxes.map((module) => ({
        cell: module.cell,
        col: module.col,
        group: module.group,
        is_enable: module.enable,
        row: module.row,
        solar_module_points: JSON.stringify(
            module.paths.map((point) => ({
                x: point[1],
                y: point[0],
            })),
        ),
    }));

    const field_segments = [
        {
            name,
            polygon: polygon.map((point) => ({
                x_coordinate: point[1],
                y_coordinate: point[0],
            })),
            solar_modules,
        },
    ];

    const azimuth = latitude > 0 ? 180 : 0;

    const payload = {
        commercial_offer: commercialOfferId,
        field_segments,
        grouped_cells,
        height: 0,
        modules_groups,
        name,
        parent_field_segment: parentFieldSegment.id,
        polygon,
        segment_color: color,
        segment_stroke_color: stroke_color,
        solar_module: id,
        total_area: getSegmentArea(google, polygon),
        type: POLYGON_SEGMENT,
        zIndex: 0,
        zoom_level: 21,
        ...solarCharacterization,
        azimuth,
    };

    return payload;
};

const buildObstaclePayload = ({ getState, config }) => {
    const state = getState();
    const parentFieldSegment = selectors.getParentFieldSegmentData(state);
    const segments = selectors.getSegmentsData(state);

    const { commercialOfferId, google, polygon } = config;

    const countSegments = segments.filter(
        (segment) => segment.type === POLYGON_OBSTACLE,
    ).length;

    const zIndex = getObstaceZIndex({
        google,
        polygon,
        segments,
        selectedSegment: { id: null },
    });

    const name = `${i18next.t('Obstacle')} ${countSegments + 1}`;

    const field_segments = [
        {
            name,
            polygon: polygon.map((point) => ({
                x_coordinate: point[1],
                y_coordinate: point[0],
            })),
        },
    ];

    const payload = {
        commercial_offer: commercialOfferId,
        field_segments,
        height: 0,
        name,
        parent_field_segment: parentFieldSegment.id,
        safe_zone: 0,
        segment_color: '#8c8c8c',
        segment_stroke_color: '#11EFD9',
        total_area: getSegmentArea(google, polygon),
        type: POLYGON_OBSTACLE,
        zindex: zIndex,
        ...solarCharacterization,
    };

    return payload;
};

const buildImagePayload = ({ getState, config }) => {
    const state = getState();
    const parentFieldSegment = selectors.getParentFieldSegmentData(state);
    const segments = selectors.getSegmentsData(state);

    const { commercialOfferId, google, polygon } = config;

    const countSegments = segments.filter(
        (segment) => segment.type === POLYGON_MAP,
    ).length;

    const name = `${i18next.t('Image')} ${countSegments + 1}`;

    const payload = {
        commercial_offer: commercialOfferId,
        field_segments: [
            {
                name,
                polygon: polygon.map((point) => ({
                    x_coordinate: point[1],
                    y_coordinate: point[0],
                })),
                solar_modules: [],
            },
        ],
        height: 0,
        name,
        opacity: 100,
        parent_field_segment: parentFieldSegment.id,
        rotate: 0,
        segment_color: '#8c8c8c',
        segment_stroke_color: '#11EFD9',
        total_area: getSegmentArea(google, polygon),
        type: POLYGON_MAP,
        ...solarCharacterization,
    };

    return payload;
};

const handleSegmentResponse = ({
    config,
    dispatch,
    parentFieldSegment,
    payload,
    response,
    segments,
}) => {
    const { commercialOfferId } = config;
    const segmentData = get(response, 'data.data', {});
    const { field_segment_points, id, solar_modules_number, panel_modules } =
        segmentData;
    const newPolygon = getPolygonCoordinates(field_segment_points);
    const solarModules = getNewSolarModules(panel_modules);
    const quantityEnabledModules = getQuantityEnabledModules(solarModules);
    const newSegment = {
        ...payload,
        id,
        polygon: newPolygon,
        quantity_enabled_modules: quantityEnabledModules,
        solar_modules: solarModules,
        solar_modules_number,
    };
    dispatch(panelLayoutV2Actions[CREATE_SEGMENT_SUCCESS](newSegment));

    const newValuesSegments = [...segments, newSegment];
    const updatedSegments = newValuesSegments.map((segment) => ({
        ...segment,
        open: newSegment.id === segment.id,
    }));

    dispatch(updateSegments(updatedSegments));

    dispatch(selectSegment(newSegment));

    dispatch(
        panelLayoutV2Actions[UPDATE_SOLAR_MODULES_DESIGN_NUMBER](
            parentFieldSegment.solarModulesDesignNumber + solar_modules_number,
        ),
    );
    dispatch(fetchOfferPanel(commercialOfferId));
    dispatch(prepareEditSegment(newSegment.id));
};

const handleObstacleResponse = ({
    config,
    dispatch,
    payload,
    response,
    segments,
}) => {
    const { commercialOfferId, google, mapRef } = config;
    const mapValue = mapRef.current.self();
    const segmentData = get(response, 'data.data', {});
    const { field_segment_points, id } = segmentData;
    const newPolygon = getPolygonCoordinates(field_segment_points);
    const newSegment = {
        ...payload,
        id,
        polygon: newPolygon,
        prev_location: newPolygon,
    };

    const newValuesSegments = [...segments, newSegment];
    const updatedSegments = newValuesSegments.map((segment) => ({
        ...segment,
        open: newSegment.id === segment.id,
    }));
    dispatch(updateSegments(updatedSegments));

    dispatch(selectSegment(newSegment));

    dispatch(prepareEditSegment(newSegment.id));

    dispatch(panelLayoutV2Actions[CREATE_SEGMENT_SUCCESS](newSegment));

    dispatch(
        handleCollition({
            callback: (id, modules) => checkCallback(id, modules, mapRef),
            callbackError: (id, values) => checkCallbackError(id, values),
            commercialOfferId,
            google,
            mapValue,
            obstacle: newSegment,
        }),
    );
};

const handleImageResponse = ({ dispatch, payload, response, segments }) => {
    const segmentData = get(response, 'data.data', {});
    const { field_segment_points, id } = segmentData;
    const newPolygon = getPolygonCoordinates(field_segment_points);
    const newSegment = {
        ...payload,
        id,
        polygon: newPolygon,
    };
    dispatch(panelLayoutV2Actions[CREATE_SEGMENT_SUCCESS](newSegment));

    const newValuesSegments = [...segments, newSegment];
    const updatedSegments = newValuesSegments.map((segment) => ({
        ...segment,
        open: newSegment.id === segment.id,
    }));
    dispatch(updateSegments(updatedSegments));

    dispatch(selectSegment(newSegment));

    dispatch(prepareEditSegment(newSegment.id));
};

export default (config) => (dispatch, getState) => {
    const { drawingType } = config;
    const state = getState();
    const parentFieldSegment = selectors.getParentFieldSegmentData(state);
    const segments = selectors.getSegmentsData(state);

    let payload = null;

    if (drawingType === POLYGON_SEGMENT) {
        payload = buildSegmentPayload({ config, getState });
    }

    if (drawingType === POLYGON_OBSTACLE) {
        payload = buildObstaclePayload({ config, getState });
    }

    if (drawingType === POLYGON_MAP) {
        payload = buildImagePayload({ config, getState });
    }

    if (!payload) return;

    dispatch(panelLayoutV2Actions[CREATE_SEGMENT]());

    createSegment(payload)
        .then((response) => {
            if (drawingType === POLYGON_SEGMENT) {
                handleSegmentResponse({
                    config,
                    dispatch,
                    parentFieldSegment,
                    payload,
                    response,
                    segments,
                });
            }

            if (drawingType === POLYGON_OBSTACLE) {
                handleObstacleResponse({
                    config,
                    dispatch,
                    payload,
                    response,
                    segments,
                });
            }

            if (drawingType === POLYGON_MAP) {
                handleImageResponse({ dispatch, payload, response, segments });
            }

            showToast({
                body: i18next.t('The segment has been saved'),
                position: 'bottom-center',
            });
        })
        .catch((error) => {
            dispatch(
                panelLayoutV2Actions[CREATE_SEGMENT_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error.response);
        });
};
