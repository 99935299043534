import React from 'react';

import LogoutIcon from '@mui/icons-material/Logout';
import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Avatar, Box, IconButton, Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';
import { stringAvatar } from 'common/utils/helpers';
import { logoutActions } from 'common/utils/helpers/session';

import whiteLogo from 'resources/isotypeSunwise3.svg';
import logo from 'resources/logotipoSunwiseAzul.svg';

import * as profileSelectors from '../../profile/selectors';
import { AVATAR_SIZE } from '../constants';

const StyledHeader = styled(Box)`
    align-items: center;
    box-shadow: 0px 4px 37px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    padding: 30px;
    position: relative;
    z-index: 100;
`;

const Header = ({ profileValues, selectedTheme }) => {
    const breakpoint = useBreakpoint();
    const profileName = `${get(profileValues, 'first_name', '')} ${get(
        profileValues,
        'last_name',
        '',
    )}`;

    return (
        <StyledHeader>
            <img
                alt="Sunwise"
                src={selectedTheme === 'dark' ? whiteLogo : logo}
                width="140"
            />
            <Box display="flex" gap={1} alignItems="center">
                {breakpoint !== 'xs' && (
                    <Typography variant="body1">{profileName}</Typography>
                )}
                {get(profileValues, 'profile_image', '') ? (
                    <Avatar
                        alt={profileName}
                        src={get(profileValues, 'profile_image', '')}
                        sx={{
                            height: AVATAR_SIZE,
                            width: AVATAR_SIZE,
                        }}
                    />
                ) : (
                    <Avatar
                        {...stringAvatar(profileName)}
                        sx={{
                            fontSize: '16px',
                            height: AVATAR_SIZE,
                            width: AVATAR_SIZE,
                        }}
                    />
                )}
                <IconButton color="secondary" onClick={logoutActions}>
                    <LogoutIcon />
                </IconButton>
            </Box>
        </StyledHeader>
    );
};

Header.propTypes = {
    profileValues: PropTypes.object,
    selectedTheme: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    profileValues: profileSelectors.getCurrentValues,
});

export default connect(mapStateToProps, null)(Header);
