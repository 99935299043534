import React from 'react';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {
    Alert,
    Box,
    Card,
    Tooltip,
    Typography,
} from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ReportResolution from '../../reportList/components/ReportResolution';
import { REPORT_STATUS_KEYS } from '../../reportList/constants';
import { getReportPeriods } from '../helpers';

import ElectricBills from './ElectricBills';
import ReportName from './ReportName';

const ReportCard = ({ localeDateFormat, onClick, report }) => {
    const { t } = useTranslation();

    const {
        complements: { status } = {},
        name,
        project: { name: projectName } = {},
        report_periods,
        resolution,
    } = report || {};

    const periods = report_periods?.[0]?.group_periods ?? [];

    const periodDates = getReportPeriods(periods, localeDateFormat);

    return (
        <Card onClick={onClick} sx={{ cursor: 'pointer' }}>
            <Card.Body>
                <ReportName name={name} status={status} variant="subtitle1" />

                <Box mb={1}>{`${t('Project')}: ${projectName}`}</Box>

                <Box display="flex" gap={2}>
                    <ReportResolution resolution={resolution} />

                    <Tooltip
                        title={
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={0.5}
                            >
                                <Typography variant="subtitle2">
                                    {t('Period', { count: 2 })}:
                                </Typography>

                                {periodDates.map((date, index) => (
                                    <Typography
                                        fontWeight="500"
                                        key={index}
                                        variant="caption"
                                    >
                                        {date}
                                    </Typography>
                                ))}
                            </Box>
                        }
                    >
                        <CalendarTodayIcon color="primary" fontSize="16px" />
                    </Tooltip>
                </Box>
            </Card.Body>

            <Card.Actions
                sx={{
                    backgroundColor: (theme) =>
                        theme?.palette?.mode === 'dark'
                            ? 'rgb(40, 43, 48)'
                            : 'rgba(238, 244, 250, 0.35)',
                    flexDirection: 'column',
                    px: 2,
                }}
            >
                {status === REPORT_STATUS_KEYS.WAITING_FOR_LISA && (
                    <Alert
                        icon={<div />}
                        severity="warning"
                        sx={{ mb: 1, mt: 1 }}
                    >
                        {t(
                            'This report is waiting for {{name}} to be generated',
                            { name: 'LISA' },
                        )}
                    </Alert>
                )}

                <ElectricBills
                    localeDateFormat={localeDateFormat}
                    periods={periods}
                />
            </Card.Actions>
        </Card>
    );
};

ReportCard.propTypes = {
    localeDateFormat: PropTypes.string,
    onClick: PropTypes.func,
    report: PropTypes.object,
};

export default ReportCard;
