import { getDeviceBasedConsumptions } from 'common/api/v1/deviceBasedConsumptions';

import {
    FETCH_CONSUMPTION,
    FETCH_CONSUMPTION_FAILURE,
    FETCH_CONSUMPTION_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (projectId) => (dispatch) => {
    if (!projectId) return;

    dispatch(actions[FETCH_CONSUMPTION]());

    return getDeviceBasedConsumptions(projectId)
        .then((response) => {
            const data = response?.data;
            dispatch(actions[FETCH_CONSUMPTION_SUCCESS](data));
            return data;
        })
        .catch((error) => dispatch(actions[FETCH_CONSUMPTION_FAILURE](error)));
};
