import React, { useEffect, useState } from 'react';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SpeedIcon from '@mui/icons-material/Speed';
import { ToggleButton, useTheme } from '@mui/material';
import {
    Skeleton,
    ToggleButtonGroup,
    Typography,
} from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ChartJs from 'common/components/ChartJs';
import { IconPanel } from 'common/components/icons';
import { getCurrencyIso, getCurrencySymbol } from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import {
    getChartSettings,
    getPaymentChartSettings,
} from '../../../reportPreview/helpers';
import { getChartsInfo } from '../../helpers';
import * as selectors from '../../selectors';

const CHARTS = [
    { Icon: SpeedIcon, label: 'Consumption', value: 'consumption' },
    {
        Icon: IconPanel,
        label: 'Generation',
        value: 'generation',
    },
    { Icon: AttachMoneyIcon, label: 'Price', value: 'price' },
];

const Charts = ({
    currency,
    isLoadingHistoricals,
    periodsData,
    reportPeriods,
}) => {
    const [chart, setChart] = useState(CHARTS[0].value);
    const [chartsInfo, setChartsInfo] = useState({
        categories: [],
        consumption: { series: [] },
        energySettings: {},
        generation: { series: [] },
        price: { categories: [], series: [], settings: {} },
    });

    const { t } = useTranslation();
    const theme = useTheme();

    const currencyIso = getCurrencyIso(currency);
    const currencySymbol = getCurrencySymbol(currency);
    const currencyLocale = getCountryCurrencyLocale();

    useEffect(() => {
        if (!reportPeriods || !periodsData) return;

        const {
            categories,
            consumptionSeries,
            energyDates,
            generationSeries,
            paymentPeriods,
            paymentSeries,
        } = getChartsInfo({
            periodsData: periodsData?.periods_data,
            proposalInfoIsBimonthly: periodsData?.is_bimonthly?.is_bimonthly,
            reportPeriods,
        });

        const energySettings = getChartSettings({
            currencyIso,
            currencyLocale,
            currencySymbol,
            energyDates,
        });

        const paymentConfig = getPaymentChartSettings({
            categories,
            currencyIso,
            currencyLocale,
            paymentPeriods,
            paymentSeries,
        });

        setChartsInfo({
            categories,
            consumption: { series: consumptionSeries },
            energySettings,
            generation: { series: generationSeries },
            price: {
                categories: paymentConfig.categories,
                series: paymentConfig.series,
                settings: paymentConfig.settings,
            },
        });
    }, [reportPeriods, periodsData]);

    const handleChange = (_, newChart) => {
        if (newChart !== null) setChart(newChart);
    };

    const chartData = chartsInfo[chart];

    return (
        <>
            <ToggleButtonGroup
                exclusive
                fullWidth
                onChange={handleChange}
                size="small"
                sx={{ mb: 2 }}
                value={chart}
            >
                {CHARTS.map(({ Icon, value }) => (
                    <ToggleButton key={value} value={value}>
                        <Icon
                            color={
                                theme.palette.mode === 'dark'
                                    ? '#fff'
                                    : '#637381'
                            }
                        />
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>

            {isLoadingHistoricals ? (
                <Skeleton variant="rect" height={400} />
            ) : (
                <>
                    <Typography variant="subtitle2">
                        {t('Total consumption')}
                    </Typography>

                    <ChartJs
                        data={{
                            datasets: chartData?.series || [],
                            labels:
                                chartData?.categories ||
                                chartsInfo.categories ||
                                [],
                        }}
                        key={chart}
                        height="300px"
                        options={
                            chartData?.settings ||
                            chartsInfo.energySettings ||
                            {}
                        }
                    />
                </>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    periodsData: selectors.getReportHistoricalsData,
    isLoadingHistoricals: selectors.getReportHistoricalsLoading,
});

Charts.propTypes = {
    currency: PropTypes.object,
    isLoadingHistoricals: PropTypes.bool,
    periodsData: PropTypes.object,
    reportPeriods: PropTypes.array,
};

export default connect(mapStateToProps)(Charts);
