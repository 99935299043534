import React from 'react';

import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useBreakpoint } from 'common/hooks';

import BranchOfficeFormatter from './BranchOfficeFormatter';
import DateFormatter from './DateFormatter';
import NameFormatter from './NameFormatter';
import ProjectFormatter from './ProjectFormatter';

const Columns = ({ handleClickView, hiddenColumns, orders }) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const columnName = {
        field: 'name',
        headerName: t('Name'),
        hide: hiddenColumns.includes('name'),
        minWidth: 250,
        order: orders?.name,
        renderCell: (params) => {
            const { row } = params;
            return (
                <NameFormatter handleClickView={handleClickView} row={row} />
            );
        },
    };

    let columns = [
        columnName,
        {
            field: 'project',
            headerName: t('Project'),
            hide: hiddenColumns.includes('project'),
            minWidth: 250,
            order: orders?.project,
            renderCell: ProjectFormatter,
        },
        {
            field: 'branch_office',
            headerName: t('Branch office'),
            hide: hiddenColumns.includes('branch_office'),
            minWidth: 180,
            order: orders?.branch_office,
            renderCell: BranchOfficeFormatter,
        },
        {
            field: 'created_at',
            headerName: t('Date'),
            hide: hiddenColumns.includes('created_at'),
            minWidth: 120,
            order: orders?.created_at,
            renderCell: DateFormatter,
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) columns = [columnName];

    return orderBy(columns, ['order'], ['asc']);
};

Columns.propTypes = {
    handleClickView: PropTypes.func,
    hiddenColumns: PropTypes.array,
    orders: PropTypes.object,
};

export default Columns;
