import React, { useContext, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Container as MaterialContainer } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import LoadingContext from 'common/modules/loadingView/Context';
import RestrictedAccessLanding from 'common/modules/restrictedAccessLanding/Container';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import * as actions from './actions';
import IrradiationCard from './components/IrradiationCard';
import OwnSourceCard from './components/OwnSourceCard';
import SourceCard from './components/SourceCard';
import { getAvg } from './helpers';
import * as selectors from './selectors';
import validate from './validate';

const FormContainer = ({
    availableSources,
    canDelete,
    canModify,
    canView,
    defaultSourceData,
    deleteGenerationProfile,
    errors,
    fetchDefaultSource,
    fetchGenerationProfile,
    fetchMunicipalities,
    fetchIrradiationByStateAndSources,
    fetchPoliticalDivisions,
    fetchSources,
    generationProfileData,
    handleClickDelete,
    handleClickSave,
    initializeGenerationProfileForm,
    initialValues,
    initValues,
    isOpenModal,
    isSavingItem,
    ownSourceData,
    politicalDivisionsForSelect,
    politicalDivisions2ForSelect,
    selectedSourceName,
    saveSource,
    series,
    sourcesForSelect,
    toggleModal,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);

    const { control, getValues, handleSubmit, reset, setValue } = useForm({
        defaultValues: initialValues,
        resolver: validate,
    });
    const source = useWatch({ control, name: 'source' });
    const getPoliticalDivision = () => getValues('political_division');
    const getPoliticalDivision2 = () => getValues('political_division2');

    useEffect(() => {
        fetchPoliticalDivisions('dp1', setValue);
        fetchSources();
        fetchDefaultSource(setValue);
    }, []);

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isSavingItem) loadingContext.openLoading(t('Saving').concat('...'));
        else loadingContext.closeLoading();
    }, [isSavingItem]);

    const handleSelectPoliticalDivision = ({ target: { value } }) => {
        if (value !== t('State')) {
            fetchMunicipalities(value, getPoliticalDivision(), setValue);
            fetchGenerationProfile(value);
        }
    };
    const handleSelectPoliticalDivision2 = ({ target: { value } }) => {
        if (value !== t('City'))
            fetchIrradiationByStateAndSources(
                value,
                getPoliticalDivision(),
                setValue,
            );
    };

    if (!canView) return null;

    return (
        <RestrictedAccessLanding
            addonName="Solar-Generation"
            url="https://somos.sunwise.io/sunwise-sales"
        >
            <MaterialContainer maxWidth={false}>
                <HeaderContainer>
                    <TitleIcon title={t('Solar generation')} />
                </HeaderContainer>

                <Box>
                    <SourceCard
                        canModify={canModify}
                        control={control}
                        saveSource={saveSource}
                        sourcesForSelect={sourcesForSelect}
                    />

                    <IrradiationCard
                        canDelete={canDelete}
                        canModify={canModify}
                        availableSources={availableSources}
                        control={control}
                        countryCurrencyLocale={getCountryCurrencyLocale()}
                        defaultSourceData={defaultSourceData(source)}
                        deleteGenerationProfile={deleteGenerationProfile}
                        generationProfile={generationProfileData}
                        getAvg={getAvg}
                        getValues={getValues}
                        handleSelectPoliticalDivision={
                            handleSelectPoliticalDivision
                        }
                        handleSelectPoliticalDivision2={
                            handleSelectPoliticalDivision2
                        }
                        initializeGenerationProfileForm={
                            initializeGenerationProfileForm
                        }
                        isPoliticalDivision={
                            getPoliticalDivision() !== t('State')
                        }
                        isPoliticalDivision2={
                            getPoliticalDivision2() !== t('City')
                        }
                        politicalDivisionsForSelect={
                            politicalDivisionsForSelect
                        }
                        politicalDivisions2ForSelect={
                            politicalDivisions2ForSelect
                        }
                        selectedSourceName={selectedSourceName(source)}
                        series={series}
                    />

                    {source &&
                        getPoliticalDivision() !== t('State') &&
                        getPoliticalDivision2() !== t('City') && (
                            <OwnSourceCard
                                availableSources={sourcesForSelect}
                                canModify={canModify}
                                canDelete={canDelete}
                                control={control}
                                countryCurrencyLocale={getCountryCurrencyLocale()}
                                defaultSourceData={defaultSourceData(source)}
                                errors={errors}
                                getAvg={getAvg}
                                getValues={getValues}
                                handleClickDelete={handleClickDelete}
                                handleClickSave={handleClickSave}
                                handleSubmit={handleSubmit}
                                initValues={initValues}
                                isOpenModal={isOpenModal}
                                source={ownSourceData}
                                toggleModal={toggleModal}
                                setValue={setValue}
                            />
                        )}
                </Box>
            </MaterialContainer>
        </RestrictedAccessLanding>
    );
};

const mapStateToProps = createStructuredSelector({
    availableSources: selectors.getAvailableSources,
    defaultSourceData: selectors.getDefaultSourceData(),
    errors: selectors.getSaveItemErrors,
    generationProfileData: selectors.getGenerationProfileData,
    initialValues: selectors.getInitialValues,
    isOpenModal: selectors.getIsOpenModal,
    isSavingItem: selectors.getIsSavingItem,
    ownSourceData: selectors.getOwnSourceData,
    politicalDivisionsForSelect: selectors.getPoliticalDivisionsForSelect,
    politicalDivisions2ForSelect: selectors.getPoliticalDivisions2ForSelect,
    selectedSourceName: selectors.getSelectedSourceName(),
    series: selectors.getSeries,
    sourcesForSelect: selectors.getSourcesForSelect,
});

const mapDispatchToProps = (dispatch) => ({
    deleteGenerationProfile: (id, politicalDivision, politicalDivisionName) =>
        dispatch(
            actions.prepareDeleteGenerationProfile(
                id,
                politicalDivision,
                politicalDivisionName,
            ),
        ),
    fetchDefaultSource: (setValue) =>
        dispatch(actions.fetchDefaultSource(setValue)),
    fetchGenerationProfile: (politicalDivision) =>
        dispatch(actions.fetchGenerationProfile(politicalDivision)),
    fetchIrradiationByStateAndSources: (id, politicalDivision, setValue) =>
        dispatch(
            actions.fetchIrradiationByStateAndSources(
                id,
                politicalDivision,
                setValue,
            ),
        ),
    fetchMunicipalities: (parent, politicalDivision, setValue) =>
        dispatch(
            actions.fetchMunicipalities(parent, politicalDivision, setValue),
        ),
    fetchPoliticalDivisions: (str, setValue) =>
        dispatch(actions.fetchPoliticalDivisions(str, setValue)),
    fetchSources: () => dispatch(actions.fetchSources()),
    handleClickDelete: (formValues, setValue) =>
        dispatch(actions.prepareDelete(formValues, setValue)),
    handleClickSave: (values, setValue) =>
        dispatch(actions.prepareSave(values, setValue)),
    initializeGenerationProfileForm: (values) =>
        dispatch(actions.initializeGenerationProfileForm(values)),
    initValues: (formValues, source, isNewRecord) =>
        dispatch(actions.initValues(formValues, source, isNewRecord)),
    saveSource: (id) => dispatch(actions.saveSource(id)),
    toggleModal: (value) => dispatch(actions.toggleModal(value)),
});

FormContainer.propTypes = {
    availableSources: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    defaultSourceData: PropTypes.func,
    deleteGenerationProfile: PropTypes.func,
    errors: PropTypes.array,
    fetchDefaultSource: PropTypes.func,
    fetchGenerationProfile: PropTypes.func,
    fetchMunicipalities: PropTypes.func,
    fetchIrradiationByStateAndSources: PropTypes.func,
    fetchPoliticalDivisions: PropTypes.func,
    fetchSources: PropTypes.func,
    generationProfileData: PropTypes.object,
    handleClickDelete: PropTypes.func,
    handleClickSave: PropTypes.func,
    initializeGenerationProfileForm: PropTypes.func,
    initialValues: PropTypes.object,
    initValues: PropTypes.func,
    isOpenModal: PropTypes.bool,
    isSavingItem: PropTypes.bool,
    ownSourceData: PropTypes.array,
    politicalDivisionsForSelect: PropTypes.array,
    politicalDivisions2ForSelect: PropTypes.array,
    selectedSourceName: PropTypes.func,
    saveSource: PropTypes.func,
    series: PropTypes.array,
    sourcesForSelect: PropTypes.array,
    toggleModal: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.SOLAR_GENERATION_PERMISSION),
)(FormContainer);
