import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Grid } from 'sunwise-ui';

import { ReactHookFormInput } from 'common/components/form/bootstrap';
import ShowErrors from 'common/components/ShowErrors';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

const FormContainer = ({
    errors,
    initialValues,
    integrationType = null,
    isSavingIntegration,
    save,
    onHide,
}) => {
    const { t } = useTranslation();

    const { control, handleSubmit, reset } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const handleOnSubmit = (values) =>
        save({ ...values, integrationType }, () => {
            onHide();
        });

    const handleClickClose = () => {
        if (onHide) onHide();
    };

    useEffect(() => {}, []);

    return (
        <form onSubmit={handleSubmit(handleOnSubmit)}>
            <ReactHookFormInput
                control={control}
                label={t('Name').concat(' *')}
                name="name"
            />

            <ShowErrors errors={errors} />

            <Grid container mt={1}>
                <Grid
                    size={{ xs: 'grow' }}
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: { xs: 'center', md: 'right' },
                    }}
                >
                    <Button
                        color="secondary"
                        onClick={handleClickClose}
                        sx={{
                            width: { xs: '100%', md: 'auto' },
                            order: { xs: 2, md: 1 },
                            mr: { xs: 0, md: 2 },
                        }}
                        type="button"
                        variant="text"
                    >
                        {t('Cancel')}
                    </Button>{' '}
                    <Button
                        disabled={isSavingIntegration}
                        sx={{
                            width: { xs: '100%', md: 'auto' },
                            order: { xs: 1, md: 2 },
                            mb: { xs: 2, md: 0 },
                        }}
                        type="submit"
                        variant="outlined"
                    >
                        {t('Save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

FormContainer.propTypes = {
    errors: PropTypes.array,
    initialValues: PropTypes.object,
    integrationType: PropTypes.string,
    isSavingIntegration: PropTypes.bool,
    save: PropTypes.func,
    onHide: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValuesForm,
    isSavingIntegration: selectors.getIsSavingIntegration,
});

const mapDispatchToProps = (dispatch) => ({
    save: (values, onSuccessCallback) =>
        dispatch(actions.save(values, onSuccessCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
