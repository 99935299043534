import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Container as MaterialContainer, Grid } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import RestrictedAccessLanding from 'common/modules/restrictedAccessLanding/Container';

import * as actions from './actions';
import TaxesList from './components/TaxesList';
import * as selectors from './selectors';

const Container = ({
    canDelete,
    canModify,
    fetchTaxes,
    isFetchingItems,
    isSavingTaxes,
    prepareDelete,
    saveTaxes,
    taxes,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        fetchTaxes();
    }, []);

    return (
        <RestrictedAccessLanding
            addonName="Taxes"
            url="https://somos.sunwise.io/sunwise-sales"
        >
            <MaterialContainer
                className="__userguiding_company_taxes_setup_container"
                maxWidth={false}
            >
                <HeaderContainer>
                    <Grid size={{ xs: 'grow' }}>
                        <TitleIcon title={t('Tax', { count: 2 })} />
                    </Grid>
                </HeaderContainer>
                <TaxesList
                    canDelete={canDelete}
                    canModify={canModify}
                    isFetchingItems={isFetchingItems}
                    isSavingTaxes={isSavingTaxes}
                    prepareDelete={prepareDelete}
                    saveTaxes={saveTaxes}
                    taxes={taxes}
                />
            </MaterialContainer>
        </RestrictedAccessLanding>
    );
};

Container.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    fetchTaxes: PropTypes.func,
    isFetchingItems: PropTypes.bool,
    isSavingTaxes: PropTypes.bool,
    taxes: PropTypes.array,
    prepareDelete: PropTypes.func,
    saveTaxes: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    isFetchingItems: selectors.getIsFetchingItems,
    isSavingTaxes: selectors.getIsSavingTaxes,
    taxes: selectors.getDataFetchTaxes,
});

const mapDispatchToProps = (dispatch) => ({
    fetchTaxes: () => dispatch(actions.fetchTaxes()),
    prepareDelete: (handleUpdate, id) =>
        dispatch(actions.prepareDelete(handleUpdate, id)),
    saveTaxes: (values, isNew) => dispatch(actions.saveTaxes(values, isNew)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.TAXES_PERMISSION),
)(Container);
