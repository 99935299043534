import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Card, Typography } from 'sunwise-ui';

import ShowErrors from 'common/components/ShowErrors';

import NextSubscripionDetails from './NextSubscripionDetails';

const NextSubscripions = ({
    addonsSubscriptions,
    canModify,
    handlePortal,
    payInvoices,
    payInvoicesErrors,
    paymentSources,
    subscriptions,
}) => {
    const { t } = useTranslation();

    return (
        <Card>
            <Card.Header sx={{ display: 'flex' }}>
                <Typography variant="body1" fontWeight="bold">
                    {t('Subscription', { count: 2 })}
                </Typography>
            </Card.Header>
            <Card.Body sx={{ minHeight: '650px' }}>
                {subscriptions.map((sub, index) => (
                    <NextSubscripionDetails
                        addonsSubscriptions={addonsSubscriptions}
                        canModify={canModify}
                        handlePortal={handlePortal}
                        key={`${sub?.item_price_id}-${index}`}
                        payInvoices={payInvoices}
                        paymentSources={paymentSources}
                        subscription={sub}
                    />
                ))}
                {payInvoicesErrors && (
                    <Box my={1}>
                        <ShowErrors
                            errors={payInvoicesErrors}
                            sxError={{ fontSize: '14px' }}
                            sxTitle={{ fontSize: '14px' }}
                        />
                    </Box>
                )}
            </Card.Body>
        </Card>
    );
};

NextSubscripions.propTypes = {
    addonsSubscriptions: PropTypes.array,
    canModify: PropTypes.bool,
    handlePortal: PropTypes.func,
    payInvoices: PropTypes.func,
    payInvoicesErrors: PropTypes.array,
    paymentSources: PropTypes.array,
    subscriptions: PropTypes.array,
};

export default NextSubscripions;
