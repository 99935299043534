import { buildValues } from '../helpers';

import initializeForm from './initializeForm';
import setIsNewRecord from './setIsNewRecord';
import toggleModalProduct from './toggleModalProduct';

export default (item, disabled = false) =>
    (dispatch) => {
        if (!item) return;
        const newValues = buildValues(item, disabled);
        dispatch(initializeForm(newValues));
        dispatch(setIsNewRecord(false));
        dispatch(toggleModalProduct(true));
    };
