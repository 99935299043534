import { create } from 'common/api/v1/googleSolarIrradiations';

export default (values) => {
    const { irradiation_id, segment_id } = values;
    if (!segment_id || !irradiation_id) return;

    return new Promise((resolve, reject) => {
        create({
            ...values,
            irradiation_key: import.meta.env.VITE_GOOGLE_SOLAR_API_KEY,
        })
            .then((response) => {
                const data = response?.data?.data;
                resolve({ ...data, segment_id });
            })
            .catch((error) => reject(error));
    });
};
