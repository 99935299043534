import React from 'react';

import PropTypes from 'prop-types';

import { ColumnsWrapper } from 'common/components/funnel';

import {
    REPORT_STATUS_CONFIG,
    REPORT_STATUS_KEYS,
} from '../../reportList/constants';

import ReportColumn from './ReportColum';

const columns = [
    REPORT_STATUS_KEYS.PENDING,
    REPORT_STATUS_KEYS.DRAFT,
    REPORT_STATUS_KEYS.GENERATED,
    REPORT_STATUS_KEYS.SENT,
];

const ReportColumns = ({
    data,
    fetchReports,
    filterQuery,
    localeDateFormat,
    onSelectReport,
}) => {
    if (!data) return null;

    return (
        <ColumnsWrapper>
            {columns.map((key) => (
                <ReportColumn
                    checkIcon={key === REPORT_STATUS_KEYS.SENT}
                    column={REPORT_STATUS_CONFIG[key]}
                    data={data[key]}
                    fetchReports={fetchReports}
                    filterQuery={filterQuery}
                    key={key}
                    localeDateFormat={localeDateFormat}
                    onSelectReport={onSelectReport}
                    status={key}
                />
            ))}
        </ColumnsWrapper>
    );
};

ReportColumns.propTypes = {
    data: PropTypes.object,
    fetchReports: PropTypes.func,
    filterQuery: PropTypes.string,
    localeDateFormat: PropTypes.string,
    onSelectReport: PropTypes.func,
};

export default ReportColumns;
