import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';

import { styled } from '@mui/material/styles';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    PageToolbar,
    withTemplateCore,
    editionLevels,
} from 'sunwise-template-core';
import { Box, Grid } from 'sunwise-ui';

import SwipeableDrawer from 'common/components/SwipeableDrawer';
import * as lisaFileActions from 'common/modules/lisaFiles/actions';
import { LoadingContext } from 'common/utils/contexts';
import { getCurrentLanguage } from 'common/utils/helpers/multiregion';
import { getToken } from 'common/utils/helpers/session';
import { scroll } from 'common/utils/mixins';

import * as actions from './actions';
import {
    FinancingBanner,
    HeaderPreview,
    NotFound,
    OfferSmartDocumentModal,
    PreviewContent,
    StyledSideBarColumn,
    SupportMenuContent,
} from './components';
import {
    DATASHEET_PDF_TYPE,
    FINANCING_TYPE,
    PDF_MEDIUM_QUALITY,
} from './constants';
import {
    getIsDownloadingFileContextMessage,
    getIsLoadingContextMessage,
    getIsUpdatingContextMessage,
    handleClickDocumentItemBuild,
    handleClickDownloadBuild,
    handleClickElectricBillBuild,
    handleCloseDrawer,
    handleScrollTopSideBar,
} from './helpers';
import * as selectors from './selectors';

const PageWrapper = styled(Box)(
    ({ theme }) => `
    background:  ${
        theme.palette.mode === 'dark' ? '#000' : 'rgb(248, 252, 255)'
    };
    height: calc(100% - 100px);
    overflow-y: scroll;
    padding-top: 24px;
    position: relative;
    width: 100%;
    
    ${scroll.custom()}

    .apexcharts-theme-light {
        color: black;
    }
`,
);

const Container = ({
    breadcrumbLocations,
    canModify,
    currentBreadcrumb,
    customTemplateData,
    customTemplateErrors,
    downloadDatasheet,
    downloadElectricBill,
    downloadFile,
    downloadOfferCsv,
    downloadOfferHourlyCsv,
    downloadOfferPdf,
    downloadOfferSmartDocumentCsv,
    downloadOfferXLS,
    editionDisabled,
    fetchCustomTemplate,
    fetchElectricBill,
    fetchFinancingTemplate,
    getTemplateUpdatedData,
    initialize,
    initTemplate,
    isFetching,
    isFetchingCustomTemplate,
    isFetchingDocumentTemplates,
    isFetchingFinancingTemplate,
    isFetchingOfferCsv,
    isFetchingOfferDatasheets,
    isFetchingOfferHourlyCsv,
    isFetchingOfferSmartDocumentCsv,
    isFetchingOfferSmartDocuments,
    isFetchingOfferXLS,
    isFetchingPdfFile,
    isInitializing,
    isUpdatingCustomTemplate,
    match,
    offerDatasheets,
    offerInfoPatern,
    offerSmartDocuments,
    prepareConverToTemplate,
    prepareRegenerateFinancingTemplate,
    prepareRegenerateSmartDocumentTemplate,
    prepareRegenerateTemplate,
    prepareRequest,
    reset,
    resetTemplate,
    restorePages,
    selectedDocument,
    setEditionDisabled,
    setSelectedDocument,
    setTemplateBackup,
    templateType,
    updateTemplate,
}) => {
    const loadingContext = useContext(LoadingContext);
    const [downloadFormat, setDownloadFormat] = useState(
        parseInt(localStorage.getItem('downloadFormat')) || PDF_MEDIUM_QUALITY,
    );
    const sideBarRef = useRef(null);
    const swipeableDrawerRef = useRef(null);
    const proposalId = match.params.uid;
    const proposalName = get(offerInfoPatern, 'proposalName', '');
    const isApproved = get(offerInfoPatern, 'isApproved', false);
    const isReadOnly = get(offerInfoPatern, 'isReadOnly', false);

    useEffect(() => () => reset(), []);

    useEffect(() => {
        if (!proposalId) return;
        resetTemplate();
        initialize(proposalId, initTemplate, templateType);
        setSelectedDocument({
            id: proposalId,
            name: proposalName,
            type: templateType,
        });
    }, [proposalId]);

    useEffect(() => {
        getIsLoadingContextMessage({
            isFetching,
            isFetchingCustomTemplate,
            isFetchingDocumentTemplates,
            isFetchingFinancingTemplate,
            isFetchingOfferDatasheets,
            isFetchingOfferSmartDocuments,
            isInitializing,
            loadingContext,
        });
    }, [
        isFetching,
        isFetchingCustomTemplate,
        isFetchingDocumentTemplates,
        isFetchingFinancingTemplate,
        isFetchingOfferDatasheets,
        isFetchingOfferSmartDocuments,
        isInitializing,
        isInitializing,
    ]);

    useEffect(() => {
        getIsDownloadingFileContextMessage({
            isFetchingOfferCsv,
            isFetchingOfferHourlyCsv,
            isFetchingPdfFile,
            isFetchingOfferXLS,
            isFetchingOfferSmartDocumentCsv,
            loadingContext,
        });
    }, [
        isFetchingOfferCsv,
        isFetchingOfferHourlyCsv,
        isFetchingPdfFile,
        isFetchingOfferXLS,
        isFetchingOfferSmartDocumentCsv,
    ]);

    useEffect(() => {
        getIsUpdatingContextMessage(
            editionDisabled,
            isUpdatingCustomTemplate,
            loadingContext,
            setEditionDisabled,
        );
    }, [isUpdatingCustomTemplate]);

    const handleUpdateTemplate = useCallback(() => {
        const { id, type } = selectedDocument;
        updateTemplate(id, type, getTemplateUpdatedData, setTemplateBackup);
    }, [selectedDocument, getTemplateUpdatedData, setTemplateBackup]);

    const handleClickDatasheetItem = (datasheet) => {
        resetTemplate();
        setSelectedDocument(datasheet);
        setDownloadFormat(PDF_MEDIUM_QUALITY);
        handleCloseDrawer(swipeableDrawerRef);
    };

    const handleClickDownload = () =>
        handleClickDownloadBuild(
            downloadDatasheet,
            downloadElectricBill,
            downloadFile,
            downloadFormat,
            downloadOfferCsv,
            downloadOfferHourlyCsv,
            downloadOfferPdf,
            downloadOfferSmartDocumentCsv,
            downloadOfferXLS,
            loadingContext,
            selectedDocument,
        );

    const handleClickDocumentItem = (document) =>
        handleClickDocumentItemBuild(
            document,
            fetchCustomTemplate,
            fetchFinancingTemplate,
            initTemplate,
            resetTemplate,
            selectedDocument,
            setDownloadFormat,
            setSelectedDocument,
            swipeableDrawerRef,
        );

    const handleClickElectricBillItem = (document) =>
        handleClickElectricBillBuild(
            document,
            fetchElectricBill,
            loadingContext,
            resetTemplate,
            setDownloadFormat,
            setSelectedDocument,
            swipeableDrawerRef,
        );

    const handleClickEditButton = () => {
        setEditionDisabled(false);
        setTemplateBackup();
        handleScrollTopSideBar(sideBarRef);
        handleCloseDrawer(swipeableDrawerRef);
    };

    const handleClickPrepareRegenerateFinancingTemplate = (
        financingTemplateId,
    ) =>
        prepareRegenerateFinancingTemplate({
            financingTemplateId,
            initTemplate,
            proposalId,
            resetTemplate,
        });

    const handleClickPrepareRegenerateSmartDocumentTemplate = (documentId) => {
        prepareRegenerateSmartDocumentTemplate({
            documentId,
            initTemplate,
            resetTemplate,
        });
    };

    const handleDblClick = (e) => {
        if (isReadOnly) return;
        const { detail } = e;
        if (
            !isApproved &&
            detail === 2 &&
            canModify &&
            selectedDocument.type !== DATASHEET_PDF_TYPE &&
            selectedDocument.type !== FINANCING_TYPE
        )
            handleClickEditButton();
    };

    const handleOnPrepareRegenerateTemplate = () =>
        prepareRegenerateTemplate({ initTemplate, proposalId, resetTemplate });

    const handleOnPrepareConverToTemplate = () =>
        prepareConverToTemplate({ proposalId, name: customTemplateData.title });

    const supportMenu = (
        <SupportMenuContent
            canModify={canModify}
            downloadFormat={downloadFormat}
            editionDisabled={editionDisabled}
            handleClickDatasheetItem={handleClickDatasheetItem}
            handleClickDocumentItem={handleClickDocumentItem}
            handleClickDownload={handleClickDownload}
            handleClickEditButton={handleClickEditButton}
            handleClickElectricBillItem={handleClickElectricBillItem}
            handleClickPrepareRegenerateFinancingTemplate={
                handleClickPrepareRegenerateFinancingTemplate
            }
            handleClickPrepareRegenerateSmartDocumentTemplate={
                handleClickPrepareRegenerateSmartDocumentTemplate
            }
            handleOnPrepareConverToTemplate={handleOnPrepareConverToTemplate}
            handleOnPrepareRegenerateTemplate={
                handleOnPrepareRegenerateTemplate
            }
            handleUpdateTemplate={handleUpdateTemplate}
            isApproved={isApproved}
            isDownloading={
                isFetchingOfferCsv ||
                isFetchingOfferHourlyCsv ||
                isFetchingPdfFile ||
                isFetchingOfferXLS ||
                isFetchingOfferSmartDocumentCsv
            }
            isFetching={
                isFetching ||
                isFetchingCustomTemplate ||
                isFetchingDocumentTemplates ||
                isFetchingFinancingTemplate ||
                isFetchingOfferDatasheets ||
                isFetchingOfferSmartDocuments ||
                isInitializing
            }
            isUpdatingCustomTemplate={isUpdatingCustomTemplate}
            isReadOnly={isReadOnly}
            offerDatasheets={offerDatasheets}
            offerInfoPatern={offerInfoPatern}
            offerSmartDocuments={offerSmartDocuments}
            prepareRequest={prepareRequest}
            proposalId={proposalId}
            proposalName={proposalName}
            restorePages={restorePages}
            selectedDocument={selectedDocument}
            setDownloadFormat={setDownloadFormat}
            setEditionDisabled={setEditionDisabled}
            swipeableDrawerRef={swipeableDrawerRef}
            templateType={templateType}
        />
    );

    if (!isEmpty(customTemplateErrors))
        return <NotFound errors={customTemplateErrors} />;

    return (
        <>
            <Grid container spacing={0}>
                <Grid
                    size={{ lg: 14, md: 12, xs: 18 }}
                    sx={{
                        height: {
                            lg: 'calc(100vh - 64px)',
                            xs: 'calc(100vh - 105px)',
                        },
                    }}
                >
                    <HeaderPreview
                        breadcrumbLocations={breadcrumbLocations}
                        currentBreadcrumb={currentBreadcrumb}
                        isApproved={isApproved}
                        isReadOnly={isReadOnly}
                        offerStatus={get(offerInfoPatern, 'proposalStatus', 0)}
                    />

                    <FinancingBanner
                        financing={get(offerInfoPatern, 'financing', {})}
                        isCertifiedRate={get(
                            offerInfoPatern,
                            'isCertifiedRate',
                            false,
                        )}
                        proposalId={proposalId}
                    />

                    <PageToolbar
                        editionLevel={editionLevels.PARTIAL_EDITION_MODE}
                        reOrderDisabled
                        sxToolbar={{
                            pl: '76px',
                            ...(editionDisabled ? { minHeight: 0 } : {}),
                        }}
                        visible={!editionDisabled}
                    />

                    <PageWrapper
                        onClick={handleDblClick}
                        sx={{ pb: { lg: 0, xs: '50px' } }}
                        toolbar={editionDisabled ? 'false' : 'true'}
                    >
                        <PreviewContent
                            editionDisabled={editionDisabled}
                            isFetchingCustomTemplate={isFetchingCustomTemplate}
                            isFetchingDocumentTemplates={
                                isFetchingDocumentTemplates
                            }
                            isFetchingFinancingTemplate={
                                isFetchingFinancingTemplate
                            }
                            isFetchingOfferSmartDocuments={
                                isFetchingOfferSmartDocuments
                            }
                            selectedDocument={selectedDocument}
                        />
                    </PageWrapper>
                </Grid>

                <StyledSideBarColumn
                    size={{ lg: 4, md: 6 }}
                    ref={sideBarRef}
                    sx={{
                        display: { md: 'block', xs: 'none' },
                        height: {
                            md: 'calc(100vh - 64px)',
                            xs: 'calc(100vh - 105px)',
                        },
                    }}
                >
                    {supportMenu}
                </StyledSideBarColumn>
            </Grid>

            <SwipeableDrawer ref={swipeableDrawerRef}>
                {supportMenu}
            </SwipeableDrawer>

            <OfferSmartDocumentModal
                handleClickDocumentItem={handleClickDocumentItem}
                proposalId={proposalId}
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    customTemplateData: selectors.getFetchCustomTemplateData,
    customTemplateErrors: selectors.getFetchCustomTemplateErrors,
    isFetchingCustomTemplate: selectors.getIsFetchingCustomTemplate,
    isFetchingDocumentTemplates: selectors.getIsFetchingDocumentTemplates,
    isFetchingFinancingTemplate: selectors.getIsFetchingFinancingTemplate,
    isFetchingOfferCsv: selectors.getIsFetchingOfferCsv,
    isFetchingOfferDatasheets: selectors.getIsFetchingOfferDatasheets,
    isFetchingOfferHourlyCsv: selectors.getIsFetchingOfferHourlyCsv,
    isFetchingOfferSmartDocumentCsv:
        selectors.getIsFetchingOfferSmartDocumentCsv,
    isFetchingOfferSmartDocuments: selectors.getIsFetchingOfferSmartDocuments,
    isFetchingOfferXLS: selectors.getIsFetchingOfferXLS,
    isFetchingPdfFile: selectors.getIsFetchingPdfFile,
    isInitializing: selectors.getIsInitializing,
    isUpdatingCustomTemplate: selectors.getIsUpdatingCustomTemplate,
    offerDatasheets: selectors.getOfferDatasheetsData,
    offerSmartDocuments: selectors.getOfferSmartDocumentsData,
    selectedDocument: selectors.getSelectedDocument,
    isReadOnly: selectors.getIsReadOnly,
});

const mapDispatchToProps = (dispatch) => ({
    downloadDatasheet: (datasheetName, openLoading, closeLoading) =>
        dispatch(
            actions.downloadDatasheet(datasheetName, openLoading, closeLoading),
        ),
    downloadElectricBill: (archive, fileName, loadingContext, name, urlType) =>
        dispatch(
            actions.downloadElectricBill(
                archive,
                fileName,
                loadingContext,
                name,
                urlType,
            ),
        ),
    downloadFile: (name, url, type) =>
        dispatch(actions.downloadFile(name, url, type)),
    downloadOfferCsv: (id, name) =>
        dispatch(actions.downloadOfferCsv(id, name)),
    downloadOfferHourlyCsv: (id, name) =>
        dispatch(actions.downloadOfferHourlyCsv(id, name)),
    downloadOfferPdf: (downloadFormat, id, name, type) =>
        dispatch(actions.downloadOfferPdf(downloadFormat, id, name, type)),
    downloadOfferSmartDocumentCsv: (id, name) =>
        dispatch(actions.downloadOfferSmartDocumentCsv(id, name)),
    downloadOfferXLS: (id, name, formatted) =>
        dispatch(actions.downloadOfferXLS(id, name, formatted)),
    fetchCustomTemplate: (id, initTemplate, templateType) =>
        dispatch(actions.fetchCustomTemplate(id, initTemplate, templateType)),
    fetchElectricBill: (file_name, onSuccess) =>
        dispatch(lisaFileActions.getFile(file_name, onSuccess)),
    fetchFinancingTemplate: (id, initTemplate) =>
        dispatch(actions.fetchFinancingTemplate(id, initTemplate)),
    initialize: (id, initTemplate, templateType) =>
        dispatch(actions.initialize(id, initTemplate, templateType)),
    prepareConverToTemplate: (values) =>
        dispatch(actions.prepareConverToTemplate(values)),
    prepareRegenerateFinancingTemplate: (params) =>
        dispatch(actions.prepareRegenerateFinancingTemplate(params)),
    prepareRegenerateSmartDocumentTemplate: (params) =>
        dispatch(actions.prepareRegenerateSmartDocumentTemplate(params)),
    prepareRegenerateTemplate: (params) =>
        dispatch(actions.prepareRegenerateTemplate(params)),
    prepareRequest: (contactId, projectId, proposalId) =>
        dispatch(actions.prepareRequest(contactId, projectId, proposalId)),
    reset: () => dispatch(actions.reset()),
    setSelectedDocument: (values) =>
        dispatch(actions.setSelectedDocument(values)),
    updateTemplate: (
        reviewId,
        templateType,
        getTemplateUpdatedData,
        setTemplateBackup,
    ) =>
        dispatch(
            actions.updateTemplate(
                reviewId,
                templateType,
                getTemplateUpdatedData,
                setTemplateBackup,
            ),
        ),
});

Container.propTypes = {
    breadcrumbLocations: PropTypes.array,
    canModify: PropTypes.bool,
    currentBreadcrumb: PropTypes.string,
    customTemplateData: PropTypes.object,
    customTemplateErrors: PropTypes.array,
    downloadDatasheet: PropTypes.func,
    downloadElectricBill: PropTypes.func,
    downloadFile: PropTypes.func,
    downloadOfferCsv: PropTypes.func,
    downloadOfferHourlyCsv: PropTypes.func,
    downloadOfferPdf: PropTypes.func,
    downloadOfferSmartDocumentCsv: PropTypes.func,
    downloadOfferXLS: PropTypes.func,
    editionDisabled: PropTypes.bool,
    fetchCustomTemplate: PropTypes.func,
    fetchElectricBill: PropTypes.func,
    fetchFinancingTemplate: PropTypes.func,
    getTemplateUpdatedData: PropTypes.func,
    initialize: PropTypes.func,
    initTemplate: PropTypes.func,
    isFetching: PropTypes.bool,
    isFetchingCustomTemplate: PropTypes.bool,
    isFetchingDocumentTemplates: PropTypes.bool,
    isFetchingFinancingTemplate: PropTypes.bool,
    isFetchingOfferCsv: PropTypes.bool,
    isFetchingOfferDatasheets: PropTypes.bool,
    isFetchingOfferHourlyCsv: PropTypes.bool,
    isFetchingOfferSmartDocumentCsv: PropTypes.bool,
    isFetchingOfferSmartDocuments: PropTypes.bool,
    isFetchingOfferXLS: PropTypes.bool,
    isFetchingPdfFile: PropTypes.bool,
    isInitializing: PropTypes.bool,
    isUpdatingCustomTemplate: PropTypes.bool,
    match: PropTypes.object,
    offerDatasheets: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    offerInfoPatern: PropTypes.object,
    offerSmartDocuments: PropTypes.array,
    prepareConverToTemplate: PropTypes.func,
    prepareRegenerateFinancingTemplate: PropTypes.func,
    prepareRegenerateSmartDocumentTemplate: PropTypes.func,
    prepareRegenerateTemplate: PropTypes.func,
    prepareRequest: PropTypes.func,
    reset: PropTypes.func,
    resetTemplate: PropTypes.func,
    restorePages: PropTypes.func,
    selectedDocument: PropTypes.object,
    setEditionDisabled: PropTypes.func,
    setSelectedDocument: PropTypes.func,
    setTemplateBackup: PropTypes.func,
    templateType: PropTypes.number,
    updateTemplate: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withTemplateCore(() => ({
        application: 'sunwise',
        baseUrl: import.meta.env.VITE_API_URL,
        editionMode: editionLevels.PARTIAL_EDITION_MODE,
        froalaLicenseKey: import.meta.env.VITE_FROALA_LICENSE,
        googleApiKey: import.meta.env.VITE_GOOGLE_MAPS_KEY,
        language: getCurrentLanguage(),
        token: getToken(),
    })),
)(Container);
