import React from 'react';

import FolderIcon from '@mui/icons-material/Folder';
import ListIcon from '@mui/icons-material/List';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PropTypes from 'prop-types';

const ProjectViewMode = ({ setViewMode, viewMode }) => {
    const handleAlignment = (event, value) => {
        if (!value) return;
        setViewMode(value);
        localStorage.setItem('projectsViewMode', value);
    };

    return (
        <ToggleButtonGroup
            aria-label="view mode"
            exclusive
            onChange={handleAlignment}
            size="small"
            value={viewMode}
            sx={{ '& .MuiToggleButton-root': { borderRadius: 1 }, ml: 'auto' }}
        >
            <ToggleButton value="folder" aria-label="left aligned">
                <FolderIcon />
            </ToggleButton>
            <ToggleButton value="list" aria-label="centered">
                <ListIcon />
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

ProjectViewMode.propTypes = {
    setViewMode: PropTypes.func,
    viewMode: PropTypes.string,
};

export default ProjectViewMode;
