import { formatDate, parseDate, subDate } from 'common/utils/dates';

import * as projectConsumptionCardSelectors from '../../projectConsumptionCard/selectors';
import { DEVICE_DEFAULT_VALUES, DEVICES, UNIT_KEYS } from '../constants';
import { getPowerValue } from '../helpers';

import fetchConsumption from './fetchConsumption';
import initializeConsumptionForm from './initializeConsumptionForm';
import setIsOpenModal from './setIsOpenModal';

const DATE_FORMAT = 'dd/MM/yyyy';

export default (projectId) => (dispatch, getState) => {
    if (!projectId) return;

    dispatch(fetchConsumption(projectId)).then((data) => {
        const state = getState();

        const consumptionProfileData =
            projectConsumptionCardSelectors.getConsumptionProfile(state);
        const consumptionData =
            projectConsumptionCardSelectors.getDataConsumptionEnergy(state);

        const energyLines =
            consumptionData?.lines_consumption_energies?.filter(
                (item) => parseInt(item.type_line) === 0,
            ) || [];

        const dates = energyLines.map(({ final_date, initial_date }) => {
            const parsedFinalDate = parseDate(final_date, DATE_FORMAT);
            return {
                initial_date,
                final_date,
                parsed_final_date: parsedFinalDate,
                parsed_initial_date: parseDate(initial_date, DATE_FORMAT),
                label: formatDate(
                    subDate(parsedFinalDate, {
                        days: consumptionData?.is_bimonthly ? 30 : 15,
                    }),
                    'MMM yy',
                ),
            };
        });

        dates?.sort((a, b) => a.parsed_initial_date - b.parsed_initial_date);

        const newData = {
            consumption_profile_array: consumptionProfileData?.[0]?.consumption,
            date_format: DATE_FORMAT,
            dates_summary: dates,
            demand_array: [],
            devices: data?.device_consumptions?.map((device) => ({
                ...device,
                installed: true,
            })) || [DEVICE_DEFAULT_VALUES[DEVICES.LIGHT]],
            id: data?.id,
            initial_consumption: getPowerValue({
                power: energyLines.reduce(
                    (acc, curr) => acc + curr.consumption,
                    0,
                ),
                quantity: 1,
                unit: UNIT_KEYS.kW,
            }),
        };

        dispatch(initializeConsumptionForm(newData));
        dispatch(setIsOpenModal(true));
    });
};
