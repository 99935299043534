import React, { useState } from 'react';

import LockIcon from '@mui/icons-material/Lock';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid, Tooltip } from 'sunwise-ui';

import {
    getPaymentDueModules,
    getSubscriptionsModules,
} from 'common/utils/helpers';

import * as profileSelectors from '../../../../modules/profile/selectors';

import Plans from './Plans';

const AccessButton = ({
    addonName = '',
    addons,
    children,
    flluidView = false,
    isAftersalesSubscribed,
    subscriptions,
    subscriptionVersion,
    textButton = null,
    variant = 'contained',
    visible = true,
}) => {
    const { t } = useTranslation();
    const [showWizard, setShowWizard] = useState(false);

    const modules = getSubscriptionsModules(
        addons,
        isAftersalesSubscribed,
        subscriptions,
        subscriptionVersion,
    );
    const paymentDuelModules = getPaymentDueModules(subscriptions);

    // Convertir addonName en un array, eliminando espacios en blanco
    const addonNamesArray = addonName.split(',').map((name) => name.trim());

    // Verificar si alguno de los addonNames está en paymentDuelModules
    const isPaymentDue = addonNamesArray.some((name) =>
        paymentDuelModules.includes(name),
    );

    // Verificar si alguno de los addonNames está en modules
    const hasAccess = addonNamesArray.some((name) => modules.includes(name));

    // Si ya tiene acceso, renderizar solo los children
    if (hasAccess) return <>{children}</>;

    // Si se muestra el wizard, renderizar solo Plans
    if (showWizard) {
        return (
            <Plans
                addonName={addonName}
                flluidView={flluidView}
                handleClickBack={() => setShowWizard(false)}
            />
        );
    }

    // Renderizar el botón junto con los children
    return (
        <>
            <Grid item xs sx={{ textAlign: 'right' }}>
                <Tooltip
                    title={
                        isPaymentDue
                            ? t(
                                  'This section is blocked due to a pending subscription payment',
                              )
                            : ''
                    }
                    placement="top"
                    arrow
                    disableHoverListener={!isPaymentDue}
                >
                    <Box component="span">
                        <Button
                            startIcon={isPaymentDue ? <LockIcon /> : null}
                            disabled={isPaymentDue}
                            onClick={() => setShowWizard(true)}
                            variant={variant}
                            visible={visible}
                        >
                            {textButton ? textButton : t('Get access')}
                        </Button>
                    </Box>
                </Tooltip>
            </Grid>
            {children}
        </>
    );
};

AccessButton.propTypes = {
    addonName: PropTypes.string,
    addons: PropTypes.array,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    flluidView: PropTypes.bool,
    isAftersalesSubscribed: PropTypes.bool,
    subscriptions: PropTypes.array,
    subscriptionVersion: PropTypes.string,
    textButton: PropTypes.string,
    variant: PropTypes.string,
    visible: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
    addons: profileSelectors.getAddons,
    isAftersalesSubscribed: profileSelectors.getIsAftersalesSubscribed,
    subscriptions: profileSelectors.getSubscriptions,
    subscriptionVersion: profileSelectors.getSubscriptionVersion,
});

export default connect(mapStateToProps, null)(AccessButton);
