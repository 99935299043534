import React from 'react';

import { TableRow } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';

import { generateColorTone } from 'common/utils/helpers';

import HeaderCell from './HeaderCell';

const TableHeader = ({
    columnGroups,
    expandedColumns,
    toggleExpandedColumn,
}) => (
    <>
        <TableRow>
            {columnGroups.map((group, groupIndex) => {
                const isCollapsible =
                    group.expandedColumns?.length > 0 &&
                    group.collapsedColumns?.length > 0;

                const isExpanded = !isCollapsible || expandedColumns[group.key];

                const _columns = isExpanded
                    ? group.expandedColumns
                    : group.collapsedColumns;

                const columns = _columns?.filter(
                    (column) => column.visible !== false,
                );

                if (!columns?.length) return null;

                const isLastColumn = groupIndex === columnGroups.length - 1;

                return (
                    <HeaderCell
                        bgColor={group.bgColor}
                        colSpan={columns?.length || 0}
                        isCollapsible={isCollapsible}
                        isExpanded={isExpanded}
                        isSticky={group.isSticky}
                        key={group.label + group.bgColor}
                        label={group.label}
                        onClick={() => {
                            if (isCollapsible) toggleExpandedColumn(group.key);
                            if (group.onClick) group.onClick();
                        }}
                        sx={{
                            borderBottom: !group.label ? 'none' : undefined,
                            borderLeft: `1px solid ${group.bgColor}`,
                            borderRight: isLastColumn
                                ? `1px solid ${group.bgColor}`
                                : 'none',
                        }}
                    />
                );
            })}
        </TableRow>

        <TableRow>
            {columnGroups.map((group, groupIndex) => {
                const isCollapsible =
                    group.expandedColumns?.length > 0 &&
                    group.collapsedColumns?.length > 0;

                const isExpanded = !isCollapsible || expandedColumns[group.key];

                const columns = isExpanded
                    ? group.expandedColumns
                    : group.collapsedColumns;

                return columns?.map((column, columnIndex) => {
                    const {
                        isSticky = false,
                        key,
                        label,
                        visible = true,
                    } = column || {};

                    if (!visible) return null;

                    const bgColor = group.useSameColor
                        ? group.bgColor
                        : generateColorTone(
                              group.bgColor,
                              columnIndex * (group.colorDelta || 25),
                          );

                    const isLastColumn =
                        groupIndex === columnGroups.length - 1 &&
                        columnIndex === columns.length - 1;

                    return (
                        <HeaderCell
                            bgColor={bgColor}
                            isSticky={isSticky}
                            key={group.label + key}
                            label={label}
                            onClick={group.onClick}
                            sx={{
                                borderBottom: 'none',
                                borderLeft: `1px solid ${group.bgColor}`,
                                borderRight: isLastColumn
                                    ? `1px solid ${group.bgColor}`
                                    : 'none',
                            }}
                        />
                    );
                });
            })}
        </TableRow>
    </>
);

TableHeader.propTypes = {
    columnGroups: PropTypes.array,
    expandedColumns: PropTypes.object,
    toggleExpandedColumn: PropTypes.func,
};

export default TableHeader;
