import React from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';

const ActionsCell = ({
    disabled,
    installed,
    onClickDelete,
    onClickDuplicate,
    toggleInstalled,
}) => {
    const { t } = useTranslation();

    return (
        <DropdownCardMenu
            items={[
                {
                    disabled,
                    label: t('Delete'),
                    icon: <DeleteIcon />,
                    handleClick: onClickDelete,
                },
                {
                    disabled,
                    label: t('Duplicate'),
                    icon: <ContentCopyIcon />,
                    handleClick: onClickDuplicate,
                },
                {
                    disabled,
                    label: t('Mark as already installed'),
                    icon: <DoneAllIcon />,
                    handleClick: () => toggleInstalled(true),
                    visible: !installed,
                },

                {
                    disabled,
                    label: t('Mark as new device'),
                    icon: <RemoveDoneIcon />,
                    handleClick: () => toggleInstalled(false),
                    visible: installed,
                },
            ]}
        />
    );
};

ActionsCell.propTypes = {
    disabled: PropTypes.bool,
    installed: PropTypes.bool,
    onClickDelete: PropTypes.func,
    onClickDuplicate: PropTypes.func,
    toggleInstalled: PropTypes.func,
};

export default ActionsCell;
