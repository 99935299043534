import { push } from 'connected-react-router';
import { get, isNull } from 'lodash';

import { getAuthMe } from 'common/api/v1/auth';
import {
    getSubscriptionStatus,
    getSubscriptionsStatus,
} from 'common/utils/helpers';
import { getCompanyGroup, updateSession } from 'common/utils/helpers/session';

import { FETCH, FETCH_FAILURE, FETCH_SUCCESS } from '../actionTypes';
import { profileActions } from '../reducer';

import initializeForm from './initializeForm';
import initializeMultiregion from './initializeMultiregion';
import initializeSecurityPermissions from './initializeSecurityPermissions';
import setCurrentValues from './setCurrentValues';

export default ({ forceLoading = true, redirecToWizard = true } = {}) =>
    (dispatch) => {
        if (forceLoading) dispatch(profileActions[FETCH]());

        getAuthMe()
            .then((response) => {
                const {
                    branch_offices,
                    company_group,
                    has_sunwise_update_notification = false,
                    onboarding,
                    permissions,
                    role_level,
                    subscriptions,
                } = response.data;
                const companyGroup = getCompanyGroup();
                const groups = get(permissions, 'groups', []);
                const subscriptionVersion = get(
                    subscriptions[0],
                    'version',
                    'v4',
                );
                const {
                    allSubscriptionsAreCanceled,
                    hasPaymentDue,
                    isMultipleSubscription,
                } = getSubscriptionsStatus(subscriptions);
                const { isCancelled } = getSubscriptionStatus(subscriptions[0]);
                updateSession({
                    branch_offices,
                    company_group: {
                        ...companyGroup,
                        maximum_discount: get(
                            company_group,
                            'maximum_discount',
                            0,
                        ),
                    },
                    hasOnboarding: !isNull(onboarding),
                    has_sunwise_update_notification,
                    is_multibranches: permissions?.is_multibranches,
                    role_level,
                    settings: response?.data?.settings,
                    show_payment_due_view: hasPaymentDue,
                    subscriptionVersion,
                });
                localStorage.setItem('permissions', JSON.stringify(groups));
                dispatch(profileActions[FETCH_SUCCESS](response.data));
                dispatch(initializeForm(response.data));
                dispatch(initializeMultiregion(response.data));
                dispatch(initializeSecurityPermissions(response.data));
                dispatch(
                    setCurrentValues({
                        first_name: response?.data?.first_name,
                        last_name: response?.data?.last_name,
                        profile_image: response?.data?.profile_image,
                    }),
                );
                if (
                    (isMultipleSubscription &&
                        allSubscriptionsAreCanceled &&
                        redirecToWizard) ||
                    (!isMultipleSubscription && isCancelled && redirecToWizard)
                ) {
                    dispatch(push('/wizard'));
                    return false;
                }
            })
            .catch((error) =>
                dispatch(
                    profileActions[FETCH_FAILURE](
                        error?.response?.data?.errors,
                    ),
                ),
            );
    };
