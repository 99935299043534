import isEmpty from 'lodash/isEmpty';

// JSON con los módulos agrupados por tipo de producto y subtipo
const modulesByProduct = {
    sales: {
        Pro: [
            'Alliances',
            'Consumption-Profiles',
            'Dashboard',
            'Electrical-Compatibility',
            'Financial-Products',
            'Financing-Funnel',
            'Google-Irradiation-API',
            'Plans',
            'Products',
            'Project-Funnel',
            'Proposal-Templates',
            'Proposals',
            'Smart-Documents-Templates',
            'Solar-Generation',
            'Summaries',
            'Summary-Templates',
            'Taxes',
            'Web-Quoter',
        ],
        Scale: [
            'Analytics-CFE',
            'Analytics-CRE',
            'Analytics-Sunwise',
            'BESS',
            'Branch-Offices',
        ],
        Enterprise: ['Branding'],
    },
    aftersales: {
        Pro: [
            'Manual-Reporting',
            'Monitoring-Dashboard',
            'Monitoring-System',
            'Report-Funnel',
            'Report-Templates',
        ],
        Scale: [
            'Automatic-Reporting',
            'Automatic-Reports-By-Mail',
            'LISA-Integration',
        ],
    },
    bundle: {
        Pro: [
            'Alliances',
            'Consumption-Profiles',
            'Dashboard',
            'Electrical-Compatibility',
            'Financial-Products',
            'Financing-Funnel',
            'Google-Irradiation-API',
            'Plans',
            'Products',
            'Project-Funnel',
            'Proposal-Templates',
            'Proposals',
            'Smart-Documents-Templates',
            'Solar-Generation',
            'Summaries',
            'Summary-Templates',
            'Taxes',
            'Web-Quoter',
            'Manual-Reporting',
            'Monitoring-Dashboard',
            'Monitoring-System',
            'Report-Funnel',
            'Report-Templates',
        ],
        Scale: [
            'Analytics-CFE',
            'Analytics-CRE',
            'Analytics-Sunwise',
            'BESS',
            'Branch-Offices',
            'Automatic-Reporting',
            'Automatic-Reports-By-Mail',
            'LISA-Integration',
        ],
        Enterprise: ['Branding'],
    },
};

export const getFilteredPricing = ({ billingFrequency, pricings }) => {
    if (isEmpty(pricings)) return [];
    return pricings[billingFrequency].map((item) => ({
        ...item,
        frequency: billingFrequency,
    }));
};

export const getProductType = (addonName, subscription) => {
    // Obtener product_type de la suscripción
    const productType = subscription.product_type;

    // Convertir siempre addonName a un array
    const addonNames = Array.isArray(addonName)
        ? addonName
        : addonName.split(',').map((name) => name.trim());

    // Verificar si al menos un módulo está en alguna sublista del productType
    const someInProductType = addonNames.some((name) =>
        Object.values(modulesByProduct[productType]).some((list) =>
            list.includes(name),
        ),
    );

    if (someInProductType) {
        return productType;
    }

    // Buscar en las demás listas, excluyendo el productType de la suscripción
    for (const pt in modulesByProduct) {
        if (pt !== productType) {
            if (
                addonNames.some((name) =>
                    Object.values(modulesByProduct[pt]).some((list) =>
                        list.includes(name),
                    ),
                )
            ) {
                return pt;
            }
        }
    }

    // Si no se encuentra en ninguna lista, retornar "Unknown"
    return 'Unknown';
};
