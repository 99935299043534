import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import {
    ReactHookFormCheck,
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect2,
} from 'common/components/form/bootstrap';

import * as selectors from '../selectors';

import FormAccordion from './FormAccordion';

const AutoReportSection = ({ control, disabled, templates }) => {
    const { t } = useTranslation();
    const autoReport = useWatch({
        control,
        name: 'auto_report',
    });

    return (
        <>
            <ReactHookFormCheck
                control={control}
                disabled={disabled}
                label={t('Create report automatically')}
                name="auto_report"
                variant="standard"
            />

            {autoReport && (
                <FormAccordion defaultExpanded title={t('Setting')}>
                    <Grid container>
                        <Grid size={{ md: 9, xs: 18 }}>
                            <ReactHookFormIntlNumberInput
                                allowDecimals={false}
                                allowNegativeValue={false}
                                control={control}
                                disabled={disabled}
                                fullWidth
                                label={t('Creation day')}
                                max={31}
                                min={1}
                                name="auto_report_day"
                                placeholder="1"
                                variant="standard"
                            />
                        </Grid>

                        <Grid size={{ md: 9, xs: 18 }}>
                            <ReactHookFormSelect2
                                control={control}
                                disabled={disabled}
                                isClearable={false}
                                label={t('Template')}
                                name="custom_offer_template"
                                options={[{ label: '', options: templates }]}
                                variant="standard"
                            />
                        </Grid>
                    </Grid>
                </FormAccordion>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    templates: selectors.getTemplatesForSelect,
});

AutoReportSection.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    templates: PropTypes.array,
};

export default connect(mapStateToProps)(AutoReportSection);
