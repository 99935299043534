import React from 'react';

import DarkModeIcon from '@mui/icons-material/DarkMode';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Switch } from 'sunwise-ui';

import { logoutActions } from 'common/utils/helpers/session';

import * as profileConfigurationActions from '../../../modules/profileConfiguration/actions';

import StyledMenuItem from './StyledMenuItem';
import SubscriptionMenuItem from './SubscriptionMenuItem';

const ProfileMenuItems = ({
    handleCloseProfileMenu,
    match,
    profileValues,
    redirect,
    saveConfig,
    selectedTheme,
    setTheme,
}) => {
    const { t } = useTranslation();
    const handleOnSwitchTheme = (e) => {
        const theme = e.target.checked ? 'dark' : 'light';
        setTheme(theme);
        saveConfig({ mode: theme });
    };

    return (
        <>
            <StyledMenuItem
                onClick={() => {
                    handleCloseProfileMenu();
                    redirect('/profile/general');
                }}
                selected={match.path === '/profile'}
            >
                <PersonIcon />
                {profileValues?.first_name} {profileValues?.last_name}
            </StyledMenuItem>

            <SubscriptionMenuItem
                handleCloseProfileMenu={handleCloseProfileMenu}
                match={match}
                redirect={redirect}
            />

            <StyledMenuItem>
                <DarkModeIcon />
                {t('Dark mode')}{' '}
                <Switch
                    checked={selectedTheme === 'dark'}
                    onChange={handleOnSwitchTheme}
                />
            </StyledMenuItem>

            <StyledMenuItem
                onClick={() => {
                    handleCloseProfileMenu();
                    logoutActions();
                }}
            >
                <LogoutIcon />
                {t('Logout')}
            </StyledMenuItem>
        </>
    );
};

ProfileMenuItems.propTypes = {
    handleCloseProfileMenu: PropTypes.func,
    match: PropTypes.object,
    profileValues: PropTypes.object,
    redirect: PropTypes.func,
    selectedTheme: PropTypes.string,
    saveConfig: PropTypes.func,
    setTheme: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    saveConfig: (config) =>
        dispatch(profileConfigurationActions.saveConfig(config)),
});

export default connect(null, mapDispatchToProps)(ProfileMenuItems);
