export const FETCH_FILTERS = 'FETCH_FILTERS';
export const FETCH_FILTERS_FAILURE = 'FETCH_FILTERS_FAILURE';
export const FETCH_FILTERS_SUCCESS = 'FETCH_FILTERS_SUCCESS';

export const FETCH_REPORT_HISTORICALS = 'FETCH_REPORT_HISTORICALS';
export const FETCH_REPORT_HISTORICALS_FAILURE =
    'FETCH_REPORT_HISTORICALS_FAILURE';
export const FETCH_REPORT_HISTORICALS_SUCCESS =
    'FETCH_REPORT_HISTORICALS_SUCCESS';

export const FETCH_REPORTS = 'FETCH_REPORTS';
export const FETCH_REPORTS_FAILURE = 'FETCH_REPORTS_FAILURE';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';

export const INITIALIZE_FILTERS = 'INITIALIZE_FILTERS';

export const RESET = 'RESET';

export const SET_INITIAL_VALUES_FILTERS = 'SET_INITIAL_VALUES_FILTERS';

export const SET_IS_OPEN_FILTERS_MODAL = 'SET_IS_OPEN_FILTERS_MODAL';
