import React, { useEffect, useContext } from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { styled } from '@mui/material/styles';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useGeolocated } from 'react-geolocated';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Box, Grid, Button, Divider, Typography } from 'sunwise-ui';

import OpacityAnimation from 'common/components/animations/OpacityAnimation';
import {
    SlidesContainer,
    SocialMediaFooterContainer,
} from 'common/components/socialSlide';
import { LoadingContext } from 'common/utils/contexts';
import { getIsGuest } from 'common/utils/helpers/session';

import divider from 'resources/diamondDivider.svg';
import fondoLogin1 from 'resources/fondoLogin1.png';
import logotipo from 'resources/logotipoSunwiseAzul.svg';
import slide1 from 'resources/slides/signup/Congratulations.svg';
import slide4 from 'resources/slides/signup/Security.svg';
import slide2 from 'resources/slides/signup/Speedup.svg';
import slide3 from 'resources/slides/signup/Support.svg';

import { createAccount, fetchCountries, getGeoLocation } from './actions';
import { CreateAccountForm } from './components';
import * as selectors from './selectors';

const StyledGrid = styled(Grid)`
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background: #fff url(${fondoLogin1});
`;

const StyledCol = styled(Grid)`
    background: #f6f8fa;
`;

const StyledSlideTitle = styled('h1')`
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 26px !important;
    line-height: 32px;
    text-align: left;
`;

const StyledSlideImage = styled('img')`
    cursor: pointer;
    display: block;
    margin: auto;
    width: 100%;
`;

const StyledSlideDivider = styled('div')`
    margin: 32px 0;
`;

const ImageContentSlide = ({ imgUrl, title, description }) => (
    <>
        <StyledSlideTitle>{title}</StyledSlideTitle>

        <Typography variant="body2">{description}</Typography>

        <StyledSlideDivider>
            <img alt="Triangulos divisor" src={divider} />
        </StyledSlideDivider>

        <StyledSlideImage className="slide-image" src={imgUrl} />
    </>
);

ImageContentSlide.propTypes = {
    description: PropTypes.string,
    imgUrl: PropTypes.string,
    title: PropTypes.string,
};

const Container = ({
    countries,
    createAccount,
    errors,
    fetchCountries,
    getGeoLocation,
    initialValues,
    isFetchingCountries,
    isLoadingSession,
    redirect,
}) => {
    const { coords } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
    });
    const { t } = useTranslation();
    const isGuest = getIsGuest();
    const loadingContext = useContext(LoadingContext);

    useEffect(() => {
        fetchCountries();
        if (!isGuest) redirect('/');
    }, []);

    useEffect(() => {
        if (coords)
            getGeoLocation(countries, coords.latitude, coords.longitude);
    }, [coords]);

    const handleSubmit = (values) => createAccount(values, loadingContext);

    const slides = [
        {
            content: (
                <ImageContentSlide
                    description={t(
                        "You're just one step to take your business to the next level",
                    )}
                    imgUrl={slide1}
                    title={t('Congratulations!')}
                />
            ),
        },
        {
            content: (
                <ImageContentSlide
                    description={t(
                        'We want you to grow, which is why we will support you to digitize your processes and take your business to the next level',
                    )}
                    imgUrl={slide2}
                    title={t('Speeds up')}
                />
            ),
        },
        {
            content: (
                <ImageContentSlide
                    description={t(
                        'We have a support team always ready to help with whatever you need',
                    )}
                    imgUrl={slide3}
                    title={t('Support')}
                />
            ),
        },
        {
            content: (
                <ImageContentSlide
                    description={t(
                        'We know that your information is very important to you. So, we maintain the highest levels of security',
                    )}
                    imgUrl={slide4}
                    title={t('Safety')}
                />
            ),
        },
    ];

    return (
        <Grid
            container
            spacing={0}
            sx={{ alignItems: 'center', height: '100%' }}
        >
            <StyledGrid
                size={{ lg: 9, xs: 18 }}
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    height: '100%',
                }}
            >
                <OpacityAnimation>
                    <Box sx={{ margin: '0 auto', maxWidth: '670px', p: 2 }}>
                        <Box sx={{ textAlign: 'center' }}>
                            <img alt="Sunwise" src={logotipo} width="238" />
                        </Box>

                        <Box py={2}>
                            <Typography variant="h4">
                                {t('Welcome!')}
                            </Typography>
                            <Typography variant="body2">
                                {t('To begin adding this data')}
                            </Typography>
                        </Box>

                        <Divider sx={{ mb: 2 }} />

                        <CreateAccountForm
                            countries={countries}
                            getGeoLocation={getGeoLocation}
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            isFetchingCountries={isFetchingCountries}
                            isLoadingSession={isLoadingSession}
                            errors={errors}
                        />

                        <Box sx={{ textAlign: 'center' }}>
                            <Button
                                component={RouterLink}
                                fullWidth
                                startIcon={<AccountCircleIcon />}
                                to="/login"
                                variant="text"
                                sx={{ textTransform: 'none' }}
                            >
                                {t('Log in')}
                            </Button>
                        </Box>
                    </Box>
                </OpacityAnimation>
            </StyledGrid>

            <StyledCol
                size={{ md: 9 }}
                sx={{
                    display: { lg: 'flex', xs: 'none' },
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <SlidesContainer
                    alignItems="center"
                    slides={slides}
                    textAlign="center"
                />
                <Box sx={{ p: 2 }}>
                    <SocialMediaFooterContainer />
                </Box>
            </StyledCol>
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    countries: selectors.getDataFetchCountries,
    errors: selectors.getErrorsLogin,
    initialValues: selectors.getInitialValues,
    isFetchingCountries: selectors.getIsFetchingContries,
    isLoadingSession: selectors.getIsLoadingSession,
});

const mapDispatchToProps = (dispatch) => ({
    createAccount: (data, loadingContext) =>
        dispatch(createAccount(data, loadingContext)),
    fetchCountries: () => dispatch(fetchCountries()),
    getGeoLocation: (items, lat, lng) =>
        dispatch(getGeoLocation(items, lat, lng)),
    redirect: (link) => dispatch(push(link)),
});

Container.propTypes = {
    countries: PropTypes.array,
    createAccount: PropTypes.func,
    errors: PropTypes.array,
    fetchCountries: PropTypes.func,
    getGeoLocation: PropTypes.func,
    initialValues: PropTypes.object,
    isFetchingCountries: PropTypes.bool,
    isLoadingSession: PropTypes.bool,
    redirect: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
