import { payInvoices } from 'common/api/v1/chargebee';

import fetchUserSettings from '../../profile/actions/fetchProfileData';
import {
    PAY_INVOICES,
    PAY_INVOICES_FAILURE,
    PAY_INVOICES_SUCCESS,
} from '../actionTypes';
import { profileInvoiceActions } from '../reducer';

export default (callback = null) =>
    (dispatch) => {
        dispatch(profileInvoiceActions[PAY_INVOICES]());

        payInvoices()
            .then((response) => {
                dispatch(
                    profileInvoiceActions[PAY_INVOICES_SUCCESS](response.data),
                );
                dispatch(fetchUserSettings());
                if (callback) callback();
            })
            .catch((error) => {
                dispatch(
                    profileInvoiceActions[PAY_INVOICES_FAILURE](
                        error?.response?.data?.errors,
                    ),
                );
                if (callback) callback();
            });
    };
