import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Container as MaterialContainer } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { getSessionLocalStorage } from 'common/utils/helpers/session';

const MainContainer = ({ canView }) => {
    const theme = useTheme();
    const session = getSessionLocalStorage();
    const { email, first_name, last_name } = session;

    if (!canView) return null;

    return (
        <MaterialContainer maxWidth={false}>
            <HeaderContainer>
                <TitleIcon fontWeight="bold" title="Zapier" />
            </HeaderContainer>

            <zapier-full-experience
                app-search-bar-display="show"
                client-id="SDCm37KHkplls6lo2kVpR4IxvQZYjBYFKrgfQQ9v"
                sign-up-email={email}
                sign-up-first-name={first_name}
                sign-up-last-name={last_name}
                theme={theme.palette.mode}
            />
        </MaterialContainer>
    );
};

MainContainer.propTypes = {
    canView: PropTypes.bool,
};

export default withPermissions(PERMISSION_LIST.ZAPIER_PERMISSION)(
    MainContainer,
);
