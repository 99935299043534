import toNumber from 'lodash/toNumber';

import { updateOferDiscount } from 'common/api/v2/offerDiscount';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import {
    SAVE_DISCOUNT,
    SAVE_DISCOUNT_SUCCESS,
    SAVE_DISCOUNT_FAILURE,
} from '../actionTypes';
import { proposalGeneratorQuoteActions } from '../reducer';

export default (proposalId, values, callback) => (dispatch) => {
    const newValues = {
        overall_discount_amount: toNumber(values.discount_amount),
        overall_discount: toNumber(values.discount_percentage),
        price_per_watt: toNumber(values.price_per_watt),
        subtotal_amount: toNumber(values.subtotal_amount),
        type_discount: values.type_discount,
    };

    dispatch(proposalGeneratorQuoteActions[SAVE_DISCOUNT]());

    updateOferDiscount(proposalId, newValues)
        .then((response) => {
            dispatch(
                proposalGeneratorQuoteActions[SAVE_DISCOUNT_SUCCESS](
                    response.data.data,
                ),
            );
            dispatch(eventsAfterUpdate(response.data.data, proposalId));
            if (callback) callback();
        })
        .catch((error) => {
            dispatch(
                proposalGeneratorQuoteActions[SAVE_DISCOUNT_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
