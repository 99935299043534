import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Divider,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Switch,
} from 'sunwise-ui';

const TooltipVisibilityControls = ({ setTooltipConfig, tooltipConfig }) => {
    const { t } = useTranslation();

    const handleOnChange = (event) =>
        setTooltipConfig((prev) => ({ ...prev, view: event.target.value }));

    const handleOnClick = () => {
        setTooltipConfig((prev) => ({
            ...prev,
            view: prev.view === 'all' ? 'none' : 'all',
        }));
    };

    return (
        <>
            {setTooltipConfig && tooltipConfig && (
                <Box>
                    <Divider />

                    <MenuItem>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={tooltipConfig.view !== 'all'}
                                    onClick={handleOnClick}
                                />
                            }
                            label={t('Hide details on hover')}
                        />
                    </MenuItem>

                    {tooltipConfig.view !== 'all' && (
                        <RadioGroup name="radio-buttons-group">
                            <MenuItem sx={{ pl: 4 }}>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={
                                                tooltipConfig.view === 'none'
                                            }
                                            onChange={handleOnChange}
                                        />
                                    }
                                    label={t('All text', { count: 2 })}
                                    value="none"
                                />
                            </MenuItem>
                            <MenuItem sx={{ pl: 4 }}>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={
                                                tooltipConfig.view === 'nearest'
                                            }
                                            onChange={handleOnChange}
                                        />
                                    }
                                    label={t('Except the nearest value')}
                                    value="nearest"
                                />
                            </MenuItem>
                        </RadioGroup>
                    )}
                </Box>
            )}
        </>
    );
};

TooltipVisibilityControls.propTypes = {
    setTooltipConfig: PropTypes.func,
    tooltipConfig: PropTypes.object,
};

export default TooltipVisibilityControls;
