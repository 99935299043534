import React from 'react';

import PropTypes from 'prop-types';
import { Grid } from 'sunwise-ui';

import FinancialProductImage from 'resources/financialProduct.png';

import ProductItem from './ProductItem';

const CardList = ({ handleClickFinancialProduct, isLocked, items }) => (
    <Grid container py={2}>
        {items.map((product) => (
            <Grid key={product.id} size={{ lg: 3, md: 5, xl: 4, xs: 9 }}>
                <ProductItem
                    disabled={isLocked}
                    name={product.name}
                    onClick={() => handleClickFinancialProduct(product)}
                    srcImg={FinancialProductImage}
                />
            </Grid>
        ))}
    </Grid>
);

CardList.propTypes = {
    handleClickFinancialProduct: PropTypes.func,
    isLocked: PropTypes.bool,
    items: PropTypes.array,
};

export default CardList;
