import React from 'react';

import { Alert } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';

import { INTEGRATION_TYPES } from '../constants';

import ZapSection from './ZapSection';

const IntegrationSection = ({ control, setValue, disabled, errors }) => {
    const [cfeUser, integrationType, serviceNumber, zapRpuId] = useWatch({
        control,
        name: ['cfe_user', 'integration_type', 'service_number', 'zap_rpu_id'],
    });

    const { t } = useTranslation();

    return (
        <>
            <ReactHookFormSelect
                control={control}
                disabled={disabled}
                isClearable
                label={t('Integration')}
                name="integration_type"
                onChange={() => {
                    setValue('zap_rpu_id', null);
                    setValue('cfe_user', null);
                }}
                options={[
                    {
                        value: '',
                        label: t('Select an option'),
                    },
                    {
                        value: INTEGRATION_TYPES.LISA_ENERGY,
                        label: 'LISA',
                    },
                ]}
                sx={{ width: { xs: '100%', md: '49%' } }}
                variant="standard"
            />

            {integrationType == INTEGRATION_TYPES.LISA_ENERGY && (
                <ZapSection
                    cfeUser={cfeUser}
                    disabled={disabled}
                    onLink={(rpuData) => {
                        setValue('zap_rpu_id', rpuData?.rpu_id, {
                            shouldValidate: true,
                        });
                        setValue('cfe_user', rpuData?.cfe_user, {
                            shouldValidate: true,
                        });
                        setValue('service_number', rpuData?.service_number, {
                            shouldValidate: true,
                        });
                    }}
                    serviceNumber={serviceNumber}
                    zapRpuId={zapRpuId}
                />
            )}

            {(errors?.cfe_user || errors?.zap_rpu_id) && (
                <Alert severity="error">
                    {t('Link the integration to continue with the process')}
                </Alert>
            )}
        </>
    );
};

IntegrationSection.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    setValue: PropTypes.func,
};

export default IntegrationSection;
