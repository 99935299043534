import React, { useEffect, useState } from 'react';

import { Step, StepButton, StepLabel, Stepper } from '@mui/material';
import { Alert, Box, Button } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import lisaFiles from 'common/modules/lisaFiles';
import yupResolver from 'common/utils/yupResolver';

import * as afterSalesMeasurementSourceSelectors from '../../../afterSalesMeasurementSource/selectors';
import * as afterSalesSettingsSelectors from '../../../afterSalesSettings/selectors';
import * as actions from '../../actions';
import { FORM_FIELDS_BY_SECTION, UPLOAD_ORIGINS } from '../../constants';
import {
    getConsumedBag,
    getHasBagEnergyField,
    populateFieldsFromIntegration,
    populateFieldsFromMeasurements,
    resetEnergyFields,
    validateEnergyBalance,
} from '../../helpers';
import * as selectors from '../../selectors';
import validate from '../../validate';

import EnergyStep from './EnergyStep';
import GeneralStep from './GeneralStep';
import MonitoringStep from './MonitoringStep';
import UploadStep from './UploadStep';

const Form = ({
    compensationScheme,
    fetchAvailableBag,
    fetchLisaFiles,
    handleCloseImportModal,
    handleFetchMeasuredEnergy,
    hasPowerStations,
    initialValues,
    integrationEnergyData,
    isFetchingCupsInfo,
    isFetchingMeasuredEnergy,
    isFetchingRateConfiguration,
    isSaving,
    measuredEnergy,
    onClose,
    readOnly,
    rateConfiguration,
    ratesDictionary,
    resetForm,
    save,
    selectedProject,
    setReadOnly,
}) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [energyBalanceErrors, setEnergyBalanceErrors] = useState({});

    const {
        control,
        formState: { errors, isSubmitted, isValid },
        getValues,
        handleSubmit,
        reset,
        setValue,
        trigger,
        watch,
    } = useForm({
        context: { ratesDictionary },
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    const { t } = useTranslation();

    const [rateId, uploadOrigin] = watch(['rate', 'upload_origin']);
    const rate = ratesDictionary[rateId];
    const disabled =
        readOnly ||
        isFetchingCupsInfo ||
        isFetchingMeasuredEnergy ||
        isFetchingRateConfiguration ||
        isSaving;

    useEffect(() => {
        if (!readOnly && currentStep > 1) setCurrentStep(1);
    }, [readOnly]);

    useEffect(() => {
        return () => resetForm();
    }, []);

    useEffect(() => {
        if (!integrationEnergyData?.energy && !integrationEnergyData?.total)
            return;
        setCurrentStep(1);
    }, [integrationEnergyData]);

    useEffect(() => {
        const newValues = resetEnergyFields({
            formValues: initialValues,
            rate: ratesDictionary?.[rateId],
            rateConfiguration,
            reset,
        });

        setEnergyBalanceErrors(validateEnergyBalance(newValues));
    }, [initialValues]);

    useEffect(() => {
        const newValues = resetEnergyFields({
            formValues: getValues(),
            rate: ratesDictionary?.[rateId],
            rateConfiguration,
            reset,
        });
        setEnergyBalanceErrors(validateEnergyBalance(newValues));
    }, [rateConfiguration, ratesDictionary?.[rateId]]);

    const handleOnClose = () => onClose();

    const handleOnClickSubmit = (values) => {
        save({ callback: handleOnClose, project: selectedProject?.id, values });
    };

    const handleChangeAvailableBag = (value) => {
        if (!value) return;
        const consumedBag = getConsumedBag(getValues('total'), value);
        setValue('applied_bag', consumedBag);
    };

    const handleClickBack = () => setCurrentStep((prev) => prev - 1);
    const handleClickNext = async (fields, callback) => {
        if (readOnly) return setCurrentStep((prev) => prev + 1);

        const isValid = await trigger(fields);

        if (!isValid) return;

        setCurrentStep((prev) => prev + 1);

        if (callback) callback();
    };

    const steps = [
        {
            label: 'Upload',
            value: 'upload',
            content: (
                <UploadStep
                    control={control}
                    disabled={disabled}
                    getValues={getValues}
                    handleClickBack={handleOnClose}
                    handleClickNext={() =>
                        handleClickNext(FORM_FIELDS_BY_SECTION.UPLOAD)
                    }
                    handleCloseImportModal={handleCloseImportModal}
                    readOnly={readOnly}
                    selectedProject={selectedProject}
                />
            ),
        },
        {
            label: 'Electric bill',
            value: 'bill',
            content: (
                <GeneralStep
                    compensationScheme={compensationScheme}
                    control={control}
                    disabled={disabled}
                    errors={errors}
                    getValues={getValues}
                    handleClickBack={handleClickBack}
                    handleClickNext={() => {
                        handleClickNext(FORM_FIELDS_BY_SECTION.GENERAL, () => {
                            const formValues = getValues();

                            populateFieldsFromIntegration({
                                formValues,
                                fields: integrationEnergyData,
                                fieldsConfig: formValues.integration_energy,
                                setValue,
                            });

                            resetEnergyFields({
                                formValues: getValues(),
                                rateConfiguration,
                                rate,
                                reset,
                            });

                            setEnergyBalanceErrors(
                                validateEnergyBalance(getValues()),
                            );

                            if (formValues.uploadOrigin !== UPLOAD_ORIGINS.CSV)
                                handleFetchMeasuredEnergy({
                                    finalDate: getValues('final_date'),
                                    initialDate: getValues('initial_date'),
                                    projectId: selectedProject?.id,
                                    setValue,
                                });

                            if (getHasBagEnergyField(rate, compensationScheme))
                                fetchAvailableBag(
                                    selectedProject?.id,
                                    getValues('final_date'),
                                    handleChangeAvailableBag,
                                );
                        });
                    }}
                    rate={rate}
                    rateConfiguration={rateConfiguration}
                    ratesDictionary={ratesDictionary}
                    readOnly={readOnly}
                    reset={reset}
                    setValue={setValue}
                />
            ),
        },
    ];

    if (!readOnly && hasPowerStations && uploadOrigin !== UPLOAD_ORIGINS.CSV)
        steps.push({
            label: 'Monitoring',
            value: 'monitoring',
            content: (
                <MonitoringStep
                    control={control}
                    disabled={disabled}
                    handleClickBack={handleClickBack}
                    handleClickNext={() => {
                        handleClickNext(
                            FORM_FIELDS_BY_SECTION.MONITORING,
                            () => {
                                populateFieldsFromMeasurements({
                                    data: measuredEnergy,
                                    getValues,
                                    rate,
                                    rateConfiguration,
                                    setValue,
                                });

                                resetEnergyFields({
                                    formValues: getValues(),
                                    rateConfiguration,
                                    rate,
                                    reset,
                                });

                                setEnergyBalanceErrors(
                                    validateEnergyBalance(getValues()),
                                );
                            },
                        );
                    }}
                    isFetchingMeasuredEnergy={isFetchingMeasuredEnergy}
                    measuredEnergy={measuredEnergy}
                    readOnly={readOnly}
                />
            ),
        });

    steps.push({
        label: 'Energy balance',
        value: 'balance',
        content: (
            <EnergyStep
                compensationScheme={compensationScheme}
                control={control}
                disabled={disabled}
                disabledNext={isSubmitted && !isValid}
                energyBalanceErrors={energyBalanceErrors}
                getValues={getValues}
                handleChangeAvailableBag={handleChangeAvailableBag}
                handleClickBack={handleClickBack}
                rate={rate}
                readOnly={readOnly}
                setEnergyBalanceErrors={setEnergyBalanceErrors}
                setValue={setValue}
            />
        ),
    });

    return (
        <>
            {readOnly && (
                <Alert
                    severity="warning"
                    sx={{ '.MuiAlert-message': { width: '100%' } }}
                    variant="filled"
                >
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        gap={1}
                        justifyContent="space-between"
                    >
                        {t(
                            'You are in read-only mode. You can only view the data, but not edit it',
                        )}

                        <Box
                            textAlign="right"
                            width={{ xs: '100%', md: 'auto' }}
                        >
                            <Button
                                color="secondary"
                                onClick={() => setReadOnly(false)}
                                sx={{ m: '0!important' }}
                            >
                                {t('Edit')}
                            </Button>
                        </Box>
                    </Box>
                </Alert>
            )}

            <form onSubmit={handleSubmit(handleOnClickSubmit)}>
                <Stepper
                    activeStep={currentStep}
                    alternativeLabel
                    sx={{
                        mb: 2,
                        overflowX: { xs: 'auto', sm: 'hidden' },
                        overflowY: 'hidden',
                        py: 1,
                    }}
                >
                    {steps.map(({ label, value }, index) => (
                        <Step key={value}>
                            <StepButton
                                color="inherit"
                                disabled={disabled || index > currentStep}
                                onClick={() => setCurrentStep(index)}
                            >
                                <StepLabel>{t(label)}</StepLabel>
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>

                {steps[currentStep]?.content}
            </form>

            <lisaFiles.Container
                onCompleteUpload={() =>
                    fetchLisaFiles({ getValues, project: selectedProject })
                }
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    compensationScheme:
        afterSalesSettingsSelectors.getAfterSalesSettingsCompensationScheme,
    hasPowerStations: afterSalesMeasurementSourceSelectors.getHasPowerStations,
    initialValues: selectors.getInitialValues,
    integrationEnergyData: selectors.getIntegrationEnergyData,
    isFetchingCupsInfo: selectors.getIsFetchingCupsInfo,
    isFetchingMeasuredEnergy: selectors.getIsFetchingMeasuredEnergy,
    isFetchingRateConfiguration:
        afterSalesSettingsSelectors.getIsFetchingScheduleRateConfiguration,
    isSaving: selectors.getIsSavingData,
    measuredEnergy: selectors.getMeasuredEnergyData,
    readOnly: selectors.getIsReadOnly,
    rateConfiguration: afterSalesSettingsSelectors.getScheduleRateConfiguration,
    ratesDictionary: afterSalesSettingsSelectors.getRatesDictionary,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAvailableBag: (projectId, date, callback) =>
        dispatch(actions.fetchAvailableBag(projectId, date, callback)),
    fetchLisaFiles: (params) => dispatch(actions.fetchLisaFiles(params)),
    handleFetchMeasuredEnergy: (params) =>
        dispatch(actions.handleFetchMeasuredEnergy(params)),
    resetForm: () => dispatch(actions.resetForm()),
    save: (params) => dispatch(actions.save(params)),
    setReadOnly: (value) => dispatch(actions.setReadOnly(value)),
});

Form.propTypes = {
    compensationScheme: PropTypes.string,
    fetchAvailableBag: PropTypes.func,
    fetchLisaFiles: PropTypes.func,
    handleCloseImportModal: PropTypes.func,
    handleFetchMeasuredEnergy: PropTypes.func,
    hasPowerStations: PropTypes.bool,
    initialValues: PropTypes.object,
    integrationEnergyData: PropTypes.object,
    isFetchingCupsInfo: PropTypes.bool,
    isFetchingMeasuredEnergy: PropTypes.bool,
    isFetchingRateConfiguration: PropTypes.bool,
    isSaving: PropTypes.bool,
    measuredEnergy: PropTypes.object,
    onClose: PropTypes.func,
    readOnly: PropTypes.bool,
    rateConfiguration: PropTypes.object,
    ratesDictionary: PropTypes.object,
    resetForm: PropTypes.func,
    save: PropTypes.func,
    selectedProject: PropTypes.object,
    setReadOnly: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
