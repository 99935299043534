import React, { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Card, Grid } from 'sunwise-ui';

import { BulkEditionMenu, InputSearch } from 'common/components';
import { ReactHookFormInputDatePicker } from 'common/components/form/bootstrap';
import { getIsOnlyContacts } from 'common/utils/helpers/session';

const BulkOperationsCard = ({
    filterData,
    handleClickBulkItems,
    handleClickEndDate,
    handleClickStartDate,
    handleOnChangeSearch,
    initialDates,
    selectionModel,
    setSelectionModel,
}) => {
    const { t } = useTranslation();
    const isOnlyContacts = getIsOnlyContacts();
    const { control, reset } = useForm({
        defaultValues: initialDates,
    });

    useEffect(() => reset(initialDates), [initialDates]);

    const callback = () => setSelectionModel([]);

    return (
        <Card sx={{ mb: '16px !important' }}>
            <Card.Body>
                <Grid alignItems="start" container justifyContent="start">
                    <Grid
                        size={{ lg: 18, md: isOnlyContacts ? 18 : 9, xs: 18 }}
                    >
                        <InputSearch
                            id="input-search"
                            onChange={handleOnChangeSearch}
                            placeholder={t('Search')}
                            value={filterData?.searchText}
                        />
                    </Grid>

                    <Grid size={{ md: 9, xs: 18 }}>
                        <ReactHookFormInputDatePicker
                            control={control}
                            fullWidth
                            label={t('Start date')}
                            maxDate={new Date()}
                            name="start_date"
                            onChange={(date) => handleClickStartDate(date)}
                            variant="standard"
                        />
                    </Grid>

                    <Grid size={{ md: 9, xs: 18 }}>
                        <ReactHookFormInputDatePicker
                            control={control}
                            fullWidth
                            label={t('Final date')}
                            maxDate={new Date()}
                            name="end_date"
                            onChange={(date) => handleClickEndDate(date)}
                            variant="standard"
                        />
                    </Grid>

                    {!isEmpty(selectionModel) && (
                        <Grid
                            size={{ lg: 4, xs: 18 }}
                            sx={{ order: { md: 1, xs: 2 } }}
                        >
                            <BulkEditionMenu
                                callback={callback}
                                items={[
                                    {
                                        label: t('Archive'),
                                        handleClick: () =>
                                            handleClickBulkItems(
                                                selectionModel,
                                                callback,
                                            ),
                                        visible: true,
                                    },
                                ]}
                                selectionModel={selectionModel}
                            />
                        </Grid>
                    )}
                </Grid>
            </Card.Body>
        </Card>
    );
};

BulkOperationsCard.propTypes = {
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleClickEndDate: PropTypes.func,
    handleClickStartDate: PropTypes.func,
    handleOnChangeSearch: PropTypes.func,
    initialDates: PropTypes.object,
    selectionModel: PropTypes.array,
    setSelectionModel: PropTypes.func,
};

export default BulkOperationsCard;
