import React, { useEffect, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, MenuItem, Menu, Checkbox } from 'sunwise-ui';

import { getProductsToSelect } from '../../../../modules/taxesConfiguration/helpers';
import {
    getDropdownProductsLabel,
    handleClickAllBuild,
    handleClickItemBuild,
} from '../helpers';

const DropdownProducts = ({ defaultValue, handleOnSelectedProducts, id }) => {
    const { t } = useTranslation();
    const [products, setProducts] = useState(getProductsToSelect());
    const [selectedProducts, setSelectedProducts] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (defaultValue) {
            const newProducts = products.map((product) => {
                if (defaultValue.includes(product.value))
                    return { ...product, checked: true };
                return product;
            });
            setProducts(newProducts);
            setSelectedProducts(defaultValue);
        }
    }, [defaultValue]);

    const handleClick = (event) => setAnchorEl(event.currentTarget);

    const handleClose = () => {
        setAnchorEl(null);
        handleOnSelectedProducts(id, selectedProducts);
    };

    const handleClickAll = () =>
        handleClickAllBuild(
            products,
            selectedProducts,
            setProducts,
            setSelectedProducts,
        );

    const handleClickItem = (value, checked) =>
        handleClickItemBuild(
            checked,
            products,
            selectedProducts,
            setProducts,
            setSelectedProducts,
            value,
        );

    return (
        <>
            <Button
                color="secondary"
                display="flex"
                endIcon={<KeyboardArrowDownIcon />}
                fullWidth
                onClick={handleClick}
                sx={{ justifyContent: 'space-between', textTransform: 'none' }}
                variant="outlined"
            >
                {getDropdownProductsLabel(products, selectedProducts)}
            </Button>

            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={() => handleClickAll()}>
                    <Checkbox
                        checked={selectedProducts.length === products.length}
                    />
                    {t('All text', { context: 'male', count: 2 })}
                </MenuItem>
                {products.map((item, index) => {
                    return (
                        <MenuItem
                            key={`dropdown-menuitem-${index}`}
                            onClick={() =>
                                handleClickItem(item.value, !item.checked)
                            }
                        >
                            <Checkbox
                                checked={item.checked}
                                id={`dropdown-item-${index}`}
                            />
                            {item.label}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

DropdownProducts.propTypes = {
    defaultValue: PropTypes.array,
    handleOnSelectedProducts: PropTypes.func,
    id: PropTypes.string,
};

export default DropdownProducts;
