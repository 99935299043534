import React from 'react';

import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import { CheckAccess } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import { useBreakpoint } from 'common/hooks';
import {
    getSubscriptionStatus,
    getSubscriptionsStatus,
} from 'common/utils/helpers';

import * as profileSelectors from '../../../modules/profile/selectors';

import { handleOnMouseHover } from './helpers';
import StyledListItemButton from './StyledListItemButton';
import StyledListItemText from './StyledListItemText';
import UpgradeIcon from './UpgradeIcon';

const AnalyticsOptions = ({
    handleClose,
    handleDrawerToggle,
    match,
    mobileOpen,
    redirect,
    subscription,
    subscriptions,
    subscriptionVersion,
}) => {
    const breakpoint = useBreakpoint();
    const { allHasPaymentDue, isMultipleSubscription } =
        getSubscriptionsStatus(subscriptions);
    const { isActive, isPaymentDue } = getSubscriptionStatus(subscription);

    const isDisabledV4 =
        subscriptionVersion === 'v4' && isActive && isPaymentDue;
    const isDisabledV5 =
        (subscriptionVersion === 'v5' &&
            !isMultipleSubscription &&
            isActive &&
            isPaymentDue) ||
        (subscriptionVersion === 'v5' &&
            isMultipleSubscription &&
            allHasPaymentDue);

    const onMouseLeave = (e) =>
        handleOnMouseHover({ e, breakpoint, handleClose });

    return (
        <Box onMouseLeave={onMouseLeave}>
            <CheckAccess
                requiredPermissions={[PERMISSION_LIST.ANALYTICS_PERMISSION]}
            >
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/analytics');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/analytics' === match.url}
                    variant="horizontal"
                >
                    <SignalCellularAltIcon />
                    <StyledListItemText primary="Sunwise" />
                    <UpgradeIcon moduleName="Analytics-Sunwise,Modulo-Sunwise-Analytics" />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess requiredPermissions={[PERMISSION_LIST.CFE_PERMISSION]}>
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/analytics-cfe');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/analytics-cfe' === match.url}
                    variant="horizontal"
                >
                    <SignalCellularAltIcon />
                    <StyledListItemText primary="CFE" />
                    <UpgradeIcon moduleName="Analytics-CFE,Modulo-Sunwise-Analytics" />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess requiredPermissions={[PERMISSION_LIST.CRE_PERMISSION]}>
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/analytics-cre');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/analytics-cre' === match.url}
                    variant="horizontal"
                >
                    <SignalCellularAltIcon />
                    <StyledListItemText primary="CRE" />
                    <UpgradeIcon moduleName="Analytics-CRE,Modulo-Sunwise-Analytics" />
                </StyledListItemButton>
            </CheckAccess>
        </Box>
    );
};

AnalyticsOptions.propTypes = {
    handleClose: PropTypes.func,
    handleDrawerToggle: PropTypes.func,
    match: PropTypes.object,
    mobileOpen: PropTypes.bool,
    redirect: PropTypes.func,
    subscription: PropTypes.object,
    subscriptions: PropTypes.array,
    subscriptionVersion: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    subscription: profileSelectors.getMainSubscription,
    subscriptions: profileSelectors.getSubscriptions,
    subscriptionVersion: profileSelectors.getSubscriptionVersion,
});

export default connect(mapStateToProps, null)(AnalyticsOptions);
