import React from 'react';

import FolderIcon from '@mui/icons-material/Folder';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Box, IconButton, Tooltip, Typography } from 'sunwise-ui';

import { SUBSCRIPTION_STATUS } from 'common/constants';
import { parseDate, formatDate } from 'common/utils/dates';

const ProjectsIconTooltip = ({ isDarkMode, subscription, sxIcon }) => {
    const { t } = useTranslation();
    const { credits, next_billing_at } = subscription || {};
    const availableProjects = get(credits, 'available_projects', 0);
    const subscriptionStatus = get(subscription, 'status.key', null);
    const isCancelled = subscriptionStatus === SUBSCRIPTION_STATUS.CANCELLED;

    if (isCancelled) return null;

    return (
        <Tooltip
            title={
                <Box flexDirection="column" my={1}>
                    <Box alignItems="center" display="flex" gap={2} mb={1}>
                        <Typography variant="caption">
                            {t('Available')}: {availableProjects}
                        </Typography>
                    </Box>
                    <Box alignItems="center" display="flex" gap={2} mb={1}>
                        <Typography variant="caption">
                            {t('Renewal date')}:{' '}
                            {formatDate(
                                parseDate(
                                    next_billing_at,
                                    'dd/MM/yyyy HH:mm:ss',
                                ),
                                'dd/MM/yyyy',
                            )}
                        </Typography>
                    </Box>
                </Box>
            }
        >
            <IconButton
                component={RouterLink}
                size="small"
                sx={{ position: 'relative' }}
                to={`/profile/subscription`}
            >
                <>
                    <FolderIcon sx={{ ...sxIcon, fontSize: 28 }} />
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            height: '24px',
                            justifyContent: 'center',
                            position: 'absolute',
                            width: '100%',
                        }}
                    >
                        <Typography
                            sx={{
                                color: isDarkMode ? '#1f3c53' : '#fff',
                                fontSize: 10,
                                fontWeight: 'bold',
                            }}
                        >
                            {availableProjects > 99 ? '99+' : availableProjects}
                        </Typography>
                    </Box>
                </>
            </IconButton>
        </Tooltip>
    );
};

ProjectsIconTooltip.propTypes = {
    isDarkMode: PropTypes.bool,
    subscription: PropTypes.object,
    sxIcon: PropTypes.object,
};

export default ProjectsIconTooltip;
