import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH,
    FETCH_FAILURE,
    FETCH_SUCCESS,
    INITIALIZE_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    SET_BRANDING,
    SET_CURRENT_VALUES,
    SET_THEME,
    SET_PREFERENCES,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    branding: {},
    currentValues: {
        birth_date: null,
        first_name: null,
        last_name: null,
        profile_image: null,
    },
    fetch: {
        data: {},
        errors: null,
        isFetching: true,
    },
    initialValues: {
        birth_date: '',
        confirm_password: '',
        email: '',
        first_name: '',
        id: '',
        last_name: '',
        new_password: '',
        old_password: '',
        profile_image: '',
    },
    preferences: { displaySubmenuOnHover: true },
    save: {
        data: {},
        errors: null,
        isSaving: false,
    },
    theme: 'light',
};

const profileSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH]: (state) => {
            state.fetch = { ...state.fetch, errors: [], isFetching: true };
        },
        [FETCH_FAILURE]: (state, action) => {
            state.fetch = {
                ...state.fetch,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SUCCESS]: (state, action) => {
            state.fetch = {
                ...state.fetch,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [SAVE]: (state) => {
            state.save = { ...state.save, errors: [], isSaving: true };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save = {
                ...state.save,
                date: action.payload,
                isSaving: false,
            };
        },
        [SET_BRANDING]: (state, action) => {
            state.branding = { ...state.branding, ...action.payload };
        },
        [SET_CURRENT_VALUES]: (state, action) => {
            state.currentValues = { ...state.currentValues, ...action.payload };
        },
        [SET_PREFERENCES]: (state, action) => {
            state.preferences = action.payload;
        },
        [SET_THEME]: (state, action) => {
            state.theme = action.payload;
        },
    },
});

export const profileActions = profileSlice.actions;

export default profileSlice.reducer;
