export const NAME = 'contact';
export const COLUMNS_CONFIG = {
    name: {
        label: 'Contact',
        is_active: true,
        required: true,
    },
    tracking: {
        label: 'Follow-up',
        is_active: true,
        required: false,
    },
    email: {
        label: 'Mail',
        is_active: true,
        required: false,
    },
    agent: {
        label: 'Solar advisor',
        is_active: true,
        required: false,
    },
    status_contact: {
        label: 'Type of contact',
        is_active: true,
        required: false,
    },
    contact_origin: {
        label: 'Source',
        is_active: true,
        required: false,
    },
    company_name: {
        label: 'Business',
        is_active: true,
        required: false,
    },
    branch_name: {
        label: 'Branch office',
        is_active: true,
        required: false,
    },
    created_at: {
        label: 'Oldest',
        is_active: true,
        required: false,
    },
};
export const POINTER_CURSOR = 'pointer';
export const STATUS_CONTACT_TYPE = 1;
