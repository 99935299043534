import { createSlice } from '@reduxjs/toolkit';

import {
    CONFIRM_SUBSCRIPTION,
    CONFIRM_SUBSCRIPTION_SUCCESS,
    CONFIRM_SUBSCRIPTION_FAILURE,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    confirmSubscription: {
        isSaving: false,
        errors: [],
    },
};

const restrictedAccessSalesSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [CONFIRM_SUBSCRIPTION]: (state) => {
            state.confirmSubscription.isSaving = true;
        },
        [CONFIRM_SUBSCRIPTION_FAILURE]: (state, action) => {
            state.confirmSubscription = {
                ...state.confirmSubscription,
                errors: action.payload,
                isSaving: false,
            };
        },
        [CONFIRM_SUBSCRIPTION_SUCCESS]: (state) => {
            state.confirmSubscription.isSaving = false;
        },
    },
});

export const restrictedAccessSalesActions = restrictedAccessSalesSlice.actions;

export default restrictedAccessSalesSlice.reducer;
