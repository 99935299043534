import { createSlice } from '@reduxjs/toolkit';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
    FETCH_PROPOSALS,
    FETCH_PROPOSALS_FAILURE,
    FETCH_PROPOSALS_SUCCESS,
    SAVE_BULK_TRACKINGS,
    SAVE_BULK_TRACKINGS_FAILURE,
    SAVE_BULK_TRACKINGS_SUCCESS,
    UPDATE_ITEMS_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    bulkUpdateItems: { errors: [], isSaving: false },
    fetchProposals: {
        data: { data: [], pagination: null },
        errors: [],
        isFetching: false,
    },
};

const proposalsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [BULK_UPDATE_ITEMS]: (state) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: [],
                isSaving: true,
            };
        },
        [BULK_UPDATE_ITEMS_FAILURE]: (state, action) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: action.payload,
                isSaving: false,
            };
        },
        [BULK_UPDATE_ITEMS_SUCCESS]: (state) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                isSaving: false,
            };
        },
        [FETCH_PROPOSALS]: (state) => {
            state.fetchProposals.isFetching = true;
        },
        [FETCH_PROPOSALS_FAILURE]: (state, action) => {
            state.fetchProposals = {
                ...state.fetchProposals,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PROPOSALS_SUCCESS]: (state, action) => {
            state.fetchProposals = {
                ...state.fetchProposals,
                data: action.payload,
                isFetching: false,
            };
        },
        [SAVE_BULK_TRACKINGS]: (state) => {
            state.saveTracking = {
                ...state.saveTracking,
                errors: [],
                isSaving: true,
            };
        },
        [SAVE_BULK_TRACKINGS_FAILURE]: (state, action) => {
            state.saveTracking = {
                ...state.saveTracking,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_BULK_TRACKINGS_SUCCESS]: (state, action) => {
            state.saveTracking = {
                ...state.saveTracking,
                data: action.payload,
                isSaving: false,
            };
        },
        [UPDATE_ITEMS_SUCCESS]: (state, action) => {
            state.fetchProposals.data.data = action.payload;
        },
    },
});

export const proposalsActions = proposalsSlice.actions;

export default proposalsSlice.reducer;
