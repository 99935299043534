import * as actions from "./actions";
import { NAME } from "./constants";
import Container from "./Container";
import reducer from "./reducer";
import * as selectors from "./selectors";

export default {
  actions,
  Container,
  NAME,
  reducer,
  selectors,
};
