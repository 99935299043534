import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, Typography } from 'sunwise-ui';

import EmptyAfterSales from 'resources/EmptyAfterSales.svg';
import EmptyBackground from 'resources/EmptyBackground.svg';

const StyledCard = styled(Card)`
    border: 0;
    box-shadow: none;
    height: 100%;
    margin-bottom: 0;
    text-align: center;
`;

const StyledCardBody = styled(Card.Body)`
    align-items: center;
    background-image: url(${EmptyBackground});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    min-height: 100%;
`;

const StyledImg = styled('img')`
    width: 248px;
    @media (min-width: 768px) and (max-width: 992px) {
        width: 200px;
    }
    @media (min-width: 0) and (max-width: 767px) {
        width: 140px;
    }
`;

const EmptyState = ({ canModify, disabled, handleOnClick }) => {
    const { t } = useTranslation();
    return (
        <StyledCard>
            <StyledCardBody>
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <Typography variant="h5">
                        {t('Unlock the power of after-sales management')}
                    </Typography>

                    <StyledImg
                        alt="aftersales management"
                        src={EmptyAfterSales}
                    />

                    <Typography variant="body2">
                        {t(
                            'Configure settings, customize your workflow, and create reports to enhance customer satisfaction',
                        )}
                    </Typography>

                    <Button
                        color="secondary"
                        disabled={disabled}
                        endIcon={<AddIcon />}
                        fullWidth
                        onClick={handleOnClick}
                        type="button"
                        variant="outlined"
                        visible={canModify}
                    >
                        {t('Start')}
                    </Button>
                </Box>
            </StyledCardBody>
        </StyledCard>
    );
};

EmptyState.propTypes = {
    canModify: PropTypes.bool,
    disabled: PropTypes.bool,
    handleOnClick: PropTypes.func,
};

export default EmptyState;
