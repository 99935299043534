import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Box, Skeleton, Typography } from 'sunwise-ui';

import StatusIndicatorTooltip from 'common/components/StatusIndicatorTooltip';

import Breadcrumbs from './Breadcrumbs';
import SupportControlDropdown from './SupportControlDropdown';

const ResponsiveHeader = ({
    canModify,
    customerMode,
    financialProductParameters,
    handleClickBreadcrumb,
    handleClickOpenConfigurationModal,
    hasSmartDocuments,
    isApproved,
    isClosed,
    isFetchingOfferDetails,
    isGenerated,
    isReadOnly,
    offerDetails,
    offerDetailsPattern,
    offerId,
    setCustomerMode,
}) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{ display: { md: 'none', xs: 'block' }, p: 2 }}
        >
            <Box alignItems="center" display="flex" gap={1}>
                <StatusIndicatorTooltip
                    isApproved={isApproved}
                    isGenerated={isGenerated}
                />
                {isFetchingOfferDetails ? (
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: '1rem', width: '100%' }}
                    />
                ) : (
                    <Typography variant="h4">
                        {get(offerDetails, 'name', null)}
                    </Typography>
                )}
                <SupportControlDropdown
                    canModify={canModify}
                    customerMode={customerMode}
                    financialProductParameters={financialProductParameters}
                    handleClickOpenConfigurationModal={
                        handleClickOpenConfigurationModal
                    }
                    hasSmartDocuments={hasSmartDocuments}
                    isApproved={isApproved}
                    isClosed={isClosed}
                    isGenerated={isGenerated}
                    isReadOnly={isReadOnly}
                    offerDetails={offerDetails}
                    offerId={offerId}
                    setCustomerMode={setCustomerMode}
                />
            </Box>
            <Box>
                {isFetchingOfferDetails ? (
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: '1rem', width: '100%' }}
                    />
                ) : (
                    <Breadcrumbs
                        handleClickBreadcrumb={handleClickBreadcrumb}
                        offerDetails={offerDetails}
                        offerDetailsPattern={offerDetailsPattern}
                    />
                )}
            </Box>
        </Box>
    );
};

ResponsiveHeader.propTypes = {
    canModify: PropTypes.bool,
    customerMode: PropTypes.bool,
    financialProductParameters: PropTypes.object,
    handleClickBreadcrumb: PropTypes.func,
    handleClickOpenConfigurationModal: PropTypes.func,
    hasSmartDocuments: PropTypes.bool,
    isApproved: PropTypes.bool,
    isClosed: PropTypes.bool,
    isFetchingOfferDetails: PropTypes.bool,
    isGenerated: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    offerDetails: PropTypes.object,
    offerDetailsPattern: PropTypes.object,
    offerId: PropTypes.string,
    setCustomerMode: PropTypes.func,
};

export default ResponsiveHeader;
