import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, Grid } from 'sunwise-ui';

import TitleWithDetail from 'common/components/TitleWithDetail';

const CardHeader = styled('div')`
    min-height: 60px;

    ${({ height }) =>
        height &&
        `
        height: ${height};
        min-height: initial;
    `}
`;

const SubTitle = styled(Box)`
    color: #848bab;
    font-size: 13px;
    line-height: 19px;
    padding-left: 10px;
`;

const TotalHeader = styled(Box)`
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    padding-left: 10px;
`;

const DashboardCardHeader = ({ height, subtitle, title, totalHeader }) => (
    <Grid container>
        <Grid size={{ xs: 'grow' }}>
            <CardHeader height={height}>
                <TitleWithDetail>{title}</TitleWithDetail>
                {subtitle && <SubTitle>{subtitle}</SubTitle>}
                {totalHeader && <TotalHeader>{totalHeader}</TotalHeader>}
            </CardHeader>
        </Grid>
    </Grid>
);

DashboardCardHeader.propTypes = {
    height: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    totalHeader: PropTypes.string,
};

export default DashboardCardHeader;
