import { getFinarciers } from 'common/api/v1/sunpay';

import {
    FETCH_FINANCIERS,
    FETCH_FINANCIERS_FAILURE,
    FETCH_FINANCIERS_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorAddAllianceActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(proposalGeneratorAddAllianceActions[FETCH_FINANCIERS]());

    getFinarciers()
        .then((response) => {
            dispatch(
                proposalGeneratorAddAllianceActions[FETCH_FINANCIERS_SUCCESS](
                    response.data,
                ),
            );
        })
        .catch((error) =>
            dispatch(
                proposalGeneratorAddAllianceActions[FETCH_FINANCIERS_FAILURE](
                    error,
                ),
            ),
        );
};
