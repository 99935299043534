import i18next from 'i18next';

export const getContactTypesOptions = (contacTypes) => {
    const items = contacTypes.map((item) => {
        return {
            label: item.name,
            value: item.id,
        };
    });

    return [
        {
            label: i18next.t('All text', { count: 2 }),
            value: 'all',
        },
        ...items,
    ];
};
