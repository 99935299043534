import { array, boolean, number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

import { ORIGINAL_SERIE_NAME } from './constants';

export default (values) => {
    const {
        INVALID_TEXT,
        MUST_BE_UNIQUE_TEXT,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
        MUST_BE_GREATER_THAN_ZERO,
    } = getValidationTexts();

    const numberValidation = number()
        .typeError(NUMBER_TYPE_TEXT)
        .required(REQUIRED_TEXT);

    return object().shape({
        devices: array().of(
            object().shape({
                backup: boolean().nullable(),
                device: numberValidation,
                device_name: string()
                    .required(REQUIRED_TEXT)
                    .test(
                        'unique-device-name',
                        MUST_BE_UNIQUE_TEXT,
                        function (value) {
                            const total = values?.devices?.filter(
                                (device) => device.device_name === value,
                            );

                            return total.length <= 1;
                        },
                    )
                    .test(
                        'invalid-device-name',
                        INVALID_TEXT,
                        (value) => value !== ORIGINAL_SERIE_NAME,
                    ),
                effective_power_current: numberValidation.min(
                    0,
                    MUST_BE_GREATER_THAN_ZERO,
                ),
                end_time: string().required(REQUIRED_TEXT),
                installed: boolean().nullable(),
                months: array().of(number()).min(1, REQUIRED_TEXT),
                power: numberValidation.min(0, MUST_BE_GREATER_THAN_ZERO),
                quantity: numberValidation.min(0, MUST_BE_GREATER_THAN_ZERO),
                soft_start: boolean().nullable(),
                start_time: string().required(REQUIRED_TEXT),
                unit: numberValidation,
                weekdays: array().of(number()).min(1, REQUIRED_TEXT),
            }),
        ),
    });
};
