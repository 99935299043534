import i18next from 'i18next';
import { get, isEmpty } from 'lodash';

import {
    MONTHLY_FREQUENCY,
    PLAN_TYPES,
    YEARLY_FREQUENCY,
} from 'common/constants';

import { ADDONS_LIST } from './constants';

export const getAddonList = (planType, productType) => {
    let addons = [];
    if (ADDONS_LIST[productType]) addons = ADDONS_LIST[productType];
    if (addons[planType]) return addons[planType];
    return [];
};

export const getBundleAddonList = (planType, category) => {
    const bundleAddons = ADDONS_LIST.bundle || {};
    const planAddons = bundleAddons[planType] || {};
    return planAddons[category] || [];
};

export const getFilteredPricing = (billingFrequency, pricingData) => {
    if (isEmpty(pricingData)) return [];
    return pricingData[billingFrequency].map((item) => ({
        ...item,
        frequency: billingFrequency,
    }));
};

export const validatedButton = ({
    fromLanding,
    noSubscriptions,
    selectedProductId,
    subscription,
    pricing,
}) => {
    const billingPeriod = get(subscription, 'billing_period_unit.key', null);
    const subscriptionType = get(subscription, 'type.key', null);
    const productType = get(subscription, 'product_type', null);
    const frequency = get(pricing, 'frequency', null);
    const planType = get(pricing, 'plan_type.key', 0);
    const version = get(subscription, 'version', null);

    if (noSubscriptions) {
        if (planType === PLAN_TYPES.ENTERPRISE)
            return {
                disabled: false,
                text: i18next.t('Contact us'),
                visible: true,
            };
        return { disabled: false, text: i18next.t('Select'), visible: true };
    }

    if (isEmpty(pricing))
        return { disabled: false, text: null, visible: false };

    if (subscription?.item_price_id === pricing.chargebee_price_id)
        return {
            disabled: fromLanding,
            text: fromLanding ? i18next.t('Subscribed') : i18next.t('Renew'),
            visible: true,
        };

    if (version === 'v4') {
        if (selectedProductId === 'sales') {
            if (planType === PLAN_TYPES.PRO && frequency === MONTHLY_FREQUENCY)
                return {
                    disabled: false,
                    text: i18next.t('Upgrade'),
                    visible: true,
                };
            if (planType === PLAN_TYPES.PRO && frequency === YEARLY_FREQUENCY)
                return {
                    disabled: false,
                    text: i18next.t('Upgrade'),
                    visible: true,
                };
            if (
                planType === PLAN_TYPES.SCALE &&
                frequency === MONTHLY_FREQUENCY
            )
                return {
                    disabled: false,
                    text: i18next.t('Upgrade'),
                    visible: true,
                };
            if (planType === PLAN_TYPES.SCALE && frequency === YEARLY_FREQUENCY)
                return {
                    disabled: false,
                    text: i18next.t('Upgrade'),
                    visible: true,
                };
            if (
                planType === PLAN_TYPES.ENTERPRISE &&
                frequency === MONTHLY_FREQUENCY
            )
                return {
                    disabled: false,
                    text: i18next.t('Contact us'),
                    visible: true,
                };
            if (
                planType === PLAN_TYPES.ENTERPRISE &&
                frequency === YEARLY_FREQUENCY
            )
                return {
                    disabled: false,
                    text: i18next.t('Contact us'),
                    visible: true,
                };
        } else {
            return { disabled: false, text: i18next.t('Get'), visible: true };
        }
    }

    if (productType === 'sales') {
        if (selectedProductId === 'sales') {
            if (
                subscriptionType === PLAN_TYPES.PRO &&
                billingPeriod === 'month'
            ) {
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.ENTERPRISE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Contact us'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.ENTERPRISE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Contact us'),
                        visible: true,
                    };
            } else if (
                subscriptionType === PLAN_TYPES.PRO &&
                billingPeriod === 'year'
            ) {
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.ENTERPRISE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Contact us'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.ENTERPRISE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Contact us'),
                        visible: true,
                    };
            } else if (
                subscriptionType === PLAN_TYPES.SCALE &&
                billingPeriod === 'month'
            ) {
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.ENTERPRISE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Contact us'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.ENTERPRISE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Contact us'),
                        visible: true,
                    };
            } else if (
                subscriptionType === PLAN_TYPES.SCALE &&
                billingPeriod === 'year'
            ) {
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.ENTERPRISE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Contact us'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.ENTERPRISE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Contact us'),
                        visible: true,
                    };
            } else if (
                subscriptionType === PLAN_TYPES.ENTERPRISE &&
                billingPeriod === 'month'
            ) {
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.ENTERPRISE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Contact us'),
                        visible: true,
                    };
            } else if (
                subscriptionType === PLAN_TYPES.ENTERPRISE &&
                billingPeriod === 'year'
            ) {
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.ENTERPRISE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Contact us'),
                        visible: true,
                    };
            }
        } else {
            return { disabled: false, text: i18next.t('Get'), visible: true };
        }
    }

    if (productType === 'aftersales') {
        if (selectedProductId === 'aftersales') {
            if (
                subscriptionType === PLAN_TYPES.PRO &&
                billingPeriod === 'month'
            ) {
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
            } else if (
                subscriptionType === PLAN_TYPES.PRO &&
                billingPeriod === 'year'
            ) {
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
            } else if (
                subscriptionType === PLAN_TYPES.SCALE &&
                billingPeriod === 'month'
            ) {
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
            } else if (
                subscriptionType === PLAN_TYPES.SCALE &&
                billingPeriod === 'year'
            ) {
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
            }
        } else {
            if (
                planType === PLAN_TYPES.ENTERPRISE &&
                frequency === MONTHLY_FREQUENCY
            )
                return {
                    disabled: false,
                    text: i18next.t('Contact us'),
                    visible: true,
                };
            if (
                planType === PLAN_TYPES.ENTERPRISE &&
                frequency === YEARLY_FREQUENCY
            )
                return {
                    disabled: false,
                    text: i18next.t('Contact us'),
                    visible: true,
                };
            return { disabled: false, text: i18next.t('Get'), visible: true };
        }
    }

    if (productType === 'bundle') {
        if (selectedProductId === 'bundle') {
            if (
                subscriptionType === PLAN_TYPES.PRO &&
                billingPeriod === 'month'
            ) {
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.ENTERPRISE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Contact us'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.ENTERPRISE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Contact us'),
                        visible: true,
                    };
            } else if (
                subscriptionType === PLAN_TYPES.PRO &&
                billingPeriod === 'year'
            ) {
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.ENTERPRISE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Contact us'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.ENTERPRISE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Contact us'),
                        visible: true,
                    };
            } else if (
                subscriptionType === PLAN_TYPES.SCALE &&
                billingPeriod === 'month'
            ) {
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.ENTERPRISE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Contact us'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.ENTERPRISE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Contact us'),
                        visible: true,
                    };
            } else if (
                subscriptionType === PLAN_TYPES.SCALE &&
                billingPeriod === 'year'
            ) {
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.ENTERPRISE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Contact us'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.ENTERPRISE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Contact us'),
                        visible: true,
                    };
            } else if (
                subscriptionType === PLAN_TYPES.ENTERPRISE &&
                billingPeriod === 'month'
            ) {
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.ENTERPRISE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Contact us'),
                        visible: true,
                    };
            } else if (
                subscriptionType === PLAN_TYPES.ENTERPRISE &&
                billingPeriod === 'year'
            ) {
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.PRO &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.SCALE &&
                    frequency === YEARLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Upgrade'),
                        visible: true,
                    };
                if (
                    planType === PLAN_TYPES.ENTERPRISE &&
                    frequency === MONTHLY_FREQUENCY
                )
                    return {
                        disabled: false,
                        text: i18next.t('Contact us'),
                        visible: true,
                    };
            }
        } else {
            if (
                planType === PLAN_TYPES.ENTERPRISE &&
                frequency === MONTHLY_FREQUENCY
            )
                return {
                    disabled: false,
                    text: i18next.t('Contact us'),
                    visible: true,
                };
            if (
                planType === PLAN_TYPES.ENTERPRISE &&
                frequency === YEARLY_FREQUENCY
            )
                return {
                    disabled: false,
                    text: i18next.t('Contact us'),
                    visible: true,
                };
            return { disabled: false, text: i18next.t('Get'), visible: true };
        }
    }

    return { disabled: false, text: i18next.t('Select'), visible: true }; // Caso por defecto general
};

export const getSxCard = (variant = 'secondary') => ({
    backgroundColor: variant === 'secondary' ? '#011e2e' : 'primary.main',
    py: 1,
    '&.Mui-expanded': {
        minHeight: '64px',
    },
});
