import { NAME } from "./constants";

export const FETCH_ALERTS = `${NAME}/FETCH_ALERTS`;
export const FETCH_ALERTS_FAILURE = `${NAME}/FETCH_ALERTS_FAILURE`;
export const FETCH_ALERTS_SUCCESS = `${NAME}/FETCH_ALERTS_SUCCESS`;

export const INITIALIZE_ALERTS_MODAL_FORM = `${NAME}/INITIALIZE_ALERTS_MODAL_FORM`;

export const RESET_ALERTS_MODAL_FORM = `${NAME}/RESET_ALERTS_MODAL_FORM`;

export const SAVE_ALERTS = `${NAME}/SAVE_ALERTS`;
export const SAVE_ALERTS_FAILURE = `${NAME}/SAVE_ALERTS_FAILURE`;
export const SAVE_ALERTS_SUCCESS = `${NAME}/SAVE_ALERTS_SUCCESS`;

export const SET_ALERTS_MODAL_IS_OPEN = `${NAME}/SET_ALERTS_MODAL_IS_OPEN`;
