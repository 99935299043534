import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import { useTheme, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Box, Card, Grid, Skeleton, Tooltip, Typography } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import { IconPanel } from 'common/components/icons';
import { numberFormat } from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';
import { scroll } from 'common/utils/mixins';

import * as actions from '../actions';
import { getPeriodLabel } from '../helpers';

const ListContainer = styled('div')`
    max-height: 400px;
    overflow-y: auto;
    padding: 0 8px;
    width: 100%;

    ${scroll.custom()}
`;

const ItemsList = ({
    canDelete,
    canModify,
    consumptionHistoryPagination,
    downloadFile,
    fetchConsumptionHistory,
    initializeReportForm,
    isLoading,
    items,
    prepareDelete,
    prepareUpdate,
    projectId,
    setModalFormOpenStatus,
    setReadOnly,
    setReportModalOpenStatus,
    year,
}) => {
    const { t } = useTranslation();
    const currencyLocale = getCountryCurrencyLocale();
    const theme = useTheme();
    const handleOnClickEdit = () => {
        setModalFormOpenStatus(true);
        setReadOnly(true);
    };

    const numberFormatOptions = {
        decimals: 0,
        locale: currencyLocale,
        style: 'decimal',
    };
    const sxCard = {
        backgroundColor: theme.palette.mode === 'dark' ? '#282b30' : '',
    };

    if (isLoading && !items.length)
        return [0, 1, 2].map((index) => (
            <Skeleton
                key={index}
                variant="rounded"
                height={70}
                sx={{ borderRadius: 2, mb: 1 }}
            />
        ));

    const prepareCreateReport = (historicalId, name = '') => {
        initializeReportForm({
            historical_id: historicalId,
            name,
            project: projectId,
        });

        setReportModalOpenStatus(true);
    };

    return (
        <ListContainer>
            <InfiniteScroll
                hasMore={!isLoading && consumptionHistoryPagination?.hasMore}
                loader={
                    <div className="scroll-loader" key={0}>
                        {t('Loading').concat('...')}
                    </div>
                }
                loadMore={(nextPage) => {
                    if (isLoading) return;
                    fetchConsumptionHistory({
                        page: nextPage,
                        projectId,
                        year,
                    });
                }}
                pageStart={1}
                threshold={100}
                useWindow={false}
            >
                {items.map((item) => {
                    const label = getPeriodLabel(item);
                    return (
                        <Card key={item.id} my={2} sx={sxCard}>
                            <Card.Body>
                                <Grid container spacing={0} alignItems="center">
                                    <Grid
                                        size={{ lg: 4, md: 16, sm: 4, xs: 16 }}
                                    >
                                        <Typography variant="subtitle2">
                                            {label}
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        display="flex"
                                        size={{ lg: 6, md: 9, sm: 6, xs: 9 }}
                                        order={{ lg: 2, md: 3, sm: 2, xs: 3 }}
                                    >
                                        <Tooltip
                                            title={t('Consumption from Grid')}
                                        >
                                            <Box
                                                alignItems="center"
                                                display="flex"
                                                gap={1}
                                            >
                                                <ElectricalServicesIcon
                                                    fontSize="small"
                                                    htmlColor="#20C4D9"
                                                />
                                                <Typography variant="body2">
                                                    {`${numberFormat(
                                                        item?.total
                                                            ?.grid_consumption
                                                            ?.value,
                                                        numberFormatOptions,
                                                    )} kWh`}
                                                </Typography>
                                            </Box>
                                        </Tooltip>
                                    </Grid>

                                    <Grid
                                        display="flex"
                                        size={{ lg: 6, md: 9, sm: 6, xs: 9 }}
                                        order={{ lg: 3, md: 4, sm: 3, xs: 4 }}
                                    >
                                        <Tooltip title={t('Total generation')}>
                                            <Box
                                                alignItems="center"
                                                display="flex"
                                                gap={1}
                                            >
                                                <IconPanel
                                                    fontSize="small"
                                                    htmlColor="#20C4D9"
                                                />
                                                <Typography variant="body2">
                                                    {`${numberFormat(
                                                        item?.total?.generation
                                                            ?.value,
                                                        numberFormatOptions,
                                                    )} kWh`}
                                                </Typography>
                                            </Box>
                                        </Tooltip>
                                    </Grid>

                                    <Grid
                                        size={2}
                                        order={{ lg: 4, md: 2, sm: 4, xs: 2 }}
                                        textAlign="right"
                                    >
                                        <DropdownCardMenu
                                            items={[
                                                {
                                                    handleClick: () =>
                                                        prepareCreateReport(
                                                            item.id,
                                                            label,
                                                        ),
                                                    icon: (
                                                        <AddIcon fontSize="small" />
                                                    ),
                                                    label: t('Create report'),
                                                    visible: canModify,
                                                },
                                                {
                                                    handleClick: () =>
                                                        prepareUpdate(
                                                            item,
                                                            handleOnClickEdit,
                                                        ),
                                                    icon: (
                                                        <EditIcon fontSize="small" />
                                                    ),
                                                    label: t('Edit'),
                                                    visible: canModify,
                                                },
                                                {
                                                    handleClick: () =>
                                                        prepareDelete(
                                                            item.id,
                                                            projectId,
                                                        ),
                                                    icon: (
                                                        <DeleteIcon fontSize="small" />
                                                    ),
                                                    label: t('Delete'),
                                                    visible: canDelete,
                                                },
                                                {
                                                    handleClick: () =>
                                                        downloadFile({
                                                            name: label,
                                                            url: item.file,
                                                            uploadOrigin:
                                                                item.upload_origin,
                                                        }),

                                                    icon: (
                                                        <DownloadIcon fontSize="small" />
                                                    ),
                                                    label: t('Download file'),
                                                    visible: Boolean(item.file),
                                                },
                                            ]}
                                            visible={
                                                canModify ||
                                                canDelete ||
                                                Boolean(item.file)
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Card.Body>
                        </Card>
                    );
                })}
            </InfiniteScroll>
        </ListContainer>
    );
};

ItemsList.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    consumptionHistoryPagination: PropTypes.object,
    downloadFile: PropTypes.func,
    fetchConsumptionHistory: PropTypes.func,
    initializeReportForm: PropTypes.func,
    isLoading: PropTypes.bool,
    items: PropTypes.array,
    prepareDelete: PropTypes.func,
    prepareUpdate: PropTypes.func,
    projectId: PropTypes.string,
    setModalFormOpenStatus: PropTypes.func,
    setReadOnly: PropTypes.func,
    setReportModalOpenStatus: PropTypes.func,
    year: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
    downloadFile: (params) => dispatch(actions.downloadFile(params)),
    fetchConsumptionHistory: (params) => dispatch(actions.fetchData(params)),
    initializeReportForm: (values) =>
        dispatch(actions.initializeReportForm(values)),
    prepareDelete: (uuid, projectId) =>
        dispatch(actions.prepareDelete(uuid, projectId)),
    prepareUpdate: (item, successCallback) =>
        dispatch(actions.prepareUpdate(item, successCallback)),
    setModalFormOpenStatus: (value) =>
        dispatch(actions.setModalFormOpenStatus(value)),
    setReadOnly: (value) => dispatch(actions.setReadOnly(value)),
    setReportModalOpenStatus: (value) =>
        dispatch(actions.setReportModalOpenStatus(value)),
});

export default connect(null, mapDispatchToProps)(ItemsList);
