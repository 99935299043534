import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Card, Grid, Typography } from 'sunwise-ui';

import { FlexRow } from 'common/utils/layout';

import EmptyBackground from 'resources/EmptyBackground.svg';

const CaptionText = styled(Typography)`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
    text-align: center;
`;
const RequirementText = styled(Typography)`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
    text-align: center;
`;
const StyledCard = styled(Card)`
    height: 100%;
    margin-bottom: 0;
    text-align: center;
`;

const StyledCardBody = styled(Card.Body)`
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    min-height: 100%;
`;
const StyledImg = styled('img')`
    width: 248px;
    @media (min-width: 768px) and (max-width: 992px) {
        width: 200px;
    }
    @media (min-width: 0) and (max-width: 767px) {
        width: 140px;
    }
`;
const TitleText = styled(Typography)`
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 0;
    text-align: center;
`;

const EmptyState = ({
    canModify,
    caption,
    classNameCard,
    classNameCardBody,
    classNameCol,
    customCaption,
    dataIntercomTarget,
    disabled,
    isLoading,
    linkManual,
    onClick,
    requirements = [],
    sizeImage = 248,
    srcImage,
    sxCard,
    textButton,
    title,
}) => {
    const { t } = useTranslation();
    return (
        <StyledCard className={classNameCard} sx={sxCard}>
            <StyledCardBody
                className={classNameCardBody}
                style={{ backgroundImage: `url('${EmptyBackground}')` }}
            >
                <FlexRow justifyContent="center" alignItems="center">
                    <Grid
                        className={classNameCol}
                        size={18}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                            alignItems: 'center',
                        }}
                    >
                        {srcImage && (
                            <StyledImg
                                src={srcImage}
                                style={{ width: sizeImage }}
                            />
                        )}
                        <TitleText>{title}</TitleText>
                        {caption && <CaptionText>{caption}</CaptionText>}
                        {customCaption ? customCaption : null}
                        {textButton && (
                            <Button
                                color="secondary"
                                data-intercom-target={dataIntercomTarget}
                                disabled={!canModify || disabled}
                                endIcon={<AddIcon />}
                                fullWidth
                                onClick={onClick}
                                type="button"
                                variant="outlined"
                            >
                                {textButton}
                            </Button>
                        )}
                        {!isLoading && requirements.length > 0 && (
                            <div>
                                {requirements.map((requirement, index) => (
                                    <RequirementText
                                        key={`requirement-item-${index}`}
                                    >
                                        {requirement}
                                    </RequirementText>
                                ))}
                            </div>
                        )}
                        {linkManual && (
                            <Button
                                color="secondary"
                                href={linkManual}
                                target="_blank"
                                variant="text"
                            >
                                {t('See manual')}
                            </Button>
                        )}
                    </Grid>
                </FlexRow>
            </StyledCardBody>
        </StyledCard>
    );
};

EmptyState.defaultProps = {
    canModify: true,
    classNameCard: '',
    classNameCardBody: '',
    classNameCol: '',
    customCaption: null,
    dataIntercomTarget: '',
    sxCard: {},
};

EmptyState.propTypes = {
    canModify: PropTypes.bool,
    caption: PropTypes.string,
    classNameCard: PropTypes.string,
    classNameCardBody: PropTypes.string,
    classNameCol: PropTypes.string,
    customCaption: PropTypes.object,
    dataIntercomTarget: PropTypes.string,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    linkManual: PropTypes.string,
    onClick: PropTypes.func,
    requirements: PropTypes.array,
    sizeImage: PropTypes.number,
    srcImage: PropTypes.string,
    sxCard: PropTypes.object,
    textButton: PropTypes.string,
    title: PropTypes.string,
};

export default EmptyState;
