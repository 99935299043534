import { FILE_ALLOWED_EXTENSIONS } from 'common/constants';
import uploadFilesLisa from 'common/modules/lisaFiles/actions/uploadFiles';

import { fetchProjects, fetchProjectDetails } from '../../project/actions';

import setConfigCsvImportModal from './setConfigCsvImportModal';
import uploadXML from './uploadXML';

export default ({ files, getValues, project }) =>
    (dispatch) => {
        if (!files?.length || !project) return;
        const [file] = files;
        const fileType = file?.type;
        if (FILE_ALLOWED_EXTENSIONS.CSV.includes(fileType))
            dispatch(setConfigCsvImportModal({ file, isOpen: true }));
        else if (FILE_ALLOWED_EXTENSIONS.XML.includes(fileType))
            dispatch(uploadXML({ file, getValues }));
        else
            dispatch(
                uploadFilesLisa({
                    files: [file],
                    onUpdatedProject: () => {
                        dispatch(
                            fetchProjects({
                                contactId: project.contact?.id || null,
                            }),
                        );

                        dispatch(fetchProjectDetails(project.id));
                    },
                    overwrite: false,
                    project,
                }),
            );
    };
