import React from 'react';

import ColorLensIcon from '@mui/icons-material/ColorLens';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import { CheckAccess } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import { useBreakpoint } from 'common/hooks';
import {
    getSubscriptionStatus,
    getSubscriptionsStatus,
} from 'common/utils/helpers';

import BranchOfficesIcon from 'resources/branchOfficesIcon.svg?react';
import GeneralIcon from 'resources/generalIcon.svg?react';
import ListingsIcon from 'resources/listingsIcon.svg?react';
import RolesIcon from 'resources/rolesIcon.svg?react';
import UsersIcon from 'resources/usersIcon.svg?react';

import * as profileSelectors from '../../../modules/profile/selectors';

import { handleOnMouseHover } from './helpers';
import StyledListItemButton from './StyledListItemButton';
import StyledStyledListItemText from './StyledStyledListItemText';
import UpgradeIcon from './UpgradeIcon';

const CompanyOptions = ({
    handleClose,
    handleDrawerToggle,
    match,
    mobileOpen,
    redirect,
    subscription,
    subscriptions,
    subscriptionVersion,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const { allHasPaymentDue, isMultipleSubscription } =
        getSubscriptionsStatus(subscriptions);
    const { isActive, isPaymentDue } = getSubscriptionStatus(subscription);

    const isDisabledV4 =
        subscriptionVersion === 'v4' && isActive && isPaymentDue;
    const isDisabledV5 =
        (subscriptionVersion === 'v5' &&
            !isMultipleSubscription &&
            isActive &&
            isPaymentDue) ||
        (subscriptionVersion === 'v5' &&
            isMultipleSubscription &&
            allHasPaymentDue);

    const onMouseLeave = (e) =>
        handleOnMouseHover({ e, breakpoint, handleClose });

    return (
        <Box onMouseLeave={onMouseLeave}>
            <CheckAccess
                requiredPermissions={[
                    PERMISSION_LIST.GENERAL_COMPANY_INFO_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    className="__userguiding_company_module_navbar_item"
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/company');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/company' === match.path}
                    variant="horizontal"
                >
                    <GeneralIcon />
                    <StyledStyledListItemText primary={t('General')} />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[
                    PERMISSION_LIST.BRANCH_OFFICES_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/branch-offices');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/branch-offices' === match.path}
                    variant="horizontal"
                >
                    <BranchOfficesIcon />
                    <StyledStyledListItemText
                        primary={t('Branch office', { count: 2 })}
                    />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[PERMISSION_LIST.BRANDING_PERMISSION]}
            >
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/branding');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/branding' === match.path}
                    variant="horizontal"
                >
                    <ColorLensIcon />
                    <StyledStyledListItemText primary="Branding" />
                    <UpgradeIcon moduleName="Branding" />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[PERMISSION_LIST.ROLES_PERMISSION]}
            >
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/roles');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/roles' === match.path}
                    variant="horizontal"
                >
                    <RolesIcon />
                    <StyledStyledListItemText
                        primary={t('Role', { count: 2 })}
                    />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[PERMISSION_LIST.COMPANY_USERS_PERMISSION]}
            >
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/users');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/users' === match.path}
                    variant="horizontal"
                >
                    <UsersIcon />
                    <StyledStyledListItemText
                        primary={t('User', { count: 2 })}
                    />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[PERMISSION_LIST.LIST_SETTINGS_PERMISSION]}
            >
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/lists');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/lists' === match.path}
                    variant="horizontal"
                >
                    <ListingsIcon />
                    <StyledStyledListItemText primary={t('Listings')} />
                </StyledListItemButton>
            </CheckAccess>
        </Box>
    );
};

CompanyOptions.propTypes = {
    handleClose: PropTypes.func,
    handleDrawerToggle: PropTypes.func,
    match: PropTypes.object,
    mobileOpen: PropTypes.bool,
    redirect: PropTypes.func,
    subscription: PropTypes.object,
    subscriptions: PropTypes.array,
    subscriptionVersion: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    subscription: profileSelectors.getMainSubscription,
    subscriptions: profileSelectors.getSubscriptions,
    subscriptionVersion: profileSelectors.getSubscriptionVersion,
});

export default connect(mapStateToProps, null)(CompanyOptions);
