import React from 'react';

import { Box, Chip, Typography } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { MissingFieldsAlert } from '../../../projectConsumptionsModal/components/warnings';
import { getAvailableFieldsByKey } from '../../helpers';
import * as selectors from '../../selectors';
import IncompleteCupsWarning from '../IncompleteCupsWarning';

import FormAccordion from './FormAccordion';

const ResolvedIntegrationFields = ({ control, disabled, energyData }) => {
    const { t } = useTranslation();
    const [energyKeys, uploadOrigin] = useWatch({
        control,
        name: ['fields_keys.energy', 'upload_origin'],
    });

    const { energy, hasMissingFields, missingFields, total } = energyData || {};

    if (
        !energy &&
        !energyKeys?.length &&
        !hasMissingFields &&
        !missingFields &&
        !total
    )
        return null;

    const energyFields = getAvailableFieldsByKey({
        fields: energy,
        fieldsKey: energyKeys,
    });

    const totalFields = getAvailableFieldsByKey({
        fields: total,
        fieldsKey: energyKeys,
    });

    const fieldsToShow = energyFields?.length > 0 ? energyFields : totalFields;

    if (uploadOrigin === null || !fieldsToShow?.length) return null;

    return (
        <FormAccordion
            defaultExpanded
            title={t('Energy data from electricity bill')}
        >
            <IncompleteCupsWarning control={control} />

            <MissingFieldsAlert period={{ hasMissingFields, missingFields }} />

            <Typography variant="subtitle2">
                {t('Select the concepts to use for the calculation')}:
            </Typography>

            <Box
                alignItems="center"
                display="flex"
                flexWrap="wrap"
                gap={1}
                mb={2}
                mt={1}
            >
                {fieldsToShow.map(
                    ({ concept, conceptName, tier, tierName, value }) => (
                        <Controller
                            control={control}
                            defaultValue={true}
                            key={`${tier || 'total'}-${concept}`}
                            name={`integration_energy.${tier || 'total'}-${concept}`}
                            render={({ field }) => (
                                <Chip
                                    color="primary"
                                    disabled={disabled}
                                    label={`${t(conceptName)} (${tierName || 'total'}): ${value}`}
                                    onClick={() =>
                                        !disabled &&
                                        field.onChange(!field.value)
                                    }
                                    onDelete={
                                        field.value && !disabled
                                            ? () => field.onChange(false)
                                            : null
                                    }
                                    variant={
                                        field.value ? 'filled' : 'outlined'
                                    }
                                />
                            )}
                        />
                    ),
                )}
            </Box>
        </FormAccordion>
    );
};

const mapStateToProps = createStructuredSelector({
    energyData: selectors.getIntegrationEnergyData,
});

ResolvedIntegrationFields.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    energyData: PropTypes.object,
};

export default connect(mapStateToProps)(ResolvedIntegrationFields);
