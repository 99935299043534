import React from 'react';

import ArticleIcon from '@mui/icons-material/Article';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

import AftersalesIcon from 'resources/aftersales_icon.svg?react';
import BundleIcon from 'resources/bundle_icon.svg?react';
import SalesIcon from 'resources/sales_icon.svg?react';

const CreditsIcon = ({ productType, sxIcon }) => {
    const sxSvgIcon = {
        svg: {
            height: '20px',
            rect: {
                fill: sxIcon.color,
            },
            width: '20px',
        },
    };

    switch (productType) {
        case 'sales':
            return (
                <Box component="span" height="20px" sx={sxSvgIcon}>
                    <SalesIcon />
                </Box>
            );
        case 'aftersales':
            return (
                <Box component="span" height="20px" sx={sxSvgIcon}>
                    <AftersalesIcon />
                </Box>
            );
        case 'bundle':
            return (
                <Box component="span" height="20px" sx={sxSvgIcon}>
                    <BundleIcon />
                </Box>
            );
        default:
            return <ArticleIcon sx={{ ...sxIcon, fontSize: 28 }} />;
    }
};

CreditsIcon.propTypes = {
    productType: PropTypes.string,
    sxIcon: PropTypes.object.isRequired,
};

export default CreditsIcon;
