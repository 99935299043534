import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button } from 'sunwise-ui';

import { MONTHLY_FREQUENCY, YEARLY_FREQUENCY } from 'common/constants';

const FrequencyOptions = ({
    billingFrequency,
    isHidden,
    setBillingFrequency,
}) => {
    const { t } = useTranslation();

    const isMonthly = billingFrequency === MONTHLY_FREQUENCY;
    const isYearly = billingFrequency === YEARLY_FREQUENCY;

    const sxProps = {
        background: '#f7f7f7',
        border: '1px solid #f7f7f7',
        borderRadius: '5px',
        boxShadow: 'none',
        color: '#000',
        fontSize: '16px',
        fontWeight: 'bold',
        padding: '13px 30px',
        width: '125px',
        '&:hover': {
            background: '#f7f7f7',
            borderColor: 'primary.main',
            color: 'primary.main',
        },
        '&.active': {
            borderColor: 'primary.main',
            background: '#011e2e',
            color: '#fff',
        },
        '&.active:hover': {
            borderColor: 'primary.main',
            opacity: 0.8,
        },
    };

    if (isHidden) return null;

    return (
        <>
            <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                gap={1}
                justifyContent="center"
                mt={3}
            >
                <Button
                    className={isMonthly ? 'active' : ''}
                    onClick={() => setBillingFrequency(MONTHLY_FREQUENCY)}
                    sx={sxProps}
                >
                    {t('Monthly')}
                </Button>
                <Button
                    className={isYearly ? 'active' : ''}
                    onClick={() => setBillingFrequency(YEARLY_FREQUENCY)}
                    sx={sxProps}
                >
                    {t('Yearly')}
                </Button>
            </Box>
        </>
    );
};

FrequencyOptions.propTypes = {
    billingFrequency: PropTypes.string,
    isHidden: PropTypes.bool,
    setBillingFrequency: PropTypes.func,
};

export default FrequencyOptions;
