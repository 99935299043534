import React from 'react';

import { push } from 'connected-react-router';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Typography } from 'sunwise-ui';

import { formatDate, parseDate } from 'common/utils/dates';
import { getSubscriptionsStatus } from 'common/utils/helpers';

import * as profileSelectors from '../../modules/profile/selectors';

const formatPeriodDate = (date) => {
    return formatDate(parseDate(date, 'dd/M/yyyy HH:mm:ss'), 'dd MMMM yyyy');
};

const PaymentDueBanner = ({
    isFetchingUserSettings,
    redirect,
    subscriptions,
}) => {
    const { t } = useTranslation();

    const { hasPaymentDue = false } = getSubscriptionsStatus(subscriptions);

    if (isFetchingUserSettings) return null;

    if (hasPaymentDue)
        return (
            <Box
                sx={{
                    alignItems: 'center',
                    backgroundColor: 'primary.light',
                    display: 'flex',
                    height: 70,
                    justifyContent: 'space-between',
                    p: 2,
                    width: '100%',
                }}
            >
                <Box display="flex" flexDirection="column">
                    {subscriptions.map((subscription, index) => {
                        const cancellationDate = get(
                            subscription,
                            'next_cancel_date',
                            '---',
                        );
                        const planName = get(subscription, 'plan_name', '---');
                        if (get(subscription, 'due_invoices_count', 0) === 0)
                            return null;
                        return (
                            <Typography
                                fontWeight={600}
                                key={`${subscription.subscription_id}_${index}`}
                                variant="body2"
                            >
                                {t(
                                    'Your subscription {{planName}} is scheduled to be cancelled on {{cancellationDate}}',
                                    {
                                        cancellationDate:
                                            formatPeriodDate(cancellationDate),
                                        planName,
                                    },
                                )}
                            </Typography>
                        );
                    })}
                </Box>
                <Button
                    color="secondary"
                    onClick={() =>
                        redirect('/profile/subscriptions?action=pay_invoices')
                    }
                    size="small"
                    sx={{ textTransform: 'none' }}
                >
                    {t('Reactivate now')}
                </Button>
            </Box>
        );
    return null;
};

PaymentDueBanner.propTypes = {
    isFetchingUserSettings: PropTypes.bool,
    redirect: PropTypes.func,
    subscriptions: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    isFetchingUserSettings: profileSelectors.getIsFetching,
    subscriptions: profileSelectors.getSubscriptions,
    subscriptionVersion: profileSelectors.getSubscriptionVersion,
});

const mapDispatchToProps = (dispatch) => ({
    redirect: (link) => dispatch(push(link)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDueBanner);
