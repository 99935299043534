import { login } from 'common/api/v1/zap';

const CACHE_KEY = 'zap-token';
const CACHE_EXPIRY_KEY = 'zap-token-expiry';
const CACHE_DURATION = 15 * 60 * 1000; // 15 min in milliseconds

let ongoingRequest = null;

/**
 * Retrieves the token from cache or makes a login request if necessary.
 * @returns {Promise<string>} A promise that resolves to the token.
 */
const getToken = () => {
    const cachedToken = localStorage.getItem(CACHE_KEY);
    const cachedExpiry = localStorage.getItem(CACHE_EXPIRY_KEY);
    const currentTime = new Date().getTime();

    if (cachedToken && cachedExpiry && currentTime < cachedExpiry)
        return Promise.resolve(cachedToken);

    if (ongoingRequest) return ongoingRequest;

    ongoingRequest = login()
        .then((response) => {
            const token = response?.data?.data?.token;

            if (!token) {
                ongoingRequest = null;
                return;
            }

            const expiryTime = currentTime + CACHE_DURATION;
            localStorage.setItem(CACHE_KEY, token);
            localStorage.setItem(CACHE_EXPIRY_KEY, expiryTime);

            ongoingRequest = null;
            return token;
        })
        .catch((error) => {
            console.error('Login request failed:', error);
            ongoingRequest = null;
            throw error;
        });

    return ongoingRequest;
};

/**
 * Authenticates the user and executes the onSuccess callback if provided.
 * @param {Function} onSuccess - Callback function to execute on successful authentication.
 * @returns {Promise<string>} A promise that resolves to the token.
 */
export default async (onSuccess) => {
    const token = await getToken();
    if (!token) return;
    if (onSuccess) return onSuccess(token);
    return token;
};
