import { mediaGetFile } from '@sunwise-clients/wise';

import showToast from 'common/utils/showToast';

import {
    GET_LISA_FILE,
    GET_LISA_FILE_FAILURE,
    GET_LISA_FILE_SUCCESS,
} from '../actionTypes';
import { lisaFilesActions } from '../reducer';

export default (fileName, onSuccess, onFailure) => (dispatch) => {
    dispatch(lisaFilesActions[GET_LISA_FILE]());

    mediaGetFile({ query: { file_name: fileName } })
        .then((response) => {
            dispatch(lisaFilesActions[GET_LISA_FILE_FAILURE]());

            if (onSuccess) onSuccess(response?.data);
        })
        .catch((error) => {
            dispatch(lisaFilesActions[GET_LISA_FILE_SUCCESS](error));

            if (error?.response?.data?.detail)
                showToast({
                    body: `${error?.response?.data?.detail}`,
                    type: 'danger',
                });

            if (onFailure) onFailure(error);
        });
};
