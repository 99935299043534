import { getFinancials } from 'common/api/v1/sunpay';

import {
    FETCH_FINANCIALS,
    FETCH_FINANCIALS_FAILURE,
    FETCH_FINANCIALS_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorAddAllianceActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(proposalGeneratorAddAllianceActions[FETCH_FINANCIALS]());

    getFinancials()
        .then((response) => {
            dispatch(
                proposalGeneratorAddAllianceActions[FETCH_FINANCIALS_SUCCESS](
                    response?.data?.data,
                ),
            );
        })
        .catch((error) =>
            dispatch(
                proposalGeneratorAddAllianceActions[FETCH_FINANCIALS_FAILURE](
                    error,
                ),
            ),
        );
};
