import React from 'react';

import BoltIcon from '@mui/icons-material/Bolt';
import { useTheme } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemButton, ListItemText } from 'sunwise-ui';

import { ELECTRIC_BILL_TYPE } from '../constants';

import SideMenuCard from './SideMenuCard';

const ElectricBillCard = ({
    electricBills,
    handleClickElectricBillItem,
    isDisabled,
    selectedDocumentId,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    if (isEmpty(electricBills)) return null;

    return (
        <SideMenuCard
            icon={<BoltIcon sx={{ color: 'primary.main' }} />}
            title={t('Electric bill')}
        >
            <List disablePadding>
                {electricBills.map((item) => {
                    const isSelected =
                        item.id.toString() === selectedDocumentId;
                    return (
                        <ListItem
                            key={`electric-bill-${item.label}`}
                            disablePadding
                            disabled={isDisabled || isSelected}
                        >
                            <ListItemButton
                                onClick={() =>
                                    handleClickElectricBillItem({
                                        archive: item.url,
                                        fileName: item.url,
                                        id: item.id.toString(),
                                        name: item.label,
                                        type: ELECTRIC_BILL_TYPE,
                                        urlType: item.urlType,
                                    })
                                }
                                selected={isSelected}
                                sx={{
                                    borderRadius: '8px',
                                    backgroundColor: isSelected
                                        ? isDarkMode
                                            ? '#000'
                                            : 'rgba(31, 60, 83, 0.13)'
                                        : 'transparent',
                                    '&.Mui-selected': {
                                        backgroundColor: isDarkMode
                                            ? '#000'
                                            : 'rgba(31, 60, 83, 0.13) !important',
                                    },
                                }}
                            >
                                <ListItemText
                                    primary={item.label}
                                    sx={{
                                        '.MuiListItemText-primary': {
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            textTransform: 'capitalize',
                                        },
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </SideMenuCard>
    );
};

ElectricBillCard.propTypes = {
    electricBills: PropTypes.array,
    handleClickElectricBillItem: PropTypes.func,
    isDisabled: PropTypes.bool,
    selectedDocumentId: PropTypes.string,
};

export default ElectricBillCard;
