import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import {
    Box,
    Button,
    Card,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@sunwisesoftware/sunwise-ui';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { syncReports } from 'common/api/boty/reports';
import { formatDate, parseDate } from 'common/utils/dates';
import {
    getCurrencyIso,
    getCurrencySymbol,
    numberFormat,
} from 'common/utils/helpers';
import { getDateFormatByLocale } from 'common/utils/helpers/multiregion';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import * as actions from '../actions';
import { DATE_FORMATS } from '../constants';

const FieldWrapper = styled(Box)`
    border-bottom: 1px solid
        ${({ theme }) =>
            theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.7)'
                : 'rgba(145, 158, 171, 0.56)'};
    height: 100%;
`;

const FieldLabel = styled(Typography)`
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.66;
    letter-spacing: 0.03333em;
`;

const ItemsList = ({
    canModify,
    item,
    prepareUpdate,
    setModalFormOpenStatus,
}) => {
    const { t } = useTranslation();

    if (!item || isEmpty(item)) return null;

    const currencyIso = getCurrencyIso(item?.currency);
    const currencyLocale = getCountryCurrencyLocale();
    const currencySymbol = getCurrencySymbol(item?.currency);
    const localeDateFormat = getDateFormatByLocale();
    const handleOnClickEdit = () => setModalFormOpenStatus(true);
    const showSyncButton = import.meta.env.VITE_ENV !== 'prod';

    const numberFormatOptions = {
        currency: currencyIso,
        decimals: 2,
        locale: currencyLocale,
        style: 'currency',
        symbol: currencySymbol,
    };

    const date = item.start_date
        ? formatDate(
              parseDate(item.start_date, DATE_FORMATS.SUNWISE),
              localeDateFormat,
          )
        : '--/--/--';

    if (item.source === '0')
        return (
            <>
                <Card.Header
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography variant="body1" fontWeight="bold">
                        {t('Project settings')}
                    </Typography>

                    {showSyncButton && (
                        <Button onClick={() => syncReports(item.id)}>
                            {t('Sync')}
                        </Button>
                    )}

                    {canModify && (
                        <IconButton
                            onClick={() =>
                                prepareUpdate(item, handleOnClickEdit)
                            }
                            size="small"
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                    )}
                </Card.Header>

                <Card.Body>
                    <Grid container>
                        <Grid size={{ md: 9, xs: 18 }}>
                            <FieldWrapper>
                                <FieldLabel>{t('Reference source')}</FieldLabel>

                                <Typography>Sunwise</Typography>
                            </FieldWrapper>
                        </Grid>
                        <Grid size={{ md: 9, xs: 18 }}>
                            <FieldWrapper>
                                <FieldLabel>{t('Total cost')}</FieldLabel>

                                <Typography>
                                    {numberFormat(
                                        item.total_cost,
                                        numberFormatOptions,
                                    )}
                                </Typography>
                            </FieldWrapper>
                        </Grid>

                        <Grid size={{ md: 9, xs: 18 }}>
                            <FieldWrapper>
                                <FieldLabel>
                                    {t('Installation date')}
                                </FieldLabel>

                                <Typography>{date}</Typography>
                            </FieldWrapper>
                        </Grid>

                        <Grid size={{ md: 9, xs: 18 }}>
                            <FieldWrapper>
                                <FieldLabel>{t('Rate')}</FieldLabel>

                                <Typography>{item.rate?.name || ''}</Typography>
                            </FieldWrapper>
                        </Grid>
                    </Grid>
                </Card.Body>
            </>
        );
    if (item.source === '1')
        return (
            <>
                <Card.Header
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography>{t('Project settings')}</Typography>

                    {canModify && (
                        <IconButton
                            onClick={() =>
                                prepareUpdate(item, handleOnClickEdit)
                            }
                            size="small"
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                    )}
                </Card.Header>

                <Card.Body>
                    <TextField
                        fullWidth
                        label={t('Source')}
                        value="Externo"
                        variant="standard"
                    />
                </Card.Body>
            </>
        );
    return null;
};

ItemsList.propTypes = {
    canModify: PropTypes.bool,
    item: PropTypes.object,
    prepareUpdate: PropTypes.func,
    setModalFormOpenStatus: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    prepareUpdate: (item, successCallback) =>
        dispatch(actions.prepareUpdate(item, successCallback)),
    setModalFormOpenStatus: (value) =>
        dispatch(actions.setModalFormOpenStatus(value)),
});

export default connect(null, mapDispatchToProps)(ItemsList);
