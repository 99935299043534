import React from 'react';

import LinkIcon from '@mui/icons-material/Link';
import { useTheme } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemButton, ListItemText } from 'sunwise-ui';

import SideMenuCard from '../../multiTemplateReview/components/SideMenuCard';

const LinkedProposalsCard = ({ isDisabled, linkedProposals }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    if (isEmpty(linkedProposals)) return null;
    return (
        <SideMenuCard
            icon={<LinkIcon sx={{ color: 'primary.main' }} />}
            title={t('Linked proposals')}
        >
            <List disablePadding>
                {linkedProposals.map((item) => (
                    <ListItem
                        disabled={isDisabled}
                        disablePadding
                        key={`linked-proposal-${item.proposal_id}`}
                        sx={{
                            borderRadius: '8px',
                            mb: '5px',
                            '&.Mui-selected': {
                                backgroundColor:
                                    theme.palette.mode === 'dark'
                                        ? '#000'
                                        : 'rgba(31, 60, 83, 0.13)',
                            },
                        }}
                    >
                        <ListItemButton
                            href={`/proposal-review-pro/${item.proposal_id}`}
                            sx={{ borderRadius: '8px' }}
                            target="_blank"
                        >
                            <ListItemText
                                primary={item.proposal_name}
                                sx={{
                                    '.MuiListItemText-primary': {
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        textTransform: 'capitalize',
                                    },
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </SideMenuCard>
    );
};

LinkedProposalsCard.propTypes = {
    linkedProposals: PropTypes.array,
    isDisabled: PropTypes.bool,
};

export default LinkedProposalsCard;
