import React from 'react';

import { Box, Grid } from '@sunwisesoftware/sunwise-ui';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import {
    ReactHookFormIntlNumberInput,
    ReactHookFormSwitch,
} from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import { RATES_WITH_POWER_FACTOR } from 'common/constants/rates';
import withPermissions from 'common/hocs/withPermissions';
import { getHasLowTensionConcept } from 'common/utils/helpers/rates';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validateRateTaxes';

import PowerFactorFields from './PowerFactorFields';

const RateTaxes = ({
    commercialOfferId,
    getPermissionsByCode,
    initialValues,
    isLoading,
    isLocked,
    isSaving,
    nextRateData,
    prevRateData,
    save,
}) => {
    const { t } = useTranslation();
    const { control, handleSubmit, setValue } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    const { canModify: canModifyRatesAnualCFE } = getPermissionsByCode(
        PERMISSION_LIST.RATES_ANNUAL_CFE_PERMISSION,
    );
    const { canModify: canModifyRatesDap } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_RATES_DAP_PERMISSION,
    );
    const { canModify: canModifyTaxesRate } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_IVA_CFE_PERMISSION,
    );

    const onSubmit = (data) => save(data, commercialOfferId);

    const handleOnChange = React.useRef(
        debounce(handleSubmit(onSubmit), 1000),
    ).current;

    const hasLowTensionConcept =
        nextRateData?.isCertified &&
        getHasLowTensionConcept(nextRateData?.name);
    const disabled = isLoading || isSaving || isLocked;
    const showPowerFactor =
        prevRateData?.isCertified &&
        RATES_WITH_POWER_FACTOR.includes(prevRateData?.name);

    return (
        <form>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">
                    {t('Rate parameters')}
                </TitleWithDetail>
            </Box>

            <Grid container mb={2}>
                <Grid size={{ md: 9, xs: 18 }}>
                    <ReactHookFormIntlNumberInput
                        append="%"
                        caption={`% ${t('Annual')}`}
                        control={control}
                        disabled={disabled || !canModifyRatesAnualCFE}
                        fullWidth
                        label={t('Rate increase')}
                        min="0"
                        name="increase_percentage"
                        onChange={handleOnChange}
                    />
                </Grid>

                {nextRateData?.isCertified && (
                    <Grid size={{ md: 9, xs: 18 }}>
                        <ReactHookFormIntlNumberInput
                            append="%"
                            caption={`% ${t('Period')}`}
                            control={control}
                            disabled={disabled || !canModifyRatesDap}
                            fullWidth
                            label="DAP"
                            min="0"
                            name="dap_value"
                            onChange={handleOnChange}
                        />
                    </Grid>
                )}

                <Grid size={{ md: 9, xs: 18 }}>
                    <ReactHookFormIntlNumberInput
                        append="%"
                        control={control}
                        disabled={disabled || !canModifyTaxesRate}
                        fullWidth
                        label={t('Tax rate')}
                        min="0"
                        name="tax_rate_percentage"
                        onChange={handleOnChange}
                    />
                </Grid>

                {hasLowTensionConcept && (
                    <Grid size={{ md: 9, xs: 18 }}>
                        <ReactHookFormSwitch
                            control={control}
                            disabled={disabled || !canModifyTaxesRate}
                            label={`2% ${t('Low tension')}`}
                            name="has_low_tension_concept"
                            onChange={handleOnChange}
                            sx={{ flexDirection: 'row-reverse', ml: 0 }}
                        />
                    </Grid>
                )}
            </Grid>

            <PowerFactorFields
                control={control}
                isLocked={isLocked}
                setValue={setValue}
                showPowerFactor={showPowerFactor}
            />
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValuesRateTaxes,
    isLoading: selectors.getRateConceptsLoading,
    isSaving: selectors.getSaveRateTaxesLoading,
});

const mapDispatchToProps = (dispatch) => ({
    save: (data, commercialOfferId) =>
        dispatch(actions.saveRateTaxes(data, commercialOfferId)),
});

RateTaxes.propTypes = {
    commercialOfferId: PropTypes.string,
    getPermissionsByCode: PropTypes.func,
    initialValues: PropTypes.object,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isSaving: PropTypes.bool,
    nextRateData: PropTypes.object,
    prevRateData: PropTypes.object,
    save: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions([
        PERMISSION_LIST.PROPOSAL_IVA_CFE_PERMISSION,
        PERMISSION_LIST.PROPOSAL_RATES_DAP_PERMISSION,
        PERMISSION_LIST.RATES_ANNUAL_CFE_PERMISSION,
    ]),
)(RateTaxes);
