import React, { useContext } from 'react';

import { get, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Skeleton, Alert, Box, Grid, Typography } from 'sunwise-ui';

import { KeyIndicator, SegmentsMap } from 'common/components';
import TitleWithDetail from 'common/components/TitleWithDetail';
import { GeneralContext } from 'common/utils/contexts';
import {
    getAddresFormatted,
    getSegmentArea,
    numberFormat,
} from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import { POLYGON_SEGMENT } from '../../multiPanelLayout/constants';
import * as selectors from '../../proposalGeneratorPanelsLayout/selectors';

const Container = ({
    haveParentFieldSegment,
    isFetching,
    isFetchingParentFieldSegment,
    parentFieldSegmentCounters,
    projectLocation,
    segments,
}) => {
    const { t } = useTranslation();
    const { google } = useContext(GeneralContext);
    const countryCurrencyLocale = getCountryCurrencyLocale();

    const { latitude = null, longitude = null } = projectLocation;

    if (isFetching || isFetchingParentFieldSegment)
        return <Skeleton variant="rounded" height={400} />;

    if (!haveParentFieldSegment) return null;

    const totalAssignedSolarModules = segments.reduce((acc, current) => {
        if (current.type === POLYGON_SEGMENT)
            return (
                acc +
                current.solar_modules.filter((module) => module.enabled).length
            );
        return acc + 0;
    }, 0);

    const showAlert =
        totalAssignedSolarModules !==
        parentFieldSegmentCounters.solarModulesOfferNumber;
    const totalArea = segments.reduce((acc, current) => {
        if (get(current, 'polygon', [])) {
            const area = getSegmentArea(google, get(current, 'polygon', []));
            if (current.type === POLYGON_SEGMENT) return acc + area;
            return acc + 0;
        }
        return acc;
    }, 0);

    return (
        <Grid container mt={2}>
            <Grid size={18}>
                <Box mb={2}>
                    <TitleWithDetail mb={2} variant="body2">
                        {t('PV Layout')}
                    </TitleWithDetail>
                </Box>
                {showAlert && (
                    <Alert severity="warning">
                        <Typography variant="caption" fontWeight="bold">
                            {t(
                                'The number of panels does not match those available in the proposal, adjust your PV layout',
                            )}
                        </Typography>
                    </Alert>
                )}
                <Grid container>
                    <Grid size={{ lg: 8, xs: 18 }}>
                        <Grid container>
                            <Grid size={18}>
                                <KeyIndicator
                                    title={t('Address')}
                                    value={getAddresFormatted(projectLocation)}
                                    widthIcon="20px"
                                />
                            </Grid>

                            <Grid size={{ md: 9, xs: 18 }}>
                                <KeyIndicator
                                    title={t('Number of modules')}
                                    unit="u"
                                    value={totalAssignedSolarModules}
                                    widthIcon="20px"
                                />
                            </Grid>

                            <Grid size={{ md: 9, xs: 18 }}>
                                <KeyIndicator
                                    title={t('Number of segments')}
                                    unit="u"
                                    value={
                                        segments.filter(
                                            (segment) =>
                                                segment.type ===
                                                POLYGON_SEGMENT,
                                        ).length
                                    }
                                    widthIcon="20px"
                                />
                            </Grid>

                            <Grid size={{ md: 9, xs: 18 }}>
                                <KeyIndicator
                                    title={t('Total area')}
                                    unit="m2"
                                    value={numberFormat(totalArea, {
                                        locale: countryCurrencyLocale,
                                        style: 'decimal',
                                    })}
                                    widthIcon="20px"
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {!isNull(latitude) && !isNull(longitude) && (
                        <Grid size={{ lg: 10, xs: 18 }}>
                            <Box>
                                <SegmentsMap
                                    center={{
                                        lat: get(projectLocation, 'latitude'),
                                        lng: get(projectLocation, 'longitude'),
                                    }}
                                    fullscreenControl
                                    height="250px"
                                    initCenter={projectLocation}
                                    projectLocation={{
                                        ...projectLocation,
                                        address: projectLocation.description,
                                    }}
                                    segments={segments}
                                    showLabels={false}
                                    width="100%"
                                    zoom={18}
                                />
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    haveParentFieldSegment: selectors.getHaveParentFieldSegment,
    isFetchingParentFieldSegment: selectors.getIsFetchingParentFieldSegment,
    parentFieldSegmentCounters: selectors.getParentFieldSegmentCounters,
    segments: selectors.getSegments,
});

Container.propTypes = {
    haveParentFieldSegment: PropTypes.bool,
    isFetching: PropTypes.bool,
    isFetchingParentFieldSegment: PropTypes.bool,
    parentFieldSegmentCounters: PropTypes.object,
    projectLocation: PropTypes.object,
    segments: PropTypes.array,
};

export default connect(mapStateToProps, null)(Container);
