import React from 'react';

import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import UploadIcon from '@mui/icons-material/Upload';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';

const DropdownActions = ({
    canModify,
    handleClickExport,
    handleClickImport,
    handleClickUpdate,
    handleOpenDeviceBasedConsumptionModal,
}) => {
    const { t } = useTranslation();

    return (
        <DropdownCardMenu
            items={[
                {
                    icon: <DevicesOtherIcon />,
                    label: t('Add device', { count: 2 }),
                    handleClick: handleOpenDeviceBasedConsumptionModal,
                },
                {
                    handleClick: handleClickUpdate,
                    icon: <EditIcon fontSize="small" />,
                    label: t('Edit'),
                    visible: canModify,
                },
                {
                    handleClick: handleClickImport,
                    icon: <UploadIcon fontSize="small" />,
                    label: t('Import'),
                    visible: canModify,
                },
                {
                    handleClick: handleClickExport,
                    icon: <DownloadIcon fontSize="small" />,
                    label: t('Export'),
                },
            ]}
        />
    );
};

DropdownActions.propTypes = {
    canModify: PropTypes.bool,
    handleClickExport: PropTypes.func,
    handleClickImport: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    handleOpenDeviceBasedConsumptionModal: PropTypes.func,
};

export default DropdownActions;
