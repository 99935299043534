export const NAME = 'billing-plans';

export const AVATAR_SIZE = 40;

export const ADDONS_LIST = {
    sales: {
        0: ['Cotizador-Web'],
        1: [],
        2: ['multi-branchs', 'TS-PS', 'Modulo-Sunwise-Analytics'],
        3: ['Preferential-Support', 'Branding'],
    },
    aftersales: {
        0: ['Monitoring-System', 'Monitoring-Dashboard', 'Manual-Reporting'],
        1: [],
        2: [
            'Automatic-Reporting',
            'LISA-Integration',
            'Automatic-Reports-By-Mail',
        ],
    },
    bundle: {
        0: {
            sales: ['Cotizador-Web', 'Project-Funnel', 'Interactive-Proposals'], // Pro: herramientas básicas de ventas
            aftersales: [
                'Monitoring-Dashboard',
                'Reports-Funnel',
                'Manual-Reporting',
            ], // Pro: herramientas básicas de posventa
        },
        1: {
            sales: [],
            aftersales: [],
        },
        2: {
            sales: ['multi-branchs', 'TS-PS', 'Modulo-Sunwise-Analytics'], // Scale: más funcionalidades de ventas
            aftersales: [
                'Automatic-Reporting',
                'LISA-Integration',
                'Automatic-Reports-By-Mail',
            ], // Scale: más funcionalidades de posventa
        },
        3: {
            sales: ['Preferential-Support', 'Branding'], // Enterprise: soporte y personalización
        },
    },
};
