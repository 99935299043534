import React, { useRef } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { Box, Card, Chip } from 'sunwise-ui';

import {
    ColumnContainer,
    ColumnTitle,
    ColumnWrapper,
} from 'common/components/funnel';

const InfiniteColumn = ({
    checkIcon,
    children,
    color,
    hasItems = true,
    hasNextPage,
    id,
    initialLoad = false,
    loadMore,
    pageStart = 1,
    threshold = 300,
    title,
    totalRows = 0,
    wrapperProps,
}) => {
    const { t } = useTranslation();
    const columnDivRef = useRef();

    const ColumnTitleIcon = checkIcon ? CheckCircleIcon : CircleIcon;

    const isTitleIconTransparent = color === 'transparent';

    return (
        <ColumnWrapper {...wrapperProps}>
            <Box alignItems="center" display="flex" gap={0.5} mb={1} mr={1}>
                {color && (
                    <ColumnTitleIcon
                        sx={{
                            border: isTitleIconTransparent
                                ? '1px solid gray'
                                : undefined,
                            borderRadius: isTitleIconTransparent
                                ? '50%'
                                : undefined,
                            color,
                            fontSize: 16,
                        }}
                    />
                )}

                <ColumnTitle>{title}</ColumnTitle>

                <Chip
                    as="span"
                    label={totalRows}
                    size="small"
                    sx={{ fontSize: 10, fontWeight: 'bold' }}
                />
            </Box>

            <ColumnContainer ref={columnDivRef}>
                <InfiniteScroll
                    getScrollParent={() => columnDivRef.current}
                    hasMore={hasNextPage}
                    key={`infinite-${id}`}
                    initialLoad={initialLoad}
                    loadMore={loadMore}
                    loader={
                        <div className="scroll-loader" key={0}>
                            {t('Loading')}
                        </div>
                    }
                    pageStart={pageStart}
                    threshold={threshold}
                    useWindow={false}
                >
                    {!hasItems && (
                        <Card
                            sx={{
                                borderRadius: '4px',
                                textAlign: 'center',
                                width: '100%',
                            }}
                        >
                            <Card.Body>{t('No items')}</Card.Body>
                        </Card>
                    )}

                    {children}
                </InfiniteScroll>
            </ColumnContainer>
        </ColumnWrapper>
    );
};
InfiniteColumn.propTypes = {
    checkIcon: PropTypes.bool,
    children: PropTypes.node,
    color: PropTypes.string,
    hasItems: PropTypes.bool,
    hasNextPage: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    initialLoad: PropTypes.bool,
    loadMore: PropTypes.func,
    pageStart: PropTypes.number,
    threshold: PropTypes.number,
    title: PropTypes.string,
    totalRows: PropTypes.number,
    wrapperProps: PropTypes.object,
};

export default InfiniteColumn;
