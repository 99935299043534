import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, Grid, MenuItem, Select } from 'sunwise-ui';

import {
    BusinessTypeFilterDropdown,
    InfoToolTip,
    InputSearch,
} from 'common/components';
import { DEBOUNCE_TIME_500 } from 'common/constants';

import { PROFILE_TYPE_ITEMS } from '../constants';

import InfoList from './InfoList';

const ConsumptionProfilesFilter = ({
    certifiedProfilesNumber,
    isArchived,
    isFetchingConsumptionProfiles,
    profileTypeSelected,
    selectProfileType,
    setFilterName,
    setIsArchived,
}) => {
    const { t } = useTranslation();
    const [timer, setTimer] = useState(null);
    const handleOnSearchChange = (profileName) => setFilterName(profileName);

    const handleChange = (value) => {
        clearTimeout(timer);
        setTimer(
            setTimeout(() => handleOnSearchChange(value), DEBOUNCE_TIME_500),
        );
    };
    return (
        <Grid container columns={20} py={2}>
            <Grid
                display="flex"
                flexDirection="column"
                justifyContent="end"
                size={{ md: 10, xs: 20 }}
            >
                <InputSearch
                    disabled={isFetchingConsumptionProfiles}
                    onChange={(e) => handleChange(e.target.value)}
                    placeholder={t('Filter by name')}
                />
            </Grid>

            <Grid size={{ md: 5, sm: 10, xs: 20 }}>
                <BusinessTypeFilterDropdown
                    disabled={isFetchingConsumptionProfiles}
                    label={t('Profile type')}
                    selectType={selectProfileType}
                    typeItems={PROFILE_TYPE_ITEMS}
                    typeSelected={profileTypeSelected}
                />
            </Grid>

            <Grid
                display="flex"
                flexDirection="column"
                justifyContent="end"
                size={{ md: 5, sm: 10, xs: 20 }}
            >
                <Box alignItems="center" display="flex" gap="16px">
                    <FormControl fullWidth size="small">
                        <Select
                            onChange={(e) => setIsArchived(e.target.value)}
                            value={isArchived}
                        >
                            <MenuItem value={false}>
                                <Box>{t('Active')}</Box>
                            </MenuItem>
                            <MenuItem value={true}>
                                <Box>{t('Archived')}</Box>
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <InfoToolTip variant="no-wrapper">
                        <InfoList
                            certifiedProfilesNumber={certifiedProfilesNumber}
                        />
                    </InfoToolTip>
                </Box>
            </Grid>
        </Grid>
    );
};

ConsumptionProfilesFilter.propTypes = {
    certifiedProfilesNumber: PropTypes.number,
    isArchived: PropTypes.bool,
    isFetchingConsumptionProfiles: PropTypes.bool,
    profileTypeSelected: PropTypes.string,
    selectProfileType: PropTypes.func,
    setFilterName: PropTypes.func,
    setIsArchived: PropTypes.func,
};

export default ConsumptionProfilesFilter;
