import { get } from 'common/api/v1/contactTrackingComments';

import {
    FETCH_COMMENTS,
    FETCH_COMMENTS_FAILURE,
    FETCH_COMMENTS_SUCCESS,
} from '../actionTypes';
import { trackingsActions } from '../reducer';

export default (trackingId) => (dispatch) => {
    dispatch(trackingsActions[FETCH_COMMENTS]());

    get(trackingId)
        .then((response) =>
            dispatch(
                trackingsActions[FETCH_COMMENTS_SUCCESS]({
                    data: response.data.data,
                    id: trackingId,
                }),
            ),
        )
        .catch((error) =>
            dispatch(trackingsActions[FETCH_COMMENTS_FAILURE](error)),
        );
};
