import i18next from 'i18next';

import { deleteSegment } from 'common/api/v1/segmentsLayout';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_SEGMENT_FAILURE,
    DELETE_SEGMENT_SUCCESS,
    DELETE_SEGMENT,
    UPDATE_SOLAR_MODULES_DESIGN_NUMBER,
} from '../actionTypes';
import { POLYGON_OBSTACLE, POLYGON_SEGMENT } from '../constants';
import { getCollidedPolygons } from '../helpers';
import { panelLayoutV2Actions } from '../reducer';
import * as selectors from '../selectors';

import checkSegment from './checkSegment';
import fetchOfferPanel from './fetchOfferPanel';
import updateSegments from './updateSegments';

export default (
        callback,
        commercialOfferId,
        id,
        google,
        mapValue,
        checkCallback,
        checkCallbackError,
    ) =>
    (dispatch, getState) => {
        const state = getState();
        const segments = selectors.getSegmentsData(state);
        const segment = segments.find((segment) => segment.id === id);
        let prevCollidedPolygons = [];

        dispatch(panelLayoutV2Actions[DELETE_SEGMENT]());

        if (segment?.type === POLYGON_OBSTACLE) {
            const polygons = segments.filter(
                (segment) => segment.type === POLYGON_SEGMENT,
            );

            prevCollidedPolygons = getCollidedPolygons({
                google,
                points: segment.prev_location,
                polygons,
            });
        }

        deleteSegment(id)
            .then(() => {
                dispatch(panelLayoutV2Actions[DELETE_SEGMENT_SUCCESS]());
                const filteredSegments = segments
                    .filter((current) => current.id !== id)
                    .map((current) => ({ ...current, open: false }));
                const newSolarModulesDesignNumber = filteredSegments.reduce(
                    (acc, current) => {
                        return acc + current.solar_modules_number;
                    },
                    0,
                );
                dispatch(updateSegments(filteredSegments));
                dispatch(
                    panelLayoutV2Actions[UPDATE_SOLAR_MODULES_DESIGN_NUMBER](
                        newSolarModulesDesignNumber,
                    ),
                );
                dispatch(fetchOfferPanel(commercialOfferId));
                showToast({
                    body: i18next.t('Segment has been removed'),
                    position: 'bottom-center',
                });

                if (callback) callback(id);

                if (prevCollidedPolygons.length > 0) {
                    prevCollidedPolygons.forEach((segment) => {
                        const { id, polygon } = segment;
                        const config = {
                            segmentId: id,
                            callback: checkCallback,
                            callbackError: checkCallbackError,
                            commercialOfferId,
                            google,
                            mapValue,
                            polygon,
                        };

                        dispatch(checkSegment(config));
                    });
                }
            })
            .catch((error) => {
                dispatch(
                    panelLayoutV2Actions[DELETE_SEGMENT_FAILURE](
                        error?.response?.data?.errors,
                    ),
                );
                showReponseErrorsAsAlert(dispatch, error.response);
            });
    };
