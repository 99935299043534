import { confirmSubscription } from 'common/api/v1/chargebee';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import fetchUserSettings from '../../../../modules/profile/actions/fetchProfileData';
import {
    CONFIRM_SUBSCRIPTION,
    CONFIRM_SUBSCRIPTION_FAILURE,
    CONFIRM_SUBSCRIPTION_SUCCESS,
} from '../actionTypes';
import { restrictedAccessSalesActions } from '../reducer';

export default (successCallback) => (dispatch) => {
    dispatch(restrictedAccessSalesActions[CONFIRM_SUBSCRIPTION]());

    confirmSubscription()
        .then(() => {
            dispatch(
                restrictedAccessSalesActions[CONFIRM_SUBSCRIPTION_SUCCESS](),
            );
            dispatch(fetchUserSettings());
            if (successCallback) successCallback();
        })
        .catch((error) => {
            dispatch(
                restrictedAccessSalesActions[CONFIRM_SUBSCRIPTION_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
