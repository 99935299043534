import React from 'react';

import PropTypes from 'prop-types';
import { Card, Divider, Skeleton, Grid } from 'sunwise-ui';

const Placeholder = (
    <Card>
        <Card.Body>
            <Skeleton variant="rounded" />

            <Divider sx={{ my: 1, mx: -2 }} />

            <Grid container>
                <Grid size={{ xs: 2 }}>
                    <Skeleton variant="circular" height={45} width={45} />
                </Grid>

                <Grid size={{ xs: 16 }}>
                    <Skeleton variant="rounded" />
                </Grid>
            </Grid>
        </Card.Body>
    </Card>
);

const PlaceholderCardLocation = ({ children, ready }) => (
    <>{ready ? Placeholder : children}</>
);

PlaceholderCardLocation.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    ready: PropTypes.bool,
};

export default PlaceholderCardLocation;
