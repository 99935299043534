import { fetchListCatalogs } from 'common/api/v1/companiesCatalogs';

import {
    FETCH_CONTACT_TYPES,
    FETCH_CONTACT_TYPES_FAILURE,
    FETCH_CONTACT_TYPES_SUCCESS,
} from '../actionTypes';
import { STATUS_CONTACT_TYPE } from '../constants';
import { actions } from '../reducer';

export default () => (dispatch) => {
    dispatch(actions[FETCH_CONTACT_TYPES]());

    fetchListCatalogs(STATUS_CONTACT_TYPE)
        .then((response) =>
            dispatch(actions[FETCH_CONTACT_TYPES_SUCCESS](response.data.data)),
        )
        .catch((error) =>
            dispatch(
                actions[FETCH_CONTACT_TYPES_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
