import { number, object } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default (_, { priceByWattMin, subtotalMin }) => {
    const { getMinValueText, NUMBER_TYPE_TEXT, REQUIRED_TEXT } =
        getValidationTexts();

    return object().shape({
        price_per_watt: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(priceByWattMin, getMinValueText(priceByWattMin))
            .nullable(),
        subtotal_amount: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(subtotalMin, getMinValueText(subtotalMin))
            .nullable(),
    });
};
