import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from 'sunwise-ui';

import { InputSearch, StatusFilterSelect } from 'common/components';

import ProjectViewMode from './ProjectViewMode';

const FilterCard = ({
    canModify,
    filterData,
    handleOnChangeSearch,
    handleOnSelectStatus,
    prepareFormCreate,
    setViewMode,
    viewMode,
}) => {
    const { t } = useTranslation();
    return (
        <Grid alignItems="end" container py={2}>
            <Grid size={{ lg: canModify ? 10 : 14, xs: 18 }}>
                <InputSearch
                    onChange={handleOnChangeSearch}
                    placeholder={t('Search')}
                    value={filterData?.searchText}
                />
            </Grid>
            <Grid size={{ lg: 3, md: 10, xs: 18 }}>
                <StatusFilterSelect
                    filterBy={filterData?.status}
                    handleSelected={handleOnSelectStatus}
                    variant="standard"
                />
            </Grid>
            <Grid
                size={{ xs: 2 }}
                sx={{
                    display: { xs: 'none', md: 'block' },
                    textAlign: 'center',
                }}
            >
                <ProjectViewMode
                    setViewMode={setViewMode}
                    viewMode={viewMode}
                />
            </Grid>
            {canModify && (
                <Grid size={{ lg: 3, md: 6, xs: 18 }}>
                    <Button
                        className="__intercom_add_project_button"
                        color="secondary"
                        dataIntercomTarget="Add Project (Button)"
                        endIcon={<AddIcon />}
                        fullWidth
                        onClick={() => prepareFormCreate()}
                        type="button"
                        variant="outlined"
                    >
                        <Typography component="span" fontWeight={700} noWrap>
                            {`${t('Create')} ${t('Project')}`}
                        </Typography>
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

FilterCard.propTypes = {
    canModify: PropTypes.bool,
    filterData: PropTypes.object,
    handleOnChangeSearch: PropTypes.func,
    handleOnSelectStatus: PropTypes.func,
    prepareFormCreate: PropTypes.func,
    setViewMode: PropTypes.func,
    viewMode: PropTypes.string,
};

export default FilterCard;
