import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BottomActions, Box, Button, Grid, Typography } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormInputDatePicker,
    ReactHookFormSelect2,
} from 'common/components/form/bootstrap';

const FiltersForm = ({
    filters,
    initialValues,
    onCancel,
    onResetValues,
    onSubmit,
    variant = 'standard',
}) => {
    const { control, getValues, handleSubmit, reset, setValue } = useForm({
        defaultValues: initialValues,
    });

    const { t } = useTranslation();

    useEffect(() => {
        if (initialValues) reset(initialValues);
        if (onResetValues) onResetValues(initialValues);
    }, [initialValues]);

    if (!filters?.length) return null;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {filters.map((filter) => (
                <Grid container key={filter.label}>
                    <Grid size={18}>
                        <Typography variant="subtitle2">
                            {filter.label}
                        </Typography>
                    </Grid>

                    {filter.fields?.map(
                        ({ onChange, render, type, visible = true, ...rest }) =>
                            visible && (
                                <Grid
                                    key={rest?.name}
                                    size={{ lg: 6, md: 9, xl: 4.5, xs: 18 }}
                                >
                                    {type === 'select' && (
                                        <ReactHookFormSelect2
                                            control={control}
                                            onChange={(value) =>
                                                onChange?.({
                                                    getValues,
                                                    setValue,
                                                    value,
                                                })
                                            }
                                            variant={variant}
                                            {...rest}
                                        />
                                    )}

                                    {type === 'input' && (
                                        <ReactHookFormInput
                                            control={control}
                                            onChange={(value) =>
                                                onChange?.({
                                                    getValues,
                                                    setValue,
                                                    value,
                                                })
                                            }
                                            variant={variant}
                                            {...rest}
                                        />
                                    )}

                                    {type === 'date' && (
                                        <ReactHookFormInputDatePicker
                                            control={control}
                                            onChange={(value) =>
                                                onChange?.({
                                                    getValues,
                                                    setValue,
                                                    value,
                                                })
                                            }
                                            variant={variant}
                                            {...rest}
                                        />
                                    )}

                                    {type === 'custom' &&
                                        render?.({
                                            control,
                                            getValues,
                                            setValue,
                                        })}
                                </Grid>
                            ),
                    )}
                </Grid>
            ))}

            <BottomActions>
                <Box
                    display="flex"
                    flexDirection={{ md: 'row', xs: 'column' }}
                    gap={1}
                    justifyContent={{ md: 'right', xs: 'center' }}
                    mt={2}
                    width="100%"
                >
                    {onCancel && (
                        <Button
                            color="secondary"
                            onClick={onCancel}
                            sx={{
                                order: { md: 1, xs: 1 },
                                width: { md: 'auto', xs: '100%' },
                            }}
                            variant="text"
                        >
                            {t('Cancel')}
                        </Button>
                    )}
                    <Button
                        sx={{
                            order: { md: 1, xs: 1 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        variant="outlined"
                        type="submit"
                    >
                        {t('Apply')}
                    </Button>
                </Box>
            </BottomActions>
        </form>
    );
};

FiltersForm.propTypes = {
    filters: PropTypes.array,
    initialValues: PropTypes.object,
    onCancel: PropTypes.func,
    onResetValues: PropTypes.func,
    onSubmit: PropTypes.func,
    variant: PropTypes.string,
};

export default FiltersForm;
