import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { FaRobot, FaUser } from 'react-icons/fa';

export const NAME = 'report-list';

export const REPORT_RESOLUTIONS = {
    MANUAL: 0,
    AUTOMATIC: 1,
};

export const REPORT_RESOLUTIONS_CONFIG = {
    [REPORT_RESOLUTIONS.MANUAL]: { Icon: FaUser, label: 'Manual' },
    [REPORT_RESOLUTIONS.AUTOMATIC]: { Icon: FaRobot, label: 'Automatic' },
};

export const REPORT_STATUS_KEYS = {
    DRAFT: 0,
    GENERATED: 1,
    PENDING: 2,
    SENT: 3,
    WAITING_FOR_LISA: 4,
};

export const REPORT_STATUS_CONFIG = {
    [REPORT_STATUS_KEYS.PENDING]: {
        color: 'transparent',
        Icon: CircleIcon,
        label: 'Pending',
    },
    [REPORT_STATUS_KEYS.WAITING_FOR_LISA]: {
        color: 'transparent',
        Icon: CircleIcon,
        label: 'Pending',
    },
    [REPORT_STATUS_KEYS.DRAFT]: {
        color: '#f57d00',
        Icon: CircleIcon,
        label: 'Draft',
    },
    [REPORT_STATUS_KEYS.GENERATED]: {
        color: '#3caf5f',
        Icon: CircleIcon,
        label: 'Generated',
    },
    [REPORT_STATUS_KEYS.SENT]: {
        color: '#3caf5f',
        Icon: CheckCircleIcon,
        label: 'Sent',
    },
};
