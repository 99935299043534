import { NAME } from './constants';

export const FETCH_FINANCIALS = `${NAME}/FETCH_FINANCIALS`;
export const FETCH_FINANCIALS_FAILURE = `${NAME}/FETCH_FINANCIALS_FAILURE`;
export const FETCH_FINANCIALS_SUCCESS = `${NAME}/FETCH_FINANCIALS_SUCCESS`;

export const FETCH_FINANCIERS = `${NAME}/FETCH_FINANCIERS`;
export const FETCH_FINANCIERS_FAILURE = `${NAME}/FETCH_FINANCIERS_FAILURE`;
export const FETCH_FINANCIERS_SUCCESS = `${NAME}/FETCH_FINANCIERS_SUCCESS`;

export const FETCH_REQUIREMENTS = `${NAME}/FETCH_REQUIREMENTS`;
export const FETCH_REQUIREMENTS_FAILURE = `${NAME}/FETCH_REQUIREMENTS_FAILURE`;
export const FETCH_REQUIREMENTS_SUCCESS = `${NAME}/FETCH_REQUIREMENTS_SUCCESS`;

export const SAVE_ALLIANCE = `${NAME}/SAVE_ALLIANCE`;
export const SAVE_ALLIANCE_FAILURE = `${NAME}/SAVE_ALLIANCE_FAILURE`;
export const SAVE_ALLIANCE_SUCCESS = `${NAME}/SAVE_ALLIANCE_SUCCESS`;

export const SIMULATE_CREDIT = `${NAME}/SIMULATE_CREDIT`;
export const SIMULATE_CREDIT_FAILURE = `${NAME}/SIMULATE_CREDIT_FAILURE`;
export const SIMULATE_CREDIT_SUCCESS = `${NAME}/SIMULATE_CREDIT_SUCCESS`;

export const SET_IS_OPEN_MODAL = `${NAME}/SET_IS_OPEN_MODAL`;
export const SET_IS_OPEN_SIMULATE_MODAL = `${NAME}/SET_IS_OPEN_SIMULATE_MODAL`;
