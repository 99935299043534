import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Grid, Typography } from 'sunwise-ui';

import OpacityAnimation from 'common/components/animations/OpacityAnimation';

import logotipoSunwiseAzul from 'resources/logotipoSunwiseAzul.svg';

import {
    resetPassword,
    setUserUuidToken,
    handleChangePassword,
    handleChangePasswordConfirmation,
} from './actions';
import { ResetPasswordForm } from './components';
import * as selectors from './selectors';

const Container = ({
    errors,
    handleChangePassword,
    handleChangePasswordConfirmation,
    initialValues,
    isLoadingSession,
    match,
    resetPassword,
    setUserUuidToken,
    showRedirectLogin,
    validPassword,
    validPasswordConfirmation,
    validPasswordConfirmationLength,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        const uuid = match.params.uuid;
        const token = match.params.token;
        setUserUuidToken(uuid, token);
    }, []);

    const handleSubmit = (values) => resetPassword(values);
    const onChangePassword = (event) => handleChangePassword(event);
    const onChangePasswordConfirmation = (event, values) =>
        handleChangePasswordConfirmation(event, values);

    return (
        <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="center"
            sx={{ height: '100%' }}
        >
            <Grid size={{ md: 7, xs: 18 }} sx={{ p: 2 }}>
                <OpacityAnimation>
                    <Box sx={{ textAlign: 'center' }}>
                        <img
                            alt="Sunwise"
                            src={logotipoSunwiseAzul}
                            width="300"
                        />
                    </Box>

                    <Box hidden={showRedirectLogin} py={2}>
                        <Typography variant="h4">
                            {t('Hello again!')}
                        </Typography>
                        <Typography variant="body2">
                            {t('We are 1 click away to reset your password')}
                        </Typography>
                    </Box>

                    <ResetPasswordForm
                        errors={errors}
                        initialValues={initialValues}
                        isLoadingSession={isLoadingSession}
                        onChangePassword={onChangePassword}
                        onChangePasswordConfirmation={
                            onChangePasswordConfirmation
                        }
                        onSubmit={handleSubmit}
                        showRedirectLogin={showRedirectLogin}
                        validPassword={validPassword}
                        validPasswordConfirmation={validPasswordConfirmation}
                        validPasswordConfirmationLength={
                            validPasswordConfirmationLength
                        }
                    />
                </OpacityAnimation>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsLogin,
    initialValues: selectors.getInitialValues,
    isLoadingSession: selectors.getIsLoadingSession,
    showRedirectLogin: selectors.getShowRedirectLogin,
    validPassword: selectors.getValidPassword,
    validPasswordConfirmation: selectors.getValidPasswordConfirmation,
    validPasswordConfirmationLength:
        selectors.getValidPasswordConfirmationLength,
});

const mapDispatchToProps = (dispatch) => ({
    handleChangePassword: (event) => dispatch(handleChangePassword(event)),
    handleChangePasswordConfirmation: (event, values) =>
        dispatch(handleChangePasswordConfirmation(event, values)),
    resetPassword: (data) => dispatch(resetPassword(data)),
    setUserUuidToken: (uuid, token) => dispatch(setUserUuidToken(uuid, token)),
});

Container.propTypes = {
    errors: PropTypes.array,
    handleChangePassword: PropTypes.func,
    handleChangePasswordConfirmation: PropTypes.func,
    initialValues: PropTypes.object,
    isLoadingSession: PropTypes.bool,
    match: PropTypes.object,
    resetPassword: PropTypes.func,
    setUserUuidToken: PropTypes.func,
    showRedirectLogin: PropTypes.bool,
    validPassword: PropTypes.bool,
    validPasswordConfirmation: PropTypes.bool,
    validPasswordConfirmationLength: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
