import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import {
    ReactHookFormCheck,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';

import FormAccordion from './FormAccordion';

const PPASection = ({ control, currencySymbol, disabled }) => {
    const { t } = useTranslation();
    const ppaActive = useWatch({
        control,
        name: 'ppa_active',
    });

    return (
        <FormAccordion title="PPA">
            <Grid container>
                <Grid
                    alignItems={{ sm: 'center', xs: 'flex-start' }}
                    display="flex"
                    flexDirection={{ sm: 'row', xs: 'column' }}
                    gap={{ sm: 2, xs: 0 }}
                    size={{ md: 9, xs: 18 }}
                >
                    <ReactHookFormCheck
                        control={control}
                        disabled={disabled}
                        label={t('Active')}
                        name="ppa_active"
                        sxFormControl={{ flexShrink: 0 }}
                        variant="standard"
                    />

                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        control={control}
                        disabled={disabled || !ppaActive}
                        fullWidth
                        label={t('Price')}
                        min={0}
                        name="ppa_price"
                        prepend={currencySymbol}
                        variant="standard"
                    />
                </Grid>

                <Grid size={{ md: 9, xs: 18 }}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="%"
                        control={control}
                        disabled={disabled || !ppaActive}
                        fullWidth
                        label={t('Annual percentage increase')}
                        min={0}
                        name="ppa_percentage_increase"
                        placeholder="0"
                        variant="standard"
                    />
                </Grid>
            </Grid>
        </FormAccordion>
    );
};

PPASection.propTypes = {
    control: PropTypes.object,
    currencySymbol: PropTypes.string,
    disabled: PropTypes.bool,
};

export default PPASection;
