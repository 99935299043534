import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import useBreakpoint from 'common/hooks/useBreakpoint';
import RestrictedAccessLanding from 'common/modules/restrictedAccessLanding/Container';

import AfterSalesConsumptionHistory from '../afterSalesConsumptionHistory';
import afterSalesSettings from '../afterSalesSettings';
import * as afterSalesSettingsSelectors from '../afterSalesSettings/selectors';
import reportList from '../reportList';

const Container = ({
    afterSalesSettingsDataId,
    canDelete,
    canModify,
    fetchAfterSalesSettings,
    selectedProject,
}) => {
    const selectedProjectId = selectedProject?.id;

    useEffect(() => {
        fetchAfterSalesSettings(selectedProjectId);
    }, [selectedProjectId]);

    if (!afterSalesSettingsDataId)
        return (
            <afterSalesSettings.Container
                canModify={canModify}
                selectedProjectId={selectedProjectId}
            />
        );

    return (
        <Grid container>
            <Grid size={{ md: 9, xs: 18 }}>
                <AfterSalesConsumptionHistory.Container
                    canDelete={canDelete}
                    canModify={canModify}
                    selectedProject={selectedProject}
                />
            </Grid>

            <Grid size={{ md: 9, xs: 18 }}>
                <reportList.Container
                    canDelete={canDelete}
                    canModify={canModify}
                    selectedProjectId={selectedProjectId}
                />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    afterSalesSettingsDataId:
        afterSalesSettingsSelectors.getAfterSalesSettingsDataId,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAfterSalesSettings: (projectId) =>
        dispatch(afterSalesSettings.actions.fetchAfterSalesSettings(projectId)),
});

Container.propTypes = {
    afterSalesSettingsDataId: PropTypes.string,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    fetchAfterSalesSettings: PropTypes.func,
    selectedProject: PropTypes.object,
};

const RestrictedContainer = ({ canView, ...props }) => {
    const [height, setHeight] = useState(3050);
    const breakpoint = useBreakpoint();

    useEffect(() => {
        switch (breakpoint) {
            case 'xs':
                setHeight(4390);
                break;
            case 'sm':
                setHeight(3700);
                break;
            case 'md':
                setHeight(2650);
                break;
            default:
                setHeight(3050);
                break;
        }
    }, [breakpoint]);

    if (!canView) return null;

    return (
        <RestrictedAccessLanding
            addonName="Manual-Reporting,Sunwise-After-Sales"
            minHeight={height}
            url="https://somos.sunwise.io/monitoreo-postventa"
        >
            <Container {...props} />
        </RestrictedAccessLanding>
    );
};

RestrictedContainer.propTypes = {
    canView: PropTypes.bool,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.AFTER_SALES_PERMISSION),
)(RestrictedContainer);
