import { getCompensationSchemeByBackendKey } from 'common/utils/helpers/rates';

import { getHourlyContractedDemandFromValues } from '../helpers';

import fetchScheduleRateConfiguration from './fetchScheduleRateConfiguration';
import initializeForm from './initializeForm';

export default (item, successCallback) => (dispatch) => {
    const newValues = {
        auto_report: item?.auto_report,
        auto_report_day: item?.auto_report_day,
        cfe_user: item?.cfe_user,
        commercial_offer: item?.commercial_offer?.id,
        compensation_scheme: getCompensationSchemeByBackendKey({
            backendKey: item?.compensation_scheme,
            certified: item?.rate?.certified,
            name: item?.rate?.name,
            paymentType: item?.rate?.payment_type,
        })?.value,
        contracted_demand: item?.contracted_demand?.[0]?.value,
        currency: item?.currency?.id,
        custom_offer_template: item?.custom_offer_template,
        exported_energy_price: item?.exported_energy_price,
        id: item?.id,
        integration_type: item?.integration_type,
        is_bimonthly: item?.is_bimonthly ? '1' : '0',
        netted_exported_generation_price:
            item?.netted_exported_generation_price,
        political_division1: item?.political_division1?.id,
        political_division2: item?.political_division2?.id,
        ppa_active: item?.ppa_active,
        ppa_percentage_increase: item?.ppa_percentage_increase,
        ppa_price: item?.ppa_price,
        rate: item?.rate?.id,
        rate_division: item?.rate_division?.id,
        rate_division_summer: item?.rate_division_summer?.id,
        rate_region: item?.rate_division?.id,
        service_number: item?.service_number || '',
        source: item?.source,
        start_date: item?.start_date,
        subsidy_rate: item?.subsidy_rate?.name,
        system_size: item?.system_size,
        total_cost: item?.total_cost,
        type_change: item?.type_change,
        zap_rpu_id: item?.zap_rpu_id,
        zip_code: item?.zip_code,
    };

    const promises = [];

    if (newValues?.rate)
        promises.push(
            dispatch(
                fetchScheduleRateConfiguration({
                    certified: item?.rate?.certified,
                    rate: newValues?.rate,
                    rate_division: newValues?.rate_division,
                }),
            ),
        );

    Promise.all(promises).then((scheduleRateConfiguration) => {
        const demandFields = getHourlyContractedDemandFromValues(
            item?.rate?.certified,
            scheduleRateConfiguration?.[0]?.tiers_demand_distribution?.[0]
                ?.tiers,
            item?.contracted_demand,
        );

        dispatch(initializeForm({ ...newValues, ...demandFields }));

        if (successCallback) successCallback();
    });
};
