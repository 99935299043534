import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_FILTERS,
    FETCH_FILTERS_FAILURE,
    FETCH_FILTERS_SUCCESS,
    FETCH_REPORT_HISTORICALS,
    FETCH_REPORT_HISTORICALS_FAILURE,
    FETCH_REPORT_HISTORICALS_SUCCESS,
    FETCH_REPORTS,
    FETCH_REPORTS_FAILURE,
    FETCH_REPORTS_SUCCESS,
    INITIALIZE_FILTERS,
    RESET,
    SET_INITIAL_VALUES_FILTERS,
    SET_IS_OPEN_FILTERS_MODAL,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    filters: { data: [], errors: null, loading: false },
    initialValuesFilters: {},
    isOpenFiltersModal: false,
    reports: {},
    reportHistoricals: { data: {}, errors: null, loading: false },
};

const funnelReportsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_FILTERS]: (state) => {
            state.filters.errors = null;
            state.filters.loading = true;
        },
        [FETCH_FILTERS_FAILURE]: (state, action) => {
            state.filters.errors = action.payload;
            state.filters.loading = false;
        },
        [FETCH_FILTERS_SUCCESS]: (state, action) => {
            state.filters.data = action.payload;
            state.filters.loading = false;
        },
        [FETCH_REPORT_HISTORICALS]: (state) => {
            state.reportHistoricals.errors = null;
            state.reportHistoricals.loading = true;
        },
        [FETCH_REPORT_HISTORICALS_FAILURE]: (state, action) => {
            state.reportHistoricals.errors = action.payload;
            state.reportHistoricals.loading = false;
        },
        [FETCH_REPORT_HISTORICALS_SUCCESS]: (state, action) => {
            state.reportHistoricals.data = action.payload;
            state.reportHistoricals.loading = false;
        },
        [FETCH_REPORTS]: (state, action) => {
            const { dataKey } = action.payload;

            if (!state.reports[dataKey])
                state.reports[dataKey] = {
                    data: [],
                    errors: null,
                    loading: false,
                    meta: {},
                };

            state.reports[dataKey].errors = null;
            state.reports[dataKey].loading = true;
        },
        [FETCH_REPORTS_FAILURE]: (state, action) => {
            const { dataKey, errors } = action.payload;

            state.reports[dataKey].errors = errors;
            state.reports[dataKey].loading = false;
        },
        [FETCH_REPORTS_SUCCESS]: (state, action) => {
            const { data, dataKey, meta, replaceValues } = action.payload;

            if (!state.reports[dataKey])
                state.reports[dataKey] = { data: [], meta: {} };

            state.reports[dataKey].loading = false;

            if (replaceValues) {
                state.reports[dataKey].data = data;
                state.reports[dataKey].meta = meta;
            } else {
                state.reports[dataKey].data = [
                    ...state.reports[dataKey].data,
                    ...data,
                ];
                state.reports[dataKey].meta = meta;
            }
        },
        [INITIALIZE_FILTERS]: (state, action) => {
            state.initialValuesFilters = action.payload;
        },
        [RESET]: () => INITIAL_STATE,
        [SET_INITIAL_VALUES_FILTERS]: (state, action) => {
            state.initialValuesFilters = action.payload;
        },
        [SET_IS_OPEN_FILTERS_MODAL]: (state, action) => {
            state.isOpenFiltersModal = action.payload;
        },
    },
});

export const actions = funnelReportsSlice.actions;
export default funnelReportsSlice.reducer;
