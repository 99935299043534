import React from 'react';

import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, DataGrid, Typography } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import { CB_PORTAL_SECTIONS, SUBSCRIPTION_STATUS } from 'common/constants';

import { getStatusDetails } from '../helpers';

const AddonsSubscriptionTable = ({
    canModify,
    handlePortal,
    items,
    last4,
    payInvoices,
}) => {
    const { t } = useTranslation();

    const formatItemPriceId = (itemPriceId) => {
        return itemPriceId.replace(/[_-]/g, ' ');
    };

    const columns = [
        {
            align: 'left',
            field: 'item_price_id',
            flex: 1,
            headerName: t('Name'),
            minWidth: 250,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography fontWeight={600} variant="body2">
                        {formatItemPriceId(row?.item_price_id)}
                    </Typography>
                );
            },
            sortable: false,
        },
        {
            align: 'left',
            field: 'amount',
            flex: 1,
            headerName: t('Amount'),
            minWidth: 120,
            sortable: false,
        },
        {
            align: 'left',
            field: 'status',
            flex: 1,
            headerName: t('Status'),
            minWidth: 110,
            renderCell: (params) => {
                const { row } = params;
                if (row?.due_invoices_count > 0)
                    return i18next.t('Payment due');
                return getStatusDetails(row?.status);
            },
            sortable: false,
        },
        {
            align: 'right',
            field: '',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <DropdownCardMenu
                        items={[
                            {
                                handleClick: () =>
                                    handlePortal(
                                        CB_PORTAL_SECTIONS.SUBSCRIPTION_CANCELLATION,
                                        row?.id,
                                    ),
                                label: t('Cancel subscription'),
                                visible:
                                    row?.status === SUBSCRIPTION_STATUS.ACTIVE,
                            },
                            {
                                handleClick: () =>
                                    handlePortal(
                                        CB_PORTAL_SECTIONS.SUBSCRIPTION_DETAILS,
                                        row?.id,
                                    ),
                                label: t('Active subscription'),
                                visible: [
                                    SUBSCRIPTION_STATUS.CANCELLED,
                                    SUBSCRIPTION_STATUS.NON_RENEWING,
                                ].includes(row?.status),
                            },
                            {
                                handleClick: () => payInvoices(last4),
                                label: t('Pay subscription'),
                                visible: row?.due_invoices_count > 0,
                            },
                        ]}
                        sxIconButton={{ ml: 'auto' }}
                        visible={canModify}
                    />
                );
            },
            sortable: false,
        },
    ];

    return (
        <Box sx={{ mt: 1 }}>
            <DataGrid
                autoHeight
                checkboxSelection={false}
                columns={columns}
                disableColumnMenu
                disableSelectionOnClick
                hideFooter
                rows={items}
            />
        </Box>
    );
};

AddonsSubscriptionTable.propTypes = {
    canModify: PropTypes.bool,
    handlePortal: PropTypes.func,
    items: PropTypes.array,
    last4: PropTypes.string,
    payInvoices: PropTypes.func,
};

export default AddonsSubscriptionTable;
