import { boolean, number, object, string } from 'yup';

import { DOMESTIC_RATES, RATES_WITH_DIVISION } from 'common/constants/rates';
import getValidationTexts from 'common/utils/getValidationTexts';

import { INTEGRATION_TYPES } from './constants';

export default (values, { ratesDictionary }) => {
    const {
        getMaxValueText,
        getMinValueText,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();

    const shape = {
        auto_report: boolean().nullable(),
        auto_report_day: number()
            .typeError(NUMBER_TYPE_TEXT)
            .min(1, getMinValueText(1))
            .max(31, getMaxValueText(31))
            .when('auto_report', {
                is: true,
                then: (schema) => schema.required(REQUIRED_TEXT),
                otherwise: (schema) => schema.nullable(),
            }),
        cfe_user: string().when('integration_type', {
            is: INTEGRATION_TYPES.LISA_ENERGY,
            then: (schema) => schema.required(REQUIRED_TEXT),
            otherwise: (schema) => schema.nullable(),
        }),
        commercial_offer: string().nullable(),
        compensation_scheme: string().required(REQUIRED_TEXT),
        contracted_demand: number().typeError(NUMBER_TYPE_TEXT).nullable(),
        currency: string().required(REQUIRED_TEXT),
        custom_offer_template: string().nullable(),
        hourly_contracted_demand: object()
            .shape({
                tier: number().typeError(NUMBER_TYPE_TEXT).nullable(),
                value: number().typeError(NUMBER_TYPE_TEXT).nullable(),
            })
            .nullable(),
        integration_type: number()
            .typeError(NUMBER_TYPE_TEXT)
            .transform((v) =>
                Object.values(INTEGRATION_TYPES).includes(v) ? v : null,
            )
            .nullable(),
        political_division1: string().required(REQUIRED_TEXT),
        political_division2: string().required(REQUIRED_TEXT),
        rate: string().required(REQUIRED_TEXT),
        source: string().required(REQUIRED_TEXT),
        start_date: string().required(REQUIRED_TEXT),
        system_size: number().typeError(NUMBER_TYPE_TEXT).required(),
        total_cost: number()
            .typeError(NUMBER_TYPE_TEXT)
            .min(0, getMinValueText(0))
            .nullable(),
        type_change: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT),
        zap_rpu_id: string().when('integration_type', {
            is: INTEGRATION_TYPES.LISA_ENERGY,
            then: (schema) => schema.required(REQUIRED_TEXT),
            otherwise: (schema) => schema.nullable(),
        }),
        zip_code: string().nullable(),
    };

    const selectedRate = ratesDictionary[values?.rate];

    if (selectedRate?.certified) {
        shape.is_bimonthly = string().required(REQUIRED_TEXT);

        if (RATES_WITH_DIVISION.includes(selectedRate.name)) {
            shape.rate_division = string().required(REQUIRED_TEXT);
        }

        if (selectedRate.name === 'DAC') {
            shape.subsidy_rate = string().required(REQUIRED_TEXT);
            shape.rate_region = string().required(REQUIRED_TEXT);
        }

        if (DOMESTIC_RATES.includes(selectedRate.name)) {
            shape.rate_division_summer = string().required(REQUIRED_TEXT);
        }
    }

    return object().shape(shape);
};
