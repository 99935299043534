import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from 'sunwise-ui';

import { applyFormat } from '../helpers';

const Indicator = ({
    cols,
    countryCurrencyLocale,
    indicatorsData,
    keyName,
    title,
}) => (
    <Grid container textAlign="center" mb={1}>
        <Grid size={{ md: 5 }} textAlign="left">
            <Typography fontWeight="bold" variant="body2">
                {title}
            </Typography>
        </Grid>

        {indicatorsData?.map((indicator, index) => (
            <Grid key={index} size={{ md: cols }}>
                {indicator[keyName]
                    ? applyFormat(indicator[keyName], countryCurrencyLocale)
                    : null}
            </Grid>
        ))}
    </Grid>
);

Indicator.propTypes = {
    cols: PropTypes.number,
    countryCurrencyLocale: PropTypes.string,
    indicatorsData: PropTypes.array,
    keyName: PropTypes.string,
    title: PropTypes.string,
};

const PeriodTable = ({ countryCurrencyLocale, indicatorsData }) => {
    const { t } = useTranslation();
    const itemCols = 13 / indicatorsData?.length || 0;
    return (
        <Box mb={2} px={2} sx={{ display: { xs: 'none', md: 'block' } }}>
            <Grid container textAlign="center" mb={1}>
                <Grid size={{ md: 5 }} />

                {indicatorsData?.map((tier, index) => (
                    <Grid key={index} size={{ md: itemCols }}>
                        <Typography variant="body2" fontWeight="bold">
                            {tier?.name}
                        </Typography>
                    </Grid>
                ))}
            </Grid>

            <Indicator
                cols={itemCols}
                countryCurrencyLocale={countryCurrencyLocale}
                indicatorsData={indicatorsData}
                keyName="max_daily_consumption"
                title={`${t('Max daily consumption')} (kWh)`}
            />

            <Indicator
                cols={itemCols}
                countryCurrencyLocale={countryCurrencyLocale}
                indicatorsData={indicatorsData}
                keyName="max_demand"
                title={`${t('Max demand')} (kW)`}
            />

            <Indicator
                cols={itemCols}
                countryCurrencyLocale={countryCurrencyLocale}
                indicatorsData={indicatorsData}
                keyName="average_daily_demand"
                title={`${t('Average daily demand')} (kW)`}
            />
        </Box>
    );
};

PeriodTable.propTypes = {
    countryCurrencyLocale: PropTypes.string,
    indicatorsData: PropTypes.array,
};

export default PeriodTable;
