import React from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PropTypes from 'prop-types';
import { Button, Grid } from 'sunwise-ui';

const SeeMoreButton = ({ isCollapsed, label, setIsCollapsed }) => (
    <Grid container>
        <Grid size="grow" textAlign="center" p={2}>
            <Button
                onClick={() => setIsCollapsed(!isCollapsed)}
                variant="text"
                endIcon={
                    isCollapsed ? (
                        <KeyboardArrowUpIcon />
                    ) : (
                        <KeyboardArrowDownIcon />
                    )
                }
            >
                {label}
            </Button>
        </Grid>
    </Grid>
);

SeeMoreButton.propTypes = {
    isCollapsed: PropTypes.bool,
    label: PropTypes.string,
    setIsCollapsed: PropTypes.func,
};

export default SeeMoreButton;
