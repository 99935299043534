import React, { useContext, useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { LoadingContext } from 'common/utils/contexts';

import * as actions from './actions';
import AddModal from './components/AddModal';
import StyledButton from './components/StyledButton';
import * as selectors from './selectors';

const Container = ({ canModify, handleClickAlliance, isSaving, openModal }) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isSaving)
            loadingContext.openLoading(
                `${t('Adding')} ${t('Alliance').toLowerCase()}`.concat('...'),
            );
        else loadingContext.closeLoading();
    }, [isSaving]);

    return (
        <>
            <Grid size={{ lg: 3, md: 5, xs: 9 }}>
                <Button
                    disabled={!canModify}
                    onClick={() => openModal(true)}
                    variant="default"
                >
                    <Box>
                        <StyledButton variant="new">
                            <AddIcon />
                        </StyledButton>
                        {t('Add alliance')}
                    </Box>
                </Button>
            </Grid>

            <AddModal
                closeModal={() => openModal(false)}
                handleClickAlliance={handleClickAlliance}
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    isSaving: selectors.getIsSavingAlliance,
});

const mapDispatchToProps = (dispatch) => ({
    openModal: (value) => dispatch(actions.setIsOpenModal(value)),
});

Container.propTypes = {
    canModify: PropTypes.bool,
    handleClickAlliance: PropTypes.func,
    isSaving: PropTypes.bool,
    openModal: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.PROPOSAL_ALLIANCE_PRODUCT_PERMISSION),
)(Container);
