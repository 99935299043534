import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from 'sunwise-ui';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';
import { getCurrencyById, getCurrenciesToSelect } from 'common/utils/helpers';

import TypeChangeField from './TypeChangeField';

const CurrencyFields = ({
    canModifyCurrencyType,
    canModifyExchangeType,
    control,
    currencies,
    isLocked,
    setValue,
}) => {
    const { t } = useTranslation();
    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">
                    {t('Currency')}
                </TitleWithDetail>
            </Box>

            <Grid container>
                <Grid size={{ lg: 9, xs: 18 }}>
                    <ReactHookFormSelect
                        control={control}
                        disabled={isLocked || !canModifyCurrencyType}
                        label={t('Type of currency')}
                        name="currency"
                        onChange={(e) => {
                            const item = getCurrencyById(
                                currencies,
                                e.target.value,
                            );
                            if (item) {
                                setValue('currency_item', item);
                                setValue('discount_rate', item.discount_rate);
                            }
                        }}
                        options={getCurrenciesToSelect(currencies, false)}
                    />
                </Grid>
                <Grid size={{ lg: 9, xs: 18 }}>
                    <TypeChangeField
                        canModifyExchangeType={canModifyExchangeType}
                        currencies={currencies}
                        control={control}
                        isLocked={isLocked}
                        setValue={setValue}
                    />
                </Grid>
            </Grid>
        </>
    );
};

CurrencyFields.propTypes = {
    canModifyCurrencyType: PropTypes.bool,
    canModifyExchangeType: PropTypes.bool,
    control: PropTypes.object,
    currencies: PropTypes.array,
    isLocked: PropTypes.bool,
    setValue: PropTypes.func,
};

export default CurrencyFields;
