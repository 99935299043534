import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput } from 'react-mentions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Card, Button, UiDialog as Dialog, Typography } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import { trackingTypeToLabel, truncateString } from 'common/utils/helpers';

import * as profileSelectors from '../../profile/selectors';
import { MAXIMUM_CHARACTERS_FOR_TRACKING } from '../constants';
import { getUsersOptions } from '../helpers';

import CommentForm from './CommentForm';
import CommentsList from './CommentsList';
import mentionsMessageStyle from './mentionsMessageStyle';
import mentionStyle from './mentionStyle';
import ReasignTrackingForm from './ReasignTrackingForm';
import TrackingTypeIcon from './TrackingTypeIcon';

const TrackingItem = ({
    agent,
    assigned_to,
    canDelete,
    canModify,
    completed,
    createdAt,
    description,
    goal,
    handleClickChangeDate,
    handleClickDelete,
    handleClickMarkAsCompleted,
    hasExpired,
    id,
    project,
    remember,
    trackingDate,
    trackingType,
    users,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const [maxCharacters, setMaxCharacters] = useState(
        MAXIMUM_CHARACTERS_FOR_TRACKING,
    );
    const [isOpenModalTracking, setIsOpenModalTracking] = useState(false);
    const [isOpenCommentArea, setIsOpenCommentArea] = useState(false);

    const defaultUser = `${get(agent, 'first_name', '---')} ${get(
        agent,
        'last_name',
        '---',
    )}`;

    const projectName = get(project, 'name', '---');

    const filterAssignedTo =
        assigned_to?.filter((user) => user.id !== agent.id) || [];

    const ohterUsers = filterAssignedTo.map(
        (user) =>
            `${get(user, 'first_name', '---')} ${get(user, 'last_name', '---')}`,
    );

    const agents = [defaultUser, ...ohterUsers].join(', ');

    const tooggleCommentArea = () => setIsOpenCommentArea((prev) => !prev);

    return (
        <Card
            sx={{
                background: (theme) =>
                    theme.palette.mode === 'dark'
                        ? 'rgb(40, 43, 48)'
                        : 'rgba(238, 244, 250, 0.35)',
                my: 2,
            }}
        >
            <Card.Header sx={{ backgroundColor: 'rgb(238 244 250 / 35%)' }}>
                <Box alignItems="center" display="flex" gap={2}>
                    <TrackingTypeIcon type={trackingType} />

                    <Box display="flex" flexDirection="column">
                        <Typography fontWeight="bold" variant="body2">
                            {trackingTypeToLabel(trackingType)}
                        </Typography>

                        {remember && (
                            <Typography variant="body2">
                                {trackingDate} -{' '}
                                {hasExpired ? t('Expired') : t('Expires')}{' '}
                                {createdAt}
                            </Typography>
                        )}
                    </Box>

                    <Box sx={{ ml: 'auto' }}>
                        <DropdownCardMenu
                            items={[
                                {
                                    handleClick: handleClickDelete,
                                    icon: <DeleteIcon fontSize="small" />,
                                    label: t('Delete'),
                                },
                            ]}
                            visible={canDelete}
                        />
                    </Box>
                </Box>
            </Card.Header>

            <Card.Body
                sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
            >
                <Box>
                    <Typography fontWeight="bold" variant="body2">
                        {t('Objective')}: {goal}
                    </Typography>
                </Box>

                <Box>
                    <Typography fontWeight="bold" variant="body2">
                        {t('Assigned to')}:
                    </Typography>
                    <Typography variant="body2">{agents}</Typography>
                </Box>

                <Box>
                    <Typography fontWeight="bold" variant="body2">
                        {t('Project')}
                    </Typography>
                    <Typography variant="body2">{projectName}</Typography>
                </Box>

                <Box>
                    <Typography fontWeight="bold" variant="body2">
                        {t('Note')}
                    </Typography>

                    <Box
                        sx={{ inlineSize: '100%', overflowWrap: 'break-word' }}
                    >
                        <MentionsInput
                            style={{
                                ...mentionsMessageStyle,
                                input: {
                                    ...mentionsMessageStyle.input,
                                    color: isDarkMode ? '#fff' : '#000',
                                },
                            }}
                            value={truncateString(description, maxCharacters)}
                        >
                            <Mention
                                data={getUsersOptions(users)}
                                style={
                                    isDarkMode
                                        ? {
                                              backgroundColor:
                                                  'rgba(255, 255, 255, 0.09)',
                                          }
                                        : mentionStyle
                                }
                            />
                        </MentionsInput>
                    </Box>

                    {description?.length > MAXIMUM_CHARACTERS_FOR_TRACKING && (
                        <Box textAlign="center">
                            <Button
                                color="secondary"
                                onClick={() =>
                                    maxCharacters >
                                    MAXIMUM_CHARACTERS_FOR_TRACKING
                                        ? setMaxCharacters(
                                              MAXIMUM_CHARACTERS_FOR_TRACKING,
                                          )
                                        : setMaxCharacters(description.length)
                                }
                                sx={{ p: 0 }}
                                variant="text"
                            >
                                {maxCharacters >
                                MAXIMUM_CHARACTERS_FOR_TRACKING ? (
                                    <ExpandLess />
                                ) : (
                                    <ExpandMore />
                                )}
                            </Button>
                        </Box>
                    )}
                </Box>

                <Box>
                    <Typography
                        onClick={tooggleCommentArea}
                        fontWeight="bold"
                        style={{ cursor: 'pointer', display: 'flex', gap: 2 }}
                        variant="body2"
                    >
                        {!isOpenCommentArea ? (
                            <>
                                {t('Comment', { count: 2 })} <ExpandMore />
                            </>
                        ) : (
                            <>
                                {t('Hide')} <ExpandLess />
                            </>
                        )}
                    </Typography>

                    {isOpenCommentArea && (
                        <>
                            <CommentsList trackingId={id} />
                            <CommentForm trackingId={id} />
                        </>
                    )}
                </Box>

                <Box hidden={!canModify}>
                    {!completed ? (
                        <Button
                            color="secondary"
                            fullWidth
                            onClick={handleClickMarkAsCompleted}
                            variant="text"
                        >
                            {t('Mark as completed')}
                        </Button>
                    ) : (
                        <Button
                            color="secondary"
                            fullWidth
                            onClick={() => setIsOpenModalTracking(true)}
                            variant="text"
                        >
                            {t('Remember again')}
                        </Button>
                    )}
                </Box>

                <Dialog
                    onClose={() => setIsOpenModalTracking(false)}
                    open={isOpenModalTracking}
                    size="sm"
                    title={t('Remember again')}
                >
                    <ReasignTrackingForm
                        onSave={handleClickChangeDate}
                        trackingId={id}
                    />
                </Dialog>
            </Card.Body>
        </Card>
    );
};

TrackingItem.propTypes = {
    agent: PropTypes.object,
    assigned_to: PropTypes.array,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    completed: PropTypes.bool,
    createdAt: PropTypes.string,
    description: PropTypes.string,
    goal: PropTypes.string,
    handleClickChangeDate: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickMarkAsCompleted: PropTypes.func,
    hasExpired: PropTypes.bool,
    id: PropTypes.string,
    project: PropTypes.object,
    remember: PropTypes.bool,
    trackingDate: PropTypes.string,
    trackingType: PropTypes.number,
    users: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    profileValues: profileSelectors.getCurrentValues,
});

export default connect(mapStateToProps, null)(TrackingItem);
