import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Alert,
    AlertTitle,
    Button,
    Grid,
    Typography,
    UiDialog as Dialog,
} from 'sunwise-ui';

import { ReactHookFormRadioButtonGroup } from 'common/components/form/bootstrap';
import { getIsMexicanAccount } from 'common/utils/helpers/session';

const SavedByModal = ({
    control,
    getValues,
    handleClickSave,
    handleSubmit,
    isOpenModal,
    setValue,
    toggleModal,
}) => {
    const { t } = useTranslation();
    const closeModal = () => toggleModal(false);
    const handleSave = (values) => {
        handleClickSave(values, setValue);
        closeModal();
    };
    const savedBy = getValues('saved_by');
    const sxButton = {
        order: { md: 1, xs: 2 },
        width: { md: 'auto', xs: '100%' },
    };
    const isMexicanAccount = getIsMexicanAccount();
    const getKeepLabel = () => {
        if (isMexicanAccount)
            return t(
                'Apply this configuration to municipalities that do not have their own irradiation',
            );
        return t(
            'Apply this configuration to political divisions of order 2 that do not have their own irradiation',
        );
    };
    const getDeleteLabel = () => {
        if (isMexicanAccount && savedBy === 'country')
            return t(
                'Overwrite data and apply to all municipalities in the country',
            );
        if (isMexicanAccount)
            return t(
                'Overwrite data and apply to all municipalities in the state',
            );
        if (savedBy === 'country')
            return t(
                'Overwrite data and apply to all political divisions of order 2 of the country',
            );
        else
            return t(
                'Overwrite data and apply to all political divisions of order 2 of the political division of order 1',
            );
    };

    return (
        <Dialog size="md" open={isOpenModal} onClose={closeModal}>
            <Alert
                severity="error"
                sx={{ '.MuiAlert-message': { fontWeight: 500 } }}
            >
                <AlertTitle>{t('Warning')}</AlertTitle>
                {isMexicanAccount
                    ? t('The selected state already has municipal irradiations')
                    : t(
                          'The political division of order 1 selected already has its own irradiations',
                      )}
                <Typography variant="body2" fontWeight="500">
                    {t('What do you want to do?')}
                </Typography>
            </Alert>

            <ReactHookFormRadioButtonGroup
                control={control}
                items={[
                    {
                        label: getKeepLabel(),
                        value: 'keep',
                    },
                    {
                        label: getDeleteLabel(),
                        value: 'delete',
                    },
                ]}
                name="keep_or_delete"
            />

            <Grid container>
                <Grid
                    size={{ xs: 'grow' }}
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: { xs: 'center', md: 'right' },
                        gap: 1,
                    }}
                    textAlign="right"
                >
                    <Button
                        color="secondary"
                        onClick={closeModal}
                        sx={sxButton}
                        type="button"
                        variant="text"
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        onClick={handleSubmit(handleSave)}
                        sx={sxButton}
                        type="button"
                        variant="outlined"
                    >
                        {t('Save')}
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};

SavedByModal.propTypes = {
    control: PropTypes.object,
    getValues: PropTypes.func,
    handleClickSave: PropTypes.func,
    handleSubmit: PropTypes.func,
    isOpenModal: PropTypes.bool,
    setValue: PropTypes.func,
    toggleModal: PropTypes.func,
};

export default SavedByModal;
