import React, { useState } from 'react';

import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import { SUBSCRIPTION_STATUS } from 'common/constants';

import AfterSalesIcon from 'resources/aftersales.svg?react';
import BundleIcon from 'resources/IsoBundle.svg?react';
import SalesIcon from 'resources/sales.svg?react';

import { formatPeriodDate, getProductNameText } from '../helpers';

import UsageSubTable from './UsageSubTable';

const UsageSubscriptionDetails = ({ subscription, index }) => {
    const { t } = useTranslation();
    const [openStates, setOpenStates] = useState({});

    const toggleOpen = (idx) => (newState) => {
        setOpenStates((prevState) => ({
            ...prevState,
            [idx]: typeof newState === 'object' ? newState : !prevState[idx],
        }));
    };

    if (isEmpty(subscription)) return null;

    const {
        current_term_end,
        current_term_start,
        item_price_id,
        product_type,
        status,
    } = subscription || {};
    const subscriptionStatus = get(status, 'key', null);

    const renderIcon = () => {
        switch (product_type) {
            case 'aftersales':
                return <AfterSalesIcon />;
            case 'bundle':
                return <BundleIcon />;
            default:
                return <SalesIcon />;
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            key={`${item_price_id}-${index}`}
            mb={2}
        >
            <Box
                alignItems="center"
                display="flex"
                gap={1}
                sx={{ svg: { height: 30, width: 30 } }}
            >
                {renderIcon(product_type)}
                <Typography variant="h5">Sunwise</Typography>
                <Typography
                    component="span"
                    sx={{ color: 'primary.main' }}
                    variant="h5"
                >
                    {getProductNameText(product_type)}
                </Typography>
            </Box>
            <Typography variant="body2" ml={2} mt={2}>
                {t('Period')}: {formatPeriodDate(current_term_start)} -{' '}
                {formatPeriodDate(current_term_end)}
            </Typography>
            {subscriptionStatus !== SUBSCRIPTION_STATUS.CANCELLED && (
                <UsageSubTable
                    subscription={subscription}
                    isOpen={
                        openStates[index] ||
                        (product_type === 'bundle'
                            ? { proposals: false, reports: false }
                            : false)
                    }
                    toggleOpen={toggleOpen(index)}
                />
            )}
        </Box>
    );
};

UsageSubscriptionDetails.propTypes = {
    subscription: PropTypes.object,
    index: PropTypes.number,
};

export default UsageSubscriptionDetails;
