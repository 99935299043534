import {
    INITIALIZE,
    INITIALIZE_FAILURE,
    INITIALIZE_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

import fetchAgents from './fetchAgents';
import fetchContactTypes from './fetchContactTypes';
import filterContacts from './filterContacts';

export default (filterData) => (dispatch) => {
    dispatch(actions[INITIALIZE]());
    Promise.all([
        dispatch(fetchAgents()),
        dispatch(fetchContactTypes()),
        dispatch(filterContacts({ ...filterData, isInitializing: true })),
    ])
        .then(() => dispatch(actions[INITIALIZE_SUCCESS]()))
        .catch(() => dispatch(actions[INITIALIZE_FAILURE]()));
};
