import React from 'react';

import ArticleIcon from '@mui/icons-material/Article';
import { useTheme } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { types } from 'sunwise-template-core';
import { List, ListItem, ListItemButton, ListItemText } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import SideMenuCard from './SideMenuCard';

const ProposalCard = ({
    financing,
    getPermissionsByCode,
    handleClickDocumentItem,
    handleClickEditButton,
    handleOnPrepareConverToTemplate,
    handleOnPrepareRegenerateTemplate,
    isApproved,
    isDisabled,
    isReadOnly,
    proposalId,
    proposalName,
    selectedDocumentId,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    const editProposalReviewPermission = getPermissionsByCode(
        PERMISSION_LIST.EDIT_PROPOSAL_PREVIEW_PERMISSION,
    );
    const proposalTemplatesPermission = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_TEMPLATES_PERMISSION,
    );

    const getTitle = () => {
        if (!isEmpty(financing)) return `${t('Proposal')} - EPC`;
        return t('Proposal');
    };

    return (
        <SideMenuCard
            icon={<ArticleIcon sx={{ color: 'primary.main' }} />}
            title={getTitle()}
        >
            <List disablePadding>
                <ListItem
                    disabled={isDisabled || proposalId === selectedDocumentId}
                    disablePadding
                    secondaryAction={
                        <DropdownCardMenu
                            items={[
                                {
                                    disabled:
                                        !editProposalReviewPermission.canModify,
                                    handleClick: () => handleClickEditButton(),
                                    label: t('Edit'),
                                },
                                {
                                    disabled:
                                        !editProposalReviewPermission.canModify,
                                    handleClick: () =>
                                        handleOnPrepareRegenerateTemplate(),
                                    label: t('Regenerate'),
                                },
                                {
                                    visible:
                                        proposalTemplatesPermission.canModify,
                                    handleClick: () =>
                                        handleOnPrepareConverToTemplate(),
                                    label: t('Convert to template'),
                                },
                            ]}
                            sxIconButton={{
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                            visible={
                                !isApproved &&
                                proposalId === selectedDocumentId &&
                                !isReadOnly
                            }
                        />
                    }
                >
                    <ListItemButton
                        onClick={() =>
                            handleClickDocumentItem({
                                id: proposalId,
                                name: proposalName,
                                type: types.ONE_PROPOSAL_TYPE,
                            })
                        }
                        selected={proposalId === selectedDocumentId}
                        sx={{
                            borderRadius: '8px',
                            backgroundColor:
                                proposalId === selectedDocumentId
                                    ? isDarkMode
                                        ? '#000'
                                        : 'rgba(31, 60, 83, 0.13)'
                                    : 'transparent',
                            '&.Mui-selected': {
                                backgroundColor: isDarkMode
                                    ? '#000'
                                    : 'rgba(31, 60, 83, 0.13) !important',
                            },
                        }}
                    >
                        <ListItemText
                            primary={proposalName}
                            sx={{
                                '.MuiListItemText-primary': {
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                },
                            }}
                        />
                    </ListItemButton>
                </ListItem>
            </List>
        </SideMenuCard>
    );
};

ProposalCard.propTypes = {
    financing: PropTypes.object,
    getPermissionsByCode: PropTypes.func,
    handleClickDocumentItem: PropTypes.func,
    handleClickEditButton: PropTypes.func,
    handleOnPrepareConverToTemplate: PropTypes.func,
    handleOnPrepareRegenerateTemplate: PropTypes.func,
    isApproved: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    proposalId: PropTypes.string,
    proposalName: PropTypes.string,
    selectedDocumentId: PropTypes.string,
};

export default compose(
    withPermissions([
        PERMISSION_LIST.EDIT_PROPOSAL_PREVIEW_PERMISSION,
        PERMISSION_LIST.PROPOSAL_TEMPLATES_PERMISSION,
    ]),
)(ProposalCard);
