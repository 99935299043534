import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Typography } from 'sunwise-ui';

const RateFormatter = ({ row }) => {
    return (
        <Typography variant="caption">
            {get(row, 'branch_office.name', '')}
        </Typography>
    );
};

RateFormatter.propTypes = {
    row: PropTypes.object,
};

export default RateFormatter;
