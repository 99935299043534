import API from '../..';
import sunaplAPI from '../../sunpalApi';
const ENTITY = 'proposals';

export const filterProposals = ({
    createdBy = 'all',
    endDate = '',
    isArchived = 'False',
    orderBy = 'created_at',
    origin = 'all',
    page = 1,
    perPage = 25,
    rateName = 'all',
    searchText,
    sortBy = null,
    startDate = '',
    status = 'all',
    storageRange = 'all',
    systemRange = 'all',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/`, {
        params: {
            is_archived: isArchived,
            order_by: orderBy,
            page: page,
            per_page: perPage,
            sort: sortBy,
            ...(createdBy !== 'all' ? { created_by: createdBy } : {}),
            ...(endDate ? { end_date: endDate } : {}),
            ...(rateName !== 'all' ? { rate_name: rateName } : {}),
            ...(searchText ? { search: searchText } : {}),
            ...(startDate ? { start_date: startDate } : {}),
            ...(status !== 'all' ? { status } : {}),
            ...(storageRange !== 'all' ? { ...storageRange } : {}),
            ...(systemRange !== 'all' ? { ...systemRange } : {}),
            ...(origin !== 'all' ? { ...{ invoice_id: origin } } : {}),
        },
    });
export const generatePreview = (data) =>
    API.post(`/api/v1/${ENTITY}/generate-preview/`, data);

export const getMonitoringConfigs = (uuid) =>
    sunaplAPI.get(`/api/v1/${ENTITY}/monitoring/configs/${uuid}/`);

export const saveMonitoringConfigs = (id, data) =>
    sunaplAPI.put(`/api/v1/${ENTITY}/monitoring/configs/${id}/`, data);

export const createMonitoringConfigs = (data) =>
    sunaplAPI.post(`/api/v1/${ENTITY}/monitoring/configs/`, data);
