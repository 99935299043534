import React, { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';

import MonkeyNoSee from 'resources/monkey-no-see.svg?react';
import Monkey from 'resources/monkey.svg?react';

import * as projectCommercialOfferTableActions from '../../projectCommercialOfferTable/actions';
import * as actions from '../actions';

const SupportControlDropdown = ({
    canModify,
    customerMode,
    financialProductParameters,
    handleClickDuplicate,
    handleClickEdit,
    handleClickOpenConfigurationModal,
    hasSmartDocuments,
    isApproved,
    isClosed,
    isGenerated,
    isReadOnly,
    offerDetails,
    offerId,
    setCustomerMode,
}) => {
    const { t } = useTranslation();
    const [count, setCount] = useState(0);
    const financierProduct = get(
        financialProductParameters,
        'financier_product',
        {},
    );
    const hasFinancingRequest = get(
        offerDetails,
        'has_financing_request',
        false,
    );

    const handleClickEditButton = () =>
        handleClickEdit(
            financierProduct,
            hasFinancingRequest,
            hasSmartDocuments,
            isApproved,
            isGenerated,
            offerId,
        );

    const handleClickCustomerMode = () => setCustomerMode(!customerMode);

    const handleClickDuplicateButton = () =>
        handleClickDuplicate(
            get(offerDetails, 'name', null),
            get(offerDetails, 'commercial_offer_parent', null),
        );

    const countClick = () => setCount((count) => count + 1);

    const getCustomerModeIcon = () => {
        if (customerMode && count > 10)
            return <MonkeyNoSee width="20" height="20" />;
        if (customerMode) return <VisibilityOffIcon fontSize="small" />;
        if (count > 10) return <Monkey width="20" height="20" />;
        return <VisibilityIcon fontSize="small" />;
    };

    return (
        <DropdownCardMenu
            items={[
                {
                    disabled: isClosed || !canModify,
                    handleClick: () => handleClickEditButton(),
                    icon: <EditIcon fontSize="small" />,
                    label: t('Edit'),
                    visible: isGenerated && !isReadOnly,
                },
                {
                    handleClick: () => {
                        handleClickCustomerMode();
                        countClick();
                    },
                    icon: getCustomerModeIcon(),
                    label: t('Customer mode'),
                },
                {
                    disabled: !canModify,
                    handleClick: () => handleClickDuplicateButton(),
                    icon: <FileCopyIcon fontSize="small" />,
                    label: t('Duplicate'),
                    visible: !isClosed,
                },
                {
                    handleClick: () => handleClickOpenConfigurationModal(true),
                    icon: <SettingsIcon fontSize="small" />,
                    label: t('Setting'),
                },
            ]}
            sxIconButton={{ ml: 'auto' }}
        />
    );
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
    handleClickDuplicate: (proposalName, proposalId) =>
        dispatch(
            projectCommercialOfferTableActions.prepareCommercialOfferDuplicate(
                proposalName,
                proposalId,
            ),
        ),
    handleClickEdit: (
        financierProduct,
        hasFinancingRequest,
        hasSmartDocuments,
        isApproved,
        isGenerated,
        offerId,
    ) =>
        dispatch(
            actions.prepareEdit(
                financierProduct,
                hasFinancingRequest,
                hasSmartDocuments,
                isApproved,
                isGenerated,
                offerId,
            ),
        ),
});

SupportControlDropdown.propTypes = {
    canModify: PropTypes.bool,
    customerMode: PropTypes.bool,
    financialProductParameters: PropTypes.object,
    handleClickDuplicate: PropTypes.func,
    handleClickEdit: PropTypes.func,
    handleClickOpenConfigurationModal: PropTypes.func,
    hasSmartDocuments: PropTypes.bool,
    isApproved: PropTypes.bool,
    isClosed: PropTypes.bool,
    isGenerated: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    offerDetails: PropTypes.object,
    offerId: PropTypes.string,
    setCustomerMode: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SupportControlDropdown);
