import { NAME } from './constants';

export const FETCH_CONTACTS = `${NAME}/FETCH_CONTACTS`;
export const FETCH_CONTACTS_FAILURE = `${NAME}/FETCH_CONTACTS_FAILURE`;
export const FETCH_CONTACTS_SUCCESS = `${NAME}/FETCH_CONTACTS_SUCCESS`;

export const FETCH_PROJECTS = `${NAME}/FETCH_PROJECTS`;
export const FETCH_PROJECTS_FAILURE = `${NAME}/FETCH_PROJECTS_FAILURE`;
export const FETCH_PROJECTS_SUCCESS = `${NAME}/FETCH_PROJECTS_SUCCESS`;

export const FETCH_PROPOSALS = `${NAME}/FETCH_PROPOSALS`;
export const FETCH_PROPOSALS_FAILURE = `${NAME}/FETCH_PROPOSALS_FAILURE`;
export const FETCH_PROPOSALS_SUCCESS = `${NAME}/FETCH_PROPOSALS_SUCCESS`;

export const FETCH_REPORTS = `${NAME}/FETCH_REPORTS`;
export const FETCH_REPORTS_FAILURE = `${NAME}/FETCH_REPORTS_FAILURE`;
export const FETCH_REPORTS_SUCCESS = `${NAME}/FETCH_REPORTS_SUCCESS`;

export const FETCH_SUMMARIES = `${NAME}/FETCH_SUMMARIES`;
export const FETCH_SUMMARIES_FAILURE = `${NAME}/FETCH_SUMMARIES_FAILURE`;
export const FETCH_SUMMARIES_SUCCESS = `${NAME}/FETCH_SUMMARIES_SUCCESS`;

export const SET_RECENT_SEARCHES = `${NAME}/SET_RECENT_SEARCHES`;
