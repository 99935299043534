import React, { useEffect, useState } from 'react';

import AddchartIcon from '@mui/icons-material/Addchart';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid, Typography } from 'sunwise-ui';

import AccordionCard from 'common/components/accordions/AccordionCard';
import ChartJs from 'common/components/ChartJs';

import {
    getChartSettings,
    getChartsInfo,
    getPaymentChartSettings,
} from '../helpers';
import * as selectors from '../selectors';

const CHART_HEIGHT = 250;

const ComparativeCard = ({
    currencyIso,
    currencyLocale,
    currencySymbol,
    historicalInfo,
    isLoading,
    periodConfig,
    proposalInfo,
    proposalInfoIsBimonthly,
}) => {
    const [chartsInfo, setChartsInfo] = useState({
        categories: [],
        consumptionSeries: [],
        energySettings: {},
        generationSeries: [],
        paymentCategories: [],
        paymentSeries: [],
        paymentSettings: {},
    });
    const { t } = useTranslation();

    useEffect(() => {
        if (!periodConfig) return;

        const {
            categories,
            consumptionSeries,
            energyDates,
            generationSeries,
            paymentPeriods,
            paymentSeries,
        } = getChartsInfo({
            historicalInfo,
            periodGroups: periodConfig?.groups || [],
            proposalInfoIsBimonthly,
            proposalPeriods: proposalInfo?.consumption_history || [],
        });

        const energySettings = getChartSettings({
            currencyIso,
            currencyLocale,
            currencySymbol,
            energyDates,
        });

        const paymentConfig = getPaymentChartSettings({
            categories,
            currencyIso,
            currencyLocale,
            paymentPeriods,
            paymentSeries,
        });

        setChartsInfo({
            categories,
            consumptionSeries,
            energySettings,
            generationSeries,
            paymentSeries: paymentConfig.series,
            paymentSettings: paymentConfig.settings,
            paymentCategories: paymentConfig.categories,
        });
    }, [historicalInfo, proposalInfo, periodConfig]);

    return (
        <AccordionCard
            defaultExpanded
            isLoading={isLoading}
            svgIcon={<AddchartIcon color="primary" />}
            title={t('Comparative')}
        >
            <Grid container>
                <Grid size={{ md: 9, xs: 18 }}>
                    <Typography variant="subtitle2">
                        {t('Total consumption')}
                    </Typography>

                    <ChartJs
                        data={{
                            datasets: chartsInfo.consumptionSeries,
                            labels: chartsInfo.categories || [],
                        }}
                        height={`${CHART_HEIGHT}px`}
                        options={chartsInfo.energySettings}
                    />
                </Grid>
                <Grid size={{ md: 9, xs: 18 }}>
                    <Typography variant="subtitle2">
                        {t('Total generation')}
                    </Typography>

                    <ChartJs
                        data={{
                            datasets: chartsInfo.generationSeries,
                            labels: chartsInfo.categories || [],
                        }}
                        height={`${CHART_HEIGHT}px`}
                        options={chartsInfo.energySettings}
                    />
                </Grid>
                <Grid size={18}>
                    <Typography variant="subtitle2">
                        {t('Total cost')}
                    </Typography>

                    <ChartJs
                        data={{
                            datasets: chartsInfo.paymentSeries,
                            labels: chartsInfo.paymentCategories || [],
                        }}
                        height={`${CHART_HEIGHT}px`}
                        options={chartsInfo.paymentSettings}
                    />
                </Grid>
            </Grid>
        </AccordionCard>
    );
};
const mapStateToProps = createStructuredSelector({
    historicalInfo: selectors.getHistoricalInfoData,
    periodConfig: selectors.getPeriodConfigData,
    proposalInfo: selectors.getProjectConfigurationProposalData,
    proposalInfoIsBimonthly: selectors.getProposalInfoIsBimonthly,
});

ComparativeCard.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    historicalInfo: PropTypes.array,
    isLoading: PropTypes.bool,
    periodConfig: PropTypes.object,
    proposalInfo: PropTypes.object,
    proposalInfoIsBimonthly: PropTypes.bool,
};

export default connect(mapStateToProps)(ComparativeCard);
