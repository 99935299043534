import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography } from 'sunwise-ui';

const ProjectFormatter = ({ row }) => {
    const { contact = {}, id, name } = get(row, 'project', {});
    return (
        <Link
            color="inherit"
            component={RouterLink}
            to={`/record/${contact}?tab=projects&project=${id}&view=proposals&type=summaries`}
            underline="none"
        >
            <Typography fontWeight={600} variant="caption">
                {name}
            </Typography>
        </Link>
    );
};

ProjectFormatter.propTypes = {
    row: PropTypes.object,
};

export default ProjectFormatter;
