import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    Button,
    Card,
    Container as MaterialContainer,
    Grid,
    Typography,
    UiDialog as Dialog,
} from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import * as customColumnsSelectors from 'common/modules/customColumns/selectors';
import RestrictedAccessLanding from 'common/modules/restrictedAccessLanding/Container';

import * as actions from './actions';
import { ListItems, ProductForm } from './components';
import * as selectors from './selectors';

const Container = ({
    branchSelected,
    filterItems,
    getPermissionsByCode,
    handleClickBulkItems,
    handleClickCloseModalProduct,
    handleClickCreate,
    handleClickUpdate,
    handleSaveRow,
    isFetching,
    isFetchingTableSettings,
    isNewRecord,
    isOpenModalProduct,
    items,
    paginationItems,
    prepareDuplicate,
    tableSettings,
}) => {
    const { t } = useTranslation();
    const [filterData, setFilterData] = useState({
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        searchText: '',
        sortBy: 'desc',
        status: 'active',
    });
    const {
        canDelete: canDeletePlan,
        canModify: canModifyPlan,
        canView: canViewPlans,
    } = getPermissionsByCode(PERMISSION_LIST.PLANS_PERMISSION);
    const { canView: canModifyEnergyBackup } = getPermissionsByCode(
        PERMISSION_LIST.ENERGY_BACKUP_PERMISSION,
    );

    useEffect(() => filterItems(filterData), []);

    const handleDuplicate = (item) => prepareDuplicate(item, filterData);

    return (
        <RestrictedAccessLanding
            addonName="Plans"
            url="https://somos.sunwise.io/sunwise-sales"
        >
            <MaterialContainer maxWidth={false}>
                <HeaderContainer>
                    <Grid size={{ xs: 'grow' }}>
                        <TitleIcon title={t('Plan', { count: 2 })} />
                    </Grid>
                </HeaderContainer>

                <Card>
                    <Card.Header>
                        <Grid container>
                            <Grid size={{ md: 'grow', xs: 18 }}>
                                <TitleIcon
                                    fontWeight="bold"
                                    title={t('Plans configured')}
                                    variant="subtitle"
                                />
                                <Typography variant="body2">
                                    {t(
                                        'Defines predetermined photovoltaic equipment according to the type of installation',
                                    )}
                                </Typography>
                            </Grid>

                            <Grid
                                size={{ md: 'grow', xs: 18 }}
                                sx={{ textAlign: 'right' }}
                            >
                                <Button
                                    onClick={() =>
                                        handleClickCreate(branchSelected)
                                    }
                                    sx={{ width: { md: 'auto', xs: '100%' } }}
                                    variant="outlined"
                                    visible={canModifyPlan}
                                >
                                    {t('Add plan')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Card.Header>

                    {canViewPlans && (
                        <Card.Body>
                            <ListItems
                                canDelete={canDeletePlan}
                                canModify={canModifyPlan}
                                filterData={filterData}
                                filterItems={filterItems}
                                handleClickBulkItems={handleClickBulkItems}
                                handleClickUpdate={handleClickUpdate}
                                handleSaveRow={handleSaveRow}
                                isFetching={
                                    isFetching || isFetchingTableSettings
                                }
                                items={items}
                                paginationData={paginationItems}
                                prepareDuplicate={handleDuplicate}
                                setFilterData={setFilterData}
                                tableSettings={tableSettings}
                            />
                        </Card.Body>
                    )}
                </Card>

                <Dialog
                    onClose={() => handleClickCloseModalProduct()}
                    open={isOpenModalProduct}
                    title={`${isNewRecord ? t('Create') : t('Update')} ${t(
                        'Plan',
                    ).toLowerCase()}`}
                >
                    <ProductForm
                        branchSelected={branchSelected}
                        canModify={canModifyPlan}
                        canModifyEnergyBackup={canModifyEnergyBackup}
                        filterData={filterData}
                        handleClickCloseForm={handleClickCloseModalProduct}
                    />
                </Dialog>
            </MaterialContainer>
        </RestrictedAccessLanding>
    );
};

const mapStateToProps = createStructuredSelector({
    isFetching: selectors.getIsFetchingItems,
    isFetchingTableSettings: customColumnsSelectors.getIsFetchingTableSettings,
    isNewRecord: selectors.getIsNewRecord,
    isOpenModalProduct: selectors.getIsOpenModalProduct,
    items: selectors.getFetchItemsData,
    paginationItems: selectors.getFetchItemsPagination,
    tableSettings: customColumnsSelectors.getTableSettingsData,
});

const mapDispatchToProps = (dispatch) => ({
    filterItems: (filterData) => dispatch(actions.filterItems(filterData)),
    handleClickBulkItems: (ids, value, filterData, callback) =>
        dispatch(actions.prepareBulkItems(ids, value, filterData, callback)),
    handleClickCloseModalProduct: () => dispatch(actions.closeModalProduct()),
    handleClickCreate: (branchSelected) =>
        dispatch(actions.prepareCreate(branchSelected)),
    handleClickUpdate: (id, disabled) =>
        dispatch(actions.fetchPlan(id, disabled)),
    handleSaveRow: (item) => dispatch(actions.updatePlan(item)),
    prepareDuplicate: (item, filter) =>
        dispatch(actions.prepareDuplicate(item, filter)),
});

Container.propTypes = {
    branchSelected: PropTypes.string,
    filterItems: PropTypes.func,
    getPermissionsByCode: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickCloseModalProduct: PropTypes.func,
    handleClickCreate: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    handleSaveRow: PropTypes.func,
    isFetching: PropTypes.bool,
    isFetchingTableSettings: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isOpenModalProduct: PropTypes.bool,
    items: PropTypes.array,
    paginationItems: PropTypes.object,
    prepareDuplicate: PropTypes.func,
    tableSettings: PropTypes.array,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions([
        PERMISSION_LIST.ENERGY_BACKUP_PERMISSION,
        PERMISSION_LIST.PLANS_PERMISSION,
    ]),
)(Container);
