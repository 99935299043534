import React from 'react';

import PropTypes from 'prop-types';
import { Grid, Skeleton } from 'sunwise-ui';

import PricingDetails from './PricingDetails';

const PricingList = ({
    flluidView = false,
    fromLanding = false,
    handleCheckout,
    isLoading,
    noSubscriptions,
    pricings,
    selectedProductId,
    subscription,
}) => {
    if (isLoading) {
        return (
            <Grid container justifyContent="center" mt={3}>
                <Grid size={{ lg: flluidView ? 8 : 6, md: 9, xs: 18 }}>
                    <Skeleton height={350} variant="rectangle" />
                </Grid>
                <Grid size={{ lg: flluidView ? 8 : 6, md: 9, xs: 18 }}>
                    <Skeleton height={350} variant="rectangle" />
                </Grid>
            </Grid>
        );
    }
    return (
        <Grid container justifyContent="center" mt={3}>
            {pricings.map((pricing, index) => (
                <Grid
                    key={`pricing-${index}`}
                    size={{ lg: flluidView ? 8 : 6, md: 9, xs: 18 }}
                >
                    <PricingDetails
                        fromLanding={fromLanding}
                        handleCheckout={handleCheckout}
                        noSubscriptions={noSubscriptions}
                        pricing={pricing}
                        selectedProductId={selectedProductId}
                        subscription={subscription}
                        variant={index % 2 == 0 ? 'primary' : 'secondary'}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

PricingList.propTypes = {
    flluidView: PropTypes.bool,
    fromLanding: PropTypes.bool,
    handleCheckout: PropTypes.func,
    isLoading: PropTypes.bool,
    noSubscriptions: PropTypes.bool,
    pricings: PropTypes.array,
    selectedProductId: PropTypes.string,
    subscription: PropTypes.object,
};

export default PricingList;
