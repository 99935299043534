import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Box, Button } from 'sunwise-ui';

import * as afterSalesSettingsActions from '../../afterSalesSettings/actions';

const CreateShareCard = ({ createInteractiveLink, selectedProjectId }) => {
    const { t } = useTranslation();
    const onClick = () =>
        createInteractiveLink({ project_id: selectedProjectId });

    return (
        <Box textAlign="center">
            <Button onClick={onClick} variant="outlined">
                {t('Create interactive link')}
            </Button>
        </Box>
    );
};

CreateShareCard.propTypes = {
    createInteractiveLink: PropTypes.func,
    selectedProjectId: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
    createInteractiveLink: (data) =>
        dispatch(afterSalesSettingsActions.createInteractiveLink(data)),
});

export default connect(null, mapDispatchToProps)(CreateShareCard);
