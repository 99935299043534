import { NAME } from './constants';

export const ADD_ZAP_RPU = `${NAME}/ADD_ZAP_RPU`;
export const ADD_ZAP_RPU_FAILURE = `${NAME}/ADD_ZAP_RPU_FAILURE`;
export const ADD_ZAP_RPU_SUCCESS = `${NAME}/ADD_ZAP_RPU_SUCCESS`;

export const FETCH_CONSUMPTION_PROFILE = `${NAME}/FETCH_CONSUMPTION_PROFILE`;
export const FETCH_CONSUMPTION_PROFILE_FAILURE = `${NAME}/FETCH_CONSUMPTION_PROFILE_FAILURE`;
export const FETCH_CONSUMPTION_PROFILE_SUCCESS = `${NAME}/FETCH_CONSUMPTION_PROFILE_SUCCESS`;

export const FETCH_CONSUMPTION_PROFILES = `${NAME}/FETCH_CONSUMPTION_PROFILES`;
export const FETCH_CONSUMPTION_PROFILES_FAILURE = `${NAME}/FETCH_CONSUMPTION_PROFILES_FAILURE`;
export const FETCH_CONSUMPTION_PROFILES_SUCCESS = `${NAME}/FETCH_CONSUMPTION_PROFILES_SUCCESS`;

export const FETCH_CUPS_INFO = `${NAME}/FETCH_CUPS_INFO`;
export const FETCH_CUPS_INFO_FAILURE = `${NAME}/FETCH_CUPS_INFO_FAILURE`;
export const FETCH_CUPS_INFO_SUCCESS = `${NAME}/FETCH_CUPS_INFO_SUCCESS`;

export const FETCH_DIVISIONS = `${NAME}/FETCH_DIVISIONS`;
export const FETCH_DIVISIONS_FAILURE = `${NAME}/FETCH_DIVISIONS_FAILURE`;
export const FETCH_DIVISIONS_SUCCESS = `${NAME}/FETCH_DIVISIONS_SUCCESS`;

export const FETCH_LISA_FILES = `${NAME}/FETCH_LISA_FILES`;
export const FETCH_LISA_FILES_FAILURE = `${NAME}/FETCH_LISA_FILES_FAILURE`;
export const FETCH_LISA_FILES_SUCCESS = `${NAME}/FETCH_LISA_FILES_SUCCESS`;

export const FETCH_LOCATIONS_ZIP_CODE = `${NAME}/FETCH_LOCATIONS_ZIP_CODE`;
export const FETCH_LOCATIONS_ZIP_CODE_FAILURE = `${NAME}/FETCH_LOCATIONS_ZIP_CODE_FAILURE`;
export const FETCH_LOCATIONS_ZIP_CODE_SUCCESS = `${NAME}/FETCH_LOCATIONS_ZIP_CODE_SUCCESS`;

export const FETCH_POLITICAL_DIVISIONS = `${NAME}/FETCH_POLITICAL_DIVISIONS`;
export const FETCH_POLITICAL_DIVISIONS_FAILURE = `${NAME}/FETCH_POLITICAL_DIVISIONS_FAILURE`;
export const FETCH_POLITICAL_DIVISIONS_SUCCESS = `${NAME}/FETCH_POLITICAL_DIVISIONS_SUCCESS`;

export const FETCH_POLITICAL_DIVISIONS2 = `${NAME}/FETCH_POLITICAL_DIVISIONS2`;
export const FETCH_POLITICAL_DIVISIONS2_SUCCESS = `${NAME}/FETCH_POLITICAL_DIVISIONS2_SUCCESS`;
export const FETCH_POLITICAL_DIVISIONS2_FAILURE = `${NAME}/FETCH_POLITICAL_DIVISIONS2_FAILURE`;

export const FETCH_PROFILE_CONSUMPTION_SUCCESS = `${NAME}/FETCH_PROFILE_CONSUMPTION_SUCCESS`;

export const FETCH_RATES = `${NAME}/FETCH_RATES`;
export const FETCH_RATES_FAILURE = `${NAME}/FETCH_RATES_FAILURE`;
export const FETCH_RATES_SUCCESS = `${NAME}/FETCH_RATES_SUCCESS`;

export const FETCH_REGIONS = `${NAME}/FETCH_REGIONS`;
export const FETCH_REGIONS_FAILURE = `${NAME}/FETCH_REGIONS_FAILURE`;
export const FETCH_REGIONS_SUCCESS = `${NAME}/FETCH_REGIONS_SUCCESS`;

export const FETCH_SCHEDULE_RATE_CONFIGURATION = `${NAME}/FETCH_SCHEDULE_RATE_CONFIGURATION`;
export const FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE = `${NAME}/FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE`;
export const FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS = `${NAME}/FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS`;

export const FETCH_SUMMER_MONTHS = `${NAME}/FETCH_SUMMER_MONTHS`;
export const FETCH_SUMMER_MONTHS_FAILURE = `${NAME}/FETCH_SUMMER_MONTHS_FAILURE`;
export const FETCH_SUMMER_MONTHS_SUCCESS = `${NAME}/FETCH_SUMMER_MONTHS_SUCCESS`;

export const FETCH_ZAP_DATA = `${NAME}/FETCH_ZAP_DATA`;
export const FETCH_ZAP_DATA_FAILURE = `${NAME}/FETCH_ZAP_DATA_FAILURE`;
export const FETCH_ZAP_DATA_SUCCESS = `${NAME}/FETCH_ZAP_DATA_SUCCESS`;

export const INITIALIZE_VALUES = `${NAME}/INITIALIZE_VALUES`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const RESET_PDF_FILE = `${NAME}/RESET_PDF_FILE`;

export const SAVE_CONSUMPTIONS = `${NAME}/SAVE_CONSUMPTIONS`;
export const SAVE_CONSUMPTIONS_FAILURE = `${NAME}/SAVE_CONSUMPTIONS_FAILURE`;
export const SAVE_CONSUMPTIONS_SUCCESS = `${NAME}/SAVE_CONSUMPTIONS_SUCCESS`;

export const SELECT_PROJECT = `${NAME}/SELECT_PROJECT`;

export const SET_AUTOMATIC_HAS_CHANGES = `${NAME}/SET_AUTOMATIC_HAS_CHANGES`;

export const SET_CONSUMPTION_PROFILE_CSV_DATA = `${NAME}/SET_CONSUMPTION_PROFILE_CSV_DATA`;

export const SET_CONSUMPTION_WITH_CSV_DATA_CALCULATED = `${NAME}/SET_SCHEDULE_DISTRIBUTION_CALCULATED`;

export const SET_CONSUMPTIONS_CAPTURE_MODE = `${NAME}/SET_CONSUMPTIONS_CAPTURE_MODE`;

export const SET_CONSUMPTIONS_RAW_DATA = `${NAME}/SET_CONSUMPTIONS_RAW_DATA`;

export const SET_HAS_MORE_DAYS_THAN_ALLOWED = `${NAME}/SET_HAS_MORE_DAYS_THAN_ALLOWED`;

export const SET_IS_DISABLED = `${NAME}/SET_IS_DISABLED`;

export const SET_IS_FROM_CREATE = `${NAME}/SET_IS_FROM_CREATE`;

export const SET_CONFIG_CSV_IMPORT_MODAL = `${NAME}/SET_CONFIG_CSV_IMPORT_MODAL`;

export const SET_IS_OPEN_MISSING_FIELDS_MODAL = `${NAME}/SET_IS_OPEN_MISSING_FIELDS_MODAL`;

export const SET_MODAL_IS_OPEN = `${NAME}/SET_MODAL_IS_OPEN`;

export const SET_UPLOAD_FILE_ORIGIN = `${NAME}/UPLOAD_FILE_ORIGIN`;

export const SET_ZAP_LOADING = `${NAME}/SET_ZAP_LOADING`;

export const UPLOAD_FILE = `${NAME}/UPLOAD_FILE`;
export const UPLOAD_FILE_FAILURE = `${NAME}/UPLOAD_FILE_FAILURE`;
export const UPLOAD_FILE_SUCCESS = `${NAME}/UPLOAD_FILE_SUCCESS`;
