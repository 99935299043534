import { projectsProjectsGetProject } from '@sunwise-clients/wise';

import * as lisaFilesActions from 'common/modules/lisaFiles/actions';
import * as lisaFilesSelectors from 'common/modules/lisaFiles/selectors';

import {
    FETCH_LISA_FILES,
    FETCH_LISA_FILES_FAILURE,
    FETCH_LISA_FILES_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

import handleLisaResponse from './handleLisaResponse';

export default ({ getValues, project }) =>
    (dispatch, getState) => {
        dispatch(actions[FETCH_LISA_FILES]());
        dispatch(lisaFilesActions.disconnectOCR());

        projectsProjectsGetProject({
            path: { project_id: project.lisa_project },
        })
            .then((response) => {
                const rawData = response?.data?.documents;

                const filesUploaded =
                    lisaFilesSelectors.getFilesSocketOcr(getState());

                const fileToGet = Object.values(filesUploaded)?.[0];
                const fileInfo = rawData?.find(
                    (file) => file.id === fileToGet?.document_id,
                );

                dispatch(handleLisaResponse({ fileInfo, getValues }));

                dispatch(actions[FETCH_LISA_FILES_SUCCESS](fileInfo || {}));
                dispatch(lisaFilesActions.setShowLisaLoading(false));
            })
            .catch((error) => {
                dispatch(
                    actions[FETCH_LISA_FILES_FAILURE](
                        error?.response?.data?.detail,
                    ),
                );
                dispatch(lisaFilesActions.setShowLisaLoading(false));
            });
    };
