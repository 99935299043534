import React from 'react';

import DashboardIcon from '@mui/icons-material/Dashboard';
import TableChartIcon from '@mui/icons-material/TableChart';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import { CheckAccess } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import { useBreakpoint } from 'common/hooks';
import {
    getSubscriptionStatus,
    getSubscriptionsStatus,
} from 'common/utils/helpers';

import * as profileSelectors from '../../../modules/profile/selectors';

import { handleOnMouseHover } from './helpers';
import StyledListItemButton from './StyledListItemButton';
import StyledStyledListItemText from './StyledStyledListItemText';
import UpgradeIcon from './UpgradeIcon';

const AfterSalesOptions = ({
    handleClose,
    handleDrawerToggle,
    match,
    mobileOpen,
    redirect,
    subscription,
    subscriptions,
    subscriptionVersion,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const { allHasPaymentDue, isMultipleSubscription } =
        getSubscriptionsStatus(subscriptions);
    const { isActive, isPaymentDue } = getSubscriptionStatus(subscription);

    const isDisabledV4 =
        subscriptionVersion === 'v4' && isActive && isPaymentDue;
    const isDisabledV5 =
        (subscriptionVersion === 'v5' &&
            !isMultipleSubscription &&
            isActive &&
            isPaymentDue) ||
        (subscriptionVersion === 'v5' &&
            isMultipleSubscription &&
            allHasPaymentDue);

    const onMouseLeave = (e) =>
        handleOnMouseHover({ e, breakpoint, handleClose });

    return (
        <Box onMouseLeave={onMouseLeave}>
            <CheckAccess requiredPermissions={[]}>
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/monitoring-dashboard');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/monitoring-dashboard' === match.url}
                    variant="horizontal"
                >
                    <DashboardIcon />
                    <StyledStyledListItemText
                        primary={t('Monitoring dashboard')}
                    />
                    <UpgradeIcon moduleName="Monitoring-Dashboard,Sunwise-After-Sales" />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[PERMISSION_LIST.FUNNEL_REPORT_PERMISSION]}
            >
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/report-funnel');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/report-funnel' === match.path}
                    variant="horizontal"
                >
                    <TableChartIcon />
                    <StyledStyledListItemText primary={t('Report funnel')} />
                    <UpgradeIcon moduleName="Report-Funnel,Sunwise-After-Sales" />
                </StyledListItemButton>
            </CheckAccess>
        </Box>
    );
};

AfterSalesOptions.propTypes = {
    handleClose: PropTypes.func,
    handleDrawerToggle: PropTypes.func,
    match: PropTypes.object,
    mobileOpen: PropTypes.bool,
    redirect: PropTypes.func,
    subscription: PropTypes.object,
    subscriptions: PropTypes.array,
    subscriptionVersion: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    subscription: profileSelectors.getMainSubscription,
    subscriptions: profileSelectors.getSubscriptions,
    subscriptionVersion: profileSelectors.getSubscriptionVersion,
});

export default connect(mapStateToProps)(AfterSalesOptions);
