import React, { useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Container as MaterialContainer, Button, Grid } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { arraytoDictionary, getCurrencyById } from 'common/utils/helpers';

import * as actions from './actions';
import { CardList, CardListDetail, Modal } from './components';
import { formattedCurrenciesData } from './helpers';
import * as selectors from './selectors';

const Container = ({
    activateItem,
    canDelete,
    canModify,
    filterItems,
    filterItemsParent,
    handleClickCloseModal,
    handleClickCreate,
    isFetchingItemsParent,
    isOpenModal,
    items,
    itemsParent,
    isFetching,
    match,
    paginationItemsParent,
    prepareUpdate,
    prepareSelectParent,
    selectedItem,
    resetForm,
    resetItem,
    searchText,
    searchTextParent,
    unselectParent,
    updateOrder,
}) => {
    const { t } = useTranslation();
    const isoParam = match.params.iso;

    useEffect(() => {
        filterItems(searchText);
        resetItem();
        resetForm();
    }, []);

    useEffect(() => {
        const data = formattedCurrenciesData(items);
        const currencyDictionary = arraytoDictionary(data, 'abbreviation');
        if (currencyDictionary[isoParam])
            prepareUpdate(currencyDictionary[isoParam]);
    }, [isoParam]);

    const handleChangeSwitch = (id) => activateItem(id, items);

    const handleClickCloseModalParent = () => handleClickCloseModal();

    const handleUnselectParent = (id) => {
        const currency = getCurrencyById(items, id);
        if (currency) unselectParent(currency.id);
        return false;
    };

    return (
        <MaterialContainer maxWidth={false}>
            <HeaderContainer>
                <Grid size={{ xs: 'grow' }}>
                    <TitleIcon title={t('Currency', { count: 2 })} />
                </Grid>
            </HeaderContainer>
            <Grid container>
                <Grid size={{ md: 9, xs: 18 }}>
                    <Box sx={{ textAlign: 'right' }}>
                        <Button
                            endIcon={<AddIcon />}
                            onClick={handleClickCreate}
                            sx={{
                                mb: 2,
                                width: { md: 'auto', xs: '100%' },
                            }}
                            variant="outlined"
                            visible={false}
                        >
                            {t('Add currency')}
                        </Button>
                    </Box>

                    <CardList
                        handleChangeSwitch={handleChangeSwitch}
                        handleOnClick={prepareUpdate}
                        isFetching={isFetching}
                        items={items}
                        updateOrder={updateOrder}
                        searchText={searchText}
                        selectedItem={selectedItem}
                    />
                </Grid>

                <Grid size={{ md: 9, xs: 18 }}>
                    <CardListDetail
                        canModify={canModify}
                        currency={selectedItem}
                    />
                </Grid>
            </Grid>

            <Modal
                canDelete={canDelete}
                data={itemsParent}
                filterItemsParent={filterItemsParent}
                handleClickClose={handleClickCloseModalParent}
                handleSelectParent={prepareSelectParent}
                handleUnselectParent={handleUnselectParent}
                isOpen={isOpenModal}
                isFetching={isFetchingItemsParent}
                onEnter={() => filterItemsParent()}
                pagination={paginationItemsParent}
                searchText={searchTextParent}
            />
        </MaterialContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    isFetching: selectors.getIsFetchingCurrencies,
    isFetchingItemsParent: selectors.getIsFetchingItemsParent,
    isOpenModal: selectors.getIsOpenModal,
    items: selectors.getDataFetchItems,
    itemsParent: selectors.getDataItemsParent,
    paginationItemsParent: selectors.getPaginationItemsParent,
    searchText: selectors.getSearchText,
    searchTextParent: selectors.getSearchTextParent,
    selectedItem: selectors.getSelectedItem,
});

const mapDispatchToProps = (dispatch) => ({
    activateItem: (id, items) => dispatch(actions.activateItem(id, items)),
    filterItems: (searchText) => dispatch(actions.filterItems(searchText)),
    filterItemsParent: ({ orderBy, sortBy, page, searchText } = {}) =>
        dispatch(
            actions.filterItemsParent({ orderBy, sortBy, page, searchText }),
        ),
    handleClickCloseModal: () => dispatch(actions.closeModal()),
    handleClickCreate: () => dispatch(actions.prepareCreate()),
    prepareUpdate: (currency) => dispatch(actions.prepareUpdate(currency)),
    prepareSelectParent: (currency, target) =>
        dispatch(actions.prepareSelectParent(currency, target)),
    resetItem: () => dispatch(actions.resetItem()),
    resetForm: () => dispatch(actions.resetForm()),
    searchItemsParent: (searchTextParent) =>
        dispatch(actions.searchItemsParent(searchTextParent)),
    unselectParent: (id) => dispatch(actions.unselectParent(id)),
    updateOrder: (oldIndex, newIndex, items) =>
        dispatch(actions.updateOrder(oldIndex, newIndex, items)),
});

Container.propTypes = {
    activateItem: PropTypes.func,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    filterItems: PropTypes.func,
    filterItemsParent: PropTypes.func,
    handleClickCloseModal: PropTypes.func,
    handleClickCreate: PropTypes.func,
    isFetching: PropTypes.bool,
    isFetchingItemsParent: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    items: PropTypes.array,
    itemsParent: PropTypes.array,
    match: PropTypes.object,
    paginationItemsParent: PropTypes.object,
    prepareUpdate: PropTypes.func,
    prepareSelectParent: PropTypes.func,
    selectedItem: PropTypes.object,
    resetForm: PropTypes.func,
    resetItem: PropTypes.func,
    searchText: PropTypes.string,
    searchTextParent: PropTypes.string,
    unselectParent: PropTypes.func,
    updateOrder: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withPermissions(PERMISSION_LIST.CURRENCIES_PERMISSION),
)(Container);
