import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Skeleton } from 'sunwise-ui';

import KeyIndicator from 'common/components/KeyIndicator';
import { numberFormat } from 'common/utils/helpers';

import FinancialProductImage from 'resources/financialProduct.png';

import ProductItem from './ProductItem';

const FinancialReview = ({
    currencyIso,
    currencyLocale,
    financialProductParameters,
    isLoading,
    ownFinancialProduct,
    show,
}) => {
    const { t } = useTranslation();

    if (!show) return null;
    if (isLoading) return <Skeleton variant="rounded" height={200} />;

    const productParameters = get(ownFinancialProduct, 'parameters', {});
    const { monthly_payment = 0, total_cost_financing = 0 } =
        financialProductParameters;
    const parameters = get(financialProductParameters, 'parameters', {});

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid size={{ lg: 3, md: 4, xs: 9 }}>
                <ProductItem
                    disabled
                    name={ownFinancialProduct.name}
                    onClick={() => {}}
                    srcImg={FinancialProductImage}
                />
            </Grid>
            <Grid size={{ lg: 15, xs: 18 }}>
                <Grid container sx={{ pl: { xs: 2, lg: 3 } }}>
                    <Grid size={{ lg: 6, md: 9, xs: 18 }}>
                        <KeyIndicator
                            title={t('Interest rate')}
                            value={numberFormat(parameters.rate, {
                                decimals: 2,
                                locale: currencyLocale,
                                style: 'decimal',
                                unit: '%',
                            })}
                            widthIcon="20px"
                        />
                    </Grid>
                    <Grid size={{ lg: 6, md: 9, xs: 18 }}>
                        {productParameters.is_commission_percentage ? (
                            <KeyIndicator
                                title={t('Opening commission')}
                                value={numberFormat(
                                    parameters.commission_credit,
                                    {
                                        decimals: 2,
                                        locale: currencyLocale,
                                        style: 'decimal',
                                        unit: '%',
                                    },
                                )}
                                widthIcon="20px"
                            />
                        ) : (
                            <KeyIndicator
                                title={t('Opening commission')}
                                value={numberFormat(
                                    parameters.commission_credit,
                                    {
                                        currency: currencyIso,
                                        decimals: 2,
                                        locale: currencyLocale,
                                        style: 'currency',
                                    },
                                )}
                                widthIcon="20px"
                            />
                        )}
                    </Grid>
                    <Grid size={{ lg: 6, md: 9, xs: 18 }}>
                        <KeyIndicator
                            title={t('Term')}
                            value={`${parameters.term} ${t('Month', {
                                count: 2,
                            })}`}
                            widthIcon="20px"
                        />
                    </Grid>
                    <Grid size={{ lg: 6, md: 9, xs: 18 }}>
                        {productParameters.is_percentage ? (
                            <KeyIndicator
                                title={t('Down payment')}
                                value={numberFormat(
                                    parameters.hitch_percentage,
                                    {
                                        decimals: 2,
                                        locale: currencyLocale,
                                        style: 'decimal',
                                        unit: '%',
                                    },
                                )}
                                widthIcon="20px"
                            />
                        ) : (
                            <KeyIndicator
                                title={t('Down payment')}
                                value={numberFormat(parameters.hitch, {
                                    currency: currencyIso,
                                    decimals: 2,
                                    locale: currencyLocale,
                                    style: 'currency',
                                })}
                                widthIcon="20px"
                            />
                        )}
                    </Grid>
                    {parameters.residual > 0 && (
                        <Grid size={{ lg: 6, md: 9, xs: 18 }}>
                            <KeyIndicator
                                title={t('Residual')}
                                value={numberFormat(parameters.residual, {
                                    decimals: 2,
                                    locale: currencyLocale,
                                    style: 'decimal',
                                    unit: '%',
                                })}
                                widthIcon="20px"
                            />
                        </Grid>
                    )}
                    <Grid size={{ lg: 6, md: 9, xs: 18 }}>
                        <KeyIndicator
                            title={t('Average monthly payment')}
                            value={numberFormat(monthly_payment, {
                                currency: currencyIso,
                                decimals: 2,
                                locale: currencyLocale,
                                style: 'currency',
                            })}
                            widthIcon="20px"
                        />
                    </Grid>
                    <Grid size={{ lg: 6, md: 9, xs: 18 }}>
                        <KeyIndicator
                            title={t('Financing amount')}
                            value={numberFormat(total_cost_financing, {
                                currency: currencyIso,
                                decimals: 2,
                                locale: currencyLocale,
                                style: 'currency',
                            })}
                            widthIcon="20px"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

FinancialReview.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    financialProductParameters: PropTypes.object,
    isLoading: PropTypes.bool,
    ownFinancialProduct: PropTypes.object,
    show: PropTypes.bool,
};

export default FinancialReview;
