import { getRpusByServiceNumber } from 'common/api/zap/rpus';

import {
    FETCH_RPUS,
    FETCH_RPUS_FAILURE,
    FETCH_RPUS_SUCCESS,
} from '../actionTypes';
import { SERVICE_NUMBER_REGEX } from '../constants';
import { actions } from '../reducer';

import authenticate from './authenticate';

export default (rawServiceNumber) => (dispatch) => {
    const serviceNumber = rawServiceNumber?.replace(/\s+/g, '');

    const isValid = SERVICE_NUMBER_REGEX.test(serviceNumber);

    if (!isValid) return;

    dispatch(actions[FETCH_RPUS]({ serviceNumber }));

    return authenticate(() => getRpusByServiceNumber(serviceNumber))
        .then((response) => {
            const data = response?.data;
            dispatch(actions[FETCH_RPUS_SUCCESS]({ data, serviceNumber }));

            return data;
        })
        .catch((error) => {
            dispatch(actions[FETCH_RPUS_FAILURE]({ error, serviceNumber }));

            throw error;
        });
};
