import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);

export const getIsOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal,
);

export const getSaveConsumption = createSelector(
    getModel,
    (model) => model.saveConsumption,
);

export const getSaveConsumptionIsLoading = createSelector(
    getSaveConsumption,
    (saveConsumption) => saveConsumption.loading,
);
