import React from 'react';

import i18next from 'i18next';
import PropTypes from 'prop-types';
import { BottomActions, Box, Button } from 'sunwise-ui';

const FormBottomActions = ({
    backText = i18next.t('Back'),
    disabled,
    handleClickBack,
    handleClickNext,
    nextText = i18next.t('Next'),
    type,
}) => {
    const handleOnNext = (e) => {
        e.stopPropagation();

        if (handleClickNext) handleClickNext();
    };

    const handleOnBack = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (handleClickBack) handleClickBack();
    };

    return (
        <BottomActions>
            <Box
                display="flex"
                flexDirection={{ md: 'row', xs: 'column' }}
                gap={1}
                justifyContent={{ md: 'right', xs: 'center' }}
                mt={2}
                width="100%"
            >
                <Button
                    color="secondary"
                    onClick={handleOnBack}
                    sx={{
                        order: { md: 1, xs: 1 },
                        width: { md: 'auto', xs: '100%' },
                    }}
                    variant="text"
                >
                    {backText}
                </Button>

                <Button
                    disabled={disabled}
                    onClick={handleOnNext}
                    sx={{
                        order: { md: 1, xs: 1 },
                        width: { md: 'auto', xs: '100%' },
                    }}
                    type={type}
                    variant="outlined"
                >
                    {nextText}
                </Button>
            </Box>
        </BottomActions>
    );
};

FormBottomActions.propTypes = {
    backText: PropTypes.string,
    disabled: PropTypes.bool,
    handleClickBack: PropTypes.func,
    handleClickNext: PropTypes.func,
    nextText: PropTypes.string,
    type: PropTypes.string,
};

export default FormBottomActions;
