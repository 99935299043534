import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { InfiniteColumn } from 'common/components/funnel';

import ReportCard from './ReportCard';

const ReportColumn = ({
    checkIcon,
    column,
    data,
    fetchReports,
    filterQuery,
    localeDateFormat,
    onSelectReport,
    status,
}) => {
    const { t } = useTranslation();

    const {
        data: columnData,
        loading,
        meta: { page, page_size: pageSize, total } = {},
    } = data || {};
    const { color, label } = column;

    useEffect(() => {
        fetchReports({
            filterQuery,
            page: 1,
            pageSize: 10,
            status,
        });
    }, [filterQuery, status]);

    return (
        <InfiniteColumn
            checkIcon={checkIcon}
            color={color}
            hasItems={columnData?.length > 0}
            hasNextPage={!loading && page * pageSize < total}
            id={status}
            loadMore={() => {
                if (loading) return;

                fetchReports({
                    filterQuery,
                    page: page + 1,
                    pageSize,
                    replaceValues: false,
                    status,
                });
            }}
            title={t(label)}
            totalRows={columnData?.length}
            wrapperProps={{ maxWidth: '500px', width: '25% !important' }}
        >
            {columnData?.map((item) => (
                <ReportCard
                    key={item.id}
                    localeDateFormat={localeDateFormat}
                    onClick={() => onSelectReport(item)}
                    report={item}
                />
            ))}
        </InfiniteColumn>
    );
};

ReportColumn.propTypes = {
    checkIcon: PropTypes.bool,
    column: PropTypes.object,
    data: PropTypes.object,
    fetchReports: PropTypes.func,
    filterQuery: PropTypes.string,
    localeDateFormat: PropTypes.string,
    onSelectReport: PropTypes.func,
    status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ReportColumn;
