import React, { useContext, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import { LoadingContext } from 'common/utils/contexts';

import * as actions from './actions';
import ConsumptionForm from './components/ConsumptionForm';
import * as selectors from './selectors';

const Container = ({
    isOpenModal,
    isSaving,
    projectId,
    resetForm,
    setIsOpenModal,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isSaving)
            loadingContext.openLoading(t('Saving consumptions').concat('...'));
        else loadingContext.closeLoading();
    }, [isSaving]);

    if (!projectId) return null;

    return (
        <Dialog
            onClose={() => setIsOpenModal(false)}
            onExited={() => resetForm()}
            open={isOpenModal}
            size={false}
            title={t('Enter consumption', { count: 2 })}
        >
            <ConsumptionForm
                onCancel={() => setIsOpenModal(false)}
                projectId={projectId}
            />
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    isSaving: selectors.getSaveConsumptionIsLoading,
    isOpenModal: selectors.getIsOpenModal,
});

const mapDispatchToProps = (dispatch) => ({
    resetForm: () => dispatch(actions.resetForm()),
    setIsOpenModal: (isOpen) => dispatch(actions.setIsOpenModal(isOpen)),
});

Container.propTypes = {
    isOpenModal: PropTypes.bool,
    isSaving: PropTypes.bool,
    projectId: PropTypes.string,
    resetForm: PropTypes.func,
    setIsOpenModal: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
