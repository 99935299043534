import React from 'react';

import { Box, Typography } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';

import ReportResolution from '../../reportList/components/ReportResolution';
import ReportStatus from '../../reportList/components/ReportStatus';

const ReportName = ({
    iconSize,
    name,
    resolution,
    showResolution,
    status,
    sxBox,
    variant = 'body2',
}) => (
    <Box alignItems="center" display="flex" gap={1} {...sxBox}>
        <ReportStatus
            iconSize={iconSize}
            resolution={resolution}
            status={status}
        />

        {showResolution && (
            <ReportResolution iconSize={iconSize} resolution={resolution} />
        )}

        <Typography variant={variant}>{name}</Typography>
    </Box>
);

ReportName.propTypes = {
    iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    resolution: PropTypes.number,
    showResolution: PropTypes.bool,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sxBox: PropTypes.object,
    variant: PropTypes.string,
};

export default ReportName;
