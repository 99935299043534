import React from 'react';

import PropTypes from 'prop-types';

import { generateColorTone } from 'common/utils/helpers';

import CustomTableCell from './CustomTableCell';

/**
 * Column properties:
 * - align: cell text alignment
 * - bgColor: cell background color
 * - borderColor: cell border color
 * - clickable: cell is clickable
 * - key: unique column key
 * - renderCell: cell content
 * - isSticky: cell is sticky
 * - sxCell: cell styles
 */
const Row = ({ columnGroups, expandedColumns, isLastRow, row, rowIndex }) => {
    if (!columnGroups?.length) return null;

    return columnGroups.map((group, groupIndex) => {
        const isCollapsible =
            group.expandedColumns?.length > 0 &&
            group.collapsedColumns?.length > 0;

        const isExpanded = !isCollapsible || expandedColumns[group.key];

        const columns = isExpanded
            ? group.expandedColumns
            : group.collapsedColumns;

        if (!columns?.length) return null;

        return columns.map((column, columnIndex) => {
            const {
                align = 'center',
                bgColor,
                clickable = false,
                isSticky = false,
                key,
                renderCell,
                sxCell = {},
                visible = true,
            } = column || {};

            if (!visible) return null;

            const isFirstColumn = groupIndex === 0 && columnIndex === 0;
            const isLastColumn =
                groupIndex === columnGroups.length - 1 &&
                columnIndex === columns.length - 1;

            const borderColor = group.useSameColor
                ? group.bgColor
                : generateColorTone(
                      group.bgColor,
                      columnIndex * (group.colorDelta || 25),
                  );

            return (
                <CustomTableCell
                    align={align}
                    clickable={`${clickable}`}
                    key={key}
                    sx={{
                        borderColor,
                        backgroundColor: isSticky
                            ? bgColor || 'background.paper'
                            : bgColor,
                        borderRight: isLastColumn
                            ? `1px solid ${borderColor}`
                            : 'none',
                        borderBottom: isLastRow
                            ? `1px solid ${borderColor}`
                            : 'none',
                        borderBottomLeftRadius:
                            isLastRow && isFirstColumn ? '10px' : 0,
                        borderBottomRightRadius:
                            isLastRow && isLastColumn ? '10px' : 0,
                        left: isSticky ? 0 : 'auto',
                        minWidth: '100px',
                        position: isSticky ? 'sticky' : 'relative',
                        zIndex: isSticky ? 1 : 'auto',
                        ...sxCell,
                    }}
                >
                    {renderCell?.(row, rowIndex) || `${row?.[key]}`}
                </CustomTableCell>
            );
        });
    });
};

Row.propTypes = {
    columnGroups: PropTypes.array,
    expandedColumns: PropTypes.object,
    isLastRow: PropTypes.bool,
    row: PropTypes.object,
    rowIndex: PropTypes.number,
};

export default Row;
