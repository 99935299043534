import i18next from 'i18next';
export const DAYS_IN_PERIOD = 365 / 12;

// Categories
export const ALLIANCE_DOCUMENTS_CATEGORY = 0;
export const PRODUCT_DOCUMENTS_CATEGORY = 1;

// Document Types
export const REQUEST_FORM_TYPE = 0;
export const DOCUMENTS_TYPE = 1;
export const CONTRACT_TYPE = 2;
export const PRIVATE_DOCUMENTS_TYPE = 3;
export const INSURANCE_DOCUMENTS_TYPE = 4;
export const TERMS_AND_CONDITIONS_TYPE = -1;
export const FILE_FOR_DOCUMENT_TYPES = [
    CONTRACT_TYPE,
    INSURANCE_DOCUMENTS_TYPE,
    REQUEST_FORM_TYPE,
];

// Credit Types
export const SIMPLE_TYPE = 0; // CIBANCO
export const FILE_COMPLEX_TYPE = 1; // RED GIRASOL
export const FILE_SIMPLE_TYPE = 2; // GALT
export const INFONAVIT_TYPE = 3; // CEIDE
export const INSURANCE_TYPE = 4; // BROVE

export const DEBOUNCE_TIME_300 = 300;
export const DEBOUNCE_TIME_500 = 500;
export const DEBOUNCE_TIME_1000 = 1000;
export const DEBOUNCE_TIME_1500 = 1500;
export const INFINITY = 100000000;
export const MAX_SMALL_FILE_SIZE = 2097152;
export const MAX_FILE_SIZE = 10485760;
export const WIDGET_SUPPORT_VERSION = 2;
export const DO_NOT_ASSIGN_VALUE = 'DO_NOT_ASSIGN';

export const ARROW_UP_KEY = 38;
export const ARROW_DOWN_KEY = 40;
export const ARROW_RIGHT_KEY = 39;
export const ARROW_LEFT_KEY = 37;

export const FILE_ALLOWED_EXTENSIONS = {
    CSV: '.csv,text/csv,application/vnd.ms-excel',
    IMAGE: 'image/png,image/jpeg,image/jpg',
    PDF: '.pdf,application/pdf',
    XML: '.xml,text/xml',
};

// Status
export const STATUS = {
    REQUESTED_STATUS: { key: 0, value: i18next.t('Requested') }, // Solicitado
    INPROCESS_STATUS: { key: 1, value: i18next.t('Process') }, // Proceso
    REJECTED_STATUS: { key: 2, value: i18next.t('Rejected') }, // Rechazado
    APPROVED_STATUS: { key: 3, value: i18next.t('Approved') }, // Aprobado
    CLOSED_STATUS: { key: 4, value: i18next.t('Closed') }, // Cerrado
    QUOTED_STATUS: { key: 5, value: i18next.t('Quoted') }, // Cotizado
    CANCELLED_STATUS: { key: 6, value: i18next.t('Cancelled') }, // Cancelado
    PRE_APPROVED_STATUS: {
        key: 7,
        value: i18next.t('Pre approved'),
    }, // Preaprobado
    INCOMPLETE_STATUS: { key: 8, value: i18next.t('Incomplete') }, // Incompleto
    CONDITIONED_APPROVED_STATUS: {
        key: 9,
        value: i18next.t('Approved conditional'),
    }, // Aprobado condicionado
};

export const DOCUMENT_STATUS = {
    APPROVED_STATUS: { key: 'Aprobado', value: i18next.t('Approved') },
    DOWNLOAD_STATUS: { key: 'Download', value: i18next.t('Download') },
    INPROCESS_STATUS: { key: 'Proceso', value: i18next.t('Process') },
    NO_DOCUMENT_STATUS: {
        key: 'Sin documento',
        value: i18next.t('No document'),
    },
    REJECTED_STATUS: { key: 'Rechazado', value: i18next.t('Rejected') },
    REQUESTED_STATUS: { key: 'Solicitado', value: i18next.t('Requested') },
};

export const DEFAULT_RATES = [
    { label: '1', value: '1' },
    { label: '1A', value: '1A' },
    { label: '1B', value: '1B' },
    { label: '1C', value: '1C' },
    { label: '1D', value: '1D' },
    { label: '1E', value: '1E' },
    { label: '1F', value: '1F' },
    { label: 'APBT', value: 'APBT' },
    { label: 'APMT', value: 'APMT' },
    { label: 'DAC', value: 'DAC' },
    { label: 'DIST', value: 'DIST' },
    { label: 'DIT', value: 'DIT' },
    { label: 'GDBT', value: 'GDBT' },
    { label: 'GDMTH', value: 'GDMTH' },
    { label: 'GDMTO', value: 'GDMTO' },
    { label: 'PDBT', value: 'PDBT' },
    { label: 'RABT', value: 'RABT' },
    { label: 'RAMT', value: 'RAMT' },
];

export const DEFAULT_TERMS = [
    { label: '6', value: '6' },
    { label: '12', value: '12' },
    { label: '18', value: '18' },
    { label: '24', value: '24' },
    { label: '36', value: '36' },
    { label: '48', value: '48' },
    { label: '60', value: '60' },
    { label: '72', value: '72' },
    { label: '84', value: '84' },
    { label: '96', value: '96' },
    { label: '108', value: '108' },
    { label: '120', value: '120' },
    { label: '132', value: '132' },
    { label: '144', value: '144' },
    { label: '180', value: '180' },
    { label: '240', value: '240' },
];

export const BUREAU_STATUS = {
    REQUESTED: 'requested',
    REFUSED: 'refused',
    NOT_FOUND: 'not_found',
    NOT_AUTHORIZED: 'not_authorized',
    PASSED: 'passed',
    PENDING_CLARIFICATION: 'pending_clarification',
};

export const PROPOSAL_BRANCH_ACCESS_DENIED = {
    body: i18next.t('Access denied. Proposal for a locked branch'),
    type: 'danger',
    autoClose: 5000,
};

export const CONTACT_BRANCH_ACCESS_DENIED = {
    body: i18next.t('Access denied. Contact of a blocked branch'),
    type: 'danger',
    autoClose: 5000,
};

//Company products keys
export const COMPANY_PRODUCTS = {
    ACCESORIES: 'accesories',
    ADDITIONALS: 'additionals',
    BATTERIES: 'batteries',
    INVERTERS: 'inverters',
    PANELS: 'panels',
    STRUCTURES: 'structures',
    WORKFORCES: 'workforces',
};

//Map pin default position

export const DEFAULT_LAT = 19.43258226220417;
export const DEFAULT_LNG = -99.13319580978921;
export const DEFAULT_MAP_PREVIEW_ZOOM = 12;

export const GOOGLE_MAPS_ADDRESS_COMPONENTS = {
    CITY: 'locality',
    COUNTRY: 'country',
    INTERIOR_NUMBER: 'subpremise',
    NEIGHBORHOOD: 'neighborhood',
    NEIGHBORHOOD_LEVEL_1: 'sublocality_level_1',
    POSTAL_CODE: 'postal_code',
    STATE: 'administrative_area_level_1',
    STREET: 'route',
    STREET_NUMBER: 'street_number',
};

export const DEFAULT_CURRENCY = {
    abbreviation: 'USD',
    locale: 'en-US',
    iso: 'USD',
    name: 'Dolar',
    symbol: '$',
};

export const DISCOUNT_TYPES = {
    AMOUNT: 0,
    PERCENTAGE: 1,
};

export const HIDDEN_FOOTER_ROUTES = [
    '/edit-template-pro',
    '/energy-backup-setup',
    '/financing-funnel',
    '/panel-layout',
    '/project-funnel',
    '/proposal-review-pro',
    '/proposal',
    '/report-review-pro',
    '/report',
];

export const MAX_MARGIN_PERCENTAGE = 99;

export const DEFAULT_TEMPLATE_LANGUAGE = { key: 'es-mx', value: 'Español' };

export const CHART_COLORS = [
    '#02E675',
    '#ff9a00',
    '#2F4DFF',
    '#803909',
    '#785F9D',
    '#FA6968',
    '#0073d0',
    '#600980',
    '#002438',
];

export const ENERGY_CHART_COLORS = [
    '#20c4d9',
    '#8bc34a',
    '#ff9a00',
    '#FF590D',
    '#BFBBBD',
    '#ff9a00',
    '#E91E63',
    '#FF0000',
    '#785F9D',
    '#FF4081',
    '#ae6afc',
    '#FC6ADC',
];

// BILLING PERIODS
export const MONTHLY_FREQUENCY = 'monthly';
export const YEARLY_FREQUENCY = 'yearly';

// PLAN TYPES
export const PLAN_TYPES = {
    PRO: 0,
    LITE: 1,
    SCALE: 2,
    ENTERPRISE: 3,
};

export const MAX_USERS_PRO_PLAN = 10;
export const MAX_USERS_PRO_SCALE = 30;

// SUBSCRIPTION STATUS
export const SUBSCRIPTION_STATUS = {
    FUTURE: 'future',
    INTRIAL: 'in_trial',
    ACTIVE: 'active',
    NON_RENEWING: 'non_renewing',
    PAUSED: 'paused',
    CANCELLED: 'cancelled',
    NO_SUBSCRIPTION: 'no_subscription',
};

// CHARGEBEE PORTAL SECTIONS
export const CB_PORTAL_SECTIONS = {
    SUBSCRIPTION_DETAILS: 'sub_details',
    SUBSCRIPTION_CANCELLATION: 'sub_cancel',
    EDIT_SUBSCRIPTION: 'edit_subscription',
    VIEW_SCHEDULED_CHANGES: 'scheduled_changes',
    ACCOUNT_DETAILS: 'account_details',
    EDIT_ACCOUNT_DETAILS: 'portal_edit_account',
    ADDRESS: 'portal_address',
    EDIT_BILLING_ADDRESS: 'portal_edit_billing_address',
    EDIT_SHIPPING_ADDRESS: 'portal_edit_shipping_address',
    EDIT_SUBSCRIPTION_CUSTOM_FIELDS: 'portal_edit_subscription_cf',
    PAYMENT_SOURCES: 'portal_payment_methods',
    ADD_PAYMENT_SOURCE: 'portal_add_payment_method',
    EDIT_PAYMENT_SOURCE: 'portal_edit_payment_method',
    VIEW_PAYMENT_SOURCE: 'portal_view_payment_method',
    CHOOSE_PAYMENT_METHOD_FOR_SUBSCRIPTION: 'portal_choose_payment_method',
    BILLING_HISTORY: 'portal_billing_history',
};

//DATES
export const DAYS_NAMES = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];

export const HOURS = [
    '12am',
    '1am',
    '2am',
    '3am',
    '4am',
    '5am',
    '6am',
    '7am',
    '8am',
    '9am',
    '10am',
    '11am',
    '12pm',
    '1pm',
    '2pm',
    '3pm',
    '4pm',
    '5pm',
    '6pm',
    '7pm',
    '8pm',
    '9pm',
    '10pm',
    '11pm',
];

export const MONTHS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const DEFAULT_MONTH_FINAL_DATE = '31/01/2019';
export const DEFAULT_YEAR_FINAL_DATE = '31/12/2019';
export const DEFAULT_INITIAL_DATE = '01/01/2019';

export const DAYS_IN_WEEK = 7;
export const DAYS_IN_YEAR = 365;
export const HOURS_IN_DAY = 24;
export const HOURS_IN_YEAR = 8760;
export const MONTHS_IN_YEAR = 12;
export const MS_IN_HOUR = 3600 * 1000;
export const MS_IN_DAY = MS_IN_HOUR * HOURS_IN_DAY;

// CONSUMPTION ORIGIN
export const DEFAULT_ORIGIN = 0;
export const USER_ORIGIN = 1;

// CONSUMPTION CSV TYPES
export const CONSUMPTION_CSV_TYPES = {
    0: i18next.t('Hourly'),
    1: i18next.t('Quarterly'),
};

// GENERATION
export const MAX_DEGRADATION = 1;
export const GENERATION_SOURCES = {
    NASA: 0,
    CSV: 1,
    DESIGNER: 2,
};

// BATERIES
export const BATTERY_STATUS = { CHARGING: 1, DISCHARGING: 2, IDLE: 0 };

export const BATTERY_OPERATION_STRATEGY = { MAX_POWER: 0, OPTIMAL: 1 };

export const SHARED_OPTIONS = [
    'copy',
    'whatsapp',
    'telegram',
    'mail',
    'facebook',
    'twitter',
    'linkedin',
];

export const URL_TYPES = {
    LISA: 1,
    SUNWISE: 0,
    ZAP: 2,
};

export const BRIGHT_UID = 'cb6c91b7-e955-48b2-9fb0-3c1292701b63';
export const KAM_UID = 'ec74918b-98fc-4866-863a-7e6267a7bdb0';
export const SERFIMEX_UID = '1c17991f-e30e-4d7e-ae5b-e01bcccfd83e';
export const FUNNEL_ITEMS_PER_PAGE = 7;

export const SALES_PRO_MODULES = [
    'Alliances',
    'Consumption-Profiles',
    'Dashboard',
    'Electrical-Compatibility',
    'Financial-Products',
    'Financing-Funnel',
    'Google-Irradiation-API',
    'Plans',
    'Products',
    'Project-Funnel',
    'Proposal-Templates',
    'Proposals',
    'Smart-Documents-Templates',
    'Solar-Generation',
    'Summaries',
    'Summary-Templates',
    'Taxes',
    'Web-Quoter',
];

export const SALES_SCALE_MODULES = [
    'Analytics-CFE',
    'Analytics-CRE',
    'Analytics-Sunwise',
    'BESS',
    'Branch-Offices',
];
export const AFTERSALES_PRO_MODULES = [
    'Manual-Reporting',
    'Monitoring-Dashboard',
    'Monitoring-System',
    'Report-Funnel',
    'Report-Templates',
];
export const AFTERSALES_SCALE_MODULES = [
    'Automatic-Reporting',
    'Automatic-Reports-By-Mail',
    'LISA-Integration',
];
export const BUNDLE_PRO_MODULES = [
    ...SALES_PRO_MODULES,
    ...AFTERSALES_PRO_MODULES,
];
export const BUNDLE_SCALE_MODULES = [
    ...SALES_SCALE_MODULES,
    ...AFTERSALES_SCALE_MODULES,
];
export const ENTERPRISE_MODULES = ['Branding'];
export const LEGACY_MODULES = [
    'Alliances',
    'Consumption-Profiles',
    'Dashboard',
    'Electrical-Compatibility',
    'Financial-Products',
    'Financing-Funnel',
    'Plans',
    'Products',
    'Project-Funnel',
    'Proposal-Templates',
    'Proposals',
    'Smart-Documents-Templates',
    'Solar-Generation',
    'Summaries',
    'Summary-Templates',
    'Taxes',
];
