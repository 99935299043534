import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import CheckAccess from 'common/components/CheckAccess';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import { useBreakpoint } from 'common/hooks';
import {
    getSubscriptionStatus,
    getSubscriptionsStatus,
} from 'common/utils/helpers';

import IcoFinancial from 'resources/icoFinancial.svg?react';
import IcoAlliance from 'resources/iconAlliance.svg?react';
import IcoPlans from 'resources/icoPlans.svg?react';
import IcoProducts from 'resources/icoProducts.svg?react';
import IcoTemplates from 'resources/icoTemplates.svg?react';

import * as profileSelectors from '../../../modules/profile/selectors';

import { handleOnMouseHover } from './helpers';
import StyledListItemButton from './StyledListItemButton';
import StyledStyledListItemText from './StyledStyledListItemText';
import UpgradeIcon from './UpgradeIcon';

const SettingsOptions = ({
    handleClose,
    handleDrawerToggle,
    match,
    mobileOpen,
    redirect,
    subscription,
    subscriptions,
    subscriptionVersion,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const { allHasPaymentDue, isMultipleSubscription } =
        getSubscriptionsStatus(subscriptions);
    const { isActive, isPaymentDue } = getSubscriptionStatus(subscription);

    const isDisabledV4 =
        subscriptionVersion === 'v4' && isActive && isPaymentDue;
    const isDisabledV5 =
        (subscriptionVersion === 'v5' &&
            !isMultipleSubscription &&
            isActive &&
            isPaymentDue) ||
        (subscriptionVersion === 'v5' &&
            isMultipleSubscription &&
            allHasPaymentDue);

    const onMouseLeave = (e) =>
        handleOnMouseHover({ e, breakpoint, handleClose });

    return (
        <Box onMouseLeave={onMouseLeave}>
            <CheckAccess
                requiredPermissions={[
                    PERMISSION_LIST.ACCESORIES_PERMISSION,
                    PERMISSION_LIST.ADITIONALS_PERMISSION,
                    PERMISSION_LIST.ENERGY_BACKUP_PERMISSION,
                    PERMISSION_LIST.INVERTERS_PERMISSION,
                    PERMISSION_LIST.PANELS_PERMISSION,
                    PERMISSION_LIST.STRUCTURES_PERMISSION,
                    PERMISSION_LIST.WORKFORCE_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    className="__userguiding_products_module_navbar_item"
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/products');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={/^\/products/i.test(match.path)}
                    variant="horizontal"
                >
                    <IcoProducts />
                    <StyledStyledListItemText
                        primary={t('Product', { count: 2 })}
                    />
                    <UpgradeIcon moduleName="Products" />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[PERMISSION_LIST.PLANS_PERMISSION]}
            >
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/plan-configuration');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/plan-configuration' === match.path}
                    variant="horizontal"
                >
                    <IcoPlans />
                    <StyledStyledListItemText
                        primary={t('Plan', { count: 2 })}
                    />
                    <UpgradeIcon moduleName="Plans" />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[
                    PERMISSION_LIST.PROPOSAL_TEMPLATES_PERMISSION,
                    PERMISSION_LIST.REPORT_TEMPLATES_PERMISSION,
                    PERMISSION_LIST.SMART_DOCUMENT_TEMPLATES_PERMISSION,
                    PERMISSION_LIST.SUMMARY_TEMPLATES_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/template-library');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={
                        /^\/template-library/i.test(match.path) ||
                        /^\/edit-template-pro/i.test(match.path)
                    }
                    variant="horizontal"
                >
                    <IcoTemplates />
                    <StyledStyledListItemText
                        primary={t('Template', { count: 2 })}
                    />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[PERMISSION_LIST.ALLIANCES_PERMISSION]}
            >
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/alliances');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/alliances' === match.path}
                    variant="horizontal"
                >
                    <IcoAlliance />
                    <StyledStyledListItemText
                        primary={t('Alliance', {
                            count: 2,
                        })}
                    />
                    <UpgradeIcon moduleName="Alliances" />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[
                    PERMISSION_LIST.FINANCIAL_PRODUCTS_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/financial-products');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/financial-products' === match.path}
                    variant="horizontal"
                >
                    <IcoFinancial />
                    <StyledStyledListItemText
                        primary={t('Financial entity', {
                            count: 2,
                        })}
                    />
                    <UpgradeIcon moduleName="Financial-Products" />
                </StyledListItemButton>
            </CheckAccess>
        </Box>
    );
};

SettingsOptions.propTypes = {
    handleClose: PropTypes.func,
    handleDrawerToggle: PropTypes.func,
    match: PropTypes.object,
    mobileOpen: PropTypes.bool,
    redirect: PropTypes.func,
    subscription: PropTypes.object,
    subscriptions: PropTypes.array,
    subscriptionVersion: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    subscription: profileSelectors.getMainSubscription,
    subscriptions: profileSelectors.getSubscriptions,
    subscriptionVersion: profileSelectors.getSubscriptionVersion,
});

export default connect(mapStateToProps, null)(SettingsOptions);
