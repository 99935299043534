import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Container as MaterialContainer, Grid } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import * as customColumnsSelectors from 'common/modules/customColumns/selectors';
import RestrictedAccessLanding from 'common/modules/restrictedAccessLanding/Container';

import quickViewSummaries from '../quickViewSummaries';
import * as quickViewSummariesActions from '../quickViewSummaries/actions';

import * as actions from './actions';
import ListItems from './components/ListItems';
import { getPastDate, getTodayDate } from './helpers';
import * as selectors from './selectors';

const Container = ({
    filterItems,
    handleClickBulkItems,
    handleClickOpenModal,
    isFetching,
    isFetchingTableSettings,
    paginationItems,
    proposals,
    tableSettings,
}) => {
    const { t } = useTranslation();
    const [filterData, setFilterData] = useState({
        createdBy: 'all',
        endDate: getTodayDate(),
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        searchText: '',
        sortBy: 'desc',
        startDate: getPastDate(365),
    });
    const [currentItem, setCurrentItem] = useState(null);

    useEffect(() => {
        filterItems(filterData);
    }, []);

    const handleClickView = (item) => {
        setCurrentItem(item);
        handleClickOpenModal();
    };

    return (
        <RestrictedAccessLanding
            addonName="Summaries"
            url="https://somos.sunwise.io/sunwise-sales"
        >
            <MaterialContainer maxWidth={false} sx={{ mb: 2 }}>
                <HeaderContainer>
                    <Grid size={18}>
                        <TitleIcon title={t('Summary', { count: 2 })} />
                    </Grid>
                </HeaderContainer>

                <ListItems
                    filterData={filterData}
                    filterItems={filterItems}
                    handleClickBulkItems={handleClickBulkItems}
                    handleClickView={handleClickView}
                    isFetching={isFetching || isFetchingTableSettings}
                    items={proposals}
                    paginationData={paginationItems}
                    setFilterData={setFilterData}
                    tableSettings={tableSettings}
                />
            </MaterialContainer>

            <quickViewSummaries.Container
                isGenerated={currentItem?.isGenerated}
                itemId={currentItem?.id}
                itemName={currentItem?.name}
                setCurrentItem={setCurrentItem}
            />
        </RestrictedAccessLanding>
    );
};

Container.propTypes = {
    filterItems: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickOpenModal: PropTypes.func,
    isFetching: PropTypes.bool,
    isFetchingTableSettings: PropTypes.bool,
    paginationItems: PropTypes.object,
    proposals: PropTypes.array,
    tableSettings: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    isFetching: selectors.getIsFetchingProposals,
    isFetchingTableSettings: customColumnsSelectors.getIsFetchingTableSettings,
    paginationItems: selectors.getProposalsPagination,
    proposals: selectors.getProposalsData,
    tableSettings: customColumnsSelectors.getTableSettingsData,
});

const mapDispatchToProps = (dispatch) => ({
    filterItems: (filterData) => dispatch(actions.filterItems(filterData)),
    handleClickBulkItems: (ids, callback) =>
        dispatch(actions.bulkItems(ids, callback)),
    handleClickOpenModal: () =>
        dispatch(quickViewSummariesActions.setIsOpenModal(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
