import React, { useMemo } from 'react';

import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Autocomplete, TextField } from 'sunwise-ui';

const ReactHookFormSelect2 = ({
    allOptionKey = '__select--all__',
    allText = i18next.t('All text'),
    blurOnSelect,
    control,
    disabled,
    fullWidth,
    isClearable,
    isLoading,
    label,
    limitTags,
    multiple,
    name,
    onChange,
    options,
    renderOption,
    required,
    sortOptions = true,
    sx,
    variant,
}) => {
    const sortedOptions = useMemo(() => {
        const optionsWithGroup = [];

        for (const group of options) {
            for (const option of group.options)
                optionsWithGroup.push({ ...option, group: group.label });
        }

        if (sortOptions)
            optionsWithGroup.sort((a, b) => -b.group?.localeCompare(a.group));

        if (multiple)
            optionsWithGroup.unshift({ label: allText, value: allOptionKey });

        return optionsWithGroup;
    }, [multiple, options, sortOptions]);

    const getDataValue = (data) => {
        if (!multiple) return data?.value ?? '';

        if (data?.find((option) => option.value === allOptionKey))
            return sortedOptions
                .filter((option) => option.value !== allOptionKey)
                .map((option) => option.value);

        return data?.map((option) => option.value) || [];
    };

    const onChangeAction = (_, data, field, onChange) => {
        const value = getDataValue(data);

        field.onChange(value);
        if (onChange) onChange(value);
    };

    const getValue = (field) => {
        if (multiple)
            return (
                sortedOptions?.filter((option) =>
                    field.value?.includes(option.value),
                ) ?? []
            );

        return (
            sortedOptions?.find((option) => option.value === field.value) ??
            null
        );
    };

    return (
        <Controller
            control={control}
            name={name}
            render={({ field, fieldState: { error } }) => (
                <Autocomplete
                    blurOnSelect={blurOnSelect}
                    disableClearable={!isClearable}
                    disableCloseOnSelect={multiple}
                    disabled={disabled}
                    fullWidth={fullWidth}
                    getOptionDisabled={(option) =>
                        option?.disabled || option.value === ''
                    }
                    getOptionLabel={(option) => option?.label || ''}
                    groupBy={(option) => option.group}
                    isOptionEqualToValue={(option, value) =>
                        option.value === value?.value
                    }
                    limitTags={limitTags}
                    loading={isLoading}
                    multiple={multiple}
                    name={field.name}
                    onChange={(event, data) =>
                        onChangeAction(event, data, field, onChange)
                    }
                    options={sortedOptions}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={Boolean(error)}
                            helperText={error?.message}
                            inputRef={field.ref}
                            label={label}
                            name={field.name}
                            variant={variant}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={`${option.value}-${option.label}`}>
                            {renderOption ? renderOption(option) : option.label}
                        </li>
                    )}
                    required={required}
                    sx={sx}
                    value={getValue(field)}
                />
            )}
        />
    );
};

ReactHookFormSelect2.defaultProps = {
    blurOnSelect: true,
    isClearable: true,
    isLoading: false,
    sortOptions: true,
};

ReactHookFormSelect2.propTypes = {
    allOptionKey: PropTypes.string,
    allText: PropTypes.string,
    blurOnSelect: PropTypes.bool,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    id: PropTypes.string,
    ignore: PropTypes.array,
    isClearable: PropTypes.bool,
    isLoading: PropTypes.bool,
    label: PropTypes.string,
    limitTags: PropTypes.number,
    multiple: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    renderOption: PropTypes.func,
    required: PropTypes.bool,
    sortOptions: PropTypes.bool,
    sx: PropTypes.object,
    variant: PropTypes.string,
};

export default ReactHookFormSelect2;
