import { createSlice } from '@reduxjs/toolkit';

import { formatDate, zonedDate } from 'common/utils/dates';
import { getTimezoneUserSettings } from 'common/utils/helpers/multiregion';

import {
    DELETE_COMMENT,
    DELETE_COMMENT_FAILURE,
    DELETE_COMMENT_SUCCESS,
    DELETE_TRACKING,
    DELETE_TRACKING_FAILURE,
    DELETE_TRACKING_SUCCESS,
    FETCH_COMMENTS,
    FETCH_COMMENTS_FAILURE,
    FETCH_COMMENTS_SUCCESS,
    FETCH_TRACKINGS,
    FETCH_TRACKINGS_FAILURE,
    FETCH_TRACKINGS_NEXT,
    FETCH_TRACKINGS_NEXT_FAILURE,
    FETCH_TRACKINGS_NEXT_SUCCESS,
    FETCH_TRACKINGS_PURPOSES,
    FETCH_TRACKINGS_PURPOSES_FAILURE,
    FETCH_TRACKINGS_PURPOSES_SUCCESS,
    FETCH_TRACKINGS_SUCCESS,
    FETCH_USERS,
    FETCH_USERS_FAILURE,
    FETCH_USERS_SUCCESS,
    INITIALIZE_FORM,
    OPEN_CONFIRM_MODAL,
    RESET_FORM,
    SAVE_COMMENT,
    SAVE_COMMENT_FAILURE,
    SAVE_COMMENT_SUCCESS,
    SAVE_TRACKING,
    SAVE_TRACKING_FAILURE,
    SAVE_TRACKING_SUCCESS,
    SET_CONTACT_ID,
    SET_DATA_CONFIRM_MODAL,
    SET_ELEMENT_TO_DELETE,
    SHOW_FORM,
} from './actionTypes';
import { FILTER_TYPE_CALL, NAME, OBJECTIVE_RECEIPT } from './constants';

const currentDate = zonedDate(new Date(), getTimezoneUserSettings());
const dateTimeToday = formatDate(currentDate, 'yyyy-MM-dd H:mm');

const INITIAL_STATE = {
    contactId: null,
    comments: { data: {}, error: null, isLoading: false },
    dataConfirmModal: {
        title: null,
        message: '',
        variant: '',
    },
    deleteComment: {
        data: null,
        errors: [],
        isLoading: false,
    },
    deleteTracking: {
        data: null,
        errors: [],
        isLoading: false,
    },
    elementToDelete: null,
    initialValues: {
        agent: '',
        contact: null,
        description: '',
        id: null,
        notification_at: dateTimeToday,
        objective_tracking: OBJECTIVE_RECEIPT,
        remember: true,
        tracking_purpose: '',
        tracking_type: FILTER_TYPE_CALL,
    },
    isOpenConfirmModal: null,
    isVisibleForm: false,
    saveComment: {
        isLoading: false,
        data: null,
        errors: null,
    },
    saveTracking: {
        isLoading: false,
        data: null,
        errors: null,
    },
    trackings: {
        isLoading: false,
        filterType: null,
        data: [],
        error: null,
    },
    trackingsNext: {
        isLoading: false,
        data: [],
        error: null,
    },
    trackingPurpose: {
        data: [],
        error: null,
        isEmpty: false,
        isLoading: false,
    },
    users: {
        data: [],
        errors: [],
        isFetching: false,
    },
};

const trackingSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [DELETE_COMMENT]: (state) => {
            state.deleteComment.isLoading = true;
        },
        [DELETE_COMMENT_FAILURE]: (state, action) => {
            state.deleteComment = {
                ...state.deleteComment,
                errors: action.payload,
                isLoading: false,
            };
        },
        [DELETE_COMMENT_SUCCESS]: (state) => {
            state.deleteComment = {
                ...state.deleteComment,
                data: null,
                errors: [],
                isLoading: false,
            };
        },
        [FETCH_COMMENTS]: (state) => {
            state.comments.isLoading = true;
        },
        [FETCH_COMMENTS_FAILURE]: (state, action) => {
            state.comments = {
                ...state.comments,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_COMMENTS_SUCCESS]: (state, action) => {
            state.comments = {
                ...state.comments,
                data: {
                    ...state.comments.data,
                    [action.payload.id]: action.payload.data,
                },
                isLoading: false,
            };
        },
        [SAVE_COMMENT]: (state) => {
            state.saveComment.isLoading = true;
        },
        [SAVE_COMMENT_FAILURE]: (state, action) => {
            state.saveComment = {
                ...state.saveComment,
                error: action.payload,
                isLoading: false,
            };
        },
        [SAVE_COMMENT_SUCCESS]: (state, action) => {
            state.saveComment = {
                ...state.saveComment,
                data: action.payload.data,
                isLoading: false,
            };
        },
        [FETCH_TRACKINGS]: (state) => {
            state.trackings.isLoading = false;
        },
        [FETCH_TRACKINGS_FAILURE]: (state, action) => {
            state.trackings = {
                ...state.trackings,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_TRACKINGS_SUCCESS]: (state, action) => {
            state.trackings = {
                ...state.trackings,
                data: action.payload.data,
                filterType: action.payload.filterType,
                isLoading: false,
            };
        },
        [FETCH_TRACKINGS_NEXT]: (state) => {
            state.trackingsNext.isLoading = true;
        },
        [FETCH_TRACKINGS_NEXT_FAILURE]: (state, action) => {
            state.trackingsNext = {
                ...state.trackingsNext,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_TRACKINGS_NEXT_SUCCESS]: (state, action) => {
            state.trackingsNext = {
                ...state.trackingsNext,
                data: action.payload,
                isLoading: false,
            };
        },
        [FETCH_USERS]: (state) => {
            state.users.isFetching = true;
        },
        [FETCH_USERS_FAILURE]: (state, action) => {
            state.users = {
                ...state.users,
                error: action.payload,
                isFetching: false,
            };
        },
        [FETCH_USERS_SUCCESS]: (state, action) => {
            state.users = {
                ...state.users,
                data: action.payload,
                isFetching: false,
            };
        },
        [SET_CONTACT_ID]: (state, action) => {
            state.contactId = action.payload;
        },
        [SHOW_FORM]: (state, action) => {
            state.isVisibleForm = action.payload;
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = action.payload;
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE_TRACKING]: (state, action) => {
            state.saveTracking = {
                ...state.saveTracking,
                data: action.payload,
                isLoading: false,
            };
        },
        [SAVE_TRACKING_FAILURE]: (state, action) => {
            state.saveTracking = {
                ...state.saveTracking,
                errors: action.payload,
                isLoading: false,
            };
        },
        [SAVE_TRACKING_SUCCESS]: (state, action) => {
            state.saveTracking = {
                ...state.saveTracking,
                data: action.payload,
                isLoading: false,
            };
        },
        [SET_ELEMENT_TO_DELETE]: (state, action) => {
            state.elementToDelete = action.payload;
        },
        [DELETE_TRACKING]: (state) => {
            state.deleteTracking.isLoading = true;
        },
        [DELETE_TRACKING_FAILURE]: (state, action) => {
            state.deleteTracking = {
                ...state.deleteTracking,
                errors: action.payload,
                isLoading: false,
            };
        },
        [DELETE_TRACKING_SUCCESS]: (state) => {
            state.deleteTracking.isLoading = false;
        },
        [OPEN_CONFIRM_MODAL]: (state, action) => {
            state.isOpenConfirmModal = action.payload;
        },
        [SET_DATA_CONFIRM_MODAL]: (state, action) => {
            state.dataConfirmModal = action.payload;
        },
        [FETCH_TRACKINGS_PURPOSES]: (state) => {
            state.trackingPurpose.isLoading = true;
        },
        [FETCH_TRACKINGS_PURPOSES_FAILURE]: (state, action) => {
            state.trackingPurpose = {
                ...state.trackingPurpose,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_TRACKINGS_PURPOSES_SUCCESS]: (state, action) => {
            state.trackingPurpose = {
                ...state.trackingPurpose,
                data: action.payload,
                isEmpty: action.payload.length === 0,
                isLoading: false,
            };
        },
    },
});

export const trackingsActions = trackingSlice.actions;

export default trackingSlice.reducer;
