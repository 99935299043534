import React, { useEffect, useState } from 'react';

import ArticleIcon from '@mui/icons-material/Article';
import ListIcon from '@mui/icons-material/List';
import TableChartIcon from '@mui/icons-material/TableChart';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Container as MaterialContainer } from 'sunwise-ui';

import ViewFilterHeader from 'common/components/ViewFilterHeader';
import RestrictedAccessLanding from 'common/modules/restrictedAccessLanding/Container';
import { getFiltersFromQuery } from 'common/utils/helpers';

import * as actions from './actions';
import AppliedFilters from './components/AppliedFilters';
import Content from './components/Content';
import FiltersModal from './components/FiltersModal';
import { FILTERS_KEYS, VIEWS } from './constants';
import * as selectors from './selectors';

const Container = ({
    // canModify,
    fetchFilters,
    initialValuesFilters,
    initializeFilters,
    location,
    reset,
    setIsOpenFiltersModal,
}) => {
    const [view, setView] = useState(
        localStorage.getItem('report-funnel-view') || VIEWS.FUNNEL,
    );
    const { t } = useTranslation();

    const { search: filterQuery = '' } = location;

    useEffect(() => {
        fetchFilters();

        () => reset();
    }, []);

    useEffect(() => {
        const filters = getFiltersFromQuery(
            Object.values(FILTERS_KEYS),
            filterQuery,
        );

        if (filters?.[FILTERS_KEYS.CUSTOM_RATE_ID]) {
            filters[FILTERS_KEYS.RATE_ID] =
                filters[FILTERS_KEYS.CUSTOM_RATE_ID];

            delete filters[FILTERS_KEYS.CUSTOM_RATE_ID];
        }

        initializeFilters(filters);
    }, [filterQuery]);

    const tabButtons = [
        { Icon: ListIcon, label: t('List'), value: VIEWS.LIST },
        { Icon: TableChartIcon, label: 'Funnel', value: VIEWS.FUNNEL },
    ];

    const handleOnChangeView = (newView) => {
        if (!newView) return;
        setView(newView);
        localStorage.setItem('report-funnel-view', newView);
    };

    const handleOpenFiltersModal = () => setIsOpenFiltersModal(true);

    return (
        <MaterialContainer maxWidth={false} height="100%">
            <ViewFilterHeader
                handleOnChangeView={handleOnChangeView}
                handleOpenFiltersModal={handleOpenFiltersModal}
                title={t('Report funnel')}
                titleIcon={<ArticleIcon color="primary" fontSize="large" />}
                view={view}
                viewTabs={tabButtons}
            />

            <AppliedFilters filtersValues={initialValuesFilters} />

            <Content filterQuery={filterQuery} view={view} />

            <FiltersModal />
        </MaterialContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValuesFilters: selectors.getInitialValuesFilters,
});

const mapDispatchToProps = (dispatch) => ({
    fetchFilters: () => dispatch(actions.fetchFilters()),
    initializeFilters: (params) => dispatch(actions.initializeFilters(params)),
    reset: () => dispatch(actions.reset()),
    setIsOpenFiltersModal: (isOpen) =>
        dispatch(actions.setIsOpenFiltersModal(isOpen)),
});

Container.propTypes = {
    fetchFilters: PropTypes.func,
    initialValuesFilters: PropTypes.object,
    initializeFilters: PropTypes.func,
    location: PropTypes.object,
    reset: PropTypes.func,
    setIsOpenFiltersModal: PropTypes.func,
};

const RestrictedContainer = (props) => (
    <RestrictedAccessLanding
        addonName="Report-Funnel,Sunwise-After-Sales"
        url="https://somos.sunwise.io/monitoreo-postventa"
    >
        <Container {...props} />
    </RestrictedAccessLanding>
);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(RestrictedContainer);
