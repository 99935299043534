import { push } from 'connected-react-router';
import { get, isEmpty, isUndefined } from 'lodash';

import { createOffer } from 'common/api/v2/commercialOfferParent';
import * as multiBranchesSelectors from 'common/modules/multiBranches/selectors';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import { getIsMultibranchesAccount } from 'common/utils/helpers/session';

import fetchUserSettings from '../../profile/actions/fetchProfileData';
import {
    CREATE_OFFER,
    CREATE_OFFER_FAILURE,
    CREATE_OFFER_SUCCESS,
} from '../actionTypes';
import { TYPE_PROPOSAL } from '../constants';
import { projectCommOfferTableActions } from '../reducer';

import openCommercialOfferModal from './openCommercialOfferModal';

export default (values, projectId) => (dispatch, getState) => {
    const branchSelected = multiBranchesSelectors.getBranchSelected(getState());
    let newValues = {
        branch_office:
            values.branch_office === 'company'
                ? branchSelected
                : values.branch_office,
        type: TYPE_PROPOSAL,
        name: values.name,
        plan: values.proposal_plan,
        project: projectId,
    };

    if (getIsMultibranchesAccount() && values.branch_office === 'company')
        return false;

    dispatch(projectCommOfferTableActions[CREATE_OFFER]());
    createOffer(newValues)
        .then((response) => {
            const data = get(response, 'data.data', {});
            dispatch(
                projectCommOfferTableActions[CREATE_OFFER_SUCCESS](
                    response.data,
                ),
            );
            if (!isEmpty(data) && !isUndefined(data.id)) {
                dispatch(fetchUserSettings());
                dispatch(openCommercialOfferModal(false));
                dispatch(push(`/proposal/${data.id}`));
            }
        })
        .catch((error) => {
            dispatch(
                projectCommOfferTableActions[CREATE_OFFER_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
