import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Typography } from 'sunwise-ui';

const ProjectFormatter = ({ row }) => {
    const name = get(row?.project, 'name', '---');
    return <Typography variant="caption">{`${name}`}</Typography>;
};

ProjectFormatter.propTypes = {
    row: PropTypes.object,
};

export default ProjectFormatter;
