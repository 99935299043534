import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_FINANCIALS,
    FETCH_FINANCIALS_FAILURE,
    FETCH_FINANCIALS_SUCCESS,
    FETCH_FINANCIERS,
    FETCH_FINANCIERS_FAILURE,
    FETCH_FINANCIERS_SUCCESS,
    FETCH_REQUIREMENTS,
    FETCH_REQUIREMENTS_FAILURE,
    FETCH_REQUIREMENTS_SUCCESS,
    SAVE_ALLIANCE,
    SAVE_ALLIANCE_FAILURE,
    SAVE_ALLIANCE_SUCCESS,
    SIMULATE_CREDIT,
    SIMULATE_CREDIT_FAILURE,
    SIMULATE_CREDIT_SUCCESS,
    SET_IS_OPEN_MODAL,
    SET_IS_OPEN_SIMULATE_MODAL,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    financials: {
        data: [],
        errors: [],
        isFetching: true,
    },
    financiers: {
        data: [],
        errors: [],
        isFetching: true,
    },
    isOpenModal: false,
    isOpenSimulateModal: false,
    requirements: {
        data: null,
        errors: [],
        isFetching: true,
    },
    saveAlliance: {
        data: [],
        errors: [],
        isFetching: false,
    },
    simulateCredit: {
        data: [],
        errors: [],
        isFetching: false,
    },
    termInitialValues: {
        companyRegistered: false,
    },
};

const proposalGeneratorAddAllianceSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_FINANCIALS]: (state) => {
            state.financials = {
                ...state.financials,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_FINANCIALS_FAILURE]: (state, action) => {
            state.financials = {
                ...state.financials,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIALS_SUCCESS]: (state, action) => {
            state.financials = {
                ...state.financials,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIERS]: (state) => {
            state.financiers = {
                ...state.financiers,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_FINANCIERS_FAILURE]: (state, action) => {
            state.financiers = {
                ...state.financiers,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIERS_SUCCESS]: (state, action) => {
            state.financiers = {
                ...state.financiers,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_REQUIREMENTS]: (state) => {
            state.requirements = {
                ...state.requirements,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_REQUIREMENTS_FAILURE]: (state, action) => {
            state.requirements = {
                ...state.requirements,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_REQUIREMENTS_SUCCESS]: (state, action) => {
            state.requirements = {
                ...state.requirements,
                data: action.payload,
                isFetching: false,
            };
        },
        [SAVE_ALLIANCE]: (state) => {
            state.saveAlliance = {
                ...state.saveAlliance,
                errors: [],
                isFetching: true,
            };
        },
        [SAVE_ALLIANCE_FAILURE]: (state, action) => {
            state.saveAlliance = {
                ...state.saveAlliance,
                errors: action.payload,
                isFetching: false,
            };
        },
        [SAVE_ALLIANCE_SUCCESS]: (state, action) => {
            state.saveAlliance = {
                ...state.financiersInstaller,
                data: action.payload,
                isFetching: false,
            };
        },
        [SET_IS_OPEN_MODAL]: (state, action) => {
            state.isOpenModal = action.payload;
        },
        [SET_IS_OPEN_SIMULATE_MODAL]: (state, action) => {
            state.isOpenSimulateModal = action.payload;
        },
        [SIMULATE_CREDIT]: (state) => {
            state.simulateCredit = {
                ...state.simulateCredit,
                errors: [],
                isFetching: true,
            };
        },
        [SIMULATE_CREDIT_FAILURE]: (state, action) => {
            state.simulateCredit = {
                ...state.simulateCredit,
                errors: action.payload,
                isFetching: false,
            };
        },
        [SIMULATE_CREDIT_SUCCESS]: (state, action) => {
            state.simulateCredit = {
                ...state.financiersInstaller,
                data: action.payload,
                isFetching: false,
            };
        },
    },
});

export const proposalGeneratorAddAllianceActions =
    proposalGeneratorAddAllianceSlice.actions;

export default proposalGeneratorAddAllianceSlice.reducer;
