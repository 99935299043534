import React, { useEffect } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Divider, Grid, Tooltip, Typography } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import ShowErrors from 'common/components/ShowErrors';
import { getIsOwnerUser } from 'common/utils/helpers/session';
import yupResolver from 'common/utils/yupResolver';

import * as profileSelectors from '../../profile/selectors';
import * as actions from '../actions';
import { getIsDisabledFields, getRoleLevelOptions } from '../helpers';
import * as selectors from '../selectors';
import validate from '../validate';

import PermissionList from './PermissionList';

const FormWrapper = styled(Box)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark' ? 'rgb(47, 51, 53)' : '#EEF4FA',
    borderBottomLeftRadius: '18px',
    borderTopLeftRadius: '18px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '16px',
    minHeight: '100%',
    padding: '16px',
}));

const RoleForm = ({
    addons,
    errors,
    fetchRoleLevelDetails,
    fetchRoleLevels,
    filterData,
    initialValues,
    isFetchingRoleDetails,
    isFetchingRoleLevelDetails,
    isFetchingRoleLevels,
    isFetchingUserSettings,
    isNewRecord,
    isSaving,
    roleLevels,
    save,
    subscriptions,
    subscriptionVersion,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        fetchRoleLevels(watch(), isNewRecord);
    }, []);

    const { control, handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const isDefault = watch('is_system_role');

    const isFetching =
        isFetchingRoleDetails ||
        isFetchingRoleLevelDetails ||
        isFetchingRoleLevels ||
        isFetchingUserSettings;

    const isDisabled = getIsDisabledFields({
        isDefault,
        isFetching,
        isNewRecord,
    });

    const handleOnSave = (values) => save(filterData, values);
    const isOwner = getIsOwnerUser();

    return (
        <form onSubmit={handleSubmit(handleOnSave)}>
            <Grid container>
                <Grid size={{ xs: 5 }}>
                    <FormWrapper>
                        <Typography fontWeight="bold" mb={1} variant="body2">
                            Rol
                        </Typography>
                        <ReactHookFormInput
                            control={control}
                            disabled={isDisabled}
                            name="name"
                            label={t('Role name')}
                            variant="standard"
                        />
                        {isNewRecord && (
                            <Box alignItems="center" display="flex" gap={1}>
                                <ReactHookFormSelect
                                    control={control}
                                    disabled={isDisabled}
                                    fullWidth
                                    name="level_of_permissions"
                                    label={t('Level of permissions')}
                                    onChange={(e) => {
                                        fetchRoleLevelDetails(
                                            watch(),
                                            e.target.value,
                                        );
                                    }}
                                    options={getRoleLevelOptions(roleLevels)}
                                    variant="standard"
                                />
                                <Tooltip
                                    title={t(
                                        'Configure your permissions from a system role',
                                    )}
                                >
                                    <Box component="span">
                                        <InfoIcon
                                            color="primary"
                                            fontSize="small"
                                        />
                                    </Box>
                                </Tooltip>
                            </Box>
                        )}
                        {isOwner && (
                            <ReactHookFormIntlNumberInput
                                allowDecimals={false}
                                allowNegativeValue={false}
                                control={control}
                                disabled={isDisabled}
                                fullWidth
                                label={t('Maximum total discount')}
                                name="maximum_discount"
                                variant="standard"
                            />
                        )}
                        <Box sx={{ mt: 'auto', textAlign: 'center' }}>
                            <Button
                                color="secondary"
                                href={`${
                                    import.meta.env.VITE_ACADEMY_URL
                                }es/articles/9312300-configurador-de-permisos`}
                                sx={{ width: { md: 'auto', xs: '100%' } }}
                                target="_blank"
                                variant="text"
                            >
                                {t('See manual')}
                            </Button>
                        </Box>
                    </FormWrapper>
                </Grid>
                <Grid size={{ xs: 13 }}>
                    <PermissionList
                        addons={addons}
                        control={control}
                        formValues={watch()}
                        isDisabled={isDisabled}
                        isFetching={isFetching}
                        isOwner={isOwner}
                        setValue={setValue}
                        subscriptions={subscriptions}
                        subscriptionVersion={subscriptionVersion}
                    />
                </Grid>
            </Grid>

            <ShowErrors errors={errors} />

            <Divider sx={{ my: 2 }} />

            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'end',
                }}
            >
                {(isNewRecord || !isDefault) && (
                    <Button disabled={isDisabled || isSaving} type="submit">
                        {isNewRecord ? 'Agregar' : 'Guardar'}
                    </Button>
                )}
            </Box>
        </form>
    );
};

RoleForm.propTypes = {
    addons: PropTypes.array,
    errors: PropTypes.array,
    fetchRoleLevelDetails: PropTypes.func,
    fetchRoleLevels: PropTypes.func,
    filterData: PropTypes.object,
    initialValues: PropTypes.object,
    isFetchingRoleDetails: PropTypes.bool,
    isFetchingRoleLevelDetails: PropTypes.bool,
    isFetchingRoleLevels: PropTypes.bool,
    isFetchingUserSettings: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isSaving: PropTypes.bool,
    roleLevels: PropTypes.array,
    save: PropTypes.func,
    subscriptions: PropTypes.array,
    subscriptionVersion: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    addons: profileSelectors.getAddons,
    initialValues: selectors.getInitialValues,
    isFetchingRoleDetails: selectors.getIsFetchingRoleDetails,
    isFetchingRoleLevelDetails: selectors.getIsFetchingRoleLevelDetails,
    isFetchingRoleLevels: selectors.getIsFetchingRoleLevels,
    isFetchingUserSettings: profileSelectors.getIsFetching,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSavingItem,
    roleLevels: selectors.getRoleLevelsData,
    subscriptions: profileSelectors.getSubscriptions,
    subscriptionVersion: profileSelectors.getSubscriptionVersion,
});

const mapDispatchToProps = (dispatch) => ({
    fetchRoleLevels: (formValues, isNewRecord) =>
        dispatch(actions.fetchRoleLevels(formValues, isNewRecord)),
    fetchRoleLevelDetails: (formValues, levelId) =>
        dispatch(actions.fetchRoleLevelDetails(formValues, levelId)),
    save: (filterData, values) => dispatch(actions.save(filterData, values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleForm);
