import React, { useEffect } from 'react';

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    Box,
    Card,
    ConfirmDialog,
    Divider,
    Grid,
    Typography,
} from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { getSessionLocalStorage } from 'common/utils/helpers/session';

import * as actions from './actions';
import { NextTrackings, TrackingList, TrackingForm } from './components';
import * as selectors from './selectors';

const Container = ({
    canDelete,
    canModify,
    canView,
    contactId,
    dataConfirmModal,
    dataContact,
    dropTracking,
    errors,
    fetchTrackings,
    fetchTrackingsNext,
    fetchTrackingsPurposes,
    fetchUsers,
    handleClickChangeDate,
    handleClickMarkAsCompleted,
    initialValues,
    isLoadingTrackingNext,
    isLoadingTrackings,
    isOpenConfirmModal,
    isSaving,
    isVisibleForm,
    openConfirmModal,
    prepareCreateForm,
    prepareDelete,
    prepareUpdateForm,
    save,
    setContactId,
    showForm,
    trackingsNextTemp,
    trackingsPurposes,
    trackingsPurposesToSelect,
    trackingsTemp,
    users,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        setContactId(contactId);
        fetchTrackingsNext();
        fetchTrackings();
        fetchUsers();
    }, []);

    const handleClickSubmit = (values) => save(values);
    const trackings = trackingsTemp.map((tracking) => {
        let trackingTemp = { ...tracking };
        dataContact &&
            (trackingTemp.agent = tracking.agent
                ? tracking.agent
                : dataContact.agent);

        return trackingTemp;
    });

    const trackingsNext = trackingsNextTemp.map((tracking) => {
        let trackingTemp = { ...tracking };

        dataContact &&
            (trackingTemp.agent = tracking.agent
                ? tracking.agent
                : dataContact.agent);

        return trackingTemp;
    });

    const renderNextTrackings = () => {
        if (!trackingsNext || trackingsNext.length === 0) return null;

        return (
            <NextTrackings
                canDelete={canDelete}
                canModify={canModify}
                handleClickDelete={prepareDelete}
                handleClickMarkAsCompleted={handleClickMarkAsCompleted}
                handleClickUpdate={prepareUpdateForm}
                isLoading={isLoadingTrackingNext}
                isVisibleForm={isVisibleForm}
                trackings={trackingsNext}
                users={users}
            />
        );
    };

    if (!canView) return null;

    return (
        <>
            <Grid container>
                <Grid size={{ md: 9, xs: 18 }}>
                    <Card>
                        <Card.Body>
                            <Box display="flex" py={2} gap={2}>
                                <NotificationsActiveIcon />
                                <Box display="flex" flexDirection="column">
                                    <Typography
                                        variant="body2"
                                        fontWeight="bold"
                                    >
                                        {t(
                                            'A well-informed prospect will be an assured customer',
                                        )}
                                    </Typography>
                                    <Typography variant="body2">
                                        {t(
                                            'Remember that Sunwise allows you to schedule your next follow-ups and will notify you on the day and time you should do it',
                                        )}
                                    </Typography>
                                </Box>
                            </Box>

                            <TrackingForm
                                canModify={canModify}
                                errors={errors}
                                fetchTrackingsPurposes={fetchTrackingsPurposes}
                                handleClickCancel={() => showForm(false)}
                                handleClickShowForm={prepareCreateForm}
                                handleClickSubmit={handleClickSubmit}
                                initialValues={initialValues}
                                isVisibleForm={isVisibleForm}
                                isSaving={isSaving}
                                dataContact={dataContact}
                                trackingsPurposes={trackingsPurposes}
                                trackingsPurposesToSelect={
                                    trackingsPurposesToSelect
                                }
                                users={users}
                            />

                            <Divider sx={{ my: 2 }} />

                            {renderNextTrackings()}
                        </Card.Body>
                    </Card>
                </Grid>

                <Grid size={{ md: 9, xs: 18 }}>
                    <Card>
                        <Card.Body>
                            <Typography
                                fontWeight="bold"
                                sx={{ py: 2 }}
                                variant="body1"
                            >
                                {t('Completed follow-ups')}
                            </Typography>

                            <TrackingList
                                canDelete={canDelete}
                                canModify={canModify}
                                handleClickChangeDate={handleClickChangeDate}
                                handleClickDelete={prepareDelete}
                                handleClickFilter={fetchTrackings}
                                handleClickUpdate={prepareUpdateForm}
                                isLoading={isLoadingTrackings}
                                trackings={trackings}
                                session={getSessionLocalStorage()}
                                users={users}
                            />
                        </Card.Body>
                    </Card>
                </Grid>
            </Grid>

            <ConfirmDialog
                handleClickSuccess={dropTracking}
                handleClickClose={() => openConfirmModal(false)}
                isOpen={isOpenConfirmModal}
                message={dataConfirmModal && dataConfirmModal.message}
                title={dataConfirmModal && dataConfirmModal.title}
                variant={dataConfirmModal && dataConfirmModal.type}
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    dataConfirmModal: selectors.getDataConfirmModal,
    errors: selectors.getErrors,
    filterTypeTrackings: selectors.getFilterTypeTrackings,
    initialValues: selectors.getInitialValues,
    isLoadingTrackingNext: selectors.getIsLoadingTrackingsNext,
    isLoadingTrackings: selectors.getIsLoadingTrackings,
    isOpenConfirmModal: selectors.getIsOpenConfirmModal,
    isSaving: selectors.getIsSavingTracking,
    isVisibleForm: selectors.getIsVisibleForm,
    trackingsNextTemp: selectors.getDataTrackingsNext,
    trackingsPurposes: selectors.getTrackingPurpose,
    trackingsPurposesToSelect: selectors.getTrackingPurposeToSelect,
    trackingsTemp: selectors.getDataTrackings,
    users: selectors.getUsersData,
});

const mapDispatchToProps = (dispatch) => ({
    dropTracking: (id) => dispatch(actions.dropTracking(id)),
    fetchTrackings: (type = null) => dispatch(actions.fetchTrackings(type)),
    fetchTrackingsNext: (type = null) =>
        dispatch(actions.fetchTrackingsNext(type)),
    fetchTrackingsPurposes: () => dispatch(actions.fetchTrackingsPurposes()),
    fetchUsers: () => dispatch(actions.fetchUsers()),
    handleClickChangeDate: (id, values) =>
        dispatch(actions.rescheduleTracking(id, values)),
    handleClickMarkAsCompleted: (id) =>
        dispatch(actions.markTrackingAsCompleted(id)),
    prepareCreateForm: () => dispatch(actions.prepareCreateForm()),
    prepareDelete: (element) => dispatch(actions.prepareDelete(element)),
    prepareUpdateForm: (id) => dispatch(actions.prepareUpdateForm(id)),
    save: (values) => dispatch(actions.save(values)),
    setContactId: (id) => dispatch(actions.setContactId(id)),
    showForm: (value) => dispatch(actions.showForm(value)),
    openConfirmModal: (value) => dispatch(actions.openConfirmModal(value)),
});

Container.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    contactId: PropTypes.string,
    dataConfirmModal: PropTypes.object,
    dataContact: PropTypes.object,
    dropTracking: PropTypes.func,
    errors: PropTypes.array,
    fetchTrackings: PropTypes.func,
    fetchTrackingsNext: PropTypes.func,
    fetchTrackingsPurposes: PropTypes.func,
    fetchUsers: PropTypes.func,
    handleClickChangeDate: PropTypes.func,
    handleClickMarkAsCompleted: PropTypes.func,
    initialValues: PropTypes.object,
    isLoadingTrackingNext: PropTypes.bool,
    isLoadingTrackings: PropTypes.bool,
    isOpenConfirmModal: PropTypes.bool,
    isSaving: PropTypes.bool,
    isVisibleForm: PropTypes.bool,
    openConfirmModal: PropTypes.func,
    prepareCreateForm: PropTypes.func,
    prepareDelete: PropTypes.func,
    prepareUpdateForm: PropTypes.func,
    save: PropTypes.func,
    setContactId: PropTypes.func,
    showForm: PropTypes.func,
    trackingsNextTemp: PropTypes.array,
    trackingsPurposes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    trackingsPurposesToSelect: PropTypes.array,
    trackingsTemp: PropTypes.array,
    users: PropTypes.array,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.TRACKING_CONTACTS_PERMISSION),
)(Container);
