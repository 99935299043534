import React from 'react';

import PropTypes from 'prop-types';
import { Grid } from 'sunwise-ui';

import { ReactHookFormIntlNumberInputConsumption } from 'common/components/form/bootstrap';

const ContractedDemandFields = ({
    baseName,
    control,
    disabled,
    fields,
    onChange,
}) => (
    <Grid container px={2} pt={1}>
        {fields?.map((key) => (
            <Grid key={`${baseName}-${key}`} size={{ lg: 6, md: 9, xs: 18 }}>
                <ReactHookFormIntlNumberInputConsumption
                    append="kW"
                    autoFocus={false}
                    control={control}
                    disabled={disabled}
                    fullWidth
                    name={`${baseName}.${key}`}
                    onChange={onChange}
                    variant="outlined"
                />
            </Grid>
        ))}
    </Grid>
);

ContractedDemandFields.propTypes = {
    baseName: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    fields: PropTypes.array,
    onChange: PropTypes.func,
};

export default ContractedDemandFields;
