import React, { useEffect } from 'react';

import { UiDialog as Dialog } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as actions from '../actions';
import * as selectors from '../selectors';

import BottomActionsForm from './form/BottomActionsForm';
import SourceInfoFields from './form/SourceInfoFields';

const SourceFieldsForm = ({
    fetchPowerStations,
    initialValues,
    isOpen,
    isSaving,
    resetSourceFieldsForm,
    saveSourceFields,
    setSourceFieldsFormModalOpenStatus,
    sunwiseProjectId,
}) => {
    const { t } = useTranslation();

    const { control, handleSubmit, reset } = useForm({
        defaultValues: initialValues,
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const onClose = () => setSourceFieldsFormModalOpenStatus(false);

    const handleOnSubmit = (values) => {
        saveSourceFields({
            successCallback: () => {
                onClose();
                fetchPowerStations(sunwiseProjectId);
            },
            values,
        });
    };

    const isDisabled = isSaving;

    return (
        <Dialog
            onClose={onClose}
            onExited={resetSourceFieldsForm}
            open={isOpen}
            size="lg"
            title={t('Edit')}
        >
            <form onSubmit={handleSubmit(handleOnSubmit)}>
                <SourceInfoFields
                    control={control}
                    disabled={isDisabled}
                    //  validFields={provider?.valid_read_fields}
                />

                <BottomActionsForm
                    cancelText={t('Cancel')}
                    continueText={t('Save')}
                    disabled={isDisabled}
                    disabledContinue={isDisabled}
                    onCancel={onClose}
                    type="submit"
                />
            </form>
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValuesSourceFields,
    isOpen: selectors.getSourceFieldsModalFormOpenStatus,
});

const mapDispatchToProps = (dispatch) => ({
    fetchPowerStations: (sunwiseProjectId) =>
        dispatch(actions.fetchPowerStations(sunwiseProjectId)),
    resetSourceFieldsForm: () => dispatch(actions.resetSourceFieldsForm()),
    saveSourceFields: (params) => dispatch(actions.saveSourceFields(params)),
    setSourceFieldsFormModalOpenStatus: (value) =>
        dispatch(actions.setSourceFieldsFormModalOpenStatus(value)),
});

SourceFieldsForm.propTypes = {
    fetchPowerStations: PropTypes.func,
    initialValues: PropTypes.object,
    isOpen: PropTypes.bool,
    isSaving: PropTypes.bool,
    resetSourceFieldsForm: PropTypes.func,
    saveSourceFields: PropTypes.func,
    setSourceFieldsFormModalOpenStatus: PropTypes.func,
    sunwiseProjectId: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(SourceFieldsForm);
