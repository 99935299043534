import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { types } from 'sunwise-template-core';
import { Box, Button } from 'sunwise-ui';

import ModalDialog from 'common/components/ModalDialog';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as summaryReviewActions from '../summaryReview/actions';
import * as summaryReviewSelectors from '../summaryReview/selectors';

import * as actions from './actions';
import Preview from './components/Preview';
import * as selectors from './selectors';

const Container = ({
    canModify,
    fetchOfferInfoPatern,
    handleCloseModal,
    isFetchingOfferInfoPatern,
    isOpenModal,
    itemId,
    itemName,
    offerInfoPatern,
    setCurrentItem,
}) => {
    const { t } = useTranslation();
    const [editionDisabled, setEditionDisabled] = useState(true);

    const callback = () => {
        handleCloseModal();
        setCurrentItem(null);
    };

    const onClose = () => callback();

    const onEnter = () => fetchOfferInfoPatern(itemId);

    return (
        <ModalDialog
            footerComponent={() => (
                <Box display="flex" gap={2} justifyContent="flex-end" py={1}>
                    <Button
                        component={RouterLink}
                        onClick={() => callback()}
                        to={`/summary-review/${itemId}`}
                        variant="outlined"
                        sx={{ textTransform: 'none' }}
                    >
                        {t('Go to the summary')}
                    </Button>
                </Box>
            )}
            onClose={onClose}
            onEnter={onEnter}
            open={isOpenModal}
            title={itemName}
        >
            <Preview
                canModify={canModify}
                editionDisabled={editionDisabled}
                isFetching={isFetchingOfferInfoPatern}
                itemId={itemId}
                offerInfoPatern={offerInfoPatern}
                setEditionDisabled={setEditionDisabled}
                templateType={types.MULTIPROPOSAL_TYPE}
            />
        </ModalDialog>
    );
};

const mapStateToProps = createStructuredSelector({
    offerInfoPatern: summaryReviewSelectors.getDataSummaryrInfoPatern,
    isFetchingOfferInfoPatern:
        summaryReviewSelectors.getIsFetchingSummaryInfoPatern,
    isOpenModal: selectors.getIsOpenModal,
});

const mapDispatchToProps = (dispatch) => ({
    fetchOfferInfoPatern: (id) =>
        dispatch(summaryReviewActions.fetchSummaryInfoPatern(id)),
    handleCloseModal: () => dispatch(actions.setIsOpenModal(false)),
});

Container.propTypes = {
    canModify: PropTypes.bool,
    fetchOfferInfoPatern: PropTypes.func,
    handleCloseModal: PropTypes.func,
    isFetchingOfferInfoPatern: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    itemId: PropTypes.string,
    itemName: PropTypes.string,
    offerInfoPatern: PropTypes.object,
    setCurrentItem: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.EDIT_PROPOSAL_PREVIEW_PERMISSION),
)(Container);
