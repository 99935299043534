import React, { useEffect, useState } from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { BottomActions, Box, Button, Tabs, Tab, TabPanel } from 'sunwise-ui';

import yupResolver from 'common/utils/yupResolver';

import * as AnalysisVsGenerationselectors from '../../proposalGeneratorConsumptionAnalysisVsGeneration/selectors';
import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

import ConsiderationsFields from './ConsiderationsFields';
import CurrencyFields from './CurrencyFields';
import DiscountRateField from './DiscountRateField';
import ModifyCalculationField from './ModifyCalculationField';
import OpexFields from './OpexFields';
import PlanFields from './PlanFields';
import PPAFields from './PPAFields';
import RoiFields from './RoiFields';
import SimulationFields from './SimulationFields';

const FormContainer = ({
    canModifyCurrencyType,
    canModifyExchangeType,
    canModifyPlans,
    canModifyRoiTaxIncentive,
    canModifyStockExchange,
    currencies,
    customerMode,
    handleClickCancel,
    initialValues,
    isLocked,
    isSaving,
    nextRateData,
    plans,
    proposalId,
    save,
}) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState('general');
    const { clearErrors, control, handleSubmit, reset, setValue, watch } =
        useForm({
            defaultValues: initialValues,
            resolver: yupResolver(validate),
        });

    useEffect(() => reset(initialValues), [initialValues]);

    const handleChange = (_, newValue) => setSelectedTab(newValue);

    const handleOnSave = (values) => save(proposalId, values);

    const currency = watch('currency_item');
    const currencyIso = get(currency, 'abbreviation', 'USD');
    const currencyLocale = get(currency, 'locale', 'en-US');
    const currencySymbol = get(currency, 'symbol', '$');

    return (
        <form>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTab} onChange={handleChange}>
                    <Tab
                        id="tab-general"
                        key="tab-general"
                        label={t('General')}
                        value="general"
                    />
                    <Tab
                        id="tab-system"
                        key="tab-system"
                        label={t('System')}
                        value="system"
                    />
                    <Tab
                        id="tab-considerations"
                        key="tab-considerations"
                        label={t('Terms and considerations')}
                        value="considerations"
                        sx={{
                            '&.MuiTab-root': { textTransform: 'initial' },
                        }}
                    />
                </Tabs>
            </Box>

            <TabPanel
                key="tab-general"
                selectedTab={selectedTab}
                value="general"
            >
                <PlanFields
                    control={control}
                    isLocked={!canModifyPlans || isLocked}
                    plans={plans}
                    setValue={setValue}
                />
                <CurrencyFields
                    canModifyCurrencyType={canModifyCurrencyType}
                    canModifyExchangeType={canModifyExchangeType}
                    control={control}
                    currencies={currencies}
                    isLocked={isLocked}
                    setValue={setValue}
                />
                <SimulationFields
                    canModifyStockExchange={canModifyStockExchange}
                    control={control}
                    isHidden={customerMode}
                    isLocked={isLocked}
                />
            </TabPanel>

            <TabPanel key="tab-system" selectedTab={selectedTab} value="system">
                <RoiFields
                    canModifyRoiTaxIncentive={canModifyRoiTaxIncentive}
                    clearErrors={clearErrors}
                    control={control}
                    isLocked={isLocked}
                    setValue={setValue}
                />
                <PPAFields
                    control={control}
                    currencyIso={currencyIso}
                    currencySymbol={currencySymbol}
                    isLocked={isLocked}
                />
                <ModifyCalculationField
                    control={control}
                    isLocked={isLocked}
                    nextRateData={nextRateData}
                />
                <DiscountRateField
                    canModify={true}
                    control={control}
                    isLocked={isLocked}
                />
                <OpexFields
                    canModify={true}
                    control={control}
                    currencyIso={currencyIso}
                    currencyLocale={currencyLocale}
                    currencySymbol={currencySymbol}
                    isLocked={isLocked}
                    setValue={setValue}
                    watch={watch}
                />
            </TabPanel>

            <TabPanel
                key="tab-considerations"
                selectedTab={selectedTab}
                value="considerations"
            >
                <ConsiderationsFields control={control} isLocked={isLocked} />
            </TabPanel>

            <BottomActions>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                        gap: '16px',
                        justifyContent: { md: 'right', xs: 'center' },
                        pt: 2,
                        width: '100%',
                    }}
                >
                    <Button
                        color="secondary"
                        onClick={() => handleClickCancel()}
                        sx={{
                            order: { md: 1, xs: 2 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        variant="text"
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        disabled={isLocked}
                        onClick={handleSubmit(handleOnSave)}
                        sx={{
                            order: { md: 2, xs: 1 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        type="submit"
                        variant="outlined"
                    >
                        {isSaving ? t('Saving').concat('...') : t('Save')}
                    </Button>
                </Box>
            </BottomActions>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    selectedPrevRate: AnalysisVsGenerationselectors.getSelectedPrevRateData,
});

const mapDispatchToProps = (dispatch) => ({
    save: (id, valuesForm) => dispatch(actions.save(id, valuesForm)),
});

FormContainer.propTypes = {
    canModifyCurrencyType: PropTypes.bool,
    canModifyExchangeType: PropTypes.bool,
    canModifyPlans: PropTypes.bool,
    canModifyRoiTaxIncentive: PropTypes.bool,
    canModifyStockExchange: PropTypes.bool,
    currencies: PropTypes.array,
    customerMode: PropTypes.bool,
    handleClickCancel: PropTypes.func,
    initialValues: PropTypes.object,
    isLocked: PropTypes.bool,
    isSaving: PropTypes.bool,
    nextRateData: PropTypes.object,
    plans: PropTypes.array,
    proposalId: PropTypes.string,
    save: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
