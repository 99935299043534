import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, DataGrid, IconButton, Typography } from 'sunwise-ui';

import { CustomPagination } from 'common/components';
import { useBreakpoint } from 'common/hooks';

const ListItems = ({ handleClickUpdate, isFetching, items }) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    let columns = [
        {
            field: 'name',
            flex: 1,
            headerName: t('Name'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography fontWeight="bold" variant="caption">
                        {row.name}
                    </Typography>
                );
            },
        },
        {
            field: 'business_name',
            flex: 1,
            headerName: t('Business name'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography fontWeight="bold" variant="caption">
                        {row.business_name}
                    </Typography>
                );
            },
        },
        {
            field: 'rfc',
            flex: 1,
            headerName: t('Tax ID'),
            renderCell: (params) => {
                const { row } = params;
                return <Typography variant="caption">{row.rfc}</Typography>;
            },
        },

        {
            field: 'web_page',
            flex: 1,
            headerName: t('Web page'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">{row.web_page}</Typography>
                );
            },
        },
        {
            field: 'contact_email',
            flex: 1,
            headerName: t('Email'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row.contact_email}
                    </Typography>
                );
            },
        },
    ];

    if (breakpoint === 'xs') {
        columns = [
            {
                field: 'name',
                flex: 1,
                headerName: t('Name'),
                renderCell: (params) => {
                    const { row } = params;
                    return (
                        <Typography fontWeight="bold" variant="caption">
                            {row.name}
                        </Typography>
                    );
                },
            },
        ];
    }

    columns = [
        ...columns,
        {
            align: 'right',
            field: '',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <IconButton
                        onClick={() => handleClickUpdate(row)}
                        size="small"
                    >
                        <EditIcon fontSize="small" />
                    </IconButton>
                );
            },
            sortable: false,
        },
    ];

    return (
        <Card>
            <Card.Body>
                <DataGrid
                    autoHeight
                    components={{
                        LoadingOverlay: LinearProgress,
                        Pagination: CustomPagination,
                    }}
                    columns={columns}
                    disableColumnMenu
                    disableSelectionOnClick
                    getRowHeight={() => 'auto'}
                    loading={isFetching}
                    pagination
                    rows={items}
                />
            </Card.Body>
        </Card>
    );
};

ListItems.propTypes = {
    handleClickUpdate: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
};

export default ListItems;
