import { getReports } from 'common/api/boty/reports';
import { getFiltersFromQuery } from 'common/utils/helpers';

import {
    FETCH_REPORTS,
    FETCH_REPORTS_FAILURE,
    FETCH_REPORTS_SUCCESS,
} from '../actionTypes';
import { DEFAULT_ALL_STATUS_KEY, FILTERS_KEYS } from '../constants';
import { actions } from '../reducer';

export default ({
        filterQuery,
        page,
        pageSize,
        replaceValues = true,
        status,
    }) =>
    (dispatch) => {
        const dataKey = status ?? DEFAULT_ALL_STATUS_KEY;

        dispatch(actions[FETCH_REPORTS]({ dataKey }));

        const filters = getFiltersFromQuery(
            Object.values(FILTERS_KEYS),
            filterQuery,
        );

        if (filters[FILTERS_KEYS.CUSTOM_RATE_ID])
            delete filters[FILTERS_KEYS.RATE_ID];
        else delete filters[FILTERS_KEYS.CUSTOM_RATE_ID];

        const filterData = {
            ...filters,
            [FILTERS_KEYS.REPORT_STATUS]:
                status ?? filters[FILTERS_KEYS.REPORT_STATUS],
            page,
            pageSize,
        };

        getReports(filterData)
            .then((response) => {
                const { reports, ...meta } = response?.data || {};

                dispatch(
                    actions[FETCH_REPORTS_SUCCESS]({
                        data: reports,
                        dataKey,
                        meta,
                        replaceValues,
                    }),
                );
            })
            .catch((errors) =>
                dispatch(actions[FETCH_REPORTS_FAILURE]({ dataKey, errors })),
            );
    };
