import React from 'react';

import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import es from 'date-fns/locale/es';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formatDate, isValidDate, parseDate } from 'common/utils/dates';
import { getTimezoneUserSettings } from 'common/utils/helpers/multiregion';

const ReactHookFormTimePicker = ({
    control,
    disableFuture,
    disablePast,
    disabled,
    format = 'HH:mm',
    fullWidth = true,
    inputFormat = 'HH:mm',
    label,
    maxTime,
    minTime,
    name,
    onChange,
    placeholder,
    readOnly,
    sxInput = {},
    timeSteps = { hours: 1, minutes: 1 },
    variant = 'outlined',
    views,
}) => {
    const { t } = useTranslation();
    const onChangeAction = ({ date, inputFormat, onChange, onChangeField }) => {
        if (isValidDate(date)) {
            const formatedDate = formatDate(date, inputFormat);
            onChangeField(formatedDate);
            if (onChange) onChange(formatedDate);
        } else if (date === null) {
            onChangeField(null);
            if (onChange) onChange(null);
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { name, onChange: onChangeField, value },
                fieldState: { error },
            }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
                    <MobileTimePicker
                        disabled={disabled}
                        disableFuture={disableFuture}
                        disablePast={disablePast}
                        format={format}
                        inputFormat={inputFormat}
                        label={label}
                        localeText={{
                            toolbarTitle: label,
                            cancelButtonLabel: t('Cancel'),
                        }}
                        maxTime={maxTime}
                        minTime={minTime}
                        name={name}
                        timeSteps={timeSteps}
                        onChange={(date) =>
                            onChangeAction({
                                date,
                                inputFormat,
                                onChange,
                                onChangeField,
                            })
                        }
                        readOnly={readOnly}
                        slotProps={{
                            textField: {
                                'data-testid': name,
                                error: Boolean(error),
                                fullWidth,
                                helperText: error?.message,
                                placeholder,
                                sx: sxInput,
                                variant,
                            },
                            toolbar: {
                                sx: {
                                    '& > .MuiPickersToolbar-content': { m: 0 },
                                },
                            },
                        }}
                        timezone={getTimezoneUserSettings()}
                        value={value ? parseDate(value, inputFormat) : null}
                        views={views}
                    />
                </LocalizationProvider>
            )}
        />
    );
};

ReactHookFormTimePicker.propTypes = {
    control: PropTypes.object.isRequired,
    disableFuture: PropTypes.bool,
    disablePast: PropTypes.bool,
    disabled: PropTypes.bool,
    format: PropTypes.string,
    fullWidth: PropTypes.bool,
    inputFormat: PropTypes.string,
    label: PropTypes.string,
    maxTime: PropTypes.instanceOf(Date),
    minTime: PropTypes.instanceOf(Date),
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    sxInput: PropTypes.object,
    variant: PropTypes.string,
    views: PropTypes.arrayOf(PropTypes.oneOf(['hours', 'minutes'])),
    timeSteps: PropTypes.object,
};

export default ReactHookFormTimePicker;
