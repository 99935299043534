import React from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Grow, Popper } from '@mui/material';
import PropTypes from 'prop-types';
import {
    Button,
    ButtonGroup,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
} from 'sunwise-ui';

const DropdownButton = ({
    dataTestId = 'dropdown-button',
    options,
    variant = 'contained',
    ...rest
}) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    if (!options || !options.length) return null;

    const handleToggleMenu = () => setOpen((prevOpen) => !prevOpen);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target))
            return;
        setOpen(false);
    };

    const firstOption = options[0];

    return (
        <>
            <ButtonGroup
                ref={anchorRef}
                size="small"
                variant={variant}
                {...rest}
            >
                <Button
                    data-testid={`${dataTestId}-main-option`}
                    onClick={firstOption.onClick}
                    startIcon={firstOption.icon}
                >
                    {firstOption.label}
                </Button>

                <Button
                    data-testid={`${dataTestId}-toggle-menu`}
                    onClick={handleToggleMenu}
                    size="small"
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>

            <Popper
                anchorEl={anchorRef.current}
                disablePortal
                open={open}
                role={undefined}
                sx={{ zIndex: 1 }}
                transition
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            data-testid={`${dataTestId}-option-${index}`}
                                            key={option.label}
                                            onClick={option.onClick}
                                            sx={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                gap: 1,
                                            }}
                                        >
                                            {option.icon}
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

DropdownButton.propTypes = {
    dataTestId: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.node,
            label: PropTypes.string,
            onClick: PropTypes.func,
        }),
    ),
    variant: PropTypes.string,
};

export default DropdownButton;
