import React, { useEffect } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Card, Grid } from 'sunwise-ui';

import { ModalDialog, TitleWithDetail } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as projectSelectors from '../project/selectors';

import * as actions from './actions';
import ConsumptionsResume from './components/ConsumptionsResume';
import ProjectLocation from './components/ProjectLocation';
import ProposalsTable from './components/ProposalsTable';
import ReportsTable from './components/ReportsTable';
import SummariesTable from './components/SummariesTable';
import * as selectors from './selectors';

const Container = ({
    fetchProposals,
    fetchReports,
    fetchSummaries,
    handleCloseModal,
    initialize,
    isFetchingProjectDetails,
    isFetchingProjectLocation,
    isFetchingProposals,
    isFetchingReports,
    isFetchingSummaries,
    isInitializing,
    isOpenModal,
    projectDetails,
    projectId,
    projectLocation,
    proposalsData,
    proposalsPagination,
    reportsData,
    reportsPagination,
    reset,
    setCurrentProjectId,
    summariesData,
    summariesPagination,
}) => {
    const { t } = useTranslation();

    useEffect(() => () => reset(), []);

    const callback = () => {
        handleCloseModal();
        setCurrentProjectId(null);
    };

    const { contact } = projectDetails;

    const renderContent = () => {
        if (isFetchingProjectDetails)
            return (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            );
        return (
            <Box>
                <Grid container>
                    <ConsumptionsResume isFetching={isInitializing} />
                    <ProjectLocation
                        hasLocation={projectDetails?.has_location}
                        isFetching={isFetchingProjectLocation || isInitializing}
                        location={projectLocation?.position}
                    />
                </Grid>
                <Grid container mt={2}>
                    <Grid size={{ lg: 6, md: 9, xs: 18 }}>
                        <Box mb={2}>
                            <TitleWithDetail variant="body2">
                                {t('Proposal', { count: 2 })}
                            </TitleWithDetail>
                        </Box>
                        <ProposalsTable
                            filterItems={fetchProposals}
                            isFetching={isFetchingProposals || isInitializing}
                            items={proposalsData}
                            paginationData={proposalsPagination}
                            projectId={projectId}
                        />
                    </Grid>
                    <Grid size={{ lg: 6, md: 9, xs: 18 }}>
                        <Box mb={2}>
                            <TitleWithDetail variant="body2">
                                {t('Summary', { count: 2 })}
                            </TitleWithDetail>
                        </Box>
                        <SummariesTable
                            filterItems={fetchSummaries}
                            isFetching={isFetchingSummaries || isInitializing}
                            items={summariesData}
                            paginationData={summariesPagination}
                            projectId={projectId}
                        />
                    </Grid>
                    <Grid size={{ lg: 6, md: 9, xs: 18 }}>
                        <Box mb={2}>
                            <TitleWithDetail variant="body2">
                                {t('Report', { count: 2 })}
                            </TitleWithDetail>
                        </Box>
                        <ReportsTable
                            filterItems={fetchReports}
                            isFetching={isFetchingReports || isInitializing}
                            items={reportsData}
                            paginationData={reportsPagination}
                            projectId={projectId}
                        />
                    </Grid>
                </Grid>
            </Box>
        );
    };

    return (
        <ModalDialog
            footerComponent={() => (
                <Box display="flex" gap={2} justifyContent="flex-end" py={1}>
                    <Button
                        component={RouterLink}
                        onClick={() => callback()}
                        to={`/record/${contact?.id}?tab=projects&project=${projectId}&view=proposals&type=proposals`}
                        variant="outlined"
                        sx={{ textTransform: 'none' }}
                    >
                        {t('Go to project file')}
                    </Button>
                </Box>
            )}
            onClose={() => callback()}
            onEnter={() => initialize(projectId)}
            open={isOpenModal}
            title={projectDetails?.name}
        >
            <Card.Body>{renderContent()}</Card.Body>
        </ModalDialog>
    );
};

Container.propTypes = {
    fetchProposals: PropTypes.func,
    fetchReports: PropTypes.func,
    fetchSummaries: PropTypes.func,
    handleCloseModal: PropTypes.func,
    initialize: PropTypes.func,
    isFetchingProjectDetails: PropTypes.bool,
    isFetchingProjectLocation: PropTypes.bool,
    isFetchingProposals: PropTypes.bool,
    isFetchingReports: PropTypes.bool,
    isFetchingSummaries: PropTypes.bool,
    isInitializing: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    projectDetails: PropTypes.object,
    projectId: PropTypes.string,
    projectLocation: PropTypes.object,
    proposalsData: PropTypes.array,
    proposalsPagination: PropTypes.object,
    reportsData: PropTypes.array,
    reportsPagination: PropTypes.object,
    reset: PropTypes.func,
    setCurrentProjectId: PropTypes.func,
    summariesData: PropTypes.array,
    summariesPagination: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    isFetchingProjectDetails: projectSelectors.getIsFetchingProjectDetails,
    isFetchingProjectLocation: selectors.getIsFetching,
    isFetchingProposals: selectors.getIsFetchingProposals,
    isFetchingReports: selectors.getIsFetchingReports,
    isFetchingSummaries: selectors.getIsFetchingSummaries,
    isInitializing: selectors.getIsInitializing,
    isOpenModal: selectors.getIsOpenModal,
    projectDetails: projectSelectors.getProjectDetailsData,
    projectLocation: selectors.getLocationData,
    proposalsData: selectors.getProposalsData,
    proposalsPagination: selectors.getProposalsPagination,
    reportsData: selectors.getReportsData,
    reportsPagination: selectors.getReportsPagination,
    summariesData: selectors.getSummariesData,
    summariesPagination: selectors.getSummariesPagination,
});

const mapDispatchToProps = (dispatch) => ({
    initialize: (projectId) => dispatch(actions.initialFetching(projectId)),
    fetchProposals: (filterData) =>
        dispatch(actions.fetchProposals(filterData)),
    fetchReports: (filterData) => dispatch(actions.fetchReports(filterData)),
    fetchSummaries: (id) => dispatch(actions.fetchSummaries(id)),
    handleCloseModal: () => dispatch(actions.closeModal()),
    reset: () => dispatch(actions.reset()),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.EDIT_PROPOSAL_PREVIEW_PERMISSION),
)(Container);
