import { filterContacts } from 'common/api/v1/contacts';

import {
    FETCH_FILTER_CONTACTS,
    FETCH_FILTER_CONTACTS_FAILURE,
    FETCH_FILTER_CONTACTS_SUCCESS,
    SET_HAS_CONTACTS,
} from '../actionTypes';
import { actions } from '../reducer';

export default ({
        agentId,
        contactType,
        isInitializing = false,
        orderBy,
        page,
        pageSize,
        searchText,
        sortBy,
        status,
    } = {}) =>
    (dispatch) => {
        dispatch(actions[FETCH_FILTER_CONTACTS]());

        filterContacts({
            agentId,
            contactType,
            orderBy,
            page: page + 1,
            perPage: pageSize,
            searchText,
            sortBy,
            status,
        })
            .then((response) => {
                dispatch(
                    actions[SET_HAS_CONTACTS](
                        !isInitializing || response.data.data.length !== 0,
                    ),
                );
                dispatch(actions[FETCH_FILTER_CONTACTS_SUCCESS](response.data));
            })
            .catch((error) =>
                dispatch(actions[FETCH_FILTER_CONTACTS_FAILURE](error)),
            );
    };
