import { addAlliance } from 'common/api/v1/sunpay';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    SAVE_FINANCIERS_INSTALLER,
    SAVE_FINANCIERS_INSTALLER_FAILURE,
    SAVE_FINANCIERS_INSTALLER_SUCCESS,
} from '../actionTypes';
import { alliancesActions } from '../reducer';

export default (values, callback) => (dispatch) => {
    let newValues = {
        financier: values.financier,
    };

    dispatch(alliancesActions[SAVE_FINANCIERS_INSTALLER]());

    addAlliance(newValues)
        .then((response) => {
            dispatch(
                alliancesActions[SAVE_FINANCIERS_INSTALLER_SUCCESS](
                    response.data,
                ),
            );
            if (callback) callback();
        })
        .catch((error) => {
            dispatch(
                alliancesActions[SAVE_FINANCIERS_INSTALLER_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
