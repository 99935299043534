import React, { useEffect, useRef, useState } from 'react';

import { debounce, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Tab,
    Tabs,
} from 'sunwise-ui';

import { DEBOUNCE_TIME_1500 } from 'common/constants';

import { getFilterOptions, getNotificationTypesOptions } from '../helpers';

import BulkEditionMenu from './BulkEditionMenu';

const BulkOperationCard = ({
    checkedAll,
    checkedNotifications,
    fetchNotifications,
    filterData,
    handleClickBulkItems,
    notifications,
    setCheckedAll,
    setFilterData,
    updateNotificationsTemp,
}) => {
    const { t } = useTranslation();
    const [currentFilterData, setCurrentFilterData] = useState(filterData);

    useEffect(() => {
        if (!isEqual(currentFilterData, filterData)) {
            handleOnFilter(filterData);
            setCurrentFilterData(filterData);
        }
    }, [filterData]);

    const handleOnFilter = useRef(
        debounce((filter) => fetchNotifications(filter), DEBOUNCE_TIME_1500),
    ).current;

    const handleSelectFilterBy = (item) => {
        setFilterData({
            ...filterData,
            viewed: item.value,
            page: 1,
        });
    };
    const handleChangeCheckbox = (event) => {
        updateNotificationsTemp(null, null, event.target.checked);
        setCheckedAll(event.target.checked);
    };
    const handleChangeTab = (_, newValue) => {
        setFilterData({
            ...filterData,
            showMentions: newValue,
            page: 1,
        });
    };
    const handleSelectType = (item) => {
        setFilterData({
            ...filterData,
            type: item.value,
            page: 1,
        });
    };

    const hasCheckedNotifications = checkedNotifications.length > 0;
    const isImcomplete =
        hasCheckedNotifications &&
        checkedNotifications.length < notifications.length;

    return (
        <>
            <Grid
                alignItems="end"
                container
                sx={{ mb: { lg: 2, xs: 0 }, mt: 1 }}
            >
                <Grid
                    size={{ lg: hasCheckedNotifications ? 8 : 12, xs: 18 }}
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        mb: { xs: 1, md: 0 },
                        order: { xs: 4, lg: 1 },
                    }}
                >
                    {hasCheckedNotifications && (
                        <Checkbox
                            color="primary"
                            checked={checkedAll}
                            onChange={handleChangeCheckbox}
                            indeterminate={isImcomplete}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    )}
                    <Tabs
                        value={filterData?.showMentions}
                        onChange={handleChangeTab}
                    >
                        <Tab label={t('System')} value={false} />
                        <Tab label={t('Mentions')} value={true} />
                    </Tabs>
                </Grid>
                <Grid
                    size={{ lg: 3, md: hasCheckedNotifications ? 9 : 6, xs: 9 }}
                    sx={{ order: 2 }}
                >
                    <FormControl fullWidth size="small">
                        <InputLabel>{t('Type')}</InputLabel>
                        <Select
                            label={t('Type')}
                            onChange={(e) => handleSelectType(e.target)}
                            value={filterData?.type}
                            variant="standard"
                        >
                            {getNotificationTypesOptions().map(
                                ({ label, value }) => (
                                    <MenuItem
                                        key={`type-select-${value}-${label}`}
                                        value={value}
                                    >
                                        {label}
                                    </MenuItem>
                                ),
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid
                    size={{ lg: 3, md: hasCheckedNotifications ? 9 : 6, xs: 9 }}
                    sx={{ order: 3 }}
                >
                    <FormControl fullWidth size="small">
                        <InputLabel>{t('Filter by')}</InputLabel>
                        <Select
                            label={t('Filter by')}
                            onChange={(e) => handleSelectFilterBy(e.target)}
                            value={filterData?.viewed}
                            variant="standard"
                        >
                            {getFilterOptions().map(({ label, value }) => (
                                <MenuItem
                                    key={`filter-select-${value}-${label}`}
                                    value={value}
                                >
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid
                    hidden={!hasCheckedNotifications}
                    size={{ lg: 4, md: 6, xs: 18 }}
                    sx={{ order: { lg: 4, xs: 3 } }}
                >
                    <BulkEditionMenu
                        checkedNotifications={checkedNotifications}
                        filterData={filterData}
                        handleClickBulkItems={handleClickBulkItems}
                        handleClickCancel={() => {
                            updateNotificationsTemp(null, null, false);
                            setCheckedAll(false);
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

BulkOperationCard.propTypes = {
    checkedAll: PropTypes.bool,
    checkedNotifications: PropTypes.array,
    fetchNotifications: PropTypes.func,
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    notifications: PropTypes.array,
    setCheckedAll: PropTypes.func,
    setFilterData: PropTypes.func,
    updateNotificationsTemp: PropTypes.func,
};

export default BulkOperationCard;
