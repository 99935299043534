import API from '../../botyAPI';

const ENTITY = 'reports';

export const getFilters = () => API.get(`/api/v1/${ENTITY}/filters/`);

export const getReports = ({
    agent_id,
    contact_id,
    customize_rate_id,
    dp1_id,
    dp2_id,
    page,
    pageSize,
    project_id,
    project_status_id,
    rate_id,
    report_status,
    resolution,
    zip_code,
}) =>
    API.get(`/api/v1/${ENTITY}/`, {
        params: {
            agent_id,
            contact_id,
            customize_rate_id,
            dp1_id,
            dp2_id,
            page,
            page_size: pageSize,
            project_id,
            project_status_id,
            rate_id,
            report_status,
            resolution,
            zip_code,
        },
    });

export const syncReports = (asId) =>
    API.post(`/api/v1/${ENTITY}/sync-as-project/${asId}`);
