import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Skeleton, Typography } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { numberFormat } from 'common/utils/helpers';

import DropdownMenu from './DropdownMenu';

const StyledColumn = styled(Grid)(
    ({ theme }) => `
    background: ${theme.palette.mode === 'dark' ? '#2f3139' : '#eef4fa'};
`,
);

const SubtotalColumn = ({
    canModify,
    canModifyProduct,
    currencyIso,
    currencyLocale,
    handleClickDelete,
    handleRemove,
    index,
    isLoading,
    isLocked,
    isProductCosting,
    productItem,
    remove,
}) => {
    const { t } = useTranslation();

    if (!canModify) return null;

    return (
        <StyledColumn size={{ md: 4, xs: 18 }}>
            <Box
                sx={{
                    alignItems: { xs: 'start', md: 'end' },
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: { xs: 'center', md: 'space-between' },
                }}
            >
                <Box sx={{ m: 1, display: { xs: 'none', md: 'block' } }}>
                    <DropdownMenu
                        canModifyProduct={canModifyProduct}
                        handleClickDelete={() =>
                            handleClickDelete(
                                remove,
                                handleRemove,
                                index,
                                productItem,
                            )
                        }
                        isLocked={isLocked}
                    />
                </Box>
                {isProductCosting && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: { xs: 'left', md: 'right' },
                            p: 2,
                            pt: 0,
                            width: '100%',
                        }}
                    >
                        <Typography variant="body2">{t('Subtotal')}</Typography>
                        {isLoading ? (
                            <Skeleton
                                variant="text"
                                sx={{
                                    fontSize: '1rem',
                                    width: { xs: 200, lg: '100%' },
                                }}
                            />
                        ) : (
                            <Typography variant="body2" fontWeight="bold">
                                {numberFormat(productItem.final_cost, {
                                    currency: currencyIso,
                                    locale: currencyLocale,
                                    style: 'currency',
                                })}
                            </Typography>
                        )}
                    </Box>
                )}
            </Box>
        </StyledColumn>
    );
};

SubtotalColumn.propTypes = {
    canModify: PropTypes.bool,
    canModifyProduct: PropTypes.bool,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    handleClickDelete: PropTypes.func,
    handleRemove: PropTypes.func,
    index: PropTypes.number,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isProductCosting: PropTypes.bool,
    productItem: PropTypes.object,
    remove: PropTypes.func,
};

export default withPermissions(PERMISSION_LIST.SEE_SUBTOTAL_PRODUCTS)(
    SubtotalColumn,
);
