import isEmpty from 'lodash/isEmpty';

import { formatDate, parseDate, subDate } from 'common/utils/dates';

export const getElectricBillPerMonths = ({ isBimonthly = false, items }) => {
    if (!items || isEmpty(items)) return [];

    const daysToSubstract = isBimonthly ? 30 : 15;
    return items.map((item) => {
        const label = formatDate(
            subDate(parseDate(item.final_date, 'yyyy-MM-dd'), {
                days: Math.floor(daysToSubstract),
            }),
            'MMM yy',
        );

        return {
            id: Math.random(),
            label,
            url: item.file_url,
            urlType: item.url_type || 1,
        };
    });
};
