import AcUnitIcon from '@mui/icons-material/AcUnit';
import BlenderIcon from '@mui/icons-material/Blender';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import CoffeeMakerIcon from '@mui/icons-material/CoffeeMaker';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import ElectricBikeIcon from '@mui/icons-material/ElectricBike';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import FactoryIcon from '@mui/icons-material/Factory';
import KitchenIcon from '@mui/icons-material/Kitchen';
import LaptopIcon from '@mui/icons-material/Laptop';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';
import MicrowaveIcon from '@mui/icons-material/Microwave';
import PowerIcon from '@mui/icons-material/Power';
import PrintIcon from '@mui/icons-material/Print';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import SpeakerIcon from '@mui/icons-material/Speaker';
import TvIcon from '@mui/icons-material/Tv';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import WindPowerIcon from '@mui/icons-material/WindPower';

export const NAME = 'project-device-based-consumption';

export const ORIGINAL_SERIE_NAME = '__original__';

export const BACKUP_VISIBILITY = {
    ALL: 'all',
    NO_BACKUP: 'no-backup',
    ONLY_BACKUP: 'backup',
};

export const TABLE_SECTIONS = {
    ACTIONS: 'actions',
    BACKUP: 'backup',
    DEVICE: 'device',
    ELECTRIC_PARAMETERS: 'electric_parameters',
    TIME: 'time',
};

export const COLORS = {
    [TABLE_SECTIONS.ACTIONS]: '#A1887F',
    [TABLE_SECTIONS.BACKUP]: '#2196F3',
    [TABLE_SECTIONS.DEVICE]: '#FFC107',
    [TABLE_SECTIONS.ELECTRIC_PARAMETERS]: '#FF5722',
    [TABLE_SECTIONS.TIME]: '#4CAF50',
};

export const COLORS_CHART = {
    ORIGINAL: '#BFBBBD',
    DEVICES: ['#005bb0', '#d68a4b', '#cc0052', '#44992f'],
};

export const DEVICES = {
    AIR_CONDITIONER: 0,
    FRIDGE: 1,
    HEATER: 2,
    LIGHT: 3,
    TV: 4,
    WASHING_MACHINE: 5,
    DISHWASHER: 6,
    MICROWAVE: 7,
    VACUUM_CLEANER: 8,
    COMPUTER: 9,
    LAPTOP: 10,
    PRINTER: 11,
    COFFEE_MACHINE: 12,
    ELECTRIC_CAR: 13,
    OVEN: 14,
    FAN: 15,
    WATER_HEATER: 16,
    INDUSTRIAL_MACHINE: 17,
    FREEZER: 18,
    LIGHT_ELECTRIC_VEHICLE: 19,
    PHONE_CHARGER: 20,
    BLENDER: 21,
    SPEAKERS: 22,
    OTHER: -1,
};

export const DEVICE_CONFIG = {
    [DEVICES.AIR_CONDITIONER]: {
        name: 'Air conditioner',
        Icon: AcUnitIcon,
    },
    [DEVICES.FRIDGE]: {
        name: 'Fridge',
        Icon: KitchenIcon,
    },
    [DEVICES.HEATER]: {
        name: 'Heater',
        Icon: WhatshotIcon,
    },
    [DEVICES.LIGHT]: {
        name: 'Light',
        Icon: LightbulbIcon,
    },
    [DEVICES.TV]: {
        name: 'TV',
        Icon: TvIcon,
    },
    [DEVICES.WASHING_MACHINE]: {
        name: 'Washing machine',
        Icon: LocalLaundryServiceIcon,
    },
    [DEVICES.DISHWASHER]: {
        name: 'Dishwasher',
        Icon: RestaurantIcon,
    },
    [DEVICES.MICROWAVE]: {
        name: 'Microwave',
        Icon: MicrowaveIcon,
    },
    [DEVICES.VACUUM_CLEANER]: {
        name: 'Vacuum cleaner',
        Icon: CleaningServicesIcon,
    },
    [DEVICES.COMPUTER]: {
        name: 'Computer',
        Icon: LaptopIcon,
    },
    [DEVICES.LAPTOP]: {
        name: 'Laptop',
        Icon: LaptopIcon,
    },
    [DEVICES.PRINTER]: {
        name: 'Printer',
        Icon: PrintIcon,
    },
    [DEVICES.COFFEE_MACHINE]: {
        name: 'Coffee machine',
        Icon: CoffeeMakerIcon,
    },
    [DEVICES.ELECTRIC_CAR]: {
        name: 'Electric car (charging)',
        Icon: ElectricCarIcon,
    },
    [DEVICES.OVEN]: {
        name: 'Oven',
        Icon: RestaurantIcon,
    },
    [DEVICES.FAN]: {
        name: 'Fan',
        Icon: WindPowerIcon,
    },
    [DEVICES.WATER_HEATER]: {
        name: 'Water heater',
        Icon: WhatshotIcon,
    },
    [DEVICES.INDUSTRIAL_MACHINE]: {
        name: 'Industrial machine',
        Icon: FactoryIcon,
    },
    [DEVICES.FREEZER]: {
        name: 'Freezer',
        Icon: KitchenIcon,
    },
    [DEVICES.LIGHT_ELECTRIC_VEHICLE]: {
        name: 'Light electric vehicle (LEV)',
        Icon: ElectricBikeIcon,
    },
    [DEVICES.PHONE_CHARGER]: {
        name: 'Phone charger',
        Icon: PowerIcon,
    },
    [DEVICES.BLENDER]: {
        name: 'Blender',
        Icon: BlenderIcon,
    },
    [DEVICES.SPEAKERS]: {
        name: 'Speakers',
        Icon: SpeakerIcon,
    },
    [DEVICES.OTHER]: {
        name: 'Other',
        Icon: DevicesOtherIcon,
    },
};
export const UNIT_KEYS = {
    BHP: 2,
    BTU: 3,
    HP: 4,
    TON: 1,
    W: 0,
    kW: 5,
};

// The base unit is Watts
export const UNITS = {
    [UNIT_KEYS.BHP]: { conversion: 756.04272922464, label: 'BHP' },
    [UNIT_KEYS.BTU]: { conversion: 0.29307107, label: 'BTU' },
    [UNIT_KEYS.HP]: { conversion: 745.699872, label: 'HP' },
    [UNIT_KEYS.TON]: { conversion: 3516.85284, label: 'Ton' },
    [UNIT_KEYS.W]: { conversion: 1, label: 'W' },
    [UNIT_KEYS.kW]: { conversion: 1000, label: 'kW' },
};

export const DEVICE_DEFAULT_VALUES = {
    [DEVICES.AIR_CONDITIONER]: {
        backup: false,
        device: DEVICES.AIR_CONDITIONER,
        device_name: 'Air Conditioner',
        effective_power_current: 15,
        end_time: '18:00',
        installed: false,
        months: [3, 4, 5, 6, 7, 8],
        power: 1,
        quantity: 1,
        soft_start: true,
        start_time: '09:00',
        startup_factor: 5,
        unit: UNIT_KEYS.TON,
        weekdays: [1, 2, 3, 4, 5],
    },
    [DEVICES.FRIDGE]: {
        backup: false,
        device: DEVICES.FRIDGE,
        device_name: 'Fridge',
        effective_power_current: 1.25,
        end_time: '23:59',
        installed: false,
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        power: 150,
        quantity: 1,
        soft_start: false,
        start_time: '00:00',
        startup_factor: 3,
        unit: UNIT_KEYS.W,
        weekdays: [0, 1, 2, 3, 4, 5, 6],
    },
    [DEVICES.HEATER]: {
        backup: false,
        device: DEVICES.HEATER,
        device_name: 'Heater',
        effective_power_current: 11.8,
        end_time: '22:00',
        installed: false,
        months: [10, 11, 0, 1, 2, 3],
        power: 1.5,
        quantity: 3,
        soft_start: false,
        start_time: '06:00',
        startup_factor: 2,
        unit: UNIT_KEYS.kW,
        weekdays: [0, 1, 2, 3, 4, 5, 6],
    },
    [DEVICES.LIGHT]: {
        backup: false,
        device: DEVICES.LIGHT,
        device_name: 'Light (LED)',
        effective_power_current: 0.08,
        end_time: '23:00',
        installed: false,
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        power: 10,
        quantity: 20,
        soft_start: true,
        start_time: '06:00',
        startup_factor: 1,
        unit: UNIT_KEYS.W,
        weekdays: [0, 1, 2, 3, 4, 5, 6],
    },
    [DEVICES.TV]: {
        backup: false,
        device: DEVICES.TV,
        device_name: 'TV',
        effective_power_current: 1,
        end_time: '23:00',
        installed: false,
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        power: 120,
        quantity: 2,
        soft_start: false,
        start_time: '18:00',
        startup_factor: 1,
        unit: UNIT_KEYS.W,
        weekdays: [0, 1, 2, 3, 4, 5, 6],
    },
    [DEVICES.WASHING_MACHINE]: {
        backup: false,
        device: DEVICES.WASHING_MACHINE,
        device_name: 'Washing Machine',
        effective_power_current: 4.16,
        end_time: '20:00',
        installed: false,
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        power: 500,
        quantity: 1,
        soft_start: true,
        start_time: '08:00',
        startup_factor: 3,
        unit: UNIT_KEYS.W,
        weekdays: [5, 6],
    },
    [DEVICES.DISHWASHER]: {
        backup: false,
        device: DEVICES.DISHWASHER,
        device_name: 'Dishwasher',
        effective_power_current: 10,
        end_time: '22:00',
        installed: false,
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        power: 1200,
        quantity: 1,
        soft_start: true,
        start_time: '18:00',
        startup_factor: 3,
        unit: UNIT_KEYS.W,
        weekdays: [0, 1, 2, 3, 4, 5, 6],
    },
    [DEVICES.MICROWAVE]: {
        backup: false,
        device: DEVICES.MICROWAVE,
        device_name: 'Microwave',
        effective_power_current: 8.3,
        end_time: '21:00',
        installed: false,
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        power: 1000,
        quantity: 1,
        soft_start: false,
        start_time: '07:00',
        startup_factor: 1,
        unit: UNIT_KEYS.W,
        weekdays: [0, 1, 2, 3, 4, 5, 6],
    },
    [DEVICES.VACUUM_CLEANER]: {
        backup: false,
        device: DEVICES.VACUUM_CLEANER,
        device_name: 'Vacuum Cleaner',
        effective_power_current: 10,
        end_time: '20:00',
        installed: false,
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        power: 1200,
        quantity: 1,
        soft_start: false,
        start_time: '08:00',
        startup_factor: 2,
        unit: UNIT_KEYS.W,
        weekdays: [1, 2, 3, 4, 5, 6],
    },
    [DEVICES.COMPUTER]: {
        backup: false,
        device: DEVICES.COMPUTER,
        device_name: 'Computer',
        effective_power_current: 1.7,
        end_time: '20:00',
        installed: false,
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        power: 200,
        quantity: 3,
        soft_start: false,
        start_time: '08:00',
        startup_factor: 1,
        unit: UNIT_KEYS.W,
        weekdays: [1, 2, 3, 4, 5, 6],
    },
    [DEVICES.LAPTOP]: {
        backup: false,
        device: DEVICES.LAPTOP,
        device_name: 'Laptop',
        effective_power_current: 0.42,
        end_time: '20:00',
        installed: false,
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        power: 50,
        quantity: 5,
        soft_start: false,
        start_time: '08:00',
        startup_factor: 1,
        unit: UNIT_KEYS.W,
        weekdays: [1, 2, 3, 4, 5, 6],
    },
    [DEVICES.PRINTER]: {
        backup: false,
        device: DEVICES.PRINTER,
        device_name: 'Printer',
        effective_power_current: 1.7,
        end_time: '20:00',
        installed: false,
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        power: 200,
        quantity: 2,
        soft_start: false,
        start_time: '08:00',
        startup_factor: 1,
        unit: UNIT_KEYS.W,
        weekdays: [1, 2, 3, 4, 5, 6],
    },
    [DEVICES.COFFEE_MACHINE]: {
        backup: false,
        device: DEVICES.COFFEE_MACHINE,
        device_name: 'Coffee Machine',
        effective_power_current: 6.67,
        end_time: '18:00',
        installed: false,
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        power: 800,
        quantity: 1,
        soft_start: false,
        start_time: '06:00',
        startup_factor: 1,
        unit: UNIT_KEYS.W,
        weekdays: [0, 1, 2, 3, 4, 5, 6],
    },
    [DEVICES.ELECTRIC_CAR]: {
        backup: false,
        device: DEVICES.ELECTRIC_CAR,
        device_name: 'Electric Car (Charging)',
        effective_power_current: 31.25,
        end_time: '22:00',
        installed: false,
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        power: 7.5,
        quantity: 1,
        soft_start: true,
        start_time: '18:00',
        startup_factor: 5,
        unit: UNIT_KEYS.kW,
        weekdays: [0, 1, 2, 3, 4, 5, 6],
    },
    [DEVICES.OVEN]: {
        backup: false,
        device: DEVICES.OVEN,
        device_name: 'Oven',
        effective_power_current: 16.67,
        end_time: '20:00',
        installed: false,
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        power: 2,
        quantity: 1,
        soft_start: false,
        start_time: '10:00',
        startup_factor: 1,
        unit: UNIT_KEYS.kW,
        weekdays: [0, 1, 2, 3, 4, 5, 6],
    },
    [DEVICES.FAN]: {
        backup: false,
        device: DEVICES.FAN,
        device_name: 'Fan',
        effective_power_current: 0.63,
        end_time: '22:00',
        installed: false,
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        power: 75,
        quantity: 3,
        soft_start: false,
        start_time: '08:00',
        startup_factor: 1,
        unit: UNIT_KEYS.W,
        weekdays: [0, 1, 2, 3, 4, 5, 6],
    },
    [DEVICES.WATER_HEATER]: {
        backup: false,
        device: DEVICES.WATER_HEATER,
        device_name: 'Water Heater',
        effective_power_current: 25,
        end_time: '23:00',
        installed: false,
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        power: 3,
        quantity: 1,
        soft_start: false,
        start_time: '06:00',
        startup_factor: 1,
        unit: UNIT_KEYS.kW,
        weekdays: [0, 1, 2, 3, 4, 5, 6],
    },
    [DEVICES.INDUSTRIAL_MACHINE]: {
        backup: false,
        device: DEVICES.INDUSTRIAL_MACHINE,
        device_name: 'Industrial Machine',
        effective_power_current: 105,
        end_time: '18:00',
        installed: false,
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        power: 50,
        quantity: 1,
        soft_start: true,
        start_time: '08:00',
        startup_factor: 5,
        unit: UNIT_KEYS.kW,
        weekdays: [1, 2, 3, 4, 5, 6],
    },
    [DEVICES.FREEZER]: {
        backup: false,
        device: DEVICES.FREEZER,
        device_name: 'Freezer',
        effective_power_current: 1.67,
        end_time: '23:59',
        installed: false,
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        power: 200,
        quantity: 1,
        soft_start: false,
        start_time: '00:00',
        startup_factor: 3,
        unit: UNIT_KEYS.W,
        weekdays: [0, 1, 2, 3, 4, 5, 6],
    },
    [DEVICES.LIGHT_ELECTRIC_VEHICLE]: {
        backup: false,
        device: DEVICES.LIGHT_ELECTRIC_VEHICLE,
        device_name: 'Light Electric Vehicle (LEV)',
        effective_power_current: 12.5,
        end_time: '22:00',
        installed: false,
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        power: 1500,
        quantity: 1,
        soft_start: true,
        start_time: '18:00',
        startup_factor: 3,
        unit: UNIT_KEYS.W,
        weekdays: [0, 1, 2, 3, 4, 5, 6],
    },
    [DEVICES.PHONE_CHARGER]: {
        backup: false,
        device: DEVICES.PHONE_CHARGER,
        device_name: 'Phone Charger',
        effective_power_current: 0.04,
        end_time: '23:59',
        installed: false,
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        power: 5,
        quantity: 2,
        soft_start: false,
        start_time: '00:00',
        startup_factor: 1,
        unit: UNIT_KEYS.W,
        weekdays: [0, 1, 2, 3, 4, 5, 6],
    },
    [DEVICES.BLENDER]: {
        backup: false,
        device: DEVICES.BLENDER,
        device_name: 'Blender',
        effective_power_current: 4.17,
        end_time: '23:59',
        installed: false,
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        power: 500,
        quantity: 1,
        soft_start: false,
        start_time: '00:00',
        startup_factor: 2,
        unit: UNIT_KEYS.W,
        weekdays: [1, 2, 3, 4, 5],
    },
    [DEVICES.SPEAKERS]: {
        backup: false,
        device: DEVICES.SPEAKERS,
        device_name: 'Speakers',
        effective_power_current: 0.42,
        end_time: '23:59',
        installed: false,
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        power: 50,
        quantity: 1,
        soft_start: false,
        start_time: '00:00',
        startup_factor: 1,
        unit: UNIT_KEYS.W,
        weekdays: [1, 2, 3, 4, 5],
    },
    [DEVICES.OTHER]: {
        backup: false,
        device: DEVICES.OTHER,
        device_name: 'Other',
        effective_power_current: 4.17,
        end_time: '23:59',
        installed: false,
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        power: 500,
        quantity: 1,
        soft_start: false,
        start_time: '00:00',
        startup_factor: 3,
        unit: UNIT_KEYS.W,
        weekdays: [1, 2, 3, 4, 5],
    },
};
