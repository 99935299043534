import React, { useContext, useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Card, Container as MaterialContainer, Grid } from 'sunwise-ui';

import { HeaderContainer, TitleIcon, TitleWithDetail } from 'common/components';
import { ReactHookFormSelect } from 'common/components/form/bootstrap';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import LoadingContext from 'common/modules/loadingView/Context';
import * as productDatasheetsActions from 'common/modules/productDatasheets/actions';
import RestrictedAccessLanding from 'common/modules/restrictedAccessLanding/Container';
import {
    getCountryCurrencyLocale,
    getIsMexicanAccount,
} from 'common/utils/helpers/session';

import * as actions from './actions';
import CardCalculationConditions from './components/CardCalculationConditions';
import CardDetails from './components/CardDetails';
import CardGroupList from './components/CardGroupList';
import CardRangeDetails from './components/CardRangeDetails';
import * as selectors from './selectors';

const FormContainer = ({
    canModify,
    canView,
    datashetInverter,
    datashetPanel,
    fetchPoliticalDivisions,
    fetchPoliticalDivisionsByParent,
    getDatasheetInverter,
    getDatasheetPanel,
    getInverters,
    getPanels,
    handleClickDownloadDatasheet,
    initialValues,
    inverters,
    isFetchingCompatibility,
    isFetchingDatashetInverter,
    isFetchingDatashetPanel,
    isFetchingInverters,
    isFetchingPanels,
    isSavingItem,
    panelInverterRangeData,
    panels,
    politicalDivisionsByParentForSelect,
    politicalDivisionsForSelect,
    fetchCompatibilityData,
    save,
}) => {
    const { t } = useTranslation();
    const [selectedPanel, setSelectedPanel] = useState(null);
    const [selectedInverter, setSelectedInverter] = useState(null);
    const loadingContext = useContext(LoadingContext);

    const { control, reset, setValue, watch } = useForm({
        defaultValues: initialValues,
    });

    const formValues = watch();

    useEffect(() => {
        getPanels();
        getInverters();
        fetchPoliticalDivisions('dp1');
    }, []);

    useEffect(() => {
        reset(initialValues);
    }, [initialValues]);

    useEffect(() => {
        if (
            selectedPanel !== null &&
            selectedInverter !== null &&
            formValues.political_division2 &&
            formValues.political_division2 !== '' &&
            formValues.political_division2 !== t('Search')
        ) {
            fetchCompatibilityData(
                selectedPanel.value,
                selectedInverter.value,
                formValues.political_division1,
                formValues.political_division2,
                setValue,
            );
        }
    }, [selectedPanel, selectedInverter, formValues.political_division2]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isSavingItem)
            loadingContext.openLoading(
                t('Saving change', { count: 2 }).concat('...'),
            );
        else loadingContext.closeLoading();
    }, [isSavingItem]);

    const _onSelectPanel = (item) => {
        setSelectedPanel(item);

        if (item !== null) {
            getDatasheetPanel(item.value);
        }
    };

    const _onSelectInverter = (item) => {
        setSelectedInverter(item);

        if (item !== null) {
            getDatasheetInverter(item.value);
        }
    };

    const handleSaveValues = (oversize) => {
        save({ ...formValues, oversize }, setValue);
    };

    const showOversizeConfig =
        selectedPanel !== null &&
        selectedInverter !== null &&
        formValues.political_division1 &&
        formValues.political_division1 !== '' &&
        formValues.political_division1 !== t('Search') &&
        formValues.political_division2 &&
        formValues.political_division2 !== '' &&
        formValues.political_division2 !== t('Search');

    const countryCurrencyLocale = getCountryCurrencyLocale();
    const isMexicanAccount = getIsMexicanAccount();

    if (!canView) return null;

    return (
        <RestrictedAccessLanding
            addonName="Electrical-Compatibility"
            url="https://somos.sunwise.io/sunwise-sales"
        >
            <MaterialContainer maxWidth={false}>
                <HeaderContainer>
                    <Grid size={{ xs: 'grow' }}>
                        <TitleIcon title={t('Electrical compatibility')} />
                    </Grid>
                </HeaderContainer>
                <Grid container>
                    <Grid size={{ lg: 12, xs: 18 }}>
                        <Box>
                            <Card>
                                <Card.Header>
                                    <TitleWithDetail variant="body2">
                                        {isMexicanAccount
                                            ? t(
                                                  'Select the state and municipality',
                                              )
                                            : t(
                                                  'Select political division of order 1 and political division of order 2',
                                              )}
                                    </TitleWithDetail>
                                </Card.Header>
                                <Card.Body>
                                    <Grid
                                        container
                                        id="equipment-compatibility-political-divisions"
                                    >
                                        <Grid size={{ lg: 9, xs: 'grow' }}>
                                            <ReactHookFormSelect
                                                control={control}
                                                label={
                                                    isMexicanAccount
                                                        ? t('State')
                                                        : t(
                                                              'Political division of order 1',
                                                          )
                                                }
                                                name="political_division1"
                                                options={
                                                    politicalDivisionsForSelect
                                                }
                                                onChange={(e) => {
                                                    if (
                                                        e.target.value !== '' &&
                                                        e.target.value !==
                                                            'undefined'
                                                    )
                                                        fetchPoliticalDivisionsByParent(
                                                            e.target.value,
                                                        );
                                                }}
                                            />
                                        </Grid>

                                        <Grid size={{ lg: 9, xs: 'grow' }}>
                                            <ReactHookFormSelect
                                                control={control}
                                                disabled={
                                                    formValues.political_division1 ===
                                                        '' ||
                                                    typeof formValues.political_division1 ===
                                                        'undefined'
                                                }
                                                label={
                                                    isMexicanAccount
                                                        ? t('City')
                                                        : t(
                                                              'Political division of order 2',
                                                          )
                                                }
                                                name="political_division2"
                                                options={
                                                    politicalDivisionsByParentForSelect
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Card.Body>
                            </Card>
                        </Box>

                        <Grid container>
                            <Grid size={{ lg: 9, xs: 18 }}>
                                <Card id="equipment-compatibility-module-card">
                                    <Card.Header>
                                        <TitleWithDetail variant="body2">
                                            {t('Select a panel')}
                                        </TitleWithDetail>
                                    </Card.Header>

                                    <Card.Body>
                                        <CardGroupList
                                            equipments={panels}
                                            isFetching={isFetchingPanels}
                                            onSelectEquipment={_onSelectPanel}
                                            title={t('Select a panel')}
                                        />

                                        {selectedPanel !== null && (
                                            <CardDetails
                                                datasheet={datashetPanel}
                                                handleClickDownloadDatasheet={
                                                    handleClickDownloadDatasheet
                                                }
                                                selectedEquipment={
                                                    selectedPanel
                                                }
                                                title={t('Data sheet of panel')}
                                            />
                                        )}
                                    </Card.Body>
                                </Card>
                            </Grid>

                            <Grid size={{ lg: 9, xs: 18 }}>
                                <Card id="equipment-compatibility-inverter-card">
                                    <Card.Header>
                                        <TitleWithDetail variant="body2">
                                            {t('Select a inverter')}
                                        </TitleWithDetail>
                                    </Card.Header>

                                    <Card.Body>
                                        <CardGroupList
                                            equipments={inverters}
                                            isFetching={isFetchingInverters}
                                            onSelectEquipment={
                                                _onSelectInverter
                                            }
                                            title={t('Select a inverter')}
                                        />

                                        {selectedInverter !== null && (
                                            <CardDetails
                                                canModify={canModify}
                                                datasheet={datashetInverter}
                                                handleClickDownloadDatasheet={
                                                    handleClickDownloadDatasheet
                                                }
                                                onSaveOversize={
                                                    handleSaveValues
                                                }
                                                oversize={formValues.oversize}
                                                selectedEquipment={
                                                    selectedInverter
                                                }
                                                showOversize={
                                                    !isFetchingDatashetInverter &&
                                                    !isFetchingDatashetPanel &&
                                                    !isFetchingCompatibility &&
                                                    showOversizeConfig
                                                }
                                                title={t(
                                                    'Data sheet of inverter',
                                                )}
                                            />
                                        )}
                                    </Card.Body>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid size={{ lg: 6, xs: 18 }}>
                        {showOversizeConfig &&
                            panelInverterRangeData !== null && (
                                <Box>
                                    <CardCalculationConditions
                                        countryCurrencyLocale={
                                            countryCurrencyLocale
                                        }
                                        min={panelInverterRangeData.temp_min}
                                        max={panelInverterRangeData.temp_max}
                                    />

                                    <CardRangeDetails
                                        countryCurrencyLocale={
                                            countryCurrencyLocale
                                        }
                                        panelInverterRangeData={
                                            panelInverterRangeData
                                        }
                                    />
                                </Box>
                            )}
                    </Grid>
                </Grid>
            </MaterialContainer>
        </RestrictedAccessLanding>
    );
};

const mapStateToProps = createStructuredSelector({
    datashetInverter: selectors.getDatasheetInverterData,
    datashetPanel: selectors.getDatasheetPanelData,
    initialValues: selectors.getInitialValues,
    inverters: selectors.getDataFetchInverters,
    isFetchingDatashetInverter: selectors.getIsFetchingDatasheetInverter,
    isFetchingDatashetPanel: selectors.getIsFetchingDatasheetPanel,
    isFetchingInverters: selectors.getIsFetchingInverters,
    isFetchingPanels: selectors.getIsFetchingPanels,
    isSavingItem: selectors.getIsSavingItem,
    isFetchingCompatibility: selectors.getIsFetchingPanelInverterRange,
    panelInverterRangeData: selectors.getPanelInverterRangeData,
    panels: selectors.getDataFetchPanels,
    politicalDivisionsByParentForSelect:
        selectors.getPoliticalDivisionsByParentForSelect,
    politicalDivisionsForSelect: selectors.getPoliticalDivisionsForSelect,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCompatibilityData: (
        selectedPanel,
        selectedInverter,
        political_division1,
        political_division2,
        setValue,
    ) =>
        dispatch(
            actions.fetchCompatibilityData(
                selectedPanel,
                selectedInverter,
                political_division1,
                political_division2,
                setValue,
            ),
        ),
    fetchPoliticalDivisions: (str) =>
        dispatch(actions.fetchPoliticalDivisions(str)),
    fetchPoliticalDivisionsByParent: (str) =>
        dispatch(actions.fetchPoliticalDivisionsByParent(str)),

    getDatasheetInverter: (id) => dispatch(actions.fetchDatasheetInverter(id)),
    getDatasheetPanel: (id) => dispatch(actions.fetchDatasheetPanel(id)),
    getInverters: () => dispatch(actions.fetchInverters()),
    getPanels: () => dispatch(actions.fetchPanels()),
    handleClickDownloadDatasheet: (datasheet, openLoading, closeLoading) =>
        dispatch(
            productDatasheetsActions.downloadDatasheet(
                datasheet,
                openLoading,
                closeLoading,
            ),
        ),
    save: (formValues, setValue) =>
        dispatch(actions.save(formValues, setValue)),
});

FormContainer.propTypes = {
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    datashetInverter: PropTypes.object,
    datashetPanel: PropTypes.object,
    fetchCompatibilityData: PropTypes.func,
    fetchPoliticalDivisions: PropTypes.func,
    fetchPoliticalDivisionsByParent: PropTypes.func,
    getDatasheetInverter: PropTypes.func,
    getDatasheetPanel: PropTypes.func,
    getInverters: PropTypes.func,
    getPanels: PropTypes.func,
    handleClickDownloadDatasheet: PropTypes.func,
    initialValues: PropTypes.object,
    inverters: PropTypes.array,
    isFetchingCompatibility: PropTypes.bool,
    isFetchingDatashetInverter: PropTypes.bool,
    isFetchingDatashetPanel: PropTypes.bool,
    isFetchingInverters: PropTypes.bool,
    isFetchingPanels: PropTypes.bool,
    isSavingItem: PropTypes.bool,
    panelInverterRangeData: PropTypes.object,
    panels: PropTypes.array,
    politicalDivisionsByParentForSelect: PropTypes.array,
    politicalDivisionsForSelect: PropTypes.array,
    save: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.EQUIPMENT_COMPATIBILITY_PERMISSION),
)(FormContainer);
