import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Card } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import useBreakpoint from 'common/hooks/useBreakpoint';
import RestrictedAccessLanding from 'common/modules/restrictedAccessLanding/Container';

import afterSalesMonitoring from '../afterSalesMonitoring';
import afterSalesSettings from '../afterSalesSettings';
import * as afterSalesSettingsSelectors from '../afterSalesSettings/selectors';

import Settings from './components/Settings';

const Container = ({
    afterSalesSettingsDataId,
    canDelete,
    canModify,
    canModifyAdvancedFeatures,
    canViewAdvancedFeatures,
    fetchAfterSalesSettings,
    selectedProjectId,
}) => {
    useEffect(() => {
        fetchAfterSalesSettings(selectedProjectId);
    }, [selectedProjectId]);

    if (!afterSalesSettingsDataId)
        return (
            <afterSalesSettings.Container
                canModify={canModify}
                canModifyAdvancedFeatures={canModifyAdvancedFeatures}
                canViewAdvancedFeatures={canViewAdvancedFeatures}
                selectedProjectId={selectedProjectId}
            />
        );

    return (
        <Card>
            <Card.Body>
                <Settings
                    canDelete={canDelete}
                    canModify={canModify}
                    canModifyAdvancedFeatures={canModifyAdvancedFeatures}
                    canViewAdvancedFeatures={canViewAdvancedFeatures}
                    selectedProjectId={selectedProjectId}
                />

                <Box mt={2}>
                    <afterSalesMonitoring.Container
                        canModifyAdvancedFeatures={canModifyAdvancedFeatures}
                        canViewAdvancedFeatures={canViewAdvancedFeatures}
                        selectedProjectId={selectedProjectId}
                    />
                </Box>
            </Card.Body>
        </Card>
    );
};

const mapStateToProps = createStructuredSelector({
    afterSalesSettingsDataId:
        afterSalesSettingsSelectors.getAfterSalesSettingsDataId,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAfterSalesSettings: (projectId) =>
        dispatch(afterSalesSettings.actions.fetchAfterSalesSettings(projectId)),
});

Container.propTypes = {
    afterSalesSettingsDataId: PropTypes.string,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    canModifyAdvancedFeatures: PropTypes.bool,
    canViewAdvancedFeatures: PropTypes.bool,
    fetchAfterSalesSettings: PropTypes.func,
    selectedProjectId: PropTypes.string,
};

const RestrictedContainer = ({ getPermissionsByCode, ...props }) => {
    const [height, setHeight] = useState(3050);

    const breakpoint = useBreakpoint();

    const { canDelete, canModify, canView } = getPermissionsByCode(
        PERMISSION_LIST.MONITORING_PERMISSION,
    );

    const {
        canModify: canModifyAdvancedFeatures,
        canView: canViewAdvancedFeatures,
    } = getPermissionsByCode(PERMISSION_LIST.FUNNEL_REPORT_PERMISSION);

    useEffect(() => {
        switch (breakpoint) {
            case 'xs':
                setHeight(4390);
                break;
            case 'sm':
                setHeight(3700);
                break;
            case 'md':
                setHeight(2650);
                break;
            default:
                setHeight(3050);
                break;
        }
    }, [breakpoint]);

    if (!canView) return null;

    return (
        <RestrictedAccessLanding
            addonName="Monitoring-System,Sunwise-After-Sales"
            minHeight={height}
            url="https://somos.sunwise.io/monitoreo-postventa"
        >
            <Container
                {...props}
                canDelete={canDelete}
                canModify={canModify}
                canModifyAdvancedFeatures={canModifyAdvancedFeatures}
                canViewAdvancedFeatures={canViewAdvancedFeatures}
            />
        </RestrictedAccessLanding>
    );
};

RestrictedContainer.propTypes = {
    getPermissionsByCode: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions([
        PERMISSION_LIST.MONITORING_PERMISSION,
        PERMISSION_LIST.FUNNEL_REPORT_PERMISSION,
    ]),
)(RestrictedContainer);
