import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from 'sunwise-ui';

import AccordionCard from 'common/components/accordions/AccordionCard';
import { SUPPORTED_LANGUAGES } from 'common/constants/multiregion';

import { getSxCard } from '../helpers';

const BenefitsCardsBundle = ({ selectedTheme }) => {
    const { i18n, t } = useTranslation();
    const isMx = i18n.language === SUPPORTED_LANGUAGES.es;
    const sxExpandMoreIcon = {
        color: selectedTheme === 'dark' ? '#000' : '',
    };
    const sxTypography = {
        color: selectedTheme === 'dark' ? '#000' : '#ffffff',
    };

    return (
        <Grid container mt={3}>
            <Grid size={18}>
                <AccordionCard
                    customTitle={
                        <Typography
                            variant="body1"
                            fontWeight={700}
                            sx={sxTypography}
                        >
                            {t('Included features')}
                        </Typography>
                    }
                    sxAccordionSummary={getSxCard()}
                    sxCard={{ mt: 2 }}
                    sxExpandMoreIcon={sxExpandMoreIcon}
                >
                    <Box
                        textAlign="center"
                        sx={{ display: { xs: 'block', sm: 'none' } }}
                    >
                        <img
                            src={
                                isMx
                                    ? 'https://cdn.prod.website-files.com/5ee80e144bcecc441f0c29be/67e188f291b3a52e0e4fdd37_Features_Bundle_Movil_Esp.svg'
                                    : 'https://cdn.prod.website-files.com/5ee80e144bcecc441f0c29be/67e18dcdfbb430afa133bf85_Features_Bundle_Movil_Eng.svg'
                            }
                        />
                    </Box>
                    <Box
                        textAlign="center"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                        <img
                            src={
                                isMx
                                    ? 'https://cdn.prod.website-files.com/5ee80e144bcecc441f0c29be/67e18dce25cd86fb4d23c831_Features_Bundle_Esp.svg'
                                    : 'https://cdn.prod.website-files.com/5ee80e144bcecc441f0c29be/67e18dcd4bcbd5b3085b5c54_Features_Bundle_Eng.svg'
                            }
                        />
                    </Box>
                </AccordionCard>
            </Grid>
        </Grid>
    );
};

BenefitsCardsBundle.propTypes = {
    selectedTheme: PropTypes.string,
};

export default BenefitsCardsBundle;
