import React, { useRef, useState } from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Container as MaterialContainer, Grid } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import TemporaryDrawer from 'common/components/TemporaryDrawer';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import RestrictedAccessLanding from 'common/modules/restrictedAccessLanding/Container';
import { formatDate, subDate } from 'common/utils/dates';

import * as projectCommercialOfferTableActions from '../projectCommercialOfferTable/actions';
import FileReview from '../projectCommercialOfferTable/components/FileReview';
import RequestSideCard from '../projectCommercialOfferTable/components/requestSideCard/Container';

import * as actions from './actions';
import ColumnsContainer from './components/ColumnsContainer';
import Filters from './components/Filters';

const Container = ({ cancelCreditApp, canModify, fetchFunnelProjects }) => {
    const { t } = useTranslation();
    const requestDrawerRef = useRef(null);
    const [creditItemSelected, setSelectedCreditItem] = useState({});
    const [selectedFile, setSelectedFile] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [projectId, setProjectId] = useState(null);

    const initialDate = formatDate(
        subDate(new Date(), { years: 1 }),
        'dd/MM/yyyy',
    );
    const finalDate = formatDate(new Date(), 'dd/MM/yyyy');
    const [filterQuery, setFilterQuery] = useState({
        final_date: finalDate,
        financier: '',
        initial_date: initialDate,
        search: '',
        status_filter: '',
        user: '',
    });

    const handleToggleDrawer = (value) => {
        if (requestDrawerRef.current) {
            const { handleOpen } = requestDrawerRef.current;
            handleOpen(value);
        }
        if (!value) setSelectedCreditItem({});
        return;
    };

    const getIsOpenDrawer = () => {
        if (requestDrawerRef.current)
            return requestDrawerRef.current.getIsOpen();
        return false;
    };

    const onClickStatus = (file) => {
        setSelectedFile(file);
        setShowModal(true);
    };

    const handleCancelCreditApp = () => {
        cancelCreditApp(creditItemSelected.offerId, () => {
            fetchFunnelProjects(filterQuery);
            handleToggleDrawer(false);
        });
    };

    const financierInstaller = get(
        creditItemSelected,
        'financier_installer',
        {},
    );
    const showChat = get(financierInstaller, 'financier.chat_documents', false);

    return (
        <RestrictedAccessLanding
            addonName="Financing-Funnel"
            url="https://somos.sunwise.io/sunwise-sales"
        >
            <MaterialContainer maxWidth={false}>
                <HeaderContainer mbDivider="8px">
                    <Grid size={{ xs: 'grow' }}>
                        <TitleIcon
                            fontWeight="bold"
                            title={t('Financing funnel')}
                        />
                    </Grid>
                </HeaderContainer>
                <Filters
                    filterQuery={filterQuery}
                    setFilterQuery={setFilterQuery}
                />
                <ColumnsContainer
                    canModify={canModify}
                    filterQuery={filterQuery}
                    handleToggleDrawer={handleToggleDrawer}
                    setProjectId={setProjectId}
                    setSelectedCreditItem={setSelectedCreditItem}
                />
                <TemporaryDrawer
                    onClose={() => {
                        localStorage.removeItem('requestIn');
                    }}
                    ref={requestDrawerRef}
                >
                    <RequestSideCard
                        cancelCreditApp={handleCancelCreditApp}
                        creditItem={creditItemSelected}
                        fetchCommercialOffers={() => {}}
                        handleToggleDrawer={handleToggleDrawer}
                        isOpenDrawer={getIsOpenDrawer()}
                        onClickStatus={onClickStatus}
                        projectId={projectId}
                        requestDrawerRef={requestDrawerRef}
                        setSelectedCreditItem={setSelectedCreditItem}
                    />
                </TemporaryDrawer>
                <FileReview
                    creditItem={creditItemSelected}
                    onHide={() => setShowModal(false)}
                    projectId={projectId}
                    setSelectedCreditItem={setSelectedCreditItem}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    show={showModal}
                    showChat={showChat}
                    user={
                        creditItemSelected.financier_installer
                            ? creditItemSelected.financier_installer.installer
                            : {}
                    }
                />
            </MaterialContainer>
        </RestrictedAccessLanding>
    );
};

const mapDispatchToProps = (dispatch) => ({
    cancelCreditApp: (offerId, onSuccess) =>
        dispatch(
            projectCommercialOfferTableActions.prepareCancel(
                offerId,
                onSuccess,
            ),
        ),
    fetchFunnelProjects: (filterQuery) =>
        dispatch(actions.fetchFunnelProjects(filterQuery)),
});

Container.propTypes = {
    cancelCreditApp: PropTypes.func,
    canModify: PropTypes.bool,
    fetchFunnelProjects: PropTypes.func,
};

export default compose(
    connect(null, mapDispatchToProps),
    withPermissions(
        PERMISSION_LIST.REQUEST_ALLIANCE_PRODUCT_FOR_PROPOSAL_PERMISSION,
    ),
)(Container);
