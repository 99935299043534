import React, { useEffect, useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Card, Grid, IconButton, Typography } from 'sunwise-ui';

import PlaceholderCardLocation from 'common/components/placeholder/PlaceholderCardLocation';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import locationForm from 'common/modules/locationForm';
import { getDefaultProjectLocation } from 'common/utils/helpers';

import * as companySelectors from '../companyGeneral/selectors';
import { getPositionData } from '../record/selectors';

import * as actions from './actions';
import * as selectors from './selectors';

const Container = ({
    canModify,
    canView,
    companyPositionLatLng,
    contactPosition,
    fetchPositions,
    fromPreview = false,
    handleClickClone,
    isLoading,
    position,
    savePosition,
    selectedProject,
    selectProject,
}) => {
    const { t } = useTranslation();
    const [editModeLocation, setEditModeLocation] = useState(false);

    useEffect(() => {
        selectProject(selectedProject);
        fetchPositions();
    }, [selectedProject.id]);

    if (!canView) return null;

    return (
        <Box mb={2}>
            <PlaceholderCardLocation ready={isLoading}>
                <Card
                    sx={{
                        minHeight: '100%',
                        mb: 0,
                        ...(fromPreview ? { boxShadow: 'none' } : {}),
                    }}
                >
                    {!fromPreview && (
                        <Card.Header>
                            <Grid container sx={{ alignItems: 'center' }}>
                                <Grid size={{ xs: 'grow' }}>
                                    <Typography
                                        fontWeight="bold"
                                        variant="body1"
                                    >
                                        {t('Location')}
                                    </Typography>
                                </Grid>

                                <Grid
                                    size={{ xs: 'grow' }}
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        gap: '16px',
                                        justifyContent: 'end',
                                    }}
                                >
                                    {get(contactPosition, 'position', null) !==
                                        null &&
                                        !editModeLocation &&
                                        canModify && (
                                            <IconButton
                                                onClick={() =>
                                                    handleClickClone()
                                                }
                                            >
                                                <ContentCopyIcon fontSize="small" />
                                            </IconButton>
                                        )}

                                    {get(position, 'position', null) !== null &&
                                        !editModeLocation &&
                                        canModify && (
                                            <IconButton
                                                onClick={() =>
                                                    setEditModeLocation(true)
                                                }
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                        )}
                                </Grid>
                            </Grid>
                        </Card.Header>
                    )}
                    <Card.Body sx={{ ...(fromPreview ? { padding: 0 } : {}) }}>
                        <locationForm.Container
                            canModify={canModify}
                            center={getDefaultProjectLocation(
                                contactPosition,
                                companyPositionLatLng,
                            )}
                            editMode={editModeLocation}
                            handleSubmit={(values) => {
                                savePosition(values, selectedProject.id, () =>
                                    setEditModeLocation(false),
                                );
                            }}
                            heightPreviewMap={fromPreview ? '300px' : '125px'}
                            initPosition={position}
                            isOpenMapPreview={true}
                            name="location-project"
                            setEditMode={setEditModeLocation}
                            showDetailedAddressFields
                        />
                    </Card.Body>
                </Card>
            </PlaceholderCardLocation>
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    companyPositionLatLng: companySelectors.getCompanyPositionLatLng,
    contactPosition: getPositionData,
    errors: selectors.getErrors,
    isLoading: selectors.getIsLoading,
    position: selectors.getPosition,
});

const mapDispatchToProps = (dispatch) => ({
    fetchPositions: () => dispatch(actions.fetchPositions()),
    handleClickClone: () => dispatch(actions.prepareClone()),
    savePosition: (values, uid, successCallback, failureCallback) =>
        dispatch(
            actions.savePosition(values, uid, successCallback, failureCallback),
        ),
    selectProject: (project) => dispatch(actions.selectProject(project)),
});

Container.propTypes = {
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    companyPositionLatLng: PropTypes.object,
    contactPosition: PropTypes.object,
    fetchPositions: PropTypes.func,
    fromPreview: PropTypes.bool,
    handleClickClone: PropTypes.func,
    isLoading: PropTypes.bool,
    position: PropTypes.object,
    savePosition: PropTypes.func,
    selectedProject: PropTypes.object,
    selectProject: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.PROJECT_LOCATION_PERMISSION),
)(Container);
