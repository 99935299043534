import React, { useCallback } from 'react';

import { debounce, toNumber } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid, Typography } from 'sunwise-ui';

import ReactHookFormIntlNumberInput from 'common/components/form/bootstrap/ReactHookFormIntlNumberInput';
import { DEBOUNCE_TIME_1500 } from 'common/constants';
import {
    calculateFinalCost,
    calculateMargin,
    getLabelWithCurrency,
} from 'common/utils/helpers';

import { calculateTotal } from '../helpers';

import PriceRangeDropDown from './PriceRangeDropDown';

const KwhFields = ({
    constingRanges,
    control,
    currencyIso,
    customerMode,
    disabled,
    handleOnChange,
    isSaving,
    setValue,
    watch,
}) => {
    const { t } = useTranslation();

    const handleChangeCostValue = (value) => {
        if (!value) {
            handleOnChange();
            return false;
        }
        setValue('kwh_cost', toNumber(value));
        const finalPrice = calculateFinalCost(
            toNumber(value),
            watch('kwh_margin'),
        );
        const totalAmount = calculateTotal({
            finalPrice,
            finalStoragekWh: watch('final_storage_kwh'),
            prefix: 'kwh',
            systemSize: watch('system_size'),
            typeChangeValue: watch('type_change'),
        });
        setValue('kwh_price', toNumber(finalPrice));
        setValue('kwh_total_amount', toNumber(totalAmount));
        handleOnChange();
    };

    const handleChangeMarginValue = (value) => {
        if (!value) {
            handleOnChange();
            return false;
        }
        if (toNumber(value) >= 100) {
            value = 99.99;
        }
        setValue('kwh_margin', toNumber(value));
        const finalPrice = calculateFinalCost(
            watch('kwh_cost'),
            toNumber(value),
        );
        const totalAmount = calculateTotal({
            finalPrice,
            finalStoragekWh: watch('final_storage_kwh'),
            prefix: 'kwh',
            systemSize: watch('system_size'),
            typeChangeValue: watch('type_change'),
        });
        setValue('kwh_price', toNumber(finalPrice));
        setValue('kwh_total_amount', toNumber(totalAmount));
        handleOnChange();
    };

    const handleChangePriceValue = (value) => {
        if (!value) {
            handleOnChange();
            return false;
        }
        setValue('kwh_price', toNumber(value));
        const newMargin = calculateMargin(toNumber(value), watch('kwh_cost'));
        if (toNumber(value) < watch('kwh_cost')) {
            setValue('kwh_cost', toNumber(value));
            setValue('kwh_margin', 0);
        } else setValue('kwh_margin', toNumber(newMargin));

        const totalAmount = calculateTotal({
            finalPrice: toNumber(value),
            finalStoragekWh: watch('final_storage_kwh'),
            prefix: 'kwh',
            systemSize: watch('system_size'),
            typeChangeValue: watch('type_change'),
        });
        setValue('kwh_total_amount', toNumber(totalAmount));
        handleOnChange();
    };

    const handleChangeTotalValue = (value) => {
        if (!value) {
            handleOnChange();
            return false;
        }
        const costTemp =
            watch('kwh_cost') *
            watch('final_storage_kwh') *
            watch('type_change');
        setValue('kwh_total_amount', toNumber(value));
        if (toNumber(value) > costTemp) {
            const newPrice =
                value / (watch('final_storage_kwh') * watch('type_change'));
            const newMargin = (1 - watch('kwh_cost') / newPrice) * 100;
            setValue('kwh_price', newPrice.toFixed(4));
            setValue('kwh_margin', newMargin.toFixed(2));
        } else {
            const newCost =
                value / (watch('final_storage_kwh') * watch('type_change'));
            setValue('kwh_cost', newCost.toFixed(4));
            setValue('kwh_margin', 0);
            setValue('kwh_price', newCost.toFixed(4));
        }
        handleOnChange();
    };

    const handleDebouncedChangeCost = useCallback(
        debounce((value) => handleChangeCostValue(value), DEBOUNCE_TIME_1500),
        [],
    );

    const handleDebouncedChangeMargin = useCallback(
        debounce((value) => handleChangeMarginValue(value), DEBOUNCE_TIME_1500),
        [],
    );

    const handleDebouncedChangePrice = useCallback(
        debounce((value) => handleChangePriceValue(value), DEBOUNCE_TIME_1500),
        [],
    );
    const handleDebouncedChangeTotal = useCallback(
        debounce((value) => handleChangeTotalValue(value), DEBOUNCE_TIME_1500),
        [],
    );

    return (
        <Grid container>
            <Grid
                size={{ lg: 2, xs: 18 }}
                sx={{ textAlign: { xs: 'left', lg: 'center' } }}
            >
                <Typography fontWeight="bold" variant="body2">
                    {t('Per kWh')}
                </Typography>
            </Grid>
            {!customerMode && (
                <>
                    <Grid size={{ lg: 4, md: 9, xs: 18 }}>
                        <ReactHookFormIntlNumberInput
                            allowNegativeValue={false}
                            append="USD/kWh"
                            control={control}
                            disabled={disabled || isSaving}
                            fullWidth
                            label={getLabelWithCurrency('USD', t('Cost'))}
                            min="0"
                            name="kwh_cost"
                            onChange={({ target: { value } }) =>
                                handleDebouncedChangeCost(value)
                            }
                            prepend="$"
                        />
                    </Grid>
                    <Grid size={{ lg: 4, md: 9, xs: 18 }}>
                        <ReactHookFormIntlNumberInput
                            allowNegativeValue={false}
                            append="%"
                            control={control}
                            disabled={disabled || isSaving}
                            fullWidth
                            label={t('Margin')}
                            min="0"
                            max="100"
                            name="kwh_margin"
                            onChange={({ target: { value } }) =>
                                handleDebouncedChangeMargin(value)
                            }
                        />
                    </Grid>
                </>
            )}
            <Grid size={{ lg: 4, md: 9, xs: 18 }} sx={{ pl: 0 }}>
                <Box alignItems="end" display="flex" sx={{ width: '100%' }}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="USD/kWh"
                        control={control}
                        disabled={disabled || isSaving}
                        fullWidth
                        label={getLabelWithCurrency('USD', t('Price'))}
                        name="kwh_price"
                        min="0"
                        onChange={({ target: { value } }) =>
                            handleDebouncedChangePrice(value)
                        }
                        prepend="$"
                    />
                    <PriceRangeDropDown ranges={constingRanges} />
                </Box>
            </Grid>
            <Grid size={{ lg: 4, md: 9, xs: 18 }}>
                <ReactHookFormIntlNumberInput
                    allowNegativeValue={false}
                    control={control}
                    disabled={disabled || isSaving}
                    fullWidth
                    label={getLabelWithCurrency(currencyIso, t('Subtotal'))}
                    name="kwh_total_amount"
                    min="0"
                    onChange={({ target: { value } }) =>
                        handleDebouncedChangeTotal(value)
                    }
                    prepend="$"
                />
            </Grid>
            <Grid size={18}>
                <Divider />
            </Grid>
        </Grid>
    );
};

KwhFields.propTypes = {
    constingRanges: PropTypes.array,
    control: PropTypes.object,
    currencyIso: PropTypes.string,
    customerMode: PropTypes.bool,
    disabled: PropTypes.bool,
    handleOnChange: PropTypes.func,
    isSaving: PropTypes.bool,
    setValue: PropTypes.func,
    watch: PropTypes.func,
};

export default KwhFields;
