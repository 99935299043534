import i18next from 'i18next';
import { isArray, isEmpty } from 'lodash';

import {
    BATTERY_OPERATION_STRATEGY,
    BATTERY_STATUS,
    CHART_COLORS,
    DAYS_IN_WEEK,
    DAYS_NAMES,
    ENERGY_CHART_COLORS,
    GENERATION_SOURCES,
    HOURS_IN_DAY,
    HOURS_IN_YEAR,
    MAX_DEGRADATION,
    MONTHS,
    MS_IN_HOUR,
} from 'common/constants';
import {
    addDate,
    shiftUTCDateString,
    differenceInDaysDate,
    formatDate,
    isInvalidLeapYearDay,
    isLeapYear,
    parseDate,
} from 'common/utils/dates';
import {
    getDayLabel,
    getMonthLabel,
    numberFormat,
    parseJSON,
} from 'common/utils/helpers';
import { getLocaleEquivalenceForDateFns } from 'common/utils/helpers/multiregion';
import {
    getBilledDemandFromEditableFormat,
    getTierValue,
} from 'common/utils/helpers/rates';

import { CHART_OPTIONS } from '../../components/charts/ChartControls';

export const MONTHS_ARRAY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const COLOR_DELTA = 25;

// Utils
export const createFilledArray = (length, value = 0) =>
    new Array(length).fill(value);

export const generateColorTone = (baseColor, delta) => {
    let r = parseInt(baseColor.slice(1, 3), 16);
    let g = parseInt(baseColor.slice(3, 5), 16);
    let b = parseInt(baseColor.slice(5, 7), 16);

    r = Math.min(255, Math.max(0, r + delta));
    g = Math.min(255, Math.max(0, g + delta));
    b = Math.min(255, Math.max(0, b + delta));

    const newColor = `#${((1 << 24) + (r << 16) + (g << 8) + b)
        .toString(16)
        .slice(1)
        .toUpperCase()}`;

    return newColor;
};

const getActiveTierFieldsValues = (active, data, key = 'max_power') => {
    if (!active) return {};

    const fields = typeof data === 'string' ? parseJSON(data) : data;
    if (!Array.isArray(fields)) return {};

    return fields.reduce((acc, curr) => {
        acc[curr.identifier] = curr[key];
        return acc;
    }, {});
};

const getAnnualValues = (data) =>
    data.reduce((acc, curr) => acc + curr, 0) / 100;

const updateConsecutiveHoursArray = ({
    consecutiveHoursArray,
    currentStatus,
    currentStatusArray,
    remainingConsecutiveHours,
}) => {
    for (let i = 0; i < currentStatusArray.length; i++) {
        if (currentStatusArray[i] === currentStatus) {
            consecutiveHoursArray.push(remainingConsecutiveHours);
            remainingConsecutiveHours--;
        } else {
            consecutiveHoursArray.push(currentStatusArray[i]);
        }
    }
};

const getBatteryConsecutiveHoursLeft = (hourlyBatteryStatus) => {
    const oppositeBatteryStatus = {
        [BATTERY_STATUS.CHARGING]: BATTERY_STATUS.DISCHARGING,
        [BATTERY_STATUS.DISCHARGING]: BATTERY_STATUS.CHARGING,
    };

    const consecutiveHoursArray = [];
    let currentStatus = BATTERY_STATUS.CHARGING;
    let currentStatusArray = [];
    let remainingConsecutiveHours = 0;

    for (const status of hourlyBatteryStatus) {
        if (status === oppositeBatteryStatus[currentStatus]) {
            updateConsecutiveHoursArray({
                consecutiveHoursArray,
                currentStatus,
                currentStatusArray,
                remainingConsecutiveHours,
            });

            currentStatus = status;
            currentStatusArray = [];
            remainingConsecutiveHours = 0;
        }

        if (status !== BATTERY_STATUS.IDLE) remainingConsecutiveHours++;

        currentStatusArray.push(status);
    }

    if (remainingConsecutiveHours > 0) {
        updateConsecutiveHoursArray({
            consecutiveHoursArray,
            currentStatus,
            currentStatusArray,
            remainingConsecutiveHours,
        });
    }

    return consecutiveHoursArray;
};

export const getDaysByWeek = (weeks) =>
    weeks.reduce((acc, curr) => {
        for (let i = DAYS_IN_WEEK * curr; i < DAYS_IN_WEEK * (curr + 1); i++)
            if (!acc.includes(i + 1)) acc.push(i + 1);
        return acc;
    }, []);

export const getInitialAndMaxIndex = ({ delay, realIndexObject } = {}) => {
    let daysOffset = 0;
    let tempDelay = 0;
    let totalDays = 0;

    for (const period in realIndexObject) {
        const daysInPeriod = realIndexObject[period]?.length || 0;
        const delayLeft = delay - tempDelay;

        if (delayLeft >= 1) daysOffset += daysInPeriod;
        else if (delayLeft > 0 && delayLeft < 1)
            daysOffset += Math.ceil(delayLeft * daysInPeriod);
        totalDays += daysInPeriod;
        tempDelay++;
    }

    return {
        initialIndex: daysOffset * HOURS_IN_DAY,
        maxIndex: totalDays * HOURS_IN_DAY - 1,
    };
};

export const getMaxEnergyByAlgorithm = ({
    algorithm,
    energy,
    hoursLeft,
} = {}) => {
    if (algorithm === BATTERY_OPERATION_STRATEGY.MAX_POWER) return energy || 0;
    return energy / hoursLeft || 0;
};

const getNormalizedDegradation = ({
    degradationRatio = 0,
    index = 0,
    selectedYear,
} = {}) => {
    const hours = selectedYear * HOURS_IN_YEAR + index + 1;
    const degradation = (hours < 0 ? 0 : hours * degradationRatio) || 0;
    return 1 - (degradation < MAX_DEGRADATION ? degradation : MAX_DEGRADATION);
};

export const getProposalChartInfoPriceSeries = (
    consumptionHistory,
    keyNames,
) => {
    if (!consumptionHistory || !keyNames) return [];
    const temp = {};
    const allKeys = new Set();
    let correctKeyNames = keyNames;

    if (typeof correctKeyNames === 'string') correctKeyNames = [keyNames];

    // Collect all unique keys
    for (let i = 0; i < consumptionHistory.length; i++) {
        const monthData = consumptionHistory[i];
        const keyName = correctKeyNames[i] || correctKeyNames[0];
        if (!monthData[keyName]) continue;
        const currentKeys = Object.keys(monthData[keyName]);
        for (const key of currentKeys) allKeys.add(key);
    }

    // Fill in the values for each key, or 0 if the key does not exist in the current monthData
    for (let i = 0; i < consumptionHistory.length; i++) {
        const monthData = consumptionHistory[i];
        const keyName = correctKeyNames[i] || correctKeyNames[0];

        for (const key of allKeys) {
            if (!Object.prototype.hasOwnProperty.call(temp, key))
                temp[key] = [];
            temp[key].push(monthData[keyName]?.[key] || 0);
        }
    }

    // Convert temp to the desired series format
    return Array.from(allKeys).map((key) => ({ data: temp[key], name: key }));
};

const getTiersDistribution = ({ summary, tiersDistribution } = {}) => {
    const hourlyTiersDistribution = [];

    for (let index = 0; index < summary.length; index++) {
        const period = summary[index];
        const [finalDay, finalMonth, finalYear] = (
            summary[index + 1]?.initial_date || period.final_date
        ).split('/');
        const finalUTCDate = new Date(
            Date.UTC(finalYear, finalMonth - 1, Number(finalDay) + 1),
        );
        const [initDay, initMonth, initYear] = period.initial_date.split('/');
        const date = new Date(
            Date.UTC(initYear, initMonth - 1, Number(initDay) + 1),
        );

        while (
            date < finalUTCDate &&
            hourlyTiersDistribution.length < HOURS_IN_YEAR
        ) {
            const year = date.getUTCFullYear();
            const month = date.getUTCMonth();
            const day = date.getUTCDate();
            const hour = date.getUTCHours();

            date.setUTCHours(hour + 1);

            if (isInvalidLeapYearDay({ day, month, year })) continue;

            let tierValue = 0;
            if (tiersDistribution?.weekday_distribution?.length)
                tierValue = getTierValue({
                    day: date.getUTCDay(),
                    hour,
                    month,
                    tiers_distribution: tiersDistribution,
                });

            hourlyTiersDistribution.push(tierValue);
        }
    }

    return hourlyTiersDistribution;
};

// Charts config
export const getEconomicChartTooltipMessage = ({
    consumptionHistory,
    countryCurrencyIso,
    countryCurrencyLocale,
    dataPointIndex,
    value,
    w,
}) => {
    let prelabel = value;
    if (consumptionHistory)
        prelabel = getTooltipDateRange({
            index: dataPointIndex,
            seriesEnergy: consumptionHistory,
        });

    return `${prelabel || ''} - <strong>${i18next.t('Total')}: ${numberFormat(
        w.globals.stackedSeriesTotals[dataPointIndex],
        {
            currency: countryCurrencyIso,
            locale: countryCurrencyLocale,
            style: 'currency',
        },
    )}
        </strong>`;
};

export const getTooltipDateRange = ({ index, seriesEnergy }) => {
    if (!seriesEnergy || !seriesEnergy[index]) return '';

    const { final_date, initial_date, label } = seriesEnergy[index];
    const parsedInitialDate = parseDate(initial_date, 'dd/MM/yyyy');
    const parsedFinalDate = parseDate(final_date, 'dd/MM/yyyy');

    const initialDate = formatDate(parsedInitialDate, 'dd/MMM');
    const finalDate = formatDate(parsedFinalDate, 'dd/MMM');

    const diffDays = differenceInDaysDate(parsedFinalDate, parsedInitialDate);

    return `${label.toUpperCase()} - (${initialDate.toUpperCase()} - ${finalDate.toUpperCase()}) - (${diffDays} ${i18next.t(
        'Day',
        { count: diffDays },
    )})`;
};

export const getEnergyChartConfig = ({
    baseChartConfig,
    consumptionProfile,
    countryCurrencyLocale,
    dataKwh,
    monthskwh,
    selectedDays,
    selectedOption,
    selectedPeriod,
    selectedWeeks,
} = {}) => {
    const { options: baseOptions } = baseChartConfig;

    const isPeriodic = selectedOption === CHART_OPTIONS.MONTHLY;
    const isYearly = selectedOption === CHART_OPTIONS.YEARLY;
    const isDaily = selectedOption === CHART_OPTIONS.DAILY;

    const options = {
        chart: {
            ...baseOptions.chart,
            animations: { speed: 300 },
            zoom: { enabled: false },
        },
        colors: [...baseOptions.colors],
        dataLabels: { ...baseOptions.dataLabels },
        legend: { ...baseOptions.legend },
        fill: { opacity: 1 },
        stroke: { curve: 'smooth' },
        xaxis: {
            ...baseOptions.xaxis,
            categories: getEnergyChartLabels({
                selectedOption,
                selectedPeriod,
                seriesEnergy: monthskwh,
            }),
            labels: {
                rotate: isPeriodic ? -45 : 0,
                rotateAlways: isPeriodic,
            },
        },
        yaxis: [...((isArray(baseOptions.yaxis) && baseOptions.yaxis) || [])],
    };
    if (!isYearly)
        options.yaxis = [
            {
                floating: false,
                labels: {
                    formatter: (val) =>
                        numberFormat(val, {
                            locale: countryCurrencyLocale,
                            style: 'decimal',
                        }),
                },
                min: 0,
                tickAmount: 4,
                title: { text: isDaily ? 'kW' : 'kWh' },
            },
        ];

    const series = getEnergyChartSeries({
        consumptionProfile,
        dataKwh,
        selectedDays,
        selectedOption,
        selectedPeriod,
        selectedWeeks,
    });
    return { options, series };
};

export const getProposalEnergyBaseChartConfig = ({
    countryCurrencyLocale,
    onLegendClick,
    seriesEnergy,
} = {}) => {
    const categories = seriesEnergy?.map((month) => month?.label) || [];

    return {
        id: 'chartEnergy',
        options: {
            chart: {
                background: '#ffffff00',
                events: { legendClick: onLegendClick },
                toolbar: { show: false },
                zoom: { enabled: false },
            },
            colors: ENERGY_CHART_COLORS,
            consumptionHistory: seriesEnergy,
            dataLabels: { enabled: false },
            tooltip: {
                x: {
                    formatter: (_, { dataPointIndex }) =>
                        getTooltipDateRange({
                            index: dataPointIndex,
                            seriesEnergy,
                        }),
                },
                y: {
                    formatter: (val) =>
                        numberFormat(val, {
                            decimals: 0,
                            locale: countryCurrencyLocale,
                            style: 'decimal',
                            unit: 'kWh',
                        }),
                },
            },
            xaxis: { categories },
            yaxis: {
                decimalsInFloat: 0,
                labels: {
                    formatter: (val) =>
                        numberFormat(val, {
                            decimals: 0,
                            locale: countryCurrencyLocale,
                            style: 'decimal',
                            unit: 'kWh',
                        }),
                },
                min: 0,
            },
        },
        series: [
            {
                color: ENERGY_CHART_COLORS[0],
                data: seriesEnergy?.map((month) => month.consumption) || [],
                id: 'original-consumption',
                name: `${i18next.t('Original consumption')} (kWh)`,
                type: 'column',
            },
            {
                color: ENERGY_CHART_COLORS[1],
                data:
                    seriesEnergy?.map((month) =>
                        month.consumption - month.generation > 0
                            ? month.consumption - month.generation
                            : 0,
                    ) || [],
                id: 'new-consumption',
                name: `${i18next.t('New consumption')} (kWh)`,
                type: 'column',
            },
            {
                color: ENERGY_CHART_COLORS[2],
                data: seriesEnergy?.map((month) => month.generation) || [],
                id: 'solar-generation',
                name: `${i18next.t('Solar generation')} (kWh)`,
                type: 'line',
            },
        ],
    };
};

const getProposalEnergyChartConfig = ({
    baseChartConfig,
    consumptionHistory,
    countryCurrencyLocale,
    maxValue,
    minValue,
    selectedOption,
    selectedPeriod,
    selectedYear,
    series,
    showBatteries,
} = {}) => {
    const isDaily = selectedOption === CHART_OPTIONS.DAILY;
    const isPeriodic = selectedOption === CHART_OPTIONS.MONTHLY;
    const isYearly = selectedOption === CHART_OPTIONS.YEARLY;

    const colors = series.map((serie) => serie.color);
    const curves = series.map((serie) => serie.curve || 'smooth');
    const dashArray = series.map((serie) => serie.dash || 0);
    const unit = isDaily ? 'kW' : 'kWh';
    const { options: baseOptions } = baseChartConfig;
    const opacity =
        baseChartConfig.type === 'bar'
            ? 1
            : series.map((serie) => serie.opacity || 0.3);
    const options = {
        chart: {
            ...baseOptions.chart,
            animations: { speed: 250 },
            stacked: false,
            zoom: { enabled: false },
        },
        colors,
        consumptionHistory,
        dataLabels: { ...baseOptions.dataLabels },
        legend: { ...baseOptions.legend },
        tooltip: {
            followCursor: true,
            intersect: false,
            shared: true,
            y: {
                formatter: (val = 0) =>
                    numberFormat(val.toFixed(5), {
                        decimals: 3,
                        locale: countryCurrencyLocale,
                        style: 'decimal',
                    }),
            },
            x: {
                formatter: (_, { dataPointIndex, w }) => {
                    const category = w.globals.categoryLabels[dataPointIndex];

                    if (!isYearly) return category;

                    return getTooltipDateRange({
                        index: dataPointIndex,
                        seriesEnergy: consumptionHistory[selectedYear || 0],
                    });
                },
            },
        },
        stroke: { curve: curves, dashArray, width: 3 },
        fill: { opacity },
        markers: { size: 0 },
        xaxis: {
            ...baseOptions.xaxis,
            categories: getEnergyChartLabels({
                selectedOption,
                selectedPeriod,
                seriesEnergy: isYearly
                    ? consumptionHistory[selectedYear || 0]
                    : consumptionHistory[0],
            }),
            labels: {
                rotate: isPeriodic ? -45 : 0,
                rotateAlways: isPeriodic,
            },
        },
        yaxis: {
            labels: {
                formatter: (val = 0) =>
                    numberFormat(val.toFixed(5), {
                        decimals: 3,
                        locale: countryCurrencyLocale,
                        style: 'decimal',
                        unit: unit,
                    }),
            },
            title: { text: unit },
        },
    };

    if (showBatteries) {
        const minY = Math.min(minValue, 0);
        const tickAmount = 5;

        options.yaxis = series
            .map((serie) => {
                if (serie.name === 'SOC (%)')
                    return {
                        axisBorder: { show: true, color: '#FF0000' },
                        axisTicks: { show: true },
                        labels: {
                            formatter: (val = 0) =>
                                numberFormat(val.toFixed(5), {
                                    decimals: 0,
                                    locale: countryCurrencyLocale,
                                    style: 'decimal',
                                    unit: '%',
                                }),
                        },
                        max: 100,
                        min: (minY / maxValue) * 100,
                        opposite: true,
                        seriesName: 'SOC (%)',
                        tickAmount,
                    };
                return {
                    max: maxValue,
                    min: minY,
                    seriesName: serie.name,
                    show: false,
                    tickAmount,
                };
            })
            .concat({ ...options.yaxis, max: maxValue, min: minY, tickAmount });
    }

    return { options };
};

export const handleProposalEnergyChartConfig = ({
    configOptions,
    consumptionHistory,
    countryCurrencyLocale,
    hasNettedExportedGeneration,
    isSelfConsumption,
    onLegendClick,
    realIndex,
    solarSimulationData,
    zeroExport,
} = {}) => {
    let seriesEnergy = [];
    if (consumptionHistory?.[configOptions.year])
        seriesEnergy = consumptionHistory[configOptions.year];

    const baseChartConfig = getProposalEnergyBaseChartConfig({
        countryCurrencyLocale,
        onLegendClick,
        seriesEnergy,
        yearOffset: configOptions.year,
    });

    if (!isSelfConsumption && configOptions.option === 0)
        return { ...baseChartConfig, key: 'energy-chart-NETMET', type: 'line' };

    const { days, option, period, type, weeks, year } = configOptions;
    const showBatteries = option === 3 && solarSimulationData?.hasBatteries;

    const { maxValue, minValue, series } = getProposalEnergySeries({
        chartType: type === 'bar' ? 'column' : type,
        hasNettedExportedGeneration,
        realIndex,
        selectedDays: days,
        selectedOption: option,
        selectedPeriod: period,
        selectedWeeks: weeks,
        selectedYear: year,
        showBatteries,
        solarSimulationData,
        zeroExport,
    });

    const energyChartConfig = getProposalEnergyChartConfig({
        baseChartConfig: { ...baseChartConfig, type },
        consumptionHistory,
        countryCurrencyLocale,
        maxValue,
        minValue,
        selectedOption: option,
        selectedPeriod: period,
        selectedYear: year,
        series,
        showBatteries,
    });

    return {
        options: energyChartConfig.options,
        key: Object.values(configOptions)?.join('-'),
        series,
        type: type === 'bar' ? 'bar' : 'line',
    };
};

export const getChartOptionsEconomic = ({
    consumptionHistory,
    countryCurrencyIso,
    countryCurrencyLocale,
    onLegendClick,
    yearCategories,
}) => ({
    chart: {
        background: '#ffffff00',
        events: { legendClick: onLegendClick },
        stacked: true,
        toolbar: { show: false },
    },
    chartJsOptions: {
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => {
                        return (
                            context.dataset.label +
                            ': ' +
                            numberFormat(context.parsed.y, {
                                currency: countryCurrencyIso,
                                locale: countryCurrencyLocale,
                                style: 'currency',
                            })
                        );
                    },
                    title: (context) => {
                        const ctx = context[0];
                        const values = ctx.parsed._stacks.y._visualValues;
                        const _metasets = ctx.chart._metasets;
                        const valuesLength = Object.keys(values).length - 1;
                        let total = 0;

                        for (let i = 0; i <= valuesLength; i++) {
                            if (_metasets[i].hidden) continue;
                            total += values[i];
                        }

                        const formattedTotal = numberFormat(total, {
                            currency: countryCurrencyIso,
                            locale: countryCurrencyLocale,
                            style: 'currency',
                        });

                        return `${getTooltipDateRange({
                            index: ctx.dataIndex,
                            seriesEnergy: consumptionHistory,
                        })} - ${formattedTotal}`;
                    },
                },
            },
        },
        scales: {
            x: { grid: { display: false }, stacked: true },
            y: {
                beginAtZero: true,
                stacked: true,
                ticks: {
                    callback: (val) =>
                        numberFormat(val, {
                            currency: countryCurrencyIso,
                            locale: countryCurrencyLocale,
                            style: 'currency',
                        }),
                },
            },
        },
    },
    colors: CHART_COLORS,
    consumptionHistory,
    dataLabels: { enabled: false },
    legend: { show: true, showForSingleSeries: true },
    tooltip: {
        x: {
            formatter: (value, { dataPointIndex, w }) =>
                getEconomicChartTooltipMessage({
                    consumptionHistory,
                    countryCurrencyIso,
                    countryCurrencyLocale,
                    dataPointIndex,
                    value,
                    w,
                }),
        },
        y: {
            formatter: (val) =>
                numberFormat(val, {
                    currency: countryCurrencyIso,
                    locale: countryCurrencyLocale,
                    style: 'currency',
                }),
        },
    },
    xaxis: { categories: yearCategories },
    yaxis: {
        decimalsInFloat: 0,
        labels: {
            formatter: (val) =>
                numberFormat(val, {
                    currency: countryCurrencyIso,
                    locale: countryCurrencyLocale,
                    style: 'currency',
                }),
        },
    },
});

export const getProposalChartConfigEconomic = ({
    consumptionHistory = [],
    countryCurrencyIso,
    countryCurrencyLocale,
    infoKey,
    onLegendClick,
    yearCategories,
}) => ({
    key: 'chartEconomic',
    options: getChartOptionsEconomic({
        consumptionHistory,
        countryCurrencyIso,
        countryCurrencyLocale,
        onLegendClick,
        yearCategories,
    }),
    series: getProposalChartInfoPriceSeries(consumptionHistory, infoKey),
    type: 'bar',
});

export const getBagChartSeries = (consumptionHistory) => {
    if (!consumptionHistory) return [];

    const colors = ['#0073d0', '#f2ae47', '#03e396'];
    const result = { applied: {}, consumed: {}, generated: {} };

    for (const month of consumptionHistory) {
        const { bag_values = {} } = month;

        if (isEmpty(bag_values)) continue;

        const { applied, consumed, generated } = bag_values[0];

        for (const key in applied) {
            const _key = `${applied[key].tier}`;
            if (!Object.prototype.hasOwnProperty.call(result.applied, _key))
                result.applied[_key] = [];
            result.applied[_key].push(applied[key].applied);
        }

        for (const key in consumed) {
            const _key = `${consumed[key].tier}`;
            if (!Object.prototype.hasOwnProperty.call(result.consumed, _key))
                result.consumed[_key] = [];
            result.consumed[_key].push(consumed[key].consumption);
        }

        for (const key in generated) {
            const _key = `${generated[key].tier}`;
            if (!Object.prototype.hasOwnProperty.call(result.generated, _key))
                result.generated[_key] = [];
            result.generated[_key].push(generated[key].generation);
        }
    }

    const _applied = Object.keys(result.applied).map((key, index) => ({
        color: generateColorTone(colors[1], index * COLOR_DELTA),
        data: result.applied[key],
        name: `${key} - ${i18next.t('Applied')}`,
        stack: 'applied',
    }));

    const _consumed = Object.keys(result.consumed).map((key, index) => ({
        color: generateColorTone(colors[0], index * COLOR_DELTA),
        data: result.consumed[key],
        name: `${key} - ${i18next.t('Consumed')}`,
        stack: 'consumed',
    }));

    const _generated = Object.keys(result.generated).map((key, index) => ({
        color: generateColorTone(colors[2], index * COLOR_DELTA),
        data: result.generated[key],
        name: `${key} - ${i18next.t('Generated')}`,
        stack: 'generated',
    }));

    return [..._applied, ..._consumed, ..._generated];
};

export const getProposalChartConfigBag = ({
    consumptionHistory = [],
    yearCategories,
}) => ({
    key: 'chartBag',
    options: { type: 'bar', xaxis: { categories: yearCategories } },
    series: getBagChartSeries(consumptionHistory),
    type: 'bar',
});

// Labels
export const getArrayHours = () =>
    Array.from({ length: HOURS_IN_DAY }, (_, i) => String(i).padStart(2, '0'));

export const getChartMonthTypeLabel = (month, year) => {
    const labels = [];

    const initialDate = new Date(year, month - 1, 1);
    const finalDate = new Date(year, month, 0);
    for (
        let date = initialDate;
        date <= finalDate;
        date.setDate(date.getDate() + 1)
    ) {
        labels.push(formatDate(date, 'dd (EE)'));
    }

    return labels;
};

export const getDaysNames = () => [
    ...DAYS_NAMES.map((day) => getDayLabel(day)).slice(1),
    getDayLabel(DAYS_NAMES[0]),
];

const getPeriodLabels = ({ final_date, initial_date } = {}) => {
    const labels = [];
    const initialDate = parseDate(initial_date, 'dd/MM/yyyy');
    const finalDate = parseDate(final_date, 'dd/MM/yyyy');
    for (
        let date = addDate(initialDate, { days: 1 });
        date <= finalDate;
        date.setDate(date.getDate() + 1)
    ) {
        if (
            isInvalidLeapYearDay({
                day: date.getDate(),
                month: date.getMonth(),
                year: date.getFullYear(),
            })
        )
            continue;
        labels.push(formatDate(date, 'dd (EE)'));
    }

    return labels;
};

export const getChartCategories = (type, month, year) => {
    switch (type) {
        case 0:
            return MONTHS.map((monthItem) => getMonthLabel(monthItem));
        case 1:
            return getChartMonthTypeLabel(month, year);
        case 2:
            return getDaysNames();
        case 3:
            return getArrayHours();
    }
};

export const getEnergyChartLabels = ({
    selectedOption,
    selectedPeriod,
    seriesEnergy,
} = {}) => {
    switch (selectedOption) {
        case 0:
            return seriesEnergy?.map((item) => item.label) || [];
        case 1: {
            const currentPeriod = seriesEnergy?.[selectedPeriod] || {};
            const nextPeriod = seriesEnergy?.[selectedPeriod + 1] || {};
            return getPeriodLabels({
                final_date: nextPeriod.initial_date || currentPeriod.final_date,
                initial_date: currentPeriod?.initial_date,
            });
        }
        case 2:
            return getDaysNames();
        case 3:
            return getArrayHours();
    }
};

// Series
export const getSeriesByOption = ({ data, options } = {}) =>
    options[data?.selectedOption](data);

const getDailyEnergyChartSeries = ({
    consumptionProfile,
    dataKwh,
    selectedDays,
    selectedPeriod,
    selectedWeeks,
} = {}) => {
    const annualConsumption =
        getAnnualValues(dataKwh) / (selectedWeeks.length * selectedDays.length);
    const daysInPeriod = getDaysByWeek(selectedWeeks);

    const consumption = createFilledArray(HOURS_IN_DAY, 0);

    for (const day of daysInPeriod) {
        const periodDay = consumptionProfile?.[selectedPeriod]?.[day - 1];
        if (!periodDay) continue;

        const dayType = periodDay.day;
        if (!selectedDays.includes(dayType)) continue;

        for (const hour in periodDay) {
            if (hour === 'total' || hour === 'day') continue;
            consumption[hour] += annualConsumption * periodDay[hour] || 0;
        }
    }
    return [{ data: consumption, name: 'kWh' }];
};

const getEnergyChartSeriesByPeriod = ({
    consumptionProfile,
    dataKwh,
    selectedPeriod,
} = {}) => {
    const annualConsumption = getAnnualValues(dataKwh);
    const consumption = [];
    for (const day in consumptionProfile[selectedPeriod])
        consumption.push(
            consumptionProfile[selectedPeriod][day].total * annualConsumption,
        );

    return [{ data: consumption, name: 'kWh' }];
};

const getWeeklyEnergyChartSeries = ({
    consumptionProfile,
    dataKwh,
    selectedPeriod,
    selectedWeeks,
} = {}) => {
    const annualConsumption = getAnnualValues(dataKwh) / selectedWeeks.length;
    const daysInPeriod = getDaysByWeek(selectedWeeks);

    const consumption = createFilledArray(DAYS_IN_WEEK, 0);
    for (const day of daysInPeriod) {
        const periodDay = consumptionProfile?.[selectedPeriod]?.[day - 1];
        if (!periodDay) continue;

        const dayType = periodDay.day === 0 ? 6 : periodDay.day - 1;
        consumption[dayType] += annualConsumption * periodDay.total || 0;
    }

    return [{ data: consumption, name: 'kWh' }];
};

const getEnergyChartSeries = (data) =>
    getSeriesByOption({
        data,
        options: {
            1: getEnergyChartSeriesByPeriod,
            2: getWeeklyEnergyChartSeries,
            3: getDailyEnergyChartSeries,
        },
    });

export const getChartSeriesKwData = (periods, rate) => {
    if (!periods?.length) return [];

    return periods.map((period) => {
        if (!period?.kW) return 0;

        return getBilledDemandFromEditableFormat({
            finalDate: period?.final_date,
            initialDate: period?.initial_date,
            rate,
            total: period?.total,
            values: Object.values(period.kW),
        });
    });
};

const createFilledSeries = (series, length) => {
    const filledSeries = {};
    for (const { name, shouldPopulate = true } of series) {
        if (!shouldPopulate) continue;

        filledSeries[name] = createFilledArray(length);
    }

    return filledSeries;
};

const populateSeries = ({
    dataIndex,
    populatedSeriesData,
    multiplier = 1,
    serieIndex,
    series,
}) => {
    for (const { data, name, shouldPopulate = true } of series) {
        if (!shouldPopulate) continue;

        populatedSeriesData[name][serieIndex] +=
            data[dataIndex] * multiplier || 0;
    }
};

export const populateDailySeries = ({
    excludedSeriesFromMinMax,
    realIndex,
    returnMinAndMax,
    selectedDays,
    selectedPeriod,
    selectedWeeks,
    selectedYear,
    series,
} = {}) => {
    const populatedData = { series: createFilledSeries(series, HOURS_IN_DAY) };

    const daysInPeriod = getDaysByWeek(selectedWeeks);
    const initialIndex = selectedYear * HOURS_IN_YEAR;
    const multiplier = 1 / (selectedWeeks.length * selectedDays.length);

    for (const day of daysInPeriod) {
        const dayObject = realIndex?.[selectedPeriod]?.[day - 1];
        if (!dayObject) continue;

        const dayType = dayObject.day;
        if (!selectedDays.includes(dayType)) continue;

        for (const hour in dayObject) {
            if (hour === 'total' || hour === 'day') continue;

            populateSeries({
                dataIndex: initialIndex + dayObject[hour],
                populatedSeriesData: populatedData.series,
                multiplier,
                serieIndex: hour,
                series,
            });
        }
    }

    if (returnMinAndMax) {
        const allValues = Object.entries(populatedData.series)
            .flatMap(([key, value]) =>
                excludedSeriesFromMinMax?.includes(key) ? [] : value,
            )
            .sort((a, b) => a - b);

        populatedData.minValue = allValues[0];
        populatedData.maxValue = allValues[allValues.length - 1];
    }

    return populatedData;
};

export const populateWeeklySeries = ({
    realIndex,
    selectedPeriod,
    selectedWeeks,
    selectedYear,
    series,
} = {}) => {
    const populatedData = { series: createFilledSeries(series, DAYS_IN_WEEK) };

    const daysInPeriod = getDaysByWeek(selectedWeeks);
    const initialIndex = selectedYear * HOURS_IN_YEAR;
    const multiplier = 1 / selectedWeeks.length;

    for (const day of daysInPeriod) {
        const dayObject = realIndex?.[selectedPeriod]?.[day - 1];
        if (!dayObject) continue;

        const dayType = dayObject.day === 0 ? 6 : dayObject.day - 1;

        for (const hour in dayObject) {
            if (hour === 'total' || hour === 'day') continue;

            populateSeries({
                dataIndex: initialIndex + dayObject[hour],
                populatedSeriesData: populatedData.series,
                multiplier,
                serieIndex: dayType,
                series,
            });
        }
    }
    return populatedData;
};

export const populatePeriodicallySeries = ({
    realIndex,
    selectedPeriod,
    selectedYear,
    series,
} = {}) => {
    const totalDays = realIndex[selectedPeriod]?.length || 0;

    const populatedData = { series: createFilledSeries(series, totalDays) };

    const initialIndex = selectedYear * HOURS_IN_YEAR;

    for (let day = 0; day < totalDays; day++) {
        for (const hour in realIndex[selectedPeriod][day]) {
            if (hour === 'total' || hour === 'day') continue;

            populateSeries({
                dataIndex: initialIndex + realIndex[selectedPeriod][day][hour],
                populatedSeriesData: populatedData.series,
                serieIndex: day,
                series,
            });
        }
    }

    return populatedData;
};

export const populateYearlyProposalEnergySeries = ({
    hasNettedExportedGeneration,
    realIndex,
    selectedYear,
    series,
} = {}) => {
    const totalPeriods = realIndex?.length || 0;

    const populatedData = { series: createFilledSeries(series, totalPeriods) };

    if (hasNettedExportedGeneration) {
        populatedData.series.nettedExportedGenerationSeries =
            createFilledArray(totalPeriods);
        populatedData.series.excessExportedGenerationSeries =
            createFilledArray(totalPeriods);
    }

    const initialIndex = selectedYear * HOURS_IN_YEAR;

    for (let period = 0; period < totalPeriods; period++) {
        for (const day in realIndex[period]) {
            if (day === 'total') continue;

            for (const hour in realIndex[period][day]) {
                if (hour === 'total' || hour === 'day') continue;

                populateSeries({
                    dataIndex: initialIndex + realIndex[period][day][hour],
                    populatedSeriesData: populatedData.series,
                    serieIndex: period,
                    series,
                });
            }
        }

        if (!hasNettedExportedGeneration) continue;
        const exportedEnergySeries =
            populatedData.series.exportedEnergySeries[period];
        const consumptionFromGridSeries =
            populatedData.series.consumptionFromGridSeries[period];

        const netSelfConsumption =
            Math.min(exportedEnergySeries, consumptionFromGridSeries) || 0;

        populatedData.series.nettedExportedGenerationSeries[period] =
            netSelfConsumption;

        populatedData.series.excessExportedGenerationSeries[period] =
            exportedEnergySeries - netSelfConsumption;
    }
    return populatedData;
};

const getProposalEnergySeries = (data) => {
    if (!data?.solarSimulationData?.consumption?.length) return { series: [] };

    const {
        chartType,
        selectedOption,
        showBatteries,
        solarSimulationData,
        zeroExport,
        ...rest
    } = data;

    const isDaily = selectedOption === CHART_OPTIONS.DAILY;

    const {
        batteryDemand,
        consumption,
        consumptionFromGrid,
        exportedEnergy,
        generation,
        hasDemand,
        newDemand,
        originalDemand,
        selfConsumption,
        socArray,
        solarPotential,
    } = solarSimulationData || {};

    const seriesData = [
        {
            data: batteryDemand,
            name: 'batteryPowerSeries',
            shouldPopulate: showBatteries,
        },
        { data: consumptionFromGrid, name: 'consumptionFromGridSeries' },
        { data: consumption, name: 'consumptionSeries' },
        { data: exportedEnergy, name: 'exportedEnergySeries' },
        { data: generation, name: 'generationSeries' },
        {
            data: newDemand,
            name: 'newDemandSeries',
            shouldPopulate: hasDemand && isDaily,
        },
        {
            data: originalDemand,
            name: 'originalDemandSeries',
            shouldPopulate: hasDemand && isDaily,
        },
        { data: selfConsumption, name: 'selfConsumptionSeries' },
        { data: socArray, name: 'socSeries', shouldPopulate: showBatteries },
        {
            data: solarPotential,
            name: 'solarPotentialSeries',
            shouldPopulate: zeroExport,
        },
    ];

    const {
        maxValue,
        minValue,
        series: {
            batteryPowerSeries,
            consumptionFromGridSeries,
            consumptionSeries,
            excessExportedGenerationSeries,
            exportedEnergySeries,
            generationSeries,
            nettedExportedGenerationSeries,
            newDemandSeries,
            originalDemandSeries,
            selfConsumptionSeries,
            socSeries,
            solarPotentialSeries,
        },
    } = getSeriesByOption({
        data: {
            ...rest,
            excludedSeriesFromMinMax: ['socSeries'],
            returnMinAndMax: showBatteries,
            selectedOption,
            series: seriesData,
        },
        options: {
            0: populateYearlyProposalEnergySeries,
            1: populatePeriodicallySeries,
            2: populateWeeklySeries,
            3: populateDailySeries,
        },
    });

    const series = [
        {
            color: ENERGY_CHART_COLORS[0],
            data: consumptionSeries || [],
            id: 'original-consumption',
            name: `${i18next.t('Original consumption')} (kWh)`,
            type: chartType,
        },
        {
            color: ENERGY_CHART_COLORS[1],
            data: selfConsumptionSeries || [],
            name: `${i18next.t('Self-consumption')} (kWh)`,
            type: chartType,
        },
        {
            color: ENERGY_CHART_COLORS[2],
            data: generationSeries || [],
            name: `${i18next.t('Generation')} (kWh)`,
            type: chartType,
        },
        {
            color: ENERGY_CHART_COLORS[3],
            data: exportedEnergySeries || [],
            name: `${i18next.t('Exported solar generation')} (kWh)`,
            type: chartType,
        },
        {
            color: ENERGY_CHART_COLORS[4],
            data: consumptionFromGridSeries || [],
            name: `${i18next.t('Consumption from Grid')} (kWh)`,
            type: chartType,
        },
    ];

    if (solarPotentialSeries?.length)
        series.push({
            color: ENERGY_CHART_COLORS[5],
            dash: 10,
            data: solarPotentialSeries || [],
            name: `${i18next.t('Solar potential')} (kWh)`,
            opacity: 1,
            type: 'line',
        });

    if (batteryPowerSeries?.length)
        series.push({
            color: ENERGY_CHART_COLORS[6],
            data: batteryPowerSeries || [],
            name: `${i18next.t('Battery power')} (kW)`,
            type: chartType,
        });

    if (socSeries?.length)
        series.push({
            color: ENERGY_CHART_COLORS[7],
            curve: 'straight',
            dash: 10,
            data: socSeries || [],
            name: 'SOC (%)',
            opacity: 1,
            type: 'line',
        });

    if (originalDemandSeries?.length)
        series.push(
            {
                color: ENERGY_CHART_COLORS[10],
                data: originalDemandSeries || [],
                name: `${i18next.t('Original demand')} (kW)`,
                opacity: 1,
                type: 'line',
            },
            {
                color: ENERGY_CHART_COLORS[11],
                data: newDemandSeries || [],
                name: `${i18next.t('New demand')} (kW)`,
                opacity: 1,
                type: 'line',
            },
        );

    if (nettedExportedGenerationSeries?.length)
        series.push(
            {
                color: ENERGY_CHART_COLORS[8],
                data: nettedExportedGenerationSeries || [],
                name: `${i18next.t('Netted exported generation')} (kWh)`,
                type: chartType,
            },
            {
                color: ENERGY_CHART_COLORS[9],
                data: excessExportedGenerationSeries || [],
                name: `${i18next.t('Excess exported generation')} (kWh)`,
                type: chartType,
            },
        );

    return { maxValue, minValue, series };
};

// Format data
export const getDataProfileFormatted = (data, year) =>
    data.reduce(
        (accum, curr) => {
            const month = accum.currMonth;
            const day = accum.currDay;
            const hour = accum.currHour;

            if (!accum.data[month]) accum.data[month] = { total: 0 };

            if (!accum.data[month][day]) accum.data[month][day] = { total: 0 };

            if (!accum.data[month][day][hour]) accum.data[month][day][hour] = 0;

            const value = Number(curr) || 0;

            accum.data[month][day][hour] += value;
            accum.data[month][day].total += value;
            accum.data[month].total += value;

            if (hour === 23) {
                accum.data[month][day].day = new Date(
                    year,
                    month - 1,
                    day,
                ).getDay();

                /**
                 * If it is december and the year is leap, the last day of the month is 30
                 * otherwise, the last day of the month is the last day of the month
                 */
                const totalDays =
                    month === 12 && isLeapYear(year)
                        ? 30
                        : new Date(year, month, 0).getDate();

                if (day === totalDays) accum.currMonth++;

                accum.currDay = day === totalDays ? 1 : day + 1;
            }

            accum.currHour = hour === 23 ? 0 : hour + 1;

            return accum;
        },
        { currDay: 1, currHour: 0, currMonth: 1, data: {} },
    ).data;

export const getDataProfileFormattedByPeriods = ({
    dateFormat = 'dd/MM/yyyy',
    localeForDateFns = getLocaleEquivalenceForDateFns(),
    profile,
    summary,
} = {}) => {
    const profileFormattedByPeriods = [];
    const pathByHourDictionary = {};
    let hoursCounter = 0;
    let firstDay = null;

    // We need to add one day because the interval does not include the first day but includes the last day
    const intervalToShiftDate = MS_IN_HOUR * HOURS_IN_DAY;

    for (let index = 0; index < summary.length; index++) {
        const { final_date, initial_date } = summary[index];

        const initialUTCdate = new Date(
            shiftUTCDateString({
                date: initial_date,
                dateFormat,
                localeForDateFns,
                interval: intervalToShiftDate,
            }),
        );

        const finalUTCdate = new Date(
            shiftUTCDateString({
                date: summary[index + 1]?.initial_date || final_date,
                dateFormat,
                interval: intervalToShiftDate,
                localeForDateFns,
            }),
        );

        let dayCounter = 0;
        for (
            let date = initialUTCdate;
            date < finalUTCdate;
            date.setHours(date.getHours() + 1)
        ) {
            const year = date.getUTCFullYear();
            const month = date.getUTCMonth();
            const day = date.getUTCDate();

            if (isInvalidLeapYearDay({ day, month, year })) continue;

            const dayType = date.getUTCDay();
            const hour = date.getUTCHours();

            if (!profileFormattedByPeriods[index])
                profileFormattedByPeriods[index] = [];
            if (!profileFormattedByPeriods[index][dayCounter])
                profileFormattedByPeriods[index][dayCounter] = {};
            if (!profileFormattedByPeriods[index][dayCounter].day)
                profileFormattedByPeriods[index][dayCounter].day = dayType;
            if (firstDay === null) firstDay = dayType;

            profileFormattedByPeriods[index][dayCounter][hour] =
                profile[hoursCounter];

            profileFormattedByPeriods[index][dayCounter].total =
                (profileFormattedByPeriods[index][dayCounter].total || 0) +
                profile[hoursCounter];

            pathByHourDictionary[hoursCounter] =
                `${index}.${dayCounter}.${hour}`;

            if (hour === 23) dayCounter++;
            hoursCounter++;
        }
    }

    return { firstDay, pathByHourDictionary, profileFormattedByPeriods };
};

export const addMissingEnergyIndex = ({
    dischargePower,
    emptyEnergyIndexes,
    energyTransferred,
    excessEnergyFromGrid,
    excessPowerFromGrid,
    maxBatteryPower,
    path,
    year,
}) => {
    const missingEnergy = excessEnergyFromGrid + energyTransferred || 0;
    const missingPower = Math.max(
        excessPowerFromGrid - dischargePower || 0,
        excessPowerFromGrid - maxBatteryPower || 0,
        0,
    );

    const epsilon = 0.001;
    if (missingEnergy < epsilon && missingPower < epsilon) return;

    emptyEnergyIndexes.push({ missingEnergy, missingPower, path, year });
};

export const getSolarSimulationData = ({
    consumptionHistory,
    consumptionProfile,
    dataTimeShifting,
    demandArray,
    generationDelay,
    generationProfile,
    hourlyBatteryStatus,
    isBimonthly,
    panelDegradation,
    pathByHourDictionary,
    pvSystemDataSource,
    realIndex: realIndexObject,
    schedule_rate_configuration,
    zeroExport,
} = {}) => {
    if (
        !consumptionHistory?.length ||
        !consumptionProfile?.length ||
        !generationProfile?.length
    )
        return {};

    const {
        algorithm,
        c_rate,
        charge_limits,
        charging_algorithm = BATTERY_OPERATION_STRATEGY.OPTIMAL,
        controlled_charging_is_active,
        controlled_discharging_is_active,
        degradation_percentage,
        discharge_limits,
        discharge_power,
        dod,
        efficiency,
        exported_energy_charge,
        load_capacity,
        peak_shaving,
        peak_shaving_is_active,
    } = dataTimeShifting || {};

    const consecutiveHoursLeft =
        getBatteryConsecutiveHoursLeft(hourlyBatteryStatus);

    const { initialIndex, maxIndex } = getInitialAndMaxIndex({
        delay: isBimonthly ? generationDelay / 2 : generationDelay,
        realIndexObject,
    });

    const chargeLimits = getActiveTierFieldsValues(
        controlled_charging_is_active,
        charge_limits,
    );
    const dischargeLimits = getActiveTierFieldsValues(
        controlled_discharging_is_active,
        discharge_limits,
    );
    const peakShaving = getActiveTierFieldsValues(
        peak_shaving_is_active,
        peak_shaving,
    );

    let tiersDemandDistribution = [];
    if (
        !isEmpty(chargeLimits) ||
        !isEmpty(dischargeLimits) ||
        !isEmpty(peakShaving)
    ) {
        tiersDemandDistribution = getTiersDistribution({
            summary: consumptionHistory[0],
            tiersDistribution:
                schedule_rate_configuration?.tiers_demand_distribution?.[0],
        });
    }

    const totalYears = consumptionHistory.length;
    const totalHours = totalYears * HOURS_IN_YEAR;
    const batteryDegradationRatio = degradation_percentage / HOURS_IN_YEAR;
    const batteryDemand = createFilledArray(totalHours, 0);
    const consumption = createFilledArray(totalHours, 0);
    const consumptionFromGrid = createFilledArray(totalHours, 0);
    const emptyEnergyIndexes = [];
    const exportedEnergy = createFilledArray(totalHours, 0);
    const generation = createFilledArray(totalHours, 0);
    const maxBatteryPower = load_capacity * c_rate;
    const newDemand = createFilledArray(totalHours, 0);
    const originalDemand = createFilledArray(totalHours, 0);
    const panelDegradationRatio = panelDegradation / HOURS_IN_YEAR;
    const selfConsumption = createFilledArray(totalHours, 0);
    const socArray = createFilledArray(totalHours, 0);
    const solarPotential = createFilledArray(totalHours, 0);
    const yearsWithoutSolarDegradation =
        pvSystemDataSource === GENERATION_SOURCES.NASA ? 0 : 1;
    let batteryEnergy = load_capacity;
    let realLoadCapacity = load_capacity;
    const totalsYearOne = {
        consumption: 0,
        consumptionFromGrid: 0,
        exportedEnergy: 0,
        generation: 0,
        selfConsumption: 0,
        solarPotential: 0,
    };

    for (let year = 0; year < totalYears; year++) {
        const yearInfo = consumptionHistory[year];

        const annualConsumption =
            yearInfo?.reduce((acc, curr) => acc + curr.consumption, 0) / 100 ||
            0;

        const annualGeneration =
            yearInfo?.reduce(
                (acc, curr) => acc + curr.generationInPeriod || curr.generation,
                0,
            ) / 100 || 0;
        const baseIndex = year * HOURS_IN_YEAR;
        const maxYearIndex = baseIndex + maxIndex;
        for (let index = 0; index < HOURS_IN_YEAR; index++) {
            const consumptionPercentage = consumptionProfile[index] || 0;
            const realIndex = baseIndex + index;
            /** Begins solar simulation **/
            const hourlyConsumption = annualConsumption * consumptionPercentage;
            const hourlyGeneration =
                realIndex >= initialIndex
                    ? annualGeneration *
                      generationProfile[index] *
                      getNormalizedDegradation({
                          degradationRatio: panelDegradationRatio,
                          index: index - initialIndex,
                          selectedYear: year - yearsWithoutSolarDegradation,
                      })
                    : 0;

            consumption[realIndex] = hourlyConsumption;
            generation[realIndex] = hourlyGeneration;
            solarPotential[realIndex] = hourlyGeneration;

            const consumGenDiff = hourlyConsumption - hourlyGeneration;
            let consumptionFromGridValue = consumGenDiff; //Consumption from the grid
            let excessGeneration = 0; //Generation that is not consumed and is exported to the grid

            if (consumGenDiff < 0) {
                consumptionFromGridValue = 0;
                excessGeneration = consumGenDiff * -1;
            }
            /** End of solar simulation **/

            /** Begins the battery simulation **/
            const hourlyDemand = demandArray?.[index] || hourlyConsumption;
            originalDemand[realIndex] = hourlyDemand;

            const energyPowerRatio = hourlyDemand
                ? hourlyConsumption / hourlyDemand || 0
                : 0;

            const calcPowerFromEnergy = (energy) =>
                energyPowerRatio ? energy / energyPowerRatio || 0 : 0;
            const calcEnergyFromPower = (power) =>
                power * energyPowerRatio || 0;

            const powerFromGrid = calcPowerFromEnergy(consumptionFromGridValue);

            // This is the power that the battery should discharge to avoid exceeding the limit of the grid
            const excessPowerFromGrid =
                powerFromGrid -
                (peakShaving?.[tiersDemandDistribution[index]] ??
                    Number.MAX_SAFE_INTEGER);
            const chargeWithGeneration =
                exported_energy_charge && excessGeneration > 0;
            let energyTransferred = 0; //Total energy transferred to the battery, positive if charging, negative if discharging
            let generationUsedByBattery = 0; //Generation used to charge the battery
            let realEnergyTransferred = 0; //Energy transferred to the battery considering the losses by efficiency

            if (realIndex >= initialIndex && realIndex <= maxYearIndex) {
                if (
                    peak_shaving_is_active &&
                    excessPowerFromGrid > 0 &&
                    hourlyBatteryStatus?.[index] !== BATTERY_STATUS.DISCHARGING
                ) {
                    /** Force discharge by peak shaving  **/
                    const manualDischargeLimit =
                        dischargeLimits?.[tiersDemandDistribution[index]];
                    const energyLimit =
                        batteryEnergy - (1 - dod) * realLoadCapacity;

                    let currentBatteryPower = 0;
                    if (energyLimit > 0)
                        currentBatteryPower =
                            -Math.min(
                                discharge_power,
                                calcPowerFromEnergy(energyLimit),
                                excessPowerFromGrid,
                                manualDischargeLimit ?? maxBatteryPower,
                                maxBatteryPower,
                            ) || 0;

                    realEnergyTransferred =
                        calcEnergyFromPower(currentBatteryPower);

                    energyTransferred = realEnergyTransferred || 0;

                    addMissingEnergyIndex({
                        dischargePower: discharge_power,
                        emptyEnergyIndexes,
                        energyTransferred,
                        excessEnergyFromGrid:
                            calcEnergyFromPower(excessPowerFromGrid),
                        excessPowerFromGrid,
                        maxBatteryPower,
                        path: pathByHourDictionary[index],
                        year,
                    });
                } else if (
                    hourlyBatteryStatus?.[index] === BATTERY_STATUS.CHARGING ||
                    chargeWithGeneration
                ) {
                    /** Charge by status or by generation **/
                    if (batteryEnergy < realLoadCapacity) {
                        realLoadCapacity =
                            load_capacity *
                            getNormalizedDegradation({
                                degradationRatio: batteryDegradationRatio,
                                index: index - initialIndex,
                                selectedYear: year,
                            });
                        // if the battery is degraded and the energy is higher than the capacity, the capacity will be the energy stored
                        if (realLoadCapacity < batteryEnergy)
                            realLoadCapacity = batteryEnergy;

                        const energyToFullLoad =
                            realLoadCapacity - batteryEnergy || 0;
                        const manualChargeLimit =
                            chargeLimits?.[tiersDemandDistribution[index]];
                        let maxEnergyToCharge = getMaxEnergyByAlgorithm({
                            algorithm: charging_algorithm,
                            energy: energyToFullLoad,
                            hoursLeft: consecutiveHoursLeft[index],
                        });

                        const realExcessGeneration =
                            excessGeneration * efficiency;

                        if (
                            realExcessGeneration > maxEnergyToCharge ||
                            (chargeWithGeneration &&
                                hourlyBatteryStatus?.[index] !==
                                    BATTERY_STATUS.CHARGING)
                        )
                            maxEnergyToCharge = realExcessGeneration;

                        realEnergyTransferred =
                            Math.min(
                                discharge_power * efficiency,
                                energyToFullLoad,
                                manualChargeLimit ?? maxBatteryPower,
                                maxBatteryPower,
                                maxEnergyToCharge,
                            ) || 0;

                        if (peak_shaving_is_active) {
                            // The minimum energy that the battery can charge to avoid exceeding the limit of the grid
                            realEnergyTransferred =
                                Math.min(
                                    realEnergyTransferred,
                                    realExcessGeneration -
                                        calcEnergyFromPower(
                                            excessPowerFromGrid,
                                        ) *
                                            efficiency,
                                ) || 0;
                        }
                    }

                    energyTransferred = realEnergyTransferred / efficiency || 0;

                    generationUsedByBattery =
                        Math.min(energyTransferred, excessGeneration) || 0;
                } else if (
                    hourlyBatteryStatus?.[index] === BATTERY_STATUS.DISCHARGING
                ) {
                    /** Discharge by status **/
                    const availableEnergy = // Energy available to discharge
                        batteryEnergy - (1 - dod) * realLoadCapacity;
                    const energyLimit = getMaxEnergyByAlgorithm({
                        algorithm,
                        energy: availableEnergy,
                        hoursLeft: consecutiveHoursLeft[index],
                    });

                    let currentBatteryPower = 0;
                    if (energyLimit >= 0) {
                        /*
                         *The battery should try to supply the energy needed by the grid to avoid exceeding the limit
                         *If the battery is not able to supply all the energy needed, the grid will supply the rest
                         */
                        const powerToDownload = Math.max(
                            calcPowerFromEnergy(energyLimit),
                            excessPowerFromGrid,
                            0,
                        );
                        const manualDischargeLimit =
                            dischargeLimits?.[tiersDemandDistribution[index]];

                        currentBatteryPower =
                            -Math.min(
                                calcPowerFromEnergy(availableEnergy),
                                discharge_power,
                                manualDischargeLimit ?? maxBatteryPower,
                                maxBatteryPower,
                                powerFromGrid,
                                powerToDownload,
                            ) || 0;
                    }

                    realEnergyTransferred =
                        calcEnergyFromPower(currentBatteryPower);

                    energyTransferred = realEnergyTransferred;

                    addMissingEnergyIndex({
                        dischargePower: discharge_power,
                        emptyEnergyIndexes,
                        energyTransferred,
                        excessEnergyFromGrid:
                            calcEnergyFromPower(excessPowerFromGrid),
                        excessPowerFromGrid,
                        maxBatteryPower,
                        path: pathByHourDictionary[index],
                        year,
                    });
                }
            }

            batteryEnergy += realEnergyTransferred;
            const gridPowerForBattery = calcPowerFromEnergy(
                generationUsedByBattery - energyTransferred,
            );

            batteryDemand[realIndex] =
                gridPowerForBattery - generationUsedByBattery;
            socArray[realIndex] = 100 * (batteryEnergy / realLoadCapacity);
            newDemand[realIndex] = powerFromGrid - gridPowerForBattery;
            /** End of battery simulation **/

            /**  Energy balance **/
            if (consumGenDiff < 0) {
                //Generation is higher than consumption
                excessGeneration -= generationUsedByBattery;
                if (!zeroExport) {
                    exportedEnergy[realIndex] = excessGeneration;
                } else {
                    generation[realIndex] -= excessGeneration;
                }
                consumptionFromGrid[realIndex] =
                    energyTransferred - generationUsedByBattery;
                selfConsumption[realIndex] =
                    hourlyConsumption + generationUsedByBattery;
            } else {
                //Consumption is higher than generation
                consumptionFromGrid[realIndex] =
                    consumGenDiff + energyTransferred;

                selfConsumption[realIndex] = hourlyGeneration;
            }

            if (year === 0) {
                totalsYearOne.consumption += consumption[realIndex];
                totalsYearOne.consumptionFromGrid +=
                    consumptionFromGrid[realIndex];
                totalsYearOne.exportedEnergy += exportedEnergy[realIndex];
                totalsYearOne.generation += generation[realIndex];
                totalsYearOne.selfConsumption += selfConsumption[realIndex];
                totalsYearOne.solarPotential += solarPotential[realIndex];
            }
        }
    }

    return {
        batteryDemand,
        consumption,
        consumptionFromGrid,
        emptyEnergyIndexes,
        exportedEnergy,
        generation,
        hasBatteries: load_capacity > 0,
        hasDemand: demandArray?.length > 0,
        newDemand,
        originalDemand,
        selfConsumption,
        socArray,
        solarPotential,
        totalsYearOne,
    };
};

export const hexToRgba = (hex, alpha = 1, object = false) => {
    if (!hex) return;
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    if (object) return { r, g, b, a: alpha };
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const getGradient = ({
    color,
    context,
    opacityFrom = 0.1,
    opacityTo = 0.9,
    shadeIntensity = 1,
    stops = [0, 100, 100],
} = {}) => {
    if (!color) return;
    const chart = context.chart;
    const { ctx, chartArea } = chart;

    // This case happens on initial chart load
    if (!chartArea) return;

    let gradient = ctx.createLinearGradient(
        0,
        chartArea.bottom,
        0,
        chartArea.top,
    );
    // Calcular los colores con el shadeIntensity y opacidades
    const { r, g, b } = hexToRgba(color, 1, true);
    const colorFrom = `rgba(${r * shadeIntensity}, ${g * shadeIntensity}, ${
        b * shadeIntensity
    }, ${opacityFrom})`; // Rojo con la intensidad y opacidad inicial
    const colorTo = `rgba(${r * shadeIntensity}, ${g * shadeIntensity}, ${
        b * shadeIntensity
    }, ${opacityTo})`; // Rojo con la intensidad y opacidad final

    // Añadir los stops al gradiente
    gradient.addColorStop(stops[0] / 100, colorFrom);
    gradient.addColorStop(stops[1] / 100, colorTo);
    gradient.addColorStop(stops[2] / 100, colorTo);
    return gradient;
};

const buildEnergyPoolChartProps = ({
    chartControlsConfig,
    countryCurrencyLocale,
    currentChartConfig,
    defaultVisibility,
    handleOnLegendClick,
}) => {
    const series = currentChartConfig.series.map((serie) => serie);
    const data = {
        datasets: series.map(
            ({ color, data, name: label, type, stack = undefined }) => {
                const extraConfig = {};
                const hidden =
                    (!isEmpty(defaultVisibility) &&
                        !defaultVisibility?.includes(label)) ||
                    defaultVisibility?.length === 0;
                return {
                    ...extraConfig,
                    backgroundColor: color,
                    borderColor: color,
                    data,
                    hidden,
                    label,
                    stack,
                    type,
                };
            },
        ),
        labels: currentChartConfig.options.xaxis?.categories || [],
    };

    const options = {
        type: 'bar',
        plugins: {
            ...(handleOnLegendClick && {
                legend: {
                    onClick: function (_, legendItem) {
                        const chart = this.chart;
                        const label = legendItem.text;
                        handleOnLegendClick({
                            label,
                            series: chart.data.datasets,
                        });
                    },
                },
            }),
            tooltip: {
                callbacks: {
                    label: (context) => {
                        return (
                            context.dataset.label +
                            ': ' +
                            numberFormat(context.parsed.y, {
                                decimals:
                                    chartControlsConfig.option === 0 ? 0 : 3,
                                locale: countryCurrencyLocale,
                                style: 'decimal',
                                unit: '',
                            })
                        );
                    },
                },
            },
        },
        scales: {
            x: { grid: { display: false } },
            y: {
                stacked: true,
                ticks: {
                    callback: (val) =>
                        numberFormat(val, {
                            locale: countryCurrencyLocale,
                            style: 'decimal',
                            unit: 'kWh',
                        }),
                    count: null,
                },
            },
        },
    };

    return { data, key: 'chartBag', options, series };
};

export const buildChartJsProps = ({
    chartControlsConfig,
    chartId,
    countryCurrencyIso,
    countryCurrencyLocale,
    currentChartConfig,
    defaultVisibility,
    handleOnLegendClick,
    isSelfConsumption,
    selectedStacks,
} = {}) => {
    if (
        chartId === 'chartCurrentEconomic' &&
        selectedStacks === 'chartProposedEnergyBag'
    ) {
        return buildEnergyPoolChartProps({
            chartControlsConfig,
            countryCurrencyIso,
            countryCurrencyLocale,
            currentChartConfig,
            defaultVisibility: defaultVisibility[chartId],
            handleOnLegendClick,
        });
    }

    let labelsMap = [];
    let series = currentChartConfig?.series;
    let yAxisIDs = new Set(['y']);
    let extraOptionsScaleY = {};
    let extraOptionsScaleY1 = {};

    if (!series)
        return { series: [], data: { datasets: [], labels: [] }, options: {} };

    const colors =
        chartId === 'chartEnergy' && chartControlsConfig?.option === 0
            ? ENERGY_CHART_COLORS
            : CHART_COLORS;

    if (chartId !== 'chartEnergy') {
        series = series.map((serie, index) => {
            const label = labelsMap.find((label) => label.label === serie.name);
            const color = label?.color || colors[index % colors.length];
            if (!label) labelsMap.push({ label: serie.name, color });

            return { ...serie, color };
        });
    }

    const _defaultVisibility = defaultVisibility?.[chartId];

    let data = {
        datasets: series.map(
            (
                { color, data, id, name: label, type, stack = undefined },
                index,
            ) => {
                const _color = color ? color : colors[index % colors.length];
                const extraConfig = {};
                const hidden =
                    (!isEmpty(_defaultVisibility) &&
                        !_defaultVisibility?.includes(label)) ||
                    _defaultVisibility?.length === 0;
                let backgroundColor = hexToRgba(_color, 0.8);
                let fill = false;
                let _type = type;
                let yAxisID = 'y';

                if (type === 'column') _type = 'bar';
                if (type === 'area') _type = 'line';

                if (currentChartConfig.key !== 'energy-chart-NETMET')
                    fill = _type !== 'line';

                if (type === 'area') {
                    fill = true;
                    backgroundColor = function (context) {
                        return getGradient({ color, context });
                    };
                }

                if (label === 'SOC (%)') {
                    extraConfig.borderDash = [5, 5];
                    yAxisID = 'SOC (%)';
                    yAxisIDs.add(yAxisID);

                    if (currentChartConfig?.options?.yaxis?.[index]) {
                        extraOptionsScaleY1 = {
                            max: currentChartConfig?.options?.yaxis[index].max,
                            min: Math.ceil(
                                currentChartConfig?.options?.yaxis[index].min,
                            ),
                        };
                    }
                } else {
                    if (currentChartConfig?.options?.yaxis?.[index]) {
                        extraOptionsScaleY = {
                            max: currentChartConfig?.options?.yaxis[index].max,
                            min: currentChartConfig?.options?.yaxis[index].min,
                        };
                    }
                }

                return {
                    ...extraConfig,
                    backgroundColor,
                    borderColor: _color,
                    data,
                    fill,
                    hidden,
                    id,
                    label,
                    stack,
                    type: _type,
                    yAxisID,
                };
            },
        ),
        labels: currentChartConfig.options.xaxis?.categories || [],
    };

    let options = {
        interaction: { axis: 'x', intersect: false, mode: 'nearest' },
        plugins: {
            ...(handleOnLegendClick && {
                legend: {
                    onClick: function (_, legendItem) {
                        const chart = this.chart;
                        const label = legendItem.text;
                        handleOnLegendClick({
                            label,
                            series: chart.data.datasets,
                        });
                    },
                },
            }),
            tooltip: {
                callbacks: {
                    label: (context) => {
                        return (
                            context.dataset.label +
                            ': ' +
                            numberFormat(context.parsed.y, {
                                decimals:
                                    chartControlsConfig?.option === 0 ? 0 : 3,
                                locale: countryCurrencyLocale,
                                style: 'decimal',
                                unit: '',
                            })
                        );
                    },
                    ...(chartControlsConfig?.option === 0
                        ? {
                              title: (context) => {
                                  const ctx = context[0];
                                  return `${getTooltipDateRange({
                                      index: ctx.dataIndex,
                                      seriesEnergy: isSelfConsumption
                                          ? currentChartConfig.options
                                                .consumptionHistory[
                                                chartControlsConfig?.year || 0
                                            ]
                                          : currentChartConfig.options
                                                .consumptionHistory,
                                  })}`;
                              },
                          }
                        : {}),
                },
            },
        },
        scales: {
            x: { grid: { display: false } },
            y: {
                ...extraOptionsScaleY,
                ticks: {
                    callback: (val) =>
                        numberFormat(val, {
                            decimals: chartControlsConfig?.option === 3 ? 3 : 0,
                            locale: countryCurrencyLocale,
                            style: 'decimal',
                        }),
                },
                title: {
                    display: true,
                    text: chartControlsConfig?.option === 3 ? 'kW' : 'kWh',
                },
            },
            'SOC (%)': {
                ...extraOptionsScaleY1,
                display: yAxisIDs.has('SOC (%)'),
                grid: { drawOnChartArea: false },
                position: 'right',
                ticks: {
                    callback: (val) =>
                        numberFormat(val, {
                            decimals: 0,
                            locale: countryCurrencyLocale,
                            style: 'decimal',
                            unit: '%',
                        }),
                },
            },
        },
        type: 'line',
    };

    const datasetsCopy = data.datasets;

    if (currentChartConfig.key === 'chartEconomic') {
        options = {
            ...options,
            type: 'bar',
            plugins: {
                legend: {
                    ...options.plugins.legend,
                    onHover: function (_, legendItem) {
                        const chart = this.chart;
                        const label = legendItem.text;
                        chart.data.datasets.forEach((dataset) => {
                            if (!dataset.backgroundColor) return;
                            if (dataset.label !== label) {
                                dataset.backgroundColor = 'rgba(0,0,0,0.05)';
                                dataset.borderColor = 'rgba(0,0,0,0)';
                            }
                        });
                        chart.update();
                    },
                    onLeave: function () {
                        const chart = this.chart;
                        chart.data.datasets.forEach((dataset, index) => {
                            if (!dataset.backgroundColor) return;
                            dataset.backgroundColor =
                                datasetsCopy[index].backgroundColor;
                            dataset.borderColor =
                                datasetsCopy[index].borderColor;
                        });
                        chart.update();
                    },
                    labels: {
                        generateLabels: (chart) => {
                            const {
                                labels: {
                                    color,
                                    useBorderRadius,
                                    borderRadius,
                                },
                            } = chart.legend.options;
                            const datasetLabels = chart.data.datasets.map(
                                (dataset) => dataset.label,
                            );
                            const uniqueLabels = [...new Set(datasetLabels)];
                            const labels = uniqueLabels.map((text) => {
                                const datasetIndex =
                                    datasetLabels.indexOf(text);
                                const dataset =
                                    chart.data.datasets[datasetIndex];
                                let hidden = false;
                                if (
                                    _defaultVisibility &&
                                    _defaultVisibility.length >= 0
                                ) {
                                    hidden =
                                        !_defaultVisibility?.includes(text);
                                }
                                return {
                                    borderRadius,
                                    boxHeight: 12,
                                    boxWidth: 12,
                                    fillStyle: dataset.backgroundColor,
                                    fontColor: color,
                                    hidden,
                                    strokeStyle: dataset.backgroundColor,
                                    text,
                                    useBorderRadius,
                                };
                            });
                            return labels;
                        },
                    },
                },
                tooltip: {
                    callbacks: {
                        beforeTitle: (context) => {
                            const ctx = context[0];
                            if (ctx.dataset.stack === 'current')
                                return i18next.t('Current');
                            if (ctx.dataset.stack === 'proposed')
                                return i18next.t('Proposed', {
                                    context: 'female',
                                });
                        },
                        label: (context) => {
                            if (selectedStacks === 'chartProposedEnergyBag') {
                                return (
                                    context.dataset.label +
                                    ': ' +
                                    numberFormat(context.parsed.y, {
                                        locale: countryCurrencyLocale,
                                        style: 'decimal',
                                        unit: 'kWh',
                                    })
                                );
                            }

                            return (
                                context.dataset.label +
                                ': ' +
                                numberFormat(context.parsed.y, {
                                    currency: countryCurrencyIso,
                                    locale: countryCurrencyLocale,
                                    style: 'currency',
                                })
                            );
                        },
                        title: (context) => {
                            const ctx = context[0];

                            if (selectedStacks === 'chartProposedEnergyBag')
                                return `${getTooltipDateRange({
                                    index: ctx.dataIndex,
                                    seriesEnergy:
                                        currentChartConfig.options
                                            .consumptionHistory,
                                })}`;

                            const values = ctx.parsed._stacks.y._visualValues;
                            const stack = ctx.dataset.stack;
                            const _metasets = ctx.chart._metasets.filter(
                                (set) => set.stack === stack,
                            );
                            const valuesLength = Object.keys(values).length - 1;
                            let total = 0;
                            for (let i = 0; i <= valuesLength; i++) {
                                const dataset = _metasets[i];
                                const datasetIndex = dataset.index;
                                const hidden =
                                    (!isEmpty(_defaultVisibility) &&
                                        !_defaultVisibility?.includes(
                                            dataset.label,
                                        )) ||
                                    _defaultVisibility?.length === 0;
                                if (hidden) continue;
                                total += values[datasetIndex];
                            }
                            const formattedTotal = numberFormat(total, {
                                currency: countryCurrencyIso,
                                locale: countryCurrencyLocale,
                                style: 'currency',
                            });

                            return `${getTooltipDateRange({
                                index: ctx.dataIndex,
                                seriesEnergy:
                                    currentChartConfig.options
                                        .consumptionHistory,
                            })} - ${formattedTotal}`;
                        },
                    },
                },
            },
            scales: {
                x: { grid: { display: false } },
                y: {
                    stacked: selectedStacks !== 'chartProposedEnergyBag',
                    ticks: {
                        callback: (val) => {
                            if (selectedStacks === 'chartProposedEnergyBag') {
                                return numberFormat(val, {
                                    locale: countryCurrencyLocale,
                                    style: 'decimal',
                                    unit: 'kWh',
                                });
                            }

                            return numberFormat(val, {
                                currency: countryCurrencyIso,
                                locale: countryCurrencyLocale,
                                style: 'currency',
                            });
                        },
                        count: null,
                        maxTicksLimit: 10,
                    },
                },
            },
        };
    }

    return { data, key: currentChartConfig?.key, options, series };
};

export const exportChartToCSV = (data) => {
    const labels = data.labels;
    const datasets = data.datasets;

    let csvContent = 'data:text/csv;charset=utf-8,';

    csvContent +=
        'category,' + datasets.map((dataset) => dataset.label).join(',') + '\n';

    labels.forEach((label, index) => {
        const row = [label];
        datasets.forEach((dataset) => {
            row.push(dataset.data[index]);
        });
        csvContent += row.join(',') + '\n';
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'chart-data.csv');
    document.body.appendChild(link);

    link.click();
};
