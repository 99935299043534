import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import {
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import { getCountryCurrencySymbol } from 'common/utils/helpers/session';

import { Label } from '../styledComponents';

const DefaultSettings = ({
    baseName = '',
    compensationSchemesOptions,
    control,
    initialValues,
    showMode,
}) => {
    const { t } = useTranslation();
    const countryCurrencySymbol = getCountryCurrencySymbol();

    return (
        <Grid container py={2}>
            <Grid size={{ lg: 6, md: 8, sm: 9, xs: 18 }}>
                <Label>{t('Energy export price')}:</Label>
            </Grid>

            <Grid size={{ lg: 12, md: 10, sm: 9, xs: 18 }}>
                <ReactHookFormIntlNumberInput
                    allowNegativeValue={false}
                    control={control}
                    disabled={showMode}
                    key={initialValues?.energy_export_price}
                    name={`${baseName}energy_export_price`}
                    placeholder="0"
                    prepend={countryCurrencySymbol}
                />
            </Grid>

            <Grid size={{ lg: 6, md: 8, sm: 9, xs: 18 }}>
                <Label>{t('Netted exported generation price')}:</Label>
            </Grid>

            <Grid size={{ lg: 12, md: 10, sm: 9, xs: 18 }}>
                <ReactHookFormIntlNumberInput
                    allowNegativeValue={false}
                    control={control}
                    disabled={showMode}
                    key={initialValues?.energy_export_price}
                    name={`${baseName}netted_exported_generation_price`}
                    placeholder="0"
                    prepend={countryCurrencySymbol}
                />
            </Grid>

            <Grid size={{ lg: 6, md: 8, sm: 9, xs: 18 }}>
                <Label>{t('Energy compensation scheme')}:</Label>
            </Grid>

            <Grid size={{ sm: 'grow', xs: 18 }}>
                <ReactHookFormSelect
                    control={control}
                    disabled={showMode}
                    key={initialValues?.default_compensation_scheme}
                    name={`${baseName}default_compensation_scheme`}
                    options={compensationSchemesOptions}
                    sxFormControl={{ maxWidth: '450px' }}
                />
            </Grid>
        </Grid>
    );
};

DefaultSettings.propTypes = {
    baseName: PropTypes.string,
    compensationSchemesOptions: PropTypes.array,
    control: PropTypes.object,
    initialValues: PropTypes.object,
    showMode: PropTypes.bool,
};

export default DefaultSettings;
