import React from 'react';

import PropTypes from 'prop-types';
import { Box, Card, Divider, Grid, Skeleton } from 'sunwise-ui';

const buildSkeletonStruct = (rows) => {
    const rowsItems = [];

    for (let i = 0; i < rows; i++) {
        rowsItems.push(
            <Box key={`Grid-skeleton-${i}`} sx={{ my: 2 }}>
                <Grid size={{ xs: 'grow' }}>
                    <Skeleton variant="rounded" />
                </Grid>
            </Box>,
        );
    }

    return rowsItems;
};

const Placeholder = (
    <Card>
        <Card.Body>
            <Skeleton variant="rounded" />

            <Divider sx={{ my: 2 }} />

            <Grid container sx={{ mb: 2 }}>
                <Grid size={{ xs: 2 }}>
                    <Skeleton variant="rounded" />
                </Grid>

                <Grid size={{ xs: 16 }}>
                    <Skeleton variant="rounded" />
                </Grid>
            </Grid>

            <Grid container>
                <Grid size={{ xs: 2 }}>
                    <Skeleton variant="rounded" />
                </Grid>

                <Grid size={{ xs: 16 }}>
                    <Skeleton variant="rounded" />
                </Grid>
            </Grid>

            {buildSkeletonStruct(10)}
        </Card.Body>
    </Card>
);

const RateDetailCardPlaceholder = ({ children, ready }) => (
    <>{ready ? Placeholder : children}</>
);

RateDetailCardPlaceholder.propTypes = {
    children: PropTypes.element,
    ready: PropTypes.bool,
};

export default RateDetailCardPlaceholder;
