import { downloadGeoTIFF } from './actions';
import {
    binaryPalette,
    ironPalette,
    rainbowPalette,
    sunlightPalette,
} from './constants';
import { renderPalette, renderRGB } from './visualize';

export async function getLayer({ googleMapsApiKey, layerId, segmentId, urls }) {
    const get = {
        mask: async () => {
            const mask = await downloadGeoTIFF(urls.maskUrl, googleMapsApiKey);
            const colors = binaryPalette;
            return {
                bounds: mask.bounds,
                id: layerId,
                palette: {
                    colors: colors,
                    max: 'Roof',
                    min: 'No roof',
                },
                render: (showRoofOnly) =>
                    renderPalette({
                        colors,
                        data: mask,
                        mask: showRoofOnly ? mask : undefined,
                    }),
                segmentId,
            };
        },
        dsm: async () => {
            const [mask, data] = await Promise.all([
                downloadGeoTIFF(urls.maskUrl, googleMapsApiKey),
                downloadGeoTIFF(urls.dsmUrl, googleMapsApiKey),
            ]);
            const sortedValues = Array.from(data.rasters[0]).sort(
                (x, y) => x - y,
            );
            const minValue = sortedValues[0];
            const maxValue = sortedValues.slice(-1)[0];
            const colors = rainbowPalette;
            return {
                bounds: mask.bounds,
                id: layerId,
                palette: {
                    colors,
                    max: `${maxValue.toFixed(1)} m`,
                    min: `${minValue.toFixed(1)} m`,
                },
                render: (showRoofOnly) =>
                    renderPalette({
                        colors,
                        data,
                        mask: showRoofOnly ? mask : undefined,
                        max: sortedValues.slice(-1)[0],
                        min: sortedValues[0],
                    }),
                segmentId,
            };
        },
        rgb: async () => {
            const [mask, data] = await Promise.all([
                downloadGeoTIFF(urls.maskUrl, googleMapsApiKey),
                downloadGeoTIFF(urls.rgbUrl, googleMapsApiKey),
            ]);
            return {
                bounds: mask.bounds,
                id: layerId,
                render: (showRoofOnly) =>
                    renderRGB(data, showRoofOnly ? mask : undefined),
            };
        },
        annualFlux: async () => {
            const [data] = await Promise.all([
                downloadGeoTIFF(urls.annualFluxUrl, googleMapsApiKey),
            ]);
            if (!data) return null;
            const colors = ironPalette;
            return {
                bounds: data.bounds,
                id: layerId,
                palette: {
                    colors,
                    max: 'Sunny',
                    min: 'Shady',
                },
                render: () =>
                    renderPalette({
                        colors,
                        data,
                        max: 1800,
                        min: 0,
                    }),
                segmentId,
            };
        },
        monthlyFlux: async () => {
            const [data] = await Promise.all([
                downloadGeoTIFF(urls.monthlyFluxUrl, googleMapsApiKey),
            ]);
            const colors = ironPalette;
            return {
                bounds: data.bounds,
                id: layerId,
                palette: {
                    colors,
                    max: 'Sunny',
                    min: 'Shady',
                },
                render: (showRoofOnly, month) =>
                    renderPalette({
                        colors,
                        data,
                        index: month,
                        max: 200,
                        min: 0,
                    }),
                segmentId,
            };
        },
        hourlyShade: async () => {
            const [...months] = await Promise.all([
                ...urls.hourlyShadeUrls.map((url) =>
                    downloadGeoTIFF(url, googleMapsApiKey),
                ),
            ]);
            const colors = sunlightPalette;
            return {
                id: layerId,
                bounds: months[0].bounds,
                palette: {
                    colors,
                    max: 'Sun',
                    min: 'Shade',
                },
                render: (showRoofOnly, month, day, hour) =>
                    renderPalette({
                        colors,
                        data: {
                            ...months[month],
                            rasters: months[month].rasters.map((values) =>
                                values.map((x) => x & (1 << (day - 1))),
                            ),
                        },
                        index: hour,
                        max: 1,
                        min: 0,
                    }),
                segmentId,
            };
        },
    };
    try {
        return get[layerId]();
    } catch (e) {
        console.error(`Error getting layer: ${layerId}\n`, e);
        throw e;
    }
}
