import React, { useState } from 'react';

import LayersIcon from '@mui/icons-material/Layers';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'sunwise-ui';

const ToggleAnnualIrradiationLayerButton = ({ item, mapRef }) => {
    const { t } = useTranslation();
    const [toggle, setToggle] = useState(true);
    const startIcon = !toggle ? <LayersIcon /> : <LayersClearIcon />;
    const onClick = () => {
        const segments = mapRef.current.getSegments();
        segments[item.id].toggleAnnualIrradiationLayer();
        setToggle(!toggle);
    };

    if (!item?.annualIrradiationData) return null;

    return (
        <Button
            onClick={onClick}
            size="small"
            startIcon={startIcon}
            variant="text"
        >
            {t('Toggle irradiation layer')}
        </Button>
    );
};

ToggleAnnualIrradiationLayerButton.propTypes = {
    item: PropTypes.object,
    mapRef: PropTypes.object,
};

export default ToggleAnnualIrradiationLayerButton;
