import React, { useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput } from 'react-mentions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, FormControl, FormLabel } from 'sunwise-ui';

import {
    ReactHookFormCheck,
    ReactHookFormMultiSelect,
    ReactHookFormSelect,
    RHFDateTimePicker,
} from 'common/components/form/bootstrap';
import yupResolver from 'common/utils/yupResolver';

import * as profileSelectors from '../../../modules/profile/selectors';
import * as projectsActions from '../../../modules/project/actions';
import * as projectsSelectors from '../../../modules/project/selectors';

import * as actions from './actions';
import mentionsInputStyle from './components/mentionsInputStyle';
import mentionStyle from './components/mentionStyle';
import { getUsersOptions, getTrackingTypesOptions } from './helpers';
import * as selectors from './selectors';
import validate from './validate';

const TrackingForm = ({
    agentId,
    contactId,
    CustomFooter,
    defaultValues,
    fetchProjects,
    fetchTrackingsPurposes,
    fetchUsers,
    projects,
    trackingsPurposesToSelect,
    users,
}) => {
    const { t } = useTranslation();

    const { control, handleSubmit, reset, setValue, watch } = useForm({
        defaultValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(defaultValues), [defaultValues]);

    useEffect(() => {
        fetchTrackingsPurposes();
        setValue('assigned_to', [agentId]);
    }, []);

    useEffect(() => {
        if (!contactId) return;
        fetchUsers(contactId);
        fetchProjects({ contactId });
    }, [contactId]);

    const remember = watch('remember');

    const optionsForSelect = users?.map((user) => ({
        label: `${user.first_name} ${user.last_name}`,
        value: user.id,
    }));

    const projectsToSelect = useMemo(() => {
        const none = { label: t('None'), value: null };
        if (!contactId) return [none];

        const mapped = projects
            ?.map(({ id, name }) => ({
                label: name,
                value: id,
            }))
            .sort((a, b) => (a.name > b.name ? 1 : -1));
        mapped.unshift(none);
        return mapped;
    }, [contactId, projects]);

    return (
        <form>
            <ReactHookFormSelect
                control={control}
                label={t('Tracking type')}
                name="tracking_type"
                options={getTrackingTypesOptions()}
            />

            <ReactHookFormSelect
                control={control}
                label={t('Objective')}
                name="tracking_purpose"
                options={trackingsPurposesToSelect}
            />

            <ReactHookFormSelect
                control={control}
                label={t('Project')}
                name="project"
                options={projectsToSelect}
            />

            <FormControl fullWidth sx={{ height: '100px' }}>
                <FormLabel sx={{ fontSize: '13px', fontWeight: 'bold' }}>
                    {t('Note')}
                </FormLabel>
                <MentionsInput
                    onChange={(e) => setValue('description', e.target.value)}
                    placeholder={t('Add note')}
                    style={{
                        ...mentionsInputStyle,
                        control: {
                            backgroundColor: '#fff',
                            borderRadius: '8px',
                            padding: '8.5px 14px',
                            height: '92px',
                        },
                    }}
                    value={watch('description')}
                >
                    <Mention
                        data={getUsersOptions(users)}
                        style={mentionStyle}
                        trigger="@"
                    />
                </MentionsInput>
            </FormControl>

            <ReactHookFormCheck
                control={control}
                label={t('Remember task')}
                name="remember"
            />

            {remember && (
                <Box>
                    {users.length > 1 && (
                        <ReactHookFormMultiSelect
                            allTextValue={t('All text', { count: 2 })}
                            control={control}
                            disableFuture
                            label={t('Assigned to')}
                            name="assigned_to"
                            options={optionsForSelect || []}
                        />
                    )}

                    <RHFDateTimePicker
                        control={control}
                        disabled={!remember}
                        fullWidth
                        disablePast
                        label={t('Date')}
                        name="notification_at"
                    />
                </Box>
            )}

            <CustomFooter control={control} handleSubmit={handleSubmit} />
        </form>
    );
};

TrackingForm.propTypes = {
    agentId: PropTypes.string,
    contactId: PropTypes.string,
    CustomFooter: PropTypes.func,
    defaultValues: PropTypes.object,
    fetchProjects: PropTypes.func,
    fetchTrackingsPurposes: PropTypes.func,
    fetchUsers: PropTypes.func,
    projects: PropTypes.array,
    trackingsPurposesToSelect: PropTypes.array,
    users: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    agentId: profileSelectors.getUserId,
    defaultValues: selectors.getInitialValues,
    projects: projectsSelectors.getDataProjects,
    trackingsPurposes: selectors.getTrackingPurpose,
    trackingsPurposesToSelect: selectors.getTrackingPurposeToSelect,
    users: selectors.getUsersData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchProjects: (filterData, callback) =>
        dispatch(projectsActions.fetchProjects(filterData, callback)),
    fetchTrackingsPurposes: () => dispatch(actions.fetchTrackingsPurposes()),
    fetchUsers: (contactId) => dispatch(actions.fetchUsers(contactId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackingForm);
