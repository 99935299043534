import React, { useState } from 'react';

import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import { SUBSCRIPTION_STATUS } from 'common/constants';

import AfterSalesIcon from 'resources/aftersales.svg?react';
import SalesIcon from 'resources/sales.svg?react';

import { formatPeriodDate } from '../helpers';

import UsageSubTableV4 from './UsageSubTableV4';

const SubscriptionDetailsV4 = ({
    subscription,
    index,
    activeUsers,
    addons,
}) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen((prev) => !prev);

    if (isEmpty(subscription)) return null;

    const {
        current_term_end,
        current_term_start,
        item_price_id,
        product_type,
        status,
    } = subscription || {};

    const subscriptionStatus = get(status, 'key', null);
    const isCancelled = subscriptionStatus === SUBSCRIPTION_STATUS.CANCELLED;
    const isSales = product_type === 'sales';

    const renderIcon = () => (isSales ? <SalesIcon /> : <AfterSalesIcon />);

    return (
        <Box
            display="flex"
            flexDirection="column"
            key={`${item_price_id}-${index}`}
            mb={2}
        >
            <Box
                alignItems="center"
                display="flex"
                gap={1}
                sx={{ svg: { height: 30, width: 30 } }}
            >
                {renderIcon()}
                <Typography variant="h5">Sunwise</Typography>
                <Typography
                    component="span"
                    sx={{ color: 'primary.main' }}
                    variant="h5"
                >
                    {isSales ? 'Sales' : 'AfterSales'}
                </Typography>
            </Box>
            <Typography variant="body2" ml={2} mt={2}>
                {t('Period')}: {formatPeriodDate(current_term_start)} -{' '}
                {formatPeriodDate(current_term_end)}
            </Typography>

            {!isCancelled && (
                <UsageSubTableV4
                    subscription={subscription}
                    activeUsers={activeUsers}
                    addons={addons}
                    isOpen={isOpen}
                    toggleOpen={toggleOpen}
                />
            )}
        </Box>
    );
};

SubscriptionDetailsV4.propTypes = {
    subscription: PropTypes.object,
    index: PropTypes.number,
    activeUsers: PropTypes.number,
    addons: PropTypes.array,
};

export default SubscriptionDetailsV4;
