import { getLayer } from '../layers';

import updateSegments from './updateSegments';

import { getDataLayerUrls } from '.';

export const loadDataLayers = async (values) => await getDataLayerUrls(values);

export default (segments) => async (dispatch) => {
    let layerPromises = [];

    const filteredSegments = segments.filter(
        ({ annualFluxUrl }) => annualFluxUrl,
    );

    filteredSegments.forEach(({ id, annualFluxUrl }) => {
        layerPromises.push(
            getLayer({
                layerId: 'annualFlux',
                urls: { annualFluxUrl },
                googleMapsApiKey: import.meta.env.VITE_GOOGLE_SOLAR_API_KEY,
                segmentId: id,
            }),
        );
    });

    const newSegments = [...segments];

    await Promise.all(layerPromises).then((data) => {
        data.forEach((d) => {
            if (!d) return;
            const canvas = d.render();
            const image = canvas.toDataURL();

            const segmentIndex = newSegments.findIndex(
                (segment) => segment.id === d.segmentId,
            );

            if (segmentIndex === -1) return;

            newSegments[segmentIndex] = {
                ...newSegments[segmentIndex],
                annualIrradiationData: { bounds: d.bounds, image },
            };
        });
    });

    dispatch(updateSegments(newSegments));
};
