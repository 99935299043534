import API from '../..';
const ENTITY = 'contact-tracking-comments';

export const create = (data) => API.post(`/api/v1/${ENTITY}/`, data);
export const deleteItem = (id) => API.delete(`/api/v1/${ENTITY}/${id}`);
export const get = (id, page = 1, per_page = 100) =>
    API.get(`/api/v1/contact-trakings/${id}/comments`, {
        params: { page, per_page },
    });
export const update = (id, data) => API.put(`/api/v1/${ENTITY}/${id}`, data);
