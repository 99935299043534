import React, { forwardRef, useEffect, useState } from 'react';

import {
    Table,
    TableBody,
    TableHead,
    TableRow,
} from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { TableVirtuoso } from 'react-virtuoso';

import Row from './Row';
import StyledTableContainer from './StyledTableContainer';
import TableHeader from './TableHeader';

const VirtuosoTableComponents = {
    Scroller: forwardRef((props, ref) => (
        <StyledTableContainer {...props} ref={ref} />
    )),
    Table: (props) => <Table {...props} />,
    TableHead: forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
    TableRow: forwardRef((props, ref) => (
        <TableRow
            {...props}
            ref={ref}
            // eslint-disable-next-line react/prop-types
            sx={props?.context?.getRowStyle?.(
                // eslint-disable-next-line react/prop-types
                props?.['data-item-index'],
                // eslint-disable-next-line react/prop-types
                props?.item,
            )}
        />
    )),
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

VirtuosoTableComponents.Scroller.displayName = 'Table';
VirtuosoTableComponents.TableHead.displayName = 'TableHead';
VirtuosoTableComponents.TableRow.displayName = 'TableRow';
VirtuosoTableComponents.TableBody.displayName = 'TableBody';

const CollapsibleTable = ({
    columnGroups,
    followOutput = true,
    getRowStyle,
    maxHeight = 500,
    minHeight = 250,
    rows,
}) => {
    const [height, setHeight] = useState(minHeight);
    const [expandedColumns, setExpandedColumns] = React.useState({});

    const columnGroupKeys = columnGroups?.map((group) => group.key)?.join(',');

    useEffect(() => {
        const initialExpandedColumns = columnGroups?.reduce((acc, group) => {
            acc[group.key] = group.defaultExpanded || false;
            return acc;
        }, {});

        setExpandedColumns(initialExpandedColumns);
    }, [columnGroupKeys]);

    const toggleExpandedColumn = (key) =>
        setExpandedColumns((prev) => ({ ...prev, [key]: !prev[key] }));

    function fixedHeaderContent() {
        return (
            <TableHeader
                columnGroups={columnGroups}
                expandedColumns={expandedColumns}
                toggleExpandedColumn={toggleExpandedColumn}
            />
        );
    }

    function rowContent(index, item) {
        return (
            <Row
                columnGroups={columnGroups}
                expandedColumns={expandedColumns}
                isLastRow={index === rows.length - 1}
                key={item.key || item.id || index}
                row={item}
                rowIndex={index}
            />
        );
    }

    return (
        <TableVirtuoso
            components={VirtuosoTableComponents}
            context={{ getRowStyle }}
            data={rows}
            fixedHeaderContent={fixedHeaderContent}
            followOutput={followOutput}
            itemContent={rowContent}
            style={{ height, maxHeight, minHeight }}
            totalListHeightChanged={(newHeight) => {
                setHeight(newHeight > maxHeight ? maxHeight : newHeight);
            }}
        />
    );
};

CollapsibleTable.propTypes = {
    columnGroups: PropTypes.array,
    followOutput: PropTypes.bool,
    getRowStyle: PropTypes.func,
    maxHeight: PropTypes.number,
    minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    rows: PropTypes.array,
};

export default CollapsibleTable;
