import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Box, Card, Grid, Skeleton, Typography } from 'sunwise-ui';

import AfterSalesIcon from 'resources/aftersales.svg?react';
import BundleIcon from 'resources/IsoBundle.svg?react';
import SalesIcon from 'resources/sales.svg?react';

const Products = ({
    history,
    isLoading,
    location,
    selectedProductId,
    setSelectedProductId,
}) => {
    const { t } = useTranslation();
    const params = new URLSearchParams(location.search);
    const productId = params.get('product');

    useEffect(() => {
        if (productId) setSelectedProductId(productId);
    }, [productId]);

    const handleSelectProduct = (productId) => {
        setSelectedProductId(productId);
        history.push(`?product=${productId}`);
    };

    if (isLoading) {
        return (
            <Grid container justifyContent="center" mt={2}>
                <Grid size={{ lg: 5, md: 6, sm: 9, xl: 4, xs: 18 }}>
                    <Skeleton height={400} variant="rectangle" />
                </Grid>
                <Grid size={{ lg: 5, md: 6, sm: 9, xl: 4, xs: 18 }}>
                    <Skeleton height={400} variant="rectangle" />
                </Grid>
            </Grid>
        );
    }
    return (
        <>
            <Grid container>
                <Grid size={{ xs: 'grow' }} textAlign="center">
                    <Typography variant="h1">
                        {t('The most accurate software for')}
                    </Typography>
                    <Typography color="primary" variant="h2">
                        Solar & BESS
                    </Typography>
                    <Typography
                        fontWeight={500}
                        my={2}
                        textAlign="center"
                        variant="h5"
                    >
                        {t('Select the solution you would like to purchase')}:
                    </Typography>
                </Grid>
            </Grid>

            <Grid container justifyContent="center" mt={2}>
                <Grid size={{ lg: 5, md: 6, sm: 9, xl: 4, xs: 18 }}>
                    <Card
                        onClick={() => handleSelectProduct('sales')}
                        sx={{
                            cursor: 'pointer',
                            border:
                                selectedProductId === 'sales'
                                    ? '2px solid'
                                    : 'none',
                            borderColor:
                                selectedProductId === 'sales'
                                    ? 'primary.main'
                                    : 'none',
                        }}
                    >
                        <Card.Body sx={{ cursor: 'pointer' }}>
                            <Box sx={{ height: 400, textAlign: 'center' }}>
                                <SalesIcon />
                                <Typography variant="h3">Sunwise</Typography>
                                <Typography
                                    component="span"
                                    sx={{ color: 'primary.main' }}
                                    variant="h3"
                                >
                                    Sales
                                </Typography>
                                <Typography mb={2} mt={3} variant="body1">
                                    {t(
                                        'Power your sales pipeline with advanced design and quoting tools for solar installations and product selection, ensuring accurate and attractive proposals',
                                    )}
                                </Typography>
                            </Box>
                        </Card.Body>
                    </Card>
                </Grid>
                <Grid size={{ lg: 5, md: 6, sm: 9, xl: 4, xs: 18 }}>
                    <Card
                        onClick={() => handleSelectProduct('aftersales')}
                        sx={{
                            cursor: 'pointer',
                            border:
                                selectedProductId === 'aftersales'
                                    ? '2px solid'
                                    : 'none',
                            borderColor:
                                selectedProductId === 'aftersales'
                                    ? 'primary.main'
                                    : 'none',
                        }}
                    >
                        <Card.Body sx={{ cursor: 'pointer' }}>
                            <Box sx={{ height: 400, textAlign: 'center' }}>
                                <AfterSalesIcon />
                                <Typography variant="h3">Sunwise</Typography>
                                <Typography
                                    component="span"
                                    sx={{ color: 'primary.main' }}
                                    variant="h3"
                                >
                                    After Sales
                                </Typography>
                                <Typography mb={2} mt={3} variant="body1">
                                    {t(
                                        'Ensures excellence in customer service by integrating data from various monitoring sources into a single platform. Processes and analyzes this information to generate professional after-sales reports',
                                    )}
                                </Typography>
                            </Box>
                        </Card.Body>
                    </Card>
                </Grid>
                <Grid size={{ lg: 5, md: 6, sm: 9, xl: 4, xs: 18 }}>
                    <Card
                        onClick={() => handleSelectProduct('bundle')}
                        sx={{
                            cursor: 'pointer',
                            border:
                                selectedProductId === 'bundle'
                                    ? '2px solid'
                                    : 'none',
                            borderColor:
                                selectedProductId === 'bundle'
                                    ? 'primary.main'
                                    : 'none',
                        }}
                    >
                        <Card.Body sx={{ cursor: 'pointer' }}>
                            <Box sx={{ height: 400, textAlign: 'center' }}>
                                <BundleIcon style={{ height: '80px' }} />
                                <Typography variant="h3">Sunwise</Typography>
                                <Typography
                                    component="span"
                                    sx={{ color: 'primary.main' }}
                                    variant="h3"
                                >
                                    Bundle
                                </Typography>
                                <Typography mb={2} mt={3} variant="body1">
                                    {t(
                                        'Combines design, quoting and post-sales management in one comprehensive platform. Create attractive proposals and professional reports with advanced tools and data analysis',
                                    )}
                                </Typography>
                            </Box>
                        </Card.Body>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

Products.propTypes = {
    history: PropTypes.object,
    isLoading: PropTypes.bool,
    location: PropTypes.object,
    selectedProductId: PropTypes.string,
    setSelectedProductId: PropTypes.func,
};

export default withRouter(Products);
