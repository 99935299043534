import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Grid, Tooltip, Typography, Link } from 'sunwise-ui';

import { formatDate, parseDate } from 'common/utils/dates';
import { getDateFormatByLocale } from 'common/utils/helpers/multiregion';

import ProposalsCount from '../../funnelProjects/components/ProposalsCount';
import * as projectActions from '../../project/actions';
import DropdownCustomCardMenuMap from '../../project/components/DropdownCustomCardMenuMap';
import * as projectSelectors from '../../project/selectors';

const ProjectListItem = ({
    canModify,
    contact,
    createdBy,
    date,
    id,
    proposalsCount,
    rate,
    statusProject,
    statusProjectsToSelect,
    title,
    updateStatusProject,
}) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState({
        color: 'transparent',
        icon: null,
        key: '',
        label: t('Select status'),
        value: t('Select status'),
    });

    useEffect(() => {
        if (!statusProject) return;
        setSelected({
            color: statusProject.primary_color,
            key: statusProject.id,
            value: statusProject.name,
        });
    }, []);

    const handleSelected = (status) => {
        if (status.key === '') return;
        updateStatusProject(id, status.key, () => setSelected(selected));
        setSelected(status);
    };

    const createdAt = formatDate(
        parseDate(date, 'dd/MM/yyyy HH:mm:ss'),
        getDateFormatByLocale(),
    );

    return (
        <Grid alignItems="center" container>
            <Grid
                order={{ md: 1, xs: 1 }}
                size={{ md: 4, xs: 'grow' }}
                sx={{ alignItems: 'center', display: 'flex', gap: 1 }}
            >
                <ProposalsCount count={proposalsCount} />

                <Tooltip title={title}>
                    <Link
                        color="inherit"
                        component={RouterLink}
                        to={`/record/${contact.id}?tab=projects&project=${id}&view=proposals&type=proposals`}
                        underline="none"
                    >
                        {title}
                    </Link>
                </Tooltip>
            </Grid>

            <Grid order={{ md: 2, xs: 4 }} size={{ md: 2, xs: 6 }}>
                <Typography
                    noWrap
                    variant="body2"
                    sx={{
                        fontSize: '14px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '100%',
                    }}
                >
                    {rate || '----'}
                </Typography>
            </Grid>

            <Grid order={{ md: 3, xs: 5 }} size={{ md: 3, xs: 18 }}>
                <Typography variant="body2">
                    {t('By')} {createdBy}
                </Typography>
            </Grid>

            <Grid order={{ md: 4, xs: 6 }} size={{ md: 3, xs: 18 }}>
                <Typography variant="body2">
                    {t('Created at')} {createdAt}
                </Typography>
            </Grid>

            <Grid order={{ md: 5, xs: 3 }} size={{ md: 5, xs: 18 }}>
                <DropdownCustomCardMenuMap
                    canModify={canModify}
                    handleSelected={handleSelected}
                    selected={selected}
                    statusToSelect={statusProjectsToSelect}
                />
            </Grid>

            <Grid
                order={{ md: 6, xs: 2 }}
                size={{ md: 1, xs: 3 }}
                textAlign="right"
            ></Grid>
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    statusProjectsToSelect: projectSelectors.getStatusProjectToSelect,
});

const mapDispatchToProps = (dispatch) => ({
    updateStatusProject: (id, project_status, onFail) =>
        dispatch(
            projectActions.updateStatusProject(id, project_status, onFail),
        ),
});

ProjectListItem.propTypes = {
    canModify: PropTypes.bool,
    contact: PropTypes.object,
    createdBy: PropTypes.string,
    date: PropTypes.string,
    id: PropTypes.string,
    proposalsCount: PropTypes.number,
    rate: PropTypes.string,
    statusProject: PropTypes.object,
    statusProjectsToSelect: PropTypes.array,
    title: PropTypes.string,
    updateStatusProject: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectListItem);
