import React from 'react';

import LinearProgress, {
    linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from 'sunwise-ui';

import { KeyIndicator } from 'common/components';
import { numberFormat } from 'common/utils/helpers';

import { DAY_TYPES } from '../constants';

import DailyChartJs from './DailyChartJs';
import TitleWithDecorator from './TItleWithDecorator';

const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#FF7043',
    },
    [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: '#FFC107',
    },
}));

const KeyValues = ({
    backupPercentage,
    currencyLocale,
    storageOffer,
    storagePlan,
}) => {
    const { t } = useTranslation();

    const { daily_cap_batteries, daily_cap_percentage, total_backup_days } =
        storagePlan || {};

    if (backupPercentage < 1)
        return (
            <Grid container>
                <Grid size={{ lg: 6, xl: 8, xs: 18 }}>
                    <Grid container>
                        <Grid size={{ lg: 18, md: 9, xs: 18 }}>
                            <KeyIndicator
                                title={t('Gross storage capacity')}
                                unit="kWh"
                                value={numberFormat(daily_cap_batteries, {
                                    decimals: 2,
                                    locale: currencyLocale,
                                    style: 'decimal',
                                })}
                                widthIcon="20px"
                            />
                        </Grid>

                        <Grid size={{ lg: 18, md: 9, xs: 18 }}>
                            <KeyIndicator
                                title={t('Full backup duration')}
                                unit={t('Day', {
                                    count: total_backup_days || 2,
                                }).toLowerCase()}
                                value={numberFormat(total_backup_days, {
                                    decimals: 2,
                                    locale: currencyLocale,
                                    style: 'decimal',
                                })}
                                widthIcon="20px"
                            />
                        </Grid>

                        <Grid size={{ lg: 18, md: 9, xs: 18 }}>
                            <KeyIndicator
                                title={t('Day-based calculation')}
                                value={t(
                                    DAY_TYPES[storageOffer?.base_day_type ?? 1],
                                )}
                                widthIcon="20px"
                            />
                        </Grid>

                        <Grid size={18} mx={1}>
                            <Typography variant="subtitle2">
                                {t('Battery energy distribution')}
                            </Typography>
                            <Box
                                alignItems="center"
                                display="flex"
                                flexWrap="wrap"
                                justifyContent="space-between"
                                my={2}
                            >
                                <TitleWithDecorator
                                    color="#FFC107"
                                    currencyLocale={currencyLocale}
                                    title={t('Energy backup')}
                                    value={backupPercentage * 100}
                                />

                                <TitleWithDecorator
                                    color="#FF7043"
                                    currencyLocale={currencyLocale}
                                    title={t('Time shifting')}
                                    value={(1 - backupPercentage) * 100}
                                />
                            </Box>
                            <BorderLinearProgress
                                variant="determinate"
                                value={backupPercentage * 100}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid size={{ lg: 12, xl: 10, xs: 18 }} textAlign="center">
                    <DailyChartJs currencyLocale={currencyLocale} />
                </Grid>
            </Grid>
        );

    return (
        <Grid container>
            <Grid mb={1} size={{ lg: 6, md: 9, xs: 18 }}>
                <KeyIndicator
                    title={t('Gross storage capacity')}
                    unit="kWh"
                    value={numberFormat(daily_cap_batteries, {
                        decimals: 2,
                        locale: currencyLocale,
                        style: 'decimal',
                    })}
                    widthIcon="20px"
                />
            </Grid>

            <Grid mb={1} size={{ lg: 6, md: 9, xs: 18 }}>
                <KeyIndicator
                    title={t('Daily storage percentage')}
                    unit="%"
                    value={numberFormat(daily_cap_percentage * 100, {
                        decimals: 2,
                        locale: currencyLocale,
                        style: 'decimal',
                    })}
                    widthIcon="20px"
                />
            </Grid>

            <Grid mb={1} size={{ lg: 6, md: 9, xs: 18 }}>
                <KeyIndicator
                    title={t('Full backup duration')}
                    unit={t('Day', {
                        count: total_backup_days || 2,
                    }).toLowerCase()}
                    value={numberFormat(total_backup_days, {
                        decimals: 2,
                        locale: currencyLocale,
                        style: 'decimal',
                    })}
                    widthIcon="20px"
                />
            </Grid>
        </Grid>
    );
};

KeyValues.propTypes = {
    backupPercentage: PropTypes.number,
    currencyLocale: PropTypes.string,
    storageOffer: PropTypes.object,
    storagePlan: PropTypes.object,
};

export default KeyValues;
