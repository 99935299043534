import React, { useState, useEffect } from 'react';

import NearMeIcon from '@mui/icons-material/NearMe';
import find from 'lodash/find';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { useGeolocated } from 'react-geolocated';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Divider, Grid, IconButton } from 'sunwise-ui';

import { ControlledMap, ShowErrors } from 'common/components';
import {
    ReactHookFormInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import {
    ItemGeocodeResult,
    ListGeocodeResult,
    WrapperGeocodeResult,
} from 'common/components/maps';
import { FormWrapper, HeaderForm } from 'common/components/productForm';
import { DEFAULT_MAP_PREVIEW_ZOOM, DEBOUNCE_TIME_1000 } from 'common/constants';
import { getIsMexicanAccount } from 'common/utils/helpers/session';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import { FETCH_GEOCODE_SUCCESS } from '../actionTypes';
import { branchOfficesActions } from '../reducer';
import * as selectors from '../selectors';
import validate from '../validate';

const FormContainer = ({
    company,
    companyPositionLatLng,
    currenciesForSelect,
    errors,
    fetchGeocodePoint,
    geocodePointData,
    geocodeResult,
    handleClickSave,
    handleGeocode,
    initialValues,
    isFetchingGeocodePoint,
    isNewRecord,
    isSaving,
    politicalDivisionsForSelect,
    setDialogTitle,
    setEmptyGeocodeResult,
    setShowGeocodeResults,
    showGeocodeResults,
}) => {
    const { t } = useTranslation();
    const { control, handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });
    const { coords, isGeolocationEnabled } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
    });

    const formValues = watch();

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        const defaultCurrency = get(company, 'currency', null);
        if (defaultCurrency) setValue('currency', defaultCurrency.id);
    }, [company]);

    useEffect(() => {
        if (formValues.position)
            fetchGeocodePoint(
                formValues.position?.latitude,
                formValues.position?.longitude,
            );
    }, [formValues?.position]);

    useEffect(() => {
        setDialogTitle(
            `${isNewRecord ? t('Create') : t('Update')} ${t(
                'Branch office',
            ).toLowerCase()}`,
        );
    }, [isNewRecord]);

    useEffect(() => {
        if (!isNil(geocodePointData)) {
            const address = geocodePointData.formatted_address;
            const political = getParameterPolitical(
                geocodePointData.address_components,
            );
            const locality = getParameterCity(
                geocodePointData.address_components,
            );
            const postal_code = getParameterCP(
                geocodePointData.address_components,
            );

            const country = getParameterCountry(
                geocodePointData.address_components,
            );

            setValue('country', country);
            setValue('description', address);
            setValue('political_division1', political);
            setValue('city', locality);
            setValue('cp', postal_code);
        }
    }, [geocodePointData]);

    const [timer, setTimer] = useState(null);
    const [previousTitle, setPreviousTitle] = useState('');

    const handleOnChange = (e) => {
        clearTimeout(timer);
        setTimer(setTimeout(() => triggerChange(e), DEBOUNCE_TIME_1000));
    };

    const triggerChange = (value) => {
        if (value.length >= 6 && previousTitle === '') {
            handleGeocode(value);
            setPreviousTitle(value);
        } else {
            setEmptyGeocodeResult();
            setPreviousTitle('');
        }
    };

    const getParameterPolitical = (address_components) => {
        const political = address_components.find((item) =>
            item.types.includes('administrative_area_level_1'),
        );
        if (political) {
            const politicalDivision = find(politicalDivisionsForSelect, [
                'label',
                political.long_name,
            ]);
            return politicalDivision ? politicalDivision.value : '';
        }
        return '';
    };

    const getParameterCity = (address_components) => {
        const sublocality = address_components.find((item) =>
            item.types.includes('sublocality'),
        );
        if (sublocality) return sublocality.long_name;

        const locality = address_components.find((item) =>
            item.types.includes('locality'),
        );
        return locality?.long_name || '';
    };

    const getParameterCP = (address_components) => {
        const address = address_components.find((item) =>
            item.types.includes('postal_code'),
        );
        return address?.long_name || '';
    };

    const getParameterCountry = (address_components) => {
        const country = address_components.find((item) =>
            item.types.includes('country'),
        );
        return country?.long_name || '';
    };
    const upperFieldValue = (value) => setValue('rfc', value.toUpperCase());

    const lowerFieldValue = (value) =>
        setValue('contact_email', value.toLowerCase());

    const isMexicanAccount = getIsMexicanAccount();

    return (
        <form>
            <FormWrapper>
                <ReactHookFormInput
                    control={control}
                    label={t('Name').concat(' *')}
                    name="name"
                    placeholder={t('Name')}
                />

                <ReactHookFormInput
                    control={control}
                    label={`${t('Business name')} *`}
                    name="business_name"
                    placeholder={t('Business name')}
                />

                <ReactHookFormInput
                    control={control}
                    label={t('Tax ID')}
                    name="rfc"
                    placeholder={t('Tax ID')}
                    onChange={({ target }) => upperFieldValue(target.value)}
                />

                <ReactHookFormSelect
                    control={control}
                    label={`${t('Size of the company')} *`}
                    name="company_size"
                    options={[
                        {
                            disabled: true,
                            label: t('Select a type'),
                            value: '',
                        },
                        {
                            label: t('Micro (1-10 employees)'),
                            value: 'MCR',
                        },
                        {
                            label: t('Small (11-49 employees)'),
                            value: 'PQ',
                        },
                        {
                            label: t('Medium (50-250 employees)'),
                            value: 'MDN',
                        },
                        {
                            label: t('Large (more than 250 employees)'),
                            value: 'GRD',
                        },
                    ]}
                />

                <ReactHookFormInput
                    control={control}
                    label={t('Web page')}
                    name="web_page"
                    placeholder={t('Web page')}
                />

                <HeaderForm title={t('Location')} />

                <Divider sx={{ my: 2 }} />

                <Grid container>
                    <Grid size={{ xs: 'grow' }}>
                        {geocodeResult &&
                            geocodeResult.length > 0 &&
                            showGeocodeResults && (
                                <WrapperGeocodeResult>
                                    <ListGeocodeResult>
                                        {geocodeResult.map((result, i) => (
                                            <ItemGeocodeResult
                                                key={`geocode-result-${i}`}
                                                onClick={() => {
                                                    const { lat, lng } =
                                                        result.geometry
                                                            .location;
                                                    const address =
                                                        result.formatted_address;
                                                    const political =
                                                        getParameterPolitical(
                                                            result.address_components,
                                                        );
                                                    const locality =
                                                        getParameterCity(
                                                            result.address_components,
                                                        );
                                                    const postal_code =
                                                        getParameterCP(
                                                            result.address_components,
                                                        );

                                                    setValue('position', {
                                                        latitude: lat,
                                                        longitude: lng,
                                                    });
                                                    setValue(
                                                        'description',
                                                        address,
                                                    );
                                                    setValue(
                                                        'political_division1',
                                                        political,
                                                    );
                                                    setValue('city', locality);
                                                    setValue('cp', postal_code);
                                                }}
                                            >
                                                {result.formatted_address}
                                            </ItemGeocodeResult>
                                        ))}
                                    </ListGeocodeResult>
                                </WrapperGeocodeResult>
                            )}
                        <ReactHookFormInput
                            control={control}
                            disabled={isFetchingGeocodePoint}
                            label={t('Address')}
                            name="description"
                            onChange={(e) => handleOnChange(e.target.value)}
                            onBlur={() => {
                                setTimeout(() => {
                                    setShowGeocodeResults(false);
                                }, 150);
                            }}
                            onFocus={() => setShowGeocodeResults(true)}
                            placeholder={t('Address')}
                        />
                    </Grid>
                </Grid>

                <Grid container sx={{ mb: 2 }}>
                    <Grid size={{ lg: 'grow', xs: 18 }}>
                        <ReactHookFormSelect
                            control={control}
                            fullWidth
                            label={
                                isMexicanAccount
                                    ? t('State')
                                    : t('Political division of order 1')
                            }
                            name="political_division1"
                            options={politicalDivisionsForSelect}
                            placeholder={
                                isMexicanAccount
                                    ? t('State')
                                    : t('Political division of order 1')
                            }
                        />
                    </Grid>

                    <Grid size={{ lg: 'grow', xs: 18 }}>
                        <ReactHookFormInput
                            control={control}
                            fullWidth
                            label={
                                isMexicanAccount
                                    ? t('City')
                                    : t('Political division of order 2')
                            }
                            name="city"
                            placeholder={
                                isMexicanAccount
                                    ? t('City')
                                    : t('Political division of order 2')
                            }
                        />
                    </Grid>

                    <Grid size={{ xs: 'grow' }}>
                        <ReactHookFormInput
                            control={control}
                            fullWidth
                            label={t('Zip code')}
                            name="cp"
                            placeholder={t('Zip code')}
                        />
                    </Grid>

                    <Grid>
                        <IconButton
                            disabled={!isGeolocationEnabled || isSaving}
                            onClick={() => {
                                if (!isNil(coords)) {
                                    setValue('position', {
                                        latitude: coords.latitude,
                                        longitude: coords.longitude,
                                    });
                                }
                            }}
                            sx={{ mb: 2 }}
                        >
                            <NearMeIcon />
                        </IconButton>
                    </Grid>

                    <Grid size={18}>
                        <Controller
                            control={control}
                            name="position"
                            render={({ field: { onChange, value } }) => (
                                <ControlledMap
                                    center={companyPositionLatLng}
                                    heightMap="206px"
                                    onChange={onChange}
                                    title={t('Location')}
                                    value={value}
                                    zoom={DEFAULT_MAP_PREVIEW_ZOOM}
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                <HeaderForm title={t('Contact')} />

                <Divider sx={{ my: 2 }} />

                <Grid container>
                    <Grid size={{ sm: 9, xs: 18 }}>
                        <ReactHookFormInput
                            control={control}
                            label={t('Name')}
                            name="contact_name"
                            placeholder={t('Name')}
                        />
                    </Grid>

                    <Grid size={{ sm: 9, xs: 18 }}>
                        <ReactHookFormInput
                            control={control}
                            label={t("Signer's position")}
                            name="signatory_position"
                            placeholder={t("Signer's position")}
                        />
                    </Grid>

                    <Grid size={{ sm: 9, xs: 18 }}>
                        <ReactHookFormInput
                            control={control}
                            label={t('Telephone')}
                            name="contact_telephone"
                            placeholder={t('Telephone')}
                        />
                    </Grid>

                    <Grid size={{ sm: 9, xs: 18 }}>
                        <ReactHookFormInput
                            control={control}
                            label={t('Mobile')}
                            name="contact_cellphone"
                            placeholder={t('Mobile')}
                        />
                    </Grid>

                    <Grid size={{ sm: 9, xs: 18 }}>
                        <ReactHookFormInput
                            control={control}
                            label={t('Email')}
                            name="contact_email"
                            onChange={({ target }) =>
                                lowerFieldValue(target.value)
                            }
                            placeholder={t('Email')}
                        />
                    </Grid>
                </Grid>

                <HeaderForm title={t('Additional settings')} />

                <Divider sx={{ my: 2 }} />

                <ReactHookFormSelect
                    control={control}
                    disabled
                    label={t('Currency')}
                    name="currency"
                    options={currenciesForSelect}
                />
            </FormWrapper>

            <ShowErrors errors={errors} />

            <Grid container>
                <Grid size={{ xs: 'grow' }} textAlign="right">
                    <Button
                        onClick={handleSubmit(handleClickSave)}
                        disabled={isSaving}
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                        variant="outlined"
                    >
                        {t('Save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    geocodePointData: selectors.getGeocodePointData,
    geocodeResult: selectors.getFetchGeocodeData,
    initialValues: selectors.getInitialValues,
    isFetchingGeocodePoint: selectors.getIsFetchingGeocodePoint,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSavingItem,
    showGeocodeResults: selectors.showGeocodeResults,
});

const mapDispatchToProps = (dispatch) => ({
    fetchGeocodePoint: (lat, lng) =>
        dispatch(actions.fetchGeocodePoint(lat, lng)),
    handleGeocode: (str) => dispatch(actions.fetchGeocodeResult(str)),
    handleClickDelete: (item) => dispatch(actions.prepareDelete(item)),
    handleClickSave: (values) => dispatch(actions.save(values)),
    setEmptyGeocodeResult: () =>
        dispatch(branchOfficesActions[FETCH_GEOCODE_SUCCESS]([])),
    setShowGeocodeResults: (value) =>
        dispatch(actions.setShowGeocodeResults(value)),
});

FormContainer.propTypes = {
    company: PropTypes.object,
    companyPositionLatLng: PropTypes.object,
    currenciesForSelect: PropTypes.array,
    errors: PropTypes.array,
    fetchGeocodePoint: PropTypes.func,
    geocodePointData: PropTypes.object,
    geocodeResult: PropTypes.array,
    handleClickSave: PropTypes.func,
    handleGeocode: PropTypes.func,
    initialValues: PropTypes.object,
    isFetchingGeocodePoint: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isSaving: PropTypes.bool,
    politicalDivisionsForSelect: PropTypes.array,
    setDialogTitle: PropTypes.func,
    setEmptyGeocodeResult: PropTypes.func,
    setShowGeocodeResults: PropTypes.func,
    showGeocodeResults: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
