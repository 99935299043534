import React from 'react';

import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

const CustomTabPanel = (props) => {
    const { children, selectedTab, sx, value, ...other } = props;

    return (
        <Box
            aria-labelledby={`simple-tab-${value}`}
            hidden={value !== selectedTab}
            id={`simple-tabpanel-${value}`}
            role="tabpanel"
            sx={{ minHeight: '100%', ...sx }}
            {...other}
        >
            {value === selectedTab && children}
        </Box>
    );
};

CustomTabPanel.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    selectedTab: PropTypes.string,
    sx: PropTypes.object,
    value: PropTypes.string,
};

export default CustomTabPanel;
