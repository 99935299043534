import React from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Typography } from 'sunwise-ui';

import { addDefaultSrc, handleFileURL } from 'common/utils/helpers';

const StyledItem = styled(Box)`
    align-items: center;
    border: 1px solid #ecedf0;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    height: 132px;
    width: 132px;

    & img {
        border-radius: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover; /* Asegura que la imagen se ajuste bien */
    }
`;

const Financials = ({
    financials,
    handleOnClick,
    isFetching,
    selectedFinancier,
}) => {
    const { t } = useTranslation();

    if (isFetching) {
        return (
            <Box
                display="flex"
                justifyContent="space-around"
                gap={2}
                flexWrap="wrap"
            >
                <Skeleton variant="circular" width={132} height={132} />
                <Skeleton variant="circular" width={132} height={132} />
                <Skeleton variant="circular" width={132} height={132} />
                <Skeleton variant="circular" width={132} height={132} />
            </Box>
        );
    }

    if (isEmpty(financials)) {
        return (
            <Box p={2} textAlign="center">
                <Typography variant="body2">
                    {t('There are no alliances to show')}
                </Typography>
            </Box>
        );
    }

    const renderIcon = (financier) => {
        const commonSxProp = {
            color:
                selectedFinancier?.id === financier?.id ? '#3caf5f' : '#ecedf0',
        };
        if (financier?.has_alliance) {
            return <CheckCircleIcon fontSize="small" sx={commonSxProp} />;
        }
        return <AddCircleIcon fontSize="small" sx={commonSxProp} />;
    };

    return (
        <Box
            display="flex"
            justifyContent="space-around"
            gap={2}
            flexWrap="wrap"
        >
            {Array.isArray(financials) &&
                financials.length > 0 &&
                financials.map((financier) => (
                    <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                        key={`financier-${financier.id}`}
                        onClick={() => handleOnClick(financier)}
                        sx={{ cursor: 'pointer' }}
                    >
                        <StyledItem>
                            <img
                                alt={financier.name}
                                onError={(e) => addDefaultSrc(e, 680)}
                                src={handleFileURL(
                                    financier?.image,
                                    import.meta.env.VITE_S3_MEDIA_PATH,
                                )}
                            />
                        </StyledItem>
                        <Box alignItems="center" display="flex" gap={1}>
                            {renderIcon(financier)}
                            <Typography fontWeight={500} variant="body2">
                                {financier?.name}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={{ width: '10rem', textAlign: 'center' }}
                        >
                            <Typography variant="caption">
                                {financier?.description}
                            </Typography>
                        </Box>
                    </Box>
                ))}
        </Box>
    );
};

Financials.propTypes = {
    financials: PropTypes.array,
    handleOnClick: PropTypes.func,
    isFetching: PropTypes.bool,
    selectedFinancier: PropTypes.object,
};

export default Financials;
