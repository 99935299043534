import React, { useEffect } from 'react';

import ShareIcon from '@mui/icons-material/Share';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RWebShare } from 'react-web-share';
import { createStructuredSelector } from 'reselect';
import { Box, Button, IconButton, TextField, Typography } from 'sunwise-ui';

import { ReactHookFormSwitch } from 'common/components/form/bootstrap';
import { SHARED_OPTIONS } from 'common/constants';

import * as afterSalesSettingsActions from '../../afterSalesSettings/actions';
import * as afterSalesSettingsSelectors from '../../afterSalesSettings/selectors';

const TextFieldLink = styled(TextField)(
    ({ theme }) => `
    background: ${theme.palette.mode === 'dark' ? '#282b30' : '#eef4fa'};
    border-radius: 8px;
    & .Mui-disabled {
        color: rgba(0, 0, 0, 0.6);
        font-weight: 500;
    }
    & .MuiOutlinedInput-notchedOutline {
        border: 0 !important;
    }
`,
);

const ShareCard = ({
    defaultValues,
    reportName = '',
    save,
    selectedProjectId,
}) => {
    const { t } = useTranslation();
    const permalink = `${import.meta.env.VITE_SUNPAL_URL}/monitoring/${selectedProjectId}`;
    const RWebShareData = {
        text: reportName,
        title: reportName,
        url: permalink,
    };
    const shareChartOptions = [
        {
            label: t('Measurement source', { count: 2 }),
            name: 'measurement_sources_enabled',
        },
        {
            label: t('Generation accuracy'),
            name: 'generation_accuracy_enabled',
        },
        {
            label: t('Energy compliance'),
            name: 'energy_compliance_enabled',
        },
        {
            label: t('ROI progress'),
            name: 'roi_progress_enabled',
        },
        {
            label: t('Accumulated savings'),
            name: 'accumulated_saving_enabled',
        },
        {
            label: t('Report', { count: 2 }),
            name: 'reports_enabled',
        },
    ];

    const {
        control,
        formState: { isDirty },
        handleSubmit,
        reset,
    } = useForm({ defaultValues });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);

    const handleSubmitForm = (data) => save({ ...data, id: selectedProjectId });

    const onCopy = (e) => {
        e.preventDefault();
        return false;
    };

    return (
        <>
            <Box alignItems="center" display="flex" gap={1} mt={1}>
                <TextFieldLink
                    disabled
                    fontWeight="bold"
                    fullWidth
                    onCopy={onCopy}
                    value={permalink}
                />

                <RWebShare
                    closeText={t('Close')}
                    data={RWebShareData}
                    disableNative
                    sites={SHARED_OPTIONS}
                >
                    <IconButton sx={{ ml: 'auto' }}>
                        <ShareIcon />
                    </IconButton>
                </RWebShare>
            </Box>

            <form onSubmit={handleSubmit(handleSubmitForm)}>
                {shareChartOptions.map((option) => (
                    <Typography key={option.name} variant="body2">
                        <ReactHookFormSwitch
                            control={control}
                            fullWidth
                            label={option.label}
                            name={option.name}
                        />
                    </Typography>
                ))}

                {isDirty && (
                    <Box sx={{ textAlign: 'center' }}>
                        <Button type="submit" variant="outlined">
                            {t('Save')}
                        </Button>
                    </Box>
                )}
            </form>
        </>
    );
};

ShareCard.propTypes = {
    defaultValues: PropTypes.object,
    reportName: PropTypes.string,
    save: PropTypes.func,
    selectedProjectId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    defaultValues: afterSalesSettingsSelectors.getSunpalConfigIninitalValues,
});

const mapDispatchToProps = (dispatch) => ({
    save: (data) => dispatch(afterSalesSettingsActions.saveSunpalConfig(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareCard);
