import React from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from 'sunwise-ui';

const UsageSubTable = ({ subscription, isOpen, toggleOpen }) => {
    const { t } = useTranslation();

    const { credits, product_type } = subscription || {};
    const includedCredits = get(credits, 'included_credits_period', 0);
    const usedCredits = get(credits, 'used_credits_period', 0);
    const availableCreditsPeriod = get(credits, 'available_credits_period', 0);
    const availablePrepaidCredits = get(
        credits,
        'available_prepaid_credits',
        0,
    );
    const prepaidCredits = get(credits, 'prepaid_credits', 0);
    const usedPrepaidCredits = get(credits, 'used_prepaid_credits', 0);
    const additionalCredits = get(credits, 'pending_payment_credits', 0);

    const totalAvailable = availableCreditsPeriod + availablePrepaidCredits;

    // For bundle case, extract proposals and reports separately
    const proposals = get(credits, 'proposals', {});
    const reports = get(credits, 'reports', {});
    const includedProposals = get(
        proposals,
        'included_credits_period',
        includedCredits,
    );
    const usedProposals = get(proposals, 'used_credits_period', usedCredits);
    const availableProposalsPeriod = get(
        proposals,
        'available_credits_period',
        availableCreditsPeriod,
    );
    const availablePrepaidProposals = get(
        proposals,
        'available_prepaid_credits',
        availablePrepaidCredits,
    );
    const prepaidProposals = get(proposals, 'prepaid_credits', prepaidCredits);
    const usedPrepaidProposals = get(
        proposals,
        'used_prepaid_credits',
        usedPrepaidCredits,
    );
    const additionalProposals = get(
        proposals,
        'pending_payment_credits',
        additionalCredits,
    );

    const includedReports = get(
        reports,
        'included_credits_period',
        includedCredits,
    );
    const usedReports = get(reports, 'used_credits_period', usedCredits);
    const availableReportsPeriod = get(
        reports,
        'available_credits_period',
        availableCreditsPeriod,
    );
    const availablePrepaidReports = get(
        reports,
        'available_prepaid_credits',
        availablePrepaidCredits,
    );
    const prepaidReports = get(reports, 'prepaid_credits', prepaidCredits);
    const usedPrepaidReports = get(
        reports,
        'used_prepaid_credits',
        usedPrepaidCredits,
    );
    const additionalReports = get(
        reports,
        'pending_payment_credits',
        additionalCredits,
    );

    const totalAvailableProposals =
        availableProposalsPeriod + availablePrepaidProposals;
    const totalAvailableReports =
        availableReportsPeriod + availablePrepaidReports;

    // Handle separate toggle states for proposals and reports in bundle case
    const isProposalsOpen = isOpen?.proposals || false;
    const isReportsOpen = isOpen?.reports || false;
    const toggleProposalsOpen = () =>
        toggleOpen({ ...isOpen, proposals: !isProposalsOpen });
    const toggleReportsOpen = () =>
        toggleOpen({ ...isOpen, reports: !isReportsOpen });

    switch (product_type) {
        case 'aftersales':
            return (
                <Table sx={{ mt: 1 }}>
                    <TableBody
                        sx={{
                            '& .MuiTableCell-root': {
                                paddingTop: 1,
                                paddingBottom: 1,
                            },
                        }}
                    >
                        <TableRow>
                            <TableCell>
                                <Typography variant="body2">
                                    {t('Available reports')}: {totalAvailable}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={toggleOpen}
                                >
                                    {isOpen ? (
                                        <KeyboardArrowUpIcon />
                                    ) : (
                                        <KeyboardArrowDownIcon />
                                    )}
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={2}
                            >
                                <Collapse
                                    in={isOpen}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <Table size="small">
                                        <TableBody
                                            sx={{
                                                '& .MuiTableCell-root': {
                                                    paddingX: 0,
                                                    paddingTop: 1,
                                                    paddingBottom: 1,
                                                },
                                            }}
                                        >
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="body2">
                                                        {t('Included reports')}:{' '}
                                                        {includedCredits}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            {prepaidCredits > 0 && (
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="body2">
                                                            {t(
                                                                'Additional reports purchased',
                                                            )}
                                                            : {prepaidCredits}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="body2">
                                                        {t('Reports used')}:{' '}
                                                        {usedCredits +
                                                            usedPrepaidCredits}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            {additionalCredits > 0 && (
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="body2">
                                                            {t(
                                                                'Reports by use',
                                                            )}
                                                            :{' '}
                                                            {additionalCredits}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            );
        case 'bundle':
            return (
                <Table sx={{ mt: 1 }}>
                    <TableBody
                        sx={{
                            '& .MuiTableCell-root': {
                                paddingTop: 1,
                                paddingBottom: 1,
                            },
                        }}
                    >
                        {/* Proposals Section */}
                        <TableRow>
                            <TableCell>
                                <Typography variant="body2">
                                    {t('Proposals available')}:{' '}
                                    {totalAvailableProposals}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <IconButton
                                    aria-label="expand proposals"
                                    size="small"
                                    onClick={toggleProposalsOpen}
                                >
                                    {isProposalsOpen ? (
                                        <KeyboardArrowUpIcon />
                                    ) : (
                                        <KeyboardArrowDownIcon />
                                    )}
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={2}
                            >
                                <Collapse
                                    in={isProposalsOpen}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <Table size="small">
                                        <TableBody
                                            sx={{
                                                '& .MuiTableCell-root': {
                                                    paddingX: 0,
                                                    paddingTop: 1,
                                                    paddingBottom: 1,
                                                },
                                            }}
                                        >
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="body2">
                                                        {t(
                                                            'Proposals included',
                                                        )}
                                                        : {includedProposals}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            {prepaidProposals > 0 && (
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="body2">
                                                            {t(
                                                                'Additional proposals purchased',
                                                            )}
                                                            : {prepaidProposals}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="body2">
                                                        {t('Proposals used')}:{' '}
                                                        {usedProposals +
                                                            usedPrepaidProposals}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            {additionalProposals > 0 && (
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="body2">
                                                            {t(
                                                                'Proposals by use',
                                                            )}
                                                            :{' '}
                                                            {
                                                                additionalProposals
                                                            }
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </Collapse>
                            </TableCell>
                        </TableRow>

                        {/* Reports Section */}
                        <TableRow>
                            <TableCell>
                                <Typography variant="body2">
                                    {t('Available reports')}:{' '}
                                    {totalAvailableReports}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <IconButton
                                    aria-label="expand reports"
                                    size="small"
                                    onClick={toggleReportsOpen}
                                >
                                    {isReportsOpen ? (
                                        <KeyboardArrowUpIcon />
                                    ) : (
                                        <KeyboardArrowDownIcon />
                                    )}
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={2}
                            >
                                <Collapse
                                    in={isReportsOpen}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <Table size="small">
                                        <TableBody
                                            sx={{
                                                '& .MuiTableDateCell-root': {
                                                    paddingX: 0,
                                                    paddingTop: 1,
                                                    paddingBottom: 1,
                                                },
                                            }}
                                        >
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="body2">
                                                        {t('Included reports')}:{' '}
                                                        {includedReports}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            {prepaidReports > 0 && (
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="body2">
                                                            {t(
                                                                'Additional reports purchased',
                                                            )}
                                                            : {prepaidReports}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="body2">
                                                        {t('Reports used')}:{' '}
                                                        {usedReports +
                                                            usedPrepaidReports}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            {additionalReports > 0 && (
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="body2">
                                                            {t(
                                                                'Reports by use',
                                                            )}
                                                            :{' '}
                                                            {additionalReports}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            );
        default:
            return (
                <Table sx={{ mt: 1 }}>
                    <TableBody
                        sx={{
                            '& .MuiTableCell-root': {
                                paddingTop: 1,
                                paddingBottom: 1,
                            },
                        }}
                    >
                        <TableRow>
                            <TableCell>
                                <Typography variant="body2">
                                    {t('Proposals available')}: {totalAvailable}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={toggleOpen}
                                >
                                    {isOpen ? (
                                        <KeyboardArrowUpIcon />
                                    ) : (
                                        <KeyboardArrowDownIcon />
                                    )}
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={2}
                            >
                                <Collapse
                                    in={isOpen}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <Table size="small">
                                        <TableBody
                                            sx={{
                                                '& .MuiTableCell-root': {
                                                    paddingX: 0,
                                                    paddingTop: 1,
                                                    paddingBottom: 1,
                                                },
                                            }}
                                        >
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="body2">
                                                        {t(
                                                            'Proposals included',
                                                        )}
                                                        : {includedCredits}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            {prepaidCredits > 0 && (
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="body2">
                                                            {t(
                                                                'Additional proposals purchased',
                                                            )}
                                                            : {prepaidCredits}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="body2">
                                                        {t('Proposals used')}:{' '}
                                                        {usedCredits +
                                                            usedPrepaidCredits}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            {additionalCredits > 0 && (
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="body2">
                                                            {t(
                                                                'Proposals by use',
                                                            )}
                                                            :{' '}
                                                            {additionalCredits}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            );
    }
};

UsageSubTable.propTypes = {
    subscription: PropTypes.object,
    isOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]), // Updated to support object for bundle
    toggleOpen: PropTypes.func,
};

export default UsageSubTable;
