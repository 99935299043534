import { create, update } from 'common/api/v1/reportCard';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import fetchUserSettings from '../../profile/actions/fetchProfileData';
import {
    EDIT_ITEM,
    SAVE_DATA,
    SAVE_DATA_FAILURE,
    SAVE_DATA_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (values, successCallback) => (dispatch) => {
    dispatch(actions[SAVE_DATA]());

    const newValues = {
        name: values.name,
        project: values.project,
    };

    const isNew = !values.id;

    const save = isNew
        ? () => create(newValues)
        : () => update(values.id, newValues);

    save(values)
        .then((response) => {
            const data = response?.data?.data;
            dispatch(actions[SAVE_DATA_SUCCESS](data));
            dispatch(fetchUserSettings());
            if (!isNew)
                dispatch(actions[EDIT_ITEM]({ id: values.id, values: data }));
            if (successCallback) successCallback(data?.id, isNew);
        })
        .catch((error) => {
            dispatch(actions[SAVE_DATA_FAILURE](error?.response?.data?.errors));
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
