import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValuesCiBanco = createSelector(
    getModel,
    (model) => model.initialValuesCiBanco,
);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);

// DELETE FINANCIAL PRODUCT

export const getDeleteFinancialProduct = createSelector(
    getModel,
    (model) => model.deleteFinancialProduct,
);

export const getIsDeletingFinancialProduct = createSelector(
    getDeleteFinancialProduct,
    (value) => value.isDeleting,
);

// FINANCIAL PRODUCTS PLAN

export const getFinancialProductsPlan = createSelector(
    getModel,
    (model) => model.financialProductsPlan,
);

export const getIsFetchingFinancialProductsPlan = createSelector(
    getFinancialProductsPlan,
    (value) => value.isFetching,
);

export const getFinancialProductsPlanData = createSelector(
    getFinancialProductsPlan,
    (value) => value.data || [],
);

export const getFinancialProductsPlanErrors = createSelector(
    getFinancialProductsPlan,
    (value) => value.errors,
);

// FINANCIER INSTALLERS

export const getFinancierInstallers = createSelector(
    getModel,
    (model) => model.financierInstallers,
);

export const getIsFetchingFinancierInstallers = createSelector(
    getFinancierInstallers,
    (value) => value.isFetching,
);

export const getFinancierInstallersData = createSelector(
    getFinancierInstallers,
    (value) => value.data || [],
);

export const getFinancierInstallersErrors = createSelector(
    getFinancierInstallers,
    (value) => value.errors,
);

// ALIANCE PRODUCTS

export const getAllianceProducts = createSelector(
    getModel,
    (model) => model.allianceProducts,
);

export const getIsFetchingAllianceProducts = createSelector(
    getAllianceProducts,
    (value) => value.isFetching,
);

export const getAllianceProductsData = createSelector(
    getAllianceProducts,
    (value) => value.data || [],
);

export const getAllianceProductsErrors = createSelector(
    getAllianceProducts,
    (value) => value.errors,
);

// SAVE

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (value) => value.isSaving);

export const getSaveData = createSelector(getSave, (value) => value.data || []);

export const getSaveErrors = createSelector(getSave, (value) => value.errors);

// MODAL

export const getIsOpenAllianceModal = createSelector(
    getModel,
    (model) => model.isOpenAllianceModal,
);

export const getIsOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal,
);
