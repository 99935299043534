import { createSlice } from '@reduxjs/toolkit';

import {
    DELETE,
    DELETE_FAILURE,
    DELETE_SUCCESS,
    FETCH_INTEGRATION,
    FETCH_INTEGRATION_FAILURE,
    FETCH_INTEGRATION_SUCCESS,
    FETCH_INTEGRATIONS,
    FETCH_INTEGRATIONS_FAILURE,
    FETCH_INTEGRATIONS_SUCCESS,
    RESET,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    SET_SELECTED_INTEGRATION,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    initialValuesForm: {
        name: '',
    },
    integration: {
        data: null,
        errors: null,
        isFetching: false,
    },
    integrations: {
        data: [],
        errors: null,
        isFetching: false,
    },
    save: {
        data: [],
        errors: null,
        isFetching: false,
    },
    delete: {
        isDeleting: false,
        errors: [],
    },
};

const webIntegrationsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [DELETE]: (state) => {
            state.delete = { ...state.delete, errors: null, isDeleting: true };
        },
        [DELETE_FAILURE]: (state, action) => {
            state.delete = {
                ...state.delete,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_SUCCESS]: (state) => {
            state.delete = { ...state.delete, errors: null, isDeleting: false };
        },
        [FETCH_INTEGRATION]: (state) => {
            state.integration = {
                ...state.integration,
                errors: null,
                isFetching: true,
            };
        },
        [FETCH_INTEGRATION_FAILURE]: (state, action) => {
            state.integration = {
                ...state.integration,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INTEGRATION_SUCCESS]: (state, action) => {
            state.integration = {
                ...state.integration,
                data: action.payload,
                errors: null,
                isFetching: false,
            };
        },
        [FETCH_INTEGRATIONS]: (state) => {
            state.integrations = {
                ...state.integrations,
                errors: null,
                isFetching: true,
            };
        },
        [FETCH_INTEGRATIONS_FAILURE]: (state, action) => {
            state.integrations = {
                ...state.integrations,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INTEGRATIONS_SUCCESS]: (state, action) => {
            state.integrations = {
                ...state.integrations,
                data: action.payload,
                errors: null,
                isFetching: false,
            };
        },
        [RESET]: () => INITIAL_STATE,
        [SAVE]: (state) => {
            state.save = { ...state.save, errors: null, isFetching: true };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isFetching: false,
            };
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save = {
                ...state.save,
                data: action.payload,
                errors: null,
                isFetching: false,
            };
        },
        [SET_SELECTED_INTEGRATION]: (state, action) => {
            state.selectedIntegration = {
                ...state.selectedIntegration,
                ...action.payload,
            };
        },
    },
});

export const webIntegrationsActions = webIntegrationsSlice.actions;

export default webIntegrationsSlice.reducer;
