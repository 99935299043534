import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { UiDialog as Dialog, Grid } from 'sunwise-ui';

import PeriodSelector from './PeriodSelector';
import PeriodTable from './PeriodTable';
import ResponsivePeriodTable from './ResponsivePeriodTable';

const IndicatorsModal = ({
    countryCurrencyLocale,
    initialValues,
    onClose,
    open,
    title,
}) => {
    const [period, setPeriod] = useState('summer');

    const handleOnChangePeriod = (newPeriod) => setPeriod(newPeriod);

    return (
        <Dialog onClose={onClose} open={open} size="lg" title={title}>
            <Grid container justifyContent="end" mb={2}>
                <Grid size={{ lg: 6, md: 9, xs: 18 }}>
                    <PeriodSelector
                        handleOnChangePeriod={handleOnChangePeriod}
                        period={period}
                    />
                </Grid>
            </Grid>

            <PeriodTable
                countryCurrencyLocale={countryCurrencyLocale}
                indicatorsData={initialValues[period]}
            />

            <ResponsivePeriodTable
                countryCurrencyLocale={countryCurrencyLocale}
                indicatorsData={initialValues[period]}
            />
        </Dialog>
    );
};

IndicatorsModal.propTypes = {
    countryCurrencyLocale: PropTypes.string,
    initialValues: PropTypes.object,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string,
};

export default IndicatorsModal;
