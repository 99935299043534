import { createSlice } from '@reduxjs/toolkit';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
    FETCH_AGENTS,
    FETCH_AGENTS_FAILURE,
    FETCH_AGENTS_SUCCESS,
    FETCH_CONTACTS_TO_REASING,
    FETCH_CONTACTS_TO_REASING_FAILURE,
    FETCH_CONTACTS_TO_REASING_SUCCESS,
    FETCH_CONTACT_TYPES,
    FETCH_CONTACT_TYPES_FAILURE,
    FETCH_CONTACT_TYPES_SUCCESS,
    FETCH_FILTER_CONTACTS,
    FETCH_FILTER_CONTACTS_FAILURE,
    FETCH_FILTER_CONTACTS_SUCCESS,
    INITIALIZE,
    INITIALIZE_FAILURE,
    INITIALIZE_REASIGN_FORM,
    INITIALIZE_SUCCESS,
    RESET,
    SAVE_CONTACT,
    SAVE_CONTACT_FAILURE,
    SAVE_CONTACT_SUCCESS,
    SET_HAS_CONTACTS,
    SET_IS_DOWNLOADING,
    SET_IS_OPEN_REASIGN_MODAL,
    SET_IS_UPLOADING,
    UPDATE_ITEMS_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    agents: {
        data: [],
        error: null,
        isEmpty: false,
        isLoading: false,
    },
    bulkUpdateItems: {
        data: [],
        errors: null,
        isFetching: false,
    },
    contacts: {
        data: { data: [], pagination: null },
        errors: null,
        isFetching: false,
    },
    contactsToReasign: {
        errors: [],
        data: [],
        isFetching: false,
    },
    contactTypes: {
        data: [],
        errors: [],
        isFetching: false,
    },
    hasContacts: true,
    isDownloading: false,
    isInitializing: true,
    isOpenReasignModal: false,
    isUploading: false,
    reasignInitialValues: {
        callback: null,
        reasignAgents: [],
    },
    saveContact: {
        data: {},
        errors: null,
        isSaving: false,
    },
    searchContact: {
        error: null,
        isLoading: false,
    },
};

const slice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [BULK_UPDATE_ITEMS]: (state) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: [],
                isFetching: true,
            };
        },
        [BULK_UPDATE_ITEMS_FAILURE]: (state, action) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: action.payload,
                isFetching: false,
            };
        },
        [BULK_UPDATE_ITEMS_SUCCESS]: (state, action) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_AGENTS]: (state) => {
            state.agents.isLoading = true;
        },
        [FETCH_AGENTS_FAILURE]: (state, action) => {
            state.agents = {
                ...state.agents,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_AGENTS_SUCCESS]: (state, action) => {
            state.agents = {
                ...state.agents,
                data: action.payload,
                isLoading: false,
            };
        },
        [FETCH_CONTACTS_TO_REASING]: (state) => {
            state.contactsToReasign = {
                ...state.contactsToReasign,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_CONTACTS_TO_REASING_FAILURE]: (state, action) => {
            state.contactsToReasign = {
                ...state.contactsToReasign,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CONTACTS_TO_REASING_SUCCESS]: (state, action) => {
            state.contactsToReasign = {
                ...state.contactsToReasign,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CONTACT_TYPES]: (state) => {
            state.contactTypes.isFetching = true;
        },
        [FETCH_CONTACT_TYPES_FAILURE]: (state, action) => {
            state.contactTypes = {
                ...state.contactTypes,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CONTACT_TYPES_SUCCESS]: (state, action) => {
            state.contactTypes = {
                ...state.contactTypes,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FILTER_CONTACTS]: (state) => {
            state.contacts = {
                ...state.contacts,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_FILTER_CONTACTS_FAILURE]: (state, action) => {
            state.contacts = {
                ...state.contacts,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FILTER_CONTACTS_SUCCESS]: (state, action) => {
            state.contacts = {
                ...state.contacts,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE]: (state) => {
            state.isInitializing = true;
        },
        [INITIALIZE_FAILURE]: (state) => {
            state.isInitializing = false;
        },
        [INITIALIZE_SUCCESS]: (state) => {
            state.isInitializing = false;
        },
        [INITIALIZE_REASIGN_FORM]: (state, action) => {
            state.reasignInitialValues = {
                ...state.reasignInitialValues,
                ...action.payload,
            };
        },
        [RESET]: () => INITIAL_STATE,
        [SAVE_CONTACT]: (state) => {
            state.saveContact = {
                ...state.saveContact,
                errors: [],
                isSaving: true,
            };
        },
        [SAVE_CONTACT_FAILURE]: (state, action) => {
            state.saveContact = {
                ...state.saveContact,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_CONTACT_SUCCESS]: (state, action) => {
            state.saveContact = {
                ...state.saveContact,
                data: action.payload,
                isSaving: false,
            };
        },
        [SET_HAS_CONTACTS]: (state, action) => {
            state.hasContacts = action.payload;
        },
        [SET_IS_DOWNLOADING]: (state, action) => {
            state.isDownloading = action.payload;
        },
        [SET_IS_OPEN_REASIGN_MODAL]: (state, action) => {
            state.isOpenReasignModal = action.payload;
        },
        [SET_IS_UPLOADING]: (state, action) => {
            state.isUploading = action.payload;
        },
        [UPDATE_ITEMS_SUCCESS]: (state, action) => {
            state.contacts.data.data = action.payload;
        },
    },
});

export const actions = slice.actions;

export default slice.reducer;
