import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Container as MaterialContainer, Grid } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import * as customColumnsSelectors from 'common/modules/customColumns/selectors';
import RestrictedAccessLanding from 'common/modules/restrictedAccessLanding/Container';

import * as companyGeneralSelectors from '../companyGeneral/selectors';

import * as actions from './actions';
import { Alliances, AllianceProducts, ProductForm } from './components';
import * as selectors from './selectors';

const Container = ({
    bulkActivateItems,
    canModify,
    canView,
    defaultCurrency,
    fetchFinarcierProduct,
    fetchTemplates,
    isFetching,
    isFetchingTableSettings,
    isFetchingTemplates,
    paginationProducts,
    prepareView,
    productModalIsOpen,
    products,
    tableSettings,
    templates,
    toggleModalProduct,
    toggleProductEnable,
}) => {
    const { t } = useTranslation();
    const [filterData, setFilterData] = useState({
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        searchText: '',
        sortBy: 'desc',
    });

    useEffect(() => {
        fetchFinarcierProduct(filterData);
    }, []);

    const handleOnClickProduct = (product) => prepareView(product);
    const handleChangeSwitch = (id) => toggleProductEnable(filterData, id);

    return (
        <RestrictedAccessLanding
            addonName="Alliances"
            url="https://somos.sunwise.io/sunwise-sales"
        >
            <MaterialContainer maxWidth={false}>
                <HeaderContainer>
                    <Grid size={{ xs: 'grow' }}>
                        <TitleIcon title={t('Alliance', { count: 2 })} />
                    </Grid>
                </HeaderContainer>

                {canView && (
                    <>
                        <Alliances
                            canModifyAlliances={canModify}
                            fetchFinarcierProduct={fetchFinarcierProduct}
                            filterData={filterData}
                        />

                        <AllianceProducts
                            canModify={canModify}
                            canView={canView}
                            fetchFinarcierProduct={fetchFinarcierProduct}
                            filterData={filterData}
                            handleChangeSwitch={handleChangeSwitch}
                            handleClickActivateItems={bulkActivateItems}
                            handleClickProduct={handleOnClickProduct}
                            isFetching={isFetching || isFetchingTableSettings}
                            items={products}
                            paginationData={paginationProducts}
                            setFilterData={setFilterData}
                            tableSettings={tableSettings}
                        />
                    </>
                )}

                <ProductForm
                    defaultCurrency={defaultCurrency}
                    fetchTemplates={fetchTemplates}
                    isFetchingTemplates={isFetchingTemplates}
                    onHide={() => toggleModalProduct(false)}
                    padding="16px 48px"
                    show={productModalIsOpen}
                    templates={templates}
                    toggleModal={toggleModalProduct}
                />
            </MaterialContainer>
        </RestrictedAccessLanding>
    );
};

const mapStateToProps = createStructuredSelector({
    defaultCurrency: companyGeneralSelectors.getCurrentCompanyCurrency,
    isFetching: selectors.getFinancierProductIsFetching,
    isFetchingTableSettings: customColumnsSelectors.getIsFetchingTableSettings,
    isFetchingTemplates: selectors.getIsFetchingTemplateCatalogs,
    paginationProducts: selectors.getPaginationFinancierProduct,
    productEnableUpdating: selectors.getProductEnableUpdating,
    productModalIsOpen: selectors.getProductModalIsOpen,
    products: selectors.getFinancierProductData,
    tableSettings: customColumnsSelectors.getTableSettingsData,
    templates: selectors.getfetchTemplateCatalogsData,
});

const mapDispatchToProps = (dispatch) => ({
    bulkActivateItems: (ids, active, callback) =>
        dispatch(actions.bulkActivateItems(ids, active, callback)),
    fetchFinarcierProduct: (filterData) =>
        dispatch(actions.fetchFinarcierProduct(filterData)),
    fetchTemplates: () => dispatch(actions.fetchTemplates()),
    prepareView: (product) => dispatch(actions.prepareView(product)),
    toggleModalProduct: (status) =>
        dispatch(actions.toggleModalProduct(status)),
    toggleProductEnable: (filterData, id) =>
        dispatch(actions.toggleProductEnable(filterData, id)),
});

Container.propTypes = {
    bulkActivateItems: PropTypes.func,
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    defaultCurrency: PropTypes.object,
    fetchFinarcierProduct: PropTypes.func,
    fetchTemplates: PropTypes.func,
    isFetching: PropTypes.bool,
    isFetchingTableSettings: PropTypes.bool,
    isFetchingTemplates: PropTypes.bool,
    paginationProducts: PropTypes.object,
    prepareView: PropTypes.func,
    productModalIsOpen: PropTypes.bool,
    products: PropTypes.array,
    tableSettings: PropTypes.array,
    templates: PropTypes.array,
    toggleModalProduct: PropTypes.func,
    toggleProductEnable: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.ALLIANCES_PERMISSION),
)(Container);
