import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid } from 'sunwise-ui';

import ErrorItem from 'common/components/ErrorItem';
import {
    ReactHookFormInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import TextVariant from 'common/components/TextVariant';
import { DEFAULT_LANGUAGE } from 'common/constants/multiregion';
import yupResolver from 'common/utils/yupResolver';

import { getCountriesToSelect, getCountryFromAddress } from '../helpers';
import * as selectors from '../selectors';
import validate from '../validate';

const FormContainer = ({
    address,
    countries,
    errors,
    onSubmit,
    initialValues,
    isFetchingCountries,
    isLoadingSession,
}) => {
    const { i18n, t } = useTranslation();

    const { control, handleSubmit, setValue } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => {
        if (address) {
            const country = getCountryFromAddress(countries, address);
            setValue('country', country.id);
        }
    }, [countries, address]);

    const renderMonthInputField = () => {
        return (
            <Grid size={{ xs: 'grow' }}>
                <ReactHookFormInput
                    autoComplete="off"
                    control={control}
                    name="monthOfBirth"
                    placeholder="MM*"
                    minLength="2"
                    maxLength="2"
                    onlyNumbers={true}
                    type="text"
                    id="register_form_number_birth_month"
                />
            </Grid>
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
                <Grid size={{ xs: 'grow' }}>
                    <ReactHookFormInput
                        autoComplete="off"
                        control={control}
                        id="register_form_text_first_name"
                        name="first_name"
                        placeholder={t('Name', { count: 2 })}
                        type="text"
                    />
                </Grid>
                <Grid size={{ xs: 'grow' }}>
                    <ReactHookFormInput
                        autoComplete="off"
                        control={control}
                        id="register_form_text_last_name"
                        name="last_name"
                        placeholder={t('Last name', { count: 2 })}
                        type="text"
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid size={{ xs: 'grow' }}>
                    <ReactHookFormInput
                        autoComplete="off"
                        control={control}
                        id="register_form_text_email"
                        name="email"
                        placeholder={t('Email')}
                        type="email"
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid size={{ xs: 'grow' }}>
                    <ReactHookFormInput
                        autoComplete="off"
                        control={control}
                        id="register_form_text_confirm_email"
                        name="confirm_email"
                        placeholder={t('Confirm your email')}
                        type="email"
                    />
                </Grid>
            </Grid>

            <TextVariant text={t('Date of birth')} variant="normal" />

            <Grid container>
                {i18n.language.toLowerCase() === DEFAULT_LANGUAGE &&
                    renderMonthInputField()}
                <Grid size={{ xs: 'grow' }}>
                    <ReactHookFormInput
                        autoComplete="off"
                        control={control}
                        id="register_form_number_birth_day"
                        maxLength="2"
                        minLength="2"
                        name="dayOfBirth"
                        onlyNumbers={true}
                        placeholder="DD*"
                        type="text"
                    />
                </Grid>
                {i18n.language.toLowerCase() !== DEFAULT_LANGUAGE &&
                    renderMonthInputField()}
                <Grid size={{ xs: 'grow' }}>
                    <ReactHookFormInput
                        autoComplete="off"
                        control={control}
                        id="register_form_number_birth_year"
                        maxLength="4"
                        minLength="4"
                        name="yearOfBirth"
                        onlyNumbers={true}
                        placeholder="YYYY*"
                        type="text"
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid size={{ xs: 'grow' }}>
                    <ReactHookFormInput
                        autoComplete="off"
                        control={control}
                        id="register_form_text_business_name"
                        name="business_name"
                        placeholder={t('Business name')}
                        type="text"
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid size={{ xs: 'grow' }}>
                    <ReactHookFormInput
                        autoComplete="off"
                        control={control}
                        id="register_form_number_business_phone"
                        name="phone"
                        placeholder={t('Business phone')}
                        type="phone"
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid size={{ xs: 'grow' }}>
                    <ReactHookFormSelect
                        autoComplete="off"
                        control={control}
                        id="register_form_country"
                        name="country"
                        options={getCountriesToSelect(
                            countries,
                            isFetchingCountries,
                        )}
                        placeholder={t('Country')}
                    />
                </Grid>
            </Grid>

            {errors &&
                errors.map((error, index) => (
                    <ErrorItem key={index}>{`* ${error}`}</ErrorItem>
                ))}

            <Box sx={{ my: 2, textAlign: 'center' }}>
                <Button
                    disabled={isLoadingSession}
                    fullWidth
                    id="register_form_create_button"
                    size="medium"
                    sx={{ textTransform: 'none' }}
                    type="submit"
                >
                    {isLoadingSession
                        ? t('Loading').concat('...')
                        : t('Create account')}
                </Button>
            </Box>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    address: selectors.getDataFetchGeocode,
});

FormContainer.propTypes = {
    address: PropTypes.object,
    countries: PropTypes.array,
    errors: PropTypes.array,
    onSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isFetchingCountries: PropTypes.bool,
    isLoadingSession: PropTypes.bool,
};

export default connect(mapStateToProps)(FormContainer);
