import { getData } from 'common/api/v1/report';

import {
    FETCH_REPORT_DETAILS,
    FETCH_REPORT_DETAILS_FAILURE,
    FETCH_REPORT_DETAILS_SUCCESS,
} from '../actionTypes';
import { getElectricBillPerMonths } from '../helpers';
import { reportReviewProActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(reportReviewProActions[FETCH_REPORT_DETAILS]());

    getData(id)
        .then((response) => {
            const data = response?.data?.data;

            data.report_files = getElectricBillPerMonths({
                items: data?.report_files,
            });

            dispatch(
                reportReviewProActions[FETCH_REPORT_DETAILS_SUCCESS](data),
            );
        })
        .catch((error) =>
            dispatch(
                reportReviewProActions[FETCH_REPORT_DETAILS_FAILURE](error),
            ),
        );
};
