import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import { ReactHookFormInputDatePicker } from 'common/components/form/bootstrap';
import { DEBOUNCE_TIME_1000 } from 'common/constants';
import { parseDate } from 'common/utils/dates';

import { DATE_FORMATS, MIN_DATE } from '../../../afterSalesSettings/constants';

const DateFields = ({
    control,
    disabled,
    finalDate,
    handleOnChangeDate,
    initialDate,
}) => {
    const [timer, setTimer] = useState(null);
    const { t } = useTranslation();

    const handleOnChange = () => {
        clearTimeout(timer);
        const newTimer = setTimeout(
            () => handleOnChangeDate(),
            DEBOUNCE_TIME_1000,
        );
        setTimer(newTimer);
    };

    return (
        <Grid container>
            <Grid size={{ sm: 9, xs: 18 }}>
                <ReactHookFormInputDatePicker
                    control={control}
                    disableFuture
                    disabled={disabled}
                    label={t('Initial date')}
                    minDate={MIN_DATE}
                    maxDate={
                        finalDate
                            ? parseDate(finalDate, DATE_FORMATS.SUNWISE)
                            : undefined
                    }
                    name="initial_date"
                    onChange={handleOnChange}
                    variant="standard"
                />
            </Grid>

            <Grid size={{ sm: 9, xs: 18 }}>
                <ReactHookFormInputDatePicker
                    control={control}
                    disableFuture
                    disabled={disabled}
                    label={t('Final date')}
                    minDate={
                        initialDate
                            ? parseDate(initialDate, DATE_FORMATS.SUNWISE)
                            : MIN_DATE
                    }
                    name="final_date"
                    onChange={handleOnChange}
                    variant="standard"
                />
            </Grid>
        </Grid>
    );
};

DateFields.propTypes = {
    control: PropTypes.any,
    disabled: PropTypes.bool,
    finalDate: PropTypes.string,
    handleOnChangeDate: PropTypes.func,
    initialDate: PropTypes.string,
};

export default DateFields;
