import React, { useState, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Grid, Tooltip, Typography } from 'sunwise-ui';

import { formatDate, parseDate } from 'common/utils/dates';
import { getDateFormatByLocale } from 'common/utils/helpers/multiregion';

import * as projectActions from '../actions';
import * as selectors from '../selectors';

import DropdownCustomCardMenuMap from './DropdownCustomCardMenuMap';
import ProjectActionsDropdown from './ProjectActionsDropdown';

const Wrapper = styled(Box)(
    ({ theme }) => `
    background: ${
        theme.palette.mode === 'dark'
            ? 'rgb(40, 43, 48)'
            : 'rgba(238, 244, 250, 0.35)'
    };
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 100%;
`,
);

const ProjectListItem = ({
    canDelete,
    canModify,
    createdBy,
    date,
    handleClickArchive,
    handleClickRename,
    handleClickView,
    id,
    isArchived,
    isSelected,
    rate,
    statusProject,
    statusProjectsToSelect,
    title,
    updateStatusProject,
}) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState({
        color: 'transparent',
        icon: null,
        key: '',
        label: t('Select status'),
        value: t('Select status'),
    });

    useEffect(() => {
        if (!statusProject) return;
        setSelected({
            color: statusProject.primary_color,
            key: statusProject.id,
            value: statusProject.name,
        });
    }, []);

    const handleSelected = (status) => {
        if (status.key === '') return;
        updateStatusProject(id, status.key, () => setSelected(selected));
        setSelected(status);
    };

    const createdAt = formatDate(
        parseDate(date, 'dd/MM/yyyy HH:mm:ss'),
        getDateFormatByLocale(),
    );

    const commonProps = { sx: { cursor: 'pointer' }, onClick: handleClickView };

    return (
        <Wrapper
            sx={{
                border: (theme) =>
                    ` 1px solid ${
                        isSelected ? theme.palette.primary.main : 'transparent'
                    }`,
                display: { xs: 'none', md: 'block' },
            }}
        >
            <Grid container alignItems="center">
                <Grid size={{ lg: 3, md: 5, xs: 'grow' }} {...commonProps}>
                    <Tooltip title={title}>
                        <Typography
                            fontWeight="bold"
                            noWrap
                            sx={{
                                fontSize: '14px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '100%',
                            }}
                            variant="body2"
                        >
                            {title}
                        </Typography>
                    </Tooltip>
                </Grid>

                <Grid size={{ lg: 2, md: 3, xs: 4 }} {...commonProps}>
                    <Typography
                        noWrap
                        variant="body2"
                        sx={{
                            fontSize: '14px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%',
                        }}
                    >
                        {rate || '----'}
                    </Typography>
                </Grid>

                <Grid order={{ lg: 4, xs: 2 }} size={{ lg: 5, md: 9, xs: 18 }}>
                    <DropdownCustomCardMenuMap
                        canModify={canModify}
                        handleSelected={handleSelected}
                        selected={selected}
                        statusToSelect={statusProjectsToSelect}
                    />
                </Grid>
                <Grid order={{ lg: 5, xs: 3 }} size={1} textAlign="right">
                    <ProjectActionsDropdown
                        canDelete={canDelete}
                        canModify={canModify}
                        handleClickArchive={handleClickArchive}
                        handleClickRename={handleClickRename}
                        isArchived={isArchived}
                    />
                </Grid>

                <Grid
                    order={{ lg: 3, xs: 4 }}
                    size={{ lg: 3, xs: 8 }}
                    {...commonProps}
                >
                    <Typography variant="body2">
                        {t('Created at')} {createdAt}
                    </Typography>
                </Grid>
                <Grid
                    order={{ lg: 2, xs: 5 }}
                    size={{ lg: 4, xs: 10 }}
                    {...commonProps}
                >
                    <Typography variant="body2">
                        {t('By')} {createdBy}
                    </Typography>
                </Grid>
            </Grid>
        </Wrapper>
    );
};

const mapStateToProps = createStructuredSelector({
    statusProjectsToSelect: selectors.getStatusProjectToSelect,
});

const mapDispatchToProps = (dispatch) => ({
    updateStatusProject: (id, project_status, onFail) =>
        dispatch(
            projectActions.updateStatusProject(id, project_status, onFail),
        ),
});

ProjectListItem.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    createdBy: PropTypes.string,
    date: PropTypes.string,
    handleClickArchive: PropTypes.func,
    handleClickRename: PropTypes.func,
    handleClickView: PropTypes.func,
    id: PropTypes.string,
    isArchived: PropTypes.bool,
    isSelected: PropTypes.bool,
    optionsMenu: PropTypes.array,
    rate: PropTypes.string,
    statusProject: PropTypes.object,
    statusProjectsToSelect: PropTypes.array,
    title: PropTypes.string,
    updateStatusProject: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectListItem);
