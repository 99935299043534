import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import PropTypes from 'prop-types';
import { Grid } from 'sunwise-ui';

import { ReactHookFormIntlNumberInputConsumption } from 'common/components/form/bootstrap';

const EnergyField = ({ control, disabled, error, name, onBlur }) => {
    let icon = null;
    if (error === true) {
        icon = <ReportProblemIcon color="error" />;
    } else if (error === false) {
        icon = <CheckCircleIcon color="success" />;
    }

    return (
        <Grid size={{ lg: 'grow', xs: 18 }}>
            <ReactHookFormIntlNumberInputConsumption
                allowDecimals={false}
                append="kWh"
                control={control}
                disabled={disabled}
                fullWidth
                name={name}
                onBlur={onBlur}
                prepend={icon}
                variant="standard"
            />
        </Grid>
    );
};

EnergyField.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
};

export default EnergyField;
