import { filter } from 'common/api/v1/proposalsSummary';
import { formatDate, parseDate } from 'common/utils/dates';

import {
    FETCH_PROPOSALS,
    FETCH_PROPOSALS_FAILURE,
    FETCH_PROPOSALS_SUCCESS,
} from '../actionTypes';
import { proposalsActions } from '../reducer';

export default ({
        createdBy = 'all',
        endDate = '',
        orderBy = 'created_at',
        page = 0,
        pageSize = 25,
        searchText,
        sortBy = null,
        startDate = '',
    } = {}) =>
    (dispatch) => {
        const _endDate = parseDate(endDate, 'dd/MM/yyyy');
        const _startDate = parseDate(startDate, 'dd/MM/yyyy');

        dispatch(proposalsActions[FETCH_PROPOSALS]());

        filter({
            createdBy,
            endDate: formatDate(_endDate, 'yyyy-MM-dd'),
            orderBy,
            page: page + 1,
            perPage: pageSize,
            searchText,
            sortBy,
            startDate: formatDate(_startDate, 'yyyy-MM-dd'),
        })
            .then((response) =>
                dispatch(
                    proposalsActions[FETCH_PROPOSALS_SUCCESS](response.data),
                ),
            )
            .catch((error) =>
                dispatch(
                    proposalsActions[FETCH_PROPOSALS_FAILURE](
                        error?.response?.data?.errors,
                    ),
                ),
            );
    };
