import React from 'react';

import BarChartIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { Tooltip } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
} from 'sunwise-ui';

import MultiSelect from 'common/components/MultiSelect';
import { DAYS_NAMES } from 'common/constants';
import { getDayLabel } from 'common/utils/helpers';

import ChartVisibilityControls from './ChartVisibilityControls';
import PeriodSelector from './PeriodSelector';

export const CHART_OPTIONS = {
    DAILY: 3,
    MONTHLY: 1,
    WEEKLY: 2,
    YEARLY: 0,
};

const CHART_OPTIONS_CONFIG = {
    [CHART_OPTIONS.YEARLY]: { label: 'Yearly' },
    [CHART_OPTIONS.MONTHLY]: { label: 'Period' },
    [CHART_OPTIONS.WEEKLY]: { label: 'Weekly' },
    [CHART_OPTIONS.DAILY]: { label: 'Daily' },
};

const ChartControls = ({
    chartRef,
    chartType,
    chartTypeOptions,
    consumptionHistory,
    customIconButtons,
    datasets,
    defaultVisibility,
    handleOnChangePeriod,
    handleOnChangeSelectedDays,
    handleOnChangeSelectedPeriod,
    handleOnChangeSelectedWeeks,
    hideChangeTypeButtons = false,
    onChangeVisibility,
    optionsToSelect = Object.values(CHART_OPTIONS),
    selectMultipleDays = false,
    selectMultipleWeeks = false,
    selectedDays,
    selectedOption,
    selectedPeriod,
    selectedStacks,
    selectedWeeks,
    series,
    setChartType = () => {},
    setSelectedStacks,
    setTooltipConfig,
    showPeriodSelectors = true,
    showStackSelector = false,
    showVisibilityControls = false,
    stacksToSelect,
    tooltipConfig,
    weeksToSelect = 4,
}) => {
    const { t } = useTranslation();

    const handleOnChangeStackOption = (e) => setSelectedStacks(e.target.value);

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            {showPeriodSelectors && (
                <Box display={'flex'} flexWrap="wrap" gap="16px">
                    <FormControl size="small">
                        <InputLabel>{t('Option')}</InputLabel>
                        <Select
                            label={t('Option')}
                            onChange={handleOnChangePeriod}
                            value={selectedOption}
                        >
                            {optionsToSelect.map((value) => {
                                const config = CHART_OPTIONS_CONFIG[value];
                                if (!config) return null;
                                return (
                                    <MenuItem key={value} value={value}>
                                        {t(config.label)}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>

                    {consumptionHistory?.length > 0 &&
                        [
                            CHART_OPTIONS.MONTHLY,
                            CHART_OPTIONS.WEEKLY,
                            CHART_OPTIONS.DAILY,
                        ].includes(selectedOption) && (
                            <PeriodSelector
                                consumptionHistory={consumptionHistory}
                                onChange={handleOnChangeSelectedPeriod}
                                showHasDropdown
                                value={selectedPeriod}
                            />
                        )}

                    {[CHART_OPTIONS.WEEKLY, CHART_OPTIONS.DAILY].includes(
                        selectedOption,
                    ) && (
                        <MultiSelect
                            allTextValue={t('All text', {
                                context: 'female',
                                count: 2,
                            })}
                            optionsSelected={selectedWeeks}
                            handleOnChangeOptionsSelected={
                                handleOnChangeSelectedWeeks
                            }
                            multiple={selectMultipleWeeks}
                            label={t('Week', { count: 2 })}
                            options={[...Array(weeksToSelect).keys()].map(
                                (index) => `${t('Week')} ${index + 1}`,
                            )}
                        />
                    )}

                    {selectedOption === CHART_OPTIONS.DAILY && (
                        <MultiSelect
                            allTextValue={t('All text', { count: 2 })}
                            optionsSelected={selectedDays}
                            handleOnChangeOptionsSelected={
                                handleOnChangeSelectedDays
                            }
                            multiple={selectMultipleDays}
                            label={t('Day', { count: 2 })}
                            options={DAYS_NAMES.map((day) => getDayLabel(day))}
                        />
                    )}
                </Box>
            )}

            {showStackSelector &&
                stacksToSelect &&
                stacksToSelect.length > 0 && (
                    <Box>
                        <FormControl size="small">
                            <InputLabel>{t('Option')}</InputLabel>
                            <Select
                                label={t('Option')}
                                onChange={handleOnChangeStackOption}
                                value={selectedStacks}
                            >
                                <MenuItem value="all">{t('All text')}</MenuItem>
                                {stacksToSelect?.map((stack) => (
                                    <MenuItem
                                        key={stack.name}
                                        value={stack.name}
                                    >
                                        {t(stack.visibilityLabel)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                )}

            <Box flex={1} textAlign="right">
                {!hideChangeTypeButtons && (
                    <>
                        <IconButton
                            disabled={chartType === chartTypeOptions[0]}
                            onClick={() => setChartType(chartTypeOptions[0])}
                        >
                            <ShowChartIcon />
                        </IconButton>

                        <IconButton
                            disabled={chartType === chartTypeOptions[1]}
                            onClick={() => setChartType(chartTypeOptions[1])}
                        >
                            <BarChartIcon />
                        </IconButton>
                    </>
                )}

                {showVisibilityControls && (
                    <ChartVisibilityControls
                        chartRef={chartRef}
                        datasets={datasets}
                        defaultVisibility={defaultVisibility}
                        onChangeVisibility={onChangeVisibility}
                        series={series}
                        setTooltipConfig={setTooltipConfig}
                        tooltipConfig={tooltipConfig}
                    />
                )}

                {customIconButtons?.length > 0 &&
                    customIconButtons.map(({ icon, label, ...rest }, index) => (
                        <Tooltip key={index} title={label}>
                            <IconButton {...rest}>{icon}</IconButton>
                        </Tooltip>
                    ))}
            </Box>
        </Box>
    );
};

ChartControls.propTypes = {
    chartRef: PropTypes.object,
    chartType: PropTypes.string,
    chartTypeOptions: PropTypes.array,
    consumptionHistory: PropTypes.array,
    customIconButtons: PropTypes.array,
    datasets: PropTypes.array,
    defaultVisibility: PropTypes.array,
    handleOnChangePeriod: PropTypes.func,
    handleOnChangeSelectedDays: PropTypes.func,
    handleOnChangeSelectedPeriod: PropTypes.func,
    handleOnChangeSelectedWeeks: PropTypes.func,
    hideChangeTypeButtons: PropTypes.bool,
    monthSelector: PropTypes.element,
    onChangeVisibility: PropTypes.func,
    optionsToSelect: PropTypes.array,
    selectMultipleDays: PropTypes.bool,
    selectMultipleWeeks: PropTypes.bool,
    selectedDays: PropTypes.array,
    selectedOption: PropTypes.number,
    selectedPeriod: PropTypes.number,
    selectedStacks: PropTypes.string,
    selectedWeeks: PropTypes.array,
    series: PropTypes.array,
    setChartType: PropTypes.func,
    setSelectedStacks: PropTypes.func,
    setTooltipConfig: PropTypes.func,
    showPeriodSelectors: PropTypes.bool,
    showStackSelector: PropTypes.bool,
    showVisibilityControls: PropTypes.bool,
    stacksToSelect: PropTypes.array,
    tooltipConfig: PropTypes.object,
    weeksToSelect: PropTypes.number,
};

export default ChartControls;
