import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from 'sunwise-ui';

import { HOURS, MONTHS } from 'common/constants';
import { getMonthLabel } from 'common/utils/helpers';

const StyledCol = styled(Grid)`
    flex-direction: column;
    min-width: 50px;
    width: 4%;
`;

const HourlyGridWrapper = ({ children }) => {
    return (
        <>
            <Box display="flex" gap={1}>
                <StyledCol columns={1} container>
                    <Grid size={1} />
                </StyledCol>
                <Grid
                    alignItems="center"
                    columns={24}
                    container
                    gap={0}
                    justifyContent="center"
                    m={0}
                    spacing={0}
                    textAlign="center"
                >
                    {HOURS.map((hour) => (
                        <Grid
                            key={hour}
                            size={1}
                            sx={{ transform: 'rotate(-90deg)', p: 1 }}
                            textAlign="center"
                        >
                            <Typography variant="h6">{hour}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            <Box display="flex" mt={1} gap={1}>
                <StyledCol columns={13} container mt={0}>
                    {MONTHS.map((month) => (
                        <Grid key={month} size={1}>
                            <Typography
                                sx={{ lineHeight: '29px' }}
                                variant="h6"
                            >
                                {getMonthLabel(month)}
                            </Typography>
                        </Grid>
                    ))}
                </StyledCol>

                {children}
            </Box>
        </>
    );
};

HourlyGridWrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default HourlyGridWrapper;
