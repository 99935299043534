import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import StarIcon from '@mui/icons-material/Star';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, Grid, Typography } from 'sunwise-ui';

import { CB_PORTAL_SECTIONS } from 'common/constants';

const BillingInformation = ({
    billingInformation,
    canModify,
    handlePortal,
    paymentSources,
    userSettings,
}) => {
    const { t } = useTranslation();
    return (
        <Card>
            <Card.Header>
                <Typography variant="body1" fontWeight="bold">
                    {t('Billing')}
                </Typography>
            </Card.Header>
            <Card.Body sx={{ minHeight: '487px' }}>
                <Grid container>
                    <Grid display="flex" flexDirection="column" size={18}>
                        <Box alignItems="center" display="flex" gap={2}>
                            <Typography fontWeight="bold" variant="body2">
                                {t('Billing address')}
                            </Typography>
                            <Button
                                disabled={!canModify}
                                color="secondary"
                                onClick={() =>
                                    handlePortal(
                                        CB_PORTAL_SECTIONS.EDIT_BILLING_ADDRESS,
                                    )
                                }
                                variant="text"
                            >
                                {t('Edit')}
                            </Button>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={1}
                            ml={2}
                            mt={1}
                        >
                            {isEmpty(billingInformation) && (
                                <Typography variant="caption">
                                    {t('There is no data to show')}
                                </Typography>
                            )}
                            <Typography variant="body2">
                                {billingInformation.first_name}{' '}
                                {billingInformation.last_name}
                            </Typography>
                            <Typography variant="body2">
                                {billingInformation.city}{' '}
                                {billingInformation.state}
                            </Typography>
                            <Typography variant="body2">
                                {billingInformation.line1}{' '}
                                {billingInformation.zip}
                            </Typography>
                            <Typography variant="body2">
                                {billingInformation.country}
                            </Typography>
                        </Box>
                        <Box alignItems="center" display="flex" gap={2} mt={3}>
                            <Typography fontWeight="bold" variant="body2">
                                {t('Billing information')}
                            </Typography>
                            <Button
                                disabled={!canModify}
                                color="secondary"
                                onClick={() =>
                                    handlePortal(
                                        CB_PORTAL_SECTIONS.EDIT_ACCOUNT_DETAILS,
                                    )
                                }
                                variant="text"
                            >
                                {t('Edit')}
                            </Button>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={1}
                            ml={2}
                            mt={1}
                        >
                            <Typography variant="body2">
                                {userSettings.first_name}{' '}
                                {userSettings.last_name}
                            </Typography>
                            <Typography variant="body2">
                                {billingInformation.cf_tax_id}
                            </Typography>
                        </Box>
                        <Box alignItems="center" display="flex" gap={2} mt={3}>
                            <Typography fontWeight="bold" variant="body2">
                                {t('Payment sources')}
                            </Typography>
                            <Button
                                disabled={!canModify}
                                color="secondary"
                                onClick={() =>
                                    handlePortal(
                                        CB_PORTAL_SECTIONS.PAYMENT_SOURCES,
                                    )
                                }
                                variant="text"
                                visible={!isEmpty(paymentSources)}
                            >
                                {t('Edit')}
                            </Button>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={1}
                            ml={2}
                            mt={2}
                        >
                            {paymentSources.map((source, index) => (
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    gap={1}
                                    key={`source_${index}`}
                                >
                                    <CreditCardIcon fontSize="small" />
                                    <Typography variant="body2">
                                        <Typography pr={1} variant="caption">
                                            xxxx xxxx xxxx
                                        </Typography>
                                        {source.last4}
                                    </Typography>
                                    {source.is_primary && (
                                        <StarIcon sx={{ fontSize: '1rem' }} />
                                    )}
                                </Box>
                            ))}
                        </Box>
                        <Box ml={2} mt={isEmpty(paymentSources) ? 0 : 2}>
                            <Button
                                disabled={!canModify}
                                color="secondary"
                                onClick={() =>
                                    handlePortal(
                                        CB_PORTAL_SECTIONS.ADD_PAYMENT_SOURCE,
                                    )
                                }
                                startIcon={<AddIcon fontSize="small" />}
                                variant="text"
                            >
                                {t('Add')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Card.Body>
            <Card.Actions>
                <Box p={1} textAlign="right" sx={{ width: '100%' }}>
                    <Button
                        onClick={() =>
                            handlePortal(CB_PORTAL_SECTIONS.BILLING_HISTORY)
                        }
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                        visible={canModify}
                    >
                        {t('Billing history')}
                    </Button>
                </Box>
            </Card.Actions>
        </Card>
    );
};

BillingInformation.propTypes = {
    billingInformation: PropTypes.object,
    canModify: PropTypes.bool,
    handlePortal: PropTypes.func,
    paymentSources: PropTypes.array,
    userSettings: PropTypes.object,
};

export default BillingInformation;
