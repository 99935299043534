import React from 'react';

import { useTheme, styled } from '@mui/material/styles';
import { Alert } from '@sunwisesoftware/sunwise-ui';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Card, Grid, Typography } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import { scroll } from 'common/utils/mixins';

import BranchOfficeTooltip from '../../projectCommercialOfferTable/components/BranchOfficeTooltip';
import DateTooltip from '../../projectCommercialOfferTable/components/DateTooltip';
import ShareMenuItem from '../../projectCommercialOfferTable/components/ShareMenuItem';
import * as actions from '../actions';
import { REPORT_STATUS_KEYS } from '../constants';
import * as selectors from '../selectors';

import ReportResolution from './ReportResolution';
import ReportStatus from './ReportStatus';

const ListContainer = styled(Box)`
    max-height: 400px;
    overflow-y: auto;
    padding: 0 8px;
    width: 100%;

    ${scroll.custom()}
`;

const ItemsList = ({
    canDelete,
    canModify,
    fetchData,
    isLoading,
    items,
    paginationData,
    prepareDelete,
    prepareUpdate,
    projectId,
    redirect,
    setModalFormOpenStatus,
    year,
}) => {
    const handleOnClickEdit = () => setModalFormOpenStatus(true);
    const { t } = useTranslation();
    const theme = useTheme();
    const sxCard = {
        backgroundColor: theme.palette.mode === 'dark' ? '#282b30' : '',
    };
    const sxBox = {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    };

    return (
        <ListContainer>
            <InfiniteScroll
                hasMore={!isLoading && paginationData?.hasMore}
                loader={
                    <div className="scroll-loader" key={0}>
                        {t('Loading').concat('...')}
                    </div>
                }
                loadMore={(nextPage) => {
                    if (isLoading) return;
                    fetchData({ page: nextPage, projectId, year });
                }}
                pageStart={1}
                threshold={100}
                useWindow={false}
            >
                {items.map((item) => {
                    const {
                        created_at,
                        id,
                        name,
                        status,
                        interactive_data = {},
                    } = item;
                    const { permalink = null } = interactive_data;

                    return (
                        <Card key={`report-item-${id}`} my={2} sx={sxCard}>
                            <Card.Body>
                                <Grid container spacing={0}>
                                    <Grid size={{ lg: 1, xs: 2 }}>
                                        <ReportStatus status={status} />
                                    </Grid>

                                    <Grid
                                        onClick={() => {
                                            if (
                                                [
                                                    REPORT_STATUS_KEYS.GENERATED,
                                                    REPORT_STATUS_KEYS.SENT,
                                                ].includes(status)
                                            )
                                                redirect(
                                                    `/report-review-pro/${id}`,
                                                );
                                            else redirect(`/report/${id}`);
                                        }}
                                        size={{ lg: 15, xs: 14 }}
                                        sx={sxBox}
                                    >
                                        <Typography
                                            fontWeight="bold"
                                            mb={1}
                                            variant="body2"
                                        >
                                            {name}
                                        </Typography>

                                        <Box
                                            alignItems="center"
                                            display="flex"
                                            gap={1}
                                        >
                                            <ReportResolution
                                                color="gray"
                                                placement="top"
                                                resolution={item?.resolution}
                                            />

                                            <DateTooltip
                                                createdAt={`${created_at} 00:00:00`}
                                            />

                                            <BranchOfficeTooltip
                                                branchOffice={
                                                    item?.branch_office
                                                }
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid size={2} textAlign="right">
                                        <DropdownCardMenu
                                            customAdditionalItem={
                                                <ShareMenuItem
                                                    isHidden={!permalink}
                                                    offerName={name}
                                                    permalink={permalink}
                                                />
                                            }
                                            items={[
                                                {
                                                    handleClick: () =>
                                                        prepareUpdate(
                                                            item,
                                                            handleOnClickEdit,
                                                        ),
                                                    label: t('Rename'),
                                                    visible: canModify,
                                                },
                                                {
                                                    handleClick: () =>
                                                        prepareDelete(
                                                            item,
                                                            projectId,
                                                        ),
                                                    label: t('Delete'),
                                                    visible: canDelete,
                                                },
                                            ]}
                                            visible={canModify || canDelete}
                                        />
                                    </Grid>

                                    <Grid mt={1} size={{ xs: 18 }}>
                                        {status ===
                                            REPORT_STATUS_KEYS.WAITING_FOR_LISA && (
                                            <Alert
                                                icon={<div />}
                                                severity="warning"
                                                sx={{ m: 0 }}
                                            >
                                                {t(
                                                    'This report is waiting for {{name}} to be generated',
                                                    { name: 'LISA' },
                                                )}
                                            </Alert>
                                        )}
                                    </Grid>
                                </Grid>
                            </Card.Body>
                        </Card>
                    );
                })}
            </InfiniteScroll>
        </ListContainer>
    );
};

ItemsList.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    fetchData: PropTypes.func,
    isLoading: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    prepareDelete: PropTypes.func,
    prepareUpdate: PropTypes.func,
    projectId: PropTypes.string,
    redirect: PropTypes.func,
    setModalFormOpenStatus: PropTypes.func,
    year: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    isLoading: selectors.getFetchDataIsLoading,
    paginationData: selectors.getFetchDataPagination,
});

const mapDispatchToProps = (dispatch) => ({
    fetchData: (params) => dispatch(actions.fetchData(params)),
    prepareDelete: (report, projectId) =>
        dispatch(actions.prepareDelete(report, projectId)),
    prepareUpdate: (item, successCallback) =>
        dispatch(actions.prepareUpdate(item, successCallback)),
    redirect: (link) => dispatch(push(link)),
    setModalFormOpenStatus: (value) =>
        dispatch(actions.setModalFormOpenStatus(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemsList);
