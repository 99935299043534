import React, { useEffect, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Box, Button, Grid, Typography } from 'sunwise-ui';

import { MONTHLY_FREQUENCY, YEARLY_FREQUENCY } from 'common/constants';
import { getSubscriptionsStatus } from 'common/utils/helpers';

import * as actions from '../actions';
import { getFilteredPricing } from '../helpers';

import BenefitsCards from './BenefitsCards';
import BenefitsCardsAfterSales from './BenefitsCardsAfterSales';
import BenefitsCardsBundle from './BenefitsCardsBundle';
import FrequencyOptions from './FrequencyOptions';
import PricingList from './PricingList';

const Plans = ({
    handleChargebeeCheckout,
    handleClickBack,
    isFetching,
    pricingData,
    selectedProductId,
    selectedTheme,
    subscription,
    subscriptions,
}) => {
    const { t } = useTranslation();
    const [cbInstance, setCbInstance] = useState(null);
    const [pricings, setPricings] = useState([]);
    const [billingFrequency, setBillingFrequency] = useState(YEARLY_FREQUENCY);
    const isBundle = selectedProductId === 'bundle';
    const billingPeriod = get(subscription, 'billing_period_unit.key', null);

    const { allSubscriptionsAreCanceled } =
        getSubscriptionsStatus(subscriptions);

    useEffect(() => {
        if (import.meta.env.VITE_CHARGEBEE_KEY) {
            setCbInstance(
                window.Chargebee.init({
                    site: import.meta.env.VITE_CHARGEBEE_KEY,
                }),
            );
        }
    }, []);

    useEffect(() => {
        if (allSubscriptionsAreCanceled)
            if (billingPeriod === 'year') setBillingFrequency(YEARLY_FREQUENCY);
            else setBillingFrequency(MONTHLY_FREQUENCY);
    }, [billingPeriod]);

    useEffect(() => {
        const filteredPricing = getFilteredPricing(
            billingFrequency,
            pricingData,
        );
        setPricings(filteredPricing);
    }, [billingFrequency, pricingData]);

    const handleCheckout = (item_price_id) =>
        handleChargebeeCheckout(cbInstance, item_price_id);

    const renderText = () => {
        switch (selectedProductId) {
            case 'aftersales':
                return 'AfterSales';
            case 'bundle':
                return 'Bundle';
            default:
                return 'Sales';
        }
    };

    const renderBenefits = () => {
        switch (selectedProductId) {
            case 'aftersales':
                return (
                    <BenefitsCardsAfterSales selectedTheme={selectedTheme} />
                );
            case 'bundle':
                return <BenefitsCardsBundle selectedTheme={selectedTheme} />;
            default:
                return <BenefitsCards selectedTheme={selectedTheme} />;
        }
    };

    return (
        <Grid container justifyContent="center" my={2}>
            <Grid size={{ lg: 12, xs: 18 }}>
                <Button
                    onClick={() => handleClickBack()}
                    startIcon={<ArrowBackIcon fontSize="small" />}
                    variant="text"
                >
                    {t('Back')}
                </Button>
                <Box
                    alignItems="center"
                    display="flex"
                    gap={1}
                    justifyContent="center"
                    sx={{ svg: { height: 30, width: 30 } }}
                >
                    <Typography variant="h1">Sunwise</Typography>
                    <Typography
                        component="span"
                        sx={{ color: 'primary.main' }}
                        variant="h1"
                    >
                        {renderText()}
                    </Typography>
                </Box>
                <Typography mt={1} textAlign="center" variant="h3">
                    {t('Plans and pricing')}
                </Typography>

                <Typography
                    fontWeight={500}
                    my={2}
                    textAlign="center"
                    variant="body1"
                >
                    {t(
                        'At Sunwise we offer you different plan options to grow your business',
                    )}
                    :
                </Typography>
                <FrequencyOptions
                    billingFrequency={billingFrequency}
                    isHidden={isBundle}
                    setBillingFrequency={setBillingFrequency}
                />
            </Grid>
            <Grid size={{ lg: 15, xs: 18 }}>
                <PricingList
                    handleCheckout={handleCheckout}
                    isLoading={isFetching}
                    noSubscriptions={isEmpty(subscriptions)}
                    pricings={pricings}
                    selectedProductId={selectedProductId}
                    subscription={subscription}
                />
            </Grid>
            <Grid size={{ lg: 12, xs: 18 }}>{renderBenefits()}</Grid>
        </Grid>
    );
};

const mapDispatchToProps = (dispatch) => ({
    handleChargebeeCheckout: (cbInstance, itemPriceId) =>
        dispatch(actions.handleChargebeeCheckout(cbInstance, itemPriceId)),
});

Plans.propTypes = {
    handleChargebeeCheckout: PropTypes.func,
    handleClickBack: PropTypes.func,
    isFetching: PropTypes.bool,
    pricingData: PropTypes.object,
    selectedProductId: PropTypes.string,
    selectedTheme: PropTypes.string,
    subscription: PropTypes.object,
    subscriptions: PropTypes.array,
};

export default connect(null, mapDispatchToProps)(Plans);
