import React from 'react';

import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableRow, TableCell, Typography } from 'sunwise-ui';

const NextSubsTable = ({ subscription }) => {
    const { t } = useTranslation();

    const { amount, billing_period_unit, credits, product_type, total_amount } =
        subscription || {};

    const additionalCredits = get(credits, 'pending_payment_credits', 0);
    const pendingPaymentCreditsAmount = get(
        credits,
        'pending_payment_credits_amount',
        0,
    );
    const billingPeriod = get(billing_period_unit, 'key', null);

    // For bundle case, extract proposals and reports
    const proposals = get(credits, 'proposals', {});
    const reports = get(credits, 'reports', {});
    const additionalCreditsBundleProposals = get(
        proposals,
        'pending_payment_credits',
        0,
    );
    const pendingPaymentCreditsAmountBundleProposals = get(
        proposals,
        'pending_payment_credits_amount',
        0,
    );
    const additionalCreditsBundleReports = get(
        reports,
        'pending_payment_credits',
        0,
    );
    const pendingPaymentCreditsAmountBundleReports = get(
        reports,
        'pending_payment_credits_amount',
        0,
    );

    switch (product_type) {
        case 'aftersales':
            return (
                <Table sx={{ mt: 1 }}>
                    <TableBody
                        sx={{
                            '& .MuiTableCell-root': {
                                paddingTop: 1,
                                paddingBottom: 1,
                            },
                        }}
                    >
                        <TableRow>
                            <TableCell>
                                <Typography variant="body2">
                                    {subscription?.plan_name}{' '}
                                    {billingPeriod === 'year'
                                        ? t('Annual')
                                        : t('Monthly')}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">{amount}</TableCell>
                        </TableRow>
                        {additionalCredits > 0 && (
                            <TableRow>
                                <TableCell>
                                    {additionalCredits}{' '}
                                    {t('Additional reports')}
                                </TableCell>
                                <TableCell align="right">
                                    {pendingPaymentCreditsAmount}
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            <TableCell
                                colSpan={2}
                                align="right"
                                sx={{
                                    color: 'primary.main',
                                    fontWeight: 700,
                                }}
                            >
                                {`${t('Total')}: ${total_amount}`}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            );
        case 'bundle':
            return (
                <Table sx={{ mt: 1 }}>
                    <TableBody
                        sx={{
                            '& .MuiTableCell-root': {
                                paddingTop: 1,
                                paddingBottom: 1,
                            },
                        }}
                    >
                        <TableRow>
                            <TableCell>
                                <Typography variant="body2">
                                    {subscription?.plan_name}{' '}
                                    {billingPeriod === 'year'
                                        ? t('Annual')
                                        : t('Monthly')}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">{amount}</TableCell>
                        </TableRow>
                        {additionalCreditsBundleProposals > 0 && (
                            <TableRow>
                                <TableCell>
                                    {additionalCreditsBundleProposals}{' '}
                                    {t('Additional proposals')}
                                </TableCell>
                                <TableCell align="right">
                                    {pendingPaymentCreditsAmountBundleProposals}
                                </TableCell>
                            </TableRow>
                        )}
                        {additionalCreditsBundleReports > 0 && (
                            <TableRow>
                                <TableCell>
                                    {additionalCreditsBundleReports}{' '}
                                    {t('Additional reports')}
                                </TableCell>
                                <TableCell align="right">
                                    {pendingPaymentCreditsAmountBundleReports}
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            <TableCell
                                colSpan={2}
                                align="right"
                                sx={{
                                    color: 'primary.main',
                                    fontWeight: 700,
                                }}
                            >
                                {`${t('Total')}: ${total_amount}`}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            );
        default:
            return (
                <Table sx={{ mt: 1 }}>
                    <TableBody
                        sx={{
                            '& .MuiTableCell-root': {
                                paddingTop: 1,
                                paddingBottom: 1,
                                fontSize: '14px',
                            },
                        }}
                    >
                        <TableRow>
                            <TableCell>
                                <Typography variant="body2">
                                    {subscription?.plan_name}{' '}
                                    {billingPeriod === 'year'
                                        ? t('Annual')
                                        : t('Monthly')}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">{amount}</TableCell>
                        </TableRow>
                        {additionalCredits > 0 && (
                            <TableRow>
                                <TableCell>
                                    {additionalCredits}{' '}
                                    {t('Additional proposals')}
                                </TableCell>
                                <TableCell align="right">
                                    {pendingPaymentCreditsAmount}
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            <TableCell
                                colSpan={2}
                                align="right"
                                sx={{
                                    color: 'primary.main',
                                    fontWeight: 700,
                                }}
                            >
                                {`${t('Total')}: ${total_amount}`}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            );
    }
};

NextSubsTable.propTypes = {
    subscription: PropTypes.object, // Updated to object since it's a single subscription
};

export default NextSubsTable;
