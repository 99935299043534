import React from 'react';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Box, Typography } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';

import { getReportPeriods } from '../helpers';

const Periods = ({ data, localeDateFormat }) => {
    if (!data?.length) return null;

    const periods = getReportPeriods(data, localeDateFormat);

    return periods.map((date, i) => (
        <Box
            alignItems="flex-start"
            display="flex"
            gap={1}
            key={date + i}
            my={0.5}
        >
            <CalendarTodayIcon color="primary" fontSize="small" />
            <Typography variant="body2">{date}</Typography>
        </Box>
    ));
};

Periods.propTypes = {
    data: PropTypes.array,
    localeDateFormat: PropTypes.string,
};

export default Periods;
