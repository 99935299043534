import React from 'react';

import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Typography,
} from 'sunwise-ui';

const CostingSelector = ({
    costingType,
    disabled,
    proposalId,
    saveCostingType,
    setCostingType,
}) => {
    const { t } = useTranslation();
    return (
        <Grid size={{ lg: 5, md: 9, xs: 18 }}>
            <Box
                alignItems="center"
                display="flex"
                gap={2}
                sx={{
                    justifyContent: { md: 'end', xs: 'start' },
                    width: '100%',
                }}
            >
                <IconButton
                    href="https://academy.sunwise.mx/es/articles/6126097-ajuste-de-precio-en-una-propuesta"
                    target="_blank"
                >
                    <InfoIcon fontSize="small" />
                </IconButton>

                <Typography noWrap variant="body2">
                    {t('Costing type')}
                </Typography>

                <FormControl size="small">
                    <Select
                        disabled={disabled}
                        onChange={(e) => {
                            saveCostingType(
                                proposalId,
                                {
                                    costing_type: e?.target?.value,
                                },
                                () => setCostingType(e?.target?.value),
                            );
                        }}
                        value={costingType}
                        variant="standard"
                    >
                        <MenuItem value="by_product">
                            {t('Per product')}
                        </MenuItem>
                        <MenuItem value="by_watt">
                            {t('Per Watt and kWh')}
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </Grid>
    );
};

CostingSelector.propTypes = {
    costingType: PropTypes.string,
    disabled: PropTypes.bool,
    proposalId: PropTypes.string,
    saveCostingType: PropTypes.func,
    setCostingType: PropTypes.func,
};

export default CostingSelector;
