import get from 'lodash/get';
import { number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

import {
    getTotalWithDealerFee,
    percentageToValue,
    valueToPercentage,
} from './helpers';

export default (_, props) => {
    const {
        getMinValueText,
        getMaxValueText,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();
    const { contact, parameters, total } = props;
    const { company_name, email } = contact;
    const isPercentage = get(parameters, 'is_percentage', false);
    const hitch = get(parameters, 'hitch', false);
    const totalWithDealerFee = getTotalWithDealerFee(
        parameters?.dealer_fee,
        total,
    );
    const minHitch = percentageToValue(isPercentage, hitch, totalWithDealerFee);
    const minHitchPercentage = valueToPercentage(
        isPercentage,
        hitch,
        totalWithDealerFee,
    );
    return object().shape({
        types_business_company: string().when('step', {
            is: 1,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        financier_product: string().when('step', {
            is: 1,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        term: string().when(['step', 'is_serfimex'], {
            is: (step, isSerfimex) => step === 2 && isSerfimex === false,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        hitch_percentage: number().when(['step', 'is_percentage'], {
            is: (step, is_percentage) => step === 2 && is_percentage === 'true',
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(
                        minHitchPercentage,
                        getMinValueText(minHitchPercentage),
                    )
                    .max(100, getMaxValueText(100))
                    .nullable(),
            otherwise: () => number().nullable(),
        }),
        hitch: number().when(['step', 'is_percentage'], {
            is: (step, is_percentage) =>
                step === 2 && is_percentage === 'false',
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(minHitch, getMinValueText(minHitch))
                    .max(
                        totalWithDealerFee,
                        getMaxValueText(totalWithDealerFee),
                    )
                    .nullable(),
            otherwise: () => number().nullable(),
        }),
        email: string().when(['step', 'is_serfimex'], {
            is: (step, isSerfimex) =>
                step === 1 && isSerfimex === true && !email,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        company_name: string().when(
            ['step', 'is_serfimex', 'types_business_company'],
            {
                is: (step, isSerfimex, typesBusiness) =>
                    step === 1 &&
                    isSerfimex &&
                    typesBusiness === 'company' &&
                    !company_name,
                then: () => string().required(REQUIRED_TEXT).nullable(),
                otherwise: () => string().nullable(),
            },
        ),
    });
};
