import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ChartJs from 'common/components/ChartJs';
import {
    handleProposalEnergyChartConfig,
    buildChartJsProps,
} from 'common/utils/helpersChart';

import * as proposalGeneratorOneColumnSelectors from '../../proposalGeneratorOneColumn/selectors';

const DailyChart = ({
    consumptionProjection,
    currencyLocale,
    solarSimulationData,
}) => {
    const [chartConfig, setChartConfig] = useState({
        data: { datasets: [], labels: [] },
        options: {},
    });

    const {
        consumptionHistory = [],
        firstDay,
        realIndex = {},
        zeroExport,
    } = consumptionProjection || {};

    useEffect(() => {
        if (!isEmpty(solarSimulationData) && !isEmpty(consumptionHistory))
            handleChartChange({
                days: [firstDay],
                option: 3,
                period: 0,
                type: 'area',
                weeks: [0],
                year: 0,
            });
    }, [firstDay, consumptionHistory, solarSimulationData]);

    const handleChartChange = (configOptions) => {
        const newChartConfig = handleProposalEnergyChartConfig({
            configOptions,
            consumptionHistory,
            countryCurrencyLocale: currencyLocale,
            isSelfConsumption: true,
            realIndex,
            solarSimulationData,
            zeroExport,
        });

        const newDataChartConfigEnergy = buildChartJsProps({
            chartControlsConfig: configOptions,
            chartId: 'chartEnergy',
            countryCurrencyLocale: currencyLocale,
            currentChartConfig: newChartConfig,
            isSelfConsumption: true,
        });

        setChartConfig(newDataChartConfigEnergy);
    };

    return (
        <ChartJs
            data={chartConfig.data}
            height="330px"
            options={chartConfig.options}
            type={chartConfig.options?.type || 'line'}
        />
    );
};

DailyChart.propTypes = {
    consumptionProjection: PropTypes.object,
    currencyLocale: PropTypes.string,
    solarSimulationData: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    consumptionProjection:
        proposalGeneratorOneColumnSelectors.getConsumptionProjectionData,
    solarSimulationData:
        proposalGeneratorOneColumnSelectors.getSolarSimulationData,
});

export default connect(mapStateToProps)(DailyChart);
