import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Typography } from 'sunwise-ui';

import UsageSubscriptionDetails from './UsageSubscriptionDetails';

const UsageCard = ({ subscriptions }) => {
    const { t } = useTranslation();

    return (
        <Card>
            <Card.Header>
                <Typography variant="body1" fontWeight="bold">
                    {t('Usage')}
                </Typography>
            </Card.Header>
            <Card.Body sx={{ minHeight: '650px' }}>
                {subscriptions.map((sub, index) => (
                    <UsageSubscriptionDetails
                        key={`${sub?.item_price_id}-${index}`}
                        subscription={sub}
                        index={index}
                    />
                ))}
            </Card.Body>
        </Card>
    );
};

UsageCard.propTypes = {
    subscriptions: PropTypes.array,
};

export default UsageCard;
