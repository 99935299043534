import { push } from 'connected-react-router';
import get from 'lodash/get';

import { simulateCredit } from 'common/api/v1/sunpay';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import fetchUserSettings from '../../profile/actions/fetchProfileData';
import {
    SIMULATE_CREDIT,
    SIMULATE_CREDIT_FAILURE,
    SIMULATE_CREDIT_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorAddAllianceActions } from '../reducer';

import fetchFinancials from './fetchFinancials';
import setIsOpenSimulateModal from './setIsOpenSimulateModal';

export default ({ allianceId, proposalId }) =>
    (dispatch) => {
        dispatch(proposalGeneratorAddAllianceActions[SIMULATE_CREDIT]());

        simulateCredit({
            financier_id: allianceId,
            proposal_id: proposalId,
        })
            .then((response) => {
                const data = get(response, 'data', {});
                const duplicatedProposalId = get(data, 'id', null);
                dispatch(setIsOpenSimulateModal(false));
                dispatch(
                    proposalGeneratorAddAllianceActions[
                        SIMULATE_CREDIT_SUCCESS
                    ](response.data),
                );
                dispatch(fetchFinancials());
                dispatch(fetchUserSettings());
                if (duplicatedProposalId)
                    dispatch(
                        push(
                            `/proposal/${duplicatedProposalId}?alliance_id=${allianceId}`,
                        ),
                    );
            })
            .catch((error) => {
                dispatch(
                    proposalGeneratorAddAllianceActions[
                        SIMULATE_CREDIT_FAILURE
                    ](error?.response?.data?.errors),
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
