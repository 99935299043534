import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getTermInitialValues = createSelector(
    getModel,
    (model) => model.termInitialValues,
);

/* FINANCIALS */
export const getFinancialsModel = createSelector(
    getModel,
    (model) => model.financials,
);

export const getFinancialsData = createSelector(
    getFinancialsModel,
    (model) => model?.data || [],
);

export const getIsFetchingFinancials = createSelector(
    getFinancialsModel,
    (model) => model.isFetching,
);

export const getFinancialsErrors = createSelector(
    getFinancialsModel,
    (model) => model.errors,
);

/* FINANCIERS */
export const getFinanciersModel = createSelector(
    getModel,
    (model) => model.financiers,
);

export const getFinanciersData = createSelector(
    getFinanciersModel,
    (model) => model?.data || [],
);

export const getIsFetchingFinanciers = createSelector(
    getFinanciersModel,
    (model) => model.isFetching,
);

export const getFinanciersErrors = createSelector(
    getFinanciersModel,
    (model) => model.errors,
);

/* REQUIREMENTS */
export const getRequirementsModel = createSelector(
    getModel,
    (model) => model.requirements,
);

export const getRequirementsData = createSelector(
    getRequirementsModel,
    (model) => model.data || { content: '' },
);

export const getRequirementsErrors = createSelector(
    getRequirementsModel,
    (model) => model.errors,
);

export const getRequirementsIsFetching = createSelector(
    getRequirementsModel,
    (model) => model.isFetching,
);

// MODAL

export const getIsOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal,
);

export const getIsOpenSimulateModal = createSelector(
    getModel,
    (model) => model.isOpenSimulateModal,
);

/* SAVE ALLIANCE */
export const getSaveAlliance = createSelector(
    getModel,
    (model) => model.saveAlliance,
);

export const getIsSavingAlliance = createSelector(
    getSaveAlliance,
    (model) => model.isFetching,
);

export const getSaveAllianceErrors = createSelector(
    getSaveAlliance,
    (model) => model.errors,
);

/* SIMULATE CREDIT  */
export const getSimulateCredit = createSelector(
    getModel,
    (model) => model.simulateCredit,
);

export const getIsSimulatingCredit = createSelector(
    getSimulateCredit,
    (model) => model.isFetching,
);

export const getSimulateCreditErrors = createSelector(
    getSimulateCredit,
    (model) => model.errors,
);
