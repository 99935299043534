import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput } from 'react-mentions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button } from 'sunwise-ui';

import ShowErrors from 'common/components/ShowErrors';

import * as profileSelectors from '../../profile/selectors';
import * as actions from '../actions';
import { getUsersOptions } from '../helpers';

import mentionsInputStyle from './mentionsInputStyle';
import mentionStyle from './mentionStyle';

const CommentForm = ({
    commentId,
    content = '',
    defaultValues,
    editionMode,
    errors,
    isSaving,
    save,
    trackingId,
    users,
    setEditionMode,
}) => {
    const { t } = useTranslation();
    const { handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: { id: commentId, comment: content },
    });

    useEffect(() => reset(defaultValues), [defaultValues]);

    const comment = watch('comment');
    const style = {
        ...mentionsInputStyle,
        control: {
            backgroundColor: '#fff',
            borderRadius: '12px',
            height: '92px',
        },
    };
    const usersOptions = getUsersOptions(users || []);

    const onChangeMention = (e) => setValue('comment', e.target.value);

    const onSubmit = (data) =>
        save({ ...data, contact_tracking: trackingId }, () => {
            setValue('comment', '');
            if (setEditionMode) setEditionMode(false);
        });

    return (
        <Box sx={{ mt: 2 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ mb: 2 }}>
                    <MentionsInput
                        onChange={onChangeMention}
                        placeholder={t('Add comment')}
                        style={style}
                        value={comment}
                    >
                        <Mention
                            data={usersOptions}
                            style={mentionStyle}
                            trigger="@"
                        />
                    </MentionsInput>
                </Box>

                <ShowErrors errors={errors} />

                <Box
                    sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}
                >
                    {editionMode && (
                        <Button
                            color="secondary"
                            onClick={() => setEditionMode(false)}
                            variant="outlined"
                        >
                            {t('Cancel')}
                        </Button>
                    )}

                    <Button
                        color="secondary"
                        disabled={isSaving || comment.length === 0}
                        type="submit"
                        variant="outlined"
                    >
                        {t('Save')}
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    agentId: profileSelectors.getUserId,
});

const mapDispatchToProps = (dispatch) => ({
    save: (values, callback) => dispatch(actions.saveComment(values, callback)),
});

CommentForm.propTypes = {
    commentId: PropTypes.string,
    content: PropTypes.string,
    defaultValues: PropTypes.object,
    editionMode: PropTypes.bool,
    errors: PropTypes.array,
    isSaving: PropTypes.bool,
    save: PropTypes.func,
    setEditionMode: PropTypes.func,
    trackingId: PropTypes.string,
    users: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentForm);
