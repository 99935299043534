import * as afterSalesSettingsSelectors from '../../afterSalesSettings/selectors';
import { SET_INTEGRATION_ENERGY_DATA } from '../actionTypes';
import { getFieldsFromIntegration } from '../helpers';
import { actions } from '../reducer';

import initializeForm from './initializeForm';

export default ({
        data,
        file,
        finalDate,
        getValues,
        initialDate,
        uploadOrigin,
    }) =>
    (dispatch, getState) => {
        if (!data || !getValues) return;

        const state = getState();
        const rateConfiguration =
            afterSalesSettingsSelectors.getScheduleRateConfiguration(state);
        const ratesDictionary =
            afterSalesSettingsSelectors.getRatesDictionary(state);
        const ratesNameIdDictionary =
            afterSalesSettingsSelectors.getRatesNameIdDictionary(state);

        const formValues = getValues();

        const rateId = ratesNameIdDictionary[data?.rate] || formValues?.rate;

        const { fields, missingFields, hasMissingFields, keys } =
            getFieldsFromIntegration({
                contractedDemandData: data?.contracted_demand || [],
                demandData: data?.values?.demand,
                energyData: data?.values?.energy,
                finalDate: finalDate,
                generationData: data?.values?.solar_generation,
                initialDate: initialDate,
                rate: ratesDictionary?.[rateId],
                rateConfiguration,
            });

        const { energy, total, ...restFields } = fields;
        const { energy: energyKeys, ...restKeys } = keys;

        const newValues = {
            ...formValues,
            file: file || formValues?.file,
            final_date: finalDate,
            incomplete: Boolean(data?.values?.incomplete),
            initial_date: initialDate,
            last_date: data?.values?.date,
            power_factor: data?.values?.fp || formValues?.power_factor,
            rate: rateId,
            upload_origin: uploadOrigin ?? null,
            ...restFields,
            fields_keys: restKeys,
        };

        dispatch(
            actions[SET_INTEGRATION_ENERGY_DATA]({
                energy,
                energyKeys,
                hasMissingFields,
                missingFields,
                total,
            }),
        );

        dispatch(initializeForm(newValues));
    };
