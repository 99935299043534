import React, { useState, useEffect } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Alert, Button, Grid, Typography } from 'sunwise-ui';

import { ShowErrors } from 'common/components';
import {
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import { KAM_UID, SERFIMEX_UID } from 'common/constants';
import yupResolver from 'common/utils/yupResolver';

import * as contactSelectors from '../../../contactForm/selectors';
import * as actions from '../../actions';
import editFinancingAllianceValidate from '../../editFinancingAllianceValidate';
import {
    getFinancierProducts,
    getFinancierProductsToSelect,
    getTypeBusinessOptions, // Nuevo
    handleOnChangeTermOrHitch,
    handleOnChangeFinancierProduct,
    handleOnChangeRegimen,
    initializeFormWithProduct, // Nuevo
    initializeFormWithFinancialProduct, // Nuevo
    updateErrorsAndAlerts,
} from '../../helpers';
import * as selectors from '../../selectors';

import HitchField from './HitchField';

const CIBancoEditForm = ({
    alliance,
    allianceProducts,
    currencyIso,
    currencySymbol,
    financialProduct,
    initializeCibanco,
    initialValues,
    isLocked,
    isSaving,
    proposalId,
    rateComplements,
    saveCiBanco,
    total,
}) => {
    const { t } = useTranslation();
    const [productSelected, setProductSelected] = useState({});
    const [errorList, setErrorList] = useState([]);
    const [infoList, setInfoList] = useState([]);

    const { control, handleSubmit, reset, setValue, watch } = useForm({
        context: {
            parameters: get(
                productSelected,
                'financier_product_parameters',
                {},
            ),
            total,
        },
        defaultValues: initialValues,
        resolver: yupResolver(editFinancingAllianceValidate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const formValues = watch();
    const typesBusinessCompany = get(formValues, 'types_business_company', '');
    const parameters = get(productSelected, 'financier_product_parameters', {});
    const financierProduct = get(formValues, 'financier_product', ''); // Para errores y alertas
    const alerts = get(productSelected, 'alerts', {});
    const errors = get(productSelected, 'errors', []);
    const financierId = get(alliance, 'financier.id', null);
    const financierProducts = getFinancierProducts(
        allianceProducts,
        typesBusinessCompany,
    );
    const annualIncrease = get(parameters, 'annual_increase', 0);
    const increasePercentage = get(rateComplements, 'increase_percentage', 0);
    const isKam = financierId === KAM_UID;
    const isSerfimex = financierId === SERFIMEX_UID;

    // useEffect para productSelected
    useEffect(() => {
        initializeFormWithProduct(
            productSelected,
            alliance.id,
            isSerfimex,
            total,
            initializeCibanco,
        );
    }, [productSelected, alliance.id, isSerfimex, total, initializeCibanco]);

    // useEffect para financialProduct
    useEffect(() => {
        initializeFormWithFinancialProduct(
            financialProduct,
            allianceProducts,
            alliance.id,
            isSerfimex,
            total,
            initializeCibanco,
        );
    }, [
        financialProduct,
        allianceProducts,
        alliance.id,
        isSerfimex,
        total,
        initializeCibanco,
    ]);

    // useEffect para errores y alertas
    useEffect(() => {
        updateErrorsAndAlerts(
            alerts,
            annualIncrease,
            errors,
            increasePercentage,
            isKam,
            setErrorList,
            setInfoList,
        );
    }, [
        alerts,
        annualIncrease,
        errors,
        increasePercentage,
        isKam,
        typesBusinessCompany, // Reinicia al cambiar régimen
        financierProduct, // Reinicia al cambiar producto
        setErrorList,
        setInfoList,
    ]);

    const handleOnClickSave = (values) =>
        saveCiBanco(financialProduct?.id, proposalId, values);

    const resetErrors = () => {
        setErrorList([]);
        setInfoList([]);
    };

    return (
        <form>
            <Grid container>
                <Grid size={{ xs: 'grow' }}>
                    <ReactHookFormSelect
                        control={control}
                        disabled={true}
                        label={t('Type of tax system')}
                        name="types_business_company"
                        onChange={({ target: { value } }) =>
                            handleOnChangeRegimen({
                                allianceProducts,
                                resetErrors,
                                setProductSelected,
                                setValue,
                                value,
                            })
                        }
                        options={getTypeBusinessOptions(allianceProducts)} // Actualizado
                    />
                    <ReactHookFormSelect
                        control={control}
                        disabled={true}
                        label={t('Product')}
                        name="financier_product"
                        onChange={({ target: { value } }) =>
                            handleOnChangeFinancierProduct({
                                financierProducts,
                                resetErrors,
                                setProductSelected,
                                setValue,
                                value,
                            })
                        }
                        options={[
                            {
                                label: t('Select a product'),
                                value: '',
                                disabled: true,
                            },
                            ...getFinancierProductsToSelect(financierProducts),
                        ]}
                        sxFormControl={{ mt: 1 }}
                    />
                </Grid>
            </Grid>

            <Grid container mt={1}>
                <Grid hidden={isSerfimex} size={{ lg: 9, xs: 18 }}>
                    <ReactHookFormSelect
                        control={control}
                        disabled={isLocked}
                        label={t('Term')}
                        name="term"
                        onChange={() => {
                            handleOnChangeTermOrHitch({
                                parameters,
                                setValue,
                                term: formValues.term,
                                hitch: formValues.hitch_percentage,
                            });
                        }}
                        options={[
                            {
                                label: t('Select a term'),
                                value: '',
                                disabled: true,
                            },
                            ...formValues.termsToSelect,
                        ]}
                        sx={{ mb: '0 !important' }}
                    />
                </Grid>
                {isKam && (
                    <Grid size={{ lg: 9, xs: 18 }}>
                        <ReactHookFormIntlNumberInput
                            allowNegativeValue={false}
                            append="%"
                            control={control}
                            disabled
                            fullWidth
                            label={t('Annual increase')}
                            min={0}
                            name="annual_increase"
                        />
                    </Grid>
                )}
                <Grid hidden={isKam} size={{ lg: isSerfimex ? 18 : 9, xs: 18 }}>
                    <HitchField
                        control={control}
                        currencyIso={currencyIso}
                        currencySymbol={currencySymbol}
                        formValues={formValues}
                        isLocked={isLocked}
                        isSerfimex={isSerfimex}
                        parameters={parameters}
                        setValue={setValue}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid size={{ xs: 'grow' }}>
                    <ShowErrors errors={errorList} />
                    {!isEmpty(infoList) && (
                        <Alert severity="info">
                            {infoList.map((info, index) => (
                                <Typography variant="body2" key={index}>
                                    * {info}
                                </Typography>
                            ))}
                        </Alert>
                    )}
                </Grid>
            </Grid>

            <Grid container mt={1}>
                <Grid
                    size="grow"
                    sx={{ textAlign: { xs: 'center', md: 'right' } }}
                >
                    <Button
                        disabled={
                            isLocked ||
                            errors.length > 0 ||
                            isEmpty(productSelected)
                        }
                        endIcon={<ArrowForwardIcon />}
                        onClick={handleSubmit(handleOnClickSave)}
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                        type="button"
                    >
                        {isSaving ? t('Saving').concat('...') : t('Save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValuesCiBanco,
    isSavingContact: contactSelectors.getIsSavingContact,
});

const mapDispatchToProps = (dispatch) => ({
    initializeCibanco: (values) =>
        dispatch(actions.initialValuesCiBanco(values)),
    saveCiBanco: (id, proposalId, values) =>
        dispatch(actions.updateCiBanco(id, proposalId, values)),
});

CIBancoEditForm.propTypes = {
    alliance: PropTypes.object,
    allianceProducts: PropTypes.array,
    currencyIso: PropTypes.string,
    currencySymbol: PropTypes.string,
    financialProduct: PropTypes.object,
    initializeCibanco: PropTypes.func,
    initialValues: PropTypes.object,
    isLocked: PropTypes.bool,
    isSaving: PropTypes.bool,
    proposalId: PropTypes.string,
    rateComplements: PropTypes.object,
    saveCiBanco: PropTypes.func,
    total: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(CIBancoEditForm);
