import React from 'react';

import PropTypes from 'prop-types';

import DrawerContent from './DrawerContent';
import StyledDrawer from './StyledDrawer';

const SideBar = ({
    drawerSize,
    handleDrawerToggle,
    isFetchingUserSettings,
    match,
    mobileOpen,
    multiBranchMode,
    oppositeCurrency,
    profileValues,
    redirect,
    selectedTheme,
    setTheme,
    toggleDrawerSize,
    userPreferences,
}) => {
    const container =
        window !== undefined ? () => window.document.body : undefined;
    const drawerContent = (
        <DrawerContent
            {...{
                container,
                drawerSize,
                handleDrawerToggle,
                isFetchingUserSettings,
                match,
                mobileOpen,
                multiBranchMode,
                oppositeCurrency,
                profileValues,
                redirect,
                selectedTheme,
                setTheme,
                toggleDrawerSize,
                userPreferences,
            }}
        />
    );
    return (
        <>
            <StyledDrawer
                drawersize={drawerSize}
                sx={{ display: { md: 'block', xs: 'none' } }}
                variant="permanent"
            >
                {drawerContent}
            </StyledDrawer>

            <StyledDrawer
                ModalProps={{ keepMounted: true }}
                container={container}
                drawersize={drawerSize}
                onClose={handleDrawerToggle}
                open={mobileOpen}
                sx={{
                    display: { md: 'none', xs: 'block' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: '100%',
                    },
                }}
                variant="temporary"
            >
                {drawerContent}
            </StyledDrawer>
        </>
    );
};

SideBar.propTypes = {
    drawerSize: PropTypes.string,
    handleDrawerToggle: PropTypes.func,
    isFetchingUserSettings: PropTypes.bool,
    match: PropTypes.object,
    mobileOpen: PropTypes.bool,
    multiBranchMode: PropTypes.bool,
    oppositeCurrency: PropTypes.object,
    profileValues: PropTypes.object,
    redirect: PropTypes.func,
    selectedTheme: PropTypes.string,
    setTheme: PropTypes.func,
    toggleDrawerSize: PropTypes.func,
    userPreferences: PropTypes.object,
};

export default SideBar;
