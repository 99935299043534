import React, { useContext, useEffect, useState } from 'react';

import { push } from 'connected-react-router';
import { get, isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import { LoadingContext } from 'common/utils/contexts';
import {
    getSubscriptionStatus,
    getSubscriptionsStatus,
} from 'common/utils/helpers';

import * as profileActions from '../profile/actions';
import * as profileSelectors from '../profile/selectors';

import * as actions from './actions';
import { Header, Plans, Products } from './components';
import * as selectors from './selectors';

const Container = ({
    fetchPricing,
    fetchUserSettings,
    history,
    isFetchingPricing,
    isFetchingUserSettings,
    isSavingSubscription,
    location,
    pricingData,
    redirect,
    selectedTheme,
    subscription,
    subscriptions,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const productType = get(subscription, 'product_type', null);

    useEffect(() => {
        fetchPricing();
        fetchUserSettings();
    }, []);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isSavingSubscription)
            loadingContext.openLoading(t('Saving').concat('...'));
        else loadingContext.closeLoading();
    }, [isSavingSubscription]);

    useEffect(() => {
        const { hasActive, hasPaymentDue, isMultipleSubscription } =
            getSubscriptionsStatus(subscriptions);
        const { isActive, isPaymentDue } = getSubscriptionStatus(subscription);
        if (
            (isMultipleSubscription && hasPaymentDue) ||
            (isActive && isPaymentDue) ||
            hasActive
        ) {
            redirect('/profile/subscription');
            return false;
        }
    }, [subscriptions]);

    useEffect(() => {
        if (productType) {
            setSelectedProductId(productType);
            history.push(`?product=${productType}`);
        }
    }, [productType]);

    const handleClickBack = () => {
        setSelectedProductId(null);
        const params = new URLSearchParams(location.search);
        params.delete('product');
        history.push({ search: params.toString() });
    };

    const renderContent = () => {
        if (isNull(selectedProductId))
            return (
                <Products
                    isLoading={isFetchingPricing || isFetchingUserSettings}
                    selectedProductId={selectedProductId}
                    setSelectedProductId={setSelectedProductId}
                />
            );
        return (
            <Plans
                handleClickBack={handleClickBack}
                isFetching={isFetchingPricing || isFetchingUserSettings}
                pricingData={pricingData[selectedProductId]}
                selectedProductId={selectedProductId}
                selectedTheme={selectedTheme}
                subscription={subscription}
                subscriptions={subscriptions}
            />
        );
    };

    return (
        <>
            <Header selectedTheme={selectedTheme} />
            <Box p={3}>{renderContent()}</Box>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    isFetchingPricing: selectors.getIsFetchingPricing,
    isFetchingUserSettings: profileSelectors.getIsFetching,
    isSavingSubscription: selectors.getIsSavingSubscription,
    pricingData: selectors.getPricingData,
    selectedTheme: profileSelectors.getTheme,
    subscription: profileSelectors.getMainSubscription,
    subscriptions: profileSelectors.getSubscriptions,
});

const mapDispatchToProps = (dispatch) => ({
    fetchPricing: () => dispatch(actions.fetchPricing()),
    fetchUserSettings: () =>
        dispatch(profileActions.fetchProfileData({ redirecToWizard: false })),
    redirect: (link) => dispatch(push(link)),
});

Container.propTypes = {
    fetchPricing: PropTypes.func,
    fetchUserSettings: PropTypes.func,
    history: PropTypes.object,
    isFetchingPricing: PropTypes.bool,
    isFetchingUserSettings: PropTypes.bool,
    isSavingSubscription: PropTypes.bool,
    location: PropTypes.object,
    pricingData: PropTypes.object,
    redirect: PropTypes.func,
    selectedTheme: PropTypes.string,
    subscription: PropTypes.object,
    subscriptions: PropTypes.array,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(Container);
