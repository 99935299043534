import React, { useContext, useEffect } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import { Box, Chip, Typography } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import DropdownButton from 'common/components/DropdownButton';
import { LoadingContext } from 'common/utils/contexts';
import { getDateRangeLabel } from 'common/utils/dates';
import { getFilterLabelFromOptions } from 'common/utils/helpers';
import { getDateFormatByLocale } from 'common/utils/helpers/multiregion';

import * as credentialIntegrationsSelectors from '../../credentialIntegrations/selectors';
import {
    DATE_FREQUENCIES,
    FILTERS_KEYS,
    FILTERS_TO_DISPLAY,
    STATUS_CONFIG,
} from '../constants';
import * as selectors from '../selectors';

const AppliedFilters = ({
    agents,
    contacts,
    downloadMonitoringCsv,
    filtersValues,
    isLoadingMonitoringCsv,
    politicalDivisions,
    projectStatuses,
    projects,
    providers,
    rates,
}) => {
    const loadingContext = useContext(LoadingContext);

    const { t } = useTranslation();
    const dateFormat = getDateFormatByLocale();

    useEffect(() => {
        if (isLoadingMonitoringCsv)
            loadingContext.openLoading(t('Downloading CSV').concat('...'));
        else loadingContext.closeLoading();
    }, [isLoadingMonitoringCsv]);

    const filtersWithOptions = {
        [FILTERS_KEYS.AGENT]: agents,
        [FILTERS_KEYS.CONTACT_ID]: contacts,
        [FILTERS_KEYS.POLITICAL_DIVISION]: politicalDivisions,
        [FILTERS_KEYS.PROJECT_ID]: projects,
        [FILTERS_KEYS.PROVIDER]: providers,
        [FILTERS_KEYS.PROJECT_STATUS]: projectStatuses,
        [FILTERS_KEYS.RATE_ID]: rates,
    };

    const getFilterValue = (key, value) => {
        if (filtersWithOptions[key])
            return getFilterLabelFromOptions(filtersWithOptions[key], value);

        if (key === FILTERS_KEYS.STATUS)
            return t(STATUS_CONFIG[value]?.label) || '';

        return value;
    };

    const dateChipLabel = getDateRangeLabel({
        finalDate: filtersValues?.[FILTERS_KEYS.FINAL_DATE],
        initialDate: filtersValues?.[FILTERS_KEYS.INITIAL_DATE],
        newFormat: dateFormat,
    });

    return (
        <Box
            display="flex"
            gap={1}
            alignItems="flex-start"
            mb={2}
            flexDirection={{ xs: 'column', md: 'row' }}
        >
            <Box
                alignItems="center"
                display="flex"
                flex={1}
                flexWrap="wrap"
                gap={1}
            >
                <Typography variant="subtitle1">
                    {t('System', { count: 2 })}:
                </Typography>

                {dateChipLabel && (
                    <Chip label={dateChipLabel} variant="outlined" />
                )}

                {FILTERS_TO_DISPLAY.map((key) => {
                    const value = getFilterValue(key, filtersValues[key]);
                    if (!value) return null;
                    return <Chip key={key} label={value} variant="outlined" />;
                })}
            </Box>

            <DropdownButton
                color="secondary"
                disabled={isLoadingMonitoringCsv}
                options={[
                    {
                        icon: <DownloadIcon />,
                        label: `${t('Download CSV')} (${t('Monthly')})`,
                        onClick: () =>
                            downloadMonitoringCsv(DATE_FREQUENCIES.MONTHLY),
                    },
                    {
                        icon: <DownloadIcon />,
                        label: `${t('Download CSV')} (${t('Daily')})`,
                        onClick: () =>
                            downloadMonitoringCsv(DATE_FREQUENCIES.DAILY),
                    },
                ]}
                variant="outlined"
            />
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    agents: selectors.getAgentsForSelect,
    contacts: selectors.getContactsForSelect,
    isLoadingMonitoringCsv: selectors.getMonitoringCsvIsLoading,
    politicalDivisions: selectors.getPoliticalDivisionForSelect,
    projectStatuses: selectors.getProjectStatusesForSelect,
    projects: selectors.getProjectsForSelect,
    providers: credentialIntegrationsSelectors.getProvidersForSelect2,
    rates: selectors.getRatesForSelect,
});

AppliedFilters.propTypes = {
    agents: PropTypes.array,
    contacts: PropTypes.array,
    downloadMonitoringCsv: PropTypes.func,
    filtersValues: PropTypes.object,
    isLoadingMonitoringCsv: PropTypes.bool,
    politicalDivisions: PropTypes.array,
    projectStatuses: PropTypes.array,
    projects: PropTypes.array,
    providers: PropTypes.array,
    rates: PropTypes.array,
};

export default connect(mapStateToProps)(AppliedFilters);
