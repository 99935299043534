import i18next from 'i18next';
import { isEmpty, isNull } from 'lodash';
import { types } from 'sunwise-template-core';

import { URL_TYPES } from 'common/constants';
import { getFileExtensionByPath } from 'common/utils/helpers';

import {
    CSV_FILE,
    ELECTRIC_BILL_TYPE,
    PDF_LOW_QUALITY,
    PDF_MEDIUM_QUALITY,
    PDF_REAL_TEXT,
} from './constants';

const getElectricBillImage = async (archiveUrl) => {
    try {
        const response = await fetch(archiveUrl);
        const blob = await response.blob();
        return URL.createObjectURL(blob);
    } catch (error) {
        return error;
    }
};

export const getDownloadOptions = (selectedDocument) => {
    const { format, type, urlType } = selectedDocument || {};
    let options = [];

    const csvFile = { label: `CSV - ${i18next.t('File')}`, value: CSV_FILE };

    const electricBillType = {
        label: `${i18next.t('Image').toUpperCase()} - ${i18next.t(
            'Medium quality',
        )}`,
        value: PDF_MEDIUM_QUALITY,
    };

    const pdfLowQuality = {
        label: `PDF - ${i18next.t('Low quality')}`,
        value: PDF_LOW_QUALITY,
    };

    const pdfMediumQuality = {
        label: `PDF - ${i18next.t('Medium quality')}`,
        value: PDF_MEDIUM_QUALITY,
    };

    const pdfRealText = { label: 'PDF', value: PDF_REAL_TEXT };

    switch (type) {
        case ELECTRIC_BILL_TYPE:
            options =
                urlType === URL_TYPES.LISA && format === 'png'
                    ? [{ ...electricBillType, disabled: true }]
                    : [{ ...pdfMediumQuality, disabled: true }];
            break;

        case types.REPORT_TYPE:
            options = [pdfRealText, pdfLowQuality, pdfMediumQuality, csvFile];
            break;

        default:
            options = [{ ...pdfMediumQuality, disabled: true }];
            break;
    }

    return [
        { disabled: true, label: i18next.t('Select'), value: '' },
        ...options,
    ];
};

export const getFileNameFromURL = (archive) => {
    if (!isNull(archive))
        return archive.substring(archive.lastIndexOf('/') + 1);
    return '';
};

export const getIsDownloadingFileContextMessage = ({
    isFetchingPdf,
    loadingContext,
}) => {
    if (isEmpty(loadingContext)) return;
    else if (isFetchingPdf)
        loadingContext.openLoading(i18next.t('Generating PDF').concat('...'));
    else loadingContext.closeLoading();
};

export const getIsLoadingContextMessage = ({
    isFetching,
    isFetchingCustomTemplate,
    isInitializing,
    loadingContext,
}) => {
    if (isEmpty(loadingContext)) return;
    if (isFetching || isFetchingCustomTemplate || isInitializing)
        loadingContext.openLoading(i18next.t('Loading').concat('...'));
    else loadingContext.closeLoading();
};

export const getIsUpdatingContextMessage = (
    editionDisabled,
    isUpdatingCustomTemplate,
    loadingContext,
    setEditionDisabled,
) => {
    if (isEmpty(loadingContext)) return;
    if (isUpdatingCustomTemplate)
        loadingContext.openLoading(i18next.t('Saving').concat('...'));
    else {
        if (!editionDisabled) setEditionDisabled(true);
        loadingContext.closeLoading();
    }
};

export const handleCloseDrawer = (swipeableDrawerRef) => {
    if (swipeableDrawerRef.current) {
        const { handleOpen } = swipeableDrawerRef.current;
        handleOpen(false);
    }
};

export const handleClickDocumentItemBuild = ({
    document,
    fetchCustomTemplate,
    initTemplate,
    resetTemplate,
    selectedDocument,
    setDownloadFormat,
    setSelectedDocument,
    swipeableDrawerRef,
}) => {
    const { id } = document;
    if (id !== selectedDocument.id) {
        resetTemplate();
        setSelectedDocument(document);
        setDownloadFormat(PDF_MEDIUM_QUALITY);
        fetchCustomTemplate({ id, initTemplate });
    }
    handleCloseDrawer(swipeableDrawerRef);
};

export const handleClickDownloadBuild = ({
    downloadElectricBill,
    downloadFormat,
    downloadReportCsv,
    downloadReportPdf,
    loadingContext,
    selectedDocument,
}) => {
    const { archive, fileName, id, name, type, urlType } = selectedDocument;
    switch (type) {
        case ELECTRIC_BILL_TYPE:
            return downloadElectricBill({
                archive,
                fileName,
                loadingContext,
                name,
                urlType,
            });
        default:
            switch (downloadFormat) {
                case CSV_FILE:
                    return downloadReportCsv({ id, name });
                default:
                    return downloadReportPdf({
                        downloadFormat,
                        id,
                        name,
                        type,
                    });
            }
    }
};

export const handleClickElectricBillBuild = ({
    document,
    fetchElectricBill,
    loadingContext,
    resetTemplate,
    setDownloadFormat,
    setSelectedDocument,
    swipeableDrawerRef,
}) => {
    resetTemplate();
    if (document.urlType === URL_TYPES.LISA) {
        loadingContext.openLoading(i18next.t('Getting file').concat('...'));
        fetchElectricBill(document?.fileName, async (data) => {
            let archive = data.url;
            const format = getFileExtensionByPath(data.url);
            if (format === 'png')
                archive = await getElectricBillImage(data.url);
            setSelectedDocument({ ...document, archive, format });
            loadingContext.closeLoading();
        });
    }
    if (document.urlType === URL_TYPES.SUNWISE) setSelectedDocument(document);
    setDownloadFormat(PDF_MEDIUM_QUALITY);
    handleCloseDrawer(swipeableDrawerRef);
};

export const handleScrollTopSideBar = (sideBarRef) => {
    if (sideBarRef && sideBarRef.current) sideBarRef.current.scrollTop = 0;
};
