import {
    add,
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    differenceInWeeks,
    differenceInYears,
    format,
    formatDistance,
    getDaysInMonth,
    getDaysInYear,
    isAfter,
    isBefore,
    isExists,
    isValid,
    parse,
    setMonth,
    startOfYear,
    sub,
} from 'date-fns';
import { getTimezoneOffset, toZonedTime } from 'date-fns-tz';

import { getLocaleEquivalenceForDateFns } from 'common/utils/helpers/multiregion';

export const addDate = (date, duration) => add(date, duration);

export const differenceInDaysDate = (dateLeft, dateRight) =>
    differenceInDays(dateLeft, dateRight);

export const differenceInHoursDate = (dateLeft, dateRight) =>
    differenceInHours(dateLeft, dateRight);

export const differenceInMinutesDate = (dateLeft, dateRight) =>
    differenceInMinutes(dateLeft, dateRight);

export const differenceInWeeksDate = (dateLeft, dateRight) =>
    differenceInWeeks(dateLeft, dateRight);

export const differenceInYearsDate = (dateLeft, dateRight) =>
    differenceInYears(dateLeft, dateRight);

export const formatDate = (
    date,
    formatDate = 'dd/MM/yyyy',
    locale = getLocaleEquivalenceForDateFns(),
) => format(date, formatDate, { locale });

export const formatDistanceDate = (date, baseDate, options) =>
    formatDistance(date, baseDate, {
        ...options,
        locale: getLocaleEquivalenceForDateFns(),
    });

export const getDateByTimezoneOffset = (date = new Date(), timezone) =>
    new Date(date.getTime() + getTimezoneOffset(timezone));

export const getDaysInMonthDate = (date) => getDaysInMonth(date);

export const getDaysInYearDate = (date) => getDaysInYear(date);

export const isAfterDate = (date, dateToCompare) =>
    isAfter(date, dateToCompare);

export const isBeforeDate = (date, dateToCompare) =>
    isBefore(date, dateToCompare);

export const isExistDate = (year, month, day) => isExists(year, month, day);

export const isValidDate = (date) => isValid(date);

export const parseDate = (
    date,
    formatDate,
    locale = getLocaleEquivalenceForDateFns(),
) => {
    if (!date || !formatDate) return new Date();
    return parse(date, formatDate, new Date(), { locale });
};

export const setMonthDate = (date, month) => setMonth(date, month);

export const startOfYearDate = (date) => startOfYear(date);

export const subDate = (date, duration) => sub(date, duration);

export const zonedDate = (date, timezone) => toZonedTime(date, timezone);

/** Others */
export const getDateRangeLabel = ({
    currentFormat,
    finalDate,
    initialDate,
    newFormat,
}) => {
    if (!initialDate && !finalDate) return null;
    const initial = transformDateFormat(initialDate, newFormat, currentFormat);
    const final = transformDateFormat(finalDate, newFormat, currentFormat);
    return `${initial} - ${final}`;
};

/**
 * Converts a date string to a UTC timestamp.
 *
 * @param {string} date - The date string to be converted.
 * @param {string} [format='dd/MM/yyyy'] - The format of the input date string.
 * @param {Object} [locale] - The locale object for date parsing.
 * @returns {number|null} The UTC timestamp in milliseconds, or null if the input date is invalid.
 */
export const getDateStringAsUTC = (date, format = 'dd/MM/yyyy', locale) => {
    if (!date) return null;
    const dateParsed = parseDate(date, format, locale);
    return Date.UTC(
        dateParsed.getFullYear(),
        dateParsed.getMonth(),
        dateParsed.getDate(),
        dateParsed.getHours(),
        dateParsed.getMinutes(),
        dateParsed.getSeconds(),
    );
};

/**
 * Converts a UTC timestamp to a formatted date string.
 *
 * @param {number} utcMs - The UTC timestamp in milliseconds.
 * @param {string} [format='dd/MM/yyyy'] - The desired format of the output date string.
 * @param {Object} [locale] - The locale object for date formatting.
 * @returns {string|null} The formatted date string, or null if the input timestamp is invalid.
 */
export const getUTCDateAsString = (utcMs, format = 'dd/MM/yyyy', locale) => {
    if (!utcMs) return null;
    const date = new Date(utcMs);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const day = date.getUTCDate();
    date.setFullYear(year, month, day);
    return formatDate(date, format, locale);
};

/**
 * Adds a given interval to a UTC timestamp given as a string. Returns the new timestamp in milliseconds.
 *
 * @param {Object} options - The options object.
 * @param {string} options.date - The date string to be transformed.
 * @param {string} [options.dateFormat='dd/MM/yyyy'] - The format of the input date string.
 * @param {number} [options.interval=0] - The interval to be added to the date in milliseconds.
 * @param {Object} [options.localeForDateFns] - The locale object for date parsing and formatting.
 */
export const shiftUTCDateString = ({
    date,
    dateFormat,
    interval = 0,
    localeForDateFns,
}) => {
    const utcDate = getDateStringAsUTC(date, dateFormat, localeForDateFns) || 0;

    return utcDate + (interval || 0);
};

/**
 * Transforms a date string from one format to another.
 *
 * @param {string} date - The date string to be transformed.
 * @param {string} [newFormat='dd/MM/yyyy'] - The desired format of the output date string.
 * @param {string} [currentFormat='dd/MM/yyyy'] - The format of the input date string.
 * @returns {string|null} The transformed date string, or null if the input date is invalid.
 */
export const transformDateFormat = (
    date,
    newFormat = 'dd/MM/yyyy',
    currentFormat = 'dd/MM/yyyy',
) => {
    if (!date) return null;
    if (newFormat === currentFormat) return date;
    return formatDate(parseDate(date, currentFormat), newFormat);
};

/**
 * Converts a given date to December 31st of the same year (UTC).
 *
 * @param {Date|number} date - The date to be converted to December 31st. Can be a Date object or milliseconds.
 * @returns {Date} The date converted to December 31st of the same year (UTC).
 */
export const getUTCDecember31st = (date) => {
    const dec31 = new Date(date);
    dec31.setUTCMonth(11); // December is month 11 in JavaScript Date (0-indexed)
    dec31.setUTCDate(31);
    return dec31;
};

/**
 * Checks if December 31st of the initial and final years is within the given interval.
 *
 * @param {number} initialMS - The initial date in milliseconds (formatted without timezone).
 * @param {number} finalMS - The final date in milliseconds (formatted without timezone).
 * @returns {boolean} True if December 31st is between the interval, false otherwise.
 */
export const isDecember31stInInterval = (initialMS, finalMS) => {
    if (!initialMS || !finalMS) return false;

    const initialDecember31st = getUTCDecember31st(initialMS);
    const finalDecember31st = getUTCDecember31st(finalMS);

    // Intervals are open on the left and closed on the right side
    return (
        (initialDecember31st > initialMS && initialDecember31st <= finalMS) ||
        (finalDecember31st > initialMS && finalDecember31st <= finalMS)
    );
};

/**
 * Determines if a given year is a leap year.
 *
 * @param {number} year - The year to be checked.
 * @returns {boolean} True if the year is a leap year, false otherwise.
 */
export const isLeapYear = (year) =>
    (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

/**
 * Checks if a given date is December 31st of a leap year.
 *
 * @param {Object} date - The date object containing day, month, and year.
 * @param {number} date.day - The day of the month.
 * @param {number} date.month - The month of the year (0-indexed, where 11 is December).
 * @param {number} date.year - The year.
 * @returns {boolean} True if the date is December 31st of a leap year, false otherwise.
 */
export const isInvalidLeapYearDay = ({ day, month, year }) =>
    isLeapYear(year) && month === 11 && day === 31;
