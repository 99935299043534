import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { BottomActions, Box, Button } from 'sunwise-ui';

import { INSURANCE_TYPE } from 'common/constants';

import * as actions from '../actions';
import { isDisabledButton } from '../helpers';
import * as selectors from '../selectors';

import InsuranceCheck from './InsuranceCheck';
import TermsContent from './TermsContent';

const TermsConditionsForm = ({
    handleOnClick,
    initialValues,
    isFetching,
    requirements,
    selectedFinancier,
}) => {
    const { t } = useTranslation();

    const { control, watch } = useForm({ defaultValues: initialValues });

    const financierType = get(selectedFinancier, 'type', null);
    const registered = watch('companyRegistered') || false;

    const buttonText = requirements.required ? t('I Accept Terms') : t('Next');

    return (
        <form>
            <TermsContent isFetching={isFetching} requirements={requirements} />

            <InsuranceCheck
                control={control}
                visible={financierType === INSURANCE_TYPE}
            />

            <BottomActions>
                <Box display="flex" gap="16px" sx={{ pt: 2 }}>
                    <Button
                        disabled={isDisabledButton(
                            financierType,
                            registered,
                            requirements,
                        )}
                        onClick={handleOnClick}
                    >
                        {buttonText}
                    </Button>
                </Box>
            </BottomActions>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getTermInitialValues,
});

const mapDispatchToProps = (dispatch) => ({
    saveAlliance: (selectedFinancier, callback) =>
        dispatch(actions.saveAlliance(selectedFinancier, callback)),
});

TermsConditionsForm.propTypes = {
    handleOnClick: PropTypes.func,
    initialValues: PropTypes.object,
    isFetching: PropTypes.bool,
    requirements: PropTypes.object,
    selectedFinancier: PropTypes.object,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TermsConditionsForm);
