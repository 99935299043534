import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Divider, Typography } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';

import * as actions from '../actions';
import * as selectors from '../selectors';

import CommentForm from './CommentForm';

const Comments = ({ data, prepareDeleteComment }) => {
    const { t } = useTranslation();
    const [editionMode, setEditionMode] = React.useState(false);

    const { comment, contact_tracking, created_at, created_by, id } = data;
    const { first_name, last_name } = created_by;

    const handleClickDelete = (commentId) =>
        prepareDeleteComment({ commentId, trackingId: contact_tracking });

    const handleClickEdit = () => setEditionMode(true);

    return (
        <Box key={`comment-${id}`} sx={{ mt: 2 }}>
            <Box sx={{ alignItems: 'center', display: 'flex', gap: 2, mb: 1 }}>
                <Typography
                    fontWeight="bold"
                    variant="body2"
                >{`${first_name} ${last_name}`}</Typography>

                <Typography sx={{ ml: 'auto' }} variant="caption">
                    {created_at}
                </Typography>

                <Box>
                    <DropdownCardMenu
                        items={[
                            {
                                handleClick: () => handleClickEdit(id),
                                icon: <EditIcon fontSize="small" />,
                                label: t('Edit'),
                            },
                            {
                                handleClick: () => handleClickDelete(id),
                                icon: <DeleteIcon fontSize="small" />,
                                label: t('Delete'),
                            },
                        ]}
                        size="small"
                    />
                </Box>
            </Box>

            <Box sx={{ fontSize: '0.9rem' }}>
                {!editionMode ? (
                    comment
                ) : (
                    <CommentForm
                        commentId={id}
                        content={comment}
                        editionMode={true}
                        setEditionMode={setEditionMode}
                        trackingId={contact_tracking}
                    />
                )}
            </Box>

            <Divider sx={{ my: 2 }} />
        </Box>
    );
};

Comments.propTypes = {
    data: PropTypes.object,
    prepareDeleteComment: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    comments: selectors.getCommentsData,
});

const mapDispatchToProps = (dispatch) => ({
    prepareDeleteComment: (data) =>
        dispatch(actions.prepareDeleteComment(data)),
    fetchComments: (id) => dispatch(actions.fetchComments(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
