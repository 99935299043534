import React from 'react';

import PropTypes from 'prop-types';
import { Card, Divider, Grid, Skeleton } from 'sunwise-ui';

const Placeholder = (
    <Card>
        <Card.Body>
            <Grid container>
                <Grid size={{ xs: 'grow' }}>
                    <Skeleton variant="rounded" />
                </Grid>
            </Grid>

            <Divider sx={{ my: 1 }} />

            <Grid container>
                <Grid size={{ xs: 2 }}>
                    <Skeleton variant="rounded" />
                </Grid>

                <Grid size={{ xs: 16 }}>
                    <Skeleton variant="rounded" />
                </Grid>
            </Grid>
        </Card.Body>
    </Card>
);

const PlaceholderCardDocuments = ({ children, ready }) => (
    <>{ready ? Placeholder : children}</>
);

PlaceholderCardDocuments.propTypes = {
    children: PropTypes.node,
    ready: PropTypes.bool,
};

export default PlaceholderCardDocuments;
