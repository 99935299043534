import React, { useContext, useEffect } from 'react';

import { push } from 'connected-react-router';
import { get } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Container as MaterialContainer, Grid } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import * as customColumnsSelectors from 'common/modules/customColumns/selectors';
import * as multiBranchesSelectors from 'common/modules/multiBranches/selectors';
import { LoadingContext } from 'common/utils/contexts';

import companyGeneral from '../companyGeneral';
import * as profileSelectors from '../profile/selectors';

import * as actions from './actions';
import TabsContent from './components/TabsContent';
import TemplateColorPalette from './components/TemplateColorPalette';
import * as selectors from './selectors';

const Container = ({
    branchOfficesDictionary,
    branchSelected,
    canModify,
    canView,
    companyCountry,
    companyUsersData,
    filterTemplates,
    handleClickBulkItems,
    handleClickCreate,
    handleClickDelete,
    handleClickDuplicate,
    handleClickUpdate,
    handleSaveRow,
    initialize,
    isCreatingItem,
    isFetchingTableSettings,
    isFetchingTemplates,
    isInitializing,
    isRenamingTemplate,
    languagesDictionary,
    paginationTemplates,
    redirect,
    reset,
    subscription,
    subscriptionVersion,
    tableSettings,
    templatesData,
}) => {
    const loadingContext = useContext(LoadingContext);
    const { t } = useTranslation();
    const productType = get(subscription, 'product_type', null);

    useEffect(() => {
        initialize();
        return () => reset();
    }, []);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isCreatingItem || isRenamingTemplate)
            loadingContext.openLoading(
                t('Saving change', { count: 2 }).concat('...'),
            );
        else loadingContext.closeLoading();
    }, [isCreatingItem, isRenamingTemplate]);

    useEffect(() => {
        if (subscriptionVersion === 'v5' && productType === 'aftersales')
            redirect('/template-library/reports');
    }, [productType, subscriptionVersion]);

    const commonProps = {
        branchOfficesDictionary,
        branchSelected,
        canModifyCarousel: canModify,
        canViewCarousel: canView,
        companyCountry,
        companyUsersData,
        filterTemplates,
        handleClickBulkItems,
        handleClickCreate,
        handleClickDelete,
        handleClickDuplicate,
        handleClickUpdate,
        handleSaveRow,
        isFetching:
            isFetchingTableSettings || isFetchingTemplates || isInitializing,
        languagesDictionary,
        paginationData: paginationTemplates,
        productType,
        subscription,
        tableSettings,
        templatesData,
    };

    return (
        <>
            <MaterialContainer maxWidth={false}>
                <HeaderContainer>
                    <Grid size={{ xs: 'grow' }}>
                        <TitleIcon title={t('Template', { count: 2 })} />
                    </Grid>
                    <Grid size={{ xs: 12 }} sx={{ textAlign: 'right' }}>
                        <TemplateColorPalette.Container />
                    </Grid>
                </HeaderContainer>
            </MaterialContainer>

            <TabsContent commonProps={commonProps} />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    branchOfficesDictionary: multiBranchesSelectors.getBranchesData,
    companyCountry: companyGeneral.selectors.getCompanyCountry,
    companyUsersData: selectors.getCompanyUsersData,
    isCreatingItem: selectors.getIsCreatingItem,
    isFetchingLanguages: selectors.getIsFetchingLanguages,
    isFetchingTableSettings: customColumnsSelectors.getIsFetchingTableSettings,
    isFetchingTemplates: selectors.getIsFetchingTemplates,
    isInitializing: selectors.getIsInitializing,
    isRenamingTemplate: selectors.getIsRenamingTemplate,
    languagesDictionary: selectors.getLanguagesDictionary,
    paginationTemplates: selectors.getTemplatesPagination,
    subscription: profileSelectors.getActiveSubscription,
    subscriptionVersion: profileSelectors.getSubscriptionVersion,
    tableSettings: customColumnsSelectors.getTableSettingsData,
    templatesData: selectors.getTemplatesData,
});

const mapDispatchToProps = (dispatch) => ({
    filterTemplates: (filterData) =>
        dispatch(actions.filterTemplates(filterData)),
    handleClickBulkItems: (ids, value, filterData, callback) =>
        dispatch(actions.prepareBulkItems(ids, value, filterData, callback)),
    handleClickCreate: (branchSelected, template, templateType) =>
        dispatch(actions.prepareCreate(branchSelected, template, templateType)),
    handleClickDelete: (filter, roww) =>
        dispatch(actions.prepareDelete(filter, roww)),
    handleClickDuplicate: (branchSelected, template) =>
        dispatch(actions.prepareDuplicate(branchSelected, template)),
    handleClickUpdate: (template) => dispatch(actions.prepareEdit(template)),
    handleSaveRow: (item) => dispatch(actions.updateProduct(item)),
    initialize: () => dispatch(actions.initialFetching()),
    redirect: (link) => dispatch(push(link)),
    reset: () => dispatch(actions.reset()),
});

Container.propTypes = {
    branchOfficesDictionary: PropTypes.array,
    branchSelected: PropTypes.string,
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    companyCountry: PropTypes.string,
    companyUsersData: PropTypes.array,
    filterTemplates: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickCreate: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickDuplicate: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    handleSaveRow: PropTypes.func,
    initialize: PropTypes.func,
    isCreatingItem: PropTypes.bool,
    isFetchingTableSettings: PropTypes.bool,
    isFetchingTemplates: PropTypes.bool,
    isInitializing: PropTypes.bool,
    isRenamingTemplate: PropTypes.bool,
    languagesDictionary: PropTypes.object,
    paginationTemplates: PropTypes.object,
    redirect: PropTypes.func,
    reset: PropTypes.func,
    subscription: PropTypes.object,
    subscriptionVersion: PropTypes.string,
    tableSettings: PropTypes.array,
    templatesData: PropTypes.array,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.PROPOSAL_TEMPLATES_PERMISSION),
    withRouter,
)(Container);
