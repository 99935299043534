import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from 'sunwise-ui';

import { EmptyMessage } from 'common/components';

const TermsContent = ({ isFetching, requirements }) => {
    const { t } = useTranslation();

    if (isFetching) {
        return (
            <EmptyMessage
                text={t('Loading terms and conditions').concat('...')}
            />
        );
    }

    if (requirements.content.length === 0)
        return <EmptyMessage text={t('No terms and conditions to show')} />;

    return (
        <Box
            dangerouslySetInnerHTML={{
                __html: requirements.content,
            }}
            sx={{
                background: '#fff',
                borderRadius: 1,
                color: '#000',
                padding: 2,
            }}
        />
    );
};

TermsContent.propTypes = {
    isFetching: PropTypes.bool,
    requirements: PropTypes.object,
};

export default TermsContent;
