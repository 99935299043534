import React from 'react';

import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton, Tooltip } from 'sunwise-ui';

import { SUBSCRIPTION_STATUS } from 'common/constants';

import CreditsIcon from './CreditsIcon';
import CreditsTooltipContent from './CreditsTooltipContent';

const CreditsIconTooltip = ({ subscriptions, sxIcon }) => {
    if (isEmpty(subscriptions)) return null;

    return subscriptions
        .filter(
            ({ status, version }) =>
                version === 'v5' &&
                get(status, 'key', null) !== SUBSCRIPTION_STATUS.CANCELLED,
        )
        .map((sub, index) => (
            <Tooltip
                key={`${sub?.item_price_id}-${index}`}
                title={<CreditsTooltipContent subscription={sub} />}
            >
                <IconButton
                    component={RouterLink}
                    size="small"
                    sx={{ position: 'relative' }}
                    to={`/profile/subscription`}
                >
                    <CreditsIcon
                        productType={sub?.product_type}
                        sxIcon={sxIcon}
                    />
                </IconButton>
            </Tooltip>
        ));
};

CreditsIconTooltip.propTypes = {
    subscriptions: PropTypes.array,
    sxIcon: PropTypes.object,
};

export default CreditsIconTooltip;
