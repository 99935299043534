import {
    REPORT_RESOLUTIONS_CONFIG,
    REPORT_STATUS_CONFIG,
} from '../reportList/constants';

export const NAME = 'funnel-reports';

export const DEFAULT_ALL_STATUS_KEY = 'all';

export const VIEWS = {
    FUNNEL: 'funnel',
    LIST: 'list',
};

export const RATE_ID_FILTERS = {
    CERTIFIED: 'rate_id',
    NOT_CERTIFIED: 'customize_rate_id',
};

export const FILTERS_KEYS = {
    AGENT: 'agent_id',
    CONTACT_ID: 'contact_id',
    CUSTOM_RATE_ID: RATE_ID_FILTERS.NOT_CERTIFIED,
    ELECTRIC_BILL_STATUS: 'electric_bill_status',
    FINAL_DATE: 'final_date',
    FREQ: 'freq',
    INITIAL_DATE: 'initial_date',
    PERIOD: 'period',
    POLITICAL_DIVISION: 'dp1_id',
    PROJECT_ID: 'project_id',
    PROJECT_STATUS: 'project_status_id',
    PROVIDER: 'provider',
    RATE_ID: RATE_ID_FILTERS.CERTIFIED,
    REPORT_RESOLUTION: 'resolution',
    REPORT_STATUS: 'report_status',
    ZIP_CODE: 'zip_code',
};

export const FILTERS_TO_DISPLAY = [
    FILTERS_KEYS.REPORT_STATUS,
    FILTERS_KEYS.REPORT_RESOLUTION,
    FILTERS_KEYS.ELECTRIC_BILL_STATUS,
    FILTERS_KEYS.PROVIDER,
    FILTERS_KEYS.AGENT,
    FILTERS_KEYS.CONTACT_ID,
    FILTERS_KEYS.PROJECT_STATUS,
    FILTERS_KEYS.PROJECT_ID,
    FILTERS_KEYS.RATE_ID,
    FILTERS_KEYS.POLITICAL_DIVISION,
    FILTERS_KEYS.ZIP_CODE,
];

export const ELECTRIC_BILL_STATUS_KEYS = {
    ERROR: 2,
    UPDATED: 0,
    PENDING: 1,
};

export const ELECTRIC_BILL_STATUS_CONFIG = {
    [ELECTRIC_BILL_STATUS_KEYS.PENDING]: { color: '#FF7043', label: 'Pending' },
    [ELECTRIC_BILL_STATUS_KEYS.UPDATED]: {
        color: '#3CAF5F',
        label: 'Up to date',
    },
    [ELECTRIC_BILL_STATUS_KEYS.ERROR]: { color: '#DA1E28', label: 'Error' },
};

export const FILTERS_WITH_TRANSLATION = {
    [FILTERS_KEYS.ELECTRIC_BILL_STATUS]: ELECTRIC_BILL_STATUS_CONFIG,
    [FILTERS_KEYS.REPORT_STATUS]: REPORT_STATUS_CONFIG,
    [FILTERS_KEYS.REPORT_RESOLUTION]: REPORT_RESOLUTIONS_CONFIG,
};

export const REPORT_TYPES = {
    SINGLE_PERIOD: 0,
    MULTIPERIOD: 1,
    GROUPED_PERIODS: 2,
};

export const REPORT_TYPES_CONFIG = {
    [REPORT_TYPES.SINGLE_PERIOD]: { label: 'Single period' },
    [REPORT_TYPES.MULTIPERIOD]: { label: 'Multiple periods' },
    [REPORT_TYPES.MULTIPERIOD]: { label: 'Grouped periods' },
};
