import { getSummaryInfo } from 'common/api/v1/summaryInfoPatern';

import {
    FETCH_SUMMARY_PATERN,
    FETCH_SUMMARY_PATERN_FAILURE,
    FETCH_SUMMARY_PATERN_SUCCESS,
} from '../actionTypes';
import { summaryReviewActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(summaryReviewActions[FETCH_SUMMARY_PATERN]());

    getSummaryInfo(id)
        .then((response) => {
            const {
                contact_id,
                contact_name,
                linked_proposals,
                permalink,
                project_id,
                project_name,
                proposal_summary_name,
            } = response.data;
            dispatch(
                summaryReviewActions[FETCH_SUMMARY_PATERN_SUCCESS]({
                    contactId: contact_id,
                    contactName: contact_name,
                    linked_proposals,
                    permalink,
                    projectId: project_id,
                    projectName: project_name,
                    summaryName: proposal_summary_name,
                }),
            );
        })
        .catch((error) =>
            dispatch(summaryReviewActions[FETCH_SUMMARY_PATERN_FAILURE](error)),
        );
};
