import React from 'react';

import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'sunwise-ui';

const ReadOnlyTooltip = ({ isReadOnly }) => {
    const { t } = useTranslation();

    if (!isReadOnly) return null;

    return (
        <Tooltip
            placement="top"
            title={t(
                'This proposal was generated via the web quote and is read only',
            )}
        >
            <LaptopMacIcon color="info" fontSize="small" />
        </Tooltip>
    );
};

ReadOnlyTooltip.propTypes = {
    isReadOnly: PropTypes.bool,
};

export default ReadOnlyTooltip;
