import API from '../../afterSalesAPI';

const config = { headers: { 'Content-Type': 'application/json' } };
const ENTITY = 'projects';

export const createProject = (data) => API.post(`/api/${ENTITY}`, data, config);

export const createProjectInfo = (sunwiseProject, data) =>
    API.post(`/api/${ENTITY}/${sunwiseProject}/projects`, data, config);

export const deleteProject = (sunwiseProject) =>
    API.delete(`/api/${ENTITY}/${sunwiseProject}`);

export const fillReads = (sunwiseProject, data) =>
    API.post(`/api/${ENTITY}/${sunwiseProject}/fill_reads`, data, config);

export const getAllReads = (data) =>
    API.post(`/api/${ENTITY}/reads`, data, config);

export const getAllSunwiseProjects = () =>
    API.get(`/api/${ENTITY}/all-account-sunwise-projects`);

export const getIndicatorsData = () => API.get(`/api/${ENTITY}/indicators`);

export const getLogs = (page, page_size) =>
    API.get(`/api/${ENTITY}/logs`, { params: { page, page_size } });

export const getMappedProjects = (data) =>
    API.post(`/api/${ENTITY}/mapped-projects`, data, config);

export const getProjectsBySunwiseProject = (sunwiseProject) =>
    API.get(`/api/${ENTITY}/${sunwiseProject}`);

export const getProjectsCsv = (data) =>
    API.post(`/api/${ENTITY}/csv`, data, config);

export const getReadsByDate = (sunwiseProject, data) =>
    API.post(`/api/${ENTITY}/${sunwiseProject}/reads`, data, config);

export const updateProjectFields = (projectId, data) =>
    API.patch(`/api/${ENTITY}/edit-project-config/${projectId}`, data, config);

export const updateProjectInfo = (sunwiseProject, data) =>
    API.patch(`/api/${ENTITY}/${sunwiseProject}`, data, config);
