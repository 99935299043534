import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton } from 'sunwise-ui';

import { ReactHookFormSelect2 } from 'common/components/form/bootstrap';
import {
    nextItemsToSelect,
    itemsToSelect2WithoutDuplicates,
} from 'common/utils/helpers';

const ReactHookFormArray = ({
    control,
    disabled,
    getValues,
    groupName,
    itemsToSelect,
}) => {
    const { t } = useTranslation();

    const { append, fields, remove } = useFieldArray({
        control: control,
        name: groupName,
    });

    const _disabled =
        disabled ||
        nextItemsToSelect(getValues(groupName), itemsToSelect).length === 0;

    return (
        <>
            {fields.map((item, i) => {
                const mappedItemsToSelect = itemsToSelect.map((item) => {
                    return {
                        brand_name: item?.brand_name || '',
                        name: item.label,
                        id: item.value,
                    };
                });
                const selectedItems = mappedItemsToSelect.filter((item) =>
                    getValues(groupName)
                        .map((i) => i.item)
                        .includes(item.id),
                );

                const options = itemsToSelect2WithoutDuplicates(
                    mappedItemsToSelect,
                    false,
                    selectedItems,
                );

                return (
                    <Box display="flex" gap={2} key={item.id}>
                        <Box sx={{ flex: 1 }}>
                            <ReactHookFormSelect2
                                control={control}
                                disabled={disabled}
                                name={`${groupName}.${i}.item`}
                                options={options}
                            />
                        </Box>

                        <Box>
                            <IconButton
                                disabled={disabled}
                                onClick={() => remove(i)}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    </Box>
                );
            })}

            <Button
                disabled={_disabled}
                onClick={() => append({ item: '' })}
                type="button"
                variant="outlined"
            >
                {t('Add')}
            </Button>
        </>
    );
};

ReactHookFormArray.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    getValues: PropTypes.func,
    groupName: PropTypes.string,
    itemsToSelect: PropTypes.array,
};

export default ReactHookFormArray;
