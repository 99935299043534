import React, { useContext, useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import { CB_PORTAL_SECTIONS } from 'common/constants';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withChargebee from 'common/hocs/withChargebee';
import withPermissions from 'common/hocs/withPermissions';
import * as subscriptionSocket from 'common/sockets/subscription';
import { LoadingContext } from 'common/utils/contexts';
import parseQueryString from 'common/utils/parseQueryString';

import * as profileActions from '../profile/actions';
import * as profileSelectors from '../profile/selectors';

import * as actions from './actions';
import BillingInformation from './components/BillingInformation';
import NextSubscripionsCard from './components/NextSubscripionsCard';
import UsageCard from './components/UsageCard';
import UsageCardV4 from './components/UsageCardV4';
import { getCallbacks, handleOpenPortal, handleSocketEvents } from './helpers';
import * as selectors from './selectors';

const Container = ({
    activeUsers,
    addons,
    addonsSubscriptions,
    billingInformation,
    canModify,
    cbInstance,
    connectSocket,
    disconnectSocket,
    fetchBillingInformation,
    fetchPaymentSources,
    fetchUserSettings,
    history,
    initialize,
    isFetchingPayInvoices,
    location,
    payInvoices,
    payInvoicesErrors,
    paymentSources,
    socketHandler,
    subscription,
    subscriptions,
    subscriptionVersion,
    userSettings,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const [event, setEvent] = useState(null);
    const { search: locationSearch = '' } = location;
    const { action = null, open_section: openSection = null } =
        parseQueryString(locationSearch);

    useEffect(() => {
        initialize();
    }, []);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isFetchingPayInvoices)
            loadingContext.openLoading(
                t('Wait while the operation is performed').concat('...'),
            );
        else loadingContext.closeLoading();
    }, [isFetchingPayInvoices]);

    useEffect(() => {
        const { company } = userSettings;
        if (company?.id) connectSocket(company?.id);
        return () => {
            disconnectSocket();
        };
    }, [userSettings]);

    useEffect(() => {
        setEvent(socketHandler);
    }, [socketHandler]);

    useEffect(() => {
        handleSocketEvents(
            cbInstance,
            event,
            fetchBillingInformation,
            fetchPaymentSources,
            fetchUserSettings,
            setEvent,
        );
    }, [cbInstance, event]);

    useEffect(() => {
        if (
            openSection &&
            Object.values(CB_PORTAL_SECTIONS).includes(openSection)
        )
            handlePortal(openSection);
    }, [cbInstance, openSection]);

    useEffect(() => {
        if (action === 'pay_invoices') {
            const primaryPaymentSource = paymentSources.find(
                (item) => item.is_primary,
            );
            const last4 = primaryPaymentSource
                ? primaryPaymentSource.last4
                : 'XXXX';
            payInvoices(last4, () => {
                const params = new URLSearchParams(location.search);
                params.delete('action');
                history.push({ search: params.toString() });
            });
        }
    }, [action]);

    const resetLocation = () =>
        history.push({
            pathname: location.pathname,
            search: '',
        });

    const callbacks = getCallbacks(fetchUserSettings, resetLocation);

    const handlePortal = (sectionType, subscriptionId = null) => {
        return handleOpenPortal(
            callbacks,
            cbInstance,
            sectionType,
            subscriptionId,
        );
    };

    return (
        <Grid container mb={2}>
            <Grid size={{ md: 6, xs: 18 }}>
                <NextSubscripionsCard
                    addonsSubscriptions={addonsSubscriptions}
                    canModify={canModify}
                    handlePortal={handlePortal}
                    payInvoices={payInvoices}
                    payInvoicesErrors={payInvoicesErrors}
                    paymentSources={paymentSources}
                    subscriptions={subscriptions}
                />
            </Grid>
            <Grid size={{ md: 6, xs: 18 }}>
                {subscriptionVersion === 'v4' && (
                    <UsageCardV4
                        activeUsers={activeUsers}
                        addons={addons}
                        subscriptions={subscriptions}
                    />
                )}
                {subscriptionVersion === 'v5' && (
                    <UsageCard subscriptions={subscriptions} />
                )}
            </Grid>
            <Grid size={{ md: 6, xs: 18 }}>
                <BillingInformation
                    billingInformation={billingInformation}
                    canModify={canModify}
                    handlePortal={handlePortal}
                    paymentSources={paymentSources}
                    subscription={subscription}
                    userSettings={userSettings}
                />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    activeUsers: profileSelectors.getActiveUsers,
    addons: profileSelectors.getAddons,
    addonsSubscriptions: selectors.getSubscriptionAddons,
    billingInformation: selectors.getBillingInformationData,
    isFetchingPayInvoices: selectors.getIsFetchingPayInvoices,
    payInvoicesErrors: selectors.getPayInvoicesErrors,
    paymentSources: selectors.getPaymentSourcesData,
    socketHandler: selectors.getSocketHandler,
    subscription: profileSelectors.getMainSubscription,
    subscriptions: profileSelectors.getSubscriptions,
    subscriptionVersion: profileSelectors.getSubscriptionVersion,
    userSettings: profileSelectors.getDataFetch,
});

const mapDispatchToProps = (dispatch) => ({
    disconnectSocket: () => dispatch(subscriptionSocket.disconnect()),
    connectSocket: (companyId) =>
        dispatch(subscriptionSocket.connect(companyId)),
    fetchBillingInformation: () => dispatch(actions.fetchBillingInformation()),
    fetchPaymentSources: () => dispatch(actions.fetchPaymentSources()),
    fetchUserSettings: () => dispatch(profileActions.fetchProfileData()),
    initialize: () => dispatch(actions.initialize()),
    payInvoices: (last4, callback) =>
        dispatch(actions.preparePayment(last4, callback)),
});

Container.propTypes = {
    activeUsers: PropTypes.number,
    addons: PropTypes.array,
    addonsSubscriptions: PropTypes.array,
    billingInformation: PropTypes.object,
    canModify: PropTypes.bool,
    cbInstance: PropTypes.object,
    connectSocket: PropTypes.func,
    disconnectSocket: PropTypes.func,
    fetchBillingInformation: PropTypes.func,
    fetchPaymentSources: PropTypes.func,
    fetchUserSettings: PropTypes.func,
    history: PropTypes.object,
    initialize: PropTypes.func,
    isFetchingPayInvoices: PropTypes.bool,
    location: PropTypes.object,
    payInvoices: PropTypes.func,
    payInvoicesErrors: PropTypes.array,
    paymentSources: PropTypes.array,
    socketHandler: PropTypes.string,
    subscription: PropTypes.object,
    subscriptions: PropTypes.array,
    subscriptionVersion: PropTypes.string,
    userSettings: PropTypes.object,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.SUBSCRIPTION_PERMISSION),
    withChargebee(),
)(Container);
