import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import ReactHookFormCheck from 'common/components/form/bootstrap/ReactHookFormCheck';

const InsuranceCheck = ({ control, visible }) => {
    const { t } = useTranslation();

    if (!visible) return null;

    return (
        <Grid container>
            <Grid size={{ xs: 'grow' }}>
                <ReactHookFormCheck
                    control={control}
                    label={t('I completed my registration')}
                    id="companyRegistered"
                    name="companyRegistered"
                />
            </Grid>
        </Grid>
    );
};

InsuranceCheck.propTypes = {
    control: PropTypes.object,
    visible: PropTypes.bool,
};

export default InsuranceCheck;
