import { getFile } from 'common/api/zap/media';
import { showFastApiErrorsAsAlert } from 'common/utils/helpers';

import {
    FETCH_FILE,
    FETCH_FILE_FAILURE,
    FETCH_FILE_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

import authenticate from './authenticate';

export default (fileName, onSuccess, onFailure) => (dispatch) => {
    dispatch(actions[FETCH_FILE]());

    authenticate(() => getFile(fileName))
        .then((response) => {
            dispatch(actions[FETCH_FILE_SUCCESS]());

            if (onSuccess) onSuccess(response?.data);
        })
        .catch((error) => {
            dispatch(actions[FETCH_FILE_FAILURE](error));

            showFastApiErrorsAsAlert(dispatch, error?.response);

            if (onFailure) onFailure(error);
        });
};
