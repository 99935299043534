import React, { useEffect } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { Box, Button, Drawer, IconButton } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { scroll } from 'common/utils/mixins';

import { fetchReportHistoricals } from '../../actions';
import { getReportRedirectConfig } from '../../helpers';
import ElectricBills from '../ElectricBills';
import Periods from '../Periods';
import ReportName from '../ReportName';

import Charts from './Charts';
import InfoAccordion from './InfoAccordion';

const DrawerHeader = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
    ...theme.mixins.toolbar,
}));

const WrapperContent = styled(Box)`
    height: ${({ footerheight }) => `calc(100vh - ${footerheight})`};
    overflow-y: auto;
    padding: 0 16px;

    ${scroll.custom()};
`;

const DetailsDrawer = ({
    fetchReportHistoricals,
    handleClose,
    isOpen,
    localeDateFormat,
    selectedReport,
}) => {
    const { t } = useTranslation();

    const {
        complements: { currency, status } = {},
        id,
        name,
        project: { contact, is_deleted } = {},
        project_id,
        report_periods,
        resolution,
    } = selectedReport || {};

    useEffect(() => {
        if (id) fetchReportHistoricals(id);
    }, [id]);

    const footerHeight = is_deleted ? 0 : '110px';

    const redirectConfig = getReportRedirectConfig(status);

    return (
        <Drawer
            anchor="right"
            open={isOpen}
            PaperProps={{ sx: { maxWidth: '100%', width: '450px' } }}
            onClose={handleClose}
        >
            <WrapperContent footerheight={footerHeight}>
                <DrawerHeader>
                    <ReportName
                        iconSize={20}
                        name={name}
                        resolution={resolution}
                        showResolution
                        status={status}
                        variant="h6"
                    />

                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DrawerHeader>

                <InfoAccordion
                    defaultExpanded
                    title={t('Period', { count: 2 })}
                >
                    <Box display="flex" flexDirection="column" gap={1}>
                        <Periods
                            data={report_periods?.[0]?.group_periods}
                            localeDateFormat={localeDateFormat}
                        />
                    </Box>
                </InfoAccordion>

                <InfoAccordion title={t('Electric bill', { count: 2 })}>
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        gap={1}
                        justifyContent="space-between"
                    >
                        <ElectricBills
                            localeDateFormat={localeDateFormat}
                            periods={report_periods?.[0]?.group_periods}
                            showAll
                        />
                    </Box>
                </InfoAccordion>

                <InfoAccordion defaultExpanded title={t('Chart', { count: 2 })}>
                    <Charts
                        currency={currency}
                        reportPeriods={report_periods?.[0]?.group_periods}
                    />
                </InfoAccordion>
            </WrapperContent>

            <Box
                alignItems="center"
                backgroundColor="background.neutral"
                display={is_deleted ? 'none' : 'flex'}
                flexDirection="column"
                height={footerHeight}
                justifyContent="center"
                px={2}
                gap={1}
            >
                <Button
                    color="secondary"
                    component={Link}
                    endIcon={<ArrowForwardIcon />}
                    to={`${redirectConfig.baseUrl}/${id}`}
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        textTransform: 'none',
                        width: '100%',
                    }}
                    variant="outlined"
                >
                    {t(redirectConfig.label)}
                </Button>

                <Button
                    color="secondary"
                    component={Link}
                    to={`/record/${contact?.id}/?tab=projects&project=${project_id}&view=reports`}
                    sx={{ textTransform: 'none', width: '100%' }}
                    variant="text"
                >
                    {t('Go to project file')}
                </Button>
            </Box>
        </Drawer>
    );
};

const mapDispatchToProps = (dispatch) => ({
    fetchReportHistoricals: (reportId) =>
        dispatch(fetchReportHistoricals(reportId)),
});

DetailsDrawer.propTypes = {
    fetchReportHistoricals: PropTypes.func,
    handleClose: PropTypes.func,
    isOpen: PropTypes.bool,
    localeDateFormat: PropTypes.string,
    selectedReport: PropTypes.object,
};

export default connect(null, mapDispatchToProps)(DetailsDrawer);
