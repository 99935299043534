import React, { useEffect, useState } from 'react';

import { Typography } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import FiltersForm from 'common/components/FiltersForm';
import { parseDate } from 'common/utils/dates';
import { getIsMexicanAccount } from 'common/utils/helpers/session';

import { MIN_DATE } from '../../../afterSalesSettings/constants';
import * as credentialIntegrationsSelectors from '../../../credentialIntegrations/selectors';
import * as actions from '../../actions';
import {
    DATE_FORMATS,
    DATE_FREQUENCIES,
    DATE_RANGE_OPTIONS,
    FILTERS_KEYS,
    RATE_ID_FILTERS,
    STATUS_CONFIG,
    STATUS_KEYS,
} from '../../constants';
import { getFilteredSelectOptions } from '../../helpers';
import * as selectors from '../../selectors';
import DotIndicator from '../DotIndicator';

const FormContainer = ({
    agentsForSelect,
    changeFilters,
    contactFilters,
    handleClose,
    initialValues,
    isLoadingContacts,
    isLoadingPoliticalDivisions,
    isLoadingRates,
    politicalDivisionForSelect,
    projectFilters,
    providersForSelect,
    ratesDictionary,
}) => {
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [filteredProjectStatuses, setFilteredProjectStatuses] = useState([]);
    const [filteredRates, setFilteredRates] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [dateValues, setDateValues] = useState({
        finalDate: null,
        initialDate: null,
        timeRange: null,
    });
    const { t } = useTranslation();
    const isMexicanAccount = getIsMexicanAccount();
    const politicalDivisionLabel = isMexicanAccount
        ? t('State')
        : t('Political division of order 1');
    const dateOptions = DATE_RANGE_OPTIONS[dateValues?.timeRange];

    const frequencyOptions = [
        { label: t('Daily'), value: DATE_FREQUENCIES.DAILY },
        { label: t('Monthly'), value: DATE_FREQUENCIES.MONTHLY },
        { label: t('Yearly'), value: DATE_FREQUENCIES.YEARLY },
    ];

    const statusOptions = [
        {
            color: STATUS_CONFIG[STATUS_KEYS.ONLINE].color,
            label: t(STATUS_CONFIG[STATUS_KEYS.ONLINE].label),
            value: `${STATUS_KEYS.ONLINE}`,
        },
        {
            color: STATUS_CONFIG[STATUS_KEYS.UNSTABLE].color,
            label: t(STATUS_CONFIG[STATUS_KEYS.UNSTABLE].label),
            value: `${STATUS_KEYS.UNSTABLE}`,
        },
        {
            color: STATUS_CONFIG[STATUS_KEYS.OFFLINE].color,
            label: t(STATUS_CONFIG[STATUS_KEYS.OFFLINE].label),
            value: `${STATUS_KEYS.OFFLINE}`,
        },
    ];

    const handleFilterProjectOptions = ({
        agentId,
        contactId,
        projectStatusId,
        rateId,
    }) => {
        const {
            contacts,
            projectStatuses,
            projects: newProjects,
            rates,
        } = getFilteredSelectOptions({
            agentId,
            contactId,
            contacts: contactFilters,
            projectStatusId,
            projects: projectFilters,
            rateId,
        });
        setFilteredContacts(contacts);
        setFilteredProjectStatuses(projectStatuses);
        setFilteredProjects(newProjects);
        setFilteredRates(rates);
    };

    useEffect(() => {
        handleFilterProjectOptions({
            agentId: initialValues?.[FILTERS_KEYS.AGENT],
            contactId: initialValues?.[FILTERS_KEYS.CONTACT_ID],
            projectStatusId: initialValues?.[FILTERS_KEYS.PROJECT_STATUS],
            rateId: initialValues?.[FILTERS_KEYS.RATE_ID],
        });
    }, [projectFilters, initialValues]);

    const onChangeDateValue = (key, value) =>
        setDateValues((prev) => ({ ...prev, [key]: value }));

    const onSubmit = (data) => {
        const rateId = data[FILTERS_KEYS.RATE_ID];
        const rateData = ratesDictionary[rateId];

        const newData = { ...data };

        if (!rateData?.is_certified) {
            delete newData[RATE_ID_FILTERS.CERTIFIED];
            newData[RATE_ID_FILTERS.NOT_CERTIFIED] = rateId;
        } else {
            delete newData[RATE_ID_FILTERS.NOT_CERTIFIED];
            newData[RATE_ID_FILTERS.CERTIFIED] = rateId;
        }

        changeFilters(newData);
        handleClose();
    };

    return (
        <FiltersForm
            filters={[
                {
                    label: t('Date'),
                    fields: [
                        {
                            isClearable: false,
                            label: t('Time range'),
                            name: FILTERS_KEYS.FREQ,
                            onChange: ({ value }) =>
                                onChangeDateValue('timeRange', value),
                            options: [{ label: '', options: frequencyOptions }],
                            type: 'select',
                        },
                        {
                            format: dateOptions?.format,
                            label: t('Initial date'),
                            maxDate:
                                dateValues?.finalDate &&
                                parseDate(
                                    dateValues.finalDate,
                                    DATE_FORMATS.SUNWISE,
                                ),
                            minDate: MIN_DATE,
                            name: FILTERS_KEYS.INITIAL_DATE,
                            onChange: ({ value }) =>
                                onChangeDateValue('initialDate', value),
                            type: 'date',
                            views: dateOptions?.views,
                            visible: !!dateOptions,
                        },
                        {
                            format: dateOptions?.format,
                            label: t('Final date'),
                            minDate: dateValues?.initialDate
                                ? parseDate(
                                      dateValues.initialDate,
                                      DATE_FORMATS.SUNWISE,
                                  )
                                : MIN_DATE,
                            name: FILTERS_KEYS.FINAL_DATE,
                            onChange: ({ value }) =>
                                onChangeDateValue('finalDate', value),
                            type: 'date',
                            views: dateOptions?.views,
                            visible: !!dateOptions,
                        },
                    ],
                },
                {
                    label: t('Project'),
                    fields: [
                        {
                            disabled: isLoadingContacts,
                            label: t('Agent'),
                            name: FILTERS_KEYS.AGENT,
                            onChange: ({ setValue, value }) => {
                                handleFilterProjectOptions({
                                    agentId: value,
                                });
                                setValue(FILTERS_KEYS.CONTACT_ID, null);
                                setValue(FILTERS_KEYS.PROJECT_STATUS, null);
                                setValue(FILTERS_KEYS.RATE_ID, null);
                                setValue(FILTERS_KEYS.PROJECT_ID, null);
                            },
                            options: agentsForSelect,
                            type: 'select',
                        },
                        {
                            disabled: isLoadingContacts,
                            label: t('Contact'),
                            name: FILTERS_KEYS.CONTACT_ID,
                            onChange: ({ getValues, setValue, value }) => {
                                handleFilterProjectOptions({
                                    agentId: getValues(FILTERS_KEYS.AGENT),
                                    contactId: value,
                                });
                                setValue(FILTERS_KEYS.PROJECT_STATUS, null);
                                setValue(FILTERS_KEYS.PROJECT_ID, null);
                                setValue(FILTERS_KEYS.RATE_ID, null);
                            },
                            options: filteredContacts,
                            type: 'select',
                        },
                        {
                            disabled: isLoadingContacts,
                            label: t('Project status'),
                            name: FILTERS_KEYS.PROJECT_STATUS,
                            onChange: ({ getValues, setValue, value }) => {
                                handleFilterProjectOptions({
                                    agentId: getValues(FILTERS_KEYS.AGENT),
                                    contactId: getValues(
                                        FILTERS_KEYS.CONTACT_ID,
                                    ),
                                    projectStatusId: value,
                                });
                                setValue(FILTERS_KEYS.PROJECT_ID, null);
                                setValue(FILTERS_KEYS.RATE_ID, null);
                            },
                            options: filteredProjectStatuses,
                            type: 'select',
                        },
                        {
                            disabled: isLoadingRates,
                            label: t('Rate'),
                            name: FILTERS_KEYS.RATE_ID,
                            onChange: ({ getValues, setValue, value }) => {
                                handleFilterProjectOptions({
                                    agentId: getValues(FILTERS_KEYS.AGENT),
                                    contactId: getValues(
                                        FILTERS_KEYS.CONTACT_ID,
                                    ),
                                    projectStatusId: getValues(
                                        FILTERS_KEYS.PROJECT_STATUS,
                                    ),
                                    rateId: value,
                                });
                                setValue(FILTERS_KEYS.PROJECT_ID, null);
                            },
                            options: filteredRates,
                            type: 'select',
                        },
                        {
                            disabled: isLoadingContacts,
                            label: t('Project'),
                            name: FILTERS_KEYS.PROJECT_ID,
                            options: filteredProjects,
                            type: 'select',
                        },
                    ],
                },
                {
                    label: t('Location'),
                    fields: [
                        {
                            disabled: isLoadingPoliticalDivisions,
                            label: politicalDivisionLabel,
                            name: FILTERS_KEYS.POLITICAL_DIVISION,
                            options: politicalDivisionForSelect,
                            type: 'select',
                        },
                        {
                            label: t('Zip code'),
                            name: FILTERS_KEYS.ZIP_CODE,
                            type: 'input',
                        },
                    ],
                },
                {
                    label: t('System', { count: 2 }),
                    fields: [
                        {
                            label: t('Status'),
                            name: FILTERS_KEYS.STATUS,
                            renderOption: (option) => (
                                <>
                                    <DotIndicator color={option.color} />
                                    <Typography variant="body2" sx={{ ml: 1 }}>
                                        {option.label}
                                    </Typography>
                                </>
                            ),
                            options: [{ label: '', options: statusOptions }],
                            type: 'select',
                        },
                        {
                            label: t('Provider'),
                            name: FILTERS_KEYS.PROVIDER,
                            options: [
                                { label: '', options: providersForSelect },
                            ],
                            type: 'select',
                        },
                    ],
                },
            ]}
            initialValues={initialValues}
            onCancel={handleClose}
            onResetValues={(values) =>
                setDateValues({
                    finalDate: values?.[FILTERS_KEYS.FINAL_DATE],
                    initialDate: values?.[FILTERS_KEYS.INITIAL_DATE],
                    timeRange: values?.[FILTERS_KEYS.FREQ],
                })
            }
            onSubmit={onSubmit}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    agentsForSelect: selectors.getAgentsForSelect,
    contactFilters: selectors.getContacts,
    initialValues: selectors.getInitialValuesFilters,
    isLoadingContacts: selectors.getContactsIsLoading,
    isLoadingPoliticalDivisions: selectors.getPoliticalDivisionIsLoading,
    isLoadingRates: selectors.getRatesIsLoading,
    politicalDivisionForSelect: selectors.getPoliticalDivisionForSelect,
    projectFilters: selectors.getProjects,
    providersForSelect: credentialIntegrationsSelectors.getProvidersForSelect,
    ratesDictionary: selectors.getRatesDictionary,
});

const mapDispatchToProps = (dispatch) => ({
    changeFilters: (filters) => dispatch(actions.changeFilters(filters)),
});

FormContainer.propTypes = {
    agentsForSelect: PropTypes.array,
    changeFilters: PropTypes.func,
    contactFilters: PropTypes.array,
    handleClose: PropTypes.func,
    initialValues: PropTypes.object,
    isLoadingContacts: PropTypes.bool,
    isLoadingPoliticalDivisions: PropTypes.bool,
    isLoadingRates: PropTypes.bool,
    politicalDivisionForSelect: PropTypes.array,
    projectFilters: PropTypes.array,
    providersForSelect: PropTypes.array,
    ratesDictionary: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
