import API from '../..';

const config = { headers: { 'Content-Type': 'application/json' } };
const ENTITY = 'sunpay';

export const addAlliance = (data) =>
    API.post(`/api/v1/${ENTITY}/alliances/`, data);
export const addAllianceProduct = (data) =>
    API.post(`/api/v1/${ENTITY}/financier-installer-product/`, data);
export const approveChangesOncredit = (commercialOfferId) =>
    API.put(`/api/v1/approve-financier-proposal/${commercialOfferId}`);
export const bulkActivateItems = (data) =>
    API.put(`/api/v1/${ENTITY}/financial-product/active-bulk/`, data, config);
export const bulkFinancialPlans = (data) =>
    API.put(
        `/api/v1/${ENTITY}/financial-plans/bulk-status-flags/`,
        data,
        config,
    );
export const cancelCreditApp = (creditAppId) =>
    API.put(`/api/v1/${ENTITY}/credit-application/${creditAppId}/cancel/`);
export const checkProposalEditStatus = (proposalId, data = {}) =>
    API.put(`/api/v1/${ENTITY}/edit-offer/${proposalId}`, data);
export const createCreditProposalItem = (data) =>
    API.post(`/api/v1/${ENTITY}/financing/`, data);
export const deleteAlliance = (id) =>
    API.delete(`/api/v1/${ENTITY}/financing/${id}/`);
export const fetchRequirements = (id) =>
    API.get(`/api/v1/${ENTITY}/alliance-document-requirement/${id}`);
export const filterFinancialPlans = ({
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    searchText = '',
    sortBy = null,
    status = 'active',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/financial-plans/`, {
        params: {
            order_by: orderBy,
            page: page,
            per_page: perPage,
            search: searchText,
            ...(searchText ? { search: searchText } : {}),
            sort: sortBy,
            status_flag: status,
        },
    });
export const filterFinancierProducts = ({
    agentId = null,
    orderBy = null,
    page = 1,
    perPage = 25,
    searchText = '',
    sortBy = null,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/financier-product/`, {
        params: {
            agent: agentId,
            order_by: orderBy,
            page: page,
            per_page: perPage,
            search: searchText,
            sort: sortBy,
        },
    });
export const getAlliances = () => API.get(`/api/v1/${ENTITY}/alliances/`);
export const getCatalogRequirements = (id) =>
    API.get(`/api/v1/${ENTITY}/alliance/catalog/requirements/${id}`);
export const getCreditAppDocuments = (creditAppId) =>
    API.get(`/api/v1/${ENTITY}/credit-application-documents/${creditAppId}`);
export const getFinancialPlan = (id) =>
    API.get(`/api/v1/${ENTITY}/financial-plans/${id}/`);
export const getFinancials = () => API.get(`/api/v1/${ENTITY}/financials/`);
export const getFinancierProductByInstaller = (
    commercialOfferId,
    installerId,
) =>
    API.get(
        `/api/v1/${ENTITY}/financier-product/${commercialOfferId}/${installerId}`,
    );
export const getFinancierProductWarningsByInstaller = (
    commercialOfferId,
    installerId,
) =>
    API.get(
        `/api/v1/${ENTITY}/financier-product-warning/${commercialOfferId}/${installerId}`,
    );
export const getFinarcierInstallerData = (id) =>
    API.get(`/api/v1/${ENTITY}/financiers-installer/${id}`);
export const getFinarciers = () => API.get(`/api/v1/${ENTITY}/financiers/`);
export const getPrecalificationInfonavitData = (proposalId, data) =>
    API.put(`/api/v1/${ENTITY}/seide/evalute/${proposalId}`, data);
export const refreshFinancing = (creditId) =>
    API.put(`/api/v1/${ENTITY}/financing-refresh/${creditId}/`);
export const saveCompanyFinancialProduct = (data) =>
    API.post(`/api/v1/${ENTITY}/products/company-financier-product/`, data);
export const saveCreditAppDocument = (creditAppId, data) =>
    API.put(
        `/api/v1/${ENTITY}/credit-application-documents/${creditAppId}`,
        data,
    );
export const saveFinancierInstallerDocument = (
    financierInstallerDocumentId,
    data,
) =>
    API.put(
        `/api/v1/${ENTITY}/financier-installer-documents/${financierInstallerDocumentId}`,
        data,
    );
export const saveFinarciersInstaller = (data) =>
    API.post(`/api/v1/${ENTITY}/financiers-installer/`, data);
export const simulateCredit = (data) =>
    API.post(`/api/v1/${ENTITY}/credit-simulation/`, data, config);
export const toggleFinancierProductEnable = (id) =>
    API.put(`/api/v1/${ENTITY}/financier-product/${id}/change-enabled/`);
export const updateFinancing = (data, id) =>
    API.patch(`/api/v1/${ENTITY}/financing/${id}/`, data, config);
export const updateCompanyFinancialProduct = (data) =>
    API.put(
        `/api/v1/${ENTITY}/products/company-financier-product/${data.id}`,
        data,
    );
export const updateCreditApplicationStatus = (commercial_offer) =>
    API.put(`/api/v1/${ENTITY}/credit-application/${commercial_offer}`);
