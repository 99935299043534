import { NAME } from './constants';

export const DELETE_DATA = `${NAME}/DELETE_DATA`;
export const DELETE_DATA_FAILURE = `${NAME}/DELETE_DATA_FAILURE`;
export const DELETE_DATA_SUCCESS = `${NAME}/DELETE_DATA_SUCCESS`;

export const FETCH_DEFAULT_LOCATION = `${NAME}/FETCH_DEFAULT_LOCATION`;
export const FETCH_DEFAULT_LOCATION_FAILURE = `${NAME}/FETCH_DEFAULT_LOCATION_FAILURE`;
export const FETCH_DEFAULT_LOCATION_SUCCESS = `${NAME}/FETCH_DEFAULT_LOCATION_SUCCESS`;

export const FETCH_EXTERNAL_SOURCES = `${NAME}/FETCH_EXTERNAL_SOURCES`;
export const FETCH_EXTERNAL_SOURCES_FAILURE = `${NAME}/FETCH_EXTERNAL_SOURCES_FAILURE`;
export const FETCH_EXTERNAL_SOURCES_SUCCESS = `${NAME}/FETCH_EXTERNAL_SOURCES_SUCCESS`;

export const FETCH_POWER_STATIONS = `${NAME}/FETCH_POWER_STATIONS`;
export const FETCH_POWER_STATIONS_FAILURE = `${NAME}/FETCH_POWER_STATIONS_FAILURE`;
export const FETCH_POWER_STATIONS_SUCCESS = `${NAME}/FETCH_POWER_STATIONS_SUCCESS`;

export const INITIALIZE_API_SOURCE_FORM = `${NAME}/INITIALIZE_API_SOURCE_FORM`;
export const INITIALIZE_EXTERNAL_SOURCE_FORM = `${NAME}/INITIALIZE_EXTERNAL_SOURCE_FORM`;
export const INITIALIZE_SETTINGS_FORM = `${NAME}/INITIALIZE_SETTINGS_FORM`;
export const INITIALIZE_SOURCE_FIELDS_FORM = `${NAME}/INITIALIZE_SOURCE_FIELDS_FORM`;

export const RESET = `${NAME}/RESET`;
export const RESET_API_SOURCE_FORM = `${NAME}/RESET_API_SOURCE_FORM`;
export const RESET_EXTERNAL_SOURCE_FORM = `${NAME}/RESET_EXTERNAL_SOURCE_FORM`;
export const RESET_SETTINGS_FORM = `${NAME}/RESET_SETTINGS_FORM`;
export const RESET_SOURCE_FIELDS_FORM = `${NAME}/RESET_SOURCE_FIELDS_FORM`;

export const SAVE_DATA = `${NAME}/SAVE_DATA`;
export const SAVE_DATA_FAILURE = `${NAME}/SAVE_DATA_FAILURE`;
export const SAVE_DATA_SUCCESS = `${NAME}/SAVE_DATA_SUCCESS`;

export const SET_API_SOURCE_FORM_MODAL_OPEN_STATUS = `${NAME}/SET_MODAL_FORM_OPEN_STATUS`;
export const SET_EXTERNAL_SOURCE_MODAL_FORM_OPEN_STATUS = `${NAME}/SET_EXTERNAL_MODAL_FORM_OPEN_STATUS`;
export const SET_SETTINGS_FORM_MODAL_OPEN_STATUS = `${NAME}/SET_SETTINGS_FORM_MODAL_OPEN_STATUS`;
export const SET_SOURCE_FIELDS_MODAL_FORM_OPEN_STATUS = `${NAME}/SET_SOURCE_FIELDS_MODAL_FORM_OPEN_STATUS`;
