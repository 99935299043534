import { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import { DEBOUNCE_TIME_500 } from 'common/constants';
import { useDebounce } from 'common/hooks';

const Debouncer = ({
    control,
    debounceTime = DEBOUNCE_TIME_500,
    setFormValues,
}) => {
    const formValues = useWatch({ control });
    const debouncedFormValues = useDebounce(formValues, debounceTime);

    useEffect(() => setFormValues(debouncedFormValues), [debouncedFormValues]);

    return null;
};

Debouncer.propTypes = {
    control: PropTypes.object,
    debounceTime: PropTypes.number,
    setFormValues: PropTypes.func,
};

export default Debouncer;
