import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Grid } from 'sunwise-ui';

import { ReactHookFormInput } from 'common/components/form/bootstrap';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import duplicateFormValidate from '../duplicateFormValidate';
import * as selectors from '../selectors';

const FormModal = ({
    duplicateOffer,
    handleClickCloseModal,
    initialValues,
    isDuplicatingOffer,
}) => {
    const { t } = useTranslation();

    const { control, handleSubmit } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(duplicateFormValidate),
    });

    const handleOnSubmit = (values) => duplicateOffer(values);

    return (
        <form onSubmit={handleSubmit(handleOnSubmit)}>
            <Grid container mt={1}>
                <Grid size={{ xs: 'grow' }}>
                    <ReactHookFormInput
                        control={control}
                        label={t('Proposal name').concat(' *')}
                        name="name"
                    />
                </Grid>
            </Grid>
            <Grid container mt={1}>
                <Grid
                    item
                    xs
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: { xs: 'center', md: 'right' },
                    }}
                >
                    <Button
                        color="secondary"
                        onClick={handleClickCloseModal}
                        sx={{
                            width: { xs: '100%', md: 'auto' },
                            order: { xs: 2, md: 1 },
                            mr: { xs: 0, md: 2 },
                        }}
                        variant="text"
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        disabled={isDuplicatingOffer}
                        sx={{
                            width: { xs: '100%', md: 'auto' },
                            order: { xs: 1, md: 2 },
                            mb: { xs: 2, md: 0 },
                        }}
                        type="submit"
                        variant="outlined"
                    >
                        {isDuplicatingOffer
                            ? t('Saving').concat('...')
                            : t('Continue')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getDuplicateFormInitialValues,
    isDuplicatingOffer: selectors.getIsDuplicatingOffer,
});

const mapDispatchToProps = (dispatch) => ({
    duplicateOffer: (values) => dispatch(actions.duplicateOffer(values)),
});

FormModal.propTypes = {
    duplicateOffer: PropTypes.func,
    handleClickCloseModal: PropTypes.func,
    initialValues: PropTypes.object,
    isDuplicatingOffer: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormModal);
