import i18next from 'i18next';

import { dictionaryToArray } from 'common/utils/helpers';

import { SAVE_INVERTERS_FAILURE, SAVE_PANELS_FAILURE } from '../actionTypes';
import { AFTERSALES_ONBOARDING_STEPS, ONBOARDING_STEPS } from '../constants';
import { getIsLockedInverterStep, getIsLockedPanelStep } from '../helpers';
import { onboardingWizardActions } from '../reducer';

import finishOnboarding from './finishOnboarding';
import goToCompany from './goToCompany';
import saveBatteries from './saveBatteries';
import saveCompany from './saveCompany';
import saveDetails from './saveDetails';
import saveInverters from './saveInverters';
import saveMembers from './saveMembers';
import savePanels from './savePanels';

export default (isAftersales, values, callback) => (dispatch) => {
    const {
        inverter_brand: inverterBrand,
        inverters,
        panel_brand: panelBrand,
        panels,
        step,
    } = values;

    if (getIsLockedPanelStep(panelBrand, panels, step)) {
        dispatch(
            onboardingWizardActions[SAVE_PANELS_FAILURE]([
                i18next.t('You must select at least one product'),
            ]),
        );
        return false;
    }
    if (getIsLockedInverterStep(inverterBrand, inverters, step)) {
        dispatch(
            onboardingWizardActions[SAVE_INVERTERS_FAILURE]([
                i18next.t('You must select at least one product'),
            ]),
        );
        return false;
    }
    const arraySteps = dictionaryToArray(
        isAftersales ? AFTERSALES_ONBOARDING_STEPS : ONBOARDING_STEPS,
    );
    switch (arraySteps[step]) {
        case ONBOARDING_STEPS.REGISTER:
            dispatch(goToCompany(step, callback));
            break;
        case ONBOARDING_STEPS.COMPANY:
            dispatch(saveCompany(values, callback));
            break;
        case ONBOARDING_STEPS.DETAILS:
            dispatch(saveDetails(isAftersales, values, callback));
            break;
        case ONBOARDING_STEPS.MEMBERS:
            dispatch(saveMembers(values, callback));
            break;
        case ONBOARDING_STEPS.PANELS:
            dispatch(savePanels(values, callback));
            break;
        case ONBOARDING_STEPS.INVERTERS:
            dispatch(saveInverters(values, callback));
            break;
        case ONBOARDING_STEPS.BATTERIES:
            dispatch(saveBatteries(values, callback));
            break;
        case ONBOARDING_STEPS.FINISH:
            dispatch(finishOnboarding(isAftersales, values));
            break;
        default:
            break;
    }
};
