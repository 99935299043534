import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from 'sunwise-ui';

import { handleCloseDrawer } from '../helpers';

const SideMenuControls = ({
    cancelUpdating,
    canModify,
    editionDisabled,
    initializeUpdating,
    isUpdatingCustomTemplate,
    onUpdateOfferEnded,
    setEditionDisabled,
    statusPrint,
    swipeableDrawerRef,
}) => {
    const { t } = useTranslation();
    const handlerEditButton = () => {
        if (!editionDisabled) {
            setEditionDisabled(true);
            onUpdateOfferEnded();
        } else {
            setEditionDisabled(false);
            initializeUpdating();
        }
        handleCloseDrawer(swipeableDrawerRef);
    };

    const getEditButtonText = () => {
        if (!editionDisabled) return t('Save');
        if (isUpdatingCustomTemplate) return t('Saving').concat('...');
        return t('Edit PDF');
    };

    return (
        <Grid container>
            <Grid
                size={{ xs: 'grow' }}
                sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 1 }}
            >
                {!editionDisabled && (
                    <Button
                        disabled={
                            !canModify ||
                            isUpdatingCustomTemplate ||
                            statusPrint
                        }
                        fullWidth
                        onClick={() => handlerEditButton()}
                        variant="outlined"
                    >
                        {getEditButtonText()}
                    </Button>
                )}

                {!editionDisabled && (
                    <Button
                        color="secondary"
                        fullWidth
                        onClick={() => {
                            cancelUpdating();
                            setEditionDisabled(true);
                            handleCloseDrawer(swipeableDrawerRef);
                        }}
                        variant="text"
                    >
                        {t('Cancel')}
                    </Button>
                )}
            </Grid>
        </Grid>
    );
};

SideMenuControls.propTypes = {
    cancelUpdating: PropTypes.func,
    canModify: PropTypes.bool,
    editionDisabled: PropTypes.bool,
    initializeUpdating: PropTypes.func,
    isUpdatingCustomTemplate: PropTypes.bool,
    onUpdateOfferEnded: PropTypes.func,
    setEditionDisabled: PropTypes.func,
    statusPrint: PropTypes.bool,
    swipeableDrawerRef: PropTypes.object,
};

export default SideMenuControls;
