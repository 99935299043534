import React, { useEffect, useState } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Grid, Typography } from 'sunwise-ui';

import { ShowErrors } from 'common/components';
import {
    ReactHookFormInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import MainBranchOfficeSelectorRHF from 'common/modules/multiBranches/components/MainBranchOfficeSelectorRHF';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import {
    getCatalogFields,
    getCommercialOffersToSelect,
    getTemplatesToSelect,
} from '../helpers';
import * as selectors from '../selectors';
import validate from '../validate';

import ReactHookFormArrayItems from './ReduxFieldArrayItems';

const FormModal = ({
    catalogs,
    commercialOffers,
    errors,
    initialValues,
    isLocked,
    isSaving,
    projectId,
    save,
    templates,
}) => {
    const { t } = useTranslation();
    const [selectedOffers, setSelectedOffers] = useState([]);

    const { control, handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const customOfferTemplate = watch('custom_offer_template');

    const handleClickSave = (values) => save(values, projectId);

    const isDisabledButton = isLocked || isSaving;

    const offersToSelect = getCommercialOffersToSelect(
        commercialOffers,
        selectedOffers,
    );

    const onChange = ({ target: { value } }) => {
        const template = templatesToSelect.find((item) => item.value === value);
        if (template) {
            setValue('proposalsNumber', template.proposalsNumber);
            const catalogItems = getCatalogFields(
                catalogs,
                template.proposalsNumber,
            );
            setValue('linked_proposals', catalogItems);
            for (let index = 0; index < catalogItems.length; index++) {
                setValue(`linked_proposals.${index}.commercial_offer`, '');
            }
        }
    };

    const templatesToSelect = getTemplatesToSelect(templates);

    return (
        <form>
            <Grid size={{ xs: 'grow' }}>
                <ReactHookFormInput
                    control={control}
                    disabled={isLocked}
                    label={t('Name')}
                    name="name"
                />

                <ReactHookFormSelect
                    control={control}
                    disabled={isLocked}
                    label={t('Summary template')}
                    name="custom_offer_template"
                    onChange={onChange}
                    options={templatesToSelect}
                />

                {!isEmpty(customOfferTemplate) && (
                    <Typography variant="body2" fontWeight="bold" mb={2}>
                        {t('Link proposal', { count: 2 })}
                    </Typography>
                )}

                <ReactHookFormArrayItems
                    control={control}
                    disabled={isLocked}
                    name="linked_proposals"
                    offersToSelect={offersToSelect}
                    setSelectedOffers={setSelectedOffers}
                />

                <MainBranchOfficeSelectorRHF
                    control={control}
                    hideCompany
                    label={t('Branch office')}
                    name="branch_office"
                />
            </Grid>

            <Grid container mb={2}>
                <Grid>
                    <ShowErrors errors={errors} />
                </Grid>
            </Grid>

            <Grid container>
                <Grid size={{ xs: 18 }} textAlign="right">
                    <Button
                        disabled={isDisabledButton}
                        endIcon={<ArrowForwardIcon />}
                        onClick={handleSubmit(handleClickSave)}
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                        type="button"
                        variant="outlined"
                    >
                        {isSaving ? t('Generating') : t('Generate preview')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};
const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSaveItem,
    initialValues: selectors.getInitialValues,
    isSaving: selectors.getIsSavingItem,
});

const mapDispatchToProps = (dispatch) => ({
    save: (values, projectId) => dispatch(actions.save(values, projectId)),
});

FormModal.propTypes = {
    catalogs: PropTypes.array,
    commercialOffers: PropTypes.array,
    errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    initialValues: PropTypes.object,
    isLocked: PropTypes.bool,
    isSaving: PropTypes.bool,
    projectId: PropTypes.string,
    save: PropTypes.func,
    templates: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormModal);
