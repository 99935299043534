import React, { useContext, useState } from 'react';

import PropTypes from 'prop-types';

import { LoadingContext } from 'common/utils/contexts';

import LinkedProposalsCard from './LinkedProposalsCard';
import ProposalCard from './ProposalCard';
import ShareCard from './ShareCard';
import SideMenuControls from './SideMenuControls';

const SideMenu = ({
    cancelUpdating,
    canModify,
    editionDisabled,
    fetchPdfFile,
    handleOnPrepareRegenerateTemplate,
    handlerEditButton,
    initializeUpdating,
    isUpdatingCustomTemplate,
    linkedProposals,
    match,
    offerId,
    permalink,
    setEditionDisabled,
    swipeableDrawerRef,
    templateName,
    templateType,
    updateTemplate,
}) => {
    const format = localStorage.getItem('summaryDownloadFormat') || '2';
    const [downloadFormat, setDownloadFormat] = useState(format);
    const [statusPrint, setStatusPrint] = useState(false);

    const loadingContext = useContext(LoadingContext);

    const onRenderPdf = () => {
        setStatusPrint(true);
        fetchPdfFile(
            match.params.uid,
            templateName,
            downloadFormat,
            (text) => loadingContext.openLoading(text),
            () => {
                loadingContext.closeLoading();
                setStatusPrint(false);
            },
            templateType,
        );
    };

    return (
        <>
            <SideMenuControls
                cancelUpdating={cancelUpdating}
                canModify={canModify}
                editionDisabled={editionDisabled}
                initializeUpdating={initializeUpdating}
                isUpdatingCustomTemplate={isUpdatingCustomTemplate}
                onUpdateOfferEnded={() =>
                    updateTemplate(match.params.uid, templateType)
                }
                setEditionDisabled={setEditionDisabled}
                statusPrint={statusPrint}
                swipeableDrawerRef={swipeableDrawerRef}
            />

            <ShareCard
                downloadFormat={downloadFormat}
                handleClickDownload={() => {
                    if (!statusPrint && editionDisabled) onRenderPdf();
                }}
                isDisabled={!editionDisabled}
                permalink={permalink}
                setDownloadFormat={setDownloadFormat}
                isEnablePermalink={true}
            />

            <ProposalCard
                handleClickEditButton={handlerEditButton}
                handleOnPrepareRegenerateTemplate={
                    handleOnPrepareRegenerateTemplate
                }
                isDisabled={editionDisabled}
                proposalId={offerId}
                proposalName={templateName}
                selectedDocumentId={offerId}
            />

            <LinkedProposalsCard
                linkedProposals={linkedProposals}
                isDisabled={editionDisabled}
            />
        </>
    );
};

SideMenu.propTypes = {
    cancelUpdating: PropTypes.func,
    canModify: PropTypes.bool,
    editionDisabled: PropTypes.bool,
    fetchPdfFile: PropTypes.func,
    handleOnPrepareRegenerateTemplate: PropTypes.func,
    handlerEditButton: PropTypes.func,
    initializeUpdating: PropTypes.func,
    isUpdatingCustomTemplate: PropTypes.bool,
    linkedProposals: PropTypes.array,
    match: PropTypes.object,
    offerId: PropTypes.string,
    permalink: PropTypes.string,
    setEditionDisabled: PropTypes.func,
    swipeableDrawerRef: PropTypes.object,
    templateName: PropTypes.string,
    templateType: PropTypes.number,
    updateTemplate: PropTypes.func,
};

export default SideMenu;
