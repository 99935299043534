import get from 'lodash/get';

import { getOnboarding } from 'common/api/v1/onboarding';

import * as profileSelectors from '../../profile/selectors';
import {
    FETCH_ONBOARDING,
    FETCH_ONBOARDING_FAILURE,
    FETCH_ONBOARDING_SUCCESS,
} from '../actionTypes';
import { getStepKey } from '../helpers';
import { onboardingWizardActions } from '../reducer';
import * as selectors from '../selectors';

import initializeForm from './initializeForm';
import setCurrentStepBySection from './setCurrentStepBySection';

export default (updateStep = false) =>
    (dispatch, getState) => {
        const currentStep = selectors.getCurentStep(getState());
        const subscription = profileSelectors.getActiveSubscription(getState());
        const productType = get(subscription, 'product_type', null);
        const isAftersales = productType === 'aftersales';

        dispatch(onboardingWizardActions[FETCH_ONBOARDING]());

        getOnboarding()
            .then((response) => {
                dispatch(
                    onboardingWizardActions[FETCH_ONBOARDING_SUCCESS](
                        response.data,
                    ),
                );
                if (updateStep) {
                    dispatch(initializeForm(response.data));
                    dispatch(
                        setCurrentStepBySection(
                            get(response.data, 'section', 'register'),
                        ),
                    );
                } else
                    dispatch(
                        initializeForm({
                            ...response.data,
                            section: getStepKey(currentStep, isAftersales),
                        }),
                    );
            })
            .catch((error) => {
                dispatch(
                    onboardingWizardActions[FETCH_ONBOARDING_FAILURE](
                        error?.response?.data?.errors,
                    ),
                );
            });
    };
