import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Grid } from 'sunwise-ui';

const FooterButtons = ({
    handleClickAcceptTerms,
    handleClickCancel,
    handleClickSave,
    isSaving,
    requireAccepTerms,
    selectedFinancierId,
}) => {
    const { t } = useTranslation();
    const commonSxProps = {
        width: { xs: '100%', md: 'auto' },
        order: { xs: 1, md: 2 }, // Cambia el orden para versiones responsivas
    };
    const renderSuccessButton = () => {
        if (requireAccepTerms)
            return (
                <Button onClick={handleClickAcceptTerms} sx={commonSxProps}>
                    {t('Accept and simulate')}
                </Button>
            );
        return (
            <Button
                disabled={!selectedFinancierId}
                onClick={() => handleClickSave()}
                sx={commonSxProps}
            >
                {isSaving ? t('Simulate').concat('...') : t('Simulate')}
            </Button>
        );
    };
    return (
        <Box mt={2}>
            <Divider sx={{ my: 2 }} />
            <Grid container>
                <Grid
                    size={{ xs: 'grow' }}
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: 'flex-end', // Asegura que los botones estén alineados a la derecha
                        gap: { xs: 2, md: 2 }, // Espaciado entre botones
                    }}
                >
                    <Button
                        color="secondary"
                        onClick={() => handleClickCancel()}
                        sx={{
                            width: { xs: '100%', md: 'auto' },
                            order: { xs: 2, md: 1 }, // Cambia el orden para versiones responsivas
                        }}
                        variant="text"
                    >
                        {requireAccepTerms ? t('Back') : t('Cancel')}
                    </Button>
                    {renderSuccessButton()}
                </Grid>
            </Grid>
        </Box>
    );
};

FooterButtons.propTypes = {
    handleClickAcceptTerms: PropTypes.func,
    handleClickCancel: PropTypes.func,
    handleClickSave: PropTypes.func,
    isSaving: PropTypes.bool,
    requireAccepTerms: PropTypes.bool,
    selectedFinancierId: PropTypes.string,
};

export default FooterButtons;
