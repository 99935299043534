import { regenerate } from 'common/api/v1/proposalsSummary';

import fetchCustomTemplate from './fetchCustomTemplate';

export default (values) => (dispatch) => {
    const { initTemplate, offerId, resetTemplate, templateType } = values;

    if (!initTemplate || !initTemplate || !templateType || !resetTemplate)
        return;

    regenerate(offerId).then(() => {
        dispatch(resetTemplate());
        dispatch(fetchCustomTemplate(offerId, initTemplate, templateType));
    });
};
