import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, Typography } from 'sunwise-ui';

import { MONTHLY_FREQUENCY, PLAN_TYPES } from 'common/constants';
import { getTranslatedAddonLabel, removeWords } from 'common/utils/helpers';

import { getAddonList } from '../helpers';

const PricingAftersalesDetails = ({
    buttonProps,
    onClick,
    pricing,
    variant,
}) => {
    const { t } = useTranslation();
    const planType = get(pricing, 'plan_type.key', 0);
    const isMonthly = pricing.frequency === MONTHLY_FREQUENCY;
    const isPro = planType === PLAN_TYPES.PRO; // Nuevo
    const isScale = planType === PLAN_TYPES.SCALE;
    const priceFrom = get(pricing, 'price_from', 0);
    const { disabled = false, text = 'Select', visible = true } = buttonProps;

    const renderIncludedText = () => {
        if (isMonthly)
            return t('Includes {{number}} reports per month', {
                number: pricing?.credits?.reports,
            });
        else
            return t('Includes {{number}} reports per year', {
                number: pricing?.credits?.reports,
            });
    };

    const renderButton = () => {
        // Nuevo
        if (text === t('Contact us'))
            return (
                <Button
                    fullWidth
                    href="https://www.sunwise.io/schedule"
                    target="_blank"
                >
                    {t('Contact us')}
                </Button>
            );
        return (
            <Button
                disabled={disabled}
                fullWidth
                onClick={() => onClick(pricing.chargebee_price_id)}
            >
                {text}
            </Button>
        );
    };

    return (
        <Card>
            <Card.Header
                sx={{
                    display: 'flex',
                    backgroundColor:
                        variant === 'secondary' ? '#011e2e' : 'primary.main',
                    color: variant === 'secondary' ? '#fff' : 'inherit',
                    border: 0,
                }}
            >
                <Typography variant="h3">
                    {removeWords(pricing.plan_name)}
                </Typography>
            </Card.Header>
            <Card.Body>
                <Box sx={{ height: 350 }}>
                    <Typography variant="h3">{priceFrom}</Typography>
                    <Typography fontWeight={500} mt={1} variant="body1">
                        {isMonthly
                            ? t('Invoiced monthly')
                            : t('Invoiced annually')}
                    </Typography>
                    <Typography fontWeight={500} my={1} variant="body1">
                        {renderIncludedText()}
                    </Typography>
                    <Typography fontWeight={500} variant="caption">
                        {t('Additional reports are charged per use')}
                    </Typography>
                    {isPro && ( // Nuevo
                        <Typography mb={2} mt={3} variant="body1">
                            {t('Essentials for solar after-sales')}:
                        </Typography>
                    )}
                    {isScale && (
                        <Typography mb={2} mt={3} variant="body1">
                            {t('Everything in the Pro plan and more')}:
                        </Typography>
                    )}
                    <Box display="flex" flexDirection="column" mt={3}>
                        {getAddonList(planType, 'aftersales').map((addon) => (
                            <Box
                                alignItems="center"
                                display="flex"
                                gap={1}
                                key={addon}
                                mb={1}
                                mt={1}
                            >
                                <CheckCircleIcon fontSize="small" />
                                <Typography
                                    fontWeight={500} // Ajustado
                                    variant="body1" // Ajustado
                                    key={addon}
                                >
                                    {getTranslatedAddonLabel(addon)}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box sx={{ minHeight: 40 }} mt={2}>
                    {visible && renderButton()}
                </Box>
            </Card.Body>
        </Card>
    );
};

PricingAftersalesDetails.propTypes = {
    buttonProps: PropTypes.object,
    onClick: PropTypes.func,
    pricing: PropTypes.object,
    variant: PropTypes.string,
};

export default PricingAftersalesDetails;
