import React, { useEffect } from 'react';

import { Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as actions from '../actions';
import * as selectors from '../selectors';

import EmptyState from './EmptyState';
import ItemsList from './ItemsList';

const CardContent = ({
    canModify,
    fetchCustomerEnergiesConsumptions,
    fetchData,
    isLoading,
    isLoadingCustomerConsumption,
    prepareCreate,
    selectedProjectId,
}) => {
    useEffect(() => {
        if (selectedProjectId)
            fetchCustomerEnergiesConsumptions(selectedProjectId);
    }, [selectedProjectId]);

    if (isLoading)
        return <Skeleton variant="rounded" width="100%" height={300} />;

    return (
        <>
            {fetchData ? (
                <ItemsList canModify={canModify} item={fetchData} />
            ) : (
                <EmptyState
                    canModify={canModify}
                    disabled={isLoadingCustomerConsumption}
                    handleOnClick={prepareCreate}
                />
            )}
        </>
    );
};

CardContent.propTypes = {
    canModify: PropTypes.bool,
    fetchCustomerEnergiesConsumptions: PropTypes.func,
    fetchData: PropTypes.object,
    isLoading: PropTypes.bool,
    isLoadingCustomerConsumption: PropTypes.bool,
    prepareCreate: PropTypes.func,
    selectedProjectId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    fetchData: selectors.getFetchDataData,
    isLoading: selectors.getFetchDataIsLoading,
    isLoadingCustomerConsumption: selectors.getCustomerConsumptionIsLoading,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCustomerEnergiesConsumptions: (projectId) =>
        dispatch(actions.fetchCustomerEnergiesConsumptions(projectId)),
    prepareCreate: () => dispatch(actions.prepareCreate()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardContent);
