import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Skeleton, Typography } from 'sunwise-ui';

import EmptyState from 'common/components/EmptyState';

import EmptyProjects from 'resources/EmptyProjects.png';

import ProjectCardItem from './ProjectCardItem';
import ProjectListItem from './ProjectListItem';
import ProjectResponsiveItem from './ProjectResponsiveItem';

const ProjectsList = ({
    callback,
    canDelete,
    canModify,
    filterData,
    handleClickArchive,
    handleClickRename,
    handleOpenProject,
    isExpanded,
    isLoading,
    listModeEnabled,
    prepareFormCreate,
    projects,
    projectsIds,
    renderExtraButton,
    selectedProject,
    selectedProjectId,
}) => {
    const { t } = useTranslation();

    const getProjectData = (project) => {
        if (!project) return null;

        const {
            created_at,
            created_by,
            id,
            name,
            proposals_numbers,
            rate,
            status_flag,
            status_project,
        } = project;

        return {
            canDelete,
            canModify,
            createdBy: created_by
                ? `${created_by.first_name} ${created_by.last_name}`
                : 'N/D',
            date: created_at,
            documents: proposals_numbers,
            handleClickArchive: (current = 'archived') =>
                handleClickArchive(project, current, callback),
            handleClickRename: () => handleClickRename(filterData, project),
            handleClickView: () => handleOpenProject(id),
            id,
            isArchived: status_flag === 'archived',
            isSelected: isExpanded && selectedProjectId === id,
            rate: rate?.name || '',
            statusProject: status_project,
            title: name,
        };
    };

    if (isLoading)
        return (
            <Skeleton
                variant="rounded"
                sx={{ height: { xs: 30, md: 50 }, my: 2, width: '100%' }}
            />
        );

    if (projects.length <= 0) {
        if (filterData?.status === 'archived' || filterData?.searchText)
            return (
                <Typography variant="body2" sx={{ my: 2 }}>
                    {t('No projects were found')}
                </Typography>
            );
        return (
            <Box sx={{ height: '500px' }}>
                <EmptyState
                    canModify={canModify}
                    caption={t(
                        'Create a new project and store all related proposals in it',
                    )}
                    classNameCardBody="__intercom_add_project_button"
                    dataIntercomTarget="Add Project (Button)"
                    onClick={prepareFormCreate}
                    srcImage={EmptyProjects}
                    sxCard={{ border: 0, boxShadow: 'none' }}
                    textButton={t('Create')}
                    title={t('There are no projects yet')}
                />
            </Box>
        );
    }

    if (
        !isExpanded &&
        selectedProjectId &&
        projectsIds.includes(selectedProjectId)
    ) {
        const projectData = getProjectData(selectedProject);

        return (
            <>
                <ProjectListItem {...projectData} />

                <ProjectResponsiveItem {...projectData} />

                {renderExtraButton?.()}
            </>
        );
    }

    return (
        <>
            <Grid container>
                {projects.map((project) => {
                    const projectData = getProjectData(project);

                    return (
                        <Grid
                            key={project.id}
                            size={{
                                lg: listModeEnabled ? 18 : 6,
                                md: listModeEnabled ? 18 : 9,
                                xl: listModeEnabled ? 18 : 4.5,
                                xs: 18,
                            }}
                        >
                            <ProjectResponsiveItem
                                canModify={canModify}
                                {...projectData}
                            />

                            {listModeEnabled ? (
                                <ProjectListItem {...projectData} />
                            ) : (
                                <ProjectCardItem {...projectData} />
                            )}
                        </Grid>
                    );
                })}
            </Grid>

            {renderExtraButton?.()}
        </>
    );
};

ProjectsList.propTypes = {
    callback: PropTypes.func,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    filterData: PropTypes.object,
    filteredProjects: PropTypes.array,
    handleClickArchive: PropTypes.func,
    handleClickRename: PropTypes.func,
    handleOpenProject: PropTypes.func,
    isExpanded: PropTypes.bool,
    isLoading: PropTypes.bool,
    listModeEnabled: PropTypes.bool,
    prepareFormCreate: PropTypes.func,
    projects: PropTypes.array,
    projectsIds: PropTypes.array,
    renderExtraButton: PropTypes.func,
    selectedProject: PropTypes.object,
    selectedProjectId: PropTypes.string,
};

export default ProjectsList;
