import { addRpu } from 'common/modules/zap/actions';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    ADD_ZAP_RPU,
    ADD_ZAP_RPU_FAILURE,
    ADD_ZAP_RPU_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (values, successCallback) => (dispatch) => {
    if (!values) return;

    dispatch(actions[ADD_ZAP_RPU]());

    const newValues = {
        cfeUser: values.cfe_user,
        connectSocket: false,
        credentialId: values.credential_id,
        fetchPeriods: false,
        lastPayment: values.last_payment,
        serviceNumber: values.service_number,
    };

    dispatch(addRpu(newValues))
        .then((data) => {
            const rpuData = {
                cfe_user: data?.cfe_user,
                credential_id: data?.credential_id,
                last_payment: data?.last_payment,
                rpu_id: data?.id,
                service_number: data?.service_number,
            };

            dispatch(actions[ADD_ZAP_RPU_SUCCESS](rpuData));

            if (successCallback) successCallback(rpuData);
        })
        .catch((error) => {
            dispatch(actions[ADD_ZAP_RPU_FAILURE](error));

            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
