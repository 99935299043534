import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getContactsData = createSelector(
    getModel,
    (model) => model.contacts.data || {},
);

export const getProjectsData = createSelector(
    getModel,
    (model) => model.projects.data || {},
);

export const getProposalsData = createSelector(
    getModel,
    (model) => model.proposals.data || {},
);

export const getReportsData = createSelector(
    getModel,
    (model) => model.reports.data || {},
);

export const getSummariesData = createSelector(
    getModel,
    (model) => model.summaries.data || {},
);

export const getRecentSearches = createSelector(
    getModel,
    (model) => [...model.recentSearches].reverse() || [],
);
