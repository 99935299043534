import API from '../..';

const ENTITY = 'device-based-consumptions';

export const getDeviceBasedConsumptions = (projectId) =>
    API.get(`/api/v1/${ENTITY}/${projectId}`);

export const saveDeviceBasedConsumptions = (projectId, data) =>
    API.post(`/api/v1/${ENTITY}/${projectId}`, data, {
        headers: { 'Content-Type': 'application/json' },
    });

export const updateDeviceBasedConsumptions = (projectId, data) =>
    API.put(`/api/v1/${ENTITY}/${projectId}`, data, {
        headers: { 'Content-Type': 'application/json' },
    });
