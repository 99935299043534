import React from 'react';

import PropTypes from 'prop-types';
import { Grid, Typography } from 'sunwise-ui';

const EnergyDistribution = ({ hourlyDistribution = [] }) => {
    return (
        <Grid
            columns={24}
            container
            gap={0}
            m={0}
            spacing={0}
            textAlign="center"
            width="100%"
        >
            {hourlyDistribution?.map((item, index) => (
                <Grid
                    key={index}
                    size={1}
                    sx={{
                        backgroundColor: item.color,
                        '&.MuiGrid-root': { p: 1 },
                    }}
                >
                    <Typography variant="h6">{item?.value}</Typography>
                </Grid>
            ))}
        </Grid>
    );
};

EnergyDistribution.propTypes = {
    hourlyDistribution: PropTypes.array,
};

export default EnergyDistribution;
