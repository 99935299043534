import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import { ReactHookFormInputDatePicker } from 'common/components/form/bootstrap';

import * as companyGeneralSelectors from '../../../../companyGeneral/selectors';
import { STAGGERED_RATE_TYPE } from '../../../constants';
import * as selectors from '../../../selectors';

import FieldArrayConcepts from './FieldArrayConcepts';

const Container = styled(Box)`
    display: flex;
    flex-direction: column;
`;

const DatesContainer = styled(Box)`
    display: flex;
    margin-top: 42px;
    margin-bottom: 31px;
`;

const Period = ({
    contractedDemandUnitsDataToSelect,
    control,
    countryCurrencySymbol,
    demandUnitsDataToSelect,
    energyUnitsDataToSelect,
    excessDemandUnitsDataToSelect,
    getValues,
    hasActiveProjects,
    isAnnualType,
    isCompanyFromSpain,
    isHourlyRate,
    name,
    othersUnitsDataToSelect,
    paymentType,
    setValue,
    showMode,
}) => {
    const { t } = useTranslation();
    return (
        <Container>
            {!isAnnualType && (
                <DatesContainer>
                    <ReactHookFormInputDatePicker
                        control={control}
                        format="dd/MMMM"
                        label={t('Start date')}
                        name={`${name}.date_start`}
                    />

                    <ReactHookFormInputDatePicker
                        control={control}
                        format="dd/MMMM"
                        label={t('Final date')}
                        name={`${name}.date_end`}
                    />
                </DatesContainer>
            )}

            <FieldArrayConcepts
                control={control}
                countryCurrencySymbol={countryCurrencySymbol}
                disableActions={hasActiveProjects}
                getValues={getValues}
                isEnergyConcept
                isHourlyRate={isHourlyRate}
                linkedConceptName={`${name}.demand_concepts.concepts`}
                name={`${name}.energy_concepts.concepts`}
                periodFieldName={name}
                setValue={setValue}
                showMode={showMode}
                title={`${t('Energy charges')} (kWh)`}
                topValueFieldEnabled={paymentType === STAGGERED_RATE_TYPE}
                unitItems={energyUnitsDataToSelect}
            />

            <FieldArrayConcepts
                control={control}
                countryCurrencySymbol={countryCurrencySymbol}
                disableActions={hasActiveProjects}
                getValues={getValues}
                isHourlyRate={isHourlyRate}
                linkedConceptParentName={`${name}.energy_concepts.concepts`}
                name={`${name}.demand_concepts.concepts`}
                periodFieldName={name}
                setValue={setValue}
                showMode={showMode}
                title={`${t('Measured demand charges')} (kW)`}
                unitItems={demandUnitsDataToSelect}
            />

            {isHourlyRate && (
                <FieldArrayConcepts
                    control={control}
                    countryCurrencySymbol={countryCurrencySymbol}
                    disableActions={hasActiveProjects}
                    getValues={getValues}
                    isContractedDemandConcept
                    isHourlyRate={isHourlyRate}
                    linkedConceptName={
                        isCompanyFromSpain
                            ? `${name}.excess_demand_concepts.concepts`
                            : null
                    }
                    name={`${name}.contracted_demand_concepts.concepts`}
                    periodFieldName={name}
                    setValue={setValue}
                    showMode={showMode}
                    title={`${t('Contracted demand charges')} (kW)`}
                    unitItems={contractedDemandUnitsDataToSelect}
                />
            )}

            {isHourlyRate && isCompanyFromSpain && (
                <FieldArrayConcepts
                    control={control}
                    countryCurrencySymbol={countryCurrencySymbol}
                    disableActions={hasActiveProjects}
                    getValues={getValues}
                    isHourlyRate={isHourlyRate}
                    linkedConceptParentName={`${name}.contracted_demand_concepts.concepts`}
                    name={`${name}.excess_demand_concepts.concepts`}
                    periodFieldName={name}
                    setValue={setValue}
                    showMode={showMode}
                    title={`${t('Excess demand charges')} (kW)`}
                    unitItems={excessDemandUnitsDataToSelect}
                />
            )}

            <FieldArrayConcepts
                control={control}
                countryCurrencySymbol={countryCurrencySymbol}
                getValues={getValues}
                isConceptsOtherChargesTaxes
                name={`${name}.other_concepts.concepts`}
                periodFieldName={name}
                setValue={setValue}
                showMode={showMode}
                title={t('Other charges')}
                unitItems={othersUnitsDataToSelect}
            />

            <FieldArrayConcepts
                control={control}
                countryCurrencySymbol={countryCurrencySymbol}
                getValues={getValues}
                isConceptsOtherChargesTaxes
                name={`${name}.taxes_concepts.concepts`}
                periodFieldName={name}
                setValue={setValue}
                showMode={showMode}
                title={t('Tax', { count: 2 })}
                unitItems={othersUnitsDataToSelect}
            />
        </Container>
    );
};

const mapStateToProps = createStructuredSelector({
    contractedDemandUnitsDataToSelect:
        selectors.getContractedDemandUnitsDataToSelect,
    demandUnitsDataToSelect: selectors.getDemandUnitsDataToSelect,
    energyUnitsDataToSelect: selectors.getEnergyUnitsDataToSelect,
    excessDemandUnitsDataToSelect:
        selectors.getSpainExcessDemandUnitsDataToSelect,
    hasActiveProjects: selectors.getHasActiveProjects,
    isCompanyFromSpain: companyGeneralSelectors.getCompanyIsFromSpain,
    othersUnitsDataToSelect: selectors.getOthersUnitsDataToSelect,
});

Period.propTypes = {
    contractedDemandUnitsDataToSelect: PropTypes.array,
    control: PropTypes.object,
    countryCurrencySymbol: PropTypes.string,
    demandUnitsDataToSelect: PropTypes.array,
    energyUnitsDataToSelect: PropTypes.array,
    excessDemandUnitsDataToSelect: PropTypes.array,
    getValues: PropTypes.func,
    hasActiveProjects: PropTypes.bool,
    isAnnualType: PropTypes.bool,
    isCompanyFromSpain: PropTypes.bool,
    isHourlyRate: PropTypes.bool,
    name: PropTypes.string,
    othersUnitsDataToSelect: PropTypes.array,
    paymentType: PropTypes.string,
    setValue: PropTypes.func,
    showMode: PropTypes.bool,
};

export default connect(mapStateToProps)(Period);
