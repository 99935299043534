import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';

import { save } from 'common/api/v2/masterButton';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import { buildPayload as buildContactPayload } from '../../../../modules/contactForm/helpers';
import fetchUserSettings from '../../../../modules/profile/actions/fetchProfileData';
import * as projectActions from '../../../../modules/project/actions';
import * as projectConsumptionsModalActions from '../../../../modules/projectConsumptionsModal/actions';
import { buildPayload as buildCunsumptionsPayload } from '../../../../modules/projectConsumptionsModal/helpers';
import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { actions } from '../reducer';

export default (values, callback, redirect = true) =>
    (dispatch, getState) => {
        const state = getState();

        const { contact, consumptions, project } = values;

        const contactNewValues = contact?.id
            ? contact
            : {
                  ...buildContactPayload(contact),
                  emails: contact.emails,
                  telephones: contact.telephones,
              };

        const projectNewValues = project?.id
            ? { id: project.id }
            : {
                  name: project.name,
                  type: 'without_consumption',
              };

        let consumption = null;

        if (!isEmpty(consumptions)) {
            consumption = buildCunsumptionsPayload({
                selectedProject: project,
                state,
                values: consumptions,
            });
            projectNewValues.type = 'add_consumption';
        }

        const newValues = {
            action: 'create-project',
            consumption,
            contact: contactNewValues,
            project: projectNewValues,
        };

        dispatch(actions[SAVE]());

        save(newValues)
            .then((response) => {
                dispatch(actions[SAVE_SUCCESS](response.data));
                dispatch(fetchUserSettings({ forceLoading: false }));
                dispatch(
                    projectConsumptionsModalActions.selectProject(
                        response.data,
                    ),
                );

                if (callback) callback(response.data);

                if (redirect) {
                    showToast();
                    dispatch(projectConsumptionsModalActions.resetForm());

                    const { contact, project } = values;
                    const { id } = response.data;

                    const contactId =
                        project.type === 'without_consumption'
                            ? response.data.contact.id
                            : contact.id;

                    dispatch(projectActions.fetchProjects({ contactId }));

                    dispatch(
                        push(
                            `/record/${contactId}?tab=projects&project=${
                                project.id || id
                            }&view=proposals&type=proposals`,
                        ),
                    );

                    return;
                }
            })
            .catch((error) => {
                dispatch(actions[SAVE_FAILURE](error?.response?.data?.errors));
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
