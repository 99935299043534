import { fetchRequirements } from 'common/api/v1/sunpay';

import {
    FETCH_REQUIREMENTS,
    FETCH_REQUIREMENTS_FAILURE,
    FETCH_REQUIREMENTS_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorAddAllianceActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(proposalGeneratorAddAllianceActions[FETCH_REQUIREMENTS]());

    fetchRequirements(id)
        .then((response) =>
            dispatch(
                proposalGeneratorAddAllianceActions[FETCH_REQUIREMENTS_SUCCESS](
                    response.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                proposalGeneratorAddAllianceActions[FETCH_REQUIREMENTS_FAILURE](
                    error,
                ),
            ),
        );
};
