import React from 'react';

import ArticleIcon from '@mui/icons-material/Article';
import { useTheme } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { types } from 'sunwise-template-core';
import { List, ListItem, ListItemButton, ListItemText } from 'sunwise-ui';

import SideMenuCard from '../../multiTemplateReview/components/SideMenuCard';

const ProposalCard = ({
    financing,
    handleClickDocumentItem,
    isDisabled,
    proposalId,
    proposalName,
    selectedDocumentId,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    const getTitle = () => {
        if (!isEmpty(financing)) return `${t('Proposal')} - EPC`;
        return t('Proposal');
    };

    return (
        <SideMenuCard
            icon={<ArticleIcon sx={{ color: 'primary.main' }} />}
            title={getTitle()}
        >
            <List disablePadding>
                <ListItem
                    disabled={isDisabled || proposalId === selectedDocumentId}
                    disablePadding
                >
                    <ListItemButton
                        onClick={() =>
                            handleClickDocumentItem({
                                id: proposalId,
                                name: proposalName,
                                type: types.ONE_PROPOSAL_TYPE,
                            })
                        }
                        sx={{
                            borderRadius: '8px',
                            backgroundColor:
                                proposalId === selectedDocumentId
                                    ? isDarkMode
                                        ? '#000'
                                        : 'rgba(31, 60, 83, 0.13)'
                                    : 'transparent',
                            '&.Mui-selected': {
                                backgroundColor: isDarkMode
                                    ? '#000'
                                    : 'rgba(31, 60, 83, 0.13) !important',
                            },
                        }}
                    >
                        <ListItemText
                            primary={proposalName}
                            sx={{
                                '.MuiListItemText-primary': {
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                },
                            }}
                        />
                    </ListItemButton>
                </ListItem>
            </List>
        </SideMenuCard>
    );
};

ProposalCard.propTypes = {
    financing: PropTypes.object,
    handleClickDocumentItem: PropTypes.func,
    isDisabled: PropTypes.bool,
    proposalId: PropTypes.string,
    proposalName: PropTypes.string,
    selectedDocumentId: PropTypes.string,
};

export default ProposalCard;
