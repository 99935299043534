import { get, isEmpty } from 'lodash';
import { createSelector } from 'reselect';

import { SUBSCRIPTION_STATUS } from 'common/constants';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);

// SAVE PROFILE DATA
export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (save) => save.isSaving);

export const getDataSave = createSelector(getSave, (save) => save.data);

export const getErrorsSave = createSelector(getSave, (save) => save.errors);

// GET PROFILE DATA
export const getFetch = createSelector(getModel, (model) => model.fetch);

export const getIsFetching = createSelector(
    getFetch,
    (fetch) => fetch.isFetching,
);

export const getDataFetch = createSelector(
    getFetch,
    (fetch) => fetch.data || {},
);

export const getErrorsFetch = createSelector(getFetch, (fetch) => fetch.errors);

export const getUserId = createSelector(getDataFetch, (data) => data.id || {});

export const getUserCompany = createSelector(
    getDataFetch,
    (data) => data?.company,
);

export const getUserCompanyCountry = createSelector(
    getUserCompany,
    (company) => company?.country_name,
);

export const getUserCompanyId = createSelector(
    getUserCompany,
    (company) => company?.id,
);

// ACTIVE USERS
export const getActiveUsers = createSelector(
    getDataFetch,
    (data) => data?.active_users,
);

// ADDONS
export const getAddons = createSelector(
    getDataFetch,
    (data) => data?.addons || [],
);

// BRANDING
export const getBranding = createSelector(getModel, (model) => model.branding);

export const getCountryCurrencyFromProfile = createSelector(
    getDataFetch,
    (data) => get(data, 'currency_company_locale', {}),
);

// PROFILE VALUES
export const getCurrentValues = createSelector(
    getModel,
    (model) => model.currentValues,
);

// THEME
export const getTheme = createSelector(getModel, (model) => model.theme);

// PREFERENCES
export const getPreferences = createSelector(
    getModel,
    (model) => model.preferences,
);

// SUBSCRIPTIONS
export const getSubscriptions = createSelector(
    getDataFetch,
    (data) => data?.subscriptions || [],
);

export const getIsSalesSubscribed = createSelector(
    getDataFetch,
    (data) => data?.is_sales_subscribed,
);

export const getIsAftersalesSubscribed = createSelector(
    getDataFetch,
    (data) => data?.is_aftersales_subscribed,
);

export const getActiveSubscription = createSelector(
    getSubscriptions,
    (data) => {
        const filtered = data.filter(
            (sub) =>
                get(sub, 'due_invoices_count', 0) === 0 &&
                get(sub, 'status.key', null) !== SUBSCRIPTION_STATUS.CANCELLED,
        );
        if (isEmpty(filtered)) return {};
        return filtered[0];
    },
);

export const getMainSubscription = createSelector(
    getSubscriptions,
    (data) => data[0] || {},
);

export const getSubscriptionVersion = createSelector(
    getMainSubscription,
    (data) => data?.version || 'v4',
);

export const getAfterSalesSubscription = createSelector(
    getSubscriptions,
    (data) => data.find((sub) => sub.product_type === 'aftersales'),
);

export const getAfterSalesSubscriptionVersion = createSelector(
    getAfterSalesSubscription,
    (data) => data?.version,
);
