import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid } from 'sunwise-ui';

import {
    ChipProposalStatus,
    CollapsedBreadcrumbs,
    ProposalStatusIcon,
    ReadOnlyAlert,
} from 'common/components';
import { COMMERCIAL_OFFER_STATUS } from 'common/constants/commercialOffer';
import { getStatusProposalLabel } from 'common/utils/helpers';

import TitlePreview from './TitlePreview';

const HeaderPreview = ({
    breadcrumbLocations,
    currentBreadcrumb,
    isApproved,
    isReadOnly,
    offerStatus,
}) => {
    const { t } = useTranslation();
    const isGenerated = offerStatus === COMMERCIAL_OFFER_STATUS.FINISHED.key;

    return (
        <Box>
            <Grid
                container
                spacing={0}
                sx={{ alignItems: 'center', p: '16px 16px 8px 16px' }}
            >
                <Grid size={{ lg: 15, md: 14, xs: 13 }}>
                    <TitlePreview name={t('Preview')} />
                </Grid>
                <Grid size={{ lg: 3, md: 4, xs: 5 }} textAlign="right">
                    <ChipProposalStatus
                        icon={
                            <ProposalStatusIcon
                                isApproved={isApproved}
                                isGenerated={isGenerated}
                            />
                        }
                        label={getStatusProposalLabel(isApproved, isGenerated)}
                    />
                </Grid>
                <Grid size={18} sx={{ pl: { md: '40px' } }}>
                    <CollapsedBreadcrumbs
                        current={currentBreadcrumb}
                        locations={breadcrumbLocations}
                    />
                </Grid>
            </Grid>

            <ReadOnlyAlert isReadOnly={isReadOnly} />

            <Divider sx={{ mb: 0 }} />
        </Box>
    );
};

HeaderPreview.propTypes = {
    breadcrumbLocations: PropTypes.array,
    currentBreadcrumb: PropTypes.string,
    isApproved: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    offerStatus: PropTypes.number,
};

export default HeaderPreview;
