export const NAME = 'multi-panel-layout';
export const DEFAULT_ZOOM = 18;
export const DELTA = 0.005;
export const orientations = {
    P: 'Vertical',
    L: 'Horizontal',
};
export const POLYGON_SEGMENT = 0;
export const POLYGON_OBSTACLE = 1;
export const POLYGON_MAP = 2;

export const timeZones = [
    { label: 'Hora Estándar de las Islas Baker', value: -12 },
    { label: 'Hora Estándar de Niue', value: -11 },
    { label: 'Hora Estándar de Hawái-Aleutianas', value: -10 },
    { label: 'Hora Estándar de Alaska', value: -9 },
    { label: 'Hora Estándar del Pacífico', value: -8 },
    { label: 'Hora Estándar de las Montañas Rocosas', value: -7 },
    { label: 'Hora Estándar Central', value: -6 },
    { label: 'Hora Estándar del Este', value: -5 },
    { label: 'Hora Estándar del Atlántico', value: -4 },
    { label: 'Hora Estándar de Argentina', value: -3 },
    {
        label: 'Hora Estándar de las Islas Georgias del Sur y Sandwich del Sur',
        value: -2,
    },
    { label: 'Azores Time', value: -1 },
    { label: 'Tiempo Medio de Greenwich', value: 0 },
    { label: 'Hora de Europa Central', value: 1 },
    { label: 'Hora de Europa del Este', value: 2 },
    { label: 'Hora de Moscú', value: 3 },
    { label: 'Hora Estándar de Azerbaiyán', value: 4 },
    { label: 'Hora Estándar de Uzbekistán', value: 5 },
    { label: 'Hora Estándar de Kazajistán', value: 6 },
    { label: 'Hora de Indochina', value: 7 },
    { label: 'Hora Estándar de China', value: 8 },
    { label: 'Hora Estándar de Japón', value: 9 },
    { label: 'Hora Estándar de Australia Oriental', value: 10 },
    { label: 'Hora Estándar de Vanuatu', value: 11 },
    { label: 'Hora Estándar de Nueva Zelanda', value: 12 },
];

export const binaryPalette = ['212121', 'B3E5FC'];
export const rainbowPalette = [
    '3949AB',
    '81D4FA',
    '66BB6A',
    'FFE082',
    'E53935',
];
export const ironPalette = ['00000A', '91009C', 'E64616', 'FEB400', 'FFFFF6'];
export const sunlightPalette = ['212121', 'FFCA28'];
export const panelsPalette = ['E8EAF6', '1A237E'];
