import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';

import { PREFIX_OCR } from 'common/sockets/constants';
import { generateSocketActions } from 'common/sockets/helpers';

import {
    GET_LISA_FILE,
    GET_LISA_FILE_FAILURE,
    GET_LISA_FILE_SUCCESS,
    RESET,
    SET_SHOW_LISA_LOADING,
    UPLOAD_FILES,
    UPLOAD_FILES_FAILURE,
    UPLOAD_FILES_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    fetchFile: { error: null, loading: false },
    showLisaLoading: false,
    socketOcr: { connected: false, error: null, files: {} },
    uploadFiles: { error: null, loading: false },
};

const {
    BROKEN_SOCKET,
    CLOSED_SOCKET,
    ERROR_SOCKET,
    MESSAGE_SOCKET,
    OPEN_SOCKET,
    RECONNECTED,
} = generateSocketActions(PREFIX_OCR);

const lisaFilesSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [GET_LISA_FILE]: (state) => {
            state.fetchFile.loading = true;
            state.fetchFile.error = null;
        },
        [GET_LISA_FILE_FAILURE]: (state, action) => {
            state.fetchFile.error = action.payload;
            state.fetchFile.loading = false;
        },
        [GET_LISA_FILE_SUCCESS]: (state) => {
            state.fetchFile.loading = false;
        },
        [RESET]: () => INITIAL_STATE,
        [SET_SHOW_LISA_LOADING]: (state, action) => {
            state.socketOcr.files = {};
            state.showLisaLoading = action.payload;
        },
        [UPLOAD_FILES]: (state) => {
            state.uploadFiles.loading = true;
            state.uploadFiles.error = null;
        },
        [UPLOAD_FILES_FAILURE]: (state, action) => {
            state.uploadFiles.error = action.payload;
            state.uploadFiles.loading = false;
        },
        [UPLOAD_FILES_SUCCESS]: (state) => {
            state.uploadFiles.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(BROKEN_SOCKET, (state) => {
                state.socketOcr.error = i18next.t(
                    'Error sending data. Restart your session or try again later',
                );
            })
            .addCase(ERROR_SOCKET, (state) => {
                state.socketOcr.error = i18next.t(
                    'Server error. Try again later',
                );
                state.showLisaLoading = false;
            })
            .addCase(RECONNECTED, (state) => {
                state.socketOcr.error = null;
            })
            .addCase(CLOSED_SOCKET, (state) => {
                state.socketOcr.connected = false;
            })
            .addCase(OPEN_SOCKET, (state) => {
                state.socketOcr.connected = true;
            })
            .addCase(MESSAGE_SOCKET, (state, action) => {
                const message = action?.payload?.message;

                if (!message) {
                    state.socketOcr.socketError = i18next.t(
                        'Server error. Try again later',
                    );
                    return;
                }

                const parsedMessage = JSON.parse(message);

                if (
                    parsedMessage?.stage !== -1 &&
                    parsedMessage?.stage <
                        state.socketOcr.files?.[parsedMessage.document]?.stage
                )
                    return;

                state.socketOcr.files[parsedMessage.document] = parsedMessage;
            });
    },
});

export const lisaFilesActions = lisaFilesSlice.actions;

export default lisaFilesSlice.reducer;
