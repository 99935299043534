import React from 'react';

import { COLUMN_TYPES, CSVImporter } from '@sunwisesoftware/csv-importer';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getCsvImporterTranslations } from 'common/utils/helpers/csv';
import { getIsoCodeCurrentLocale } from 'common/utils/helpers/multiregion';

import * as actions from '../../actions';
import { DEMAND_CONCEPTS, ENERGY_CONCEPTS } from '../../constants';
import { validateCsvUploadColumn } from '../../helpers';
import * as selectors from '../../selectors';

import FormBottomActions from './FormBottomActions';
import UploadFile from './UploadFile';

const UploadStep = ({
    configCsvImporter,
    control,
    disabled,
    getValues,
    handleClickBack,
    handleClickNext,
    handleCloseImportModal,
    readOnly,
    selectedProject,
    uploadCsv,
    uploadFile,
}) => {
    const { t } = useTranslation();

    const handleFileUpload = (files) => {
        uploadFile({ files, getValues, project: selectedProject });
    };

    return (
        <>
            <UploadFile
                control={control}
                disabled={disabled}
                name="file"
                onChange={handleFileUpload}
            />

            {!disabled && (
                <CSVImporter
                    columns={[
                        {
                            columnName: 'date',
                            displayLabel: t('Date'),
                            matchAliases: [
                                'date',
                                'fecha y hora',
                                'fecha',
                                'timestamp',
                            ],
                            required: true,
                            shouldFormat: false,
                            type: COLUMN_TYPES.DATE,
                        },
                        {
                            columnName: 'time',
                            displayLabel: t('Time'),
                            matchAliases: ['hora', 'time'],
                            shouldFormat: false,
                            type: COLUMN_TYPES.TIME,
                            visible: (_, columnsFormat) =>
                                columnsFormat?.date &&
                                !columnsFormat.date.includes('HH'),
                        },
                        {
                            columnName: ENERGY_CONCEPTS.GENERATION,
                            displayLabel: `${t('Total generation')} (kWh)`,
                            matchAliases: [
                                'generacion',
                                'generación (kW)',
                                'generation',
                                'solar',
                            ],
                            type: COLUMN_TYPES.NUMBER,
                            validate: validateCsvUploadColumn,
                        },
                        {
                            columnName: ENERGY_CONCEPTS.EXPORTED_ENERGY,
                            displayLabel: `${t(
                                'Exported solar generation',
                            )} (kWh)`,
                            matchAliases: [
                                'exported',
                                'exportación',
                                'exportacion',
                                'exported energy',
                                'exported generation',
                                'exported solar generation',
                            ],
                            type: COLUMN_TYPES.NUMBER,
                            validate: validateCsvUploadColumn,
                        },
                        {
                            columnName: ENERGY_CONCEPTS.SELF_CONSUMPTION,
                            displayLabel: `${t('Self-consumption')} (kWh)`,
                            matchAliases: [
                                'autoconsumo',
                                'self_consumed',
                                'self_consumed_energy',
                                'self_consumption',
                                'selfconsumed',
                                'selfconsumption',
                            ],
                            type: COLUMN_TYPES.NUMBER,
                            validate: validateCsvUploadColumn,
                        },
                        {
                            columnName: ENERGY_CONCEPTS.GRID_CONSUMPTION,
                            displayLabel: `${t('Consumption from Grid')} (kWh)`,
                            matchAliases: [
                                'consumo de red',
                                'consumo red',
                                'grid consumption',
                                'grid',
                                'red',
                            ],
                            type: COLUMN_TYPES.NUMBER,
                            validate: validateCsvUploadColumn,
                        },
                        {
                            columnName: ENERGY_CONCEPTS.CONSUMPTION,
                            displayLabel: `${t('Total consumption')} (kWh)`,
                            matchAliases: [
                                'consumo total',
                                'consumo',
                                'consumption',
                                'energy',
                                'kwh',
                            ],
                            type: COLUMN_TYPES.NUMBER,
                            validate: validateCsvUploadColumn,
                        },
                        {
                            columnName: DEMAND_CONCEPTS.MEASURED,
                            displayLabel: `${t('Demand')} (kW)`,
                            matchAliases: ['demand', 'demanda', 'kw', 'power'],
                            type: COLUMN_TYPES.NUMBER,
                            validate: validateCsvUploadColumn,
                        },
                    ]}
                    initialFile={configCsvImporter?.file}
                    locale={getIsoCodeCurrentLocale()}
                    onCancel={handleCloseImportModal}
                    onImport={(result, data) =>
                        uploadCsv({ data, getValues, result })
                    }
                    openModal={configCsvImporter?.isOpen}
                    returnFormattedData
                    textOverrides={getCsvImporterTranslations()}
                    title={t('Import CSV')}
                    visible={false}
                />
            )}

            <FormBottomActions
                backText={t('Cancel')}
                disabled={disabled && !readOnly}
                handleClickBack={handleClickBack}
                handleClickNext={handleClickNext}
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    configCsvImporter: selectors.getConfigCsvImportModal,
});

const mapDispatchToProps = (dispatch) => ({
    uploadCsv: (params) => dispatch(actions.uploadCsv(params)),
    uploadFile: (params) => dispatch(actions.uploadFile(params)),
});

UploadStep.propTypes = {
    configCsvImporter: PropTypes.object,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    getValues: PropTypes.func,
    handleClickBack: PropTypes.func,
    handleClickNext: PropTypes.func,
    handleCloseImportModal: PropTypes.func,
    readOnly: PropTypes.bool,
    selectedProject: PropTypes.object,
    uploadCsv: PropTypes.func,
    uploadFile: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadStep);
