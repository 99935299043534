import { push } from 'connected-react-router';
import { get, isEmpty } from 'lodash';

import { getSegments } from 'common/api/v2/offerDesign';

import {
    FETCH_PARENT_FIELD_SEGMENT,
    FETCH_PARENT_FIELD_SEGMENT_FAILURE,
    FETCH_PARENT_FIELD_SEGMENT_SUCCESS,
} from '../actionTypes';
import { getParentFieldSegments } from '../helpers';
import { panelLayoutV2Actions } from '../reducer';

import fetchCachedTIFF from './fetchCachedTIFF';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(panelLayoutV2Actions[FETCH_PARENT_FIELD_SEGMENT]());

        getSegments(id)
            .then((response) => {
                const parentFieldSegment = get(
                    response,
                    'data.data.parent_field_segments[0]',
                    {},
                );

                if (isEmpty(parentFieldSegment)) {
                    dispatch(push(`/proposal/${id}`));
                    return false;
                }

                const {
                    address,
                    id,
                    latitude,
                    longitude,
                    solar_modules_design_number,
                    solar_modules_offer_number,
                } = parentFieldSegment;

                const segments = getParentFieldSegments(parentFieldSegment, id);

                dispatch(
                    panelLayoutV2Actions[FETCH_PARENT_FIELD_SEGMENT_SUCCESS]({
                        id,
                        location: { address, latitude, longitude },
                        segments,
                        solarModulesDesignNumber: solar_modules_design_number,
                        solarModulesOfferNumber: solar_modules_offer_number,
                    }),
                );

                dispatch(fetchCachedTIFF(segments));
            })
            .catch((error) => {
                dispatch(
                    panelLayoutV2Actions[FETCH_PARENT_FIELD_SEGMENT_FAILURE](
                        error?.response?.data?.errors,
                    ),
                );
            })
            .finally(resolve);
    });
