import React, { useState } from 'react';

import { Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getDateFormatByLocale } from 'common/utils/helpers/multiregion';

import * as actions from '../actions';
import { VIEWS } from '../constants';
import * as selectors from '../selectors';

import DetailsDrawer from './DetailsDrawer';
import ReportColumns from './ReportColumns';
import ReportTable from './ReportTable';

const Content = ({
    fetchReports,
    filterQuery,
    isLoadingFilters,
    reportsData,
    view,
}) => {
    const [isOpenDetailsDrawer, setIsOpenDetailsDrawer] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);

    if (isLoadingFilters)
        return <Skeleton variant="rounded" width="100%" height="100%" />;

    const localeDateFormat = getDateFormatByLocale();

    const onCloseDetailsDrawer = () => {
        setIsOpenDetailsDrawer(false);
        setSelectedReport(null);
    };

    const onSelectReport = (report) => {
        setSelectedReport(report);
        setIsOpenDetailsDrawer(true);
    };

    return (
        <>
            {view === VIEWS.FUNNEL && (
                <ReportColumns
                    data={reportsData}
                    fetchReports={fetchReports}
                    filterQuery={filterQuery}
                    localeDateFormat={localeDateFormat}
                    onSelectReport={onSelectReport}
                />
            )}

            {view === VIEWS.LIST && (
                <ReportTable
                    data={reportsData}
                    fetchReports={fetchReports}
                    filterQuery={filterQuery}
                    localeDateFormat={localeDateFormat}
                    onSelectReport={onSelectReport}
                />
            )}

            <DetailsDrawer
                handleClose={onCloseDetailsDrawer}
                isOpen={isOpenDetailsDrawer}
                localeDateFormat={localeDateFormat}
                selectedReport={selectedReport}
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    isLoadingFilters: selectors.getFiltersLoading,
    reportsData: selectors.getReportsModel,
});

const mapDispatchToProps = (dispatch) => ({
    fetchReports: (params) => dispatch(actions.fetchReports(params)),
});

Content.propTypes = {
    fetchReports: PropTypes.func,
    filterQuery: PropTypes.string,
    isLoadingFilters: PropTypes.bool,
    reportsData: PropTypes.object,
    view: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
