import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Grid, IconButton } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormSelect2,
} from 'common/components/form/bootstrap';

const Periods = ({
    control,
    disabled,
    isSinglePeriod,
    name,
    onChangePeriod,
    periods,
    proposalPeriods,
    setValue,
    showName,
}) => {
    const { append, fields, remove } = useFieldArray({
        control,
        name: `${name}.periods`,
    });
    const { t } = useTranslation();

    const handleOnChangePeriod = () => {
        if (onChangePeriod) onChangePeriod();
    };

    const handleOnChangeComparisonPeriod = (id, path) => {
        let periodInfo = proposalPeriods.find((period) => period.value === id);
        if (!periodInfo)
            periodInfo = periods.find((period) => period.value === id);

        setValue(path, periodInfo?.period_type);
        handleOnChangePeriod();
    };

    const hasMultiPeriods = fields?.length > 1;
    const colSize = hasMultiPeriods ? 7 : 8;

    return (
        <>
            {showName && (
                <ReactHookFormInput
                    control={control}
                    disabled={disabled}
                    label="Name"
                    name={`${name}.name`}
                    onChange={handleOnChangePeriod}
                    variant="standard"
                />
            )}
            {fields.map((item, index) => (
                <Box key={item.id}>
                    <Grid container key={item.id}>
                        <Grid size={{ sm: colSize, xs: 18 }}>
                            <ReactHookFormSelect2
                                control={control}
                                disabled={disabled}
                                isClearable={false}
                                label={`${t('Current period')} *`}
                                name={`${name}.periods.${index}.current_period`}
                                onChange={handleOnChangePeriod}
                                options={[
                                    {
                                        label: t('Historical'),
                                        options: periods,
                                    },
                                ]}
                                variant="standard"
                            />
                        </Grid>

                        <Grid
                            size={{ xs: 2 }}
                            sx={{ display: { xs: 'none', sm: 'block' }, mt: 1 }}
                            textAlign="center"
                        >
                            <LinkIcon fontSize="medium" />
                        </Grid>

                        <Grid
                            size={{
                                sm: colSize,
                                xs: hasMultiPeriods ? 15 : 18,
                            }}
                        >
                            <ReactHookFormSelect2
                                control={control}
                                disabled={disabled}
                                label={t('Comparison period')}
                                name={`${name}.periods.${index}.comparison_period`}
                                onChange={(id) =>
                                    handleOnChangeComparisonPeriod(
                                        id,
                                        `${name}.periods.${index}.period_type`,
                                    )
                                }
                                options={[
                                    {
                                        label: t('Historical'),
                                        options: periods,
                                    },
                                    {
                                        label: t('Proposal'),
                                        options: proposalPeriods,
                                    },
                                ]}
                                variant="standard"
                            />
                        </Grid>

                        {hasMultiPeriods && (
                            <Grid
                                size={{ sm: 2, xs: 3 }}
                                sx={{ mt: 1 }}
                                textAlign="center"
                            >
                                <IconButton onClick={() => remove(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>

                    {hasMultiPeriods && <Divider sx={{ my: 2 }} />}
                </Box>
            ))}

            {!isSinglePeriod && (
                <Button
                    disabled={disabled}
                    endIcon={<AddIcon />}
                    fullWidth
                    onClick={() =>
                        append({
                            comparison_period: null,
                            current_period: null,
                            period_type: null,
                        })
                    }
                    sx={{ my: 2 }}
                    variant="outlined"
                >
                    {t('Add period')}
                </Button>
            )}
        </>
    );
};

Periods.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    isSinglePeriod: PropTypes.bool,
    name: PropTypes.string,
    onChangePeriod: PropTypes.func,
    periods: PropTypes.array,
    proposalPeriods: PropTypes.array,
    setValue: PropTypes.func,
    showName: PropTypes.bool,
};

export default Periods;
