import React from 'react';

import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Card,
    Divider,
    Grid,
    InputAdornment,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from 'sunwise-ui';

import {
    ReactHookFormCheckboxButtonGroup,
    ReactHookFormCheck,
    ReactHookFormInput,
    ReactHookFormIntlNumberInput,
    ReactHookFormRadioButtonGroup,
} from 'common/components/form/bootstrap';
import { DEFAULT_RATES, DEFAULT_TERMS } from 'common/constants';
import {
    getCurrencyIso,
    getCurrencySymbol,
    getAlgorithmTypes,
} from 'common/utils/helpers';

const Page1 = ({
    control,
    defaultCurrency,
    disabled,
    isBright,
    isKam,
    isSerfimex,
}) => {
    const [
        insurance,
        parametersCommissionCredit,
        parametersHitch,
        typeAlgorithm,
    ] = useWatch({
        control,
        name: [
            'insurance',
            'parameters_commission_credit',
            'parameters_hitch',
            'type_algorithm',
        ],
    });
    const { t } = useTranslation();
    const currencyIso = getCurrencyIso(defaultCurrency);
    const currencySymbol = getCurrencySymbol(defaultCurrency);
    const getAppendText = (parameter) => {
        if (parameter && parameter.unit === 0) return '%';
        return currencyIso;
    };

    const getPreAppendText = (parameter) => {
        if (parameter && parameter.unit === 1) return currencySymbol;
        return null;
    };

    const getTermsOptions = () => {
        if (isBright) {
            let data = [...DEFAULT_TERMS, { label: '156', value: '156' }];
            return sortBy(data, [
                function (o) {
                    return parseInt(o.value);
                },
            ]);
        }
        return DEFAULT_TERMS;
    };

    return (
        <>
            <ReactHookFormInput
                control={control}
                defaultBehavior
                disabled={disabled}
                label={t('Product name')}
                name="name"
                placeholder={t('Add the name of the product')}
            />

            <ReactHookFormInput
                control={control}
                defaultBehavior
                disabled={disabled}
                label={t('General information about the product')}
                multiline
                name="description"
            />

            <Box display="flex" flexDirection="column" gap="16px">
                <Typography variant="caption" fontWeight="bold">
                    {t('Select rates to consider')}
                </Typography>
                <ReactHookFormCheckboxButtonGroup
                    control={control}
                    disabled={disabled}
                    items={DEFAULT_RATES}
                    name="rate_list"
                    row
                />
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                gap="16px"
                sx={{ display: 'none' }}
            >
                <Divider sx={{ mb: 2 }} />

                <Typography variant="caption" fontWeight="bold">
                    {t('Type of tax system')}
                </Typography>

                <ReactHookFormRadioButtonGroup
                    control={control}
                    disabled={disabled}
                    items={[
                        {
                            label: t('Physical person'),
                            value: 'physical_person',
                        },
                        {
                            label: t('Physical person with activity'),
                            value: 'person_company',
                        },
                        { label: t('Moral person'), value: 'company' },
                    ]}
                    name="types_business_company"
                />
            </Box>

            <Divider sx={{ mb: 2 }} />

            <Box display="flex" flexDirection="column">
                <Typography fontWeight="bold" variant="caption">
                    {t('Algorithm to run')}
                </Typography>
                <ReactHookFormRadioButtonGroup
                    control={control}
                    disabled={disabled}
                    items={getAlgorithmTypes(isBright, isKam, isSerfimex)}
                    name="type_algorithm"
                />
            </Box>

            <Divider sx={{ mb: 2 }} />

            <Box hidden={typeAlgorithm === 'ppa_api_key'}>
                <Typography fontWeight="bold" variant="caption">
                    {t('Parameter', { count: 2 })}
                </Typography>

                <Grid container sx={{ mt: 0.1 }}>
                    <Grid size={{ md: 9, xs: 18 }}>
                        <ReactHookFormIntlNumberInput
                            append="%"
                            control={control}
                            disabled={disabled}
                            fullWidth
                            label={t('Interest rate')}
                            name="parameters_rate.value"
                        />
                    </Grid>

                    <Grid size={{ md: 9, xs: 18 }}>
                        <ReactHookFormIntlNumberInput
                            append="%"
                            control={control}
                            disabled={disabled}
                            fullWidth
                            label={t('Tax on interest')}
                            name="parameters.tax"
                            placeholder="%"
                        />
                    </Grid>
                </Grid>
                <Box hidden={typeAlgorithm === 'static_api_key'}>
                    <Grid container sx={{ mt: 0.1 }}>
                        <Grid size={{ md: 9, xs: 18 }}>
                            <ReactHookFormIntlNumberInput
                                append={getAppendText(
                                    parametersCommissionCredit,
                                )}
                                control={control}
                                disabled={disabled}
                                fullWidth
                                label={t('Opening commission')}
                                name="parameters_commission_credit.value"
                                prepend={getPreAppendText(
                                    parametersCommissionCredit,
                                )}
                            />
                        </Grid>

                        <Grid size={{ md: 9, xs: 18 }}>
                            <ReactHookFormIntlNumberInput
                                append="%"
                                control={control}
                                disabled={disabled}
                                fullWidth
                                label={t('Commission tax')}
                                name="parameters_commission_credit.opening_commission_vat"
                                placeholder="%"
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Grid container>
                    <Grid
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            gap: '16px',
                        }}
                        size={18}
                    >
                        <Box sx={{ mb: 2, '& .MuiCheckbox-root': { p: 0 } }}>
                            <ReactHookFormCheck
                                control={control}
                                disabled={disabled}
                                name="parameters_hitch.isChecked"
                            />
                        </Box>
                        <ReactHookFormIntlNumberInput
                            append={getAppendText(parametersHitch)}
                            control={control}
                            disabled={disabled}
                            fullWidth
                            label={t('Minimum down payment')}
                            name="parameters_hitch.value"
                            prepend={getPreAppendText(parametersHitch)}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            gap: '16px',
                        }}
                        size={18}
                    >
                        <Box sx={{ mb: 2, '& .MuiCheckbox-root': { p: 0 } }}>
                            <ReactHookFormCheck
                                control={control}
                                disabled={disabled}
                                name="parameters_residual.isChecked"
                            />
                        </Box>

                        <ReactHookFormIntlNumberInput
                            append="%"
                            control={control}
                            disabled={disabled}
                            fullWidth
                            label={t('Minimal residual')}
                            name="parameters_residual.value"
                        />
                    </Grid>
                </Grid>

                <Divider sx={{ mb: 2 }} />

                <Box hidden={typeAlgorithm === 'static_api_key'}>
                    <Typography fontWeight="bold" variant="caption">
                        {t('Ensurance')}
                    </Typography>

                    {insurance && insurance.length > 0 ? (
                        <>
                            {insurance.map((insurance) => (
                                <Card key={`insurance-${insurance.id}`}>
                                    <Card.Header>
                                        <Typography
                                            fontWeight={700}
                                            sx={{
                                                color: '#919eab',
                                            }}
                                        >
                                            {insurance.name}
                                        </Typography>
                                    </Card.Header>
                                    <Card.Body>
                                        {insurance.insurance_product_params.map(
                                            (param) => (
                                                <Box
                                                    key={`insurance-params-${param.id}`}
                                                >
                                                    <Typography
                                                        fontWeight={500}
                                                        sx={{
                                                            color: '#919eab',
                                                        }}
                                                    >
                                                        {param.description}
                                                    </Typography>
                                                    <Divider sx={{ mb: 2 }} />
                                                    <Grid container mb={2}>
                                                        <Grid
                                                            size={{
                                                                md: 4,
                                                                xs: 18,
                                                            }}
                                                        >
                                                            <TextField
                                                                disabled
                                                                InputProps={{
                                                                    startAdornment:
                                                                        param.value_type ===
                                                                            1 && (
                                                                            <InputAdornment position="end">
                                                                                {
                                                                                    currencySymbol
                                                                                }
                                                                            </InputAdornment>
                                                                        ),
                                                                    endAdornment:
                                                                        (
                                                                            <InputAdornment position="end">
                                                                                {param.value_type ===
                                                                                1
                                                                                    ? currencyIso
                                                                                    : '%'}
                                                                            </InputAdornment>
                                                                        ),
                                                                }}
                                                                value={
                                                                    param.amount
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            size={{
                                                                md: 7,
                                                                xs: 18,
                                                            }}
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                                gap: 1,
                                                            }}
                                                        >
                                                            <Typography
                                                                fontWeight="bold"
                                                                sx={{
                                                                    color: '#919eab',
                                                                }}
                                                                variant="caption"
                                                            >
                                                                {t('Tax', {
                                                                    count: 2,
                                                                })}
                                                            </Typography>
                                                            <ToggleButtonGroup
                                                                color="secondary"
                                                                disabled
                                                                exclusive
                                                                onChange={() => {}}
                                                                size="small"
                                                                value={
                                                                    param.tax_included
                                                                }
                                                            >
                                                                {[
                                                                    {
                                                                        label: t(
                                                                            'Includes tax',
                                                                            {
                                                                                count: 2,
                                                                            },
                                                                        ),
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        label: t(
                                                                            'Does not include tax',
                                                                            {
                                                                                count: 2,
                                                                            },
                                                                        ),
                                                                        value: false,
                                                                    },
                                                                ].map(
                                                                    (item) => (
                                                                        <ToggleButton
                                                                            disabled={
                                                                                item.disabled
                                                                            }
                                                                            key={`toggle-buttons-${item.value}`}
                                                                            value={
                                                                                item.value
                                                                            }
                                                                        >
                                                                            {
                                                                                item.label
                                                                            }
                                                                        </ToggleButton>
                                                                    ),
                                                                )}
                                                            </ToggleButtonGroup>
                                                        </Grid>
                                                        <Grid
                                                            size={{
                                                                md: 7,
                                                                xs: 18,
                                                            }}
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                                gap: 1,
                                                            }}
                                                        >
                                                            <Typography
                                                                fontWeight="bold"
                                                                sx={{
                                                                    color: '#919eab',
                                                                }}
                                                                variant="caption"
                                                            >
                                                                {t(
                                                                    'Way to pay',
                                                                )}
                                                            </Typography>
                                                            <ToggleButtonGroup
                                                                color="secondary"
                                                                disabled
                                                                exclusive
                                                                onChange={() => {}}
                                                                size="small"
                                                                value={
                                                                    param.type_cost
                                                                }
                                                            >
                                                                {[
                                                                    {
                                                                        label: t(
                                                                            'Counted',
                                                                        ),
                                                                        value: 0,
                                                                    },
                                                                    {
                                                                        label: t(
                                                                            'Funded',
                                                                        ),
                                                                        value: 1,
                                                                    },
                                                                    {
                                                                        label: t(
                                                                            'Let the user select',
                                                                        ),
                                                                        value: 2,
                                                                    },
                                                                ].map(
                                                                    (item) => (
                                                                        <ToggleButton
                                                                            disabled={
                                                                                item.disabled
                                                                            }
                                                                            key={`toggle-buttons-${item.value}`}
                                                                            value={
                                                                                item.value
                                                                            }
                                                                        >
                                                                            {
                                                                                item.label
                                                                            }
                                                                        </ToggleButton>
                                                                    ),
                                                                )}
                                                            </ToggleButtonGroup>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            ),
                                        )}
                                    </Card.Body>
                                </Card>
                            ))}
                        </>
                    ) : (
                        <p>
                            <small>{t('No selected insurance')}</small>
                        </p>
                    )}

                    <Divider sx={{ my: 2 }} />
                </Box>
            </Box>

            <ReactHookFormIntlNumberInput
                allowNegativeValue={false}
                append="%"
                control={control}
                disabled={disabled}
                fullWidth
                label={t('Annual increase')}
                min={0}
                name="annual_increase"
            />

            <Typography fontWeight="bold" variant="caption">
                {t('Limit amount')}
            </Typography>

            <Grid container sx={{ mt: 0.2 }}>
                <Grid
                    size={{ md: 9, xs: 18 }}
                    sx={{ alignItems: 'center', display: 'flex', gap: '16px' }}
                >
                    <Box sx={{ '& .MuiCheckbox-root': { p: 0 } }}>
                        <ReactHookFormCheck
                            control={control}
                            disabled={disabled}
                            name="amount_values.isChecked"
                        />
                    </Box>
                    <ReactHookFormIntlNumberInput
                        append={currencyIso}
                        control={control}
                        disabled={disabled}
                        fullWidth
                        label={t('Minimum amount')}
                        name="amount_values.min"
                        prepend={currencySymbol}
                    />
                </Grid>

                <Grid size={{ md: 9, xs: 18 }}>
                    <ReactHookFormIntlNumberInput
                        append={currencyIso}
                        control={control}
                        disabled={disabled}
                        fullWidth
                        label={t('Maximum amount')}
                        name="amount_values.max"
                        prepend={currencySymbol}
                    />
                </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            <Typography fontWeight="bold" variant="caption">
                {t('Power limit')}
            </Typography>

            <Grid container sx={{ mt: 0.2 }}>
                <Grid
                    size={{ md: 9, xs: 18 }}
                    sx={{ alignItems: 'center', display: 'flex', gap: '16px' }}
                >
                    <Box sx={{ '& .MuiCheckbox-root': { p: 0 } }}>
                        <ReactHookFormCheck
                            control={control}
                            disabled={disabled}
                            fullWidth
                            name="power_values.isChecked"
                        />
                    </Box>

                    <ReactHookFormIntlNumberInput
                        append="kW"
                        control={control}
                        disabled={disabled}
                        fullWidth
                        label={t('Minimum power')}
                        name="power_values.min"
                    />
                </Grid>

                <Grid size={{ md: 9, xs: 18 }}>
                    <ReactHookFormIntlNumberInput
                        append="kW"
                        control={control}
                        disabled={disabled}
                        fullWidth
                        label={t('Maximum power')}
                        name="power_values.max"
                    />
                </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            <Box display="flex" flexDirection="column" gap="16px">
                <Typography fontWeight="bold" variant="caption">
                    {t('Select the periods of the product')}
                </Typography>
                <ReactHookFormCheckboxButtonGroup
                    control={control}
                    items={getTermsOptions()}
                    name="parameters.terms"
                    disabled={disabled}
                    row
                />
            </Box>
        </>
    );
};

Page1.propTypes = {
    control: PropTypes.object,
    defaultCurrency: PropTypes.object,
    disabled: PropTypes.bool,
    isBright: PropTypes.bool,
    isKam: PropTypes.bool,
    isSerfimex: PropTypes.bool,
};

export default Page1;
