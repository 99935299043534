import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

export default styled(Box)`
    flex: 0 0 auto;
    min-width: 316px;
    overflow: hidden;
    padding: 0 4px;
    width: 316px;
`;
