import React, { useCallback } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material/styles';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button } from 'sunwise-ui';

import { FILE_ALLOWED_EXTENSIONS, MAX_FILE_SIZE } from 'common/constants';
import showToast from 'common/utils/showToast';

const WrapperField = styled('div')`
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    justify-content: center;
    letter-spacing: -0.29px;
    line-height: 19px;
    outline: none;
    padding: 48px 16px;

    ${({ variant }) =>
        variant === 'firstLoad' &&
        `
        background: #002438;
        border-radius: 3px;
        border: 3px dashed #a3a6b4;
        color: #ffffff;
        margin-bottom: 8px;
`}
`;

const UploadFile = ({
    control,
    disabled,
    maxSize = MAX_FILE_SIZE,
    maxSizeErrorMessage = i18next.t(
        'The file exceeds the allowable size limit',
    ),
    name,
    onChange,
    visible,
}) => {
    const { t } = useTranslation();
    if (!visible) return null;

    const onDrop = useCallback((acceptedFiles) => {
        if (!acceptedFiles.length) return;
        if (acceptedFiles.length > 0 && acceptedFiles[0].size >= maxSize) {
            showToast({
                body: maxSizeErrorMessage,
                type: 'danger',
                autoClose: 3000,
            });
            return;
        }

        if (onChange) onChange(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const acceptedFiles = `${FILE_ALLOWED_EXTENSIONS.CSV},${FILE_ALLOWED_EXTENSIONS.XML},${FILE_ALLOWED_EXTENSIONS.PDF},${FILE_ALLOWED_EXTENSIONS.IMAGE}`;

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { value } }) => (
                <WrapperField
                    {...getRootProps()}
                    variant={!value ? 'firstLoad' : 'reload'}
                    style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
                >
                    <input
                        {...getInputProps()}
                        accept={acceptedFiles}
                        disabled={disabled}
                    />

                    {!value ? (
                        <Box
                            alignItems="center"
                            display="flex"
                            gap={2}
                            justifyContent="center"
                        >
                            <AttachFileIcon />
                            {`${t('File upload')} (.csv, .pdf, .xml, .png )`}
                        </Box>
                    ) : (
                        <Box
                            alignItems="center"
                            display="flex"
                            flexDirection="column"
                            gap={2}
                        >
                            <CheckCircleIcon />

                            <Button disabled={disabled}>
                                {t('Change file')}
                            </Button>
                        </Box>
                    )}
                </WrapperField>
            )}
        />
    );
};

UploadFile.defaultProps = {
    disabled: false,
    visible: true,
};

UploadFile.propTypes = {
    accept: PropTypes.string,
    buttonText: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    error: PropTypes.object,
    id: PropTypes.string,
    label: PropTypes.string,
    maxSize: PropTypes.number,
    maxSizeErrorMessage: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    setValue: PropTypes.func,
    variant: PropTypes.string,
    visible: PropTypes.bool,
};

export default UploadFile;
