export default {
    control: {
        backgroundColor: 'transparent',
    },
    input: {
        margin: 0,
        boxShadow: 'none',
        outline: 'none',
        border: 0,
    },
};
