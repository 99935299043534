import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';

const InfoAccordion = ({ children, defaultExpanded, description, title }) => (
    <Accordion
        defaultExpanded={defaultExpanded}
        disableGutters
        sx={{
            boxShadow: 'none!important',
            '&::before': { display: 'none' },
        }}
    >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">{title}</Typography>
        </AccordionSummary>

        <AccordionDetails>
            {description && (
                <Typography variant="body2">{description}</Typography>
            )}

            {children}
        </AccordionDetails>
    </Accordion>
);

InfoAccordion.propTypes = {
    children: PropTypes.node,
    defaultExpanded: PropTypes.bool,
    description: PropTypes.string,
    title: PropTypes.string,
};

export default InfoAccordion;
