import React, { useEffect } from 'react';

import { Switch } from 'react-router-dom';

import { LayoutRoute, PageNotFound } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import {
    BlankLayout,
    DefaultLayout,
    LoginLayout,
    PageNotFoundLayout,
    PaymentDueLayout,
} from 'common/layouts';
import {
    getIsColombianAccount,
    getIsMexicanAccount,
    initSessionActions,
} from 'common/utils/helpers/session';

import alliances from '../alliances';
import analytics from '../analytics';
import analyticsCFE from '../analyticsCFE';
import analyticsCRE from '../analyticsCRE';
import branchOffices from '../branchOffices';
import companyBranding from '../companyBranding';
import companyGeneral from '../companyGeneral';
import companyUsers from '../companyUsers';
import consumptionProfiles from '../consumptionProfiles';
import contact from '../contact';
import createAccount from '../createAccount';
import credentialIntegrations from '../credentialIntegrations';
import dashboard from '../dashboard';
import editTemplateView from '../editTemplateView';
import energyBackupSetup from '../energyBackupSetup';
import equipmentCompatibility from '../equipmentCompatibility';
import financialProduct from '../financialProduct';
import followUps from '../followUps';
import forgotPassword from '../forgotPassword';
import funnelFinancing from '../funnelFinancing';
import funnelReports from '../funnelReports';
import listSettings from '../listSettings';
import login from '../login';
import monitoringDashboard from '../monitoringDashboard';
import multiCurrency from '../multiCurrency';
import multiPanelLayout from '../multiPanelLayout';
import notifications from '../notifications';
import onboardingWizard from '../onboardingWizard';
import paymentDueAdvice from '../paymentDueAdvice';
import planConfiguration from '../planConfiguration';
import powerStations from '../powerStations';
import products from '../products';
import profile from '../profile';
import projects from '../projects';
import proposalGeneratorOneColumn from '../proposalGeneratorOneColumn';
import proposalReviewPro from '../proposalReviewPro';
import proposals from '../proposals';
import proposalSummaries from '../proposalSummaries';
import rates from '../rates';
import record from '../record';
import reportPreview from '../reportPreview';
import reportReviewPro from '../reportReviewPro';
import resetPassword from '../resetPassword';
import rolesConfiguration from '../rolesConfiguration';
import selectBillingPlan from '../selectBillingPlan';
import solarGeneration from '../solarGeneration';
import summaryReview from '../summaryReview';
import supplierIntegrations from '../supplierIntegrations';
import taxesConfiguration from '../taxesConfiguration';
import templateLibrary from '../templateLibrary';
import templateRender from '../templateRender';
import webIntegrations from '../webIntegrations';
import integrationWeb from '../webIntegrations/web';
import zapierIntegration from '../zapierIntegration';

const Container = () => {
    const isColombianAccount = getIsColombianAccount();
    const isMexicanAccount = getIsMexicanAccount();

    useEffect(() => {
        initSessionActions();
    }, []);

    return (
        <Switch>
            <LayoutRoute
                component={login.Container}
                isPublic
                layout={LoginLayout}
                path="/login"
            />

            <LayoutRoute
                component={createAccount.Container}
                isPublic
                layout={LoginLayout}
                path="/create-account"
            />

            <LayoutRoute
                component={forgotPassword.Container}
                exact
                isPublic
                layout={LoginLayout}
                path="/reset-password"
            />

            <LayoutRoute
                component={resetPassword.Container}
                exact
                isPublic
                layout={LoginLayout}
                path="/reset-password/:uuid/:token"
            />

            <LayoutRoute
                checkOnboarding
                component={selectBillingPlan.Container}
                exact
                layout={LoginLayout}
                path="/wizard"
            />

            <LayoutRoute
                checkPaymentDue
                component={onboardingWizard.Container}
                exact
                layout={DefaultLayout}
                path="/onboarding"
            />

            <LayoutRoute
                checkOnboarding
                component={paymentDueAdvice.Container}
                exact
                layout={PaymentDueLayout}
                path="/payment-due"
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={dashboard.Container}
                exact
                layout={DefaultLayout}
                path={['/', '/dashboard']}
                redirect={{ pathname: '/contacts' }}
                requiredPermissions={[PERMISSION_LIST.DASHBOARD_PERMISSION]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={projects.Container}
                exact
                layout={DefaultLayout}
                path="/project-funnel"
                requiredPermissions={[
                    PERMISSION_LIST.FUNNEL_PROJECTS_PERMISSION,
                ]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={funnelFinancing.Container}
                exact
                isHidden={isColombianAccount}
                layout={DefaultLayout}
                path="/financing-funnel"
                requiredPermissions={[
                    PERMISSION_LIST.FUNNEL_FINANCING_PERMISSION,
                ]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={funnelReports.Container}
                exact
                layout={DefaultLayout}
                path="/report-funnel"
                requiredPermissions={[PERMISSION_LIST.FUNNEL_REPORT_PERMISSION]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={monitoringDashboard.Container}
                exact
                layout={DefaultLayout}
                path="/monitoring-dashboard"
                requiredPermissions={[
                    PERMISSION_LIST.MONITORING_DASHBOARD_PERMISSION,
                ]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={contact.Container}
                exact
                layout={DefaultLayout}
                multiBranchMode
                path="/contacts"
                requiredPermissions={[PERMISSION_LIST.CONTACTS_PERMISSION]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={record.Container}
                exact
                layout={DefaultLayout}
                path="/record/:uid"
                requiredPermissions={[PERMISSION_LIST.CONTACTS_PERMISSION]}
            />

            <LayoutRoute
                checkPaymentDue
                component={proposalGeneratorOneColumn.Container}
                exact
                layout={DefaultLayout}
                path="/proposal/:uid"
                requiredPermissions={[PERMISSION_LIST.CONTACTS_PERMISSION]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={multiPanelLayout.Container}
                exact
                layout={DefaultLayout}
                path="/panel-layout/:uid"
                requiredPermissions={[PERMISSION_LIST.CONTACTS_PERMISSION]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={energyBackupSetup.Container}
                exact
                layout={DefaultLayout}
                path="/energy-backup-setup/:id"
                requiredPermissions={[
                    PERMISSION_LIST.PROPOSAL_ENERGY_BACKUP_PERMISSION,
                ]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={proposalReviewPro.Container}
                exact
                layout={DefaultLayout}
                path="/proposal-review-pro/:uid"
                requiredPermissions={[PERMISSION_LIST.CONTACTS_PERMISSION]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={proposals.Container}
                exact
                layout={DefaultLayout}
                path="/proposals"
                requiredPermissions={[
                    PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION,
                ]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={proposalSummaries.Container}
                exact
                layout={DefaultLayout}
                path="/summaries"
                requiredPermissions={[
                    PERMISSION_LIST.PROPOSAL_SUMMARIES_PERMISSION,
                ]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={summaryReview.Container}
                exact
                layout={DefaultLayout}
                path="/summary-review/:uid"
                requiredPermissions={[PERMISSION_LIST.CONTACTS_PERMISSION]}
            />

            <LayoutRoute
                checkPaymentDue
                component={reportPreview.Container}
                exact
                layout={DefaultLayout}
                path="/report/:uid"
                requiredPermissions={[PERMISSION_LIST.AFTER_SALES_PERMISSION]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={reportReviewPro.Container}
                exact
                layout={DefaultLayout}
                path="/report-review-pro/:uid"
                requiredPermissions={[PERMISSION_LIST.AFTER_SALES_PERMISSION]}
            />

            <LayoutRoute
                checkPaymentDue
                component={analytics.Container}
                exact
                layout={DefaultLayout}
                path={['/analytics']}
                requiredPermissions={[PERMISSION_LIST.ANALYTICS_PERMISSION]}
            />

            <LayoutRoute
                checkPaymentDue
                component={analyticsCFE.Container}
                exact
                layout={DefaultLayout}
                path={['/analytics-cfe']}
                requiredPermissions={[PERMISSION_LIST.CFE_PERMISSION]}
            />

            <LayoutRoute
                checkPaymentDue
                component={analyticsCRE.Container}
                exact
                layout={DefaultLayout}
                path={['/analytics-cre']}
                requiredPermissions={[PERMISSION_LIST.CRE_PERMISSION]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={products.Container}
                exact
                layout={DefaultLayout}
                multiBranchMode
                path={['/products', '/products/:id']}
                requiredPermissions={[
                    PERMISSION_LIST.ACCESORIES_PERMISSION,
                    PERMISSION_LIST.ADITIONALS_PERMISSION,
                    PERMISSION_LIST.ENERGY_BACKUP_PERMISSION,
                    PERMISSION_LIST.INVERTERS_PERMISSION,
                    PERMISSION_LIST.PANELS_PERMISSION,
                    PERMISSION_LIST.STRUCTURES_PERMISSION,
                    PERMISSION_LIST.WORKFORCE_PERMISSION,
                ]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={planConfiguration.Container}
                exact
                layout={DefaultLayout}
                multiBranchMode
                path="/plan-configuration"
                requiredPermissions={[PERMISSION_LIST.PLANS_PERMISSION]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={templateLibrary.Container}
                exact
                layout={DefaultLayout}
                multiBranchMode
                path={['/template-library', '/template-library/:id']}
                requiredPermissions={[
                    PERMISSION_LIST.PROPOSAL_TEMPLATES_PERMISSION,
                    PERMISSION_LIST.REPORT_TEMPLATES_PERMISSION,
                    PERMISSION_LIST.SMART_DOCUMENT_TEMPLATES_PERMISSION,
                    PERMISSION_LIST.SUMMARY_TEMPLATES_PERMISSION,
                ]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={editTemplateView.Container}
                exact
                layout={DefaultLayout}
                path="/edit-template-pro/:uid"
                requiredPermissions={[
                    PERMISSION_LIST.PROPOSAL_TEMPLATES_PERMISSION,
                ]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={alliances.Container}
                exact
                isHidden={!isMexicanAccount}
                layout={DefaultLayout}
                path={['/alliances', '/alliances/:id']}
                requiredPermissions={[PERMISSION_LIST.ALLIANCES_PERMISSION]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={financialProduct.Container}
                exact
                layout={DefaultLayout}
                path={['/financial-products']}
                requiredPermissions={[
                    PERMISSION_LIST.FINANCIAL_PRODUCTS_PERMISSION,
                ]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={followUps.Container}
                exact
                layout={DefaultLayout}
                path={['/follow-ups']}
                requiredPermissions={[
                    PERMISSION_LIST.TRACKING_CONTACTS_PERMISSION,
                ]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={solarGeneration.Container}
                exact
                layout={DefaultLayout}
                path={['/solar-generation']}
                requiredPermissions={[
                    PERMISSION_LIST.SOLAR_GENERATION_PERMISSION,
                ]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={equipmentCompatibility.Container}
                exact
                layout={DefaultLayout}
                path={['/equipment-compatibility']}
                requiredPermissions={[
                    PERMISSION_LIST.EQUIPMENT_COMPATIBILITY_PERMISSION,
                ]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={rates.Container}
                exact
                layout={DefaultLayout}
                path={['/rates']}
                requiredPermissions={[
                    PERMISSION_LIST.CERTIFIED_RATES_PERMISSION,
                ]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={consumptionProfiles.Container}
                exact
                layout={DefaultLayout}
                path={['/consumption-profiles']}
                requiredPermissions={[
                    PERMISSION_LIST.SELFCONSUMPTION_ADMIN_PROFILES_PERMISSION,
                ]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={multiCurrency.Container}
                exact
                layout={DefaultLayout}
                path={['/currencies', '/currencies/:iso']}
                requiredPermissions={[PERMISSION_LIST.CURRENCIES_PERMISSION]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={taxesConfiguration.Container}
                exact
                layout={DefaultLayout}
                path={['/taxes']}
                requiredPermissions={[PERMISSION_LIST.TAXES_PERMISSION]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={companyGeneral.Container}
                exact
                layout={DefaultLayout}
                path={['/company']}
                requiredPermissions={[
                    PERMISSION_LIST.GENERAL_COMPANY_INFO_PERMISSION,
                ]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={branchOffices.Container}
                exact
                layout={DefaultLayout}
                path={['/branch-offices']}
                requiredPermissions={[
                    PERMISSION_LIST.BRANCH_OFFICES_PERMISSION,
                ]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={companyBranding.Container}
                exact
                layout={DefaultLayout}
                path={['/branding']}
                requiredPermissions={[PERMISSION_LIST.BRANDING_PERMISSION]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={rolesConfiguration.Container}
                exact
                layout={DefaultLayout}
                path={['/roles']}
                requiredPermissions={[
                    PERMISSION_LIST.MAX_DISCOUNT_IN_ROLES_PERMISSION,
                    PERMISSION_LIST.ROLES_PERMISSION,
                ]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={companyUsers.Container}
                exact
                layout={DefaultLayout}
                path={['/users']}
                requiredPermissions={[PERMISSION_LIST.COMPANY_USERS_PERMISSION]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={listSettings.Container}
                exact
                layout={DefaultLayout}
                path={['/lists']}
                requiredPermissions={[PERMISSION_LIST.LIST_SETTINGS_PERMISSION]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={webIntegrations.Container}
                exact
                isHidden={!isMexicanAccount}
                layout={DefaultLayout}
                path={['/web-quoter']}
                requiredPermissions={[
                    PERMISSION_LIST.EMBEDDED_PROPOSAL_PERMISSION,
                ]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={integrationWeb.Container}
                exact
                isHidden={!isMexicanAccount}
                layout={DefaultLayout}
                path={['/web-quoter/:uidParent', '/web-quoter/:uidParent/:id']}
                requiredPermissions={[
                    PERMISSION_LIST.EMBEDDED_PROPOSAL_PERMISSION,
                ]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={credentialIntegrations.Container}
                exact
                layout={DefaultLayout}
                path={['/monitoring-credentials']}
                requiredPermissions={[PERMISSION_LIST.REPORTS_PERMISSION]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={powerStations.Container}
                exact
                layout={DefaultLayout}
                path={['/monitoring-credentials/:id']}
                requiredPermissions={[PERMISSION_LIST.REPORTS_PERMISSION]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={supplierIntegrations.Container}
                exact
                layout={DefaultLayout}
                path={['/suppliers']}
                requiredPermissions={[PERMISSION_LIST.SUPPLIERS_PERMISSION]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={zapierIntegration.Container}
                exact
                layout={DefaultLayout}
                path={['/zapier']}
                requiredPermissions={[PERMISSION_LIST.ZAPIER_PERMISSION]}
            />

            <LayoutRoute
                checkOnboarding
                component={profile.Container}
                exact
                layout={DefaultLayout}
                path={['/profile', '/profile/:section']}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={notifications.Container}
                exact
                layout={DefaultLayout}
                path={['/notifications', '/notifications/:id']}
            />

            <LayoutRoute
                component={templateRender.Container}
                isPublic
                layout={BlankLayout}
                path="/template-render"
            />

            <LayoutRoute
                component={PageNotFound}
                layout={PageNotFoundLayout}
                path="*"
            />
        </Switch>
    );
};

export default Container;
