import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from 'sunwise-ui';

const ProductHeaderPreview = ({
    description,
    name,
    placeholder,
    productThumbnail,
}) => {
    const { t } = useTranslation();
    return (
        <Grid container>
            <Grid size={{ lg: 2, md: 3, xs: 6 }}>
                <Box p={1}>
                    <img
                        alt="product thumbnail"
                        src={productThumbnail ? productThumbnail : placeholder}
                    />
                </Box>
            </Grid>
            <Grid size={{ xs: 'grow' }}>
                <Typography fontWeight="bold" variant="h5">
                    {name || t('Nameless')}
                </Typography>
                <Typography variant="body2">
                    {description || t('Without description')}
                </Typography>
            </Grid>
        </Grid>
    );
};

ProductHeaderPreview.propTypes = {
    description: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    productThumbnail: PropTypes.string,
};

export default ProductHeaderPreview;
