import React, { useCallback, useContext, useEffect, useRef } from 'react';

import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    PageToolbar,
    TemplateView,
    withTemplateCore,
    editionLevels,
} from 'sunwise-template-core';
import { Box, Grid } from 'sunwise-ui';

import SwipeableDrawer from 'common/components/SwipeableDrawer';
import { LoadingContext } from 'common/utils/contexts';
import { getCurrentLanguage } from 'common/utils/helpers/multiregion';
import { getToken } from 'common/utils/helpers/session';
import { scroll } from 'common/utils/mixins';

import * as actions from './actions';
import HeaderPreview from './components/HeaderPreview';
import SideMenu from './components/SideMenu';
import StyledSideBarColumn from './components/StyledSideBarColumn';
import {
    getIsLoadingContextMessage,
    getIsUpdatingContextMessage,
    handleCloseDrawer,
} from './helpers';
import * as selectors from './selectors';

const PageWrapper = styled(Box)(
    ({ theme }) => `
    background:  ${
        theme.palette.mode === 'dark' ? '#000' : 'rgb(248, 252, 255)'
    };
    height: calc(100% - 100px);
    overflow-y: scroll;
    padding-top: 24px;
    position: relative;
    width: 100%;

    ${scroll.custom()}

    .apexcharts-theme-light {
        color: black;
    }
`,
);

const Container = ({
    breadcrumbLocations,
    canModify,
    currentBreadcrumb,
    editionDisabled,
    fetchCustomTemplate,
    fetchPdfFile,
    getTemplateUpdatedData,
    initTemplate,
    isFetchingCustomTemplate,
    isUpdatingCustomTemplate,
    match,
    permalink,
    prepareRegenerateTemplate,
    reset,
    resetTemplate,
    restorePages,
    setEditionDisabled,
    setTemplateBackup,
    summaryInfoPatern,
    templateName,
    templateType,
    updateTemplate,
}) => {
    const loadingContext = useContext(LoadingContext);
    const sideBarRef = useRef(null);
    const swipeableDrawerRef = useRef(null);
    const offerId = match.params.uid;

    useEffect(() => {
        getIsLoadingContextMessage(isFetchingCustomTemplate, loadingContext);
    }, [isFetchingCustomTemplate]);

    useEffect(() => () => reset(), []);

    useEffect(() => {
        if (offerId) fetchCustomTemplate(offerId, initTemplate, templateType);
    }, [offerId]);

    useEffect(() => {
        getIsUpdatingContextMessage(
            editionDisabled,
            isUpdatingCustomTemplate,
            loadingContext,
            setEditionDisabled,
        );
    }, [isUpdatingCustomTemplate]);

    const handleUpdateTemplate = useCallback(
        () =>
            updateTemplate(
                offerId,
                templateType,
                getTemplateUpdatedData,
                setTemplateBackup,
            ),
        [offerId, templateType, getTemplateUpdatedData, setTemplateBackup],
    );

    const handleOnPrepareRegenerateTemplate = () =>
        prepareRegenerateTemplate({
            initTemplate,
            offerId,
            resetTemplate,
            templateType,
        });

    const linkedProposals = get(summaryInfoPatern, 'linked_proposals', []);

    const renderSideMenu = () => (
        <SideMenu
            canModify={canModify}
            cancelUpdating={restorePages}
            editionDisabled={editionDisabled}
            fetchPdfFile={fetchPdfFile}
            handleOnPrepareRegenerateTemplate={
                handleOnPrepareRegenerateTemplate
            }
            handlerEditButton={handlerEditButton}
            initializeUpdating={setTemplateBackup}
            isUpdatingCustomTemplate={isUpdatingCustomTemplate}
            linkedProposals={linkedProposals}
            match={match}
            offerId={offerId}
            permalink={permalink}
            setEditionDisabled={setEditionDisabled}
            swipeableDrawerRef={swipeableDrawerRef}
            templateName={templateName}
            templateType={templateType}
            updateTemplate={handleUpdateTemplate}
        />
    );

    const handlerEditButton = () => {
        setEditionDisabled(false);
        setTemplateBackup();
        handleCloseDrawer(swipeableDrawerRef);
    };

    const handleDblClick = (e) => {
        const { detail } = e;
        if (detail === 2 && canModify) {
            setEditionDisabled(false);
            setTemplateBackup();
        }
    };

    return (
        <>
            <Grid container spacing={0}>
                <Grid
                    size={{ lg: 14, md: 12, xs: 18 }}
                    sx={{
                        height: {
                            lg: 'calc(100vh - 64px)',
                            xs: 'calc(100vh - 105px)',
                        },
                    }}
                >
                    <HeaderPreview
                        breadcrumbLocations={breadcrumbLocations}
                        currentBreadcrumb={currentBreadcrumb}
                        editionDisabled={editionDisabled}
                    />

                    <PageToolbar
                        editionLevel={editionLevels.PARTIAL_EDITION_MODE}
                        reOrderDisabled
                        sxToolbar={{
                            pl: '76px',
                            ...(editionDisabled ? { minHeight: 0 } : {}),
                        }}
                        visible={!editionDisabled}
                    />

                    <PageWrapper
                        onClick={handleDblClick}
                        sx={{ pb: { xs: '50px', lg: 0 } }}
                        toolbar={editionDisabled ? 'false' : 'true'}
                    >
                        <TemplateView
                            editionLevel={
                                editionDisabled
                                    ? editionLevels.NO_EDITION_MODE
                                    : editionLevels.PARTIAL_EDITION_MODE
                            }
                        />
                    </PageWrapper>
                </Grid>

                <StyledSideBarColumn
                    ref={sideBarRef}
                    size={{ lg: 4, md: 6 }}
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        height: {
                            md: 'calc(100vh - 64px)',
                            xs: 'calc(100vh - 105px)',
                        },
                    }}
                >
                    {renderSideMenu()}
                </StyledSideBarColumn>
            </Grid>

            <SwipeableDrawer ref={swipeableDrawerRef}>
                {renderSideMenu()}
            </SwipeableDrawer>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    isFetchingCustomTemplate: selectors.getIsFetchingCustomTemplate,
    isUpdatingCustomTemplate: selectors.getIsUpdatingCustomTemplate,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCustomTemplate: (id, initTemplate, templateType) =>
        dispatch(actions.fetchCustomTemplate(id, initTemplate, templateType)),
    fetchPdfFile: (
        id,
        templateName,
        downloadFormat,
        openLoading,
        closeLoading,
        templateType,
    ) =>
        dispatch(
            actions.fetchPdfFile(
                id,
                templateName,
                downloadFormat,
                openLoading,
                closeLoading,
                templateType,
            ),
        ),
    prepareRegenerateTemplate: (values) =>
        dispatch(actions.prepareRegenerateTemplate(values)),
    reset: () => dispatch(actions.reset()),
    updateTemplate: (
        offerId,
        templateType,
        getTemplateUpdatedData,
        setTemplateBackup,
    ) =>
        dispatch(
            actions.updateTemplate(
                offerId,
                templateType,
                getTemplateUpdatedData,
                setTemplateBackup,
            ),
        ),
});

Container.propTypes = {
    breadcrumbLocations: PropTypes.array,
    canModify: PropTypes.bool,
    currentBreadcrumb: PropTypes.string,
    editionDisabled: PropTypes.bool,
    fetchCustomTemplate: PropTypes.func,
    fetchPdfFile: PropTypes.func,
    getTemplateUpdatedData: PropTypes.func,
    initTemplate: PropTypes.func,
    isFetchingCustomTemplate: PropTypes.bool,
    isUpdatingCustomTemplate: PropTypes.bool,
    match: PropTypes.object,
    permalink: PropTypes.string,
    prepareRegenerateTemplate: PropTypes.func,
    reset: PropTypes.func,
    resetTemplate: PropTypes.func,
    restorePages: PropTypes.func,
    setEditionDisabled: PropTypes.func,
    setTemplateBackup: PropTypes.func,
    summaryInfoPatern: PropTypes.object,
    templateName: PropTypes.string,
    templateType: PropTypes.number,
    updateTemplate: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withTemplateCore(() => ({
        application: 'sunwise',
        baseUrl: import.meta.env.VITE_API_URL,
        editionMode: editionLevels.PARTIAL_EDITION_MODE,
        froalaLicenseKey: import.meta.env.VITE_FROALA_LICENSE,
        googleApiKey: import.meta.env.VITE_GOOGLE_MAPS_KEY,
        language: getCurrentLanguage(),
        token: getToken(),
    })),
)(Container);
