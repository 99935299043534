import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Card, Typography } from 'sunwise-ui';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';

const SourceCard = ({ canModify, control, saveSource, sourcesForSelect }) => {
    const { t } = useTranslation();
    const handleOnChangeSelect = (id) => saveSource(id);

    return (
        <Card>
            <Card.Body>
                <Grid container>
                    <Grid size={{ md: 'grow', xs: 18 }}>
                        <TitleWithDetail variant="body2">
                            {t('Select the default source')}
                        </TitleWithDetail>
                        <Typography variant="caption">
                            {t('Set and select the default radiation source')}
                        </Typography>
                    </Grid>

                    <Grid size={{ md: 'grow', xs: 18 }}>
                        <ReactHookFormSelect
                            control={control}
                            disabled={!canModify}
                            label=""
                            name="source"
                            options={sourcesForSelect}
                            onChange={(e) =>
                                handleOnChangeSelect(e.target.value)
                            }
                        />
                    </Grid>
                </Grid>
            </Card.Body>
        </Card>
    );
};

SourceCard.propTypes = {
    canModify: PropTypes.bool,
    control: PropTypes.object,
    saveSource: PropTypes.func,
    sourcesForSelect: PropTypes.array,
};

export default SourceCard;
