import { styled } from '@mui/material';
import { TableContainer } from '@sunwisesoftware/sunwise-ui';

import { scroll } from 'common/utils/mixins';

export default styled(TableContainer)`
    max-height: 75dvh;
    border-top-left-radius: 10px;

    ${scroll.custom()}
`;
