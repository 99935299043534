import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Avatar, Box } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';

import * as companyGeneralSelectors from '../../../../modules/companyGeneral/selectors';

import MenuItemDropdown from './MenuItemDropdown';

const AVATAR_SIZE = 36;

const Container = styled(Box)`
    align-items: center;
    display: flex;
    gap: 4px;
`;

const BranchOfficesNavBarDropdown = ({ companyImage, multiBranchMode }) => {
    const breakpoint = useBreakpoint();
    const sxAvatar = { height: AVATAR_SIZE, width: AVATAR_SIZE };
    if (['xs', 'sm'].includes(breakpoint)) return null;
    return (
        <Container>
            {companyImage && (
                <RouterLink to="/company">
                    <Avatar src={companyImage} sx={sxAvatar} />
                </RouterLink>
            )}
            <MenuItemDropdown multiBranchMode={multiBranchMode} />
        </Container>
    );
};

const mapStateToProps = createStructuredSelector({
    companyImage: companyGeneralSelectors.getCompanyImage,
});

BranchOfficesNavBarDropdown.propTypes = {
    companyImage: PropTypes.string,
    multiBranchMode: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
};

export default connect(mapStateToProps, null)(BranchOfficesNavBarDropdown);
