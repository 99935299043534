import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';

import { confirmSubscription } from 'common/api/v1/chargebee';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import { updateSession } from 'common/utils/helpers/session';

import * as profileSelectors from '../../profile/selectors';
import {
    CONFIRM_SUBSCRIPTION,
    CONFIRM_SUBSCRIPTION_FAILURE,
    CONFIRM_SUBSCRIPTION_SUCCESS,
} from '../actionTypes';
import { selectBillingPlanActions } from '../reducer';

export default (successCallback) => (dispatch, getState) => {
    const subscriptions = profileSelectors.getSubscriptions(getState());
    const noSubscription = isEmpty(subscriptions);
    dispatch(selectBillingPlanActions[CONFIRM_SUBSCRIPTION]());

    confirmSubscription()
        .then((response) => {
            dispatch(
                selectBillingPlanActions[CONFIRM_SUBSCRIPTION_SUCCESS](
                    response.data.data,
                ),
            );
            if (successCallback) successCallback();
            if (noSubscription) {
                updateSession({
                    hasOnboarding: true,
                });
                dispatch(push('/onboarding'));
                return false;
            } else dispatch(push('/profile/subscription'));
        })
        .catch((error) => {
            dispatch(
                selectBillingPlanActions[CONFIRM_SUBSCRIPTION_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
