import React, { useEffect } from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Skeleton, UiDialog as Dialog } from 'sunwise-ui';

import CIBancoEditForm from './CIBancoEditForm';
import CIBancoForm from './CIBancoForm';

const AllianceFormModal = ({
    alliance,
    allianceProducts,
    canModifyContacts,
    contact,
    currencyIso,
    currencySymbol,
    editionMode,
    fetchOfferComplements,
    fetchOfferDetails,
    financialProduct,
    handleClickCancel,
    isLoading,
    isLocked,
    isOpenModal,
    isSaving,
    proposalId,
    rateComplements,
    total,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (isOpenModal) fetchOfferComplements(proposalId);
    }, [isOpenModal]);

    const renderAllianceForm = (editionMode) => {
        if (isLoading) return <Skeleton variant="rounded" height={30} />;
        if (editionMode)
            return (
                <CIBancoEditForm
                    alliance={alliance}
                    allianceProducts={allianceProducts}
                    currencyIso={currencyIso}
                    currencySymbol={currencySymbol}
                    editionMode={editionMode}
                    financialProduct={financialProduct}
                    isLocked={isLocked}
                    isSaving={isSaving}
                    proposalId={proposalId}
                    rateComplements={rateComplements}
                    total={total}
                />
            );
        return (
            <CIBancoForm
                alliance={alliance}
                allianceProducts={allianceProducts}
                canModifyContacts={canModifyContacts}
                contact={contact}
                currencyIso={currencyIso}
                currencySymbol={currencySymbol}
                fetchOfferDetails={fetchOfferDetails}
                isLocked={isLocked}
                isSaving={isSaving}
                proposalId={proposalId}
                rateComplements={rateComplements}
                total={total}
            />
        );
    };

    return (
        <Dialog
            closeButton={isLocked ? false : true}
            isLoading={isLoading}
            onClose={() => handleClickCancel()}
            onExited={() => handleClickCancel()}
            open={isOpenModal}
            title={`${editionMode ? t('Edit') : ''} ${get(
                alliance,
                'financier.name',
                t('Alliance'),
            )}`}
        >
            {renderAllianceForm(editionMode)}
        </Dialog>
    );
};

AllianceFormModal.propTypes = {
    alliance: PropTypes.object,
    allianceProducts: PropTypes.array,
    canModifyContacts: PropTypes.bool,
    contact: PropTypes.object,
    currencyIso: PropTypes.string,
    currencySymbol: PropTypes.string,
    editionMode: PropTypes.bool,
    fetchOfferComplements: PropTypes.func,
    fetchOfferDetails: PropTypes.func,
    financialProduct: PropTypes.object,
    handleClickCancel: PropTypes.func,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    isSaving: PropTypes.bool,
    proposalId: PropTypes.string,
    rateComplements: PropTypes.object,
    total: PropTypes.number,
};

export default AllianceFormModal;
