import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Card, DataGrid, Grid } from 'sunwise-ui';

import SingleAutocomplete from 'common/components/SingleAutocomplete';
import TitleWithDetail from 'common/components/TitleWithDetail';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { scroll } from 'common/utils/mixins';

import * as actions from '../actions';
import * as selectors from '../selectors';

import ProposalColumns from './ProposalColumns';

const Container = ({
    canView,
    commercialOffers,
    commercialOffersByRate,
    fetchCommercialOffers,
    groupedRates,
    isLoading,
    menuOptions,
}) => {
    const { t } = useTranslation();
    const [currentValue, setCurrentValue] = useState(null);

    useEffect(() => {
        if (currentValue?.value) {
            commercialOffersByRate(currentValue.value);
        } else {
            fetchCommercialOffers();
        }
    }, [currentValue]);

    return (
        <Card
            id="card-last-offers"
            sx={{ mb: 0, minHeight: '100%', width: '100%' }}
        >
            <Card.Header id="card-header-last-offers">
                <Grid
                    alignContent="center"
                    alignItems="center"
                    container
                    gap={2}
                >
                    <Grid size={{ lg: 'grow', xs: 18 }}>
                        <TitleWithDetail variant="body2">
                            {t('Latest proposal', { count: 2 })}
                        </TitleWithDetail>
                    </Grid>

                    <Grid size={{ lg: 5, xs: 18 }}>
                        <SingleAutocomplete
                            fullWidth
                            isLoading={isLoading}
                            label={t('Rate')}
                            options={groupedRates}
                            onChange={setCurrentValue}
                            variant="outlined"
                        />
                    </Grid>
                    {menuOptions}
                </Grid>
            </Card.Header>

            <Card.Body
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100% - 73px)',
                    overflowY: 'auto',
                    ...scroll.object,
                }}
            >
                <DataGrid
                    autoHeight
                    columns={ProposalColumns()}
                    disableColumnMenu
                    disableSelectionOnClick
                    getRowHeight={() => 'auto'}
                    loading={isLoading}
                    pageSize={5}
                    rows={commercialOffers}
                    rowsPerPageOptions={[5]}
                    sx={{
                        '& .MuiDataGrid-virtualScroller': {
                            ...scroll.object,
                        },
                        '& .MuiDataGrid-root': {
                            height: '100%',
                        },
                    }}
                />
                <Box mt="auto" py={1}>
                    <Button
                        color="secondary"
                        component={RouterLink}
                        fullWidth
                        to="/proposals"
                        variant="text"
                        visible={canView}
                        sx={{ textTransform: 'none' }}
                    >
                        {t('See more proposals')}
                    </Button>
                </Box>
            </Card.Body>
        </Card>
    );
};

Container.propTypes = {
    canView: PropTypes.bool,
    commercialOffers: PropTypes.array,
    commercialOffersByRate: PropTypes.func,
    fetchCommercialOffers: PropTypes.func,
    groupedRates: PropTypes.array,
    isLoading: PropTypes.bool,
    menuOptions: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    commercialOffers: selectors.getDataFetchCommercialOffers,
    isLoading: selectors.getIsFetchingCommercialOffers,
});

const mapDispatchToProps = (dispatch) => ({
    commercialOffersByRate: (id) =>
        dispatch(actions.commercialOffersByRate(id)),
    fetchCommercialOffers: () => dispatch(actions.fetchCommercialOffers()),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION),
)(Container);
