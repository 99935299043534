import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import {
    getSubscriptionStatus,
    getSubscriptionsStatus,
} from 'common/utils/helpers';
import { isValidAccess } from 'common/utils/helpers/permissionsV2';
import { getHasOnboarding } from 'common/utils/helpers/session';

import * as profileSelectors from '../../modules/profile/selectors';

import LayoutComponent from './LayoutComponent';
import LayoutPublicComponent from './LayoutPublicComponent';

const LayoutRoute = ({
    checkOnboarding,
    checkPaymentDue = false,
    component,
    isHidden,
    isPublic,
    layout,
    multiBranchMode,
    redirect = { pathname: '/login' },
    requiredPermissions,
    subscription,
    subscriptions,
    subscriptionVersion,
    ...rest
}) => {
    const hasOnboarding = getHasOnboarding();
    const { allHasPaymentDue, isMultipleSubscription } =
        getSubscriptionsStatus(subscriptions);
    const { isActive, isPaymentDue } = getSubscriptionStatus(subscription);
    const hasPaymentDueV4 =
        subscriptionVersion === 'v4' && isActive && isPaymentDue;
    const hasPaymentDueV5 =
        (subscriptionVersion === 'v5' &&
            !isMultipleSubscription &&
            isActive &&
            isPaymentDue) ||
        (subscriptionVersion === 'v5' &&
            isMultipleSubscription &&
            allHasPaymentDue);

    if (checkPaymentDue && (hasPaymentDueV4 || hasPaymentDueV5))
        return <Redirect to={{ pathname: '/profile/subscription' }} />;
    if (checkOnboarding && hasOnboarding)
        return <Redirect to={{ pathname: '/onboarding' }} />;
    if (isHidden) return <Redirect to={redirect} />;
    if (!isValidAccess(isPublic, requiredPermissions))
        return <Redirect to={redirect} />;

    return (
        <Route
            {...rest}
            render={(matchProps) =>
                isPublic ? (
                    <LayoutPublicComponent
                        component={component}
                        layout={layout}
                        matchProps={matchProps}
                        rest={rest}
                    />
                ) : (
                    <LayoutComponent
                        component={component}
                        layout={layout}
                        matchProps={matchProps}
                        multiBranchMode={multiBranchMode}
                        rest={rest}
                    />
                )
            }
        />
    );
};

LayoutRoute.defaultProps = {
    checkOnboarding: false,
    checkPaymentDue: false,
    isHidden: false,
    isPublic: false,
    redirect: { pathname: '/login' },
};

LayoutRoute.propTypes = {
    checkOnboarding: PropTypes.bool,
    checkPaymentDue: PropTypes.bool,
    component: PropTypes.func,
    isHidden: PropTypes.bool,
    isPublic: PropTypes.bool,
    layout: PropTypes.func,
    multiBranchMode: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    redirect: PropTypes.object,
    requiredPermissions: PropTypes.array,
    subscription: PropTypes.object,
    subscriptions: PropTypes.array,
    subscriptionVersion: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    subscription: profileSelectors.getMainSubscription,
    subscriptions: profileSelectors.getSubscriptions,
    subscriptionVersion: profileSelectors.getSubscriptionVersion,
});

export default connect(mapStateToProps, null)(LayoutRoute);
