import React, { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import { Avatar, Box, Tooltip } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FaRobot } from 'react-icons/fa';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from 'sunwise-ui';

import zapLogo from 'resources/zap_logo.svg';

import afterSalesMeasurementSource from '../../afterSalesMeasurementSource';
import afterSalesSettings from '../../afterSalesSettings';
import { INTEGRATION_TYPES } from '../../afterSalesSettings/constants';
import * as afterSalesSettingsSelectors from '../../afterSalesSettings/selectors';

import Providers from './Providers';
import ShareCard from './ShareCard';

const Settings = ({
    autoReport,
    canDelete,
    canModify,
    canModifyAdvancedFeatures,
    canViewAdvancedFeatures,
    integrationType,
    rate,
    selectedProjectId,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { t } = useTranslation();

    return (
        <Accordion
            expanded={isExpanded}
            onChange={() => setIsExpanded(!isExpanded)}
            disableGutters
            sx={{
                boxShadow: 'none!important',
                '&::before': { display: 'none' },
            }}
        >
            <AccordionSummary
                expandIcon={null}
                sx={{
                    borderBottom: (theme) =>
                        `1px solid ${theme.palette.divider}`,
                    gap: 2,
                    justifyContent: 'flex-start',
                    p: 0,
                    '& .MuiAccordionSummary-content': {
                        alignItems: 'center',
                        flexGrow: 0,
                        gap: 1,
                        width: '100%',
                    },
                }}
            >
                <SettingsIcon />

                <Typography variant="body2" fontWeight="bold">
                    {t('Setting')}
                </Typography>

                {autoReport && (
                    <Tooltip title={t('Automatic reporting')}>
                        <Box alignItems="center" display="flex">
                            <FaRobot size={16} />
                        </Box>
                    </Tooltip>
                )}

                {!isExpanded && rate?.name && (
                    <Typography
                        variant="caption"
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {rate?.name}
                    </Typography>
                )}

                <ExpandMoreIcon sx={{ mr: 'auto' }} />

                {integrationType == INTEGRATION_TYPES.LISA_ENERGY && (
                    <Avatar size="small" src={zapLogo} />
                )}

                {!isExpanded && <Providers />}
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0 }}>
                <afterSalesSettings.Container
                    canModify={canModify}
                    canModifyAdvancedFeatures={canModifyAdvancedFeatures}
                    canViewAdvancedFeatures={canViewAdvancedFeatures}
                    selectedProjectId={selectedProjectId}
                />

                <afterSalesMeasurementSource.Container
                    canDelete={canDelete}
                    canModify={canModify}
                    selectedProjectId={selectedProjectId}
                />

                <ShareCard selectedProjectId={selectedProjectId} />
            </AccordionDetails>
        </Accordion>
    );
};

const mapStateToProps = createStructuredSelector({
    autoReport: afterSalesSettingsSelectors.getAfterSalesSettingsAutoReport,
    integrationType:
        afterSalesSettingsSelectors.getAfterSalesSettingsIntegrationType,
    rate: afterSalesSettingsSelectors.getAfterSalesSettingsRate,
});

Settings.propTypes = {
    autoReport: PropTypes.bool,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    canModifyAdvancedFeatures: PropTypes.bool,
    canViewAdvancedFeatures: PropTypes.bool,
    integrationType: PropTypes.number,
    rate: PropTypes.object,
    selectedProjectId: PropTypes.string,
};

export default connect(mapStateToProps)(Settings);
