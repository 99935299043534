import React from 'react';

import PropTypes from 'prop-types';
import { Grid } from 'sunwise-ui';

import AddAlliance from '../../../proposalGeneratorAddAlliance';

import AllianceItem from './AllianceItem';

const CardList = ({ handleOnClikAlliance, isLocked, items }) => {
    return (
        <Grid container py={2}>
            {items.map((alliance) => (
                <Grid key={alliance.id} size={{ lg: 3, md: 5, xs: 9 }}>
                    <AllianceItem
                        alliance={alliance}
                        disabled={isLocked}
                        handleOnClikAlliance={handleOnClikAlliance}
                    />
                </Grid>
            ))}
            <AddAlliance.Container
                handleOnClikAlliance={handleOnClikAlliance}
            />
        </Grid>
    );
};

CardList.propTypes = {
    handleOnClikAlliance: PropTypes.func,
    isLocked: PropTypes.bool,
    items: PropTypes.array,
};

export default CardList;
