import i18next from 'i18next';

import { transformDateFormat } from 'common/utils/dates';
import {
    getContactName,
    transformObjectToSelectOptions,
} from 'common/utils/helpers';
import {
    getProposalChartInfoPriceSeries,
    hexToRgba,
} from 'common/utils/helpersChart';

import { REPORT_STATUS_KEYS } from '../reportList/constants';
import { CHART_COLORS, PERIOD_TYPES } from '../reportPreview/constants';
import { getPeriodLabel } from '../reportPreview/helpers';

import { ELECTRIC_BILL_STATUS_KEYS } from './constants';

export const getReportRedirectConfig = (reportStatus) => {
    if (
        [REPORT_STATUS_KEYS.GENERATED, REPORT_STATUS_KEYS.SENT].includes(
            reportStatus,
        )
    )
        return {
            baseUrl: '/report-review-pro',
            label: 'Go to preview',
        };

    return { baseUrl: '/report', label: 'Go to the report' };
};

export const getFilteredSelectOptions = ({
    agentId,
    contactId,
    filtersData,
    politicalDivisionId,
    projectId,
    projectStatusId,
    rateId,
} = {}) => {
    if (!filtersData?.length)
        return {
            agents: [],
            contacts: [],
            politicalDivisions: [],
            projectStatuses: [],
            projects: [],
            rates: [],
            zipCodes: [],
        };

    const filters = {
        agents: {},
        contacts: {},
        politicalDivisions: {},
        projectStatuses: {},
        projects: {},
        rates: {},
        reportStatuses: {},
        zipCodes: {},
    };

    for (const reportData of filtersData) {
        const { customize_rate, project, political_division1, rate, zip_code } =
            reportData || {};

        const {
            contact,
            id: _projectId,
            name: _projectName,
            status_project,
        } = project || {};

        const { agent, id: _contactId } = contact || {};

        filters.agents[agent?.id] = {
            label: getContactName(contact),
            value: agent?.id,
        };

        if (agentId && agent?.id !== agentId) continue;

        filters.contacts[_contactId] = {
            label: getContactName(contact),
            value: _contactId,
        };

        if (contactId && contact?.id !== contactId) continue;

        filters.projectStatuses[status_project?.id] = {
            label: status_project?.name,
            value: status_project?.id,
        };

        if (projectStatusId && status_project?.id !== projectStatusId) continue;

        if (customize_rate?.id)
            filters.rates[customize_rate.id] = {
                group: i18next.t('Custom', { context: 'female', count: 2 }),
                label: customize_rate.rate_name?.name,
                value: customize_rate.id,
            };

        if (rate?.id)
            filters.rates[rate?.id] = {
                group: i18next.t('Certificated'),
                label: rate.name,
                value: rate.id,
            };

        if (rateId && customize_rate?.id !== rateId && rate?.id !== rateId)
            continue;

        filters.projects[_projectId] = {
            label: _projectName,
            value: _projectId,
        };

        if (projectId && _projectId !== projectId) continue;

        filters.politicalDivisions[political_division1?.id] = {
            label: political_division1?.name,
            value: political_division1?.id,
        };

        if (
            politicalDivisionId &&
            political_division1?.id !== politicalDivisionId
        )
            continue;

        filters.zipCodes[zip_code] = { label: zip_code, value: zip_code };
    }

    return Object.entries(filters).reduce((acc, [key, value]) => {
        acc[key] = transformObjectToSelectOptions(value);
        return acc;
    }, {});
};

const flattenReportHistoricalData = (groups, getterFn) => {
    if (!groups?.length || !getterFn) return [];

    const periodsData = new Map();

    for (const group of groups) {
        if (!group?.periods?.length) continue;

        for (const period of group.periods) {
            if (!period) continue;

            const { key, value } = getterFn(period) || {};

            if (value) periodsData.set(key, value);
        }
    }

    return Array.from(periodsData.values());
};

export const getReportHistoricalFiles = (groups) =>
    flattenReportHistoricalData(groups, (item) => {
        const url = item.historical?.file;
        if (!url) return null;

        return {
            key: url,
            value: {
                label: getPeriodLabel(
                    item.historical.final_date,
                    item.historical.is_bimonthly,
                ),
                status: ELECTRIC_BILL_STATUS_KEYS.UPDATED,
                uploadOrigin: item.historical?.upload_origin,
                url,
            },
        };
    });

export const getReportPeriods = (
    groups,
    localeDateFormat,
    currentDateFormat = 'dd/MM/yyyy',
) =>
    flattenReportHistoricalData(groups, (item) => {
        const initial_date = item.historical?.initial_date;
        const final_date = item.historical?.final_date;

        if (!initial_date || !final_date) return null;

        const initialDate = transformDateFormat(
            initial_date,
            localeDateFormat,
            currentDateFormat,
        );

        const finalDate = transformDateFormat(
            final_date,
            localeDateFormat,
            currentDateFormat,
        );

        const value = `${initialDate || ''} - ${finalDate || ''}`;
        return { key: value, value };
    });

export const getChartsInfo = ({
    groupSelected = 0,
    periodsData,
    proposalInfoIsBimonthly,
    reportPeriods,
}) => {
    const categories = [];
    const energyDates = [[], []];
    const consumptionSeries = { historical: [], compared: [] };
    const generationSeries = { historical: [], compared: [] };
    let currentLabel = '';
    let paymentSeriesData = { periods: [], keys: [] };
    let paymentSeries = [];

    const periodsByGroupId =
        periodsData?.reduce((acc, curr) => {
            if (!acc[curr.group_period_id]) acc[curr.group_period_id] = [];

            acc[curr.group_period_id].push(curr);

            return acc;
        }, {}) || {};

    const groupedPeriods = reportPeriods?.[groupSelected] || {};

    const currentGroupedPeriods = periodsByGroupId[groupedPeriods.id] || [];

    for (const period of currentGroupedPeriods) {
        const { comparison_periods, current_periods, period_type } = period;

        if (current_periods) {
            currentLabel = getPeriodLabel(
                current_periods?.final_date,
                current_periods?.is_bimonthly,
            );
        } else {
            currentLabel = 'N/A';
        }
        categories.push(currentLabel);
        consumptionSeries.historical.push(
            current_periods?.total?.consumption?.value || 0,
        );
        generationSeries.historical.push(
            current_periods?.total?.generation?.value || 0,
        );
        energyDates[1].push({
            finalDate: current_periods?.final_date,
            initialDate: current_periods?.initial_date,
            label: currentLabel,
        });

        paymentSeriesData.periods.push(
            { ...(current_periods ?? {}), label: currentLabel },
            { ...(current_periods ?? {}), label: currentLabel },
        );
        paymentSeriesData.keys.push(
            'total_info_price_consumption',
            'real_info_price_consumption',
        );

        // Compared period
        switch (period_type) {
            case PERIOD_TYPES.PROPOSAL_LEGACY:
            case PERIOD_TYPES.PROPOSAL: {
                consumptionSeries.compared.push(
                    comparison_periods?.consumption || 0,
                );
                generationSeries.compared.push(
                    comparison_periods?.generation || 0,
                );
                energyDates[0].push({
                    finalDate: comparison_periods?.date_finish,
                    initialDate: comparison_periods?.date_start,
                    label: getPeriodLabel(
                        comparison_periods?.date_finish,
                        proposalInfoIsBimonthly,
                    ),
                    periodType: period_type,
                });

                break;
            }
            case PERIOD_TYPES.HISTORICAL: {
                consumptionSeries.compared.push(
                    comparison_periods?.total?.consumption?.value || 0,
                );
                generationSeries.compared.push(
                    comparison_periods?.total?.generation?.value || 0,
                );
                energyDates[0].push({
                    finalDate: comparison_periods?.final_date,
                    initialDate: comparison_periods?.initial_date,
                    label: getPeriodLabel(
                        comparison_periods?.final_date,
                        comparison_periods?.is_bimonthly,
                    ),
                    periodType: period_type,
                });

                break;
            }
            default: {
                consumptionSeries.compared.push(0);
                generationSeries.compared.push(0);
                energyDates[0].push({
                    finalDate: '',
                    initialDate: '',
                    label: 'N/A',
                    periodType: period_type,
                });
            }
        }
    }

    paymentSeries = getProposalChartInfoPriceSeries(
        paymentSeriesData.periods,
        paymentSeriesData.keys,
    );

    return {
        categories,
        consumptionSeries: [
            {
                backgroundColor: hexToRgba(CHART_COLORS.CONSUMPTION[0], 0.8),
                data: consumptionSeries.compared,
                label: i18next.t('Compared period'),
            },
            {
                backgroundColor: hexToRgba(CHART_COLORS.CONSUMPTION[1], 0.8),
                data: consumptionSeries.historical,
                label: i18next.t('Current period'),
            },
        ],
        energyDates,
        generationSeries: [
            {
                backgroundColor: hexToRgba(CHART_COLORS.GENERATION[0], 0.8),
                data: generationSeries.compared,
                label: i18next.t('Compared period'),
            },
            {
                backgroundColor: hexToRgba(CHART_COLORS.GENERATION[1], 0.8),
                data: generationSeries.historical,
                label: i18next.t('Current period'),
            },
        ],
        paymentPeriods: paymentSeriesData.periods,
        paymentSeries,
    };
};
