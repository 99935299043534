import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import ProviderImage from 'common/components/ProviderImage';
import { transformDateFormat } from 'common/utils/dates';
import { getDateFormatByLocale } from 'common/utils/helpers/multiregion';

import { DATE_FORMATS } from '../../afterSalesSettings/constants';
import * as credentialIntegrationsSelectors from '../../credentialIntegrations/selectors';
import * as actions from '../actions';
import { SOURCE_FIELD_KEYS } from '../constants';
import * as selectors from '../selectors';

import InfoCell from './InfoCell';
import Table from './Table';

const ApiSourceList = ({
    canDelete,
    canModify,
    disabled,
    initializeSourceFieldsForm,
    isFetchingPowerStations,
    powerStations,
    powerStationsStartDate,
    prepareUnassignPowerStation,
    providersDictionary,
    selectedProjectId,
    setSourceFieldsFormModalOpenStatus,
}) => {
    const { t } = useTranslation();
    const localeDateFormat = getDateFormatByLocale();
    const infoKeys = Object.values(SOURCE_FIELD_KEYS);

    const powerStationsColumns = [
        {
            align: 'left',
            field: 'provider',
            headerName: t('Provider'),
            renderCell: ({ row }) => {
                const provider = providersDictionary[row?.provider_id || 0];
                return (
                    <ProviderImage
                        provider={provider}
                        style={{ maxHeight: 60, maxWidth: 80 }}
                    />
                );
            },
            sortable: false,
            width: 120,
        },
        {
            align: 'left',
            field: 'name',
            flex: 1,
            headerName: t('Name'),
            minWidth: 200,
            sortable: false,
            valueGetter: ({ row }) =>
                row?.name || row?.read_requirements?.station_id || '--',
        },
        {
            align: 'left',
            field: 'start_date',
            flex: 1,
            headerName: t('Commissioning date'),
            minWidth: 200,
            sortable: false,
            valueGetter: ({ row }) => {
                const date = row?.first_read || powerStationsStartDate;
                return date
                    ? transformDateFormat(
                          date,
                          localeDateFormat,
                          DATE_FORMATS.AFTER_SALES,
                      )
                    : '--';
            },
        },
        {
            align: 'left',
            field: 'information',
            flex: 2,
            headerName: t('Information'),
            minWidth: 200,
            renderCell: ({ row }) => <InfoCell infoKeys={infoKeys} row={row} />,
            sortable: false,
        },
        {
            align: 'right',
            field: 'actions',
            headerName: '',
            renderCell: ({ row }) => (
                <DropdownCardMenu
                    items={[
                        {
                            disabled,
                            label: t('Delete'),
                            icon: <DeleteIcon />,
                            handleClick: () =>
                                prepareUnassignPowerStation(
                                    row.id,
                                    selectedProjectId,
                                ),
                            visible: canDelete,
                        },
                        {
                            disabled,
                            label: t('Edit'),
                            icon: <EditIcon />,
                            handleClick: () => {
                                const values = Object.values(
                                    SOURCE_FIELD_KEYS,
                                ).reduce(
                                    (acc, key) => ({
                                        ...acc,
                                        [key]: row[key] || false,
                                    }),
                                    {},
                                );

                                initializeSourceFieldsForm({
                                    ...values,
                                    project_id: row.id,
                                });
                                setSourceFieldsFormModalOpenStatus(true);
                            },
                            visible: canModify,
                        },
                    ]}
                    visible={canDelete}
                />
            ),
            sortable: false,
            width: 50,
        },
    ];

    return (
        <Table
            columns={powerStationsColumns}
            isLoading={isFetchingPowerStations}
            items={powerStations}
            selectable={false}
        />
    );
};

ApiSourceList.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    disabled: PropTypes.bool,
    initializeSourceFieldsForm: PropTypes.func,
    isFetchingPowerStations: PropTypes.bool,
    powerStations: PropTypes.array,
    powerStationsStartDate: PropTypes.string,
    prepareUnassignPowerStation: PropTypes.func,
    providersDictionary: PropTypes.object,
    selectedProjectId: PropTypes.string,
    setSourceFieldsFormModalOpenStatus: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    isFetchingPowerStations: selectors.getIsFetchingPowerStations,
    powerStationsStartDate: selectors.getPowerStationsDataStartDate,
    providersDictionary: credentialIntegrationsSelectors.getProvidersDictionary,
});

const mapDispatchToProps = (dispatch) => ({
    initializeSourceFieldsForm: (params) =>
        dispatch(actions.initializeSourceFieldsForm(params)),
    prepareUnassignPowerStation: (id, sunwiseProject) =>
        dispatch(actions.prepareUnassignPowerStation(id, sunwiseProject)),
    setSourceFieldsFormModalOpenStatus: (value) =>
        dispatch(actions.setSourceFieldsFormModalOpenStatus(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiSourceList);
