import { array, bool, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { DATE_VALIDATION_TEXT, maximumNumberCharacters, REQUIRED_TEXT } =
        getValidationTexts();

    return object().shape({
        assigned_to: array().min(1, REQUIRED_TEXT),
        description: string()
            .required(REQUIRED_TEXT)
            .max(1000, maximumNumberCharacters(1000))
            .nullable(),
        notification_at: string(DATE_VALIDATION_TEXT).when('remember', {
            is: true,
            then: (schema) => schema.required(REQUIRED_TEXT),
            otherwise: (schema) => schema.nullable(),
        }),
        remember: bool().nullable(),
        tracking_purpose: string().required(REQUIRED_TEXT).nullable(),
        tracking_type: string().required(REQUIRED_TEXT).nullable(),
    });
};
