import React from 'react';

import {
    Alert,
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
} from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
    ReactHookFormInputDatePicker,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import InfoToolTip from 'common/components/InfoToolTip';

import * as actions from '../actions';
import {
    getMinInitialDate,
    getPeriodicityTypeText,
    handleChangeLastConsumption,
} from '../helpers';
import * as selectors from '../selectors';

import AccordionForm from './Accordion';
import FormatDatesButton from './formatDatesButton';
import IntakeCapture from './intakeForms/';
import AlertBadge from './warnings/AlertBadge';

const IntakeManualCapture = ({
    changeMonths,
    control,
    disabled,
    getValues,
    handleNormalizeDates,
    hasFormErrors,
    isFromCsv,
    isManually,
    openDevicesModal,
    profilesConsumptionData,
    rateConfiguration,
    ratesDictionary,
    selectedRate,
    setOpenDevicesModal,
    setValue,
    summary,
    warnings,
}) => {
    const { t } = useTranslation();
    const disabledConsumptionFieldsByCsv = disabled || isFromCsv;

    const props = {
        control,
        disabled,
        disabledConsumptionFieldsByCsv,
        getValues,
        handleNormalizeDates,
        profilesConsumptionData,
        rateConfiguration,
        ratesDictionary,
        selectedRate,
        setValue,
        summary,
        summaryWarnings: warnings?.summaryWarnings,
    };

    return (
        <AccordionForm
            title={
                <AlertBadge
                    hasFormErrors={hasFormErrors}
                    label={t('Consumption history')}
                    warnings={warnings}
                />
            }
            titleWithBadge={false}
        >
            {warnings?.hasPredictedValues && (
                <Alert severity="warning">
                    {t(
                        'The CFE service does not contain a minimum year of history, Sunwise has filled in the values (marked in yellow), based on the average of the known consumptions',
                    )}
                </Alert>
            )}

            {isManually && (
                <Grid container>
                    {selectedRate && (
                        <Grid size={{ md: 'grow', xs: 18 }}>
                            {selectedRate.isCertified && (
                                <ReactHookFormSelect
                                    control={control}
                                    disabled={disabledConsumptionFieldsByCsv}
                                    fullWidth
                                    label={t('Period')}
                                    name="is_bimonthly"
                                    onChange={(value) => {
                                        changeMonths({
                                            formValues: getValues(),
                                            periodicityType:
                                                value.target.value === '1'
                                                    ? 1
                                                    : 0,
                                            rate: selectedRate,
                                            rateConfiguration,
                                            setValue,
                                        });
                                    }}
                                    options={[
                                        { label: t('Monthly'), value: '0' },
                                        { label: t('Bimonthly'), value: '1' },
                                    ]}
                                    variant="filled"
                                />
                            )}

                            {!selectedRate.isCertified && (
                                <ReactHookFormSelect
                                    control={control}
                                    disabled={disabledConsumptionFieldsByCsv}
                                    fullWidth
                                    label={t('Period')}
                                    name="periodicity_type"
                                    options={[
                                        {
                                            label: getPeriodicityTypeText(
                                                selectedRate.periodicityType,
                                                t,
                                            ),
                                            value: selectedRate.periodicityType,
                                        },
                                    ]}
                                    variant="filled"
                                />
                            )}
                        </Grid>
                    )}

                    <Grid size={{ md: 'grow', xs: 18 }}>
                        <Box display="flex" gap={2}>
                            <ReactHookFormInputDatePicker
                                control={control}
                                disabled={disabledConsumptionFieldsByCsv}
                                label={t('Date of last receipt')}
                                maxDate={new Date()}
                                minDate={getMinInitialDate()}
                                name="last_consumption"
                                onChange={(value) =>
                                    handleChangeLastConsumption({
                                        getValues,
                                        handleNormalizeDates,
                                        profilesConsumptionData,
                                        rateConfiguration,
                                        ratesDictionary,
                                        setValue,
                                        value,
                                    })
                                }
                                variant="filled"
                            />

                            <InfoToolTip variant="no-wrapper" sxBox={{ mb: 2 }}>
                                {t('Enter the end date of the billed period')}
                            </InfoToolTip>

                            {!isFromCsv && (
                                <FormatDatesButton
                                    disabled={disabled}
                                    getValues={getValues}
                                    handleNormalizeDates={handleNormalizeDates}
                                    profilesConsumptionData={
                                        profilesConsumptionData
                                    }
                                    rateConfiguration={rateConfiguration}
                                    ratesDictionary={ratesDictionary}
                                    selectedRate={selectedRate}
                                    setValue={setValue}
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            )}

            {selectedRate && <IntakeCapture {...props} />}

            <FormControl sx={{ alignItems: 'flex-end', display: 'flex' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={openDevicesModal}
                            disabled={disabled}
                            name="open_devices_modal"
                            onChange={(e) =>
                                setOpenDevicesModal(e?.target?.checked)
                            }
                            sx={{ flexDirection: 'row-reverse' }}
                        />
                    }
                    label={t('Add device', { count: 2 })}
                />
            </FormControl>
        </AccordionForm>
    );
};

const mapStateToProps = createStructuredSelector({
    isFromCsv: selectors.getIsFromCsv,
    isManually: selectors.getIsManually,
    rateConfiguration: selectors.getFetchScheduleRateConfigurationData,
});

const mapDispatchToProps = (dispatch) => ({
    handleNormalizeDates: (config) =>
        dispatch(actions.handleNormalizeDates(config)),
    setAutomaticHasChanges: (value) =>
        dispatch(actions.setAutomaticHasChanges(value)),
});

IntakeManualCapture.propTypes = {
    changeMonths: PropTypes.func,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    getValues: PropTypes.func,
    handleNormalizeDates: PropTypes.func,
    hasFormErrors: PropTypes.bool,
    isFromCsv: PropTypes.bool,
    isManually: PropTypes.bool,
    openDevicesModal: PropTypes.bool,
    profilesConsumptionData: PropTypes.array,
    rateConfiguration: PropTypes.object,
    ratesDictionary: PropTypes.object,
    selectedRate: PropTypes.object,
    setOpenDevicesModal: PropTypes.func,
    setValue: PropTypes.func,
    summary: PropTypes.array,
    warnings: PropTypes.object,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IntakeManualCapture);
