import React, { useMemo } from 'react';

import AddIcon from '@mui/icons-material/Add';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Button, Typography } from '@sunwisesoftware/sunwise-ui';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Table } from 'common/components/collapsibleTables';
import {
    ReactHookFormCheck,
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect,
    ReactHookFormInput,
    ReactHookFormSelect2,
    ReactHookFormTimePicker,
} from 'common/components/form/bootstrap';
import { DAYS_NAMES, MONTHS } from 'common/constants';
import { numberFormat } from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import {
    COLORS,
    DEVICE_CONFIG,
    DEVICE_DEFAULT_VALUES,
    DEVICES,
    TABLE_SECTIONS,
    UNITS,
} from '../constants';
import { formatMultipleItemsLabel } from '../helpers';

import ActionsCell from './ActionsCell';

const inputVariant = 'standard';

const DevicesTable = ({
    control,
    devicesValues,
    disabled,
    errors,
    getValues,
    setValue,
}) => {
    const { t } = useTranslation();
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: 'devices',
    });

    const countryCurrencyLocale = getCountryCurrencyLocale();

    const deviceOptions = useMemo(() => {
        return [
            {
                label: '',
                options: Object.entries(DEVICE_CONFIG).map(([key, value]) => ({
                    label: t(value.name),
                    value: parseInt(key),
                })),
            },
        ];
    }, [DEVICE_CONFIG, t]);

    const monthOptions = useMemo(() => {
        return [
            {
                label: '',
                options: MONTHS.map((month, monthIndex) => ({
                    label: t(month),
                    value: monthIndex,
                })),
            },
        ];
    }, [MONTHS, t]);

    const unitOptions = useMemo(() => {
        return Object.entries(UNITS).map(([key, { label }]) => ({
            label,
            value: parseInt(key),
        }));
    }, [UNITS]);

    const dayOptions = useMemo(
        () => [
            {
                label: '',
                options: DAYS_NAMES.map((day, dayIndex) => ({
                    label: t(day),
                    value: dayIndex,
                })),
            },
        ],
        [DAYS_NAMES, t],
    );

    const columnGroups = [
        {
            bgColor: COLORS[TABLE_SECTIONS.DEVICE],
            expandedColumns: [
                {
                    label: t('Type'),
                    key: 'device',
                    renderCell: (_, index) => (
                        <ReactHookFormSelect2
                            control={control}
                            disabled={disabled}
                            isClearable={false}
                            name={`devices.${index}.device`}
                            onChange={(value) =>
                                update(index, DEVICE_DEFAULT_VALUES[value])
                            }
                            options={deviceOptions}
                            variant={inputVariant}
                            sx={{ minWidth: 'max-content' }}
                        />
                    ),
                    sxCell: { minWidth: '180px' },
                },
                {
                    label: t('Name'),
                    key: 'device_name',
                    renderCell: (_, index) => (
                        <ReactHookFormInput
                            control={control}
                            disabled={disabled}
                            fullWidth
                            name={`devices.${index}.device_name`}
                            variant={inputVariant}
                        />
                    ),
                    sxCell: { minWidth: '150px' },
                },
                {
                    label: t('Quantity'),
                    key: 'quantity',
                    renderCell: (_, index) => (
                        <ReactHookFormIntlNumberInput
                            allowDecimals={false}
                            allowNegativeValue={false}
                            control={control}
                            disabled={disabled}
                            fullWidth
                            min="1"
                            name={`devices.${index}.quantity`}
                            variant={inputVariant}
                        />
                    ),
                    sxCell: { minWidth: '150px' },
                },
            ],
            collapsedColumns: [
                {
                    label: t('Device'),
                    key: 'device',
                    renderCell: (_, index) => {
                        const row = devicesValues?.[index] || {};

                        const deviceConfig = DEVICE_CONFIG[row?.device];

                        if (!deviceConfig) return '--';

                        return (
                            <Box alignItems="center" display="flex" gap={1}>
                                {!isEmpty(errors?.[index]) && (
                                    <WarningIcon color="error" />
                                )}

                                <deviceConfig.Icon color="primary" />

                                <Typography>{row?.device_name}</Typography>
                            </Box>
                        );
                    },
                    sxCell: { minWidth: '200px', width: '200px' },
                },
            ],
            label: t('Equipment details'),
            key: TABLE_SECTIONS.DEVICE,
        },
        {
            bgColor: COLORS[TABLE_SECTIONS.ELECTRIC_PARAMETERS],
            label: t('Electric parameters'),
            expandedColumns: [
                {
                    label: t('Power consumption'),
                    key: 'power',
                    renderCell: (_, index) => (
                        <ReactHookFormIntlNumberInput
                            allowNegativeValue={false}
                            control={control}
                            disabled={disabled}
                            fullWidth
                            min="1"
                            name={`devices.${index}.power`}
                            variant={inputVariant}
                        />
                    ),
                },
                {
                    label: t('Unit'),
                    key: 'unit',
                    renderCell: (_, index) => (
                        <ReactHookFormSelect
                            control={control}
                            disabled={disabled}
                            name={`devices.${index}.unit`}
                            options={unitOptions}
                            variant={inputVariant}
                            sxFormControl={{ maxWidth: 'max-content' }}
                        />
                    ),
                },
                {
                    label: t('Current', { context: 'electricity' }),
                    key: 'effective_power_current',
                    renderCell: (_, index) => (
                        <ReactHookFormIntlNumberInput
                            allowNegativeValue={false}
                            append="A"
                            control={control}
                            disabled={disabled}
                            fullWidth
                            name={`devices.${index}.effective_power_current`}
                            variant={inputVariant}
                        />
                    ),
                    visible: false,
                },
                {
                    label: t('Soft start'),
                    key: 'soft_start',
                    renderCell: (_, index) => (
                        <ReactHookFormCheck
                            control={control}
                            disabled={disabled}
                            name={`devices.${index}.soft_start`}
                            sxFormControl={{ flexShrink: 0 }}
                            variant={inputVariant}
                        />
                    ),
                    sxCell: { width: '100px' },
                    visible: false,
                },
            ],
            collapsedColumns: [
                {
                    label: t('Power consumption'),
                    key: 'power_collapsed',
                    renderCell: (_, index) => {
                        const row = devicesValues?.[index] || {};

                        const unit = UNITS[row.unit]?.label || '';

                        const power = numberFormat(
                            row?.quantity * row.power || 0,
                            {
                                decimals: 1,
                                locale: countryCurrencyLocale,
                                style: 'decimal',
                            },
                        );

                        return `${power} ${unit}`;
                    },
                    sxCell: { minWidth: '200px', width: '200px' },
                },
            ],
            key: TABLE_SECTIONS.ELECTRIC_PARAMETERS,
        },
        {
            bgColor: COLORS[TABLE_SECTIONS.TIME],
            label: t('Usage schedule'),
            expandedColumns: [
                {
                    label: t('Month', { count: 2 }),
                    key: 'months',
                    renderCell: (_, index) => (
                        <ReactHookFormSelect2
                            blurOnSelect={false}
                            control={control}
                            disabled={disabled}
                            limitTags={1}
                            multiple
                            name={`devices.${index}.months`}
                            options={monthOptions}
                            sx={{ minWidth: '200px' }}
                            variant={inputVariant}
                        />
                    ),
                },
                {
                    label: t('Day', { count: 2 }),
                    key: 'weekdays',
                    renderCell: (_, index) => (
                        <ReactHookFormSelect2
                            blurOnSelect={false}
                            control={control}
                            disabled={disabled}
                            limitTags={1}
                            multiple
                            name={`devices.${index}.weekdays`}
                            options={dayOptions}
                            sx={{ minWidth: '230px' }}
                            variant={inputVariant}
                        />
                    ),
                },
                {
                    label: t('Start time'),
                    key: 'start_time',
                    renderCell: (_, index) => (
                        <ReactHookFormTimePicker
                            control={control}
                            format="hh a"
                            name={`devices.${index}.start_time`}
                            variant={inputVariant}
                            views={['hours']}
                        />
                    ),
                },
                {
                    label: t('End time'),
                    key: 'end_time',
                    renderCell: (_, index) => (
                        <ReactHookFormTimePicker
                            control={control}
                            format="hh a"
                            name={`devices.${index}.end_time`}
                            variant={inputVariant}
                            views={['hours']}
                        />
                    ),
                },
            ],
            collapsedColumns: [
                {
                    label: t('Usage schedule'),
                    key: 'months',
                    renderCell: (_, index) => {
                        const row = devicesValues?.[index] || {};

                        const startTime = row?.start_time || '--';
                        const endTime = row?.end_time || '--';

                        return (
                            <Box
                                alignItems="center"
                                display="flex"
                                flexWrap="wrap"
                                gap={1}
                                justifyContent="center"
                            >
                                <Typography>
                                    {formatMultipleItemsLabel(
                                        monthOptions?.[0]?.options,
                                        row?.months,
                                    )}{' '}
                                    /
                                </Typography>

                                <Typography>
                                    {formatMultipleItemsLabel(
                                        dayOptions?.[0]?.options,
                                        row?.weekdays,
                                    )}{' '}
                                    /
                                </Typography>

                                <Typography>
                                    {startTime} - {endTime}
                                </Typography>
                            </Box>
                        );
                    },
                    sxCell: { minWidth: '250px', width: '250px' },
                },
            ],
            key: TABLE_SECTIONS.TIME,
        },
        {
            bgColor: COLORS[TABLE_SECTIONS.BACKUP],
            label: '',
            expandedColumns: [
                {
                    label: t('Backup'),
                    key: 'backup',
                    renderCell: (_, index) => (
                        <ReactHookFormCheck
                            control={control}
                            disabled={disabled}
                            name={`devices.${index}.backup`}
                            sxFormControl={{ flexShrink: 0 }}
                            variant={inputVariant}
                        />
                    ),
                    sxCell: { minWidth: '80px', width: '80px' },
                },
            ],
            key: TABLE_SECTIONS.BACKUP,
            useSameColor: true,
        },
        {
            bgColor: COLORS[TABLE_SECTIONS.ACTIONS],
            label: '',
            expandedColumns: [
                {
                    label: '',
                    key: 'actions',
                    renderCell: (_, index) => (
                        <ActionsCell
                            disabled={disabled}
                            installed={devicesValues?.[index]?.installed}
                            onClickDelete={() => remove(index)}
                            onClickDuplicate={() =>
                                append({ ...getValues(`devices.${index}`) })
                            }
                            toggleInstalled={(value) =>
                                setValue(`devices.${index}.installed`, value)
                            }
                        />
                    ),
                    sxCell: { minWidth: '60px', width: '60px' },
                },
            ],
            key: TABLE_SECTIONS.ACTIONS,
        },
    ];

    return (
        <>
            <Table
                columnGroups={columnGroups}
                minHeight={150}
                rows={fields}
                getRowStyle={(index) =>
                    devicesValues?.[index]?.installed
                        ? {
                              backgroundColor: (theme) =>
                                  theme.palette.mode === 'dark'
                                      ? 'rgba(255, 255, 255, 0.2)'
                                      : 'rgba(0, 0, 0, 0.2)',
                          }
                        : {}
                }
            />

            <Button
                endIcon={<AddIcon />}
                onClick={() => append(DEVICE_DEFAULT_VALUES[DEVICES.LIGHT])}
                sx={{ mt: 1, textTransform: 'none', width: '100%' }}
            >
                {t('Add device')}
            </Button>
        </>
    );
};

DevicesTable.propTypes = {
    control: PropTypes.object,
    devicesValues: PropTypes.array,
    disabled: PropTypes.bool,
    errors: PropTypes.array,
    getValues: PropTypes.func,
    setValue: PropTypes.func,
};

export default DevicesTable;
