import React from 'react';

import { useTranslation } from 'react-i18next';
import {
    Box,
    Divider,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from 'sunwise-ui';

import { IntlNumberInputField } from 'common/components';

const InfoList = () => {
    const { t } = useTranslation();
    return (
        <Box display="flex" flexDirection="column" sx={{ width: '100%', p: 2 }}>
            <Grid container>
                <Grid size={18}>
                    <Typography variant="body2" fontWeight="bold">
                        {t('Example', { count: 2 })}
                    </Typography>
                </Grid>
            </Grid>
            <Divider sx={{ mt: 2 }} />
            <Grid container mt={2}>
                <Grid size={{ md: 9, xs: 18 }}>
                    <TextField
                        disabled
                        fullWidth
                        select
                        label={t('Select type of document')}
                        value={1}
                    >
                        <MenuItem value={1}>{t('ID')}</MenuItem>
                    </TextField>
                </Grid>
                <Grid size={{ md: 9, xs: 18 }}>
                    <IntlNumberInputField
                        disabled
                        fullWidth
                        label={t('Enter value')}
                        value="184627993"
                    />
                </Grid>
            </Grid>
            <Divider sx={{ mt: 2 }} />
            <Grid container mt={2}>
                <Grid size={{ md: 9, xs: 18 }}>
                    <TextField
                        disabled
                        fullWidth
                        label={t('Select type of document')}
                        select
                        value={1}
                    >
                        <MenuItem value={1}>{t('Address')}</MenuItem>
                    </TextField>
                </Grid>
                <Grid size={{ md: 9, xs: 18 }}>
                    <TextField
                        disabled
                        fullWidth
                        label={t('Enter value')}
                        value="Calle Almadia"
                    />
                </Grid>
            </Grid>
            <Divider sx={{ mt: 2 }} />
            <Grid container mt={2}>
                <Grid size={{ md: 9, xs: 18 }}>
                    <TextField
                        disabled
                        fullWidth
                        label={t('Select type of document')}
                        select
                        value={1}
                    >
                        <MenuItem value={1}>{t('Passport')}</MenuItem>
                    </TextField>
                </Grid>
                <Grid size={{ md: 9, xs: 18 }}>
                    <TextField
                        disabled
                        fullWidth
                        label={t('Enter value')}
                        value="ABCD1234EF56"
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default InfoList;
