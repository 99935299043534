import fetchContacts from './fetchContacts';
import fetchProjects from './fetchProjects';
import fetchProposals from './fetchProposals';
import fetchReports from './fetchReports';
import fetchSummaries from './fetchSummaries';

export default (values) => (dispatch) => {
    const { context, page, q } = values;
    if (!q) return;

    switch (context) {
        case 'contacts':
            return dispatch(fetchContacts(q, page));
        case 'projects':
            return dispatch(fetchProjects(q, page));
        case 'proposals':
            return dispatch(fetchProposals(q, page));
        case 'reports':
            return dispatch(fetchReports(q, page));
        case 'summaries':
            return dispatch(fetchSummaries(q, page));
        default:
            return;
    }
};
