export default async function getDataLayerUrls({
    apiKey,
    location,
    pixelSizeMeters = 0.1,
    radiusMeters = 100,
    requiredQuality = 'HIGH',
    segmentId,
} = {}) {
    const args = {
        'location.latitude': location.lat.toFixed(5),
        'location.longitude': location.lng.toFixed(5),
        pixelSizeMeters,
        radiusMeters,
        requiredQuality,
    };
    const params = new URLSearchParams({ ...args, key: apiKey });
    // https://developers.google.com/maps/documentation/solar/reference/rest/v1/dataLayers/get
    return fetch(
        `https://solar.googleapis.com/v1/dataLayers:get?${params}`,
    ).then(async (response) => {
        const content = await response.json();
        if (response.status != 200) {
            console.error('getDataLayerUrls\n', content);
            throw content;
        }
        return { ...content, segmentId };
    });
}
