import i18next from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';

import {
    DAYS_IN_PERIOD,
    DEFAULT_ORIGIN,
    HOURS_IN_DAY,
    HOURS_IN_YEAR,
    MS_IN_HOUR,
    URL_TYPES,
    USER_ORIGIN,
} from 'common/constants';
import {
    DEFAULT_SCHEDULE_RATE_CONFIGURATION,
    DOMESTIC_RATES,
    HIGH_TENSION_RATES,
    RATES_WITH_DIVISION,
    RATES_WITH_POWER_FACTOR,
    SPAIN_RATES,
} from 'common/constants/rates';
import {
    formatDate,
    getDateStringAsUTC,
    getUTCDateAsString,
    isInvalidLeapYearDay,
    isLeapYear,
    parseDate,
    subDate,
} from 'common/utils/dates';
import { numberFormat } from 'common/utils/helpers';
import { getHasErrorsByFields } from 'common/utils/helpers/form';
import { getLocaleEquivalenceForDateFns } from 'common/utils/helpers/multiregion';
import {
    getConsumptionValue,
    getNormalizedSummary,
    calcEnergyDistributionPercentages,
    getDailyAvgFields,
    getDaysInPeriod,
    getDistributionRatioByTier,
    getFieldsInPeriod,
    getIsHourlyRate,
} from 'common/utils/helpers/rates';
import {
    getCountryCurrencyDecimalSeparator,
    getCountryCurrencyLocale,
    getCountryCurrencyThousandSeparator,
} from 'common/utils/helpers/session';
import {
    COLOR_DELTA,
    createFilledArray,
    generateColorTone,
    getChartSeriesKwData,
} from 'common/utils/helpersChart';

import { updatePlaceholder } from './actions/updatePlaceholders';
import {
    AUTO_PDF_RECEIPT_ORIGIN,
    BASE_COLORS,
    CONSUMPTIONS_CAPTURE_MODE,
    DATE_FORMATS,
    DEFAULT_RECEIPT_ORIGIN,
    LINE_KW,
    LINE_KWH,
    MANUAL_RECEIPT_ORIGIN,
    MULTIPLIER_BY_UNIT_PREFIX,
    PREDICTED_COLOR_SERIE_1,
    PREDICTED_COLOR_SERIE_2,
    SEMIAUTO_PDF_RECEIPT_ORIGIN,
    SEMIAUTO_RECEIPT_ORIGIN,
} from './constants';
import { getConsumptionProfileDistributed } from './helpersConsumptionProfile';
import * as selectors from './selectors';

const monthsInYear = 12;

export const formatDateDefault = (date) =>
    formatDate(date, DATE_FORMATS.DEFAULT);
export const parseDateDefault = (date) => parseDate(date, DATE_FORMATS.DEFAULT);
export const formatDateLabel = (date) =>
    formatDate(date, DATE_FORMATS.LABEL)?.toUpperCase();

export const calcNewAvg = (oldAvg, newValue, size) =>
    oldAvg + (newValue - oldAvg) / size;

export const getCellInputStyle = (bgColor, isClickable) => ({
    border: `1px solid ${bgColor}`,
    cursor: isClickable ? 'pointer' : 'default',
    minWidth: '150px',
    p: 1,
    textAlign: 'center',
    '& input': { textAlign: 'center' },
});

const getStackSeries = ({
    color = BASE_COLORS.ENERGY,
    colorDelta = COLOR_DELTA,
    key = '',
    stackName = undefined,
    stacked = false,
    summary = {},
    type = 'bar',
    yAxisID = '',
}) => {
    const series = {};
    const fallback = [];
    let result = [];

    for (let i = 0, len = summary.length; i < len; i++) {
        const entries = Object.entries(summary[i][key] || {});
        for (const [_key, value] of entries) {
            if (!series[_key]) {
                const tier = value?.tier || 0;

                series[_key] = {
                    color: generateColorTone(color, tier * colorDelta),
                    data: new Array(summary.length).fill(0),
                    name: value.label,
                    order: tier,
                    stack: stacked && stackName ? stackName : undefined,
                    type,
                    yAxisID,
                };
            }

            series[_key].data[i] = value.value || parseFloat(value.placeholder);
        }

        fallback.push(
            summary[i].total.value || parseFloat(summary[i].total.placeholder),
        );
    }

    Object.keys(series).forEach((key) => result.push(series[key]));

    if (result.length === 0)
        result = [{ color, data: fallback, name: 'kWh', stack: 'kWh_total' }];

    return result;
};

const getChartSeries = (selectedRate, isFromScrapper, summary) => {
    if (!Array.isArray(summary) || summary.length === 0 || !selectedRate)
        return { colors: [], series: [], yaxis: {} };

    const reverseSummary = [...summary].reverse();
    const colors = [
        ({ dataPointIndex }) =>
            isFromScrapper
                ? handleColorSerie(
                      PREDICTED_COLOR_SERIE_1,
                      dataPointIndex,
                      reverseSummary,
                  )
                : PREDICTED_COLOR_SERIE_1[0],
    ];

    const kWhSeriesStack = getStackSeries({
        color: BASE_COLORS.ENERGY,
        key: 'kWh',
        stacked: true,
        stackName: 'kWh',
        summary: reverseSummary,
        yAxisID: 'y0',
    });

    if (
        selectedRate.isCertified &&
        HIGH_TENSION_RATES.includes(selectedRate.name)
    ) {
        const locale = getCountryCurrencyLocale();

        /*
        const kWSeriesStack = getStackSeries({
            color: 'basekwColor,
            colorDelta: 70,
            key: 'kW',
            summary: reverseSummary,
            type: 'line',
            yAxisID: 'y1',
        });
        */

        return {
            animations: { enabled: false },
            series: [
                /*
                {
                    color: basekwhColor,
                    name: 'kWh',
                    data: chartDataKwh,
                    stack: 'kWh_total',
                },
                */

                ...kWhSeriesStack,

                {
                    color: BASE_COLORS.DEMAND,
                    name: 'kW',
                    data: getChartSeriesKwData(reverseSummary, selectedRate),
                    order: 4,
                    stack: 'kW',
                    yAxisID: 'y1',
                },

                //...kWSeriesStack,
            ],
            yaxis: [
                {
                    axisBorder: { show: true, color: BASE_COLORS.ENERGY },
                    axisTicks: { show: true },
                    labels: {
                        formatter: (val = 0) =>
                            numberFormat(val, {
                                decimals: 0,
                                locale,
                                style: 'decimal',
                                unit: 'kWh',
                            }),
                    },
                    title: { text: 'kWh' },
                    tooltip: { enabled: false },
                },
                {
                    axisBorder: { show: true, color: BASE_COLORS.DEMAND },
                    axisTicks: { show: true },
                    opposite: true,
                    seriesName: 'kW',
                    labels: {
                        formatter: (val = 0) =>
                            numberFormat(val, {
                                decimals: 0,
                                locale,
                                style: 'decimal',
                                unit: 'kW',
                            }),
                    },
                    title: { text: 'kW' },
                },
            ],
            colors: [
                ...colors,
                ({ dataPointIndex }) =>
                    isFromScrapper
                        ? handleColorSerie(
                              PREDICTED_COLOR_SERIE_2,
                              dataPointIndex,
                              reverseSummary,
                          )
                        : PREDICTED_COLOR_SERIE_2[0],
            ],
        };
    }

    return {
        colors,
        series: kWhSeriesStack,
        yaxis: {},
    };
};

const getConsumptionTotal = ({ summary, index }) => {
    if (isEmpty(summary?.[index]?.kWh)) return 0;
    let total = 0;

    const energyKeys = Object.keys(summary[index].kWh);

    for (const key of energyKeys) {
        total += Number.parseFloat(summary[index].kWh[key].value) || 0;
    }
    return total.toFixed(2);
};

const getPeriodsBeforeDateChanged = (summary, index, value) => {
    const newSummary = cloneDeep(summary);
    if (index === newSummary.length - 1) return newSummary;
    for (let i = index + 1; i < newSummary.length; i++) {
        const periodDays = getDaysInPeriod(newSummary[i]);
        if (i === index + 1) newSummary[i].final_date = value;
        else newSummary[i].final_date = newSummary[i - 1].initial_date;
        if (!newSummary[i].final_date) continue;

        const parsedFinalDate = parseDateDefault(newSummary[i].final_date);

        newSummary[i].initial_date = formatDateDefault(
            subDate(parsedFinalDate, { days: periodDays }),
        );
        newSummary[i].label = formatDateLabel(
            subDate(parsedFinalDate, { days: Math.floor(periodDays / 2) }),
        );
    }
    return newSummary;
};

const handleColorSerie = (serie, index, monthsKwh) =>
    serie[get(monthsKwh, `[${index}].predicted`, 0)];

const checkApplyValue = ({ avgs, index, key, summary } = {}) => {
    const value = get(summary[index], key);
    return value === 0 ? 0 : value !== null ? value : avgs[key].avg;
};

export const avgRates = (summary) => {
    const total = summary?.reduce((total, item) => {
        const value = item?.total?.value
            ? Number.parseInt(item.total.value)
            : 0;
        return total + value;
    }, 0);
    return total / monthsInYear;
};

const calcAverages = (summary = []) => {
    const avgs = summary.reduce((acc, current) => {
        if (isEmpty(current?.kWh)) {
            const currenValue = current?.total?.value;
            if (!acc['total.value']) acc['total.value'] = { avg: 0, size: 0 };
            if (currenValue && currenValue > 0) {
                const size = acc['total.value'].size + 1;
                acc['total.value'] = {
                    avg: calcNewAvg(acc['total.value'].avg, currenValue, size),
                    size,
                };
            }
            return acc;
        }

        const energyKeys = Object.keys(current.kWh);

        for (const key of energyKeys) {
            const currenValue = current.kWh[key]?.value;
            if (!acc[key]) acc[key] = { avg: 0, size: 0 };
            if (currenValue && currenValue > 0) {
                const size = acc[key].size + 1;
                acc[key] = {
                    avg: calcNewAvg(acc[key].avg, currenValue, size),
                    size,
                };
            }
        }
        return acc;
    }, {});
    return avgs;
};

export const calcTotalAverage = (summary = []) => {
    let acc = { avg: 0, size: 0 };

    for (const period of summary) {
        const currenValue = period?.total?.value;
        if (currenValue && currenValue > 0) {
            const size = acc.size + 1;
            acc = { avg: calcNewAvg(acc.avg, currenValue, size), size };
        }
    }
    return acc;
};

const cleanDecimalSeparator = ({
    decimalSeparator,
    thousandSeparator,
    value,
}) =>
    value
        ?.replaceAll(thousandSeparator, '')
        ?.replaceAll(decimalSeparator, '.') ?? '';

export const getBorderColor = (predicted) => {
    if (predicted === 1 || predicted === 3) return '#ffc107';
    if (predicted === 2) return '#28a745';
    return '';
};

const getDaysInPeriodLimit = (selectedRate, isBimonthly) => {
    if (selectedRate && !selectedRate.isCertified)
        return (
            (Number.parseInt(selectedRate.periodicityType) + 1) * DAYS_IN_PERIOD
        );
    if (isBimonthly === '1') return DAYS_IN_PERIOD * 2;
    return DAYS_IN_PERIOD;
};

export const getDefaultSummerMonth = ({
    formValues,
    selectedRate,
    summerMonths,
}) => {
    if (formValues.rate_division_summer) return formValues.rate_division_summer;
    if (
        selectedRate?.isCertified &&
        DOMESTIC_RATES.includes(selectedRate.name)
    ) {
        const defaultSummerMonth = summerMonths.find(
            (item) => item.label === i18next.t('April').toUpperCase(),
        );
        if (defaultSummerMonth) return defaultSummerMonth.value;
    }
};

export const getChartConfig = ({
    isFromScrapper,
    selectedRate,
    summary,
} = {}) => {
    const { colors, yaxis, series } = getChartSeries(
        selectedRate,
        isFromScrapper,
        summary,
    );
    return {
        options: {
            chart: { background: '#ffffff00', toolbar: { show: false } },
            colors,
            dataLabels: { enabled: false },
            legend: { show: false },
            tooltip: {
                y: {
                    formatter: (val = 0) =>
                        numberFormat(val, {
                            locale: getCountryCurrencyLocale(),
                            style: 'decimal',
                        }),
                },
            },
            xaxis: {
                categories: !isEmpty(summary)
                    ? summary.map((period) => period.label).reverse()
                    : [],
            },
            yaxis,
        },
        series,
    };
};

export const getDACAlerts = ({
    ratesNameDictionary,
    selectedRate,
    subsidyRate,
    summary,
}) => {
    if (!selectedRate || !selectedRate.isCertified) return {};

    const annualAvgConsumption = avgRates(summary);
    const limitRate = getLimitRate(subsidyRate, ratesNameDictionary);
    const { name, limitDac } = selectedRate;

    const isDAC = name === 'DAC';
    const isDomestic = DOMESTIC_RATES.includes(name);

    const hasDACRateAlert = isDAC && limitRate > annualAvgConsumption;

    const hasDomesticRateAlert =
        !isDAC && isDomestic && limitDac < annualAvgConsumption;

    return { hasDACRateAlert, hasDomesticRateAlert };
};

export const getHasCalculatedValues = (summary) => {
    if (!summary?.length) return false;

    return summary.some(
        ({ kW, total } = {}) =>
            total?.value === null ||
            Object.values(kW || {}).some((field) => field?.value === null),
    );
};

const isValueOutsideTolerance = (tolerance, limitValue, actualValue) =>
    actualValue < limitValue * (1 - tolerance) ||
    actualValue > limitValue * (1 + tolerance);

export const getHasDaysInPeriodWarning = (daysInPeriod, daysInPeriodLimit) => {
    const tolerance = 0.1;

    return isValueOutsideTolerance(tolerance, daysInPeriodLimit, daysInPeriod);
};

const getHasAvgConsumptionWarning = (avg, total) => {
    if (!total && total === null) return false;

    const tolerance = 0.75;
    const _total = Number(total);

    return isValueOutsideTolerance(tolerance, avg, _total);
};

export const getConsumptionHistoryWarnings = ({
    formValues,
    ratesNameDictionary,
    selectedRate,
    subsidyRate,
}) => {
    const { summary, is_bimonthly } = formValues;

    const { avg } = calcTotalAverage(summary);
    const daysInPeriodLimit = getDaysInPeriodLimit(selectedRate, is_bimonthly);

    const { hasDACRateAlert, hasDomesticRateAlert } = getDACAlerts({
        ratesNameDictionary,
        selectedRate,
        subsidyRate,
        summary,
    });

    const warnings = {
        hasAvgConsumptionWarning: false,
        hasDACRateAlert,
        hasDaysInPeriodWarning: false,
        hasDomesticRateAlert,
        hasInconsistentDemands: false,
        hasMissingFields: false,
        hasPredictedValues: getHasPredictedValues(summary),
        summaryWarnings: [],
    };

    for (const period of summary) {
        const daysInPeriod = getDaysInPeriod(period);
        const hasDaysInPeriodWarning = getHasDaysInPeriodWarning(
            daysInPeriod,
            daysInPeriodLimit,
        );
        const inconsistentDemands = getInconsistentDemands(period);
        const totalConsumption = get(period, 'total.value', null);
        const hasAvgConsumptionWarning = getHasAvgConsumptionWarning(
            avg,
            totalConsumption,
        );
        warnings.summaryWarnings.push({
            hasAvgConsumptionWarning,
            hasDaysInPeriodWarning,
            hasInconsistentDemands: inconsistentDemands?.length > 0,
            hasMissingFields: period.hasMissingFields,
            inconsistentDemands,
        });

        if (!warnings.hasAvgConsumptionWarning && hasAvgConsumptionWarning)
            warnings.hasAvgConsumptionWarning = true;

        if (!warnings.hasDaysInPeriodWarning && hasDaysInPeriodWarning)
            warnings.hasDaysInPeriodWarning = true;

        if (!warnings.hasInconsistentDemands && inconsistentDemands?.length)
            warnings.hasInconsistentDemands = true;

        if (!warnings.hasMissingFields && period.hasMissingFields)
            warnings.hasMissingFields = true;
    }

    return warnings;
};

export const getTotalWarningIndicator = (warnings) => {
    if (!warnings) return { total: 0 };

    const {
        hasAvgConsumptionWarning,
        hasDaysInPeriodWarning,
        hasInconsistentDemands,
        hasMissingFields,
    } = warnings ?? {};
    let alerts = 0;

    if (hasAvgConsumptionWarning) alerts++;
    if (hasDaysInPeriodWarning) alerts++;
    if (hasInconsistentDemands) alerts++;
    if (hasMissingFields) alerts++;

    return { errorSeverity: hasInconsistentDemands, total: alerts };
};

export const getSectionsWithErrors = (sections, errors) => {
    if (!sections) return {};

    const sectionsKeys = Object.keys(sections);

    const sectionsWithErrors = {};

    for (const section of sectionsKeys) {
        const fields = sections[section];

        if (!fields?.length) continue;

        sectionsWithErrors[section] = getHasErrorsByFields({ errors, fields });
    }

    return sectionsWithErrors;
};

const getInconsistentDemands = (period) => {
    const { hoursDistribution, kWh, kW } = period;

    if (isEmpty(kW) || isEmpty(kWh)) return [];

    const countryCurrencyLocale = getCountryCurrencyLocale();
    const inconsistentDemands = [];
    const tolerance = 1.005;

    for (const key of Object.keys(kWh)) {
        const consumption = kWh[key]?.value;
        const demand = kW[key]?.value;
        const tier = kWh[key]?.tier;
        const hours = hoursDistribution[tier]?.total;

        if (consumption === null || demand === null) continue;

        const minDemand = Math.ceil(consumption / hours / tolerance);

        if (demand >= minDemand) continue;

        const formattedDemand = numberFormat(minDemand, {
            decimals: 0,
            locale: countryCurrencyLocale,
            style: 'decimal',
        });
        const helperText = `${kWh[key]?.label} (${i18next.t(
            'Minimum',
        )} ${formattedDemand} kW)`;
        inconsistentDemands.push(helperText);
    }

    return inconsistentDemands;
};

export const getHasPredictedValues = (summary) => {
    if (!isEmpty(summary))
        for (const period of summary) {
            if (period.predicted) return true;
        }
    return false;
};

export const getMinInitialDate = () => parseDateDefault('01/01/2018');

export const getMaxInitialDate = (period) =>
    !period || !period.final_date
        ? new Date()
        : subDate(parseDateDefault(period.final_date), { days: 1 });

export const getLimitRate = (subsidyRate, ratesNameDictionary) =>
    !subsidyRate || !ratesNameDictionary[subsidyRate]
        ? null
        : ratesNameDictionary[subsidyRate].limitDac;

export const getPeriodicityType = (period) => {
    switch (period) {
        case 'Bimonthly':
            return '1';
        case 'Quarter':
            return '2';
        default:
            return '0';
    }
};

export const getPeriodicityTypeText = (periodCode, t) => {
    switch (periodCode) {
        case '1':
            return t('Bimonthly');
        case '2':
            return t('Quarterly');
        default:
            return t('Monthly');
    }
};

export const getReceiptOrigin = (
    receiptOrigin,
    isManually,
    automaticHasChanges,
) => {
    if (isManually || receiptOrigin === MANUAL_RECEIPT_ORIGIN)
        return MANUAL_RECEIPT_ORIGIN;
    if (
        automaticHasChanges &&
        ![SEMIAUTO_PDF_RECEIPT_ORIGIN, SEMIAUTO_RECEIPT_ORIGIN].includes(
            receiptOrigin,
        )
    ) {
        if (receiptOrigin === AUTO_PDF_RECEIPT_ORIGIN)
            return SEMIAUTO_PDF_RECEIPT_ORIGIN;
        return SEMIAUTO_RECEIPT_ORIGIN;
    }
    if (
        [
            AUTO_PDF_RECEIPT_ORIGIN,
            SEMIAUTO_PDF_RECEIPT_ORIGIN,
            SEMIAUTO_RECEIPT_ORIGIN,
        ].includes(receiptOrigin)
    )
        return receiptOrigin;
    return DEFAULT_RECEIPT_ORIGIN;
};

export const handleChangeLastConsumption = ({
    getValues,
    handleNormalizeDates,
    profilesConsumptionData,
    rateConfiguration,
    ratesDictionary,
    setValue,
    value,
}) => {
    setValue('summary.0.final_date', value);
    handleNormalizeFinalDateBuild({
        getValues,
        handleNormalizeDates,
        index: 0,
        profilesConsumptionData,
        rateConfiguration,
        ratesDictionary,
        setValue,
        value,
    });
};

export const handleApplyAvgToRowBuild = ({ getValues, setValue }) => {
    const summary = cloneDeep(getValues('summary'));
    const avgs = calcAverages(summary);
    const newSummary = [];
    for (let index = 0; index < summary.length; index++) {
        const period = summary[index];

        const keys = Object.keys(avgs);

        for (const key of keys) {
            const _avg = Number.parseInt(
                checkApplyValue({ avgs, index, key, summary }),
            );
            set(period, key, _avg);
        }
        const totalAvg = get(period, 'total.value', 0);

        set(
            period,
            'dailyAvg',
            getDailyAvgFields(totalAvg, period.final_date, period.initial_date),
        );
        newSummary[index] = period;
    }
    setValue('summary', newSummary);
};

export const handleNormalizeInitialDateBuild = ({
    getValues,
    handleNormalizeDates,
    index,
    profilesConsumptionData,
    rateConfiguration,
    ratesDictionary,
    setValue,
    value,
}) => {
    const formValues = getValues();
    const summary = getPeriodsBeforeDateChanged(
        formValues.summary,
        index,
        value,
    );

    const summaryNormalizeDates = handleNormalizeDates({
        summary,
        getValues,
    });

    const newSummary = handleNormalizeFields({
        discardValues: true,
        distribution: getValues('distribution'),
        rate: ratesDictionary[getValues('rate')],
        rateConfiguration,
        summary: summaryNormalizeDates,
    });

    setValue('summary', newSummary);

    updatePlaceholder({
        formValues: {
            ...formValues,
            last_consumption: newSummary?.[0]?.final_date,
            summary: newSummary,
        },
        profilesConsumptionData,
        ratesDictionary,
        setValue,
    });
};

export const handleNormalizeFields = ({
    discardValues,
    distribution,
    rate,
    rateConfiguration,
    summary,
}) => {
    return summary.map((period) => ({
        ...period,
        ...getFieldsInPeriod({
            discardValues,
            distribution,
            finalDate: period.final_date,
            initialDate: period.initial_date,
            period,
            rate,
            ...(rateConfiguration || {}),
        }),
    }));
};

export const handleNormalizeFinalDateBuild = ({
    getValues,
    handleNormalizeDates,
    index,
    profilesConsumptionData,
    rateConfiguration,
    ratesDictionary,
    setValue,
    value,
}) => {
    const formValues = getValues();
    const rate = ratesDictionary[formValues.rate];
    const isBimonthly = formValues.is_bimonthly === '1';

    const parsedFinalDate = parseDateDefault(value);
    const newPeriodInitialDate = formatDateDefault(
        subDate(parsedFinalDate, { days: isBimonthly ? 60 : 30 }),
    );

    setValue(`summary.${index}.initial_date`, newPeriodInitialDate);

    const summary = getPeriodsBeforeDateChanged(
        formValues.summary,
        index,
        newPeriodInitialDate,
    );

    const summaryNormalizeDates = handleNormalizeDates({ getValues, summary });

    const newSummary = handleNormalizeFields({
        discardValues: true,
        distribution: getValues('distribution'),
        rate,
        rateConfiguration,
        summary: summaryNormalizeDates,
    });

    if (newSummary?.[0]?.final_date)
        newSummary[0].label = formatDateLabel(
            subDate(parseDateDefault(newSummary[0].final_date), {
                days: isBimonthly ? 30 : 15,
            }),
        );

    setValue('summary', newSummary);

    updatePlaceholder({
        formValues: {
            ...formValues,
            last_consumption: newSummary?.[0]?.final_date,
            summary: newSummary,
        },
        profilesConsumptionData,
        ratesDictionary,
        setValue,
    });
};

export const handleNormalizeRate = ({
    calculateConsumptionWithCsvData,
    fetchScheduleRateConfiguration,
    getValues,
    ratesDictionary,
    resetPDFfile,
    setValue,
}) => {
    const formValues = getValues();
    const rate = formValues.rate;

    if (!rate) {
        setValue('file', '');
        resetPDFfile();
        return;
    }

    const selectedRate = ratesDictionary[rate];
    fetchScheduleRateConfiguration({
        calculateConsumptionWithCsvData,
        getValues,
        rate: selectedRate,
        rateDivision: formValues?.rate_division,
        setValue,
    });
};

export const handleNormalizeConsumption = ({
    field,
    getValues,
    index,
    profilesConsumptionData,
    ratesDictionary,
    setValue,
    updatePlaceholders = true,
}) => {
    const formValues = cloneDeep(getValues());
    const total = Number.parseFloat(
        getConsumptionTotal({ index, summary: formValues.summary }),
    );

    const totalValue = formValues?.summary?.[index]?.total?.value;
    const value = get(formValues, `${field}.value`, null);
    if ((total === totalValue || (!total && !totalValue)) && value !== 0)
        return;
    set(formValues, `summary[${index}].total.value`, total);

    if (!updatePlaceholders)
        setValue(`summary.${index}.total`, {
            ...formValues.summary[index].total,
            value: total,
        });

    const distribution = calcEnergyDistributionPercentages(formValues.summary);
    if (!isEmpty(distribution)) {
        setValue('distribution', distribution);
        set(formValues, 'distribution', distribution);
    }

    if (updatePlaceholders)
        updatePlaceholder({
            formValues,
            profilesConsumptionData,
            ratesDictionary,
            setValue,
        });
};

export const handleNormalizeTotalKwh = ({
    getValues,
    index,
    profilesConsumptionData,
    ratesDictionary,
    setValue,
    updatePlaceholders = true,
}) => {
    const formValues = cloneDeep(getValues());
    const energyFields = formValues.summary[index].kWh;

    const total = Number.parseFloat(
        getConsumptionTotal({ index, summary: formValues.summary }),
    );
    const totalValue = formValues?.summary?.[index]?.total?.value;
    if ((total === totalValue || (!total && !totalValue)) && totalValue !== 0)
        return;

    if (!isEmpty(energyFields)) {
        let _total = 0;
        const _value =
            Number.parseFloat(formValues.summary[index]?.total?.value) || 0;
        const energyKeys = Object.keys(energyFields);

        for (const element of energyKeys)
            _total += Number.parseFloat(energyFields[element].value) || 0;

        if (_total === _value && _value !== 0) return;

        const distribution = getDistributionRatioByTier({
            distribution: formValues.distribution,
            hoursDistribution: formValues.summary[index].hoursDistribution,
        });

        const indexLastTier = energyKeys.length - 1;
        const lastTier = energyKeys[indexLastTier];
        let sum = 0;

        for (let tier = 0; tier < indexLastTier; tier++) {
            const key = energyKeys[tier];
            const newValue =
                Number.parseInt(
                    distribution[energyFields[key].tier] * _value,
                ) || 0;

            sum += newValue;

            set(formValues, `summary.${index}.kWh.${key}.value`, newValue);

            if (!updatePlaceholders)
                setValue(`summary.${index}.kWh.${key}`, {
                    ...energyFields[key],
                    value: newValue,
                });
        }
        set(formValues, `summary.${index}.kWh.${lastTier}.value`, _value - sum);
        if (!updatePlaceholders)
            setValue(`summary.${index}.kWh.${lastTier}`, {
                ...energyFields[lastTier],
                value: _value - sum,
            });
    }

    if (updatePlaceholders)
        updatePlaceholder({
            formValues,
            profilesConsumptionData,
            ratesDictionary,
            setValue,
        });
};

export const round = (value) =>
    typeof value !== 'undefined' && value
        ? Number.parseFloat(value).toFixed(0)
        : 0;

export const handleFormatDates = ({ isBimonthly = false, summary = [] }) =>
    summary.reduce(
        (acc, curr) => {
            const [, month, year] = (acc.date || curr.final_date).split('/');

            const _finalDate = new Date(year, month, 0);
            const final_date = formatDateDefault(_finalDate);

            const initialMonth = month - (isBimonthly ? 2 : 1);
            const _initialDate = new Date(year, initialMonth, 0);
            const initial_date = formatDateDefault(_initialDate);

            const label = formatDateLabel(
                subDate(_finalDate, { days: isBimonthly ? 30 : 15 }),
            );

            acc.date = initial_date;
            acc.data.push({ ...curr, final_date, initial_date, label });

            return acc;
        },
        { data: [], date: null },
    ).data;

export const handleOnPasteConsumptionHistory = ({
    columnGroups,
    columnOrigin,
    data,
    getValues,
    rowOrigin,
    setValue,
}) => {
    if (
        !columnGroups?.length ||
        !columnOrigin ||
        !getValues ||
        (!rowOrigin && rowOrigin !== 0) ||
        !setValue ||
        isEmpty(data)
    )
        return;

    const flatColumns = columnGroups.flatMap((group) => group.columns);
    const validColumns = flatColumns.filter((column) => column.key);
    const columnOriginIndex = validColumns.findIndex(
        (column) => column.key === columnOrigin,
    );

    if (columnOriginIndex === -1) return;

    const summary = getValues('summary');
    if (!summary?.length) return;

    const thousandSeparator = getCountryCurrencyThousandSeparator();
    const decimalSeparator =
        getCountryCurrencyDecimalSeparator(thousandSeparator);
    const summaryLength = summary.length;
    const validColumnsLength = validColumns.length;
    const columnOriginConfig = validColumns[columnOriginIndex];

    for (let i = 0; i < data.length; i++) {
        const currentRow = rowOrigin + i;

        if (currentRow >= summaryLength) return;

        const row = data[i];

        for (let j = 0; j < row.length; j++) {
            const currentColumnIndex = columnOriginIndex + j;

            if (currentColumnIndex >= validColumnsLength) break;

            const columnKey = validColumns[currentColumnIndex]?.key;
            if (!columnKey) continue;

            const fieldPath = `${currentRow}.${columnKey}`;
            if (!has(summary, fieldPath)) continue;

            const cleanedValue = cleanDecimalSeparator({
                decimalSeparator,
                thousandSeparator,
                value: row[j],
            });

            const isObjectValue = validColumns[currentColumnIndex].objectValue;

            const newValue = isObjectValue
                ? {
                      ...get(summary, fieldPath, null),
                      value: Number.parseInt(cleanedValue) || 0,
                  }
                : Number.parseInt(cleanedValue) || 0;

            setValue(`summary.${fieldPath}`, newValue);
        }

        if (columnOriginConfig?.onRowPaste)
            columnOriginConfig.onRowPaste({
                index: currentRow,
                updatePlaceholders: false,
            });
    }
};

export const subsidyRateValuesForSelect = () => [
    { label: i18next.t('Subsidized rate'), value: '' },
    { label: '1', value: '1' },
    { label: '1A', value: '1A' },
    { label: '1B', value: '1B' },
    { label: '1C', value: '1C' },
    { label: '1D', value: '1D' },
    { label: '1E', value: '1E' },
    { label: '1F', value: '1F' },
];

export const updateEnergyValuesByPeriod = ({ newSummary, index, setValue }) => {
    const period = newSummary[index];

    if (isEmpty(period)) return;

    const kWhEntries = Object.entries(period.kWh);
    const kWEntries = Object.entries(period.kW);

    for (const [key, value] of kWhEntries) {
        setValue(`summary.${index}.kWh.${key}`, value);
    }

    for (const [key, value] of kWEntries) {
        setValue(`summary.${index}.kW.${key}`, value);
    }

    setValue(`summary.${index}.missingFields`, period.missingFields);
    setValue(`summary.${index}.hasMissingFields`, period.hasMissingFields);
    setValue(`summary.${index}.total`, period.total);
    setValue(`summary.${index}.power_factor`, period.power_factor);
    setValue(`summary.${index}.file`, period.file);
    setValue(`summary.${index}.predicted`, period.predicted);
    setValue(`summary.${index}.season_change_pdf`, period.season_change_pdf);
    setValue(`summary.${index}.url_type`, period.url_type);

    const distribution = calcEnergyDistributionPercentages(newSummary);
    setValue('distribution', distribution);
};

/** LISA **/
export const getFirstDataFoundInLisaFiles = (documents, field) => {
    if (!documents?.length) return null;

    for (const doc of documents) {
        if (doc?.completion?.[field] || doc?.completion?.[field] === false)
            return doc?.completion?.[field];
    }
    return null;
};

const getTotalFields = (energy = {}) => {
    const value =
        Object.values(energy).reduce((acc, curr) => acc + curr.value, 0) || 0;
    return { placeholder: value.toString(), value };
};

export const getCorrectLisaDateFormat = (date = '') =>
    date.split('-').reverse().join('/');

export const getDynamicFields = (data, multiplier = 1) => {
    if (!data?.length) return {};

    return data.reduce((acc, curr) => {
        const tier = curr.tier?.toLowerCase();
        const value = multiplier * Number.parseInt(curr?.consumption) || 0;

        acc[tier] = { label: tier, placeholder: value.toString(), value };

        return acc;
    }, {});
};

export const getPeriodFromLisa = ({
    data = {},
    file,
    isBimonthly,
    rate,
    tiers_energy_distribution,
    url_type,
}) => {
    if (!data?.values?.initial_date || !data?.values?.final_date) return null;

    const initial_date = getCorrectLisaDateFormat(data.values.initial_date);
    const final_date = getCorrectLisaDateFormat(data.values.final_date);
    const final = parseDate(data.values.final_date, DATE_FORMATS.LISA);
    const representativeDate = subDate(final, { days: isBimonthly ? 30 : 15 });

    return {
        file,
        final_date,
        generation: data.values.solar_generation,
        history: data.history,
        initial_date,
        label: formatDateLabel(representativeDate),
        power_factor: data.values.fp || 90,
        representativeMonth: representativeDate.getMonth(),
        season_change_pdf: data.values.season_change,
        url_type: url_type ?? URL_TYPES.LISA,
        ...getEnergyFields({
            demand: data.values.demand,
            energy: data.values.energy,
            final_date,
            initial_date,
            rate,
            tiers_energy_distribution,
            unitPrefix: data.unit_prefix,
        }),
    };
};

export const getMultiplierByUnitPrefix = (unitPrefix) => {
    return MULTIPLIER_BY_UNIT_PREFIX[unitPrefix] || 1;
};

export const getEnergyFields = ({
    demand,
    energy,
    final_date,
    initial_date,
    rate,
    tiers_energy_distribution,
    unitPrefix,
}) => {
    const multiplier = getMultiplierByUnitPrefix(unitPrefix);
    const energyFields = getDynamicFields(energy, multiplier);
    const total = getTotalFields(energyFields);
    return getFieldsInPeriod({
        discardValues: true,
        finalDate: final_date,
        initialDate: initial_date,
        period: {
            kW: getDynamicFields(demand, multiplier),
            kWh: energyFields,
            total,
        },
        rate,
        tiers_energy_distribution,
    });
};

export const getContractedDemandFields = (data, hourlyContractedDemand) => {
    if (isEmpty(hourlyContractedDemand))
        return { contracted_demand: data?.[0]?.consumption ?? null };

    const hourly_contracted_demand = { ...hourlyContractedDemand };

    if (isEmpty(data)) return { hourly_contracted_demand };

    for (const item of data) {
        const tier = item.tier?.toLowerCase();
        if (!hourly_contracted_demand[tier]) continue;

        const value = Number.parseInt(item?.consumption) || 0;

        hourly_contracted_demand[tier] = {
            ...hourly_contracted_demand[tier],
            value,
        };
    }

    return { hourly_contracted_demand };
};

export const getSortedPeriods = (
    periods = [],
    format = DATE_FORMATS.DEFAULT,
) => {
    return [...periods].sort((a, b) => {
        if (!a?.final_date || !b?.final_date) return 0;
        return (
            parseDate(b.final_date, format) - parseDate(a.final_date, format)
        );
    });
};

const getStringWithoutSpaces = (string) => {
    if (!string) return '';
    return string.replace(/\s/g, '').toLowerCase();
};

export const getRateHasMeterType = (rate, isSpain) => {
    if (!isSpain || !rate?.name || rate?.isCertified) return false;
    const rateName = getStringWithoutSpaces(rate.name);
    return SPAIN_RATES.some((rate) =>
        rate ? rateName.includes(getStringWithoutSpaces(rate)) : false,
    );
};

/** CSV IMPORTER */
export const validateCsvUploadColumn = (value) => {
    const parsedValue = Number(value);

    if ((!parsedValue && parsedValue !== 0) || parsedValue < 0)
        return {
            success: false,
            message: i18next.t('Minimum valid value is 0'),
        };

    return { success: true };
};

export const getLastMSByStringDate = (date) => {
    if (!date) return null;
    const dateAsUTC = getDateStringAsUTC(date);
    return dateAsUTC + MS_IN_HOUR * HOURS_IN_DAY - 1;
};

export const getConsumptionProfileIndexByMS = ({
    currentMS,
    currentYear,
    finalMS,
    finalYear,
}) => {
    const hoursToFinal = Math.floor((finalMS - currentMS) / MS_IN_HOUR);
    const index = HOURS_IN_YEAR - hoursToFinal - 1;

    const _currentYear = currentYear || new Date(currentMS).getUTCFullYear();
    const _finalYear = finalYear || new Date(finalMS).getUTCFullYear();

    // Fix the offset if december 31st of a leap year was removed
    if (_currentYear + 1 === _finalYear && isLeapYear(_currentYear))
        return index + HOURS_IN_DAY;

    return index;
};

export const getNormalizedCsvData = (result, columnsFormat, columnsMatch) => {
    if (!result?.length) return [];

    const dateFormat = columnsFormat?.date || DATE_FORMATS.DEFAULT;
    const dateHasTime = dateFormat.includes('HH');
    const localeDateFns = getLocaleEquivalenceForDateFns();
    const timeFormat = columnsFormat?.time || '';
    const uniqueYears = new Set();

    const formattedData = [];
    let totalConsumption = 0;
    let tempLastDate = null;

    const shouldUseTimeFormat = !dateHasTime && timeFormat;

    for (const row of result) {
        const { date, time, consumption = 0, demand = null } = row || {};
        if (!date) continue;

        const dateString = shouldUseTimeFormat ? `${date} ${time || ''}` : date;
        const format = shouldUseTimeFormat
            ? `${dateFormat} ${timeFormat}`
            : dateFormat;

        const dateAsUTC = getDateStringAsUTC(dateString, format, localeDateFns);

        const parsedDate = new Date(dateAsUTC);
        const day = parsedDate.getUTCDate();
        const month = parsedDate.getUTCMonth();
        const year = parsedDate.getUTCFullYear();

        // Remove data from December 31st of leap years
        if (isInvalidLeapYearDay({ day, month, year })) continue;

        const newRow = {
            consumption,
            date: dateAsUTC,
            demand,
            year,
        };

        if (!tempLastDate || newRow.date > tempLastDate) {
            tempLastDate = newRow.date;
        }

        uniqueYears.add(year);
        formattedData.push(newRow);
    }

    if (!tempLastDate) return { normalizedData: [], totalConsumption };

    const lastDate = getUTCDateAsString(
        tempLastDate,
        DATE_FORMATS.DEFAULT,
        localeDateFns,
    );

    const dateLastIndex = getLastMSByStringDate(
        lastDate,
        DATE_FORMATS.DEFAULT,
        localeDateFns,
    );

    const lastYear = Math.max(...uniqueYears);

    const hourlyData = createFilledArray(HOURS_IN_YEAR);
    const demandData = createFilledArray(HOURS_IN_YEAR);

    for (const row of formattedData) {
        const index = getConsumptionProfileIndexByMS({
            currentMS: row.date,
            currentYear: row.year,
            finalMS: dateLastIndex,
            finalYear: lastYear,
        });

        if (index < 0 || index >= HOURS_IN_YEAR) continue;

        const value = row.consumption || 0;
        hourlyData[index] += value;
        totalConsumption += value;

        const demandValue = columnsMatch?.demand
            ? Math.max(demandData[index], row.demand || 0)
            : hourlyData[index];

        demandData[index] = Number.parseInt(demandValue) || 0;
    }

    const normalizedData = hourlyData.map(
        (value) => (100 * value) / totalConsumption,
    );

    return { demandData, lastDate, normalizedData, totalConsumption };
};

export const buildPayload = ({ selectedProject, state, values } = {}) => {
    const automaticHasChanges = selectors.getAutomaticHasChanges(state);
    const consumptionsCaptureMode = selectors.getConsumptionsCaptureMode(state);
    const consumptionProfile = selectors.getFetchConsumptionProfileData(state);
    const csvData = selectors.getConsumptionProfileCsv(state);
    const rate_configuration =
        selectors.getFetchScheduleRateConfigurationData(state);
    const ratesNameIdDictionary = selectors.getRatesNameIdDictionary(state);
    const selectedRate = values.ratesDictionary[values.rate];

    const valuesKwh = values.summary.map((month) => {
        const consumption = getConsumptionValue(month.total);

        const value = {
            consumption: consumption,
            final_date: month.final_date,
            initial_date: month.initial_date,
            predicted: month.predicted,
            total_cost: month.total_cost
                ? Number.parseInt(month.total_cost)
                : -1,
            type_line: `${LINE_KWH}`,
            url: month?.file || null,
            url_type: month?.url_type || URL_TYPES.SCRAPPER,
        };

        if (
            selectedRate?.isCertified &&
            RATES_WITH_POWER_FACTOR.includes(selectedRate.name)
        )
            value.power_factor = month.power_factor;

        return value;
    });

    let valuesKw = [];

    if (
        (selectedRate?.isCertified &&
            HIGH_TENSION_RATES.includes(selectedRate?.name)) ||
        selectedRate?.formType === '1'
    ) {
        valuesKw = values.summary.map((month) => {
            const consumption = getConsumptionValue(month.kW?.peak);

            return {
                consumption: consumption,
                final_date: month.final_date,
                initial_date: month.initial_date,
                total_cost: month.total_cost
                    ? Number.parseInt(month.total_cost)
                    : -1,
                type_line: `${LINE_KW}`,
                url: null,
                url_type: URL_TYPES.SCRAPPER,
            };
        });
    }

    const consumptionLines = valuesKwh.reverse().concat(valuesKw.reverse());

    const billing_period_start = formatDateDefault(
        subDate(parseDateDefault(values.summary[0].final_date), { years: 1 }),
    );

    const isFromCsv = consumptionsCaptureMode === CONSUMPTIONS_CAPTURE_MODE.CSV;

    const newValues = {
        address: values.address,
        billing_period_end: values.summary[0].final_date,
        billing_period_start: billing_period_start,
        certified: selectedRate?.isCertified || false,
        consumption_lines: consumptionLines,
        consumption_origin: isFromCsv ? USER_ORIGIN : DEFAULT_ORIGIN,
        from_pdf: ['automatic_pdf', 'semiautomatic_pdf'].includes(
            values.receipt_origin,
        ),
        has_low_tension_concept: values.has_low_tension_concept,
        holder: values.holder,
        is_bimonthly: values.is_bimonthly === '1',
        last_consumption: values.last_consumption,
        periodicity_type: selectedRate?.periodicityType || 0,
        political_division1: values.political_division1,
        political_division2: values.political_division2,
        previous_consumption: values.previous_consumption,
        project: selectedProject.id,
        rate: values.rate,
        receipt_origin: getReceiptOrigin(
            values.receipt_origin,
            consumptionsCaptureMode !== CONSUMPTIONS_CAPTURE_MODE.SCRAPPER,
            automaticHasChanges,
        ),
        service_number: values.service_number
            ? values.service_number.replace(/[^A-Za-z0-9 ]/g, '').trim()
            : values.service_number,
        spain_meter_type: values.spain_meter_type,
        terms_consumption: values.terms_consumption,
        threads_number: values.threads_number,
        without_consumption: false,
        zip_code: values.zip_code,
    };

    const isHourlyRate = getIsHourlyRate(selectedRate);
    if (selectedRate?.isCertified) {
        if (RATES_WITH_DIVISION.includes(selectedRate.name))
            newValues.rate_division = values.rate_division;
        if (selectedRate.name === 'DAC') {
            newValues.rate_division = values.rate_region;
            newValues.subsidy_rate = ratesNameIdDictionary[values.subsidy_rate];
        }
        if (DOMESTIC_RATES.includes(selectedRate.name))
            newValues.rate_division_summer = values.rate_division_summer;
        if (HIGH_TENSION_RATES.includes(selectedRate.name))
            newValues.contrated_demand = values.contracted_demand;
        if (selectedRate.name === 'GDMTH') {
            const gdmth_summary_data = values.summary.map((item) => {
                const base_conpsumption = getConsumptionValue(item.kWh?.base);

                const base_conpsumption_kw = getConsumptionValue(item.kW?.base);

                const intermediate_consumption = getConsumptionValue(
                    item.kWh?.middle,
                );

                const intermediate_consumption_kw = getConsumptionValue(
                    item.kW?.middle,
                );

                const peak_consumption = getConsumptionValue(item.kWh?.peak);

                const peak_consumption_kw = getConsumptionValue(item.kW?.peak);

                const total_consumption =
                    base_conpsumption +
                    intermediate_consumption +
                    peak_consumption;
                return {
                    base_conpsumption,
                    base_conpsumption_kw,
                    id: item.id,
                    initial_date: item.initial_date,
                    intermediate_consumption,
                    intermediate_consumption_kw,
                    maximum_demand: item?.maximum_demand || 0,
                    peak_consumption,
                    peak_consumption_kw,
                    power_factor: item.power_factor || 90,
                    total_consumption: total_consumption,
                };
            });
            newValues.gdmth_summary = gdmth_summary_data;
        } else if (
            isHourlyRate &&
            Object.keys(values?.summary?.[0]?.kWh || {}).length > 0
        )
            newValues.tiers_consumptions = getNormalizedSummary({
                contracted_demand: values.hourly_contracted_demand || {},
                summary: values.summary,
            });
    } else if (
        isHourlyRate &&
        Object.keys(values?.summary?.[0]?.kWh || {}).length > 0 &&
        !isEmpty(rate_configuration)
    )
        newValues.tiers_consumptions = getNormalizedSummary({
            contracted_demand: values.hourly_contracted_demand || {},
            summary: values.summary,
        });

    if (values.consumption_profile && !isFromCsv) {
        newValues.consumption_profile = values.consumption_profile;
        newValues.consumption_profile_array = getConsumptionProfileDistributed({
            consumption_profile: consumptionProfile?.consumption,
            consumption_profile_year: consumptionProfile?.year,
            rate_configuration: !isEmpty(rate_configuration)
                ? rate_configuration
                : DEFAULT_SCHEDULE_RATE_CONFIGURATION,
            summary: values.summary,
        });
    }

    if (isFromCsv) {
        newValues.consumption_profile_array = csvData?.consumptionProfileArray;
        newValues.consumptions_csv = values.consumptions_csv;
        newValues.demand_array = csvData?.demandArray;
    }

    return newValues;
};
