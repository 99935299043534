import React from 'react';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import GroupIcon from '@mui/icons-material/Group';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import { CheckAccess } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import { useBreakpoint } from 'common/hooks';
import {
    getSubscriptionStatus,
    getSubscriptionsStatus,
} from 'common/utils/helpers';

import * as profileSelectors from '../../../modules/profile/selectors';

import { handleOnMouseHover } from './helpers';
import StyledListItemButton from './StyledListItemButton';
import StyledStyledListItemText from './StyledStyledListItemText';

const ContactOptions = ({
    handleClose,
    handleDrawerToggle,
    match,
    mobileOpen,
    redirect,
    subscription,
    subscriptions,
    subscriptionVersion,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const { allHasPaymentDue, isMultipleSubscription } =
        getSubscriptionsStatus(subscriptions);
    const { isActive, isPaymentDue } = getSubscriptionStatus(subscription);

    const isDisabledV4 =
        subscriptionVersion === 'v4' && isActive && isPaymentDue;
    const isDisabledV5 =
        (subscriptionVersion === 'v5' &&
            !isMultipleSubscription &&
            isActive &&
            isPaymentDue) ||
        (subscriptionVersion === 'v5' &&
            isMultipleSubscription &&
            allHasPaymentDue);

    const onMouseLeave = (e) =>
        handleOnMouseHover({ e, breakpoint, handleClose });

    return (
        <Box onMouseLeave={onMouseLeave}>
            <CheckAccess
                requiredPermissions={[PERMISSION_LIST.CONTACTS_PERMISSION]}
            >
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/contacts');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={
                        match.path === '/contacts' ||
                        /^\/record/i.test(match.path) ||
                        /^\/proposal-review-pro/i.test(match.path)
                    }
                    variant="horizontal"
                >
                    <GroupIcon />
                    <StyledStyledListItemText primary={t('Contact list')} />
                </StyledListItemButton>
            </CheckAccess>
            <CheckAccess
                requiredPermissions={[
                    PERMISSION_LIST.TRACKING_CONTACTS_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/follow-ups');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/follow-ups' === match.path}
                    variant="horizontal"
                >
                    <CalendarTodayIcon />
                    <StyledStyledListItemText
                        primary={t('Follow-up', { count: 2 })}
                    />
                </StyledListItemButton>
            </CheckAccess>
        </Box>
    );
};

ContactOptions.propTypes = {
    handleClose: PropTypes.func,
    handleDrawerToggle: PropTypes.func,
    match: PropTypes.object,
    mobileOpen: PropTypes.bool,
    redirect: PropTypes.func,
    subscription: PropTypes.object,
    subscriptions: PropTypes.array,
    subscriptionVersion: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    subscription: profileSelectors.getMainSubscription,
    subscriptions: profileSelectors.getSubscriptions,
    subscriptionVersion: profileSelectors.getSubscriptionVersion,
});

export default connect(mapStateToProps, null)(ContactOptions);
