export const NAME = 'ZAP-MODULE';

export const SERVICE_NUMBER_REGEX = /^\d{12}$/;

export const ENTITIES = {
    CREDENTIAL: 'credential',
    RPU: 'rpu',
};

export const DOCUMENT_TYPES = {
    ALL: -1,
    PDF: 0,
    XML: 1,
};

export const MAX_ATTEMPTS = {
    [ENTITIES.CREDENTIAL]: 2,
    [ENTITIES.RPU]: 3,
};

export const STATUS = {
    ERROR: 1,
    SUCCESS: 0,
};

export const STATUS_CONFIG = {
    [STATUS.ERROR]: { color: '#d63031', label: 'Error' },
    [STATUS.SUCCESS]: { color: '#00b894', label: 'Success' },
};

export const ZAP_COLOR = '#DA70D6';

export const EVENT_TYPES = {
    ERROR: 'error',
    PROGRESS: 'progress',
    SUCCESS: 'success',
};

export const CHAIN_WORKFLOWS = {
    DEFAULT: 0,
    LINK_AND_SYNC: 1,
    SYNC_MI_ESPACIO: 2,
};

export const CHAIN_WORKFLOWS_CONFIG = {
    [CHAIN_WORKFLOWS.LINK_AND_SYNC]: {
        title: 'Linking and synchronizing',
        steps: [
            'Attempting to link',
            'Synchronizing with "Mi Espacio CFE"',
            'Synchronizing with "Consulta tu Recibo"',
            'Attempting to link with additional information',
            'Synchronizing with "Mi Espacio CFE"',
        ],
    },
    [CHAIN_WORKFLOWS.SYNC_MI_ESPACIO]: {
        title: 'Document download',
        steps: [
            'Synchronizing with "Mi Espacio CFE"',
            'Attempting to link',
            'Synchronizing with "Mi Espacio CFE"',
        ],
    },
};
