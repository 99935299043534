import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { costingChoiceToLabel } from 'common/utils/helpers';

const UnitPriceColumn = ({
    canModify,
    control,
    costingUnit,
    customerMode,
    index,
    name,
    prefixId,
    size,
}) => {
    const { t } = useTranslation();
    let costing_label = '';
    if (
        ['accessory', 'additional', 'structure', 'workforce'].includes(prefixId)
    )
        costing_label = `(${costingChoiceToLabel(costingUnit)})`;

    if (!canModify) return null;

    return (
        <Grid size={{ lg: customerMode ? 18 : size.unit_price[0], xs: 'grow' }}>
            <ReactHookFormIntlNumberInput
                control={control}
                disabled
                fullWidth
                label={`${t('Unit Price')} ${costing_label}`}
                name={`${name}.${index}.unit_price`}
                variant="standard"
            />
        </Grid>
    );
};

UnitPriceColumn.propTypes = {
    canModify: PropTypes.bool,
    control: PropTypes.object,
    costingUnit: PropTypes.string,
    customerMode: PropTypes.bool,
    index: PropTypes.number,
    name: PropTypes.string,
    prefixId: PropTypes.string,
    size: PropTypes.object,
};

export default withPermissions(PERMISSION_LIST.SEE_UNIT_PRICE)(UnitPriceColumn);
