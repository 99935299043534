import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import FiltersForm from 'common/components/FiltersForm';
import { getIsMexicanAccount } from 'common/utils/helpers/session';

import * as credentialIntegrationsSelectors from '../../../credentialIntegrations/selectors';
import {
    REPORT_RESOLUTIONS_CONFIG,
    REPORT_STATUS_CONFIG,
} from '../../../reportList/constants';
import * as actions from '../../actions';
import { FILTERS_KEYS, RATE_ID_FILTERS } from '../../constants';
import { getFilteredSelectOptions } from '../../helpers';
import * as selectors from '../../selectors';
import ReportName from '../ReportName';

const RELATED_FILTERS_ORDER = [
    FILTERS_KEYS.AGENT,
    FILTERS_KEYS.CONTACT_ID,
    FILTERS_KEYS.PROJECT_STATUS,
    FILTERS_KEYS.RATE_ID,
    FILTERS_KEYS.PROJECT_ID,
    FILTERS_KEYS.POLITICAL_DIVISION,
    FILTERS_KEYS.ZIP_CODE,
];

const FormContainer = ({
    changeFilters,
    filtersData,
    handleClose,
    initialValues,
    isLoadingFilters,
    projectFilters,
    providersForSelect,
}) => {
    const [filtersOptions, setFiltersOptions] = useState({});
    const { t } = useTranslation();
    const isMexicanAccount = getIsMexicanAccount();
    const politicalDivisionLabel = isMexicanAccount
        ? t('State')
        : t('Political division of order 1');

    const reportStatusOptions = Object.entries(REPORT_STATUS_CONFIG).map(
        ([value, { label }]) => ({ label: t(label), value }),
    );
    const reportResolutionOptions = Object.entries(
        REPORT_RESOLUTIONS_CONFIG,
    ).map(([value, { label }]) => ({ label: t(label), value }));

    const handleFilterProjectOptions = (currentValues = {}) => {
        const newFiltersOptions = getFilteredSelectOptions({
            agentId: currentValues[FILTERS_KEYS.AGENT],
            contactId: currentValues[FILTERS_KEYS.CONTACT_ID],
            filtersData,
            politicalDivisionId: currentValues[FILTERS_KEYS.POLITICAL_DIVISION],
            projectId: currentValues[FILTERS_KEYS.PROJECT_ID],
            projectStatusId: currentValues[FILTERS_KEYS.PROJECT_STATUS],
            rateId: currentValues[FILTERS_KEYS.RATE_ID],
            zipCode: currentValues[FILTERS_KEYS.ZIP_CODE],
        });

        setFiltersOptions(newFiltersOptions);
    };

    useEffect(() => {
        handleFilterProjectOptions(initialValues);
    }, [projectFilters, initialValues]);

    const onSubmit = (data) => {
        const newData = { ...data };

        const rateId = data[FILTERS_KEYS.RATE_ID];

        if (rateId) {
            if (
                filtersData?.some((item) => item?.customize_rate?.id === rateId)
            ) {
                delete newData[RATE_ID_FILTERS.CERTIFIED];
                newData[RATE_ID_FILTERS.NOT_CERTIFIED] = rateId;
            } else {
                delete newData[RATE_ID_FILTERS.NOT_CERTIFIED];
                newData[RATE_ID_FILTERS.CERTIFIED] = rateId;
            }
        }

        changeFilters(newData);
        handleClose();
    };

    const resetRelatedFilters = (key, setValue) => {
        const initialIndex = RELATED_FILTERS_ORDER.indexOf(key);

        for (let i = initialIndex + 1; i < RELATED_FILTERS_ORDER.length; i++)
            setValue(RELATED_FILTERS_ORDER[i], null);
    };

    return (
        <FiltersForm
            filters={[
                {
                    label: t('Project'),
                    fields: [
                        {
                            disabled: isLoadingFilters,
                            label: t('Agent'),
                            name: FILTERS_KEYS.AGENT,
                            onChange: ({ getValues, setValue, value }) => {
                                handleFilterProjectOptions({
                                    ...getValues(),
                                    [FILTERS_KEYS.AGENT]: value,
                                });
                                resetRelatedFilters(
                                    FILTERS_KEYS.AGENT,
                                    setValue,
                                );
                            },
                            options: filtersOptions.agents || [],
                            type: 'select',
                        },
                        {
                            disabled: isLoadingFilters,
                            label: t('Contact'),
                            name: FILTERS_KEYS.CONTACT_ID,
                            onChange: ({ getValues, setValue, value }) => {
                                handleFilterProjectOptions({
                                    ...getValues(),
                                    [FILTERS_KEYS.CONTACT_ID]: value,
                                });

                                resetRelatedFilters(
                                    FILTERS_KEYS.CONTACT_ID,
                                    setValue,
                                );
                            },
                            options: filtersOptions.contacts || [],
                            type: 'select',
                        },
                        {
                            disabled: isLoadingFilters,
                            label: t('Project status'),
                            name: FILTERS_KEYS.PROJECT_STATUS,
                            onChange: ({ getValues, setValue, value }) => {
                                handleFilterProjectOptions({
                                    ...getValues(),
                                    [FILTERS_KEYS.PROJECT_STATUS]: value,
                                });
                                resetRelatedFilters(
                                    FILTERS_KEYS.PROJECT_STATUS,
                                    setValue,
                                );
                            },
                            options: filtersOptions.projectStatuses || [],
                            type: 'select',
                        },
                        {
                            disabled: isLoadingFilters,
                            label: t('Rate'),
                            name: FILTERS_KEYS.RATE_ID,
                            onChange: ({ getValues, setValue, value }) => {
                                handleFilterProjectOptions({
                                    ...getValues(),
                                    [FILTERS_KEYS.RATE_ID]: value,
                                });

                                resetRelatedFilters(
                                    FILTERS_KEYS.RATE_ID,
                                    setValue,
                                );
                            },
                            options: filtersOptions.rates || [],
                            type: 'select',
                        },
                        {
                            disabled: isLoadingFilters,
                            label: t('Project'),
                            name: FILTERS_KEYS.PROJECT_ID,
                            onChange: ({ getValues, setValue, value }) => {
                                handleFilterProjectOptions({
                                    ...getValues(),
                                    [FILTERS_KEYS.PROJECT_ID]: value,
                                });

                                resetRelatedFilters(
                                    FILTERS_KEYS.PROJECT_ID,
                                    setValue,
                                );
                            },
                            options: filtersOptions.projects || [],
                            type: 'select',
                        },
                    ],
                },
                {
                    label: t('Location'),
                    fields: [
                        {
                            disabled: isLoadingFilters,
                            label: politicalDivisionLabel,
                            name: FILTERS_KEYS.POLITICAL_DIVISION,
                            options: filtersOptions.politicalDivisions || [],
                            onChange: ({ getValues, setValue, value }) => {
                                handleFilterProjectOptions({
                                    ...getValues(),
                                    [FILTERS_KEYS.POLITICAL_DIVISION]: value,
                                });

                                resetRelatedFilters(
                                    FILTERS_KEYS.POLITICAL_DIVISION,
                                    setValue,
                                );
                            },
                            type: 'select',
                        },
                        {
                            label: t('Zip code'),
                            name: FILTERS_KEYS.ZIP_CODE,
                            options: filtersOptions.zipCodes || [],
                            type: 'select',
                        },
                    ],
                },
                {
                    label: t('Report', { count: 2 }),
                    fields: [
                        {
                            label: t('Report status'),
                            name: FILTERS_KEYS.REPORT_STATUS,
                            options: [
                                { label: '', options: reportStatusOptions },
                            ],
                            renderOption: ({ label, value }) => (
                                <ReportName name={label} status={value} />
                            ),
                            type: 'select',
                        },
                        {
                            label: t('Creation type'),
                            name: FILTERS_KEYS.REPORT_RESOLUTION,
                            options: [
                                { label: '', options: reportResolutionOptions },
                            ],
                            type: 'select',
                        },
                        {
                            label: t('Electric bill status'),
                            name: FILTERS_KEYS.ELECTRIC_BILL_STATUS,
                            options: [{ label: '', options: [] }],
                            type: 'select',
                            visible: false,
                        },
                        {
                            label: t('Provider'),
                            name: FILTERS_KEYS.PROVIDER,
                            options: [
                                { label: '', options: providersForSelect },
                            ],
                            type: 'select',
                            visible: false,
                        },
                    ],
                },
            ]}
            initialValues={initialValues}
            onCancel={handleClose}
            onSubmit={onSubmit}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    filtersData: selectors.getFiltersData,
    initialValues: selectors.getInitialValuesFilters,
    isLoadingFilters: selectors.getFiltersLoading,
    providersForSelect: credentialIntegrationsSelectors.getProvidersForSelect,
});

const mapDispatchToProps = (dispatch) => ({
    changeFilters: (filters) => dispatch(actions.changeFilters(filters)),
});

FormContainer.propTypes = {
    changeFilters: PropTypes.func,
    filtersData: PropTypes.array,
    handleClose: PropTypes.func,
    initialValues: PropTypes.object,
    isLoadingFilters: PropTypes.bool,
    projectFilters: PropTypes.array,
    providersForSelect: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
