import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

import ChartJs from 'common/components/ChartJs';

import { buildChartJsConfig } from '../../helpers';
import ChartWrapper from '../ChartWrapper';

const ChartComponent = ({ countryCurrencyLocale, seriesData }) => {
    const [chartConfig, setChartConfig] = useState({
        data: { datasets: [], labels: [] },
        options: {},
    });
    const { t } = useTranslation();

    useEffect(() => {
        const chartOptions = buildChartJsConfig({
            categories: seriesData?.categories,
            countryCurrencyLocale,
            pointsSelection: false,
            series: seriesData?.series,
        });
        setChartConfig(chartOptions);
    }, [seriesData]);

    const { data, options } = chartConfig;

    return (
        <>
            <Typography variant="caption" fontWeight="bold">
                {t('Generation')}
            </Typography>

            <ChartWrapper height="300px">
                <ChartJs data={data} options={options} type="bar" />
            </ChartWrapper>
        </>
    );
};

ChartComponent.propTypes = {
    countryCurrencyLocale: PropTypes.string,
    seriesData: PropTypes.object,
};

export default ChartComponent;
