import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';

import { PREFIX_ZAP } from 'common/sockets/constants';
import { generateSocketActions } from 'common/sockets/helpers';

import {
    FETCH_CREDENTIALS,
    FETCH_CREDENTIALS_FAILURE,
    FETCH_CREDENTIALS_SUCCESS,
    FETCH_FILE,
    FETCH_FILE_FAILURE,
    FETCH_FILE_SUCCESS,
    FETCH_RPUS,
    FETCH_RPUS_FAILURE,
    FETCH_RPUS_SUCCESS,
    RESET,
    RESET_ENTITY,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    credentials: { data: [], error: null, loading: false },
    file: { data: null, error: null, loading: false },
    rpus: {},
    socketInfo: { connected: false, error: null },
};

const {
    BROKEN_SOCKET,
    CLOSED_SOCKET,
    ERROR_SOCKET,
    MESSAGE_SOCKET,
    OPEN_SOCKET,
    RECONNECTED,
} = generateSocketActions(PREFIX_ZAP);

const lisaFilesSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_CREDENTIALS]: (state) => {
            state.credentials.data = [];
            state.credentials.error = null;
            state.credentials.loading = true;
        },
        [FETCH_CREDENTIALS_FAILURE]: (state, action) => {
            state.credentials.error = action.payload;
            state.credentials.loading = false;
        },
        [FETCH_CREDENTIALS_SUCCESS]: (state, action) => {
            state.credentials.data = action.payload;
            state.credentials.loading = false;
        },
        [FETCH_FILE]: (state) => {
            state.file.data = null;
            state.file.error = null;
            state.file.loading = true;
        },
        [FETCH_FILE_FAILURE]: (state, action) => {
            state.file.error = action.payload;
            state.file.loading = false;
        },
        [FETCH_FILE_SUCCESS]: (state, action) => {
            state.file.data = action.payload;
            state.file.loading = false;
        },
        [FETCH_RPUS]: (state, action) => {
            const { serviceNumber } = action?.payload || {};
            state.rpus[serviceNumber] = {
                data: [],
                error: null,
                loading: true,
            };
        },
        [FETCH_RPUS_FAILURE]: (state, action) => {
            const { error, serviceNumber } = action?.payload || {};

            if (!state.rpus[serviceNumber]) state.rpus[serviceNumber] = {};

            state.rpus[serviceNumber].error = error;
            state.rpus[serviceNumber].loading = false;
        },
        [FETCH_RPUS_SUCCESS]: (state, action) => {
            const { data, serviceNumber } = action?.payload || {};

            if (!state.rpus[serviceNumber]) state.rpus[serviceNumber] = {};

            state.rpus[serviceNumber].data = data;
            state.rpus[serviceNumber].loading = false;
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_ENTITY]: (state, action) => {
            const { entity, id } = action?.payload || {};

            if (!state.socketInfo[entity]) return;

            delete state.socketInfo[entity][id];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(BROKEN_SOCKET, (state) => {
                state.socketInfo.error = i18next.t(
                    'Error sending data. Restart your session or try again later',
                );
            })
            .addCase(ERROR_SOCKET, (state) => {
                state.socketInfo.error = i18next.t(
                    'Server error. Try again later',
                );
            })
            .addCase(RECONNECTED, (state) => {
                state.socketInfo.error = null;
            })
            .addCase(CLOSED_SOCKET, (state) => {
                state.socketInfo.connected = false;
            })
            .addCase(OPEN_SOCKET, (state) => {
                state.socketInfo.connected = true;
            })
            .addCase(MESSAGE_SOCKET, (state, action) => {
                const message = action?.payload?.message;

                if (!message) {
                    state.socketInfo.socketError = i18next.t(
                        'Server error. Try again later',
                    );
                    return;
                }

                const parsedMessage = JSON.parse(message);

                if (!parsedMessage?.entity) return;

                /**
                 * entity: 'credential' | 'rpu'
                 * status: 0 | 1
                 */
                const { entity, id, ...rest } = parsedMessage;

                if (!state.socketInfo[entity]) state.socketInfo[entity] = {};
                if (!state.socketInfo[entity][id])
                    state.socketInfo[entity][id] = {};

                state.socketInfo[entity][id] = rest;
            });
    },
});

export const actions = lisaFilesSlice.actions;

export default lisaFilesSlice.reducer;
