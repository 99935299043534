import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Divider,
    Grid,
    ButtonGroup,
    Button,
    Table,
    TableContainer,
    TableFooter,
    TableCell,
    TableRow,
    TableBody,
    Typography,
} from 'sunwise-ui';

import { MONTHS } from 'common/constants';
import { useBreakpoint } from 'common/hooks';
import {
    getMonthLabel,
    getSourceName,
    numberFormat,
} from 'common/utils/helpers';

const TableConfig = ({
    availableSources,
    countryCurrencyLocale,
    formValues,
    getAvg,
    isDefault = false,
    sourceData,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    let hrSol = new Array(12).fill(0);
    let kwKwh = new Array(12).fill(0);
    let origin = '';
    let temp_max = 0;
    let temp_min = 0;

    if (
        sourceData &&
        sourceData[0] &&
        sourceData[0].monthly_solar_radiation_hrsol &&
        sourceData[0].monthly_solar_radiation_hrsol.length > 0
    ) {
        hrSol = sourceData[0].monthly_solar_radiation_hrsol.map((item) => {
            return { value: item.average };
        });

        kwKwh = sourceData[0].monthly_solar_radiation_hrsol.map((item) => {
            return { value: (item.average * 365).toFixed(2) };
        });

        temp_min = sourceData[0].temp_min;
        temp_max = sourceData[0].temp_max;
    }

    if (formValues) {
        origin = getSourceName(formValues.source, availableSources);
    }

    return (
        <>
            <Grid container>
                {isDefault === true && origin && (
                    <Grid size={{ xs: 'grow' }}>
                        <Typography variant="caption">
                            {t('Generation source')}: {origin}
                        </Typography>
                    </Grid>
                )}

                <Grid size={{ xs: 'grow' }}>
                    <ButtonGroup variant="outlined">
                        <Button>{t('Temp')}</Button>
                        <Button>
                            {t('Min')}{' '}
                            {numberFormat(temp_min, {
                                locale: countryCurrencyLocale,
                                style: 'decimal',
                                unit: 'ºC',
                            })}
                        </Button>
                        <Button>
                            {t('Max')}{' '}
                            {numberFormat(temp_max, {
                                locale: countryCurrencyLocale,
                                style: 'decimal',
                                unit: 'ºC',
                            })}
                        </Button>
                    </ButtonGroup>
                </Grid>

                <Grid size={{ xs: 'grow' }} sx={{ textAlign: 'right' }}>
                    <ButtonGroup variant="outlined">
                        <Button>{t('Average')}</Button>
                        <Button>
                            {numberFormat(getAvg(hrSol), {
                                locale: countryCurrencyLocale,
                                style: 'decimal',
                                unit: t('Hr Sun'),
                            })}
                        </Button>
                        <Button>
                            {numberFormat(getAvg(kwKwh), {
                                locale: countryCurrencyLocale,
                                style: 'decimal',
                                unit: 'Kwh/Kw',
                            })}
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            <TableContainer>
                <Table
                    className={breakpoint === 'xs' ? 'transposed' : null}
                    size="small"
                >
                    <TableBody>
                        <TableRow>
                            <TableCell>{t('Hr Sun')}</TableCell>
                            {hrSol.map((data, index) => (
                                <TableCell key={`hrSolData-${index}`}>
                                    {numberFormat(data.value, {
                                        locale: countryCurrencyLocale,
                                        style: 'decimal',
                                    })}
                                </TableCell>
                            ))}
                        </TableRow>

                        <TableRow>
                            <TableCell>Kwh / Kw</TableCell>
                            {kwKwh.map((data, index) => (
                                <TableCell key={`KwKwhData-${index}`}>
                                    {numberFormat(data.value, {
                                        locale: countryCurrencyLocale,
                                        style: 'decimal',
                                    })}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            <TableCell>
                                {breakpoint === 'xs' ? t('Month') : ''}
                            </TableCell>
                            {MONTHS.map((month) => (
                                <TableCell key={`month-${month}`}>
                                    {getMonthLabel(month)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
};

TableConfig.propTypes = {
    availableSources: PropTypes.array,
    countryCurrencyLocale: PropTypes.string,
    formValues: PropTypes.object,
    getAvg: PropTypes.func,
    isDefault: PropTypes.bool,
    sourceData: PropTypes.array,
};

export default TableConfig;
