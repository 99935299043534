import { getOfferInfo } from 'common/api/v1/offerInfoPatern';

import {
    FETCH_OFFER_INFO_PATERN,
    FETCH_OFFER_INFO_PATERN_FAILURE,
    FETCH_OFFER_INFO_PATERN_SUCCESS,
} from '../actionTypes';
import { getElectricBillPerMonths } from '../helpers';
import { proposalReviewProActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(proposalReviewProActions[FETCH_OFFER_INFO_PATERN]());

    getOfferInfo(id)
        .then((response) => {
            const {
                contact_id,
                contact_name,
                credit_application,
                financing,
                has_approbed,
                has_credit_close,
                has_permalink,
                has_project_profiles,
                has_signature,
                is_bimonthly,
                is_certified_rate,
                lines_consumption_energies,
                permalink,
                project_id,
                project_name,
                proposal_item,
                proposal_name,
                read_only,
                status,
            } = response.data;
            dispatch(
                proposalReviewProActions[FETCH_OFFER_INFO_PATERN_SUCCESS]({
                    contactId: contact_id,
                    contactName: contact_name,
                    creditApplication: proposal_item?.credit_application,
                    creditItem: proposal_item?.credit_item,
                    creditStatus: credit_application?.status,
                    electricBills: getElectricBillPerMonths({
                        isBimonthly: is_bimonthly,
                        lines: lines_consumption_energies,
                    }),
                    financing,
                    hasClosedCredit: has_credit_close,
                    hasPermalink: has_permalink,
                    hasProjectProfiles: has_project_profiles,
                    hasSignature: has_signature,
                    isApproved: has_approbed,
                    isCertifiedRate: is_certified_rate,
                    isReadOnly: read_only ?? false,
                    permalink: permalink,
                    projectId: project_id,
                    projectName: project_name,
                    proposalName: proposal_name,
                    proposalStatus: status,
                }),
            );
        })
        .catch((error) =>
            dispatch(
                proposalReviewProActions[FETCH_OFFER_INFO_PATERN_FAILURE](
                    error,
                ),
            ),
        );
};
