import React, { useEffect, useState } from 'react';

import { Box, Button, Divider } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Debouncer } from 'common/components/form/bootstrap';
import ShowErrors from 'common/components/ShowErrors';
import yupResolver from 'common/utils/yupResolver';

import * as projectConsumptionCardActions from '../../projectConsumptionCard/actions';
import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

import Chart from './Chart';
import DevicesTable from './DevicesTable';

const ConsumptionForm = ({
    fetchProjectConsumption,
    initialValues,
    onCancel,
    projectId,
    saveConsumption,
}) => {
    const [formValues, setFormValues] = useState(initialValues);

    const { t } = useTranslation();

    const {
        control,
        formState: { errors, isSubmitted, isValid },
        getValues,
        handleSubmit,
        reset,
        setValue,
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const disabled = false;

    const onSave = (data) =>
        saveConsumption(projectId, data, () =>
            fetchProjectConsumption(projectId),
        );

    return (
        <>
            <Debouncer control={control} setFormValues={setFormValues} />

            <form onSubmit={handleSubmit(onSave)}>
                <DevicesTable
                    control={control}
                    devicesValues={formValues?.devices}
                    disabled={disabled}
                    errors={isValid ? [] : errors?.devices}
                    getValues={getValues}
                    setValue={setValue}
                />

                <Divider sx={{ mb: 3, mt: 0 }} />

                <Chart formValues={formValues} />

                {isSubmitted && !isValid && (
                    <ShowErrors
                        errors={[
                            t(
                                'Complete all fields with the correct information',
                            ),
                        ]}
                    />
                )}

                <Box
                    display="flex"
                    flexDirection={{ md: 'row', xs: 'column' }}
                    gap={1}
                    justifyContent={{ md: 'right', xs: 'center' }}
                    mt={2}
                    width="100%"
                >
                    <Button
                        color="secondary"
                        onClick={onCancel}
                        sx={{
                            order: { md: 1, xs: 2 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        variant="text"
                    >
                        {t('Cancel')}
                    </Button>

                    <Button
                        disabled={disabled}
                        sx={{
                            order: { md: 2, xs: 1 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        type="submit"
                    >
                        {t('Save')}
                    </Button>
                </Box>
            </form>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
});

const mapDispatchToProps = (dispatch) => ({
    fetchProjectConsumption: (projectId) =>
        dispatch(
            projectConsumptionCardActions.fetchConsumptionEnergy(projectId),
        ),
    saveConsumption: (projectId, data, onSuccess) =>
        dispatch(actions.saveConsumption(projectId, data, onSuccess)),
});

ConsumptionForm.propTypes = {
    fetchProjectConsumption: PropTypes.func,
    initialValues: PropTypes.object,
    onCancel: PropTypes.func,
    projectId: PropTypes.string,
    saveConsumption: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsumptionForm);
