import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getFilters = createSelector(
    getModel,
    (model) => model.filters || {},
);

export const getFiltersData = createSelector(
    getFilters,
    (filters) => filters.data || [],
);

export const getFiltersErrors = createSelector(
    getFilters,
    (filters) => filters.errors || null,
);

export const getFiltersLoading = createSelector(
    getFilters,
    (filters) => filters.loading || false,
);

export const getInitialValuesFilters = createSelector(
    getModel,
    (model) => model.initialValuesFilters || {},
);

export const getIsOpenFiltersModal = createSelector(
    getModel,
    (model) => model.isOpenFiltersModal || false,
);

export const getReportsModel = createSelector(
    getModel,
    (model) => model.reports,
);

export const getReportHistoricals = createSelector(
    getModel,
    (model) => model.reportHistoricals || {},
);

export const getReportHistoricalsData = createSelector(
    getReportHistoricals,
    (reportHistoricals) => reportHistoricals.data || {},
);

export const getReportHistoricalsErrors = createSelector(
    getReportHistoricals,
    (reportHistoricals) => reportHistoricals.errors || null,
);

export const getReportHistoricalsLoading = createSelector(
    getReportHistoricals,
    (reportHistoricals) => reportHistoricals.loading || false,
);
