import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid } from 'sunwise-ui';

import Plans from './Plans';

const LandingV5 = ({
    addonName,
    canModify,
    flluidView,
    minHeight = '100%',
    url,
}) => {
    const { t } = useTranslation();
    const [showWizard, setShowWizard] = useState(false);

    if (showWizard)
        return (
            <Plans
                addonName={addonName}
                flluidView={flluidView}
                handleClickBack={() => setShowWizard(false)}
            />
        );

    return (
        <Box display="flex" flexDirection="column" gap={1} height={minHeight}>
            <Grid container p={canModify ? 2 : 0}>
                <Grid size={{ xs: 18 }} sx={{ textAlign: 'right', px: 2 }}>
                    <Button
                        onClick={() => setShowWizard(true)}
                        sx={{ ml: { md: 'auto', xs: 0 }, mt: 1 }}
                        visible={canModify}
                    >
                        {t('Get access')}
                    </Button>
                </Grid>
            </Grid>
            <iframe
                src={url}
                style={{ border: 0, height: '100%', width: '100%' }}
            />
        </Box>
    );
};

LandingV5.propTypes = {
    addonName: PropTypes.string,
    canModify: PropTypes.bool,
    flluidView: PropTypes.bool,
    minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    url: PropTypes.string,
};

export default LandingV5;
