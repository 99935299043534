import React, { useEffect } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import toNumber from 'lodash/toNumber';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, IconButton, Tooltip } from 'sunwise-ui';

import ReactHookFormIntlNumberInput from 'common/components/form/bootstrap/ReactHookFormIntlNumberInput';
import { getMaximumDiscount } from 'common/utils/helpers/session';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import {
    getPriceByWattMin,
    getSubtotalMin,
    valueToPercentage,
} from '../helpers';
import * as selectors from '../selectors';
import subtotalValidate from '../subtotalValidate';

import SubtotalTypeGroup from './SubtotalTypeGroup';

const SubtotalForm = ({
    costingValues,
    currencyIso,
    currencySymbol,
    initialValues,
    isProductCosting,
    isSaving,
    proposalId,
    save,
    setEditable,
    subtotal,
}) => {
    const { t } = useTranslation();
    const { system_size, type_change } = costingValues;

    const subtotalMin = getSubtotalMin({
        maximumDiscount: getMaximumDiscount(),
        subtotal,
    });

    const priceByWattMin = getPriceByWattMin({
        currencyIso,
        maximumDiscount: getMaximumDiscount(),
        subtotal,
        systemSize: system_size,
        typeChangeOffer: type_change,
    });

    const { control, handleSubmit, reset, setValue, watch } = useForm({
        context: { priceByWattMin, subtotalMin },
        defaultValues: initialValues,
        resolver: yupResolver(subtotalValidate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const handleOnSave = (values) =>
        save(proposalId, values, () => setEditable(false));

    const handleChangePricePerWatt = ({ value }) => {
        const newSubtotal = value * system_size * 1000 * type_change;
        const discountAmount = subtotal - newSubtotal;
        const newValue = valueToPercentage({
            subtotal,
            value: discountAmount,
        });
        setValue('subtotal_amount', toNumber(newSubtotal));
        setValue('type_discount', 2);
        setValue('discount_amount', toNumber(discountAmount).toFixed(2));
        setValue('discount_percentage', toNumber(newValue).toFixed(2));
    };

    const handleChangeSubtotal2 = ({ value }) => {
        const divisor = system_size * 1000 * type_change;
        const newPricePerWatt = divisor > 0 ? value / divisor : 0;
        const discountAmount = subtotal - value;
        const newValue = valueToPercentage({
            subtotal,
            value: discountAmount,
        });
        setValue('price_per_watt', toNumber(newPricePerWatt).toFixed(2));
        setValue('type_discount', 2);
        setValue('discount_amount', toNumber(discountAmount).toFixed(2));
        setValue('discount_percentage', toNumber(newValue).toFixed(2));
    };

    const isSubtotal = watch('type_subtotal') === 0;

    return (
        <Box
            alignItems="center"
            display="flex"
            gap={1}
            justifyContent="flex-end"
        >
            <Box alignItems="center" display="flex" gap={2}>
                {isSubtotal && (
                    <ReactHookFormIntlNumberInput
                        control={control}
                        disabled={isSaving}
                        label={`${t('Subtotal')} (2)`}
                        min="0"
                        name="subtotal_amount"
                        onBlur={handleChangeSubtotal2}
                        prepend={currencySymbol}
                        variant="standard"
                    />
                )}
                {!isSubtotal && (
                    <ReactHookFormIntlNumberInput
                        control={control}
                        disabled={isSaving}
                        label={t('Price per watt')}
                        min="0"
                        name="price_per_watt"
                        onBlur={handleChangePricePerWatt}
                        prepend={currencySymbol}
                        variant="standard"
                    />
                )}
                {isProductCosting && (
                    <SubtotalTypeGroup
                        handleOnChange={(value) =>
                            setValue('type_subtotal', value)
                        }
                        selected={watch('type_subtotal')}
                    />
                )}
            </Box>
            <Tooltip title={t('Save changes')}>
                <IconButton onClick={handleSubmit(handleOnSave)}>
                    <SaveIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Tooltip title={t('Cancel')}>
                <IconButton onClick={() => setEditable(false)}>
                    <CancelIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

SubtotalForm.propTypes = {
    costingValues: PropTypes.object,
    currencyIso: PropTypes.string,
    currencySymbol: PropTypes.string,
    initialValues: PropTypes.object,
    isProductCosting: PropTypes.bool,
    isSaving: PropTypes.bool,
    proposalId: PropTypes.string,
    save: PropTypes.func,
    setEditable: PropTypes.func,
    subtotal: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
    costingValues: selectors.getInitialCostingValues,
    initialValues: selectors.getInitialDiscountValues,
    isSaving: selectors.getIsSavingDiscount,
});

const mapDispatchToProps = (dispatch) => ({
    save: (proposalId, values, callback) =>
        dispatch(actions.saveDiscount(proposalId, values, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubtotalForm);
