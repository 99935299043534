import get from 'lodash/get';

import { updateSection } from 'common/api/v1/onboarding';

import * as profileSelectors from '../../profile/selectors';
import {
    UPDATE_SECTION,
    UPDATE_SECTION_FAILURE,
    UPDATE_SECTION_SUCCESS,
} from '../actionTypes';
import { getStepIndex } from '../helpers';
import { onboardingWizardActions } from '../reducer';
import * as selectors from '../selectors';

export default (section) => (dispatch, getState) => {
    const initSection = selectors.getOnboardingSection(getState());
    const subscription = profileSelectors.getActiveSubscription(getState());
    const productType = get(subscription, 'product_type', null);
    const isAftersales = productType === 'aftersales';

    if (
        getStepIndex(isAftersales, initSection) >
        getStepIndex(isAftersales, section)
    )
        return false;
    dispatch(onboardingWizardActions[UPDATE_SECTION]());

    updateSection({ section })
        .then(() => {
            dispatch(onboardingWizardActions[UPDATE_SECTION_SUCCESS]());
        })
        .catch((error) => {
            dispatch(
                onboardingWizardActions[UPDATE_SECTION_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
        });
};
