import { NAME } from './constants';

export const FETCH_CREDENTIALS = `${NAME}/FETCH_CREDENTIALS`;
export const FETCH_CREDENTIALS_FAILURE = `${NAME}/FETCH_CREDENTIALS_FAILURE`;
export const FETCH_CREDENTIALS_SUCCESS = `${NAME}/FETCH_CREDENTIALS_SUCCESS`;

export const FETCH_FILE = `${NAME}/FETCH_FILE`;
export const FETCH_FILE_FAILURE = `${NAME}/FETCH_FILE_FAILURE`;
export const FETCH_FILE_SUCCESS = `${NAME}/FETCH_FILE_SUCCESS`;

export const FETCH_RPUS = `${NAME}/FETCH_RPUS`;
export const FETCH_RPUS_FAILURE = `${NAME}/FETCH_RPUS_FAILURE`;
export const FETCH_RPUS_SUCCESS = `${NAME}/FETCH_RPUS_SUCCESS`;

export const RESET = `${NAME}/RESET`;
export const RESET_ENTITY = `${NAME}/RESET_ENTITY`;
