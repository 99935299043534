import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import InstallationDateWarning from '../../../afterSalesSettings/components/warnings/InstallationDateWarning';
import * as afterSalesSettingsSelectors from '../../../afterSalesSettings/selectors';
import * as supplierIntegrationsSelectors from '../../../supplierIntegrations/selectors';
import * as actions from '../../actions';
import { resetEnergyFields } from '../../helpers';

import ContractedDemandFields from './ContractedDemandFields';
import DateFields from './DateFields';
import DemandFields from './DemandFields';
import DemandWithoutSolarFields from './DemandWithoutSolarFields';
import EnergyExportPriceFields from './EnergyExportPriceFields';
import FormBottomActions from './FormBottomActions';
import GeneralInformation from './GeneralInformation';
import NetMeteringFields from './NetMeteringFields';
import PPAFields from './PPAFields';
import ResolvedIntegrationFields from './ResolvedIntegrationFields';

const GeneralStep = ({
    compensationScheme,
    control,
    disabled,
    errors,
    fetchCupsInfo,
    getValues,
    handleClickBack,
    handleClickNext,
    rate,
    rateConfiguration,
    ratesDictionary,
    readOnly,
    reset,
    serviceNumber,
    setValue,
    sipsIntegration,
}) => {
    const [finalDate, initialDate] = useWatch({
        control,
        name: ['final_date', 'initial_date'],
    });

    const canUseCups = Boolean(sipsIntegration?.id);

    const onChangeDate = () => {
        resetEnergyFields({
            formValues: getValues(),
            rateConfiguration,
            rate,
            reset,
        });
    };

    const handleOnChangeDate = () => {
        if (canUseCups)
            fetchCupsInfo({
                cups: serviceNumber,
                getValues,
                onFailed: onChangeDate,
            });
        else onChangeDate();
    };

    return (
        <>
            <DateFields
                control={control}
                disabled={disabled}
                finalDate={finalDate}
                handleOnChangeDate={handleOnChangeDate}
                initialDate={initialDate}
            />

            <InstallationDateWarning date={initialDate} />

            <GeneralInformation
                control={control}
                disabled={disabled}
                ratesDictionary={ratesDictionary}
                selectedRate={rate}
                setValue={setValue}
            />

            <ContractedDemandFields
                control={control}
                disabled={disabled}
                errors={errors}
                rate={rate}
            />

            <DemandFields
                control={control}
                disabled={disabled}
                errors={errors}
            />

            <DemandWithoutSolarFields
                control={control}
                disabled={disabled}
                errors={errors}
                rate={rate}
            />

            <NetMeteringFields
                compensationScheme={compensationScheme}
                control={control}
                disabled={disabled}
                errors={errors}
                rate={rate}
            />

            <EnergyExportPriceFields
                compensationScheme={compensationScheme}
                control={control}
                disabled={disabled}
                errors={errors}
            />

            <PPAFields
                control={control}
                disabled={disabled}
                errors={errors}
                getValues={getValues}
                setValue={setValue}
            />

            <ResolvedIntegrationFields control={control} disabled={disabled} />

            <FormBottomActions
                disabled={disabled && !readOnly}
                handleClickBack={handleClickBack}
                handleClickNext={handleClickNext}
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    serviceNumber: afterSalesSettingsSelectors.getAfterSalesServiceNumber,
    sipsIntegration: supplierIntegrationsSelectors.getSipsIntegration,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCupsInfo: (params) => dispatch(actions.fetchCupsInfo(params)),
});

GeneralStep.propTypes = {
    compensationScheme: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    fetchCupsInfo: PropTypes.func,
    getValues: PropTypes.func,
    handleClickBack: PropTypes.func,
    handleClickNext: PropTypes.func,
    rate: PropTypes.object,
    rateConfiguration: PropTypes.object,
    ratesDictionary: PropTypes.object,
    readOnly: PropTypes.bool,
    reset: PropTypes.func,
    serviceNumber: PropTypes.string,
    setValue: PropTypes.func,
    sipsIntegration: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralStep);
