import {
    saveDeviceBasedConsumptions,
    updateDeviceBasedConsumptions,
} from 'common/api/v1/deviceBasedConsumptions';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    SAVE_CONSUMPTION,
    SAVE_CONSUMPTION_FAILURE,
    SAVE_CONSUMPTION_SUCCESS,
} from '../actionTypes';
import { distributeConsumptionProfile } from '../helpers';
import { actions } from '../reducer';

import setIsOpenModal from './setIsOpenModal';

export default (projectId, data, onSuccess) => (dispatch) => {
    if (!projectId || !data?.devices?.length) return;

    dispatch(actions[SAVE_CONSUMPTION]());

    const {
        consumption_profile_array,
        date_format,
        dates_summary,
        devices,
        initial_consumption,
    } = data;

    const initialDate = dates_summary?.[0]?.initial_date;
    const finalDate = dates_summary?.[dates_summary.length - 1]?.final_date;

    const { consumptionProfile, demandArray, totalConsumption } =
        distributeConsumptionProfile({
            consumptionProfile: consumption_profile_array,
            dateFormat: date_format,
            devices,
            finalDate,
            initialConsumption: initial_consumption,
            initialDate,
            returnValuesInKW: true,
        });

    const newData = {
        annual_total: totalConsumption,
        consumption_profile_array: consumptionProfile,
        demand_array: demandArray,
        device_consumptions: data?.devices,
    };

    const saveFunction = data.id
        ? updateDeviceBasedConsumptions
        : saveDeviceBasedConsumptions;

    saveFunction(projectId, newData)
        .then((response) => {
            const data = response.data;

            dispatch(actions[SAVE_CONSUMPTION_SUCCESS](data));

            dispatch(setIsOpenModal(false));
            if (onSuccess) onSuccess(data);
        })
        .catch((error) => {
            dispatch(actions[SAVE_CONSUMPTION_FAILURE](error));
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
