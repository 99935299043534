import { createSlice } from '@reduxjs/toolkit';

import {
    ADD_ZAP_RPU,
    ADD_ZAP_RPU_FAILURE,
    ADD_ZAP_RPU_SUCCESS,
    DELETE_DATA,
    DELETE_DATA_FAILURE,
    DELETE_DATA_SUCCESS,
    FETCH_CURRENCIES,
    FETCH_CURRENCIES_FAILURE,
    FETCH_CURRENCIES_SUCCESS,
    FETCH_CUSTOMER_CONSUMPTION,
    FETCH_CUSTOMER_CONSUMPTION_FAILURE,
    FETCH_CUSTOMER_CONSUMPTION_SUCCESS,
    FETCH_DATA,
    FETCH_DATA_FAILURE,
    FETCH_DATA_SUCCESS,
    FETCH_LOCATIONS_BY_ZIP_CODE,
    FETCH_LOCATIONS_BY_ZIP_CODE_FAILURE,
    FETCH_LOCATIONS_BY_ZIP_CODE_SUCCESS,
    FETCH_POLITICAL_DIVISIONS,
    FETCH_POLITICAL_DIVISIONS_FAILURE,
    FETCH_POLITICAL_DIVISIONS_SUCCESS,
    FETCH_POLITICAL_DIVISIONS2,
    FETCH_POLITICAL_DIVISIONS2_FAILURE,
    FETCH_POLITICAL_DIVISIONS2_SUCCESS,
    FETCH_RATE_DIVISIONS,
    FETCH_RATE_DIVISIONS_FAILURE,
    FETCH_RATE_DIVISIONS_SUCCESS,
    FETCH_RATES,
    FETCH_RATES_FAILURE,
    FETCH_RATES_SUCCESS,
    FETCH_REGIONS,
    FETCH_REGIONS_FAILURE,
    FETCH_REGIONS_SUCCESS,
    FETCH_SCHEDULE_RATE_CONFIGURATION,
    FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE,
    FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS,
    FETCH_SUMMER_MONTHS,
    FETCH_SUMMER_MONTHS_FAILURE,
    FETCH_SUMMER_MONTHS_SUCCESS,
    FETCH_SUNPAL_CONFIG,
    FETCH_SUNPAL_CONFIG_FAILURE,
    FETCH_SUNPAL_CONFIG_SUCCESS,
    FETCH_TEMPLATES,
    FETCH_TEMPLATES_FAILURE,
    FETCH_TEMPLATES_SUCCESS,
    INITIALIZE_FORM,
    INITIALIZE_SUNPAL_CONFIG,
    RESET_FORM,
    RESET_SUNPAL_CONFIG_FORM,
    SAVE_DATA,
    SAVE_DATA_FAILURE,
    SAVE_DATA_SUCCESS,
    SAVE_SUNPAL_CONFIG,
    SAVE_SUNPAL_CONFIG_FAILURE,
    SAVE_SUNPAL_CONFIG_SUCCESS,
    SET_MODAL_FORM_OPEN_STATUS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    currencies: { data: [], errors: null, isLoading: false },
    customerConsumption: { data: {}, errors: null, isFetching: false },
    deleteData: { data: null, errors: null, isLoading: false },
    fetchData: { data: null, errors: null, isLoading: false },
    fethSunpalConfig: { data: null, errors: null, isLoading: false },
    initialValues: {
        auto_report: false,
        auto_report_day: null,
        cfe_user: null,
        commercial_offer: null,
        compensation_scheme: null,
        contracted_demand: null,
        currency: null,
        custom_offer_template: null,
        hourly_contracted_demand: [],
        id: null,
        integration_type: null,
        is_bimonthly: null,
        modules_quantity: null,
        political_division1: null,
        political_division2: null,
        project: null,
        rate: null,
        rate_division: null,
        rate_division_summer: null,
        rate_region: null,
        source: '0',
        start_date: null,
        subsidy_rate: null,
        system_size: null,
        total_cost: null,
        type_change: null,
        zap_rpu_id: null,
        zip_code: null,
    },
    isModalFormOpen: false,
    locationsByZipCode: { data: null, errors: null, isLoading: false },
    politicalDivisions1: { data: null, errors: null, isLoading: false },
    politicalDivisions2: { data: null, errors: null, isLoading: false },
    rateDivisions: { data: [], errors: null, isFetching: false },
    rates: { data: {}, errors: null, isFetching: false },
    regions: { data: [], errors: null, isFetching: false },
    saveData: { data: null, errors: null, isLoading: false },
    scheduleRateConfiguration: { data: {}, errors: null, isFetching: false },
    saveSunpalConfig: { data: null, errors: null, isLoading: false },
    summerMonths: { data: [], errors: null, isFetching: false },
    sunpalConfigIninitalValues: {
        accumulated_saving_enabled: false,
        energy_compliance_enabled: false,
        generation_accuracy_enabled: false,
        measurement_sources_enabled: true,
        reports_enabled: true,
        roi_progress_enabled: false,
    },
    templates: { data: [], errors: null, isLoading: false },
    zapRpu: { data: null, errors: null, isLoading: false },
};

const slice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [ADD_ZAP_RPU]: (state) => {
            state.zapRpu.errors = null;
            state.zapRpu.isLoading = true;
        },
        [ADD_ZAP_RPU_FAILURE]: (state, action) => {
            state.zapRpu.errors = action.payload;
            state.zapRpu.isLoading = false;
        },
        [ADD_ZAP_RPU_SUCCESS]: (state, action) => {
            state.zapRpu.data = action.payload;
            state.zapRpu.isLoading = false;
        },
        [DELETE_DATA]: (state) => {
            state.deleteData.errors = null;
            state.deleteData.isLoading = true;
        },
        [DELETE_DATA_FAILURE]: (state, action) => {
            state.deleteData.errors = action.payload;
            state.deleteData.isLoading = false;
        },
        [DELETE_DATA_SUCCESS]: (state) => {
            state.deleteData.isLoading = false;
        },
        [FETCH_CURRENCIES]: (state) => {
            state.currencies.errors = null;
            state.currencies.isLoading = true;
        },
        [FETCH_CUSTOMER_CONSUMPTION]: (state) => {
            state.customerConsumption.data = {};
            state.customerConsumption.errors = [];
            state.customerConsumption.isFetching = true;
        },
        [FETCH_CUSTOMER_CONSUMPTION_FAILURE]: (state, action) => {
            state.customerConsumption.errors = action.payload;
            state.customerConsumption.isFetching = false;
        },
        [FETCH_CUSTOMER_CONSUMPTION_SUCCESS]: (state, action) => {
            state.customerConsumption.data = action.payload;
            state.customerConsumption.isFetching = false;
        },
        [FETCH_CURRENCIES_FAILURE]: (state, action) => {
            state.currencies.errors = action.payload;
            state.currencies.isLoading = false;
        },
        [FETCH_CURRENCIES_SUCCESS]: (state, action) => {
            state.currencies.data = action.payload;
            state.currencies.isLoading = false;
        },
        [FETCH_DATA]: (state) => {
            state.fetchData.errors = null;
            state.fetchData.isLoading = true;
        },
        [FETCH_DATA_FAILURE]: (state, action) => {
            state.fetchData.errors = action.payload;
            state.fetchData.isLoading = false;
        },
        [FETCH_DATA_SUCCESS]: (state, action) => {
            state.fetchData.data = action.payload;
            state.fetchData.isLoading = false;
        },
        [FETCH_SUNPAL_CONFIG]: (state) => {
            state.fethSunpalConfig.errors = null;
            state.fethSunpalConfig.isLoading = true;
        },
        [FETCH_SUNPAL_CONFIG_FAILURE]: (state, action) => {
            state.fethSunpalConfig.errors = action.payload;
            state.fethSunpalConfig.isLoading = false;
        },
        [FETCH_SUNPAL_CONFIG_SUCCESS]: (state, action) => {
            state.fethSunpalConfig.data = action.payload;
            state.fethSunpalConfig.isLoading = false;
        },
        [FETCH_LOCATIONS_BY_ZIP_CODE]: (state) => {
            state.locationsByZipCode.errors = null;
            state.locationsByZipCode.isLoading = true;
        },
        [FETCH_LOCATIONS_BY_ZIP_CODE_FAILURE]: (state, action) => {
            state.locationsByZipCode.errors = action.payload;
            state.locationsByZipCode.isLoading = false;
        },
        [FETCH_LOCATIONS_BY_ZIP_CODE_SUCCESS]: (state, action) => {
            state.locationsByZipCode.data = action.payload;
            state.locationsByZipCode.isLoading = false;
        },
        [FETCH_POLITICAL_DIVISIONS]: (state) => {
            state.politicalDivisions1.errors = null;
            state.politicalDivisions1.isLoading = true;
        },
        [FETCH_POLITICAL_DIVISIONS_FAILURE]: (state, action) => {
            state.politicalDivisions1.errors = action.payload;
            state.politicalDivisions1.isLoading = false;
        },
        [FETCH_POLITICAL_DIVISIONS_SUCCESS]: (state, action) => {
            state.politicalDivisions1.data = action.payload;
            state.politicalDivisions1.isLoading = false;
        },
        [FETCH_POLITICAL_DIVISIONS2]: (state) => {
            state.politicalDivisions2.errors = null;
            state.politicalDivisions2.isLoading = true;
        },
        [FETCH_POLITICAL_DIVISIONS2_FAILURE]: (state, action) => {
            state.politicalDivisions2.errors = action.payload;
            state.politicalDivisions2.isLoading = false;
        },
        [FETCH_POLITICAL_DIVISIONS2_SUCCESS]: (state, action) => {
            state.politicalDivisions2.data = action.payload;
            state.politicalDivisions2.isLoading = false;
        },
        [FETCH_RATE_DIVISIONS]: (state) => {
            state.rateDivisions.data = [];
            state.rateDivisions.errors = [];
            state.rateDivisions.isFetching = true;
        },
        [FETCH_RATE_DIVISIONS_FAILURE]: (state, action) => {
            state.rateDivisions.errors = action.payload;
            state.rateDivisions.isFetching = false;
        },
        [FETCH_RATE_DIVISIONS_SUCCESS]: (state, action) => {
            state.rateDivisions.data = action.payload;
            state.rateDivisions.isFetching = false;
        },
        [FETCH_RATES]: (state) => {
            state.rates.data = {};
            state.rates.errors = null;
            state.rates.isFetching = true;
        },
        [FETCH_RATES_FAILURE]: (state, action) => {
            state.rates.errors = action.payload;
            state.rates.isFetching = false;
        },
        [FETCH_RATES_SUCCESS]: (state, action) => {
            state.rates.data = action.payload;
            state.rates.isFetching = false;
        },
        [FETCH_REGIONS]: (state) => {
            state.regions.data = [];
            state.regions.errors = [];
            state.regions.isFetching = true;
        },
        [FETCH_REGIONS_FAILURE]: (state, action) => {
            state.regions.errors = action.payload;
            state.regions.isFetching = false;
        },
        [FETCH_REGIONS_SUCCESS]: (state, action) => {
            state.regions.data = action.payload;
            state.regions.isFetching = false;
        },
        [FETCH_SCHEDULE_RATE_CONFIGURATION]: (state) => {
            state.scheduleRateConfiguration.data = {};
            state.scheduleRateConfiguration.errors = [];
            state.scheduleRateConfiguration.isFetching = true;
        },
        [FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE]: (state, action) => {
            state.scheduleRateConfiguration.errors = action.payload;
            state.scheduleRateConfiguration.isFetching = false;
        },
        [FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS]: (state, action) => {
            state.scheduleRateConfiguration.data = action.payload;
            state.scheduleRateConfiguration.isFetching = false;
        },
        [FETCH_SUMMER_MONTHS]: (state) => {
            state.summerMonths.data = [];
            state.summerMonths.errors = [];
            state.summerMonths.isFetching = true;
        },
        [FETCH_SUMMER_MONTHS_FAILURE]: (state, action) => {
            state.summerMonths.errors = action.payload;
            state.summerMonths.isFetching = false;
        },
        [FETCH_SUMMER_MONTHS_SUCCESS]: (state, action) => {
            state.summerMonths.data = action.payload;
            state.summerMonths.isFetching = false;
        },
        [FETCH_TEMPLATES]: (state) => {
            state.templates.data = [];
            state.templates.errors = [];
            state.templates.isLoading = true;
        },
        [FETCH_TEMPLATES_FAILURE]: (state, action) => {
            state.templates.errors = action.payload;
            state.templates.isLoading = false;
        },
        [FETCH_TEMPLATES_SUCCESS]: (state, action) => {
            state.templates.data = action.payload;
            state.templates.isLoading = false;
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
            state.zapRpu = INITIAL_STATE.zapRpu;
        },
        [SAVE_DATA]: (state) => {
            state.saveData.errors = null;
            state.saveData.isLoading = true;
        },
        [SAVE_DATA_FAILURE]: (state, action) => {
            state.saveData.errors = action.payload;
            state.saveData.isLoading = false;
        },
        [SAVE_DATA_SUCCESS]: (state, action) => {
            state.saveData.data = action.payload;
            state.saveData.isLoading = false;
            state.fetchData.data = action.payload;
        },
        [SAVE_SUNPAL_CONFIG]: (state) => {
            state.saveSunpalConfig.errors = null;
            state.saveSunpalConfig.isLoading = true;
        },
        [SAVE_SUNPAL_CONFIG_FAILURE]: (state, action) => {
            state.saveSunpalConfig.errors = action.payload;
            state.saveSunpalConfig.isLoading = false;
        },
        [SAVE_SUNPAL_CONFIG_SUCCESS]: (state, action) => {
            state.saveSunpalConfig.data = action.payload;
            state.saveSunpalConfig.isLoading = false;
        },
        [SET_MODAL_FORM_OPEN_STATUS]: (state, action) => {
            state.isModalFormOpen = action.payload;
        },
        [INITIALIZE_SUNPAL_CONFIG]: (state, action) => {
            state.sunpalConfigIninitalValues = action.payload;
        },
        [RESET_SUNPAL_CONFIG_FORM]: (state) => {
            state.sunpalConfigIninitalValues =
                INITIAL_STATE.sunpalConfigIninitalValues;
        },
    },
});

export const actions = slice.actions;

export default slice.reducer;
