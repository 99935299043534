import React from 'react';

import PropTypes from 'prop-types';
import { Card } from 'sunwise-ui';

import CardContent from './components/CardContent';
import ModalForm from './components/ModalForm';

const Container = ({
    canModify,
    canModifyAdvancedFeatures,
    canViewAdvancedFeatures,
    selectedProjectId,
}) => {
    return (
        <Card>
            <CardContent
                canModify={canModify}
                selectedProjectId={selectedProjectId}
            />

            <ModalForm
                canModifyAdvancedFeatures={canModifyAdvancedFeatures}
                canViewAdvancedFeatures={canViewAdvancedFeatures}
                selectedProjectId={selectedProjectId}
            />
        </Card>
    );
};

Container.propTypes = {
    canModify: PropTypes.bool,
    canModifyAdvancedFeatures: PropTypes.bool,
    canViewAdvancedFeatures: PropTypes.bool,
    selectedProjectId: PropTypes.string,
};

export default Container;
