import React, { useEffect } from 'react';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import AccordionCard from 'common/components/accordions/AccordionCard';
import { DEFAULT_CURRENCY } from 'common/constants';
import { COMMERCIAL_OFFER_STATUS } from 'common/constants/commercialOffer';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { getCurrencyIso, getCurrencySymbol } from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import * as actions from './actions';
import ComparativeCardChartJs from './components/ComparativeCardChartJs';
import Header from './components/Header';
import HistoricalCard from './components/HistoricalCard';
import PeriodSettings from './components/PeriodSettings';
import ResponsiveHeader from './components/ResponsiveHeader';
import SupportDrawer from './components/SupportDrawer';
import SupportMenuGrid from './components/SupportMenuGrid';
import WrapperContent from './components/WrapperContent';
import * as selectors from './selectors';

const Container = ({
    canModify,
    handleClickBreadcrumb,
    initialize,
    isFetchingHistoricalInfo,
    isFetchingPeriods,
    isFetchingProjectConfiguration,
    isFetchingReportDetails,
    isSavingPeriods,
    match,
    prepareEditReport,
    reportComplements,
    reportDetails,
}) => {
    const { t } = useTranslation();

    const reportId = match.params.uid;

    useEffect(() => {
        initialize(reportId, reportDetails?.project?.id);
    }, [reportId]);

    const handlePrepareEditReport = () => prepareEditReport(reportId);

    const reportStatus = get(reportComplements, 'status', null);
    const reportCurrency = get(reportComplements, 'currency', DEFAULT_CURRENCY);
    const currencyIso = getCurrencyIso(reportCurrency);
    const currencySymbol = getCurrencySymbol(reportCurrency);
    const currencyLocale = getCountryCurrencyLocale();

    const isGenerated = reportStatus === COMMERCIAL_OFFER_STATUS.FINISHED.key;
    const isVisibleEditButton = canModify && isGenerated;
    const isLoadingPeriodsConfig = isSavingPeriods || isFetchingPeriods;
    const isLoadingInfo =
        isFetchingHistoricalInfo || isFetchingProjectConfiguration;

    return (
        <>
            <Grid container spacing={0}>
                <Grid
                    size={{ lg: 14, md: 12, xs: 18 }}
                    sx={{ display: 'flex', flexDirection: 'column' }}
                >
                    <ResponsiveHeader
                        handleClickBreadcrumb={handleClickBreadcrumb}
                        handlePrepareEditReport={handlePrepareEditReport}
                        isFetchingReportDetails={isFetchingReportDetails}
                        isGenerated={isGenerated}
                        isVisibleEditButton={isVisibleEditButton}
                        reportDetails={reportDetails}
                    />
                    <WrapperContent>
                        <Header
                            handleClickBreadcrumb={handleClickBreadcrumb}
                            isFetchingReportDetails={isFetchingReportDetails}
                            isGenerated={isGenerated}
                            reportDetails={reportDetails}
                        />

                        <AccordionCard
                            defaultExpanded
                            isLoading={isLoadingPeriodsConfig}
                            title={t('Period config')}
                            svgIcon={
                                <CalendarTodayIcon
                                    color="primary"
                                    fontSize="small"
                                />
                            }
                        >
                            <PeriodSettings
                                canModify={canModify}
                                isGenerated={isGenerated}
                                reportId={reportId}
                                isFetching={
                                    isFetchingPeriods ||
                                    isFetchingHistoricalInfo
                                }
                                isSaving={isSavingPeriods}
                            />
                        </AccordionCard>

                        <ComparativeCardChartJs
                            currencyIso={currencyIso}
                            currencyLocale={currencyLocale}
                            currencySymbol={currencySymbol}
                            isLoading={isLoadingPeriodsConfig || isLoadingInfo}
                        />

                        <HistoricalCard
                            currencyIso={currencyIso}
                            currencyLocale={currencyLocale}
                            currencySymbol={currencySymbol}
                            isLoading={isLoadingInfo}
                        />
                    </WrapperContent>
                </Grid>

                <SupportMenuGrid
                    canModify={canModify}
                    handlePrepareEditReport={handlePrepareEditReport}
                    isGenerated={isGenerated}
                    isVisibleEditButton={isVisibleEditButton}
                    reportId={reportId}
                    reportComplements={reportComplements}
                />
            </Grid>

            <SupportDrawer
                isGenerated={isGenerated}
                reportComplements={reportComplements}
                reportId={reportId}
            />
        </>
    );
};
const mapStateToProps = createStructuredSelector({
    isFetchingHistoricalInfo: selectors.getIsFetchingHistoricalInfo,
    isFetchingPeriods: selectors.getPeriodConfigIsFetching,
    isFetchingProjectConfiguration: selectors.getIsFetchingProjectConfiguration,
    isFetchingReportDetails: selectors.getReportDetailsIsFetching,
    isSavingPeriods: selectors.getSaveIsSaving,
    reportComplements: selectors.getReportComplementsData,
    reportDetails: selectors.getReportDetailsData,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickBreadcrumb: (contactId, projectId) =>
        dispatch(actions.handleClickBreadcrumb(contactId, projectId)),
    initialize: (id, projectId) =>
        dispatch(actions.initialFetching(id, projectId)),
    prepareEditReport: (id) => dispatch(actions.prepareEdit(id)),
});

Container.propTypes = {
    canModify: PropTypes.bool,
    handleClickBreadcrumb: PropTypes.func,
    initialize: PropTypes.func,
    isFetchingHistoricalInfo: PropTypes.bool,
    isFetchingPeriods: PropTypes.bool,
    isFetchingProjectConfiguration: PropTypes.bool,
    isFetchingReportDetails: PropTypes.bool,
    isSavingPeriods: PropTypes.bool,
    match: PropTypes.object,
    prepareEditReport: PropTypes.func,
    reportComplements: PropTypes.object,
    reportDetails: PropTypes.object,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.AFTER_SALES_PERMISSION),
    withRouter,
)(Container);
