import React, { useRef } from 'react';

import ShareIcon from '@mui/icons-material/Share';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RWebShare } from 'react-web-share';
import { ListItemIcon, MenuItem, Typography } from 'sunwise-ui';

import { SHARED_OPTIONS } from 'common/constants';

const ShareMenuItem = ({ isHidden, offerName, permalink }) => {
    const { t } = useTranslation();
    const menuRef = useRef(null);
    const data = { text: offerName, url: permalink, title: offerName };

    const onClick = () => {
        if (menuRef?.current) menuRef?.current?.closeMenu();
    };

    if (isHidden) return null;

    return (
        <RWebShare
            closeText={t('Close')}
            data={data}
            disableNative
            onClick={onClick}
            sites={SHARED_OPTIONS}
        >
            <MenuItem>
                <ListItemIcon sx={{ minWidth: '24px !important', mr: 1 }}>
                    <ShareIcon />
                </ListItemIcon>
                <Typography variant="body2">{t('Share')}</Typography>
            </MenuItem>
        </RWebShare>
    );
};

ShareMenuItem.propTypes = {
    isHidden: PropTypes.bool,
    offerName: PropTypes.string,
    permalink: PropTypes.string,
};

export default ShareMenuItem;
