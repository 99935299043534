import React from 'react';

import { Campaign, School } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { IconButton } from 'sunwise-ui';

import * as profileSelectors from '../../../modules/profile/selectors';

import CreditsIconTooltip from './CreditsIconTooltip';
import DollarPriceItem from './DollarPriceItem';
import ProjectsIconTooltip from './ProjectsIconTooltip';

const OtherInfo = ({
    handleDrawerToggle,
    oppositeCurrency,
    subscription,
    subscriptions,
    subscriptionVersion,
}) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const sxIcon = {
        color: isDarkMode ? '#61666b' : '#1f3c53',
    };

    return (
        <>
            <DollarPriceItem
                handleDrawerToggle={handleDrawerToggle}
                oppositeCurrency={oppositeCurrency}
            />

            <IconButton
                href={`${import.meta.env.VITE_ACADEMY_URL}es/`}
                size="small"
                target="_blank"
            >
                <School sx={sxIcon} />
            </IconButton>

            <IconButton
                href={import.meta.env.VITE_NEW_URL}
                rel="noopener noreferrer"
                size="small"
                target="_blank"
            >
                <Campaign sx={sxIcon} />
            </IconButton>

            {subscriptionVersion === 'v4' && (
                <ProjectsIconTooltip
                    isDarkMode={isDarkMode}
                    subscription={subscription}
                    sxIcon={sxIcon}
                />
            )}

            <CreditsIconTooltip subscriptions={subscriptions} sxIcon={sxIcon} />
        </>
    );
};

OtherInfo.propTypes = {
    handleDrawerToggle: PropTypes.func,
    oppositeCurrency: PropTypes.object,
    subscription: PropTypes.object,
    subscriptions: PropTypes.array,
    subscriptionVersion: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    subscription: profileSelectors.getMainSubscription,
    subscriptions: profileSelectors.getSubscriptions,
    subscriptionVersion: profileSelectors.getSubscriptionVersion,
});

export default connect(mapStateToProps, null)(OtherInfo);
