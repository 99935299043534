import React from 'react';

import { Box, Tooltip } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { REPORT_STATUS_CONFIG } from '../constants';

const ReportStatus = ({ iconSize = 16, status }) => {
    const { t } = useTranslation();

    const statusConfig = REPORT_STATUS_CONFIG[status];

    if (!statusConfig) return null;

    const isTransparent = statusConfig?.color === 'transparent';

    return (
        <Tooltip placement="top" title={t(statusConfig.label)}>
            <Box display="flex">
                <statusConfig.Icon
                    sx={{
                        fontSize: iconSize,
                        border: isTransparent ? '1px solid gray' : undefined,
                        borderRadius: isTransparent ? '50%' : undefined,
                        color: statusConfig.color,
                    }}
                />
            </Box>
        </Tooltip>
    );
};

ReportStatus.propTypes = {
    iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ReportStatus;
