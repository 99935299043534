import React from "react";

import PropTypes from "prop-types";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Alert, Box, Grid, Typography } from "sunwise-ui";

import {
  ReactHookFormIntlNumberInput,
  ReactHookFormInput,
  ReactHookFormSwitch,
} from "common/components/form/bootstrap";

import { ALERT_TYPE_ITEMS } from "../constants";

const AlertItem = ({ alert_type, disabled, control, index }) => {
  const alertMessage = useWatch({
    control,
    name: `alerts.${index}.message`,
  });
  const { t } = useTranslation();

  if (!alert_type && alert_type !== 0) return null;

  const { currentType, title, unit, unitLabel } =
    ALERT_TYPE_ITEMS.find((item) => item.alertType === alert_type) || {};

  const currentTypeText = currentType ? `(${currentType})` : "";

  return (
    <Box p={1}>
      <Typography variant="subtitle2">
        {`${t(title)} ${currentTypeText}`}
      </Typography>
      <Grid container alignItems="flex-start" columns={12} my={1}>
        <Grid size={{ md: 1, xs: 3 }}>
          <ReactHookFormSwitch
            control={control}
            disabled={disabled}
            name={`alerts.${index}.status`}
          />
        </Grid>
        <Grid size={{ md: 3, xs: 9 }}>
          <ReactHookFormIntlNumberInput
            allowNegativeValue={false}
            append={unit}
            label={t(unitLabel)}
            control={control}
            disabled={disabled}
            name={`alerts.${index}.value`}
            sx={{ mb: "0!important" }}
          />
        </Grid>
        <Grid size={{ md: 8, xs: 12 }}>
          <ReactHookFormInput
            control={control}
            disabled={disabled}
            label={t("Message")}
            multiline
            name={`alerts.${index}.message`}
            maxLength="200"
            sx={{ mb: "0!important" }}
          />
        </Grid>
      </Grid>

      <Typography variant="body2" mb={1}>
        {t("Example")}:
      </Typography>
      <Alert severity="warning">{alertMessage}</Alert>
    </Box>
  );
};

AlertItem.propTypes = {
  alert_type: PropTypes.number,
  control: PropTypes.object,
  disabled: PropTypes.bool,
  index: PropTypes.number,
};

export default AlertItem;
