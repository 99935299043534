import { NAME } from './constants';

export const FETCH_CONSUMPTION = `${NAME}/FETCH_CONSUMPTION`;
export const FETCH_CONSUMPTION_FAILURE = `${NAME}/FETCH_CONSUMPTION_FAILURE`;
export const FETCH_CONSUMPTION_SUCCESS = `${NAME}/FETCH_CONSUMPTION_SUCCESS`;

export const INITIALIZE_CONSUMPTION_FORM = `${NAME}/INITIALIZE_CONSUMPTION_FORM`;

export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_CONSUMPTION = `${NAME}/SAVE_CONSUMPTION`;
export const SAVE_CONSUMPTION_FAILURE = `${NAME}/SAVE_CONSUMPTION_FAILURE`;
export const SAVE_CONSUMPTION_SUCCESS = `${NAME}/SAVE_CONSUMPTION_SUCCESS`;

export const SET_IS_OPEN_MODAL = `${NAME}/SET_IS_OPEN_MODAL`;
