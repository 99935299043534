import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from 'sunwise-ui';

const Footer = ({ onHide, step, totalSteps }) => {
    const { t } = useTranslation();
    return (
        <Grid container p={2}>
            <Grid
                size={{ md: 8, xs: 18 }}
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
            >
                <Typography variant="caption">{t('Step No')}</Typography>
                <Typography
                    variant="body2"
                    fontWeight="bold"
                    sx={{ color: '#008dff' }}
                >
                    {step + 1}
                </Typography>
                <Typography variant="caption">{t('Of')}</Typography>
                <Typography
                    variant="body2"
                    fontWeight="bold"
                    sx={{ color: '#008dff' }}
                >
                    {totalSteps}
                </Typography>
            </Grid>

            <Grid size={{ md: 10, xs: 18 }} textAlign="right">
                <Button
                    color="secondary"
                    onClick={onHide}
                    type="button"
                    variant="text"
                    sx={{ width: { xs: '100%', md: 'auto' } }}
                >
                    {t('Back')}
                </Button>
            </Grid>
        </Grid>
    );
};

Footer.propTypes = {
    onHide: PropTypes.func,
    step: PropTypes.number,
    totalSteps: PropTypes.number,
};

export default Footer;
