import React, { useEffect } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NearMeIcon from '@mui/icons-material/NearMe';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { useGeolocated } from 'react-geolocated';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid, IconButton } from 'sunwise-ui';

import { ControlledMap, ShowErrors } from 'common/components';
import {
    ReactHookFormDragnDropFile,
    ReactHookFormInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import {
    ItemGeocodeResult,
    ListGeocodeResult,
    WrapperGeocodeResult,
} from 'common/components/maps';
import {
    DEFAULT_MAP_PREVIEW_ZOOM,
    FILE_ALLOWED_EXTENSIONS,
    MAX_SMALL_FILE_SIZE,
} from 'common/constants';
import {
    getCurrenciesToSelect,
    handleOnChangeLatLng,
} from 'common/utils/helpers';
import yupResolver from 'common/utils/yupResolver';

import * as multiCurrencyActions from '../../multiCurrency/actions';
import * as multiCurrencySelectors from '../../multiCurrency/selectors';
import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

import MapWarning from './MapWarning';

const FormContainer = ({
    accountCountryPositionLatLng,
    canModify,
    currencies,
    errors,
    fetchCompanyData,
    fetchCurrencies,
    fetchGeocodePoint,
    geocodePointData,
    geocodeResult,
    handleClickSave,
    handleGeocode,
    initialValues,
    isFetchingCurrencies,
    isFetchingGeocodePoint,
    isSaving,
    setEmptyGeocodeResult,
    setShowGeocodeResults,
    showGeocodeResults,
}) => {
    const { t } = useTranslation();
    const { coords, getPosition, isGeolocationEnabled } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
        suppressLocationOnMount: true,
    });

    const { control, handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });
    const formValues = watch();

    const position = get(formValues, 'position', {});

    useEffect(() => {
        fetchCompanyData();
        fetchCurrencies();
    }, []);

    useEffect(() => {
        if (coords) updateLocation(coords);
    }, [coords]);

    useEffect(() => {
        if (!isNil(geocodePointData)) setValue('description', geocodePointData);
    }, [geocodePointData]);

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        if (position) {
            setValue('latitude', position.latitude);
            setValue('longitude', position.longitude);
        }
    }, [position]);

    useEffect(() => {
        if (
            formValues.latitude !== initialValues.latitude ||
            formValues.longitude !== initialValues.longitude
        ) {
            fetchGeocodePoint(formValues.latitude, formValues.longitude);
            updateLocation({
                latitude: formValues.latitude,
                latlong: `${formValues.latitude},${formValues.longitude}`,
                longitude: formValues.longitude,
            });
        }
    }, [formValues.latitude, formValues.longitude]);

    const disabledButton = isEqual(initialValues, formValues);

    const isDisabled = !canModify ? true : false;

    const isVisibleMapWarning = () => {
        if (!isNil(formValues.position) && isNil(formValues.latitude))
            return true;

        if (!isNil(formValues.position) && isNil(formValues.longitude))
            return true;

        return false;
    };

    const updateLocation = (position) => {
        if (position) {
            const { latitude, longitude } = position;
            const latlng =
                latitude && longitude ? `${latitude}, ${longitude}` : null;
            setValue('latitude', latitude);
            setValue('latlng', latlng);
            setValue('longitude', longitude);
            setValue('position', { latitude, latlng, longitude });
        }
    };

    return (
        <Box component="form">
            <Grid container>
                <Grid
                    id="company-general-information-form"
                    size={{ lg: 9, md: 18, xs: 18 }}
                    sx={{ pt: { md: 2, xs: 0 } }}
                >
                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label={t('Tradename')}
                        name="name"
                        placeholder={t('Tradename')}
                        id="business_general_form_text_tradename"
                    />

                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label={t('Business activity')}
                        name="business_activity"
                        placeholder={t('Business activity')}
                        id="business_general_form_text_activity"
                    />

                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label={t('Web page')}
                        name="web_page"
                        placeholder={t('Web page')}
                        id="business_general_form_text_website"
                    />

                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label={t('Email contact company')}
                        name="contact_email"
                        placeholder={t('Email contact company')}
                        id="business_general_form_text_email"
                    />
                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label={t('Business name')}
                        name="business_name"
                        placeholder={t('Business name')}
                        id="business_general_form_text_business_name"
                    />

                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label={t('Tax ID')}
                        name="rfc"
                        placeholder={t('Tax ID')}
                        id="business_general_form_string_tax_id"
                    />

                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label={t('Phone number company')}
                        name="contact_telephone"
                        placeholder={t('Phone number company')}
                        id="business_general_form_number_phone"
                    />

                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label={t('WhatsApp company')}
                        name="contact_cellphone"
                        placeholder={t('WhatsApp company')}
                        id="business_general_form_number_whatsapp"
                    />
                    <ReactHookFormSelect
                        control={control}
                        disabled={isDisabled}
                        label={t('Currency')}
                        name="currency"
                        options={getCurrenciesToSelect(
                            currencies,
                            isFetchingCurrencies,
                        )}
                        id="business_general_form_currency"
                    />
                </Grid>

                <Grid size={{ lg: 9, md: 18, xs: 18 }}>
                    <Grid container justifyContent="center">
                        <Grid size={{ md: 8, xs: 18 }}>
                            <ReactHookFormDragnDropFile
                                accept={FILE_ALLOWED_EXTENSIONS.IMAGE}
                                avatarData={{
                                    image: formValues?.image,
                                    name: formValues?.name,
                                }}
                                control={control}
                                formats="jpg, jpeg, png"
                                id="company_image"
                                maxSize={MAX_SMALL_FILE_SIZE}
                                name="image"
                                setValue={setValue}
                                variant="profile"
                                visible={canModify}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid size={{ xs: 'grow' }}>
                            {geocodeResult &&
                                geocodeResult.length > 0 &&
                                showGeocodeResults && (
                                    <WrapperGeocodeResult>
                                        <ListGeocodeResult
                                            style={{
                                                transform:
                                                    'translate3d(0px, 38px, 0px)',
                                            }}
                                        >
                                            {geocodeResult.map((result, i) => (
                                                <ItemGeocodeResult
                                                    key={i}
                                                    onClick={() => {
                                                        setValue(
                                                            'description',
                                                            result.formatted_address,
                                                        );

                                                        const { lat, lng } =
                                                            result.geometry
                                                                .location;
                                                        updateLocation({
                                                            latitude: lat,
                                                            longitude: lng,
                                                        });
                                                    }}
                                                >
                                                    {result.formatted_address}
                                                </ItemGeocodeResult>
                                            ))}
                                        </ListGeocodeResult>
                                    </WrapperGeocodeResult>
                                )}
                            <ReactHookFormInput
                                control={control}
                                disabled={isDisabled || isFetchingGeocodePoint}
                                name="description"
                                onChange={({ target }) => {
                                    if (target.value.length >= 6)
                                        handleGeocode(target.value);
                                    else setEmptyGeocodeResult();
                                    setValue('description', target.value);
                                }}
                                onBlur={() => {
                                    setTimeout(() => {
                                        setShowGeocodeResults(false);
                                    }, 150);
                                }}
                                onFocus={() => setShowGeocodeResults(true)}
                                placeholder={t('Location')}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid size={{ xs: 'grow' }}>
                            <ReactHookFormInput
                                control={control}
                                fullWidth
                                id="business_general_lat_lng"
                                onChange={(e) => {
                                    handleOnChangeLatLng({
                                        setValue,
                                        value: e.target.value,
                                    });
                                }}
                                label={`${t('Latitude')}, ${t('Longitude')} (Ej: 19.43290, -99.1332)`}
                                name="latlng"
                            />
                        </Grid>
                        <Grid>
                            <IconButton
                                disabled={
                                    isDisabled ||
                                    !isGeolocationEnabled ||
                                    isSaving
                                }
                                onClick={() => {
                                    if (!isNil(coords))
                                        updateLocation({
                                            latitude: coords.latitude,
                                            longitude: coords.longitude,
                                        });
                                    else getPosition();
                                }}
                            >
                                <NearMeIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid size={{ xs: 'grow' }}>
                            <Controller
                                control={control}
                                name="position"
                                render={({ field: { onChange, value } }) => (
                                    <ControlledMap
                                        center={accountCountryPositionLatLng}
                                        disableDefaultUI={isDisabled}
                                        heightMap="206px"
                                        onChange={onChange}
                                        readOnly={isDisabled}
                                        title={t('Location')}
                                        value={value}
                                        zoom={DEFAULT_MAP_PREVIEW_ZOOM}
                                        id="business_general_address"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <MapWarning visible={isVisibleMapWarning()} />
                </Grid>
            </Grid>

            <Grid container sx={{ mt: { xs: 2, lg: 0 } }}>
                <Grid size={18} sx={{ textAlign: 'right' }}>
                    <Button
                        disabled={disabledButton}
                        id="business_general_form_save_button"
                        onClick={handleSubmit(handleClickSave)}
                        endIcon={<ArrowForwardIcon />}
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                        variant="outlined"
                        visible={canModify}
                    >
                        {isSaving ? t('Saving').concat('...') : t('Save')}
                    </Button>
                </Grid>
            </Grid>
            <ShowErrors errors={errors} />
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    accountCountryPositionLatLng: selectors.getAccountCountryPositionLatLng,
    errors: selectors.getErrorsSave,
    currencies: multiCurrencySelectors.getDataFetchCurrencies,
    geocodeResult: selectors.getFetchGeocodeData,
    geocodePointData: selectors.getGeocodePointData,
    initialValues: selectors.getInitialValues,
    isFetchingCurrencies: multiCurrencySelectors.getIsFetchingCurrencies,
    isFetchingGeocodePoint: selectors.getIsFetchingGeocodePoint,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSaving,
    showGeocodeResults: selectors.showGeocodeResults,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCompanyData: () => dispatch(actions.fetchCompanyData()),
    fetchCurrencies: () => dispatch(multiCurrencyActions.filterItems()),
    fetchGeocodePoint: (lat, lng) =>
        dispatch(actions.fetchGeocodePoint(lat, lng)),
    handleClickSave: (values) => dispatch(actions.save(values)),
    handleGeocode: (str) => dispatch(actions.fetchGeocodeResult(str)),
    setEmptyGeocodeResult: () => dispatch(actions.setEmptyGeocodeResult()),
    setShowGeocodeResults: (value) =>
        dispatch(actions.setShowGeocodeResults(value)),
});

FormContainer.propTypes = {
    accountCountryPositionLatLng: PropTypes.object,
    canModify: PropTypes.bool,
    currencies: PropTypes.array,
    errors: PropTypes.array,
    fetchCompanyData: PropTypes.func,
    fetchCurrencies: PropTypes.func,
    fetchGeocodePoint: PropTypes.func,
    geocodePointData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    geocodeResult: PropTypes.array,
    handleClickSave: PropTypes.func,
    handleGeocode: PropTypes.func,
    initialValues: PropTypes.object,
    isFetchingCurrencies: PropTypes.bool,
    isFetchingGeocodePoint: PropTypes.bool,
    isSaving: PropTypes.bool,
    setEmptyGeocodeResult: PropTypes.func,
    setShowGeocodeResults: PropTypes.func,
    showGeocodeResults: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
