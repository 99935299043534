import fetchProjectMeasuredEnergyByDate from '../../afterSalesMonitoring/actions/fetchProjectMeasuredEnergyByDate';
import {
    FETCH_MEASURED_ENERGY,
    FETCH_MEASURED_ENERGY_FAILURE,
    FETCH_MEASURED_ENERGY_SUCCESS,
} from '../actionTypes';
import { getDateDayAfter, getHasTimestampGaps } from '../helpers';
import { actions } from '../reducer';

export default ({ finalDate, initialDate, projectId, setValue }) =>
    (dispatch) => {
        if (!projectId || !initialDate || !finalDate) return;

        dispatch(actions[FETCH_MEASURED_ENERGY]());

        const _initialDate = getDateDayAfter(initialDate);

        dispatch(
            fetchProjectMeasuredEnergyByDate(projectId, {
                finalDate,
                force: true,
                freq: '5T',
                initialDate: _initialDate,
            }),
        )
            .then((data) => {
                dispatch(actions[FETCH_MEASURED_ENERGY_SUCCESS](data));

                const hasTimestampGaps = getHasTimestampGaps({
                    finalDate: getDateDayAfter(finalDate),
                    initialDate: _initialDate,
                    timestamp: data.timestamp,
                });

                if (setValue) setValue('hasTimestampGaps', hasTimestampGaps);
            })
            .catch((error) =>
                dispatch(actions[FETCH_MEASURED_ENERGY_FAILURE](error)),
            );
    };
