import { projectsProjectsUploadDocuments } from '@sunwise-clients/wise';
import i18next from 'i18next';

import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import * as companyGeneralSelectors from '../../../../modules/companyGeneral/selectors';
import {
    UPLOAD_FILES,
    UPLOAD_FILES_FAILURE,
    UPLOAD_FILES_SUCCESS,
} from '../actionTypes';
import { lisaFilesActions } from '../reducer';

import connectOCR from './connectOCR';
import createLisaProject from './createLisaProject';
import disconnectOCR from './disconnectOCR';
import setShowLisaLoading from './setShowLisaLoading';

const onFailure = (dispatch, error) => {
    dispatch(lisaFilesActions[UPLOAD_FILES_FAILURE](error));
    dispatch(setShowLisaLoading(false));
    dispatch(disconnectOCR());
};

export default ({ files, onUpdatedProject, overwrite = true, project }) =>
    (dispatch, getState) => {
        if (!files?.length || !project) return;

        let lisaProject = project?.lisa_project;

        dispatch(lisaFilesActions[UPLOAD_FILES]());

        const state = getState();
        const country = companyGeneralSelectors.getCompanyCountryName(state);

        let promises = [];

        if (!lisaProject)
            promises.push(createLisaProject(project, onUpdatedProject));

        Promise.all(promises)
            .then((responses) => {
                if (responses.length) lisaProject = responses[0];

                dispatch(connectOCR(lisaProject));
                dispatch(setShowLisaLoading(true));

                projectsProjectsUploadDocuments({
                    body: { country, files, overwrite },
                    path: { project_id: lisaProject },
                })
                    .then(() =>
                        dispatch(lisaFilesActions[UPLOAD_FILES_SUCCESS]()),
                    )
                    .catch((error) => {
                        onFailure(dispatch, error?.response?.data?.detail);

                        const dataAlert = {
                            messages: [
                                i18next.t(
                                    "I'm having trouble reading the format of this file, but don't worry! If you could kindly convert it to one of the compatible formats listed below, I'll be able to assist you more effectively. Thank you!",
                                ),
                                'PDF, PNG',
                            ],
                            confirmText: i18next.t('Accept'),
                            title: i18next.t('Error'),
                            type: alerts.ALERT_TYPE_ONLY_CONFIRM,
                            variant: 'warning',
                        };

                        dispatch(alerts.actions.show(dataAlert));
                    });
            })
            .catch((error) => {
                onFailure(dispatch, error?.response?.data?.errors);
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
