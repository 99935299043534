import { projectsProjectsGetProject } from '@sunwise-clients/wise';

import * as lisaFilesActions from 'common/modules/lisaFiles/actions';
import * as lisaFilesSelectors from 'common/modules/lisaFiles/selectors';

import {
    FETCH_LISA_FILES,
    FETCH_LISA_FILES_FAILURE,
    FETCH_LISA_FILES_SUCCESS,
} from '../actionTypes';
import { projectConsumptionModalActions } from '../reducer';
import * as selectors from '../selectors';

import handleConsumptionIntegration from './handleConsumptionIntegration';

export default ({ getValues, setValue }) =>
    (dispatch, getState) => {
        dispatch(projectConsumptionModalActions[FETCH_LISA_FILES]());
        dispatch(lisaFilesActions.disconnectOCR());
        const state = getState();
        const project = selectors.getSelectedProject(state);

        projectsProjectsGetProject({
            path: { project_id: project.lisa_project },
        })
            .then(({ data }) => {
                const rawData = data?.documents;

                dispatch(
                    projectConsumptionModalActions[FETCH_LISA_FILES_SUCCESS](
                        data || {},
                    ),
                );

                const fileOrigin = selectors.getUploadFileOrigin(state);

                let fileInfo = null;

                if (fileOrigin || fileOrigin === 0) {
                    const filesUploaded =
                        lisaFilesSelectors.getFilesSocketOcr(state);

                    const fileToGet = Object.values(filesUploaded)?.[0];
                    fileInfo = rawData?.find(
                        (file) => file.id === fileToGet?.document_id,
                    );
                }

                dispatch(
                    handleConsumptionIntegration({
                        fileInfo,
                        fileOrigin,
                        getValues,
                        rawData,
                        setValue,
                    }),
                );
            })
            .catch((error) => {
                dispatch(
                    projectConsumptionModalActions[FETCH_LISA_FILES_FAILURE](
                        error?.response?.data?.detail,
                    ),
                );
                dispatch(lisaFilesActions.setShowLisaLoading(false));
            });
    };
