import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';

import ShareCard from '../../summaryTemplateReview/components/ShareCard';

import LinkedProposalsCard from './LinkedProposalsCard';
import ProposalCard from './ProposalCard';

const SupportMenuContent = ({
    downloadFormat,
    editionDisabled,
    handleClickDocumentItem,
    handleClickDownload,
    isDownloading,
    isFetching,
    isUpdatingCustomTemplate,
    linkedProposals,
    offerInfoPatern,
    proposalId,
    proposalName,
    selectedDocument,
    setDownloadFormat,
}) => {
    const isDisabled =
        !editionDisabled ||
        isDownloading ||
        isFetching ||
        isUpdatingCustomTemplate;

    return (
        <>
            <ShareCard
                downloadFormat={downloadFormat}
                handleClickDownload={handleClickDownload}
                isDisabled={false}
                isEnablePermalink={true}
                permalink={get(offerInfoPatern, 'permalink', null)}
                setDownloadFormat={setDownloadFormat}
            />

            <ProposalCard
                handleClickDocumentItem={handleClickDocumentItem}
                isDisabled={isDisabled}
                proposalId={proposalId}
                proposalName={proposalName}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />

            <LinkedProposalsCard linkedProposals={linkedProposals} />
        </>
    );
};

SupportMenuContent.propTypes = {
    downloadFormat: PropTypes.number,
    editionDisabled: PropTypes.bool,
    handleClickDocumentItem: PropTypes.func,
    handleClickDownload: PropTypes.func,
    isDownloading: PropTypes.bool,
    isFetching: PropTypes.bool,
    isUpdatingCustomTemplate: PropTypes.bool,
    linkedProposals: PropTypes.array,
    offerInfoPatern: PropTypes.object,
    proposalId: PropTypes.string,
    proposalName: PropTypes.string,
    selectedDocument: PropTypes.object,
    setDownloadFormat: PropTypes.func,
};

export default SupportMenuContent;
