import { getSubscription } from 'common/api/v1/companies';

import {
    FETCH_SUBSCRIPTION,
    FETCH_SUBSCRIPTION_FAILURE,
    FETCH_SUBSCRIPTION_SUCCESS,
} from '../actionTypes';
import { profileSubscriptionsActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(profileSubscriptionsActions[FETCH_SUBSCRIPTION]());

    getSubscription()
        .then((response) =>
            dispatch(
                profileSubscriptionsActions[FETCH_SUBSCRIPTION_SUCCESS](
                    response.data,
                ),
            ),
        )
        .catch((error) =>
            dispatch(
                profileSubscriptionsActions[FETCH_SUBSCRIPTION_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
