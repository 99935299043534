import React, { useEffect, useState } from 'react';

import BarChartIcon from '@mui/icons-material/BarChart';
import ListIcon from '@mui/icons-material/List';
import RefreshIcon from '@mui/icons-material/Refresh';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid, IconButton, ToggleButton, ToggleButtonGroup } from 'sunwise-ui';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import { CHART_COLORS, VIEW_OPTIONS } from '../constants';
import {
    getConsumptionHistoryPeriods,
    getRoiProgressSeries,
    getGenerationRatioChartConfig,
} from '../helpers';
import * as selectors from '../selectors';
import { generationAccuracyValidation } from '../validations';

import ChartJs from './ChartJs';

const chartTypes = [
    { icon: <ShowChartIcon />, value: 'line' },
    { icon: <BarChartIcon />, value: 'bar' },
    { icon: <ListIcon />, value: 'table' },
];

const RoiProgressChart = ({
    afterSalesStartDate,
    consumptionHistory,
    countryCurrencyLocale,
    dateRangeOptions,
    initialValues,
    initializeAccumulatedSavings,
    isFetchingConsumptionHistory,
    loadedData,
    setLoadedData,
}) => {
    const [chartConfig, setChartConfig] = useState({
        categories: [],
        options: {},
        series: [],
    });
    const [chartType, setChartType] = useState('bar');
    const [formConfig, setFormConfig] = useState({});
    const {
        control,
        formState: { isDirty },
        getValues,
        handleSubmit,
        reset,
        watch,
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(generationAccuracyValidation),
    });
    const { t } = useTranslation();
    const viewOption = watch('view_option');

    const resetForm = (values) => {
        setLoadedData(true);
        if (!values) return;
        setFormConfig({ ...values });
        reset(values);
    };

    const handleChartChange = (config) => {
        const periods = getConsumptionHistoryPeriods({
            consumptionHistory,
            dateRange: config.dateRange,
            dateRangeOptions,
            startDate: afterSalesStartDate,
            viewOption: config.viewOption,
        });

        const { categories, series } = getRoiProgressSeries(periods);

        const newConfig = getGenerationRatioChartConfig({
            categories,
            colors: CHART_COLORS.roi_progress,
            config,
            countryCurrencyLocale,
            periods,
            series,
        });
        setChartConfig(newConfig);
    };

    useEffect(() => {
        const values = getValues();

        let dateRange = values?.date_range || dateRangeOptions?.[0]?.value;
        const newValues = { ...values, date_range: dateRange };

        resetForm(newValues);
        handleChartChange({
            chartType,
            dateRange,
            viewOption: newValues.view_option,
        });
    }, [dateRangeOptions, initialValues]);

    const handleOnChangeChartType = (value) => {
        setChartType(value);
        handleChartChange({
            chartType: value,
            dateRange: formConfig?.date_range,
            viewOption: formConfig?.view_option,
        });
    };

    const disabled = isFetchingConsumptionHistory || !loadedData;
    const isPeriod = viewOption === VIEW_OPTIONS.MONTHLY;

    return (
        <Grid container alignItems="flex-end" px={2}>
            {consumptionHistory?.length > 0 && (
                <>
                    <Grid
                        alignItems="center"
                        display="flex"
                        gap={1}
                        size={{
                            md: 4,
                            sm: isPeriod ? 18 : 9,
                            xs: isPeriod ? 18 : 14,
                        }}
                    >
                        <ReactHookFormSelect
                            control={control}
                            disabled={disabled}
                            label={t('Time range')}
                            name="view_option"
                            options={[
                                {
                                    label: t('Annual'),
                                    value: VIEW_OPTIONS.ANNUAL,
                                },
                                {
                                    label: t('Period'),
                                    value: VIEW_OPTIONS.MONTHLY,
                                },
                            ]}
                            variant="standard"
                        />
                    </Grid>
                    {viewOption === VIEW_OPTIONS.MONTHLY && (
                        <Grid size={{ md: 4, sm: 9, xs: 14 }}>
                            <ReactHookFormSelect
                                control={control}
                                disabled={disabled}
                                label={t('Period')}
                                name="date_range"
                                options={dateRangeOptions}
                                variant="standard"
                            />
                        </Grid>
                    )}
                    <Grid size={{ sm: 2, xs: 4 }}>
                        <IconButton
                            disabled={disabled || !isDirty}
                            sx={{
                                backgroundColor: 'primary.main',
                                color: 'text.primary',
                                '&:hover': { backgroundColor: 'primary.dark' },
                            }}
                            onClick={handleSubmit(initializeAccumulatedSavings)}
                        >
                            <RefreshIcon />
                        </IconButton>
                    </Grid>
                    <Grid size={{ xs: 18, sm: 'grow' }} textAlign="right">
                        <ToggleButtonGroup
                            disabled={disabled}
                            exclusive
                            onChange={(_, value) =>
                                value !== null && handleOnChangeChartType(value)
                            }
                            size="small"
                            value={chartType}
                        >
                            {chartTypes.map((type) => (
                                <ToggleButton
                                    key={type.value}
                                    value={type.value}
                                >
                                    {type.icon}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </Grid>
                </>
            )}
            <Grid size={18}>
                <ChartJs
                    chartConfig={chartConfig}
                    chartType={chartType}
                    emptyDescription={t(
                        'Make sure you have at least one historical period',
                    )}
                    formConfig={formConfig}
                    isLoading={disabled}
                    showIndicators={false}
                    tableDateLabelDefault
                />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    consumptionHistory: selectors.getConsumptionHistoryData,
    initialValues: selectors.getInitialValuesRoiProgress,
    isFetchingConsumptionHistory: selectors.getIsFetchingConsumptionHistory,
});

const mapDispatchToProps = (dispatch) => ({
    initializeAccumulatedSavings: (values) =>
        dispatch(actions.initializeRoiProgress(values)),
});

RoiProgressChart.propTypes = {
    afterSalesStartDate: PropTypes.string,
    consumptionHistory: PropTypes.array,
    countryCurrencyLocale: PropTypes.string,
    dateRangeOptions: PropTypes.array,
    initialValues: PropTypes.object,
    initializeAccumulatedSavings: PropTypes.func,
    isFetchingConsumptionHistory: PropTypes.bool,
    loadedData: PropTypes.bool,
    setLoadedData: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoiProgressChart);
