import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';

import {
    getFinancierProductsToSelect,
    handleOnChangeFinancierProduct,
    handleOnChangeRegimen,
} from '../../helpers';

const FormStep1 = ({
    allianceProducts,
    canModifyContacts,
    contactCompanyName,
    contactEmail,
    control,
    financierProducts,
    isLocked,
    isSerfimex,
    productSelected,
    resetErrors,
    setProductSelected,
    setValue,
    show,
    typesBusinessOptions,
    typesBusinessCompany,
}) => {
    const { t } = useTranslation();

    if (!show) return false;

    return (
        <Grid container>
            <Grid size={{ xs: 'grow' }}>
                <ReactHookFormSelect
                    control={control}
                    disabled={isLocked}
                    label={t('Type of tax system')}
                    name="types_business_company"
                    onChange={({ target: { value } }) =>
                        handleOnChangeRegimen({
                            allianceProducts,
                            resetErrors,
                            setProductSelected,
                            setValue,
                            value,
                        })
                    }
                    options={typesBusinessOptions}
                />
                <ReactHookFormSelect
                    control={control}
                    disabled={
                        isLocked ||
                        isEmpty(typesBusinessOptions) ||
                        financierProducts.length <= 0
                    }
                    label={t('Product')}
                    name="financier_product"
                    onChange={({ target: { value } }) =>
                        handleOnChangeFinancierProduct({
                            financierProducts,
                            resetErrors,
                            setProductSelected,
                            setValue,
                            value,
                        })
                    }
                    options={[
                        {
                            label: t('Select a product'),
                            value: '',
                            disabled: true,
                        },
                        ...getFinancierProductsToSelect(financierProducts),
                    ]}
                    sxFormControl={{ mt: 1 }}
                />
                {isSerfimex &&
                    !isEmpty(productSelected) &&
                    isEmpty(contactEmail) && (
                        <ReactHookFormInput
                            control={control}
                            disabled={!canModifyContacts}
                            label={t('Email').concat(' *')}
                            name="email"
                        />
                    )}
                {isSerfimex &&
                    typesBusinessCompany === 'company' &&
                    !isEmpty(productSelected) &&
                    isEmpty(contactCompanyName) && (
                        <ReactHookFormInput
                            control={control}
                            disabled={!canModifyContacts}
                            label={t('Business').concat(' *')}
                            name="company_name"
                        />
                    )}
            </Grid>
        </Grid>
    );
};

FormStep1.propTypes = {
    allianceProducts: PropTypes.array,
    canModifyContacts: PropTypes.bool,
    contactCompanyName: PropTypes.string,
    contactEmail: PropTypes.string,
    control: PropTypes.object,
    financierProducts: PropTypes.array,
    isLocked: PropTypes.bool,
    isSerfimex: PropTypes.bool,
    productSelected: PropTypes.object,
    resetErrors: PropTypes.func,
    setProductSelected: PropTypes.func,
    setValue: PropTypes.func,
    show: PropTypes.bool,
    typesBusinessOptions: PropTypes.array,
    typesBusinessCompany: PropTypes.string,
};

export default FormStep1;
