import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Container as MaterialContainer, Grid, Divider } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import RestrictedAccessLanding from 'common/modules/restrictedAccessLanding/Container';

import * as actions from './actions';
import ConsumptionProfileDetail from './components/ConsumptionProfileDetail';
import ConsumptionProfileForm from './components/ConsumptionProfileForm';
import ConsumptionProfileList from './components/ConsumptionProfileList';
import ConsumptionProfilesFilter from './components/ConsumptionProfilesFilter';
import * as selectors from './selectors';

const Container = ({
    canDelete,
    canModify,
    certifiedProfilesNumber,
    deleteConsumptionProfile,
    fetchConsumptionProfiles,
    filterConsumptionProfiles,
    isFetchingConsumptionProfile,
    isFetchingConsumptionProfiles,
    prepareArchiveConsumptionProfile,
    selectedItemId,
    selectedItemModel,
    setFilterQuery,
}) => {
    const { t } = useTranslation();
    const [filterName, setFilterName] = useState('');
    const [isArchived, setIsArchived] = useState(false);
    const [profileTypeSelected, selectProfileType] = useState('all');

    useEffect(
        () =>
            fetchConsumptionProfiles({
                name: filterName,
                isArchived,
                type: profileTypeSelected,
            }),
        [],
    );

    useEffect(() => {
        setFilterQuery({
            name: filterName,
            isArchived,
            type: profileTypeSelected,
        });
    }, [filterName, isArchived, profileTypeSelected]);

    const handleChangeFilter = (setAction, key, value) => {
        setAction(value);
        filterConsumptionProfiles({
            isArchived,
            name: filterName,
            type: profileTypeSelected,
            [key]: value,
        });
    };

    return (
        <RestrictedAccessLanding
            addonName="Consumption-Profiles"
            url="https://somos.sunwise.io/sunwise-sales"
        >
            <MaterialContainer maxWidth={false}>
                <HeaderContainer>
                    <Grid size={{ xs: 'grow' }}>
                        <TitleIcon
                            title={t('Consumption profile', { count: 2 })}
                        />
                    </Grid>
                </HeaderContainer>
                <ConsumptionProfilesFilter
                    certifiedProfilesNumber={certifiedProfilesNumber}
                    filterConsumptionProfiles={filterConsumptionProfiles}
                    isArchived={isArchived}
                    isFetchingConsumptionProfiles={
                        isFetchingConsumptionProfiles
                    }
                    profileTypeSelected={profileTypeSelected}
                    selectProfileType={(value) =>
                        handleChangeFilter(selectProfileType, 'type', value)
                    }
                    setFilterName={(value) =>
                        handleChangeFilter(setFilterName, 'name', value)
                    }
                    setIsArchived={(value) =>
                        handleChangeFilter(setIsArchived, 'isArchived', value)
                    }
                />

                <Divider sx={{ my: 2 }} />

                <Grid container>
                    <Grid size={{ md: 9, xs: 18 }}>
                        <ConsumptionProfileList
                            canModify={canModify}
                            canDelete={canDelete}
                        />
                    </Grid>
                    <Grid size={{ md: 9, xs: 18 }}>
                        <ConsumptionProfileDetail
                            canModify={canModify}
                            canDeleteCustomConsumptionProfile={canDelete}
                            deleteConsumptionProfile={deleteConsumptionProfile}
                            isFetchingConsumptionProfile={
                                isFetchingConsumptionProfile
                            }
                            prepareArchiveConsumptionProfile={
                                prepareArchiveConsumptionProfile
                            }
                            selectedItemId={selectedItemId}
                            selectedItemModel={selectedItemModel}
                        />
                    </Grid>
                </Grid>
                <ConsumptionProfileForm />
            </MaterialContainer>
        </RestrictedAccessLanding>
    );
};

const mapStateToProps = createStructuredSelector({
    certifiedProfilesNumber: selectors.getCertifiedConsumptionProfilesNumber,
    isFetchingConsumptionProfile: selectors.getIsFetchingConsumptionProfile,
    isFetchingConsumptionProfiles: selectors.getIsFetchingConsumptionProfiles,
    selectedItemId: selectors.getSelectedItemId,
    selectedItemModel: selectors.getSelectedItemModel,
});

const mapDispatchToProps = (dispatch) => ({
    deleteConsumptionProfile: (id, name) =>
        dispatch(actions.prepareDeleteConsumptionProfile(id, name)),
    fetchConsumptionProfiles: (params) =>
        dispatch(actions.fetchConsumptionProfiles(params)),
    filterConsumptionProfiles: (params) =>
        dispatch(actions.filterConsumptionProfiles(params)),
    prepareArchiveConsumptionProfile: (id, isArchived) =>
        dispatch(actions.prepareArchiveConsumptionProfile(id, isArchived)),
    setFilterQuery: (query) => dispatch(actions.setFilterQuery(query)),
});

Container.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    certifiedProfilesNumber: PropTypes.number,
    deleteConsumptionProfile: PropTypes.func,
    fetchConsumptionProfiles: PropTypes.func,
    filterConsumptionProfiles: PropTypes.func,
    isFetchingConsumptionProfile: PropTypes.bool,
    isFetchingConsumptionProfiles: PropTypes.bool,
    prepareArchiveConsumptionProfile: PropTypes.func,
    selectedItemId: PropTypes.string,
    selectedItemModel: PropTypes.object,
    setFilterQuery: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.SELFCONSUMPTION_ADMIN_PROFILES_PERMISSION),
)(Container);
