import React from 'react';

import ArticleIcon from '@mui/icons-material/Article';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TableChartIcon from '@mui/icons-material/TableChart';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import { CheckAccess } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import { useBreakpoint } from 'common/hooks';
import {
    getSubscriptionStatus,
    getSubscriptionsStatus,
} from 'common/utils/helpers';
import { getIsColombianAccount } from 'common/utils/helpers/session';

import IcoAlliance from 'resources/iconAlliance.svg?react';

import * as profileSelectors from '../../../modules/profile/selectors';

import { handleOnMouseHover } from './helpers';
import StyledListItemButton from './StyledListItemButton';
import StyledStyledListItemText from './StyledStyledListItemText';
import UpgradeIcon from './UpgradeIcon';

const SalesOptions = ({
    handleClose,
    handleDrawerToggle,
    match,
    mobileOpen,
    redirect,
    subscription,
    subscriptions,
    subscriptionVersion,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const isColombianAccount = getIsColombianAccount();
    const { allHasPaymentDue, isMultipleSubscription } =
        getSubscriptionsStatus(subscriptions);
    const { isActive, isPaymentDue } = getSubscriptionStatus(subscription);

    const isDisabledV4 =
        subscriptionVersion === 'v4' && isActive && isPaymentDue;
    const isDisabledV5 =
        (subscriptionVersion === 'v5' &&
            !isMultipleSubscription &&
            isActive &&
            isPaymentDue) ||
        (subscriptionVersion === 'v5' &&
            isMultipleSubscription &&
            allHasPaymentDue);

    const onMouseLeave = (e) =>
        handleOnMouseHover({ e, breakpoint, handleClose });

    return (
        <Box onMouseLeave={onMouseLeave}>
            <CheckAccess
                requiredPermissions={[
                    PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION,
                    PERMISSION_LIST.DASHBOARD_PERMISSION,
                    PERMISSION_LIST.TRACKING_CONTACTS_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    className="__userguiding_dashboard_module_navbar_item"
                    disabled={isDisabledV4 || isDisabledV5}
                    id="navbar-item-dashboard"
                    onClick={() => {
                        redirect('/dashboard');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={match.path === '/' || match.path === '/dashboard'}
                    variant="horizontal"
                >
                    <DashboardIcon />
                    <StyledStyledListItemText primary="Dashboard" />
                    <UpgradeIcon moduleName="Dashboard" />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[
                    PERMISSION_LIST.FUNNEL_PROJECTS_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/project-funnel');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/project-funnel' === match.path}
                    variant="horizontal"
                >
                    <TableChartIcon />
                    <StyledStyledListItemText primary={t('Project funnel')} />
                    <UpgradeIcon moduleName="Project-Funnel" />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                isHidden={isColombianAccount}
                requiredPermissions={[
                    PERMISSION_LIST.FUNNEL_FINANCING_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/financing-funnel');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/financing-funnel' === match.path}
                    variant="horizontal"
                >
                    <IcoAlliance />
                    <StyledStyledListItemText primary={t('Financing funnel')} />
                    <UpgradeIcon moduleName="Financing-Funnel" />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[
                    PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/proposals');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/proposals' === match.path}
                    variant="horizontal"
                >
                    <ArticleIcon />
                    <StyledStyledListItemText
                        primary={t('Proposal', { count: 2 })}
                    />
                    <UpgradeIcon moduleName="Proposals" />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[
                    PERMISSION_LIST.PROPOSAL_SUMMARIES_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    disabled={isDisabledV4 || isDisabledV5}
                    onClick={() => {
                        redirect('/summaries');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/summaries' === match.path}
                    variant="horizontal"
                >
                    <ArticleIcon />
                    <StyledStyledListItemText
                        primary={t('Summary', { count: 2 })}
                    />
                    <UpgradeIcon moduleName="Summaries" />
                </StyledListItemButton>
            </CheckAccess>
        </Box>
    );
};

SalesOptions.propTypes = {
    handleClose: PropTypes.func,
    handleDrawerToggle: PropTypes.func,
    match: PropTypes.object,
    mobileOpen: PropTypes.bool,
    redirect: PropTypes.func,
    subscription: PropTypes.object,
    subscriptions: PropTypes.array,
    subscriptionVersion: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    subscription: profileSelectors.getMainSubscription,
    subscriptions: profileSelectors.getSubscriptions,
    subscriptionVersion: profileSelectors.getSubscriptionVersion,
});

export default connect(mapStateToProps, null)(SalesOptions);
