import React from 'react';

import { useTheme } from '@mui/material/styles';
import { Box, Tooltip } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { REPORT_RESOLUTIONS, REPORT_RESOLUTIONS_CONFIG } from '../constants';

const ReportResolution = ({
    color,
    iconSize = 16,
    placement,
    resolution,
    sxBox,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const resolutionConfig =
        REPORT_RESOLUTIONS_CONFIG[resolution] ??
        REPORT_RESOLUTIONS_CONFIG[REPORT_RESOLUTIONS.MANUAL];

    if (!resolutionConfig) return null;

    return (
        <Tooltip placement={placement} title={t(resolutionConfig.label)}>
            <Box display="flex" sx={sxBox}>
                <resolutionConfig.Icon
                    color={color || theme?.palette?.primary?.main}
                    size={iconSize}
                />
            </Box>
        </Tooltip>
    );
};

ReportResolution.propTypes = {
    color: PropTypes.string,
    iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placement: PropTypes.string,
    resolution: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    sxBox: PropTypes.object,
};

export default ReportResolution;
