import { create, update } from 'common/api/v1/aftersalesProjectConfiguration';
import {
    DOMESTIC_RATES,
    HIGH_TENSION_RATES,
    RATES_WITH_DIVISION,
} from 'common/constants/rates';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import { getCompensationSchemeByValue } from 'common/utils/helpers/rates';
import showToast from 'common/utils/showToast';

import {
    SAVE_DATA,
    SAVE_DATA_FAILURE,
    SAVE_DATA_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';
import * as selectors from '../selectors';

import fetchAfterSalesSettings from './fetchAfterSalesSettings';

export default (values, successCallback) => (dispatch, getState) => {
    dispatch(actions[SAVE_DATA]());

    const state = getState();
    const ratesDictionary = selectors.getRatesDictionary(state);

    const rate = ratesDictionary[values.rate];

    const compensation_scheme = getCompensationSchemeByValue(
        values.compensation_scheme,
    );

    const newValues = {
        auto_report: values?.auto_report || false,
        auto_report_day: values?.auto_report_day || null,
        cfe_user: values?.cfe_user || null,
        commercial_offer: values.commercial_offer || null,
        compensation_scheme: compensation_scheme?.backendKey || null,
        currency: values.currency || null,
        custom_offer_template: values?.custom_offer_template || null,
        exported_energy_price: values.exported_energy_price,
        integration_type: values?.integration_type ?? null,
        is_bimonthly: values.is_bimonthly === '1',
        modules: values.modules || 0,
        netted_exported_generation_price:
            values.netted_exported_generation_price,
        political_division1: values.political_division1 || null,
        political_division2: values.political_division2 || null,
        ppa_active: values.ppa_active || false,
        ppa_percentage_increase: values.ppa_percentage_increase || 0,
        ppa_price: values.ppa_price || 0,
        project: values.project,
        rate: values.rate || null,
        rate_division: values.rate_division || null,
        rate_division_summer: values.rate_division_summer || null,
        service_number: values.service_number || null,
        source: values.source || '0',
        start_date: values.start_date || null,
        system_size: values.system_size || 0,
        total_cost: values.total_cost || 0,
        type_change: values.type_change || null,
        zap_rpu_id: values?.zap_rpu_id || null,
        zip_code: values.zip_code || null,
    };

    if (rate?.certified) {
        if (RATES_WITH_DIVISION.includes(rate.name))
            newValues.rate_division = values.rate_division || null;
        if (rate.name === 'DAC') {
            const ratesDictionaryByName =
                selectors.getRatesDictionaryByName(state);
            const subsidyRate = ratesDictionaryByName[values.subsidy_rate];
            newValues.rate_division = values.rate_region || null;
            newValues.subsidy_rate = subsidyRate?.id || null;
        }
        if (DOMESTIC_RATES.includes(rate.name))
            newValues.rate_division_summer =
                values.rate_division_summer || null;
        if (HIGH_TENSION_RATES.includes(rate.name))
            newValues.contracted_demand = [
                {
                    tier: 0,
                    value: values?.contracted_demand || 0,
                },
            ];
    } else if (values.hourly_contracted_demand) {
        newValues.contracted_demand = Object.values(
            values.hourly_contracted_demand,
        ).map((item) => ({ tier: item.tier, value: item.value || 0 }));
    }

    const save = !values.id
        ? () => create(newValues)
        : () => update(values.id, newValues);

    save()
        .then((response) => {
            dispatch(actions[SAVE_DATA_SUCCESS](response.data));
            dispatch(fetchAfterSalesSettings(values.project));
            if (successCallback) successCallback();
            showToast();
        })
        .catch((error) => {
            dispatch(actions[SAVE_DATA_FAILURE](error?.response?.data?.errors));
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
