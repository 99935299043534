import React, { useEffect } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Card, Typography } from 'sunwise-ui';

import withChargebee from 'common/hocs/withChargebee';
import { getShowPaymentDueView } from 'common/utils/helpers/session';

import isotypeSunwiseWhite from 'resources/isotypeSunwise3.svg';
import logotipoSunwiseAzul from 'resources/logotipoSunwiseAzul.svg';

import * as profileActions from '../profile/actions';
import * as profileSelectors from '../profile/selectors';
import { formatPeriodDate } from '../profileSubscriptions/helpers';

const Container = ({
    fetchUserSettings,
    isFetchingUserSettings,
    redirect,
    subscriptions,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const show = getShowPaymentDueView();

    useEffect(() => {
        fetchUserSettings();
    }, []);

    useEffect(() => {
        if (subscriptions && !show) redirect('/profile/subscription');
    }, [subscriptions]);

    const subscriptionPD =
        subscriptions.filter((item) => item.due_invoices_count >= 1) || [];

    const text1 = () => {
        if (subscriptionPD.length === 1)
            return (
                <Typography fontWeight="bold" gutterBottom variant="body1">
                    {t(
                        'We hope you’ve enjoyed the benefits of your {{planName}} subscription',
                        { planName: subscriptionPD[0]?.plan_name },
                    )}
                    .
                </Typography>
            );
        return (
            <Box display="flex" flexDirection="column">
                <Typography fontWeight="bold" gutterBottom variant="body1">
                    {t(
                        'We hope you have enjoyed the benefits of your subscriptions',
                    )}
                    .
                </Typography>
            </Box>
        );
    };

    const text2 = () => {
        if (subscriptionPD.length === 1)
            return (
                <Typography gutterBottom variant="body2">
                    {t(
                        'It looks like there is a pending payment on your account. If the payment is not completed, your subscription is scheduled for cancellation on {{cancellationDate}}',
                        {
                            cancellationDate: formatPeriodDate(
                                subscriptionPD[0]?.next_cancel_date,
                            ),
                        },
                    )}
                    .
                </Typography>
            );
        return (
            <Box display="flex" flexDirection="column">
                <Typography variant="body2">
                    {t(
                        'We have noticed that there are pending payments on your account for the following subscriptions',
                    )}
                    :
                </Typography>
                <Box my={1}>
                    {subscriptionPD.map((sub, index) => (
                        <Box
                            key={index}
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                mb: 1,
                            }}
                        >
                            <Typography fontWeight={700} variant="body2">
                                {sub.plan_name}
                            </Typography>
                            <Typography variant="body2">
                                {t('Cancellation date on')}{' '}
                                {formatPeriodDate(sub?.next_cancel_date)}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        );
    };

    const renderContent = () => {
        if (isFetchingUserSettings) {
            return (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            );
        }
        return (
            <Card sx={{ width: 480, borderRadius: 4 }}>
                <Card.Header sx={{ textAlign: 'center' }}>
                    {isDarkMode ? (
                        <img
                            alt="Sunwise"
                            src={isotypeSunwiseWhite}
                            width="200"
                        />
                    ) : (
                        <img
                            alt="Sunwise"
                            src={logotipoSunwiseAzul}
                            width="200"
                        />
                    )}
                </Card.Header>
                <Card.Body sx={{ textAlign: 'center' }}>
                    <Typography
                        fontWeight="bold"
                        gutterBottom
                        variant="h5"
                        sx={{
                            color: 'primary.main',
                        }}
                    >
                        {t('Thank you for using Sunwise!')}
                    </Typography>
                    {text1()}
                    {text2()}
                    <Typography gutterBottom variant="body2">
                        {t(
                            'To continue enjoying uninterrupted access, please update your payment details',
                        )}
                        .
                    </Typography>
                    <Typography fontWeight={700} gutterBottom variant="caption">
                        Sunwise Team
                    </Typography>
                </Card.Body>
                <Card.Actions
                    sx={{
                        display: 'flex',
                        gap: 2,
                        justifyContent: 'center',
                        py: 2,
                    }}
                >
                    <Button
                        onClick={() =>
                            redirect(
                                '/profile/subscriptions?action=pay_invoices',
                            )
                        }
                        sx={{ textTransform: 'none' }}
                    >
                        {t('Reactivate now')}
                    </Button>
                    <Button
                        onClick={() => redirect('/profile/subscriptions')}
                        sx={{ textTransform: 'none' }}
                    >
                        {t("I'll do it later")}
                    </Button>
                </Card.Actions>
            </Card>
        );
    };

    return (
        <Box
            alignItems={isFetchingUserSettings ? 'flex-start' : 'center'}
            display="flex"
            height="100%"
            justifyContent="center"
            sx={{ backgroundColor: '#011e2e' }}
        >
            {renderContent()}
        </Box>
    );
};

Container.propTypes = {
    fetchUserSettings: PropTypes.func,
    isFetchingUserSettings: PropTypes.bool,
    redirect: PropTypes.func,
    subscriptions: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    subscriptions: profileSelectors.getSubscriptions,
    isFetchingUserSettings: profileSelectors.getIsFetching,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUserSettings: () => dispatch(profileActions.fetchProfileData()),
    redirect: (link) => dispatch(push(link)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withChargebee(),
)(Container);
