export const NAME = 'onboarding-wizard';
export const ONBOARDING_STEPS = {
    REGISTER: 'register',
    COMPANY: 'company',
    DETAILS: 'details',
    MEMBERS: 'members',
    PANELS: 'panels',
    INVERTERS: 'inverters',
    BATTERIES: 'batteries',
    FINISH: 'finish',
};
export const AFTERSALES_ONBOARDING_STEPS = {
    REGISTER: 'register',
    COMPANY: 'company',
    DETAILS: 'details',
    FINISH: 'finish',
};
export const DETAILS_STEP = 2;
export const MEMBERS_STEP = 3;
export const PANELS_STEP = 4;
export const INVERTERS_STEP = 5;
export const BATTERIES_STEP = 6;
