import { push } from 'connected-react-router';

import { buildQueryFromFilters } from 'common/utils/helpers';

import { FILTERS_KEYS } from '../constants';

export default (filters, baseUrl = '/monitoring-dashboard') =>
    (dispatch) => {
        const query = buildQueryFromFilters(
            Object.values(FILTERS_KEYS),
            filters,
        );
        dispatch(push(`${baseUrl}/?${query}`));
    };
