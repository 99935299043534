import React, { useContext, useEffect } from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid, UiDialog as Dialog } from 'sunwise-ui';

import commentArea from 'common/modules/commentArea';
import fileUploader from 'common/modules/fileUploader';
import { LoadingContext } from 'common/utils/contexts';

import * as actions from '../actions';
import * as selectors from '../selectors';

const FileReview = ({
    isUploadingFile,
    onHide,
    selectedFile,
    show,
    showChat,
    uploadDocumentFile,
    user,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isUploadingFile)
            loadingContext.openLoading(t('Uploading file').concat('...'));
        else loadingContext.closeLoading();
    }, [isUploadingFile]);

    const handleSend = (tempFile, onSuccess) => {
        if (get(selectedFile, 'id', null) !== null) {
            uploadDocumentFile(selectedFile.id, tempFile, onSuccess);
        }
    };

    return (
        <Dialog onClose={onHide} open={show} title={selectedFile.name}>
            <Grid container sx={{ height: '680px' }}>
                <Grid size={{ xs: 'grow' }}>
                    <fileUploader.Container
                        handleSend={handleSend}
                        initialFile={selectedFile.file}
                        isPreDownloadFile={selectedFile.preDownloadRequired}
                        status={selectedFile.status}
                    />
                </Grid>
                {showChat && (
                    <Grid size={{ xs: 'grow' }} sx={{ position: 'relative' }}>
                        <commentArea.Container
                            description={selectedFile.description}
                            documentId={selectedFile.id}
                            documentType={commentArea.ALLIANCE_DOCUMENT_TYPE}
                            title={selectedFile.name}
                            user={user}
                            userType={commentArea.INSTALLER_USER_TYPE}
                        />
                    </Grid>
                )}
            </Grid>
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    isUploadingFile: selectors.isFileUploading,
});

const mapDispatchToProps = (dispatch) => ({
    uploadDocumentFile: (financierInstallerDocumentId, file, onSuccess) =>
        dispatch(
            actions.uploadDocumentFile(
                financierInstallerDocumentId,
                file,
                onSuccess,
            ),
        ),
});

FileReview.propTypes = {
    isUploadingFile: PropTypes.bool,
    onHide: PropTypes.func,
    selectedFile: PropTypes.object,
    show: PropTypes.bool,
    showChat: PropTypes.bool,
    uploadDocumentFile: PropTypes.func,
    user: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(FileReview);
