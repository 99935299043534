import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import { ReactHookFormSelect2 } from 'common/components/form/bootstrap';

import * as actions from '../actions';
import { updatePlaceholder } from '../actions/updatePlaceholders';
import { handleNormalizeFields } from '../helpers';
import * as selectors from '../selectors';

import AccordionForm from './Accordion';

const SelectConsumptionProfile = ({
    consumptionProfiles,
    control,
    defaultConsumptionProfile,
    disabled,
    fetchConsumptionProfile,
    fetchConsumptionProfiles,
    getValues,
    handleNormalizeDates,
    hasFormErrors,
    hasId,
    isFetchingConsumptionProfiles,
    isFromCsv,
    profilesConsumptionData,
    rateConfiguration,
    ratesDictionary,
    resetConsumptionProfile,
    setValue,
}) => {
    const { t } = useTranslation();

    const handleOnChange = (e) => {
        if (e) {
            fetchConsumptionProfile(e);
            const formValues = getValues();
            const summaryNormalizeDates = handleNormalizeDates({
                getValues,
                summary: formValues?.summary,
            });
            const newSummary = handleNormalizeFields({
                discardValues: true,
                distribution: formValues?.distribution,
                rate: ratesDictionary[formValues?.rate],
                rateConfiguration,
                summary: summaryNormalizeDates,
            });

            setValue('summary', newSummary);

            updatePlaceholder({
                formValues: { ...formValues, summary: newSummary },
                profilesConsumptionData,
                ratesDictionary,
                setValue,
            });
        } else resetConsumptionProfile();
    };

    useEffect(() => {
        fetchConsumptionProfiles();
    }, []);

    useEffect(() => {
        if (!consumptionProfiles?.length || !defaultConsumptionProfile) return;

        const currentProfile = getValues('consumption_profile');

        if (currentProfile) return;
        setValue('consumption_profile', defaultConsumptionProfile);
        handleOnChange(defaultConsumptionProfile);
    }, [consumptionProfiles, defaultConsumptionProfile]);

    return (
        <AccordionForm
            defaultExpanded
            hasFormErrors={hasFormErrors}
            title={t('Consumption profile')}
        >
            <Grid container>
                <Grid size={{ md: 9, xs: 18 }}>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={disabled || isFromCsv}
                        isClearable={!hasId}
                        isLoading={isFetchingConsumptionProfiles}
                        label={t('Consumption profile')}
                        name="consumption_profile"
                        onChange={handleOnChange}
                        options={consumptionProfiles}
                        variant="filled"
                    />
                </Grid>
            </Grid>
        </AccordionForm>
    );
};

SelectConsumptionProfile.propTypes = {
    consumptionProfiles: PropTypes.array,
    control: PropTypes.object,
    defaultConsumptionProfile: PropTypes.string,
    disabled: PropTypes.bool,
    fetchConsumptionProfile: PropTypes.func,
    fetchConsumptionProfiles: PropTypes.func,
    getValues: PropTypes.func,
    handleNormalizeDates: PropTypes.func,
    hasFormErrors: PropTypes.bool,
    hasId: PropTypes.bool,
    isFetchingConsumptionProfiles: PropTypes.bool,
    isFromCsv: PropTypes.bool,
    profilesConsumptionData: PropTypes.array,
    rateConfiguration: PropTypes.object,
    ratesDictionary: PropTypes.object,
    resetConsumptionProfile: PropTypes.func,
    setValue: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    consumptionProfiles: selectors.getGroupedConsumptionProfilesForSelect,
    defaultConsumptionProfile: selectors.getDefaultConsumptionProfile,
    isFetchingConsumptionProfiles:
        selectors.getFetchConsumptionProfilesIsFetchhing,
    rateConfiguration: selectors.getFetchScheduleRateConfigurationData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchConsumptionProfile: (id) =>
        dispatch(actions.fetchConsumptionProfile(id)),
    fetchConsumptionProfiles: () =>
        dispatch(actions.fetchConsumptionProfiles()),
    resetConsumptionProfile: () => dispatch(actions.resetConsumptionProfile()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SelectConsumptionProfile);
