import i18next from 'i18next';

import showToast from 'common/utils/showToast';

import { getLayer } from '../layers';
import * as selectors from '../selectors';
import { getBounds } from '../solarGeometryhelpers';

import createCachedLayer from './createCachedLayer';
import updateSegments from './updateSegments';

import { getDataLayerUrls } from './';

const MAX_RADIUS = 150;

export const loadDataLayers = async (values) => await getDataLayerUrls(values);

export default (values) => async (dispatch, getState) => {
    const state = getState();

    const { mapRef } = values;

    const mapCurrent = mapRef.current;
    const google = mapCurrent.getGoogle();

    const segments = selectors.getSegmentsData(state);

    if (segments.length === 0) return;

    let promises = [];
    let hasMaxRadiusError = false;

    segments.forEach((segment) => {
        const { annualIrradiationData = null, polygon } = segment;
        const paths = polygon.map((point) => ({
            lat: point[0],
            lng: point[1],
        }));

        if (annualIrradiationData !== null) return;

        const gmPolygon = new google.maps.Polygon({ paths });
        const bounds = getBounds({ google, polygon: gmPolygon });
        const position = bounds.getCenter();
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();

        const diameter = google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(ne.lat(), ne.lng()),
            new google.maps.LatLng(sw.lat(), sw.lng()),
        );
        const radius = Math.ceil(diameter / 2);

        if (radius > MAX_RADIUS) {
            hasMaxRadiusError = true;
            return;
        }

        promises.push(
            loadDataLayers({
                apiKey: import.meta.env.VITE_GOOGLE_SOLAR_API_KEY,
                location: {
                    lat: parseFloat(position.lat()),
                    lng: parseFloat(position.lng()),
                },
                radiusMeters: radius,
                segmentId: segment.id,
            }),
        );
    });

    let dataLayersUrls = [];

    await Promise.all(promises).then((response) => {
        dataLayersUrls = response;
    });

    let cachedLayersPromises = [];

    dataLayersUrls.forEach((dataLayerUrl) => {
        const url = dataLayerUrl.annualFluxUrl;
        const params = new URLSearchParams(new URL(url).search);

        cachedLayersPromises.push(
            createCachedLayer({
                irradiation_id: params.get('id'),
                segment_id: dataLayerUrl.segmentId,
            }),
        );
    });

    let cachedLayersResponses = [];

    await Promise.all(cachedLayersPromises).then((response) => {
        cachedLayersResponses = response;
    });

    let layerPromises = [];

    cachedLayersResponses.forEach(({ segment_id, url }) => {
        layerPromises.push(
            getLayer({
                layerId: 'annualFlux',
                urls: { annualFluxUrl: url },
                googleMapsApiKey: import.meta.env.VITE_GOOGLE_SOLAR_API_KEY,
                segmentId: segment_id,
            }),
        );
    });

    const newSegments = [...segments];

    await Promise.all(layerPromises).then((data) => {
        data.forEach((d) => {
            const canvas = d.render();
            const image = canvas.toDataURL();

            const segmentIndex = newSegments.findIndex(
                (segment) => segment.id === d.segmentId,
            );

            if (segmentIndex === -1) return;

            newSegments[segmentIndex] = {
                ...newSegments[segmentIndex],
                annualIrradiationData: { bounds: d.bounds, image },
            };
        });
    });

    dispatch(updateSegments(newSegments));

    if (hasMaxRadiusError) {
        showToast({
            autoClose: 3000,
            body: i18next.t(
                'One or more segments exceeded the maximum radius ({{maxRadius}} m) for this action',
                { maxRadius: MAX_RADIUS },
            ),
            position: 'bottom-center',
            type: 'warning',
        });
    }
};
