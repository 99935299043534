import React from 'react';

import { useTranslation } from 'react-i18next';
import { Alert } from 'sunwise-ui';

const GenerationAlert = () => {
    const { t } = useTranslation();
    return (
        <Alert severity="warning">
            {t(
                "It looks like you uploaded an electricity bill with solar generation. Consumption data may be inaccurate, and expansion projects don't have full support yet. Contact us if you need assistance",
            )}
        </Alert>
    );
};

export default GenerationAlert;
