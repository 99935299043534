import { getMonitoringConfigs } from 'common/api/v1/proposals';

import {
    FETCH_SUNPAL_CONFIG,
    FETCH_SUNPAL_CONFIG_FAILURE,
    FETCH_SUNPAL_CONFIG_SUCCESS,
    INITIALIZE_SUNPAL_CONFIG,
} from '../actionTypes';
import { actions } from '../reducer';

export default (uuid) => (dispatch) => {
    dispatch(actions[FETCH_SUNPAL_CONFIG]());

    getMonitoringConfigs(uuid)
        .then((response) => {
            const data = response.data;
            dispatch(actions[FETCH_SUNPAL_CONFIG_SUCCESS](data));
            dispatch(actions[INITIALIZE_SUNPAL_CONFIG](data));
        })
        .catch((error) =>
            dispatch(
                actions[FETCH_SUNPAL_CONFIG_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
