import React from 'react';

import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, Grid, Tooltip, Typography } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import ShowErrors from 'common/components/ShowErrors';
import { CB_PORTAL_SECTIONS, SUBSCRIPTION_STATUS } from 'common/constants';
import { formatDate, parseDate } from 'common/utils/dates';

import { getStatusName, getUsersDetails } from '../helpers';

import SubscriptionTable from './SubscriptionTable';

const CurrentPlan = ({
    activeUsers,
    addonsSubscriptions,
    canModify,
    handlePortal,
    payInvoices,
    payInvoicesErrors,
    paymentSources,
    subscription,
}) => {
    const { t } = useTranslation();

    if (isEmpty(subscription)) return null;

    const billingPeriod = get(subscription, 'billing_period_unit.value', null);
    const dueInvoicesCount = get(subscription, 'due_invoices_count', 0);
    const hasPaymentDue = dueInvoicesCount > 0;
    const renovationDate = formatDate(
        parseDate(
            get(subscription, 'next_billing_at', null),
            'dd/M/yyyy HH:mm:ss',
        ),
        'dd MMMM yyyy',
    );
    const primaryPaymentSource = paymentSources.find((item) => item.is_primary);
    const last4 = primaryPaymentSource ? primaryPaymentSource.last4 : 'XXXX';
    const subscriptionStatus = get(subscription, 'status.key', null);
    const showOptions = [
        SUBSCRIPTION_STATUS.ACTIVE,
        SUBSCRIPTION_STATUS.NON_RENEWING,
    ].includes(subscriptionStatus);

    return (
        <Card>
            <Card.Header sx={{ display: 'flex' }}>
                <Typography variant="body1" fontWeight="bold">
                    {t('Subscription')}
                </Typography>
                <Box sx={{ ml: 'auto' }}>
                    <Typography variant="body2" fontWeight="bold">
                        {getStatusName(hasPaymentDue, subscription)}
                    </Typography>
                </Box>
            </Card.Header>
            <Card.Body sx={{ minHeight: '487px' }}>
                <Grid container>
                    <Grid display="flex" flexDirection="column" size={18}>
                        <Grid container>
                            <Grid alignItems="center" display="flex" size={18}>
                                <Typography fontWeight="bold" variant="body2">
                                    {subscription?.plan_name}{' '}
                                    {billingPeriod === 'year'
                                        ? t('Annual')
                                        : t('Monthly')}
                                </Typography>
                                <DropdownCardMenu
                                    items={[
                                        {
                                            handleClick: () =>
                                                handlePortal(
                                                    CB_PORTAL_SECTIONS.SUBSCRIPTION_CANCELLATION,
                                                ),
                                            label: t('Cancel subscription'),
                                            visible:
                                                subscriptionStatus ===
                                                SUBSCRIPTION_STATUS.ACTIVE,
                                        },
                                        {
                                            handleClick: () =>
                                                handlePortal(
                                                    CB_PORTAL_SECTIONS.SUBSCRIPTION_DETAILS,
                                                ),
                                            label: t('Active subscription'),
                                            visible:
                                                subscriptionStatus ===
                                                SUBSCRIPTION_STATUS.NON_RENEWING,
                                        },
                                    ]}
                                    sxIconButton={{ ml: 'auto' }}
                                    visible={showOptions && canModify}
                                />
                            </Grid>
                            <Grid display="flex" gap={1} size={18}>
                                <Tooltip title={t('Renewal date')}>
                                    <Typography ml={2} variant="body2">
                                        {t('Renewal date')}:
                                    </Typography>
                                </Tooltip>
                                <Typography variant="body2">
                                    {renovationDate}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Typography ml={2} mt={1} variant="body2">
                            {getUsersDetails(activeUsers, subscription)}
                        </Typography>

                        <Typography
                            fontWeight="bold"
                            ml={2}
                            mt={1}
                            sx={{ color: 'primary.main' }}
                            variant="body2"
                        >
                            {`${t('Total')}: ${get(subscription, 'amount', 0)}`}
                        </Typography>
                    </Grid>
                </Grid>
                {!isEmpty(addonsSubscriptions) && (
                    <Grid container mt={1}>
                        <Grid size={{ xs: 'grow' }}>
                            <Typography fontWeight="bold" variant="body2">
                                {t('Other subscriptions')}
                            </Typography>
                            <SubscriptionTable
                                canModify={canModify}
                                handlePortal={handlePortal}
                                items={addonsSubscriptions}
                            />
                        </Grid>
                    </Grid>
                )}
                {payInvoicesErrors && (
                    <Box my={1}>
                        <ShowErrors
                            errors={payInvoicesErrors}
                            sxError={{ fontSize: '14px' }}
                            sxTitle={{ fontSize: '14px' }}
                        />
                    </Box>
                )}
            </Card.Body>
            <Card.Actions sx={{ minHeight: '65px', justifyContent: 'end' }}>
                <Button
                    onClick={() => payInvoices(last4)}
                    sx={{ width: { xs: '100%', md: 'auto' } }}
                    visible={canModify && hasPaymentDue}
                >
                    {t('Pay subscription')}
                </Button>
            </Card.Actions>
        </Card>
    );
};

CurrentPlan.propTypes = {
    activeUsers: PropTypes.number,
    addonsSubscriptions: PropTypes.array,
    canModify: PropTypes.bool,
    handlePortal: PropTypes.func,
    payInvoices: PropTypes.func,
    payInvoicesErrors: PropTypes.array,
    paymentSources: PropTypes.array,
    subscription: PropTypes.object,
};

export default CurrentPlan;
