import React, { useState } from 'react';

import BusinessIcon from '@mui/icons-material/Business';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Collapse, Grid, IconButton } from 'sunwise-ui';

import MapContainer from 'common/components/MapContainer';
import TextLabel from 'common/components/TextLabel';
import TextVariant from 'common/components/TextVariant';
import {
    DEFAULT_LAT,
    DEFAULT_LNG,
    DEFAULT_MAP_PREVIEW_ZOOM,
} from 'common/constants';

import iconGoogleMaps from 'resources/iconGoogleMaps.svg';

const PreviewLocation = ({ heightPreviewMap, isOpen, position }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(isOpen);

    if (!position || !position.position) return null;

    const markers = [
        {
            lat: get(position, 'position.latitude'),
            lng: get(position, 'position.longitude'),
            name: position.description,
            title: position.title,
        },
    ];
    const center = {
        lat: get(position, 'position.latitude', DEFAULT_LAT),
        lng: get(position, 'position.longitude', DEFAULT_LNG),
    };

    return (
        <>
            <Card.Body>
                <Grid container>
                    <Grid>
                        <BusinessIcon />
                    </Grid>

                    <Grid size={{ xs: 'grow' }}>
                        <TextVariant
                            text={position.title || t('No location')}
                            variant="bold"
                        />
                        <br />
                        <TextVariant
                            text={`(${get(
                                position,
                                'position.latitude',
                                '',
                            )}, ${get(position, 'position.longitude', '')})`}
                        />
                        &nbsp;&nbsp;
                        <a
                            href={`https://www.google.com/maps/search/?api=1&query=${get(
                                position,
                                'position.latitude',
                                '',
                            )},${get(position, 'position.longitude', '')}`}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <img
                                alt=""
                                className="img-fluid"
                                src={iconGoogleMaps}
                                width="22px"
                            />
                        </a>
                    </Grid>

                    <Grid>
                        <TextLabel noBorder>
                            <IconButton onClick={() => setOpen(!open)}>
                                {open ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </TextLabel>
                    </Grid>
                </Grid>
            </Card.Body>

            <Collapse in={open}>
                {open && (
                    <MapContainer
                        center={center}
                        heightMap={heightPreviewMap}
                        markers={markers}
                        zoom={DEFAULT_MAP_PREVIEW_ZOOM}
                    />
                )}
            </Collapse>
        </>
    );
};

PreviewLocation.propTypes = {
    heightPreviewMap: PropTypes.string,
    isOpen: PropTypes.bool,
    position: PropTypes.object,
};

export default PreviewLocation;
