import { BRIGHT_UID, KAM_UID, SERFIMEX_UID } from 'common/constants';

import { MODAL_PRODUCT_STATUS } from '../actionTypes';
import { alliancesActions } from '../reducer';

import initializeForm from './initializeForm';

const handleLists = (list) => {
    const temp = {};

    if (list) {
        list.split(',').forEach((item) => (temp[item] = true));
    }

    return temp;
};

const handleMaxMin = (limits) => (limits ? limits.split(',') : [0, 0]);

export default (values, disabled = true) =>
    (dispatch) => {
        const rules = {};

        values.financier_product_rules.forEach((rule) => {
            rules[rule.key] = rule.value;
        });

        const parameters = values.financier_product_parameters;

        const amount_values = handleMaxMin(rules.amount_values);
        const power_values = handleMaxMin(rules.power_values);
        const rate_list = handleLists(rules.rate_list);
        const catalog_documents = handleLists(values.catalog_documents);
        const parameters_terms = handleLists(parameters.terms);

        const newValues = {
            accesories: values.accesories,
            alerts: values.alerts,
            amount_values: {
                isChecked: parameters.blind_objects.includes('amount_values'),
                max: amount_values[1],
                min: amount_values[0],
            },
            annual_increase: parameters?.annual_increase,
            catalog_documents: catalog_documents,
            description: values.description,
            disabled: disabled,
            id: values.id,
            insurance: values.financier_product_insurance,
            inverters: values.inverters,
            is_bright: values?.financier?.id === BRIGHT_UID,
            is_kam: values?.financier?.id === KAM_UID,
            is_serfimex: values?.financier?.id === SERFIMEX_UID,
            name: values.name,
            panels: values.panels,
            parameters_commission_credit: {
                isChecked:
                    parameters.blind_objects.includes('commission_credit'),
                opening_commission_vat: parameters.opening_commission_vat,
                value: parameters.commission_credit,
                unit: parameters.is_commission_percentage,
            },
            parameters_hitch: {
                isChecked: parameters.blind_objects.includes('hitch'),
                value: parameters.hitch,
                unit: parameters.is_percentage,
            },
            parameters_insurance: {
                isChecked: parameters.blind_objects.includes('insurance'),
                value: parameters.insurance,
            },
            parameters_rate: {
                isChecked: parameters.blind_objects.includes('rate'),
                value: parameters.rate,
            },
            parameters_residual: {
                isChecked: parameters.blind_objects.includes('residual'),
                value: parameters.residual,
            },
            parameters: { ...parameters, terms: parameters_terms },
            power_values: {
                isChecked: parameters.blind_objects.includes('power_values'),
                max: power_values[1],
                min: power_values[0],
            },
            rate_list: rate_list,
            template: values?.template?.id,
            type_algorithm: values.type_algorithm,
            type: values.type,
            types_business_company: values.types_business_company,
        };

        dispatch(initializeForm(newValues));

        dispatch(alliancesActions[MODAL_PRODUCT_STATUS](true));
    };
