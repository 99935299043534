import { getAllReads } from 'common/api/afterSales/projects';
import { showFastApiErrorsAsAlert } from 'common/utils/helpers';

import {
    FETCH_PROJECT_READS,
    FETCH_PROJECT_READS_FAILURE,
    FETCH_PROJECT_READS_SUCCESS,
} from '../actionTypes';
import { FILTERS_KEYS } from '../constants';
import { getAfterSalesDateRange } from '../helpers';
import { actions } from '../reducer';

export default (projectId, filters) => (dispatch) => {
    const {
        [FILTERS_KEYS.FINAL_DATE]: final_date,
        [FILTERS_KEYS.FREQ]: freq,
        [FILTERS_KEYS.INITIAL_DATE]: initial_date,
        ...rest
    } = filters || {};

    if (!projectId || !initial_date || !final_date || !freq) return;

    dispatch(actions[FETCH_PROJECT_READS]());

    const dateValues = getAfterSalesDateRange({
        final_date,
        freq,
        initial_date,
    });

    getAllReads({
        ...rest,
        ...dateValues,
        [FILTERS_KEYS.PROJECT_ID]: projectId,
    })
        .then((response) => {
            dispatch(actions[FETCH_PROJECT_READS_SUCCESS](response?.data));
        })
        .catch((error) => {
            dispatch(actions[FETCH_PROJECT_READS_FAILURE](error));

            showFastApiErrorsAsAlert(dispatch, error?.response);
        });
};
