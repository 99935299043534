import { getHistoricalsReport } from '../../../common/api/v1/historicalsReport';
import {
    FETCH_REPORT_HISTORICALS,
    FETCH_REPORT_HISTORICALS_FAILURE,
    FETCH_REPORT_HISTORICALS_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (reportId) => (dispatch) => {
    dispatch(actions[FETCH_REPORT_HISTORICALS]());

    getHistoricalsReport(reportId)
        .then((response) =>
            dispatch(
                actions[FETCH_REPORT_HISTORICALS_SUCCESS](response?.data?.data),
            ),
        )
        .catch((error) =>
            dispatch(actions[FETCH_REPORT_HISTORICALS_FAILURE](error)),
        );
};
