import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    Button,
    Container as MaterialContainer,
    Grid,
    UiDialog as Dialog,
} from 'sunwise-ui';

import HeaderContainer from 'common/components/HeaderContainer';
import TitleIcon from 'common/components/TitleIcon';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as actions from './actions';
import { ListItems, RoleForm } from './components';
import * as selectors from './selectors';

const Container = ({
    canDelete,
    canModify,
    fetchItems,
    handleClickCloseModal,
    handleClickCreate,
    handleClickDelete,
    handleClickUpdate,
    isFetching,
    isNewRecord,
    isOpenModal,
    items,
    paginationItems,
}) => {
    const { t } = useTranslation();
    const [filterData, setFilterData] = useState({
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        sortBy: 'desc',
    });

    useEffect(() => {
        fetchItems(filterData);
    }, []);

    return (
        <MaterialContainer maxWidth={false}>
            <HeaderContainer>
                <Grid size={{ md: 12, xs: 18 }}>
                    <TitleIcon
                        fontWeight="bold"
                        title={t('Role', { count: 2 })}
                    />
                </Grid>
                <Grid size={{ md: 6, xs: 18 }} textAlign="right">
                    <Button
                        endIcon={<AddIcon fontSize="small" />}
                        onClick={handleClickCreate}
                        visible={canModify}
                    >
                        {`${t('Add')} ${t('Role').toLowerCase()}`}
                    </Button>
                </Grid>
            </HeaderContainer>
            <ListItems
                canDelete={canDelete}
                canModify={canModify}
                filterData={filterData}
                filterItems={fetchItems}
                handleClickDelete={handleClickDelete}
                handleClickUpdate={handleClickUpdate}
                isFetching={isFetching}
                items={items}
                paginationData={paginationItems}
                setFilterData={setFilterData}
            />
            <Dialog
                onClose={() => handleClickCloseModal()}
                open={isOpenModal}
                size="lg"
                title={`${isNewRecord ? t('Add') : t('Update')} ${t(
                    'Role',
                ).toLowerCase()}`}
            >
                <RoleForm filterData={filterData} />
            </Dialog>
        </MaterialContainer>
    );
};

Container.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    fetchItems: PropTypes.func,
    handleClickCloseModal: PropTypes.func,
    handleClickCreate: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    isFetching: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    items: PropTypes.array,
    paginationItems: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    isFetching: selectors.getIsFetchingItems,
    isNewRecord: selectors.getIsNewRecord,
    isOpenModal: selectors.getisOpenModal,
    items: selectors.getFetchItemsData,
    paginationItems: selectors.getFetchItemsPagination,
});

const mapDispatchToProps = (dispatch) => ({
    fetchItems: (filterData) => dispatch(actions.fetchItems(filterData)),
    handleClickCloseModal: () => dispatch(actions.openModal(false)),
    handleClickCreate: () => dispatch(actions.prepareCreate()),
    handleClickDelete: (item) => dispatch(actions.prepareDelete(item)),
    handleClickUpdate: (id) => dispatch(actions.fetchRoleDetails(id)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.ROLES_PERMISSION),
)(Container);
