import React from 'react';

import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert } from 'sunwise-ui';

const ReadOnlyAlert = ({ isReadOnly }) => {
    const { t } = useTranslation();

    if (!isReadOnly) return null;

    return (
        <Alert
            icon={<LaptopMacIcon fontSize="inherit" />}
            severity="info"
            sx={{ mx: 2, my: 1 }}
        >
            {t(
                'This proposal was generated via the web quote and is read only',
            )}
        </Alert>
    );
};

ReadOnlyAlert.propTypes = {
    isReadOnly: PropTypes.bool,
};

export default ReadOnlyAlert;
