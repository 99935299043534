import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, Grid, MenuItem, Select } from 'sunwise-ui';

import { BusinessTypeFilterDropdown, InfoToolTip } from 'common/components';

import { BUSINESS_TYPE_ITEMS } from '../constants';

import InfoList from './InfoList';
import RateTypeFilterDropdown from './RateTypeFilterDropdown';
import SearchInput from './SearchInput';
import VoltageTypeFilterDropdown from './VoltageTypeFilterDropdown';

const RateFilter = ({
    businessTypeSelected,
    certifiedRatesNumber = 0,
    isEnabled = true,
    isFetchingRates,
    rateTypeSelected,
    selectBusinessType,
    selectRateType,
    selectVoltageType,
    setFilterName,
    setIsEnabled,
    voltageTypeSelected,
}) => {
    const { t } = useTranslation();
    const handleOnSearchChange = (projectName) => setFilterName(projectName);
    return (
        <Grid container py={2}>
            <Grid
                size={{ lg: 6, md: 12, xs: 18 }}
                display="flex"
                flexDirection="column"
                justifyContent="end"
            >
                <SearchInput
                    handleOnSearchChange={handleOnSearchChange}
                    placeholderSearch={t('Filter by name')}
                />
            </Grid>

            <Grid size={{ lg: 3, md: 6, sm: 9, xs: 18 }}>
                <RateTypeFilterDropdown
                    disabled={isFetchingRates}
                    rateTypeSelected={rateTypeSelected}
                    selectRateType={selectRateType}
                />
            </Grid>

            <Grid size={{ lg: 3, md: 6, sm: 9, xs: 18 }}>
                <BusinessTypeFilterDropdown
                    disabled={isFetchingRates}
                    label={t('Type of installation')}
                    selectType={selectBusinessType}
                    typeItems={BUSINESS_TYPE_ITEMS}
                    typeSelected={businessTypeSelected}
                />
            </Grid>

            <Grid size={{ lg: 3, md: 6, sm: 9, xs: 18 }}>
                <VoltageTypeFilterDropdown
                    disabled={isFetchingRates}
                    selectVoltageType={selectVoltageType}
                    voltageTypeSelected={voltageTypeSelected}
                />
            </Grid>

            <Grid size={{ lg: 3, md: 6, sm: 9, xs: 18 }}>
                <Box alignItems="center" display="flex" gap="16px">
                    <FormControl fullWidth size="small">
                        <Select
                            onChange={(e) => setIsEnabled(e.target.value)}
                            value={isEnabled}
                        >
                            <MenuItem value={true}>
                                <Box>{t('Active')}</Box>
                            </MenuItem>
                            <MenuItem value={false}>
                                <Box>{t('Archived')}</Box>
                            </MenuItem>
                        </Select>
                    </FormControl>

                    <InfoToolTip variant="no-wrapper">
                        <InfoList certifiedRatesNumber={certifiedRatesNumber} />
                    </InfoToolTip>
                </Box>
            </Grid>
        </Grid>
    );
};

RateFilter.propTypes = {
    businessTypeSelected: PropTypes.string,
    certifiedRatesNumber: PropTypes.number,
    isEnabled: PropTypes.bool,
    isFetchingRates: PropTypes.bool,
    rateTypeSelected: PropTypes.string,
    selectBusinessType: PropTypes.func,
    selectRateType: PropTypes.func,
    selectVoltageType: PropTypes.func,
    setFilterName: PropTypes.func,
    setIsEnabled: PropTypes.func,
    voltageTypeSelected: PropTypes.string,
};

export default RateFilter;
