import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import { buildValues } from '../helpers';

import save from './save';

export default (item, filter) => (dispatch) => {
    const values = buildValues(item);
    const dataAlert = {
        confirmText: i18next.t('Duplicate'),
        defaultTextResponse: `${i18next.t('Copy of')} ${values.name}`,
        haveTextResponse: true,
        messages: [i18next.t('Plan name')],
        onSuccess: (value) =>
            dispatch(
                save(filter, {
                    ...values,
                    id: null,
                    installation_type: {},
                    name: value,
                }),
            ),
        title: i18next.t('Duplicate plan'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
