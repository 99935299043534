import React from 'react';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import LockIcon from '@mui/icons-material/Lock';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Tooltip } from 'sunwise-ui';

import {
    getPaymentDueModules,
    getSubscriptionsModules,
} from 'common/utils/helpers';

import * as profileSelectors from '../../../modules/profile/selectors';

const UpgradeIcon = ({
    addons,
    isAftersalesSubscribed,
    moduleName,
    subscriptions,
    subscriptionVersion,
}) => {
    const { t } = useTranslation();
    const modules = getSubscriptionsModules(
        addons,
        isAftersalesSubscribed,
        subscriptions,
        subscriptionVersion,
    );
    const paymentDuelModules = getPaymentDueModules(subscriptions);

    // Convertir moduleName en un array, eliminando espacios en blanco
    const moduleNamesArray = moduleName.split(',').map((name) => name.trim());

    // Verificar si alguno de los moduleNames está en paymentDuelModules
    const isPaymentDue = moduleNamesArray.some((name) =>
        paymentDuelModules.includes(name),
    );

    const renderIcon = () => {
        if (isPaymentDue) return <LockIcon fontSize="small" />;
        return <ArrowUpwardIcon fontSize="small" />;
    };

    // Verificar si alguno de los moduleNames está en modules
    const hasAccess = moduleNamesArray.some((name) => modules.includes(name));

    if (!hasAccess) {
        return (
            <Tooltip
                title={
                    isPaymentDue
                        ? t(
                              'This section is blocked due to a pending subscription payment',
                          )
                        : 'Improve your plan'
                }
                placement="top"
                arrow
            >
                <Box
                    component="span"
                    sx={{
                        alignItems: 'center',
                        backgroundColor: 'primary.main',
                        borderRadius: 24,
                        display: 'flex',
                        height: 24,
                        justifyContent: 'center',
                        ml: 1,
                        svg: {
                            color: '#fff !important',
                            height: '16px',
                            width: '16px',
                        },
                        width: 24,
                    }}
                >
                    {renderIcon()}
                </Box>
            </Tooltip>
        );
    }
    return null;
};

UpgradeIcon.propTypes = {
    addons: PropTypes.array,
    isAftersalesSubscribed: PropTypes.bool,
    moduleName: PropTypes.string,
    subscriptions: PropTypes.array,
    subscriptionVersion: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    addons: profileSelectors.getAddons,
    isAftersalesSubscribed: profileSelectors.getIsAftersalesSubscribed,
    subscriptions: profileSelectors.getSubscriptions,
    subscriptionVersion: profileSelectors.getSubscriptionVersion,
});

export default connect(mapStateToProps, null)(UpgradeIcon);
