import React from 'react';

import PropTypes from 'prop-types';
import { Badge, Box, Tab } from 'sunwise-ui';

import UpgradeIcon from 'common/layouts/defaultLayout/UpgradeIcon';

const TabBadge = ({
    color = 'error',
    content = '',
    label,
    moduleName = null,
    value,
    visible,
    ...props
}) => (
    <Tab
        {...props}
        label={
            <Box alignItems="center" display="flex">
                <Badge
                    color={color}
                    content={content}
                    invisible={!visible}
                    variant="dot"
                >
                    {label}
                </Badge>
                {moduleName && <UpgradeIcon moduleName={moduleName} />}
            </Box>
        }
        value={value}
    />
);

TabBadge.propTypes = {
    color: PropTypes.string,
    content: PropTypes.string,
    label: PropTypes.string,
    moduleName: PropTypes.string,
    onClick: PropTypes.func,
    value: PropTypes.string,
    visible: PropTypes.bool,
};

export default TabBadge;
