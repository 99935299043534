import { styled } from '@mui/material';
import { TableCell } from '@sunwisesoftware/sunwise-ui';

export default styled(TableCell)`
    border: 1px solid;
    cursor: ${({ clickable }) =>
        clickable === 'true' ? 'pointer' : 'default'};
    min-width: 150px;
    padding: 8px;
    text-align: center;

    .MuiFormControl-root {
        margin: 0;
    }

    .MuiFormControl-root > :before {
        border: 0;
    }

    * {
        text-align: center;
        margin: 0;
    }
`;
