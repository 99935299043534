import { create } from 'common/api/v1/interactiveMonitoring';
import showToast from 'common/utils/showToast';

import fethSunpalConfig from './fethSunpalConfig';

export default (data) => (dispatch) => {
    create(data)
        .then(() => {
            const { project_id } = data;
            dispatch(fethSunpalConfig(project_id));
            showToast();
        })
        .catch(() => {});
};
