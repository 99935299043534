import React, { useEffect } from 'react';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useTheme, styled } from '@mui/material/styles';
import { get, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Box, Button, Grid, Typography } from 'sunwise-ui';

import { STATUS } from 'common/constants';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import {
    addDefaultSrc,
    getTranslatedStatusValue,
    handleFileURL,
} from 'common/utils/helpers';
import parseQueryString from 'common/utils/parseQueryString';

import * as actions from '../actions';
import { getSxRequestButton } from '../helpers';

const WrapperImage = styled(Box)`
    background-color: #d2d2d2;
    border-radius: 35px;
    border: 1px solid #eef4fa;
    display: flex;
    height: 32px;
    width: 32px;
`;

const StyledFinancingImage = styled('img')`
    border-radius: 50%;
    display: block;
    height: 100%;
    object-fit: contain;
    object-position: center;
    overflow: hidden;
    width: 100%;
`;

const FinancingSection = ({
    canModify,
    creditAppId,
    creditItem,
    creditStatus,
    currency,
    handleOnClickApplyCredit,
    handleToggleDrawer,
    hasClosedCredit,
    hasFinancingRequest,
    hasRequestBureau,
    isArchived,
    isGenerated,
    offerId,
    projectId,
    proposalId,
    requestCount,
    setSelectedCreditItem,
    setRef,
    showAllianceWarning,
    typesBusiness,
    location,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    if (
        isNull(creditItem) ||
        isNull(get(creditItem, 'financier_installer', null))
    )
        return null;

    const { financier_installer } = creditItem;
    const { search: locationSearch = '' } = location;
    const { credit: creditId = null } = parseQueryString(locationSearch);
    const image = get(financier_installer, 'financier.image', null);
    const isDarkMode = theme.palette.mode === 'dark';
    const isQuoted = creditStatus === STATUS.QUOTED_STATUS.key;
    const isDisabledButton =
        !canModify ||
        !isGenerated ||
        isArchived ||
        (isQuoted && hasClosedCredit);

    const openDrawer = () => {
        setSelectedCreditItem({
            ...creditItem,
            creditAppId,
            currency,
            hasRequestBureau,
            offerId,
            proposalId,
            status: creditStatus,
            typesBusiness,
        });
        handleToggleDrawer(true);
    };

    useEffect(() => {
        if (!creditId) return;
        if (creditId !== creditAppId) return;
        openDrawer();
    }, [creditId]);

    const handleClickButton = () => {
        if (!isGenerated && hasFinancingRequest) {
            showAllianceWarning();
            return false;
        }
        if (isGenerated && isQuoted)
            handleOnClickApplyCredit(
                () => {
                    setSelectedCreditItem({
                        ...creditItem,
                        creditAppId,
                        currency,
                        hasRequestBureau,
                        offerId,
                        proposalId,
                        status: STATUS.REQUESTED_STATUS.key,
                        typesBusiness,
                    });
                    handleToggleDrawer(true);
                },
                isArchived,
                offerId,
                projectId,
                requestCount,
            );
        else openDrawer();
    };

    return (
        <Grid
            container
            spacing={0}
            sx={{
                alignItems: 'center',
                backgroundColor: isDarkMode ? '#1b1d20' : '#ebf2f9',
                pb: { lg: 0, xs: 2 },
            }}
        >
            <Grid size={{ md: 12, xs: 18 }}>
                <Box
                    p={2}
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: 2,
                    }}
                >
                    <WrapperImage>
                        <StyledFinancingImage
                            onError={(e) => addDefaultSrc(e, 480)}
                            src={handleFileURL(
                                image,
                                import.meta.env.VITE_S3_MEDIA_PATH,
                            )}
                            width="100%"
                        />
                    </WrapperImage>
                    <Typography variant="body2">
                        {get(financier_installer, 'financier.name', '')}
                    </Typography>
                </Box>
            </Grid>

            <Grid px={2} size={{ lg: 6, xs: 18 }} textAlign="right">
                <Button
                    color="secondary"
                    disabled={isDisabledButton}
                    endIcon={<ArrowCircleRightIcon />}
                    id={offerId}
                    onClick={handleClickButton}
                    ref={setRef(offerId)}
                    sx={getSxRequestButton(creditStatus, isDarkMode)}
                    type="button"
                    variant="outlined"
                >
                    {isQuoted
                        ? t('Start request')
                        : t(getTranslatedStatusValue(creditStatus))}
                </Button>
            </Grid>
        </Grid>
    );
};

const mapDispatchToProps = (dispatch) => ({
    handleOnClickApplyCredit: (
        callback,
        isArchived,
        offerId,
        projectId,
        requestCount,
    ) =>
        dispatch(
            actions.onClickApplyCredit(
                callback,
                isArchived,
                offerId,
                projectId,
                requestCount,
            ),
        ),
    showAllianceWarning: () => dispatch(actions.showAllianceWarning()),
});

FinancingSection.propTypes = {
    canModify: PropTypes.bool,
    creditAppId: PropTypes.string,
    creditItem: PropTypes.object,
    creditStatus: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currency: PropTypes.object,
    handleOnClickApplyCredit: PropTypes.func,
    handleToggleDrawer: PropTypes.func,
    hasClosedCredit: PropTypes.bool,
    hasFinancingRequest: PropTypes.bool,
    hasRequestBureau: PropTypes.bool,
    isArchived: PropTypes.bool,
    isGenerated: PropTypes.bool,
    location: PropTypes.object,
    offerId: PropTypes.string,
    projectId: PropTypes.string,
    proposalId: PropTypes.string,
    requestCount: PropTypes.number,
    setSelectedCreditItem: PropTypes.func,
    setRef: PropTypes.func,
    showAllianceWarning: PropTypes.func,
    typesBusiness: PropTypes.string,
};

export default compose(
    connect(null, mapDispatchToProps),
    withPermissions(
        PERMISSION_LIST.REQUEST_ALLIANCE_PRODUCT_FOR_PROPOSAL_PERMISSION,
    ),
    withRouter,
)(FinancingSection);
