import { get } from 'lodash';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getContacts = createSelector(getModel, (model) => model.contacts);

export const getIsFetchingContacts = createSelector(
    getContacts,
    (value) => value.isFetching,
);

export const getContactsData = createSelector(getContacts, (value) =>
    value?.data?.data.map((contact) => ({
        ...contact,
        email: get(contact, 'emails[0].email', null),
    })),
);

export const getContactsPagination = createSelector(
    getContacts,
    (value) => value?.data?.pagination,
);

export const getContactsErrors = createSelector(
    getContacts,
    (value) => value.errors,
);

export const getContactsToReasign = createSelector(
    getModel,
    (model) => model.contactsToReasign,
);

export const getIsFetchingContactsToReasign = createSelector(
    getContactsToReasign,
    (value) => value.isFetching,
);

export const getContactTypes = createSelector(
    getModel,
    (model) => model.contactTypes,
);

export const getIsFetchingContactTypes = createSelector(
    getContactTypes,
    (model) => model.isFetching,
);

export const getContactTypesData = createSelector(
    getContactTypes,
    (model) => model.data,
);

export const getContactTypesErrors = createSelector(
    getContactTypes,
    (model) => model.errors,
);

export const getAgents = createSelector(getModel, (model) => model.agents);

export const getAgentsData = createSelector(getAgents, (model) => model.data);

export const getIsDownloading = createSelector(
    getModel,
    (model) => model.isDownloading,
);

export const getIsInitializing = createSelector(
    getModel,
    (model) => model.isInitializing,
);

export const getIsOpenReasignModal = createSelector(
    getModel,
    (model) => model.isOpenReasignModal,
);

export const getIsUploading = createSelector(
    getModel,
    (model) => model.isUploading,
);

export const getReasignInitialValues = createSelector(
    getModel,
    (model) => model.reasignInitialValues,
);

export const getHasContacts = createSelector(
    getModel,
    (model) => model.hasContacts,
);
