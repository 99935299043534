import { generateCheckoutNewUrl } from 'common/api/v1/chargebee';

import confirmSubscription from './confirmSubscription';

export default (cbInstance, itemPriceId) => (dispatch) => {
    if (!cbInstance || !itemPriceId) return;

    cbInstance.openCheckout({
        hostedPage() {
            return generateCheckoutNewUrl({ item_price_id: itemPriceId }).then(
                (response) => response.data,
            );
        },
        success() {
            dispatch(confirmSubscription(() => cbInstance.closeAll()));
        },
    });
};
