import { HOURS_IN_DAY, HOURS_IN_YEAR, MS_IN_HOUR } from 'common/constants';
import { isInvalidLeapYearDay, shiftUTCDateString } from 'common/utils/dates';
import { getLocaleEquivalenceForDateFns } from 'common/utils/helpers/multiregion';

import { UNIT_KEYS, UNITS } from './constants';

const formatRange = (start, end) => {
    if (start === end) return `${start}`;
    return `${start}-${end}`;
};

export const formatMultipleItemsLabel = (options, values) => {
    if (!options?.length || !values?.length) return '--';

    const valuesSet = new Set(values);
    const ranges = [];
    let start = null;
    let end = null;

    for (const option of options) {
        const hasStartValue = start || start === 0;

        if (valuesSet.has(option?.value)) {
            if (!hasStartValue) start = option?.label;
            end = option?.label;
        } else if (hasStartValue) {
            ranges.push(formatRange(start, end));
            start = null;
            end = null;
        }
    }

    if (start) ranges.push(formatRange(start, end));

    return ranges.join(', ');
};

const convertPowerToWatts = (power, unit) => {
    const conversion = UNITS[unit]?.conversion;

    if (!power || !conversion) return 0;

    return power * conversion || 0;
};

export const convertPowerToKilowatts = (power, unit) => {
    const wattsEquivalent = convertPowerToWatts(power, unit);

    return wattsEquivalent / 1000 || 0;
};

export const getPowerValue = ({ power, quantity, unit }) => {
    if (!power || !quantity || (!unit && unit !== 0)) return 0;

    const watts = convertPowerToWatts(power, unit);

    return Math.round(watts * quantity || 0);
};

export const convertTimeToNumber = (time) => {
    if (!time) return 0;

    return Number(time.split(':').join(''));
};

export const processDevices = (devices = []) => {
    if (!devices?.length) return {};

    const processedDevices = {};

    for (const device of devices) {
        const {
            device_name,
            end_time,
            installed,
            power,
            quantity,
            soft_start,
            start_time,
            startup_factor,
            unit,
        } = device || {};

        if (installed || !device_name || !end_time || !start_time) continue;

        const startTime = convertTimeToNumber(start_time);
        const endTime = convertTimeToNumber(end_time);

        processedDevices[device_name] = {
            device_name,
            end_time: endTime,
            power: getPowerValue({ power, quantity, unit }),
            start_time: startTime,
            startup_factor: soft_start ? 0 : startup_factor,
        };
    }

    return processedDevices;
};

export const buildDeviceSchedule = (devices = []) => {
    if (!devices?.length) return {};

    const schedule = {};

    for (const device of devices) {
        const { device_name, installed, months, weekdays } = device;
        if (installed || !device_name || !months?.length || !weekdays?.length)
            continue;

        for (const month of months) {
            if (!schedule[month]) schedule[month] = {};

            for (const day of weekdays) {
                if (!schedule[month][day]) schedule[month][day] = [];

                schedule[month][day].push(device_name);
            }
        }
    }

    return schedule;
};

export const getDevicePowerInHour = (device, hourAsNumber) => {
    if (!device) return 0;
    if (hourAsNumber >= device.start_time && hourAsNumber <= device.end_time)
        return device.power || 0;
    return 0;
};

export const getStartupPower = (device, hourAsNumber) => {
    if (!device || !device.startup_factor) return 0;

    const { end_time, power, start_time, startup_factor } = device;

    const deviceStarted = hourAsNumber >= start_time;
    const isWithinFirstHour = hourAsNumber - start_time < 100;
    const isNotOverlappingMidnight = !(start_time < 100 && end_time >= 2300);

    if (deviceStarted && isWithinFirstHour && isNotOverlappingMidnight)
        return Math.max((startup_factor || 1) * power - power, 0) || 0;
    return 0;
};

export const getTotalPowerFromDevices = ({
    devices,
    devicesInDay,
    hourAsNumber,
}) => {
    if (!devices || !devicesInDay?.length)
        return { totalEnergy: 0, totalPower: 0 };

    let totalPower = 0;
    let totalEnergy = 0;

    for (const deviceName of devicesInDay) {
        const power = getDevicePowerInHour(devices[deviceName], hourAsNumber);
        const startupPower = getStartupPower(devices[deviceName], hourAsNumber);

        totalEnergy += power;
        totalPower += power + startupPower;
    }

    return { totalEnergy, totalPower };
};

export const transformValueToPercentage = (value, total) => {
    if (!value || !total) return 0;

    return (100 * value) / total;
};

export const transformPercentageToValue = (percentage, total) => {
    if (!percentage || !total) return 0;

    return (total * percentage) / 100;
};

export const getDemandValue = (value, returnValuesInKW) => {
    const fixedValue = returnValuesInKW
        ? convertPowerToKilowatts(value, UNIT_KEYS.W)
        : value;

    return Math.round(fixedValue || 0);
};

const getOriginalProfileValue = ({
    consumptionProfile,
    hasPreviousProfile,
    hour,
    totalHours,
}) => {
    if (hasPreviousProfile) {
        return consumptionProfile[hour] || 0;
    } else if (hour < totalHours) {
        return 100 / totalHours || 0;
    }

    return 0;
};

export const distributeConsumptionProfile = ({
    consumptionProfile,
    dateFormat,
    demandArray,
    devices,
    finalDate,
    initialConsumption,
    initialDate,
    returnValuesInKW,
    summarize = true,
}) => {
    const localeForDateFns = getLocaleEquivalenceForDateFns();

    // We need to add one day because the interval does not include the first day but includes the last day
    const intervalToShiftDate = MS_IN_HOUR * HOURS_IN_DAY;

    const initialUTCDate = shiftUTCDateString({
        date: initialDate || `01/01/${new Date().getFullYear()}`,
        dateFormat: dateFormat || 'dd/MM/yyyy',
        interval: intervalToShiftDate,
        localeForDateFns,
    });

    const finalUTCDate =
        finalDate &&
        shiftUTCDateString({
            date: finalDate,
            dateFormat: dateFormat || 'dd/MM/yyyy',
            interval: intervalToShiftDate,
            localeForDateFns,
        });

    const deviceSchedule = buildDeviceSchedule(devices);
    const processedDevices = processDevices(devices);
    const fixedInitialDate = new Date(initialUTCDate);
    const hasPreviousProfile = consumptionProfile?.length > 0;
    const hasPreviousDemand = demandArray?.length > 0;
    const newYearlyConsumption = [];
    const newYearlyDemand = [];
    let totalConsumption = initialConsumption || 0;
    let totalHours = 0;

    for (
        let currentDate = fixedInitialDate;
        newYearlyConsumption.length < HOURS_IN_YEAR &&
        (!finalUTCDate || currentDate < finalUTCDate);
        currentDate.setHours(currentDate.getHours() + 1)
    ) {
        const day = currentDate.getUTCDate();
        const month = currentDate.getUTCMonth();
        const year = currentDate.getUTCFullYear();

        if (isInvalidLeapYearDay({ day, month, year })) continue;

        totalHours++;
        const hour = currentDate.getUTCHours();
        const weekDay = currentDate.getUTCDay();

        const hourAsNumber = convertTimeToNumber(`${hour}:00`);
        const devicesInDay = deviceSchedule?.[month]?.[weekDay] || [];

        if (summarize) {
            const { totalEnergy, totalPower } = getTotalPowerFromDevices({
                devices: processedDevices,
                devicesInDay,
                hourAsNumber,
            });

            newYearlyConsumption.push(totalEnergy);
            newYearlyDemand.push(totalPower);
            totalConsumption += totalEnergy;
        } else {
            const processedDevicesArray = Object.values(processedDevices);
            const devicesInDayAsObject = devicesInDay.reduce(
                (acc, deviceName) => {
                    acc[deviceName] = true;
                    return acc;
                },
                {},
            );

            const devicesEnergy = {};
            const devicesPower = {};

            for (const device of processedDevicesArray) {
                const deviceName = device.device_name;

                const isDeviceInDay = devicesInDayAsObject[deviceName];
                const currentPower = isDeviceInDay
                    ? getDevicePowerInHour(device, hourAsNumber)
                    : 0;
                const startupPower = isDeviceInDay
                    ? getStartupPower(device, hourAsNumber)
                    : 0;

                devicesEnergy[deviceName] = currentPower;
                devicesPower[deviceName] = currentPower + startupPower;

                totalConsumption += currentPower;
            }

            newYearlyConsumption.push(devicesEnergy);
            newYearlyDemand.push(devicesPower);
        }
    }

    const newConsumptionProfile = [];
    const newConsumptionProfileDevices = { devices: {}, original: [] };
    const newDemandArray = [];
    const newDemandArrayDevices = { devices: {}, original: [] };

    for (let hour = 0; hour < HOURS_IN_YEAR; hour++) {
        const currentPower = newYearlyConsumption[hour] || 0;
        const currentDemand = newYearlyDemand[hour] || 0;

        const originalProfileValue = getOriginalProfileValue({
            consumptionProfile,
            hasPreviousProfile,
            hour,
            totalHours,
        });

        const originalConsumption = transformPercentageToValue(
            originalProfileValue,
            initialConsumption,
        );
        const originalDemand = demandArray?.[hour] || 0;

        if (summarize) {
            const percentage = transformValueToPercentage(
                originalConsumption + currentPower,
                totalConsumption,
            );

            newConsumptionProfile.push(percentage);
            newDemandArray.push(
                getDemandValue(
                    currentDemand + originalDemand,
                    returnValuesInKW,
                ),
            );
        } else {
            newConsumptionProfileDevices.original.push(
                transformValueToPercentage(
                    originalConsumption,
                    totalConsumption,
                ),
            );

            if (hasPreviousDemand)
                newDemandArrayDevices.original.push(
                    getDemandValue(originalDemand, returnValuesInKW),
                );

            for (const deviceName in currentPower) {
                if (!newConsumptionProfileDevices.devices[deviceName])
                    newConsumptionProfileDevices.devices[deviceName] = [];

                if (!newDemandArrayDevices.devices[deviceName])
                    newDemandArrayDevices.devices[deviceName] = [];

                newConsumptionProfileDevices.devices[deviceName].push(
                    transformValueToPercentage(
                        currentPower[deviceName],
                        totalConsumption,
                    ),
                );

                newDemandArrayDevices.devices[deviceName].push(
                    getDemandValue(currentDemand[deviceName], returnValuesInKW),
                );
            }
        }
    }

    return {
        consumptionProfile: newConsumptionProfile,
        consumptionProfileDevices: newConsumptionProfileDevices,
        demandArray: newDemandArray,
        demandArrayDevices: newDemandArrayDevices,
        totalConsumption: returnValuesInKW
            ? convertPowerToKilowatts(totalConsumption, UNIT_KEYS.W)
            : totalConsumption,
    };
};
