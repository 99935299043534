import React, { useEffect, useState } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Checkbox,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from 'sunwise-ui';

import TooltipVisibilityControls from './TooltipVisibilityControls';

const VisibilityOption = ({ checked, label, onClick, onClickOnlyButton }) => {
    const [isHovered, setIsHovered] = useState(false);
    const { t } = useTranslation();
    return (
        <MenuItem
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{ width: '300px' }}
        >
            <Checkbox checked={checked} />

            <Typography
                noWrap
                sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: isHovered ? 'calc(100% - 120px)' : '100%',
                }}
            >
                {label}
            </Typography>

            {onClickOnlyButton && isHovered && (
                <Button
                    onClick={(e) => {
                        e.stopPropagation();
                        onClickOnlyButton();
                    }}
                    sx={{
                        borderRadius: 2,
                        minWidth: '90px',
                        ml: 1,
                        overflow: 'hidden',
                        p: 0,
                        position: 'absolute',
                        right: '10px',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {t('Only')}
                </Button>
            )}
        </MenuItem>
    );
};

VisibilityOption.propTypes = {
    checked: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    onClickOnlyButton: PropTypes.func,
};

const ChartVisibilityControls = ({
    chartRef,
    datasets,
    defaultVisibility,
    onChangeVisibility,
    series,
    setTooltipConfig,
    tooltipConfig,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [options, setOptions] = useState([]);
    const [visibleSeries, setVisibleSeries] = useState(defaultVisibility || []);
    const { t } = useTranslation();

    const handleChangeVisibility = ({
        chartRef,
        datasets,
        options,
        values,
    }) => {
        if (!chartRef?.current) return;

        const chart = chartRef.current;

        const showSeries = (serie) => {
            datasets.forEach((dataset, i) => {
                if (dataset.name === serie) {
                    const meta = chart.getDatasetMeta(i);
                    meta.hidden = false;
                }
            });
        };

        const hideSeries = (serie) => {
            datasets.forEach((dataset, i) => {
                if (dataset.name === serie) {
                    const meta = chart.getDatasetMeta(i);
                    meta.hidden = true;
                }
            });
        };

        options?.forEach((serie) => {
            values.includes(serie) ? showSeries(serie) : hideSeries(serie);
        });

        chart.update();

        setVisibleSeries(values);
    };

    const getDefaultVisibility = (series, defaultValues) => {
        const values = series || [];
        if (!defaultValues) return values;
        const mappedDefaultValues = defaultValues?.reduce((acc, value) => {
            acc[value] = true;
            return acc;
        }, {});
        return values?.filter((serie) => mappedDefaultValues?.[serie]);
    };

    useEffect(() => {
        if (chartRef && series) {
            const newOptions = series.map((item) => item.name);
            const newValues = getDefaultVisibility(
                newOptions,
                defaultVisibility,
            );
            setOptions(newOptions);
            setVisibleSeries(newValues);
            handleChangeVisibility({
                chartRef,
                datasets,
                options: newOptions,
                values: newValues,
            });
            setIsVisible(true);
        } else {
            setIsVisible(false);
            setOptions([]);
            setVisibleSeries([]);
        }
    }, [chartRef, series]);

    useEffect(() => {
        if (Array.isArray(defaultVisibility))
            setVisibleSeries(defaultVisibility);
    }, [defaultVisibility]);

    if (!isVisible) return null;

    const handleOnChangeVisibility = (value) => {
        let newValues = [];
        if (value === 'all')
            newValues =
                options?.length === visibleSeries?.length
                    ? []
                    : options.map((label) => label);
        else {
            if (visibleSeries.includes(value))
                newValues = visibleSeries.filter((item) => item !== value);
            else newValues = [...visibleSeries, value];
        }
        handleChangeVisibility({
            chartRef,
            datasets,
            options,
            values: newValues,
        });

        if (onChangeVisibility) onChangeVisibility(newValues);
    };

    const setOnlyOneSerie = (index) => {
        const newValues = [index];
        handleChangeVisibility({
            chartRef,
            datasets,
            options,
            values: newValues,
        });

        if (onChangeVisibility) onChangeVisibility(newValues);
    };

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);

    return (
        <>
            <Tooltip title={t('Visibility')}>
                <IconButton onClick={handleClick}>
                    <VisibilityIcon />
                </IconButton>
            </Tooltip>

            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                <VisibilityOption
                    checked={visibleSeries.length === options.length}
                    label={t('All text', { count: 2 })}
                    onClick={() => handleOnChangeVisibility('all')}
                />

                {options?.map((serie, index) => (
                    <VisibilityOption
                        checked={visibleSeries.includes(serie)}
                        key={index}
                        label={serie}
                        onClick={() => handleOnChangeVisibility(serie)}
                        onClickOnlyButton={() => setOnlyOneSerie(serie)}
                    />
                ))}

                <TooltipVisibilityControls
                    setTooltipConfig={setTooltipConfig}
                    tooltipConfig={tooltipConfig}
                />
            </Menu>
        </>
    );
};

ChartVisibilityControls.propTypes = {
    chartRef: PropTypes.object,
    datasets: PropTypes.array,
    defaultVisibility: PropTypes.array,
    onChangeVisibility: PropTypes.func,
    series: PropTypes.array,
    setTooltipConfig: PropTypes.func,
    tooltipConfig: PropTypes.object,
};

export default ChartVisibilityControls;
