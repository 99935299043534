import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, UiDialog as Dialog, Tab, Tabs, TabPanel } from 'sunwise-ui';

import { ShowErrors } from 'common/components';

import * as selectors from '../../selectors';

import Alerts from './Alerts';
import Footer from './Footer';
import Page1 from './Page1';
import Page2 from './Page2';

const FormContainer = ({
    callback,
    defaultCurrency,
    errors,
    fetchTemplates,
    initialValues,
    onHide,
    save,
    show = false,
    templates,
}) => {
    const { t } = useTranslation();
    const [currentStep, setCurrentStep] = useState(0);

    const { control, handleSubmit, reset, watch } = useForm({
        defaultValues: initialValues,
    });
    const [alerts, disabled, id, isBright, isKam, isSerfimex] = watch([
        'alerts',
        'disabled',
        'id',
        'is_bright',
        'is_kam',
        'is_serfimex',
    ]);

    const handleStepChange = (step) => {
        const elements = document.getElementsByClassName('modal');
        if (elements.length > 0) {
            elements[0].scrollTo(0, 0);
        }
        setCurrentStep(step);
    };

    const handleOnClickComplete = (values) => save(values, callback);

    const [selectedTab, setSelectedTab] = useState('page1');

    const handleChange = (_, newValue) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        reset(initialValues);
    }, [show]);

    return (
        <Dialog
            open={show}
            onClose={() => onHide()}
            onExited={() => {
                setSelectedTab('page1');
                setCurrentStep(0);
            }}
            title={t('Enter your product data')}
            footerComponent={() => (
                <Footer
                    onHide={() => onHide()}
                    disabled={disabled}
                    isNew={Boolean(id)}
                    step={currentStep}
                    totalSteps={1}
                    onSetCurrentStep={handleStepChange}
                    onComplete={handleSubmit(handleOnClickComplete)}
                />
            )}
        >
            <form>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTab} onChange={handleChange}>
                        <Tab
                            key="tab-page1"
                            id="tab-page1"
                            label={t('Parameter', { count: 2 })}
                            value="page1"
                        />
                        <Tab
                            key="tab-page2"
                            id="tab-page2"
                            label={t('Product', { count: 2 })}
                            value="page2"
                        />
                        <Tab
                            key="alerts"
                            id="alerts"
                            label={t('Alert', { count: 2 })}
                            value="alerts"
                        />
                    </Tabs>
                </Box>

                <TabPanel
                    key="tab-page1"
                    selectedTab={selectedTab}
                    value="page1"
                >
                    <Page1
                        control={control}
                        defaultCurrency={defaultCurrency}
                        disabled={disabled}
                        fetchTemplates={fetchTemplates}
                        isBright={isBright}
                        isKam={isKam}
                        isSerfimex={isSerfimex}
                        templates={templates}
                    />
                </TabPanel>

                <TabPanel
                    key="tab-page2"
                    selectedTab={selectedTab}
                    value="page2"
                >
                    <Page2 control={control} disabled={disabled} />
                </TabPanel>

                <TabPanel key="alerts" selectedTab={selectedTab} value="alerts">
                    <Alerts
                        alerts={alerts}
                        control={control}
                        disabled={disabled}
                    />
                </TabPanel>

                <ShowErrors errors={errors} />
            </form>
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
});

FormContainer.propTypes = {
    callback: PropTypes.func,
    defaultCurrency: PropTypes.object,
    errors: PropTypes.array,
    fetchTemplates: PropTypes.func,
    initialValues: PropTypes.object,
    onHide: PropTypes.func,
    save: PropTypes.func,
    show: PropTypes.bool,
    templates: PropTypes.array,
};

export default connect(mapStateToProps, null)(FormContainer);
