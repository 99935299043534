import i18next from 'i18next';

import { uploadDocument } from 'common/api/v1/historicalDocument';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import * as afterSalesSettingsSelectors from '../../afterSalesSettings/selectors';
import {
    UPLOAD_FILE,
    UPLOAD_FILE_FAILURE,
    UPLOAD_FILE_SUCCESS,
} from '../actionTypes';
import { UPLOAD_ORIGINS } from '../constants';
import { transformAfterSalesDateToSunwiseFormat } from '../helpers';
import { actions } from '../reducer';

import handleIntegrationsInfo from './handleIntegrationsInfo';

export default ({ file, getValues }) =>
    (dispatch, getState) => {
        if (!file) return;

        dispatch(actions[UPLOAD_FILE]());

        dispatch(
            alerts.actions.show({
                confirmText: i18next.t('Back'),
                hideButtons: true,
                messages: [
                    i18next.t(
                        'Please wait a moment as the information is processed',
                    ),
                ],
                title: i18next.t('Loading').concat('...'),
                type: alerts.ALERT_TYPE_ALERT,
                variant: 'info',
            }),
        );

        const state = getState();

        const afterSalesSettingsId =
            afterSalesSettingsSelectors.getAfterSalesSettingsDataId(state);

        uploadDocument(afterSalesSettingsId, file)
            .then((response) => {
                const data = response?.data?.data;

                dispatch(
                    handleIntegrationsInfo({
                        data,
                        file: data?.url,
                        finalDate: transformAfterSalesDateToSunwiseFormat(
                            data?.values?.final_date,
                        ),
                        getValues,
                        initialDate: transformAfterSalesDateToSunwiseFormat(
                            data?.values?.initial_date,
                        ),
                        uploadOrigin: UPLOAD_ORIGINS.XML,
                    }),
                );

                dispatch(alerts.actions.close());
                dispatch(actions[UPLOAD_FILE_SUCCESS]());
            })
            .catch((error) => {
                dispatch(actions[UPLOAD_FILE_FAILURE]());
                dispatch(alerts.actions.close());
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
