import {
    createMonitoringConfigs,
    saveMonitoringConfigs,
} from 'common/api/v1/proposals';
import showToast from 'common/utils/showToast';

import {
    INITIALIZE_SUNPAL_CONFIG,
    SAVE_SUNPAL_CONFIG,
    SAVE_SUNPAL_CONFIG_FAILURE,
    SAVE_SUNPAL_CONFIG_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (values) => (dispatch) => {
    dispatch(actions[SAVE_SUNPAL_CONFIG]());

    const { id = null } = values;

    const save = !id
        ? () => createMonitoringConfigs(values)
        : () => saveMonitoringConfigs(id, values);

    save()
        .then((response) => {
            const data = response.data.data;
            dispatch(actions[SAVE_SUNPAL_CONFIG_SUCCESS](data));
            dispatch(actions[INITIALIZE_SUNPAL_CONFIG](values));
            showToast();
        })
        .catch((error) =>
            dispatch(
                actions[SAVE_SUNPAL_CONFIG_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
