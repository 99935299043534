import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Grid } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect2,
} from 'common/components/form/bootstrap';
import ZapAccessRestrictedBanner from 'common/modules/zap/components/ZapAccessRestrictedBanner';
import yupResolver from 'common/utils/yupResolver';

import * as supplierIntegrationsSelectors from '../../../../modules/supplierIntegrations/selectors';
import * as actions from '../actions';
import * as selectors from '../selectors';
import validateRpu from '../validateRpu';

const ZapForm = ({
    addRpu,
    addRpuText,
    credentials,
    customRender,
    disabled,
    fetchCredentials,
    getRpusByServiceNumber,
    hiddenFields,
    initialValues,
    isLoading,
    isLoadingCredentials,
    onCancel,
    rpusData,
    variant = 'filled',
}) => {
    const { t } = useTranslation();
    const {
        control,
        formState: { isDirty },
        getValues,
        handleSubmit,
        reset,
        setValue,
        watch,
    } = useForm({
        defaultValues: {
            cfe_user: '',
            credential_id: '',
            rpu_id: '',
            service_number: '',
        },
        resolver: yupResolver(validateRpu),
    });

    const serviceNumber = watch('service_number');
    const { data: rpus, loading: isFetchingRpus } =
        rpusData?.[serviceNumber] || {};

    useEffect(() => {
        if (initialValues) {
            reset(initialValues);

            if (initialValues.service_number !== serviceNumber)
                getRpusByServiceNumber(initialValues.service_number);
        }
    }, [initialValues]);

    useEffect(() => {
        if (fetchCredentials) fetchCredentials();
    }, [fetchCredentials]);

    useEffect(() => {
        const currentCredential = getValues('credential_id');

        if (credentials?.length && !currentCredential) {
            const [firstCredential] = credentials;
            setValue('credential_id', firstCredential.value);
        }
    }, [credentials]);

    useEffect(() => {
        if (!rpus?.length) return;

        const item = rpus.find((rpu) => rpu.credential_id) || rpus[0];

        setValue('cfe_user', item.cfe_user);
        setValue('credential_id', item.credential_id);
        setValue('last_payment', item.last_payment);
        setValue('rpu_id', item.id);
    }, [rpus]);

    if (customRender)
        return customRender({
            control,
            handleSubmit,
            isFetchingRpus,
            isLoading,
            rpus,
        });

    const isFieldHidden = (fieldName) =>
        hiddenFields?.length && hiddenFields.includes(fieldName);

    const isDisabled = isLoading || isFetchingRpus || disabled;

    const hasOddFields = (hiddenFields?.length || 0) % 2 === 0;

    return (
        <Grid container alignItems="flex-start">
            {!isFieldHidden('service_number') && (
                <Grid size={{ md: 9, xs: 18 }}>
                    <ReactHookFormInput
                        control={control}
                        disabled={isLoading || isFetchingRpus}
                        label={`${t('Service number')} *`}
                        name="service_number"
                        onBlur={(e) => getRpusByServiceNumber(e.target.value)}
                        onlyIntegerNumbers
                        placeholder="111222333444"
                        variant={variant}
                    />
                </Grid>
            )}

            {!isFieldHidden('cfe_user') && (
                <Grid size={{ md: 9, xs: 18 }}>
                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled || rpus?.length > 0}
                        label={`${t('Service name')} *`}
                        name="cfe_user"
                        variant={variant}
                    />
                </Grid>
            )}

            {!isFieldHidden('credential_id') && (
                <Grid size={{ md: 9, xs: 18 }}>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={isLoadingCredentials || isDisabled}
                        label={`${t('Link credential')} *`}
                        name="credential_id"
                        options={[{ label: '', options: credentials }]}
                        variant={variant}
                    />
                </Grid>
            )}

            {!isFieldHidden('rpu_id') && (
                <Grid size={{ md: 9, xs: 18 }}>
                    <ReactHookFormInput
                        control={control}
                        disabled
                        label={`${t('Integration Id')}`}
                        name="rpu_id"
                        variant={variant}
                    />
                </Grid>
            )}

            {!isFieldHidden('last_payment') && (
                <Grid size={{ md: 9, xs: 18 }}>
                    <ReactHookFormIntlNumberInput
                        allowDecimals={false}
                        allowNegativeValue={false}
                        control={control}
                        disabled={isDisabled || rpus?.length > 0}
                        fullWidth
                        label={t('Last payment')}
                        name="last_payment"
                        placeholder="0"
                        prepend="$"
                        variant={variant}
                    />
                </Grid>
            )}

            <Grid
                alignItems="center"
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }}
                gap={2}
                size={{ md: hasOddFields ? 9 : 18, xs: 18 }}
                sx={{ '& button': { m: '0 !important' } }}
            >
                <Button
                    disabled={isLoading || isFetchingRpus || !isDirty}
                    onClick={handleSubmit(addRpu)}
                    sx={{ flex: 1, width: '100%' }}
                    type="button"
                >
                    {addRpuText || t('Search')}
                </Button>

                {onCancel && (
                    <Button
                        color="secondary"
                        disabled={isLoading || isFetchingRpus}
                        onClick={onCancel}
                        sx={{ flex: 1, width: '100%' }}
                        type="button"
                        variant="outlined"
                    >
                        {t('Cancel')}
                    </Button>
                )}
            </Grid>
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    credentials: selectors.getCredentialsForSelect,
    isLoadingCredentials: selectors.getCredentialsIsLoading,
    rpusData: selectors.getRpus,
    zapIntegration: supplierIntegrationsSelectors.getZapIntegration,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCredentials: () => dispatch(actions.fetchCredentials()),
    getRpusByServiceNumber: (serviceNumber) =>
        dispatch(actions.getRpusByServiceNumber(serviceNumber)),
});

ZapForm.propTypes = {
    addRpu: PropTypes.func,
    addRpuText: PropTypes.string,
    credentials: PropTypes.array,
    customRender: PropTypes.func,
    disabled: PropTypes.bool,
    fetchCredentials: PropTypes.func,
    getRpusByServiceNumber: PropTypes.func,
    hiddenFields: PropTypes.array,
    initialValues: PropTypes.object,
    isLoading: PropTypes.bool,
    isLoadingCredentials: PropTypes.bool,
    onCancel: PropTypes.func,
    rpusData: PropTypes.object,
    variant: PropTypes.string,
};

const RestrictedForm = ({ zapIntegration, ...props }) => {
    if (!zapIntegration?.id) return <ZapAccessRestrictedBanner />;
    return <ZapForm {...props} />;
};

RestrictedForm.propTypes = {
    zapIntegration: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictedForm);
