import { create } from 'common/api/v1/reportCard';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import fetchUserSettings from '../../profile/actions/fetchProfileData';
import {
    CREATE_REPORT,
    CREATE_REPORT_FAILURE,
    CREATE_REPORT_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (values, successCallback) => (dispatch) => {
    dispatch(actions[CREATE_REPORT]());

    const newValues = {
        historical: values.historical_id,
        name: values.name,
        project: values.project,
    };

    create(newValues)
        .then((response) => {
            const data = response?.data?.data;
            dispatch(actions[CREATE_REPORT_SUCCESS](data));
            dispatch(fetchUserSettings());
            if (successCallback) successCallback(data);
        })
        .catch((error) => {
            dispatch(actions[CREATE_REPORT_FAILURE](error?.response));
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
