import React from 'react';

import PropTypes from 'prop-types';

import { validatedButton } from '../helpers';

import PricingAftersalesDetails from './PricingAftersalesDetails';
import PricingBundleDetails from './PricingBundleDetails';
import PricingDetailsSales from './PricingDetailsSales';

const PricingDetails = ({
    fromLanding,
    handleCheckout,
    noSubscriptions,
    pricing,
    selectedProductId,
    subscription,
    variant,
}) => {
    const buttonProps = validatedButton({
        fromLanding,
        noSubscriptions,
        selectedProductId,
        subscription,
        pricing,
    });

    switch (selectedProductId) {
        case 'sales':
            return (
                <PricingDetailsSales
                    buttonProps={buttonProps}
                    onClick={handleCheckout}
                    pricing={pricing}
                    variant={variant}
                />
            );
        case 'aftersales':
            return (
                <PricingAftersalesDetails
                    buttonProps={buttonProps}
                    onClick={handleCheckout}
                    pricing={pricing}
                    variant={variant}
                />
            );
        case 'bundle':
            return (
                <PricingBundleDetails
                    buttonProps={buttonProps}
                    onClick={handleCheckout}
                    pricing={pricing}
                    variant={variant}
                />
            );
        default:
            // Caso por defecto ahora es Sales
            return (
                <PricingDetailsSales
                    buttonProps={buttonProps}
                    onClick={handleCheckout}
                    pricing={pricing}
                    variant={variant}
                />
            );
    }
};

PricingDetails.propTypes = {
    fromLanding: PropTypes.bool,
    handleCheckout: PropTypes.func,
    noSubscriptions: PropTypes.bool,
    pricing: PropTypes.object,
    selectedProductId: PropTypes.string,
    subscription: PropTypes.object,
    variant: PropTypes.string,
};

export default PricingDetails;
