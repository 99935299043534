import i18next from 'i18next';
import { get, isEmpty, toNumber } from 'lodash';

import { INSURANCE_TYPE } from 'common/constants';
import { getDecimals } from 'common/utils/helpers';

export const getAllianceProductsDisctionary = (allianceProducts) => {
    return allianceProducts.reduce((acc, current) => {
        let temp = { ...acc };
        temp[current.id] = current;
        return temp;
    }, {});
};

export const getFinancierProducts = (
    allianceProducts,
    typesBusinessCompany,
) => {
    if (typesBusinessCompany) {
        return allianceProducts.filter(
            (item) => item.types_business_company === typesBusinessCompany,
        );
    }
    return [];
};

export const getFinancierProductsToSelect = (financierProducts) =>
    financierProducts.map((item) => ({
        label: item.name,
        value: item.id,
    }));

export const getFinancierProductSelected = (
    financialProductParameters,
    financialProducts,
) => {
    const id = get(financialProductParameters, 'product_financial_plan', null);
    if (id) return financialProducts.find((item) => item.id === id);
    return {};
};

export const getTotalWithDealerFee = (dealer_fee, total) => {
    const dealerFee = toNumber(dealer_fee);
    if (dealerFee !== 0) return total * (1 + dealer_fee / 100);
    return total;
};

export const getIsDisabledApplyButton = (
    errors,
    formValues,
    isLocked,
    parameters,
    step,
    typesBusinessCompany,
) => {
    const hasCommisions =
        hasCommissionDict(parameters) || hasRateDict(parameters);
    if (isLocked || errors.length > 0) {
        return true;
    }

    if (
        (step == 1 && isEmpty(typesBusinessCompany)) ||
        (step == 1 && isEmpty(formValues.financier_product))
    ) {
        return true;
    }

    if (step === 2 && isEmpty(formValues.term)) {
        return true;
    }
    if (step === 2 && hasCommisions && isEmpty(formValues.hitch_percentage)) {
        return true;
    }

    if (
        step === 2 &&
        !hasCommisions &&
        formValues.is_percentage == 'true' &&
        isEmpty(formValues.hitch_percentage)
    ) {
        return true;
    }

    if (
        step === 2 &&
        !hasCommisions &&
        formValues.is_percentage == 'false' &&
        isEmpty(formValues.hitch)
    ) {
        return true;
    }
    return false;
};

export const hasCommissionDict = (parameters) => {
    return !isEmpty(get(parameters, 'commission_dict', {}));
};

export const hasRateDict = (parameters) => {
    return !isEmpty(get(parameters, 'rate_dict', {}));
};

export const getHitchToSelect = (hasCommissionDict, parameters) => {
    const dictionary = hasCommissionDict
        ? get(parameters, 'commission_dict', {})
        : get(parameters, 'rate_dict', {});

    const keys = Object.keys(dictionary);

    return keys.map((key) => ({ value: key, label: `${key}%` }));
};

export const getInsuranceConfig = (product) => {
    return get(
        product,
        `financier_product_insurance[0].insurance_product_params`,
        [],
    ).map((value) => ({
        id: value.id,
        value_type: value.value_type,
        type_cost: value.type_cost,
        description: value.description,
        amount: value.amount,
        tax_included: value.tax_included,
    }));
};

export const getLabelButton = ({ isSaving, step }) => {
    if (step === 1) {
        return i18next.t('Continue');
    }
    if (isSaving) {
        return i18next.t('Saving').concat('...');
    }
    return i18next.t('Apply to proposal');
};

export const getTypeBusinessOptions = (allianceProducts) => {
    // Definir los tipos de negocio base con etiquetas y productos
    const typeBusinessOptions = {
        physical_person: {
            label: i18next.t('Physical person'),
            products: [],
        },
        person_company: {
            label: i18next.t('Physical person with activity'),
            products: [],
        },
        company: {
            label: i18next.t('Moral person'),
            products: [],
        },
    };

    // Rellenar los productos en cada tipo de negocio
    allianceProducts.forEach((product) => {
        typeBusinessOptions[product.types_business_company].products.push({
            label: product.name,
            value: product.id,
        });
    });

    // Construir las opciones para el selector, filtrando tipos sin productos
    const selectOptions = Object.keys(typeBusinessOptions)
        .filter((key) => !isEmpty(typeBusinessOptions[key].products))
        .map((key) => ({
            label: typeBusinessOptions[key].label,
            value: key,
        }));

    // Devolver el arreglo final con la opción inicial
    return [
        {
            label: i18next.t('Select a regimen'),
            value: '',
            disabled: true,
        },
        ...selectOptions,
    ];
};

export const isDisabledButton = (financierType, registered, requirements) => {
    if (requirements.content.length === 0 && requirements.required) {
        return true;
    }
    if (financierType === INSURANCE_TYPE && !registered) {
        return true;
    }
    return false;
};

export const handleOnChangeTermOrHitch = ({
    parameters,
    setValue,
    term,
    hitch,
}) => {
    if (parameters && !isEmpty(parameters.rate_dict)) {
        const rate = parameters.rate_dict[hitch][term];
        setValue('rate', rate ? rate : 0);
    }

    if (parameters && !isEmpty(parameters.commission_dict)) {
        const commission = parameters?.commission_dict[hitch][term];
        if (commission) setValue('extra_commission', commission);
    }
};

export const handleOnChangeFinancierProduct = ({
    financierProducts,
    resetErrors = null,
    setProductSelected,
    setValue,
    value,
}) => {
    const allianceProductsDictionary =
        getAllianceProductsDisctionary(financierProducts);
    const product = allianceProductsDictionary[value];
    setProductSelected(product);
    setValue('financier_product_insurance', getInsuranceConfig(product));
    setValue('term', '');
    if (resetErrors) resetErrors();
};

export const handleOnChangeRegimen = ({
    allianceProducts,
    resetErrors = null,
    setProductSelected,
    setValue,
    value,
}) => {
    // Limpiar campos relacionados mientras se selecciona un nuevo producto
    setValue('financier_product_insurance', []);
    setValue('financier_product', '');
    setValue('term', '');
    if (resetErrors) resetErrors();

    // Obtener los productos disponibles para el nuevo tipo de régimen
    const financierProducts = getFinancierProducts(allianceProducts, value);

    // Validar si hay productos disponibles
    if (financierProducts.length === 1) {
        const firstProduct = financierProducts[0];
        const productId = firstProduct.id;

        // Seleccionar el primer producto usando el método existente
        handleOnChangeFinancierProduct({
            financierProducts,
            resetErrors: null,
            setProductSelected,
            setValue,
            value: productId,
        });
    } else {
        // Si no hay productos, reiniciar a un estado vacío
        setProductSelected({});
    }
};

export const percentageToValue = (isPercentage, value, total) => {
    if (!isPercentage) return toNumber(value);
    const newValue = total * (toNumber(value) / 100);
    return newValue.toFixed(getDecimals(newValue));
};

export const valueToPercentage = (isPercentage, value, total) => {
    if (isPercentage) return toNumber(value);
    return toNumber((toNumber(value) / total) * 100);
};

export const updateFormWithProduct = (
    allianceProducts,
    typesBusinessOptions,
    setValue,
    setProductSelected,
) => {
    if (!isEmpty(allianceProducts)) {
        const typesBusiness = typesBusinessOptions.slice(1);
        let initialFinancierProducts = [];
        if (typesBusiness.length === 1) {
            const initialTypeBusiness = typesBusiness[0]?.value || '';
            setValue('types_business_company', initialTypeBusiness);
            initialFinancierProducts = getFinancierProducts(
                allianceProducts,
                initialTypeBusiness,
            );
        }
        if (initialFinancierProducts?.length === 1) {
            const initialProduct = initialFinancierProducts[0];
            const value = initialProduct.id;
            handleOnChangeFinancierProduct({
                financierProducts: initialFinancierProducts,
                resetErrors: null,
                setProductSelected,
                setValue,
                value,
            });
        }
    }
};

export const initializeForm = (
    alliance,
    isSerfimex,
    productSelected,
    setValue,
    total,
    typesBusinessCompany,
) => {
    if (!isEmpty(productSelected)) {
        const parameters = get(
            productSelected,
            'financier_product_parameters',
            {},
        );

        let terms = [];
        if (parameters?.terms) {
            terms = parameters.terms.split(',').map((item) => ({
                label: `${item} ${i18next.t('Month', { count: parseInt(item) })}`,
                value: item,
            }));
        }

        const totalWithDealerFee = getTotalWithDealerFee(
            parameters?.dealer_fee,
            total,
        );

        let newValues = {
            amount: total,
            annual_increase: parameters?.annual_increase,
            commission_credit: valueToPercentage(
                parameters.is_commission_percentage,
                parameters.commission_credit,
                totalWithDealerFee,
            ),
            dealer_fee: parameters.dealer_fee,
            financier_installer: alliance?.id,
            financier_product: parameters.financier_product,
            hitch_percentage: valueToPercentage(
                parameters.is_percentage,
                parameters.hitch,
                totalWithDealerFee,
            ),
            hitch: percentageToValue(
                parameters.is_percentage,
                parameters.hitch,
                totalWithDealerFee,
            ),
            insurance: parameters.insurance,
            is_commission_percentage: hasCommissionDict(parameters)
                ? true
                : parameters.is_commission_percentage,
            is_percentage: parameters.is_percentage.toString(),
            is_serfimex: isSerfimex,
            rate: parameters.rate,
            residual: parameters.residual,
            term: isSerfimex ? 0 : parameters.term || '',
            termsToSelect: terms || [],
            total,
            total_with_dealer_fee: totalWithDealerFee,
            types_business_company: typesBusinessCompany,
            parameters: parameters,
        };

        // Conservar valores existentes de hitch y hitch_percentage si están definidos
        const currentHitch = get(productSelected, 'hitch', null);
        const currentHitchPercentage = get(
            productSelected,
            'hitch_percentage',
            null,
        );
        if (currentHitch !== null) newValues.hitch = currentHitch;
        if (currentHitchPercentage !== null)
            newValues.hitch_percentage = currentHitchPercentage;

        // Establecer los valores en el formulario
        Object.entries(newValues).forEach(([key, value]) =>
            setValue(key, value),
        );
    }
};

export const initializeFormWithProduct = (
    productSelected,
    allianceId,
    isSerfimex,
    total,
    initializeCibanco,
) => {
    if (!isEmpty(productSelected)) {
        initializeCibanco({
            ...productSelected,
            financier_installer: allianceId,
            is_serfimex: isSerfimex,
            total,
        });
    }
};

export const initializeFormWithFinancialProduct = (
    financialProduct,
    allianceProducts,
    allianceId,
    isSerfimex,
    total,
    initializeCibanco,
) => {
    if (!isEmpty(financialProduct)) {
        const product = allianceProducts.find(
            (item) => item.id === financialProduct?.financier_product?.id,
        );
        if (product) {
            initializeCibanco({
                ...product,
                financier_product: product.id,
                financier_installer: allianceId,
                hitch: financialProduct?.parameters?.hitch,
                hitch_percentage:
                    financialProduct?.parameters?.hitch_percentage,
                is_percentage:
                    financialProduct?.parameters?.is_percentage || false,
                is_serfimex: isSerfimex,
                total,
                term: financialProduct?.parameters?.term?.toString(),
                types_business_company: product?.types_business_company,
            });
        }
    }
};

const processAlert = ({
    alertItem,
    isKam,
    message,
    setErrorList,
    setInfoList,
}) => {
    if (isKam && alertItem?.is_active && alertItem?.requires_validation) {
        if (alertItem.type === 'restrictive') {
            setErrorList((prev) => {
                // Check if message already exists in the list
                if (prev.includes(message)) return prev;
                return [...prev, message];
            });
        } else if (alertItem.type === 'informative') {
            setInfoList((prev) => {
                // Check if message already exists in the list
                if (prev.includes(message)) return prev;
                return [...prev, message];
            });
        }
    }
};

export const updateErrorsAndAlerts = (
    alerts,
    annualIncrease,
    errors,
    increasePercentage,
    isKam,
    setErrorList,
    setInfoList,
) => {
    if (!isEmpty(errors)) {
        setErrorList((prev) => {
            // Filter out any errors that already exist in prev
            const newErrors = errors.filter((error) => !prev.includes(error));
            return [...prev, ...newErrors];
        });
    }

    if (!isEmpty(alerts)) {
        const commonProps = {
            isKam,
            setErrorList,
            setInfoList,
        };

        processAlert({
            ...commonProps,
            alertItem: alerts.supplier_VAT,
            message: i18next.t(
                'To apply this product, the tax rate must be zero',
            ),
        });
        processAlert({
            ...commonProps,
            alertItem: alerts.tax_incentive,
            message: i18next.t(
                'To apply this product, the tax incentive must be disabled',
            ),
        });
        processAlert({
            ...commonProps,
            alertItem: alerts.supplier_annual_increase,
            message: i18next.t(
                'It is recommended that the increase of the supplier ({{value1}}%) be greater than or equal to the increase of the PPA Rate ({{value2}}%)',
                { value1: increasePercentage, value2: annualIncrease },
            ),
        });
        processAlert({
            ...commonProps,
            alertItem: alerts.OPEX,
            message: i18next.t(
                'To apply this product, the OPEX must be disabled',
            ),
        });
    }
};
