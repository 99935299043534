import { downloadFileFromName as downloadLisaFile } from 'common/modules/lisaFiles/actions';
import { downloadFileFromName as downloadZapFile } from 'common/modules/zap/actions';
import { handleDownloadSunwiseFile } from 'common/utils/helpers';

import { UPLOAD_ORIGINS } from '../constants';

export default ({ name, uploadOrigin, url } = {}) =>
    (dispatch) => {
        if (!url) return;

        switch (uploadOrigin) {
            case UPLOAD_ORIGINS.LISA:
                dispatch(downloadLisaFile(url));
                break;
            case UPLOAD_ORIGINS.ZAP:
                dispatch(downloadZapFile(url));
                break;
            default:
                handleDownloadSunwiseFile(url, name);
        }
    };
