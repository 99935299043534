import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { BottomActions, Box, Button, Grid } from 'sunwise-ui';

import { TextVariant } from 'common/components';
import {
    ReactHookFormInput,
    ReactHookFormSelect2,
} from 'common/components/form/bootstrap';
import { getIsMexicanAccount } from 'common/utils/helpers/session';
import yupResolver from 'common/utils/yupResolver';

import * as projectConsumptionsModalActions from '../../../projectConsumptionsModal/actions';
import * as actions from '../../actions';
import commercialOffervalidate from '../../commercialOffervalidate';
import * as selectors from '../../selectors';

const FormModal = ({
    consumptionEnergy,
    CustomFooter,
    fetchDivisions,
    fetchLocationsByZipCode,
    fetchPlans,
    fetchPoliticalDivisions,
    fetchPoliticalDivisions2,
    fetchRateDivisions,
    fetchRates,
    fetchRegionsByPoliticalDivision,
    initialValues,
    isFetchingDivisions,
    isFetchingLocationsByZip,
    isFetchingPlans,
    isFetchingPoliticalDivisions,
    isFetchingPoliticalDivisions2,
    isSavingOffer,
    locationsByZipCode,
    plansForSelect,
    politicalDivisions2ForSelect,
    politicalDivisionsForSelect,
    projectId,
    saveConsumptionsZero,
    submitCommercialOfferForm,
}) => {
    const { t } = useTranslation();
    const [timer, setTimer] = useState(null);
    const handleChange = (callback) => {
        clearTimeout(timer);
        setTimer(setTimeout(() => callback(), 2000));
    };

    const { control, getValues, handleSubmit, reset, setValue, watch } =
        useForm({
            defaultValues: initialValues,
            resolver: yupResolver(commercialOffervalidate),
        });
    const branchOffice = watch('branch_office');

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        if (isEmpty(consumptionEnergy)) {
            setValue('without_consumptions', true);
            fetchDivisions();
            fetchPoliticalDivisions('dp1');
            fetchRates();
        }
    }, [consumptionEnergy]);

    useEffect(() => {
        if (branchOffice) fetchPlans(branchOffice);
    }, [branchOffice]);

    const isMexicanAccount = getIsMexicanAccount();

    const renderCustomFooter = ({ getValues, handleSubmit }) => {
        if (CustomFooter)
            return (
                <CustomFooter
                    getValues={getValues}
                    handleSubmit={handleSubmit}
                />
            );
    };

    return (
        <form
            onSubmit={handleSubmit((values) => {
                if (isEmpty(consumptionEnergy)) {
                    saveConsumptionsZero(values, () => {
                        submitCommercialOfferForm(values, projectId);
                    });
                } else submitCommercialOfferForm(values, projectId);
            })}
        >
            <Grid container>
                <Grid size={{ xs: 'grow' }}>
                    <ReactHookFormInput
                        control={control}
                        name="name"
                        label={t('Proposal name').concat(' *')}
                    />

                    <ReactHookFormSelect2
                        control={control}
                        disabled={isFetchingPlans || !branchOffice}
                        label={t('Plan')}
                        name="proposal_plan"
                        options={plansForSelect}
                    />

                    {isEmpty(consumptionEnergy) && (
                        <>
                            <Box sx={{ mb: 2 }}>
                                <TextVariant
                                    text={t('About the location')}
                                    variant="bold"
                                />
                            </Box>
                            <ReactHookFormInput
                                control={control}
                                id="zip_code"
                                name="zip_code"
                                onChange={({ target: { value } }) => {
                                    setValue('zip_code', value);
                                    handleChange(() => {
                                        setValue('political_division1', '');
                                        setValue('political_division2', '');
                                        fetchLocationsByZipCode(
                                            value,
                                            setValue,
                                        );
                                    });
                                }}
                                placeholder={t('Zip code')}
                            />

                            <ReactHookFormSelect2
                                control={control}
                                disabled={
                                    isFetchingPoliticalDivisions ||
                                    isFetchingLocationsByZip
                                }
                                id="political_division1"
                                isClearable={false}
                                isLoading={isFetchingPoliticalDivisions}
                                label={`${
                                    isMexicanAccount
                                        ? t('State')
                                        : t('Political division of order 1')
                                } *`}
                                placeholder={`${
                                    isMexicanAccount
                                        ? t('State')
                                        : t('Political division of order 1')
                                } *`}
                                name="political_division1"
                                onChange={(value) => {
                                    setValue('political_division2', '');
                                    setValue('rate_division', '');
                                    if (
                                        value === `${t('State')} *` ||
                                        value ===
                                            `${t(
                                                'Political division of order 1',
                                            )} *`
                                    )
                                        return;

                                    fetchLocationsByZipCode('', setValue);
                                    fetchRateDivisions(value, setValue);
                                    fetchRegionsByPoliticalDivision(
                                        value,
                                        setValue,
                                    );
                                    fetchPoliticalDivisions2(value);
                                }}
                                options={[
                                    {
                                        label: '',
                                        options: politicalDivisionsForSelect,
                                    },
                                ]}
                            />

                            <ReactHookFormSelect2
                                control={control}
                                disabled={
                                    isFetchingPoliticalDivisions ||
                                    isFetchingPoliticalDivisions2 ||
                                    isFetchingLocationsByZip
                                }
                                id="political_division2"
                                isClearable={false}
                                isLoading={isFetchingPoliticalDivisions2}
                                label={`${
                                    isMexicanAccount
                                        ? t('City')
                                        : t('Political division of order 2')
                                } *`}
                                placeholder={`${
                                    isMexicanAccount
                                        ? t('City')
                                        : t('Political division of order 2')
                                } *`}
                                name="political_division2"
                                options={[
                                    {
                                        label: '',
                                        options: !isEmpty(locationsByZipCode)
                                            ? locationsByZipCode
                                            : politicalDivisions2ForSelect,
                                    },
                                ]}
                            />
                        </>
                    )}
                </Grid>
            </Grid>

            {renderCustomFooter({ getValues, handleSubmit })}

            {!CustomFooter && (
                <BottomActions>
                    <Box mt={2}>
                        <Button
                            dataIntercomTarget="Create Proposal (area)"
                            disabled={
                                isFetchingDivisions ||
                                isFetchingLocationsByZip ||
                                isFetchingPlans ||
                                isFetchingPoliticalDivisions ||
                                isFetchingPoliticalDivisions2 ||
                                isSavingOffer
                            }
                            type="submit"
                        >
                            {isSavingOffer
                                ? t('Saving').concat('...')
                                : t('Continue')}
                        </Button>
                    </Box>
                </BottomActions>
            )}
        </form>
    );
};
const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getCommercialOfferInitialValues,
    isFetchingDivisions: selectors.getIsFetchingDivisions,
    isFetchingLocationsByZip: selectors.getIsFetchingLocationsByZipCode,
    isFetchingPlans: selectors.getIsFetchingPlans,
    isFetchingPoliticalDivisions2: selectors.getIsFetchingPoliticalDivisions2,
    isFetchingPoliticalDivisions: selectors.getIsFetchingPoliticalDivisions,
    isSavingOffer: selectors.getCreateOfferIsSaving,
    locationsByZipCode: selectors.getLocationsByZipCodeData,
    plansForSelect: selectors.getPlansForSelect,
    politicalDivisions2ForSelect: selectors.getPoliticalDivisions2ForSelect,
    politicalDivisionsForSelect: selectors.getPoliticalDivisionsForSelect,
});

const mapDispatchToProps = (dispatch) => ({
    fetchDivisions: () => dispatch(actions.fetchDivisions()),
    fetchPoliticalDivisions: (str) =>
        dispatch(actions.fetchPoliticalDivisions(str)),
    fetchPoliticalDivisions2: (str) =>
        dispatch(actions.fetchPoliticalDivisions2(str)),
    fetchLocationsByZipCode: (zipCode, setValue) =>
        dispatch(actions.fetchLocationsByZipCode(zipCode, setValue)),
    fetchPlans: (branchOffice) => dispatch(actions.fetchPlans(branchOffice)),
    fetchRateDivisions: (id, setValue) =>
        dispatch(actions.fetchRateDivisions(id, null, setValue)),
    fetchRates: () => dispatch(projectConsumptionsModalActions.fetchRates()),
    fetchRegionsByPoliticalDivision: (id, setValue) =>
        dispatch(actions.fetchRegionsByPoliticalDivision(id, setValue)),
    saveConsumptionsZero: (values, onSuccessCallback) =>
        dispatch(actions.saveConsumptionsZero(values, onSuccessCallback)),
    submitCommercialOfferForm: (values, projectId) =>
        dispatch(actions.submitCommercialOfferForm(values, projectId)),
});

FormModal.propTypes = {
    consumptionEnergy: PropTypes.object,
    CustomFooter: PropTypes.func,
    fetchDivisions: PropTypes.func,
    fetchLocationsByZipCode: PropTypes.func,
    fetchPlans: PropTypes.func,
    fetchPoliticalDivisions2: PropTypes.func,
    fetchPoliticalDivisions: PropTypes.func,
    fetchRateDivisions: PropTypes.func,
    fetchRates: PropTypes.func,
    fetchRegionsByPoliticalDivision: PropTypes.func,
    initialValues: PropTypes.object,
    isFetchingDivisions: PropTypes.bool,
    isFetchingLocationsByZip: PropTypes.bool,
    isFetchingPlans: PropTypes.bool,
    isFetchingPoliticalDivisions2: PropTypes.bool,
    isFetchingPoliticalDivisions: PropTypes.bool,
    isSavingOffer: PropTypes.bool,
    locationsByZipCode: PropTypes.array,
    plansForSelect: PropTypes.array,
    politicalDivisions2ForSelect: PropTypes.array,
    politicalDivisionsForSelect: PropTypes.array,
    projectId: PropTypes.string,
    saveConsumptionsZero: PropTypes.func,
    submitCommercialOfferForm: PropTypes.func,
};

const mergeProps = (stateProps, dispatchProps, ownProps) =>
    Object.assign({}, stateProps, dispatchProps, ownProps);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
)(FormModal);
