import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import {
    ReactHookFormCheck,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';

import * as afterSalesSettingsSelectors from '../../../afterSalesSettings/selectors';
import { getPPAPrice, getSectionHasErrors } from '../../helpers';

import FormAccordion from './FormAccordion';

const PPAFields = ({
    control,
    currency,
    disabled,
    errors,
    getValues,
    initialDate,
    ppaValues,
    setValue,
    startDate,
}) => {
    const { t } = useTranslation();
    const ppaActive = useWatch({
        control,
        name: 'ppa_active',
    });

    useEffect(() => {
        const price = getValues('ppa_price');
        const hasPrice = price || price === 0;
        if (!initialDate || !startDate || hasPrice) return;

        const newPPAPrice = getPPAPrice({
            initialDate,
            percentageIncrease: ppaValues?.percentageIncrease,
            price: ppaValues?.price,
            startDate,
        });

        setValue('ppa_price', newPPAPrice);
    }, [initialDate, startDate]);

    return (
        <FormAccordion
            hasFormErrors={getSectionHasErrors(errors, [
                'ppa_active',
                'ppa_price',
            ])}
            title="PPA"
        >
            <Grid container>
                <Grid
                    alignItems={{ sm: 'center', xs: 'flex-start' }}
                    display="flex"
                    flexDirection={{ sm: 'row', xs: 'column' }}
                    gap={{ sm: 2, xs: 0 }}
                    size={{ md: 9, xs: 18 }}
                >
                    <ReactHookFormCheck
                        control={control}
                        disabled={disabled}
                        label={t('Active')}
                        name="ppa_active"
                        sxFormControl={{ flexShrink: 0 }}
                        variant="standard"
                    />

                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        control={control}
                        disabled={disabled || !ppaActive}
                        fullWidth
                        label={t('Price')}
                        min={0}
                        name="ppa_price"
                        prepend={currency?.symbol}
                        variant="standard"
                    />
                </Grid>
            </Grid>
        </FormAccordion>
    );
};

const mapStateToProps = createStructuredSelector({
    currency: afterSalesSettingsSelectors.getAfterSalesSettingsCurrency,
    ppaValues: afterSalesSettingsSelectors.getAfterSalesSettingsPPAValues,
    startDate: afterSalesSettingsSelectors.getAfterSalesStartDate,
});

PPAFields.propTypes = {
    control: PropTypes.object,
    currency: PropTypes.object,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    getValues: PropTypes.func,
    initialDate: PropTypes.string,
    ppaValues: PropTypes.object,
    setValue: PropTypes.func,
    startDate: PropTypes.string,
};

export default connect(mapStateToProps)(PPAFields);
