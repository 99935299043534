import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

import { formatDate } from 'common/utils/dates';
import { generateColorTone } from 'common/utils/helpersChart';

const COLOR_DELTA = 25;
const ENERGY_COLOR = ['#20c4d9', '#8bc34a'];

export const buildDataOriginalConsumption = ({
    mappedTiers,
    tiersConsumptions,
}) => {
    const labels = [];
    const datasets = [];

    if (!tiersConsumptions) return { labels, datasets };

    const tiers = {};

    tiersConsumptions.forEach((entry, idx) => {
        const formattedDate = formatDate(new Date(entry.final_date), 'MMM yy');
        labels.push(formattedDate.toUpperCase());

        entry.consumption?.forEach(({ tier, consumption }, index) => {
            if (!tiers[tier]) {
                const color = generateColorTone(
                    ENERGY_COLOR[0],
                    index * COLOR_DELTA,
                );
                const tiernName = mappedTiers[tier] || tier;
                tiers[tier] = {
                    backgroundColor: color,
                    borderColor: color,
                    data: Array(labels.length).fill(0),
                    label: `${i18next.t('Original consumption')} - ${i18next.t(tiernName)} (kWh)`,
                    stack: 'original-consumption',
                    type: 'bar',
                };
            }

            tiers[tier].data[idx] = consumption;
        });
    });

    datasets.push(...Object.values(tiers));

    const chartData = {
        datasets: datasets.filter((item) => !isEmpty(item.data)),
        labels,
    };

    return chartData;
};

export const buildDataNewConsumption = ({ tiersConsumptions }) => {
    const labels = [];
    const datasets = [];
    const COLOR_DELTA = 12;

    if (!tiersConsumptions) return { labels, datasets };

    const tiers = {};

    tiersConsumptions?.forEach((item, idx) => {
        labels.push(item.label);

        if (!item.consumption) return;

        Object.keys(item.consumption).forEach((key, index) => {
            if (!tiers[key]) {
                const color = generateColorTone(
                    ENERGY_COLOR[1],
                    index * COLOR_DELTA,
                );
                tiers[key] = {
                    backgroundColor: color,
                    borderColor: color,
                    data: Array(labels.length).fill(0),
                    label: `${i18next.t('New consumption')} - ${key} (kWh)`,
                    stack: 'new-consumption',
                    type: 'bar',
                };
            }

            tiers[key].data[idx] = item.consumption[key];
        });
    });

    datasets.push(...Object.values(tiers));

    const chartData = {
        datasets: datasets.filter((item) => !isEmpty(item.data)),
        labels,
    };

    return chartData;
};

export const rebuildData = ({
    chartControlsConfig,
    chartId,
    consumptionProjection,
    data,
    proposalConfiguration,
    series,
} = {}) => {
    if (isEmpty(data) || isEmpty(series))
        return { finalData: { datasets: [], labels: [] }, finalSeries: [] };

    let finalData = data;
    let finalSeries = series;

    if (chartId !== 'chartEnergy' || chartControlsConfig.option !== 0)
        return { finalData, finalSeries };

    const currentTiers = proposalConfiguration?.current_tiers || {};
    const mappedTiers = Object.fromEntries(
        Object.entries(currentTiers).map((a) => a.reverse()),
    );

    const originalConsumptionCharData = buildDataOriginalConsumption({
        mappedTiers,
        tiersConsumptions: consumptionProjection.tiersConsumptions,
    });

    if (!originalConsumptionCharData.datasets.length)
        return { finalData, finalSeries };

    const newConsumption =
        consumptionProjection?.consumptionHistory?.[
            chartControlsConfig?.year
        ]?.map((item) => ({
            consumption: item.tieredConsumption,
            final_date: item.final_date,
            initial_date: item.initial_date,
            label: item.label,
        })) || [];

    const newConsumptionCharData = buildDataNewConsumption({
        tiersConsumptions: newConsumption,
    });

    const finalDataset = {
        datasets: [
            ...originalConsumptionCharData.datasets,
            ...newConsumptionCharData.datasets,
            ...data.datasets.filter(
                (item) =>
                    !['new-consumption', 'original-consumption'].includes(
                        item?.id,
                    ),
            ),
        ],
        labels: data.labels,
    };

    finalData = finalDataset;
    finalSeries = finalDataset.datasets
        .filter((item) => item?.label)
        .map((item) => ({ name: item?.label }));

    return { finalData, finalSeries };
};

export const rebuildOptions = ({
    currentChartConfig,
    tooltipConfig = { view: 'all' },
} = {}) => {
    const { view: tootltipView } = tooltipConfig;
    const { key, options } = currentChartConfig;
    const axis = tootltipView === 'nearest' ? 'xy' : 'x';
    const defaultMode = key === 'chartEconomic' ? 'point' : 'index';
    const enabled = tootltipView !== 'none';
    const mode = tootltipView === 'nearest' ? tootltipView : defaultMode;
    const position = 'nearest';

    const finalOptions = {
        ...options,
        interaction: { axis, intersect: false, mode: 'nearest' },
        plugins: {
            ...options.plugins,
            tooltip: {
                ...options.plugins?.tooltip,
                enabled,
                mode,
                position,
            },
        },
    };

    return { finalOptions };
};
