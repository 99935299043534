import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { types } from 'sunwise-template-core';
import { Box } from 'sunwise-ui';

import TabsSystem from 'common/components/TabsSystem';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import useBreakpoint from 'common/hooks/useBreakpoint';
import UpgradeIcon from 'common/layouts/defaultLayout/UpgradeIcon';
import RestrictedAccessLanding from 'common/modules/restrictedAccessLanding/Container';

import TemplateCarousel from './TemplateCarousel';
import TemplateTableSection from './TemplateTableSection';

const TabsContent = ({ commonProps, getPermissionsByCode }) => {
    const { t } = useTranslation();
    const [height, setHeight] = useState(3050);
    const [templateType, setTemplateType] = useState(types.ONE_PROPOSAL_TYPE);
    const breakpoint = useBreakpoint();

    useEffect(() => {
        switch (breakpoint) {
            case 'xs':
                setHeight(4390);
                break;
            case 'sm':
                setHeight(3700);
                break;
            case 'md':
                setHeight(2650);
                break;
            default:
                setHeight(3050);
                break;
        }
    }, [breakpoint]);

    const {
        canDelete: canDeleteProposalTemplates,
        canModify: canModifyProposalTemplates,
    } = getPermissionsByCode(PERMISSION_LIST.PROPOSAL_TEMPLATES_PERMISSION);
    const {
        canDelete: canDeleteSummaryTemplates,
        canModify: canModifySummaryTemplates,
    } = getPermissionsByCode(PERMISSION_LIST.SUMMARY_TEMPLATES_PERMISSION);
    const {
        canDelete: canDeleteSmartDocumentTemplates,
        canModify: canModifySmartDocumentTemplates,
    } = getPermissionsByCode(
        PERMISSION_LIST.SMART_DOCUMENT_TEMPLATES_PERMISSION,
    );
    const {
        canDelete: canDeleteReportTemplates,
        canModify: canModifyReportTemplates,
    } = getPermissionsByCode(PERMISSION_LIST.REPORT_TEMPLATES_PERMISSION);

    const renderCarousel = ({ canModify } = {}) => (
        <Box sx={{ mt: 2 }}>
            <TemplateCarousel
                branchSelected={commonProps.branchSelected}
                canModify={canModify}
                handleClickCreate={commonProps.handleClickCreate}
                selectedCountry={commonProps.companyCountry}
                templateType={templateType}
            />
        </Box>
    );

    const getTabs = () => [
        {
            id: 'proposals',
            label: (
                <Box alignItems="center" display="flex">
                    {t('Proposal', { count: 2 })}{' '}
                    <UpgradeIcon moduleName="Proposal-Templates" />
                </Box>
            ),
            content: (
                <RestrictedAccessLanding
                    addonName="Proposal-Templates"
                    minHeight={height}
                    url="https://somos.sunwise.io/sunwise-sales"
                >
                    {renderCarousel({ canModify: canModifyProposalTemplates })}
                    <TemplateTableSection
                        {...commonProps}
                        canDelete={canDeleteProposalTemplates}
                        canModify={canModifyProposalTemplates}
                        key="proposal-templates-content"
                        setTemplateType={setTemplateType}
                        templateType={types.ONE_PROPOSAL_TYPE}
                    />
                </RestrictedAccessLanding>
            ),
            requiredPermissions: [
                PERMISSION_LIST.PROPOSAL_TEMPLATES_PERMISSION,
            ],
        },
        {
            id: 'summaries',
            label: (
                <Box alignItems="center" display="flex">
                    {t('Summary', { count: 2 })}{' '}
                    <UpgradeIcon moduleName="Summary-Templates" />
                </Box>
            ),
            content: (
                <RestrictedAccessLanding
                    addonName="Summary-Templates"
                    minHeight={height}
                    url="https://somos.sunwise.io/sunwise-sales"
                >
                    {renderCarousel({ canModify: canModifySummaryTemplates })}
                    <TemplateTableSection
                        {...commonProps}
                        canDelete={canDeleteSummaryTemplates}
                        canModify={canModifySummaryTemplates}
                        key="summary-templates-content"
                        setTemplateType={setTemplateType}
                        templateType={types.MULTIPROPOSAL_TYPE}
                    />
                </RestrictedAccessLanding>
            ),
            requiredPermissions: [PERMISSION_LIST.SUMMARY_TEMPLATES_PERMISSION],
        },
        {
            id: 'smart-documents',
            label: (
                <Box alignItems="center" display="flex">
                    {t('Smart document', { count: 2 })}{' '}
                    <UpgradeIcon moduleName="Smart-Documents-Templates" />
                </Box>
            ),
            content: (
                <RestrictedAccessLanding
                    addonName="Smart-Documents-Templates"
                    minHeight={height}
                    url="https://somos.sunwise.io/sunwise-sales"
                >
                    {renderCarousel({
                        canModify: canModifySmartDocumentTemplates,
                    })}
                    <TemplateTableSection
                        {...commonProps}
                        canDelete={canDeleteSmartDocumentTemplates}
                        canModify={canModifySmartDocumentTemplates}
                        key="intelligent-document-templates-content"
                        setTemplateType={setTemplateType}
                        templateType={types.SMART_DOCUMENTS_TYPE}
                    />
                </RestrictedAccessLanding>
            ),
            requiredPermissions: [
                PERMISSION_LIST.SMART_DOCUMENT_TEMPLATES_PERMISSION,
            ],
        },
        {
            id: 'reports',
            label: (
                <Box alignItems="center" display="flex">
                    {t('Report', { count: 2 })}{' '}
                    <UpgradeIcon moduleName="Report-Templates,Sunwise-After-Sales" />
                </Box>
            ),
            content: (
                <RestrictedAccessLanding
                    addonName="Report-Templates,Sunwise-After-Sales"
                    minHeight={height}
                    url="https://somos.sunwise.io/monitoreo-postventa"
                >
                    {renderCarousel({
                        canModify: canModifyReportTemplates,
                    })}
                    <TemplateTableSection
                        {...commonProps}
                        canDelete={canDeleteReportTemplates}
                        canModify={canModifyReportTemplates}
                        key="report-templates-content"
                        setTemplateType={setTemplateType}
                        templateType={types.REPORT_TYPE}
                    />
                </RestrictedAccessLanding>
            ),
            requiredPermissions: [PERMISSION_LIST.REPORT_TEMPLATES_PERMISSION],
        },
    ];

    return (
        <TabsSystem
            baseUrl="template-library"
            tabs={getTabs()}
            showHeader={false}
        />
    );
};

TabsContent.propTypes = {
    commonProps: PropTypes.object,
    getPermissionsByCode: PropTypes.func,
};

export default withPermissions([
    PERMISSION_LIST.PROPOSAL_TEMPLATES_PERMISSION,
    PERMISSION_LIST.REPORT_TEMPLATES_PERMISSION,
    PERMISSION_LIST.SMART_DOCUMENT_TEMPLATES_PERMISSION,
    PERMISSION_LIST.SUMMARY_TEMPLATES_PERMISSION,
])(TabsContent);
