import React, { useEffect, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button } from 'sunwise-ui';

import { ZapForm as ZapFormComponent } from 'common/modules/zap/components/';

import * as actions from '../actions';
import * as selectors from '../selectors';

const ZapForm = ({ addRpu, hasZapData, isLoading }) => {
    const [showFields, setShowFields] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        setShowFields(!hasZapData);
    }, [hasZapData]);

    const customRender = () => (
        <Box alignItems="center" display="flex" flexDirection="column" gap={2}>
            <CheckCircleIcon />

            <Typography variant="caption">
                {t('Electric bill found successfully')}
            </Typography>

            <Button
                onClick={() => setShowFields((prev) => !prev)}
                type="button"
                variant="outlined"
            >
                {t('Search another')}
            </Button>
        </Box>
    );

    return (
        <ZapFormComponent
            addRpu={addRpu}
            customRender={!isLoading && !showFields ? customRender : null}
            hiddenFields={['rpu_id']}
            isLoading={isLoading}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    hasZapData: selectors.getHasZapData,
    isLoading: selectors.getIsLoadingZapIntegration,
});

const mapDispatchToProps = (dispatch) => ({
    addRpu: (data) => dispatch(actions.addZapRpu(data)),
});

ZapForm.propTypes = {
    addRpu: PropTypes.func,
    hasZapData: PropTypes.bool,
    isLoading: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ZapForm);
