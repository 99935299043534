import React, { useEffect, useState } from 'react';

import { Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Chip, Typography } from 'sunwise-ui';

import { getDateRangeLabel } from 'common/utils/dates';
import { getFilterLabelFromOptions } from 'common/utils/helpers';
import { getDateFormatByLocale } from 'common/utils/helpers/multiregion';

import * as credentialIntegrationsSelectors from '../../credentialIntegrations/selectors';
import {
    FILTERS_KEYS,
    FILTERS_TO_DISPLAY,
    FILTERS_WITH_TRANSLATION,
} from '../constants';
import { getFilteredSelectOptions } from '../helpers';
import * as selectors from '../selectors';

const AppliedFilters = ({
    filtersData,
    filtersValues,
    isLoadingFilters,
    providers,
}) => {
    const [filtersOptions, setFiltersOptions] = useState({});
    const { t } = useTranslation();
    const dateFormat = getDateFormatByLocale();

    useEffect(() => {
        const newFiltersOptions = getFilteredSelectOptions({ filtersData });

        setFiltersOptions(newFiltersOptions);
    }, [filtersData]);

    const filtersWithOptions = {
        [FILTERS_KEYS.AGENT]: filtersOptions.agents,
        [FILTERS_KEYS.CONTACT_ID]: filtersOptions.contacts,
        [FILTERS_KEYS.POLITICAL_DIVISION]: filtersOptions.politicalDivisions,
        [FILTERS_KEYS.PROJECT_ID]: filtersOptions.projects,
        [FILTERS_KEYS.PROJECT_STATUS]: filtersOptions.projectStatuses,
        [FILTERS_KEYS.PROVIDER]: providers,
        [FILTERS_KEYS.RATE_ID]: filtersOptions.rates,
        [FILTERS_KEYS.ZIP_CODE]: filtersOptions.zipCodes,
    };

    const getFilterValue = (key, value) => {
        if (filtersWithOptions[key])
            return getFilterLabelFromOptions(filtersWithOptions[key], value);

        if (FILTERS_WITH_TRANSLATION[key])
            return t(FILTERS_WITH_TRANSLATION[key][value]?.label) || '';

        return value;
    };

    const dateChipLabel = getDateRangeLabel({
        finalDate: filtersValues?.[FILTERS_KEYS.FINAL_DATE],
        initialDate: filtersValues?.[FILTERS_KEYS.INITIAL_DATE],
        newFormat: dateFormat,
    });

    return (
        <Box display="flex" alignItems="center" flexWrap="wrap" gap={1} my={1}>
            <Typography variant="subtitle1">
                {t('Report', { count: 2 })}:
            </Typography>

            {isLoadingFilters ? (
                <Skeleton variant="text" width={100} />
            ) : (
                <>
                    {dateChipLabel && (
                        <Chip label={dateChipLabel} variant="outlined" />
                    )}

                    {FILTERS_TO_DISPLAY.map((key) => {
                        const value = getFilterValue(key, filtersValues?.[key]);
                        if (!value) return null;
                        return (
                            <Chip key={key} label={value} variant="outlined" />
                        );
                    })}
                </>
            )}
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    filtersData: selectors.getFiltersData,
    isLoadingFilters: selectors.getFiltersLoading,
    providers: credentialIntegrationsSelectors.getProvidersForSelect2,
});

AppliedFilters.propTypes = {
    filtersData: PropTypes.array,
    filtersValues: PropTypes.object,
    isLoadingFilters: PropTypes.bool,
    providers: PropTypes.array,
};

export default connect(mapStateToProps)(AppliedFilters);
