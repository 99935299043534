import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, Typography } from 'sunwise-ui';

import { MONTHLY_FREQUENCY, PLAN_TYPES } from 'common/constants';
import { getTranslatedAddonLabel, removeWords } from 'common/utils/helpers';

import AfterSalesIcon from 'resources/aftersales.svg?react';
import SalesIcon from 'resources/sales.svg?react';

import { getBundleAddonList } from '../helpers'; // Asegúrate de importar la nueva función

const PricingBundleDetails = ({ buttonProps, onClick, pricing, variant }) => {
    const { t } = useTranslation();
    const planType = get(pricing, 'plan_type.key', 0);
    const isMonthly = pricing.frequency === MONTHLY_FREQUENCY;
    const isPro = planType === PLAN_TYPES.PRO;
    const isScale = planType === PLAN_TYPES.SCALE;
    const isEnterprise = planType === PLAN_TYPES.ENTERPRISE;
    const priceFrom = get(pricing, 'price_from', 0);
    const { disabled = false, text = 'Select', visible = true } = buttonProps;

    const renderIncludedText = () => {
        if (isMonthly) {
            return (
                <>
                    <Typography fontWeight={500} my={1} variant="body1">
                        {t('Includes {{number}} proposals per month', {
                            number: pricing?.credits?.proposals,
                        })}
                    </Typography>
                    <Typography fontWeight={500} my={1} variant="body1">
                        {t('Includes {{number}} reports per month', {
                            number: pricing?.credits?.reports,
                        })}
                    </Typography>
                </>
            );
        } else {
            return (
                <>
                    <Typography fontWeight={500} my={1} variant="body1">
                        {t('Includes {{number}} proposals per year', {
                            number: pricing?.credits?.proposals,
                        })}
                    </Typography>
                    <Typography fontWeight={500} my={1} variant="body1">
                        {t('Includes {{number}} reports per year', {
                            number: pricing?.credits?.reports,
                        })}
                    </Typography>
                </>
            );
        }
    };

    const renderInvoicedText = () => {
        if (isEnterprise)
            return (
                <>
                    <Typography variant="h3">{t('Talk to sales')}</Typography>
                    <Typography fontWeight={500} mt={1} variant="body1">
                        {isMonthly
                            ? t('Invoiced monthly')
                            : t('Invoiced annually')}
                    </Typography>
                </>
            );
        return (
            <>
                <Typography variant="h3">{priceFrom}</Typography>
                <Typography fontWeight={500} mt={1} variant="body1">
                    {isMonthly ? t('Invoiced monthly') : t('Invoiced annually')}
                </Typography>
            </>
        );
    };

    const renderButton = () => {
        if (text === t('Contact us'))
            return (
                <Button
                    fullWidth
                    href="https://www.sunwise.io/schedule"
                    target="_blank"
                >
                    {t('Contact us')}
                </Button>
            );
        return (
            <Button
                disabled={disabled}
                fullWidth
                onClick={() => onClick(pricing.chargebee_price_id)}
            >
                {text}
            </Button>
        );
    };

    return (
        <Card>
            <Card.Header
                sx={{
                    display: 'flex',
                    backgroundColor:
                        variant === 'secondary' ? '#011e2e' : 'primary.main',
                    color: variant === 'secondary' ? '#fff' : 'inherit',
                    border: 0,
                    overflow: 'hidden', // Oculta el contenido que excede el ancho
                    whiteSpace: 'nowrap', // Evita que el texto se divida en varias líneas
                    textOverflow: 'ellipsis', // Agrega los puntos suspensivos
                }}
            >
                <Typography
                    variant="h3"
                    sx={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {removeWords(pricing.plan_name)}
                </Typography>
            </Card.Header>
            <Card.Body>
                <Box sx={{ height: 650 }}>
                    {renderInvoicedText()}
                    <Typography fontWeight={500} my={1} variant="body1">
                        {renderIncludedText()}
                    </Typography>
                    <Typography fontWeight={500} variant="caption">
                        {t('Additional charges per use')}
                    </Typography>
                    {isPro && (
                        <Typography mb={1} mt={3} variant="body1">
                            {t('Essentials in solar sales')}:
                        </Typography>
                    )}
                    {isScale && (
                        <Typography mb={1} mt={3} variant="body1">
                            {t('Everything in the Pro plan and more')}:
                        </Typography>
                    )}
                    {isEnterprise && (
                        <Typography mb={1} mt={3} variant="body1">
                            {t('Everything from the Scale plan and more')}:
                        </Typography>
                    )}
                    <Box display="flex" flexDirection="column" mt={2}>
                        {/* Sección Sales */}
                        <Box
                            alignItems="center"
                            display={isEnterprise ? 'none' : 'flex'}
                            gap={1}
                            mb={1}
                            mt={2}
                            sx={{ svg: { height: 30, width: 30 } }}
                        >
                            <SalesIcon />
                            <Typography variant="h5">Sunwise</Typography>
                            <Typography
                                component="span"
                                sx={{ color: 'primary.main' }}
                                variant="h5"
                            >
                                Sales
                            </Typography>
                        </Box>
                        {getBundleAddonList(planType, 'sales').map((addon) => (
                            <Box
                                alignItems="center"
                                display="flex"
                                gap={1}
                                key={addon}
                                mb={1}
                                mt={1}
                            >
                                <CheckCircleIcon fontSize="small" />
                                <Typography fontWeight={500} variant="body1">
                                    {getTranslatedAddonLabel(addon)}
                                </Typography>
                            </Box>
                        ))}
                        {/* Sección Aftersales */}
                        <Box
                            alignItems="center"
                            display={isEnterprise ? 'none' : 'flex'}
                            gap={1}
                            mb={1}
                            mt={2}
                            sx={{ svg: { height: 30, width: 30 } }}
                        >
                            <AfterSalesIcon />
                            <Typography variant="h5">Sunwise</Typography>
                            <Typography
                                component="span"
                                sx={{ color: 'primary.main' }}
                                variant="h5"
                            >
                                AfterSales
                            </Typography>
                        </Box>
                        {getBundleAddonList(planType, 'aftersales').map(
                            (addon) => (
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    gap={1}
                                    key={addon}
                                    mb={1}
                                    mt={1}
                                >
                                    <CheckCircleIcon fontSize="small" />
                                    <Typography
                                        fontWeight={500}
                                        variant="body1"
                                    >
                                        {getTranslatedAddonLabel(addon)}
                                    </Typography>
                                </Box>
                            ),
                        )}
                    </Box>
                </Box>
                <Box sx={{ minHeight: 40 }} mt={2}>
                    {visible && renderButton()}
                </Box>
            </Card.Body>
        </Card>
    );
};

PricingBundleDetails.propTypes = {
    buttonProps: PropTypes.object,
    onClick: PropTypes.func,
    pricing: PropTypes.object,
    variant: PropTypes.string,
};

export default PricingBundleDetails;
