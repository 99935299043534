import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';

const QuantityColumn = ({
    control,
    formValues,
    handleNormalizeField,
    handleNormalizeFields,
    index,
    initialValues,
    isDisabledField,
    name,
}) => {
    const { t } = useTranslation();
    return (
        <Grid size={{ md: 3, xs: 18 }}>
            <ReactHookFormIntlNumberInput
                allowDecimals={false}
                allowNegativeValue={false}
                control={control}
                disabled={isDisabledField}
                fullWidth
                label={t('Quantity')}
                onChange={({ target: { value } }) =>
                    handleNormalizeField(index, handleNormalizeFields)(
                        value,
                        initialValues?.[name]?.[index]?.quantity,
                        formValues,
                        name,
                    )
                }
                name={`${name}.${index}.quantity`}
                step="any"
                variant="standard"
            />
        </Grid>
    );
};

QuantityColumn.propTypes = {
    control: PropTypes.object,
    formValues: PropTypes.object,
    handleNormalizeField: PropTypes.func,
    handleNormalizeFields: PropTypes.func,
    index: PropTypes.number,
    initialValues: PropTypes.object,
    isDisabledField: PropTypes.bool,
    name: PropTypes.string,
};

export default QuantityColumn;
