import React, { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    Card,
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from 'sunwise-ui';

import { BulkEditionMenu, InputSearch } from 'common/components';
import { ReactHookFormInputDatePicker } from 'common/components/form/bootstrap';
import { getIsOnlyContacts } from 'common/utils/helpers/session';

import {
    getOriginOptions,
    getRateOptions,
    getStatusOptions,
    getStorageOptions,
    getSystemOptions,
    getUserOptions,
} from '../helpers';

const BulkOperationsCard = ({
    filterData,
    handleChange,
    handleClickBulkItems,
    initialDates,
    rates,
    selectionModel,
    setSelectionModel,
    users,
}) => {
    const { t } = useTranslation();
    const isOnlyContacts = getIsOnlyContacts();
    const { control, reset } = useForm({
        defaultValues: initialDates,
    });
    const callback = () => setSelectionModel([]);

    useEffect(() => reset(initialDates), [initialDates]);

    return (
        <Card sx={{ mb: '16px !important' }}>
            <Card.Body>
                <Grid alignItems="end" container>
                    <Grid
                        size={{ lg: 18, md: isOnlyContacts ? 18 : 9, xs: 18 }}
                    >
                        <InputSearch
                            id="input-search"
                            onChange={(e) =>
                                handleChange('searchText', e.target.value, true)
                            }
                            placeholder={t('Search')}
                            value={filterData?.searchText}
                        />
                    </Grid>
                    {!isOnlyContacts && (
                        <Grid size={{ lg: 4, md: 9, xs: 18 }}>
                            <FormControl fullWidth size="small">
                                <InputLabel>{t('User')}</InputLabel>
                                <Select
                                    label={t('User')}
                                    onChange={(e) =>
                                        handleChange(
                                            'createdBy',
                                            e.target.value,
                                            true,
                                        )
                                    }
                                    value={filterData.createdBy}
                                    variant="standard"
                                >
                                    {getUserOptions(users).map(
                                        ({ label, value, disabled }) => (
                                            <MenuItem
                                                disabled={disabled}
                                                key={`user-${value}-${label}`}
                                                value={value}
                                            >
                                                {label}
                                            </MenuItem>
                                        ),
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    <Grid size={{ lg: isOnlyContacts ? 3 : 2, md: 6, xs: 18 }}>
                        <ReactHookFormInputDatePicker
                            control={control}
                            fullWidth
                            label={t('Start date')}
                            maxDate={new Date()}
                            name="start_date"
                            onChange={(date) => handleChange('startDate', date)}
                            variant="standard"
                        />
                    </Grid>
                    <Grid size={{ lg: isOnlyContacts ? 3 : 2, md: 6, xs: 18 }}>
                        <ReactHookFormInputDatePicker
                            control={control}
                            fullWidth
                            label={t('Final date')}
                            maxDate={new Date()}
                            name="end_date"
                            onChange={(date) => handleChange('endDate', date)}
                            variant="standard"
                        />
                    </Grid>
                    <Grid size={{ lg: isOnlyContacts ? 3 : 2, md: 6, xs: 18 }}>
                        <FormControl fullWidth size="small">
                            <InputLabel>{t('Status')}</InputLabel>
                            <Select
                                label={t('Status')}
                                onChange={(e) =>
                                    handleChange('status', e.target.value, true)
                                }
                                value={filterData.status}
                                variant="standard"
                            >
                                {getStatusOptions().map(
                                    ({ label, value, disabled }) => (
                                        <MenuItem
                                            disabled={disabled}
                                            key={`status-${value}-${label}`}
                                            value={value}
                                        >
                                            {label}
                                        </MenuItem>
                                    ),
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid size={{ lg: isOnlyContacts ? 3 : 2, md: 6, xs: 18 }}>
                        <FormControl fullWidth size="small">
                            <InputLabel>{t('Rate')}</InputLabel>
                            <Select
                                label={t('Rate')}
                                onChange={(e) =>
                                    handleChange('rateName', e.target.value)
                                }
                                value={filterData.rateName}
                                variant="standard"
                            >
                                {getRateOptions(rates).map(
                                    ({ label, value, disabled }) => (
                                        <MenuItem
                                            disabled={disabled}
                                            key={`rate-${value}-${label}`}
                                            value={value}
                                        >
                                            {label}
                                        </MenuItem>
                                    ),
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid size={{ lg: isOnlyContacts ? 3 : 2, md: 6, xs: 18 }}>
                        <FormControl fullWidth size="small">
                            <InputLabel>{`${t('System')} (kW)`}</InputLabel>
                            <Select
                                label={`${t('System')} (kW)`}
                                onChange={(e) =>
                                    handleChange(
                                        'systemRange',
                                        e.target.value,
                                        true,
                                    )
                                }
                                value={filterData.systemRange}
                                variant="standard"
                            >
                                {getSystemOptions().map(
                                    ({ label, value, disabled }) => (
                                        <MenuItem
                                            disabled={disabled}
                                            key={`system-${value}-${label}`}
                                            value={value}
                                        >
                                            {label}
                                        </MenuItem>
                                    ),
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid size={{ lg: isOnlyContacts ? 3 : 2, md: 6, xs: 18 }}>
                        <FormControl fullWidth size="small">
                            <InputLabel>{`${t('Storage')} (kWh)`}</InputLabel>
                            <Select
                                label={`${t('Storage')} (kWh)`}
                                onChange={(e) =>
                                    handleChange(
                                        'storageRange',
                                        e.target.value,
                                        true,
                                    )
                                }
                                value={filterData.storageRange}
                                variant="standard"
                            >
                                {getStorageOptions().map(
                                    ({ label, value, disabled }) => (
                                        <MenuItem
                                            disabled={disabled}
                                            key={`storage-${value}-${label}`}
                                            value={value}
                                        >
                                            {label}
                                        </MenuItem>
                                    ),
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid size={{ lg: isOnlyContacts ? 3 : 2, md: 6, xs: 18 }}>
                        <FormControl fullWidth size="small">
                            <InputLabel>{`${t('Origin')}`}</InputLabel>
                            <Select
                                label={`${t('Origin')}`}
                                onChange={(e) =>
                                    handleChange('origin', e.target.value, true)
                                }
                                value={filterData.origin}
                                variant="standard"
                            >
                                {getOriginOptions().map(
                                    ({ label, value, disabled }) => (
                                        <MenuItem
                                            disabled={disabled}
                                            key={`origin-${value}-${label}`}
                                            value={value}
                                        >
                                            {label}
                                        </MenuItem>
                                    ),
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    {!isEmpty(selectionModel) && (
                        <Grid
                            size={{ lg: 4, xs: 18 }}
                            sx={{ order: { md: 1, xs: 2 } }}
                        >
                            <BulkEditionMenu
                                callback={callback}
                                items={[
                                    {
                                        label: t('Archive'),
                                        handleClick: () =>
                                            handleClickBulkItems(
                                                selectionModel,
                                                callback,
                                            ),
                                        visible: true,
                                    },
                                ]}
                                selectionModel={selectionModel}
                            />
                        </Grid>
                    )}
                </Grid>
            </Card.Body>
        </Card>
    );
};

BulkOperationsCard.propTypes = {
    filterData: PropTypes.object,
    handleChange: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    initialDates: PropTypes.object,
    rates: PropTypes.array,
    selectionModel: PropTypes.array,
    setSelectionModel: PropTypes.func,
    users: PropTypes.array,
};

export default BulkOperationsCard;
