import React, { useEffect } from 'react';

import SendIcon from '@mui/icons-material/Send';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Card, Typography } from 'sunwise-ui';

import * as profileSelectors from '../../../modules/profile/selectors';
import * as afterSalesSettingsActions from '../../afterSalesSettings/actions';
import * as afterSalesSettingsSelectors from '../../afterSalesSettings/selectors';

import CreateShareLinkButton from './CreateShareLinkButton';
import ShareCardContent from './ShareCardContent';

const renderContent = ({ selectedProjectId, sunpalConfig } = {}) => {
    if (!sunpalConfig)
        return <CreateShareLinkButton selectedProjectId={selectedProjectId} />;
    return <ShareCardContent selectedProjectId={selectedProjectId} />;
};

const ShareCard = ({
    afterSalesSubscriptionVersion,
    fethSunpalConfig,
    resetSunpalConfig,
    selectedProjectId,
    sunpalConfig,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        fethSunpalConfig(selectedProjectId);
        return () => {
            resetSunpalConfig();
        };
    }, [selectedProjectId]);

    if (
        !afterSalesSubscriptionVersion ||
        afterSalesSubscriptionVersion === 'v4'
    )
        return null;

    return (
        <Card sx={{ mb: '16px !important' }}>
            <Card.Header
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    p: '8px 16px !important',
                }}
            >
                <SendIcon sx={{ color: 'primary.main' }} />

                <Typography variant="body2" ml={1} fontWeight="bold">
                    {t('Share')}
                </Typography>
            </Card.Header>

            <Card.Body sx={{ pt: 1 }}>
                {renderContent({ selectedProjectId, sunpalConfig })}
            </Card.Body>
        </Card>
    );
};

ShareCard.propTypes = {
    afterSalesSubscriptionVersion: PropTypes.string,
    fethSunpalConfig: PropTypes.func,
    resetSunpalConfig: PropTypes.func,
    selectedProjectId: PropTypes.string,
    sunpalConfig: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    afterSalesSubscriptionVersion:
        profileSelectors.getAfterSalesSubscriptionVersion,
    initialValues: afterSalesSettingsSelectors.getSunpalConfigIninitalValues,
    sunpalConfig: afterSalesSettingsSelectors.getSunpalConfigData,
});

const mapDispatchToProps = (dispatch) => ({
    fethSunpalConfig: (uuid) =>
        dispatch(afterSalesSettingsActions.fethSunpalConfig(uuid)),
    resetSunpalConfig: () =>
        dispatch(afterSalesSettingsActions.setSunpalConfigData(null)),
    save: (data) => dispatch(afterSalesSettingsActions.saveSunpalConfig(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareCard);
