import React, { useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Button, Container as MaterialContainer, Grid } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as actions from './actions';
import AddSourceModal from './components/AddSourceModal';
import CredentialLists from './components/CredentialLists';
import * as selectors from './selectors';

const Container = ({
    canModify,
    canView,
    credentials,
    fetchCredentials,
    fetchProviders,
    initialValues,
    initialize,
    isDeletingCredential,
    isOpenModal,
    isSavingCredential,
    prepareDeleteCredential,
    providers,
    providersDictionary,
    redirect,
    reset,
    resetForm,
    saveCredential,
    setIsOpenModal,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        fetchCredentials();
        fetchProviders();
        return () => reset();
    }, []);

    const handleClickUpdate = (data) => {
        let newData = { ...(data ?? {}) };
        const fields = newData.provider?.fields || [];
        if (fields?.length > 0) {
            newData.credential = {};
            fields.forEach((field) => {
                newData.credential[field] = data[field];
            });
        }
        initialize(newData);
        setIsOpenModal(true);
    };

    const handleOpenPowerStation = (id) =>
        redirect(`/monitoring-credentials/${id}`);

    const disabled = isDeletingCredential || isSavingCredential;

    if (!canView) return null;

    return (
        <MaterialContainer maxWidth={false}>
            <HeaderContainer>
                <Grid
                    display="flex"
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    gap={1}
                    justifyContent="space-between"
                    size={18}
                >
                    <TitleIcon
                        fontWeight="bold"
                        title={t('Monitoring credentials')}
                    />

                    {canModify && providers?.length > 0 && (
                        <Button
                            disabled={disabled}
                            endIcon={<AddIcon />}
                            onClick={() => setIsOpenModal(true)}
                        >
                            {t('Add credential')}
                        </Button>
                    )}
                </Grid>
            </HeaderContainer>

            <CredentialLists
                canModify={canModify}
                credentials={credentials}
                disabled={disabled}
                handleClickUpdate={handleClickUpdate}
                handleOpenPowerStation={handleOpenPowerStation}
                prepareDeleteCredential={prepareDeleteCredential}
                providersDictionary={providersDictionary}
            />

            <AddSourceModal
                disabled={disabled}
                initialValues={initialValues}
                isOpen={isOpenModal}
                providers={providers}
                providersDictionary={providersDictionary}
                resetForm={resetForm}
                saveCredential={saveCredential}
                setIsOpen={setIsOpenModal}
            />
        </MaterialContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    credentials: selectors.getCredentialsData,
    initialValues: selectors.getInitialValues,
    isDeletingCredential: selectors.getIsDeletingCredential,
    isOpenModal: selectors.getIsOpenModal,
    isSavingCredential: selectors.getIsSavingCredential,
    providers: selectors.getProviders,
    providersDictionary: selectors.getProvidersDictionary,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCredentials: () => dispatch(actions.fetchCredentials()),
    fetchProviders: () => dispatch(actions.fetchProviders()),
    initialize: (data) => dispatch(actions.initialize(data)),
    prepareDeleteCredential: (id) =>
        dispatch(actions.prepareDeleteCredential(id)),
    redirect: (link) => dispatch(push(link)),
    reset: () => dispatch(actions.reset()),
    resetForm: () => dispatch(actions.resetForm()),
    saveCredential: (data) => dispatch(actions.saveCredential(data)),
    setIsOpenModal: (isOpen) => dispatch(actions.setIsOpenModal(isOpen)),
});

Container.propTypes = {
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    credentials: PropTypes.array,
    fetchCredentials: PropTypes.func,
    fetchProviders: PropTypes.func,
    initialValues: PropTypes.object,
    initialize: PropTypes.func,
    isDeletingCredential: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    isSavingCredential: PropTypes.bool,
    prepareDeleteCredential: PropTypes.func,
    providers: PropTypes.array,
    providersDictionary: PropTypes.object,
    redirect: PropTypes.func,
    reset: PropTypes.func,
    resetForm: PropTypes.func,
    saveCredential: PropTypes.func,
    setIsOpenModal: PropTypes.func,
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.REPORTS_PERMISSION),
)(Container);
