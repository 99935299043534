import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_CONSUMPTION,
    FETCH_CONSUMPTION_FAILURE,
    FETCH_CONSUMPTION_SUCCESS,
    INITIALIZE_CONSUMPTION_FORM,
    RESET,
    RESET_FORM,
    SAVE_CONSUMPTION,
    SAVE_CONSUMPTION_FAILURE,
    SAVE_CONSUMPTION_SUCCESS,
    SET_IS_OPEN_MODAL,
} from './actionTypes';
import { DEVICE_DEFAULT_VALUES, NAME } from './constants';

const INITIAL_STATE = {
    fetchConsumption: { data: null, error: null, loading: false },
    initialValues: { devices: Object.values(DEVICE_DEFAULT_VALUES) },
    isOpenModal: false,
    saveConsumption: { data: null, error: null, loading: false },
};

const slice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_CONSUMPTION]: (state) => {
            state.fetchConsumption.loading = true;
            state.fetchConsumption.error = null;
        },
        [FETCH_CONSUMPTION_FAILURE]: (state, action) => {
            state.fetchConsumption.loading = false;
            state.fetchConsumption.error = action.payload;
        },
        [FETCH_CONSUMPTION_SUCCESS]: (state, action) => {
            state.fetchConsumption.loading = false;
            state.fetchConsumption.data = action.payload;
        },
        [INITIALIZE_CONSUMPTION_FORM]: (state, action) => {
            state.initialValues = action.payload;
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE_CONSUMPTION]: (state) => {
            state.saveConsumption.loading = true;
            state.saveConsumption.error = null;
        },
        [SAVE_CONSUMPTION_FAILURE]: (state, action) => {
            state.saveConsumption.loading = false;
            state.saveConsumption.error = action.payload;
        },
        [SAVE_CONSUMPTION_SUCCESS]: (state, action) => {
            state.saveConsumption.loading = false;
            state.saveConsumption.data = action.payload;
        },
        [SET_IS_OPEN_MODAL]: (state, action) => {
            state.isOpenModal = action.payload;
        },
    },
});

export const actions = slice.actions;

export default slice.reducer;
