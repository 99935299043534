import React from 'react';

import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Grid,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from 'sunwise-ui';

import {
    LabelDecorator,
    ProgressLine,
    StyledSyncAltIcon,
} from 'common/components';
import {
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect2,
} from 'common/components/form/bootstrap';
import { itemsToSelect2WithoutDuplicates } from 'common/utils/helpers';

import { DAY_TYPES } from '../constants';
import { getDailyAverage, getQuantityByPercentage } from '../helpers';

const StorageForm = ({
    batteries,
    control,
    dataForCalculations,
    handleOnChangeValue,
    isDisabled,
    maxDailyConsumption,
    setValue,
}) => {
    const [baseDayType, dailyStoragePercentage] = useWatch({
        control,
        name: ['base_day_type', 'daily_storage_percentage'],
    });
    const { t } = useTranslation();
    const { batteryOffer, diffDays, originalConsumption } = dataForCalculations;

    const handleGetDailyAverage = () =>
        getDailyAverage({ days: diffDays, value: originalConsumption });

    const handleOnChangeQuantity = (quantity) =>
        handleOnChangeValue({ quantity }, dataForCalculations);

    const optionsToSelect = itemsToSelect2WithoutDuplicates(
        batteries,
        false,
        [],
    );

    return (
        <Box className="__userguiding_energy_backup_storage" p={2}>
            <Grid container>
                <Grid size={{ xs: 'grow' }}>
                    <Box display="flex" alignItems="center" p={1} pl={0}>
                        <BatteryChargingFullIcon sx={{ color: '#8bc34a' }} />
                        <Typography fontWeight="bold" ml={1} variant="body2">
                            {t('Storage')}
                        </Typography>
                    </Box>
                    <Typography variant="body2" mt={1}>
                        {t(
                            'Make sure that the battery model you choose meets the appropriate voltage and current according to your selected inverters',
                        )}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container mt={2}>
                <Grid size={{ lg: 10, xl: 12, xs: 18 }}>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={isDisabled}
                        fullWidth
                        isClearable
                        label={t('Model')}
                        name="product_branch_office"
                        onChange={(value) =>
                            handleOnChangeValue(
                                { product_branch_office: value },
                                dataForCalculations,
                            )
                        }
                        options={optionsToSelect}
                        placeholder={t('Select an storage')}
                    />
                </Grid>

                {maxDailyConsumption > 0 && (
                    <Grid size={{ lg: 8, md: 12, xl: 6, xs: 18 }}>
                        <ToggleButtonGroup
                            exclusive
                            fullWidth
                            onChange={(e) => {
                                setValue(
                                    'base_day_type',
                                    parseInt(e.target.value),
                                );
                                handleOnChangeValue(
                                    { base_day_type: parseInt(e.target.value) },
                                    dataForCalculations,
                                );
                            }}
                            size="small"
                            sx={{ mb: 2 }}
                            value={baseDayType}
                        >
                            <ToggleButton disabled={isDisabled} value={0}>
                                {t('Daily maximum')}
                            </ToggleButton>
                            <ToggleButton disabled={isDisabled} value={1}>
                                {t('Daily average')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                )}
            </Grid>

            <Grid container mt={1}>
                <Grid alignItems="end" display="flex" size={{ md: 6, xs: 18 }}>
                    <ReactHookFormIntlNumberInput
                        allowDecimals={false}
                        allowNegativeValue={false}
                        append="u"
                        control={control}
                        disabled={isDisabled}
                        fullWidth
                        label={t('Quantity')}
                        min={0}
                        name="quantity"
                        onChange={({ target: { value } }) =>
                            handleOnChangeQuantity(value)
                        }
                        variant="standard"
                    />
                    <Box
                        sx={{
                            display: { xs: 'none', md: 'block' },
                            ml: { xs: 0, md: 2 },
                        }}
                    >
                        <StyledSyncAltIcon fontSize="medium" />
                    </Box>
                </Grid>
                <Grid alignItems="end" display="flex" size={{ md: 6, xs: 18 }}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="kWh"
                        control={control}
                        decimalsLimit={2}
                        disabled={isDisabled}
                        fullWidth
                        label={t('Storage size')}
                        min={0}
                        name="storage_size"
                        onChange={({ target: { value } }) =>
                            handleOnChangeQuantity(
                                value / (batteryOffer?.[0]?.storage_cap / 1000),
                            )
                        }
                        variant="standard"
                    />
                    <Box
                        sx={{
                            display: { xs: 'none', md: 'block' },
                            ml: { xs: 0, md: 2 },
                        }}
                    >
                        <StyledSyncAltIcon fontSize="medium" />
                    </Box>
                </Grid>
                <Grid size={{ md: 6, xs: 18 }}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="%"
                        control={control}
                        decimalsLimit={2}
                        disabled={isDisabled}
                        fullWidth
                        label={
                            <Box display="flex" alignItems="center" gap={2}>
                                <LabelDecorator />
                                {t('Storage percentage')}
                            </Box>
                        }
                        min={0}
                        name="daily_storage_percentage"
                        onChange={({ target: { value } }) => {
                            const total =
                                baseDayType === DAY_TYPES.MAX
                                    ? maxDailyConsumption
                                    : handleGetDailyAverage();

                            const quantity = getQuantityByPercentage({
                                percentage: value / 100,
                                total,
                                unitTotal:
                                    batteryOffer?.[0]?.storage_cap / 1000,
                            });
                            handleOnChangeQuantity(quantity);
                        }}
                        variant="standard"
                    />
                </Grid>
            </Grid>

            <Grid container mt={1}>
                <Grid size={18}>
                    <ProgressLine
                        value={Math.min(dailyStoragePercentage, 100)}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

StorageForm.propTypes = {
    batteries: PropTypes.array,
    control: PropTypes.object,
    dataForCalculations: PropTypes.object,
    handleOnChangeValue: PropTypes.func,
    isDisabled: PropTypes.bool,
    maxDailyConsumption: PropTypes.number,
    setValue: PropTypes.func,
};

export default StorageForm;
