import React from 'react';

import PropTypes from 'prop-types';

import SidebarIndicators from './indicators/SidebarIndicators';
import Sidebar from './Sidebar';
import StyledGrid from './StyledGrid';
import SupportControlButtons from './SupportControlButtons';

const SupportMenuGrid = ({
    handlePrepareEditReport,
    isGenerated,
    isVisibleEditButton,
    reportComplements,
    reportId,
}) => (
    <StyledGrid
        size={{ md: 6, lg: 4 }}
        sx={{ display: { md: 'block', xs: 'none' }, p: 1 }}
    >
        <SupportControlButtons
            handlePrepareEditReport={handlePrepareEditReport}
            isVisibleEditButton={isVisibleEditButton}
        />
        <Sidebar isGenerated={isGenerated} reportId={reportId} />

        <SidebarIndicators reportComplements={reportComplements} />
    </StyledGrid>
);

SupportMenuGrid.propTypes = {
    handlePrepareEditReport: PropTypes.func,
    isGenerated: PropTypes.bool,
    isVisibleEditButton: PropTypes.bool,
    reportComplements: PropTypes.object,
    reportId: PropTypes.string,
};

export default SupportMenuGrid;
