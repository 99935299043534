import React, { useEffect } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Box, Chip, DataGrid } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CustomPagination } from 'common/components';
import { useBreakpoint } from 'common/hooks';
import { scroll } from 'common/utils/mixins';

import { DEFAULT_ALL_STATUS_KEY, REPORT_TYPES_CONFIG } from '../constants';

import ElectricBills from './ElectricBills';
import Periods from './Periods';
import ReportName from './ReportName';

const getRowStyles = (selectable) => {
    const styles = { '& .MuiDataGrid-cell:focus': { outline: 'none' } };
    if (selectable) styles['& .MuiDataGrid-row'] = { cursor: 'pointer' };
    return styles;
};

const StyledDataGrid = styled(DataGrid)`
    & .MuiDataGrid-virtualScroller {
        ${scroll.custom()}
    }
`;

const pageSizes = [15, 25, 50];

const getCorrectPageSize = (pageSize) =>
    pageSizes.includes(pageSize) ? pageSize : pageSizes[0];

const ReportTable = ({
    data,
    fetchReports,
    filterQuery,
    localeDateFormat,
    onSelectReport,
}) => {
    const { t } = useTranslation();

    const breakpoint = useBreakpoint();

    const {
        data: columnData,
        loading,
        meta: { page, page_size: pageSize, total } = {},
    } = data?.[DEFAULT_ALL_STATUS_KEY] || {};

    useEffect(() => {
        handleFetchReports({ filterQuery });
    }, [filterQuery]);

    const handleFetchReports = ({ filterQuery, newPage, newPageSize } = {}) => {
        if (loading) return;

        fetchReports({
            filterQuery,
            page: newPage || 1,
            pageSize: getCorrectPageSize(newPageSize || pageSize),
            replaceValues: true,
        });
    };

    const columns = [
        {
            align: 'left',
            field: 'name',
            flex: 1,
            headerName: t('Name'),
            minWidth: 200,
            renderCell: ({ row }) => (
                <ReportName
                    name={row?.name}
                    resolution={row?.resolution}
                    showResolution
                    status={row?.complements?.status}
                />
            ),
            sortable: false,
        },
        {
            align: 'left',
            field: 'period',
            flex: 1,
            headerName: t('Period', { count: 2 }),
            minWidth: 230,
            sortable: false,
            renderCell: ({ row }) => (
                <Box>
                    <Periods
                        data={row.report_periods?.[0]?.group_periods}
                        localeDateFormat={localeDateFormat}
                    />
                </Box>
            ),
        },
    ];

    if (!['xs', 'sm'].includes(breakpoint))
        columns.push(
            {
                align: 'left',
                field: 'type',
                flex: 1,
                headerName: t('Type'),
                minWidth: 190,
                renderCell: ({ row }) => {
                    const config =
                        REPORT_TYPES_CONFIG[
                            row.report_periods?.[0]?.report_type
                        ];

                    if (!config?.label) return null;

                    return <Chip label={t(config.label)} />;
                },
                sortable: false,
            },
            {
                align: 'left',
                field: 'electric_bill',
                flex: 1,
                headerName: t('Electric bill'),
                minWidth: 200,
                renderCell: ({ row }) => (
                    <Box>
                        <ElectricBills
                            localeDateFormat={localeDateFormat}
                            periods={row.report_periods?.[0]?.group_periods}
                        />
                    </Box>
                ),
                sortable: false,
            },
        );

    columns.push({
        align: 'left',
        field: 'project',
        flex: 1,
        headerName: t('Project'),
        minWidth: 200,
        sortable: false,
        valueGetter: ({ row }) => row?.project?.name || '--',
    });

    return (
        <StyledDataGrid
            autoHeight
            checkboxSelection={false}
            components={{
                LoadingOverlay: LinearProgress,
                Pagination: CustomPagination,
            }}
            componentsProps={{ pagination: { rowsPerPageOptions: pageSizes } }}
            columns={columns || []}
            disableColumnMenu
            disableSelectionOnClick
            getRowHeight={() => 'auto'}
            hideFooterSelectedRowCount
            loading={loading}
            initialState={{ pagination: { pageSize: pageSizes[0] } }}
            onPageChange={(newPage) =>
                handleFetchReports({ filterQuery, newPage: newPage + 1 })
            }
            onPageSizeChange={(newPageSize) =>
                handleFetchReports({ filterQuery, newPageSize })
            }
            onRowClick={(params) => onSelectReport(params.row)}
            page={page - 1 || 0}
            pageSize={getCorrectPageSize(pageSize)}
            pagination
            paginationMode="server"
            rowCount={total || 0}
            rows={columnData || []}
            sx={getRowStyles(true)}
        />
    );
};

ReportTable.propTypes = {
    data: PropTypes.object,
    fetchReports: PropTypes.func,
    filterQuery: PropTypes.string,
    localeDateFormat: PropTypes.string,
    onSelectReport: PropTypes.func,
};

export default ReportTable;
