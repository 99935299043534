import { getMappedProjects } from 'common/api/afterSales/projects';
import { showFastApiErrorsAsAlert } from 'common/utils/helpers';

import {
    FETCH_MONITORING_DATA,
    FETCH_MONITORING_DATA_FAILURE,
    FETCH_MONITORING_DATA_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (params) => (dispatch) => {
    dispatch(actions[FETCH_MONITORING_DATA]());

    getMappedProjects(params)
        .then((response) =>
            dispatch(actions[FETCH_MONITORING_DATA_SUCCESS](response?.data)),
        )
        .catch((error) => {
            dispatch(actions[FETCH_MONITORING_DATA_FAILURE](error));

            showFastApiErrorsAsAlert(dispatch, error?.response);
        });
};
