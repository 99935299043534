import React from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
    Avatar,
    Box,
    IconButton,
    Tooltip,
    Typography,
} from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import zapLogo from 'resources/zap_logo.svg';

import { downloadFile as downloadAfterSalesFile } from '../../afterSalesConsumptionHistory/actions';
import { UPLOAD_ORIGINS } from '../../afterSalesConsumptionHistory/constants';
import { ELECTRIC_BILL_STATUS_CONFIG } from '../constants';
import { getReportHistoricalFiles } from '../helpers';

const ElectricBills = ({
    //localeDateFormat = 'dd/MM/yyyy',
    downloadFile,
    periods,
    showAll = false,
    sxItem,
}) => {
    const { t } = useTranslation();

    const files = getReportHistoricalFiles(periods);

    const totalFiles = files?.length;

    if (!totalFiles) return null;

    if (totalFiles > 1 && !showAll)
        return (
            <Box alignItems="center" display="flex" gap={1} mb={1}>
                <Avatar size="small" sx={{ bgcolor: 'transparent' }}>
                    <InsertDriveFileIcon htmlColor="gray" />
                </Avatar>

                <Typography variant="body2">{t('Multiple')}</Typography>
            </Box>
        );

    return files.map(({ label, status, uploadOrigin, url }) => {
        const statusConfig = ELECTRIC_BILL_STATUS_CONFIG[status];

        const isFromZap = uploadOrigin === UPLOAD_ORIGINS.ZAP;

        return (
            <Box
                alignItems="center"
                display="flex"
                gap={1}
                key={url}
                width={totalFiles > 1 ? 'auto' : '100%'}
                sx={sxItem}
            >
                <Avatar
                    size="small"
                    src={isFromZap ? zapLogo : null}
                    sx={{ bgcolor: 'transparent' }}
                >
                    <InsertDriveFileIcon htmlColor={statusConfig.color} />
                </Avatar>

                <Box>
                    <Typography variant="body2">{label}</Typography>

                    {statusConfig && (
                        <Typography
                            color={statusConfig.color}
                            fontWeight="600"
                            variant="caption"
                        >
                            {t(statusConfig.label)}
                        </Typography>
                    )}
                </Box>

                <Tooltip title={t('Download')} placement="bottom">
                    <IconButton
                        aria-label="download"
                        onClick={(e) => {
                            e.stopPropagation();

                            downloadFile({ name: label, uploadOrigin, url });
                        }}
                        size="small"
                        sx={{ ml: 'auto' }}
                    >
                        <DownloadIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Box>
        );
    });
};

const mapDispatchToProps = (dispatch) => ({
    downloadFile: (params) => dispatch(downloadAfterSalesFile(params)),
});

ElectricBills.propTypes = {
    downloadFile: PropTypes.func,
    periods: PropTypes.array,
    showAll: PropTypes.bool,
    sxItem: PropTypes.object,
};

export default connect(null, mapDispatchToProps)(ElectricBills);
