import { updateProjectFields } from 'common/api/afterSales/projects';
import { showFastApiErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_DATA,
    SAVE_DATA_FAILURE,
    SAVE_DATA_SUCCESS,
} from '../actionTypes';
import { SOURCE_FIELD_KEYS } from '../constants';
import { actions } from '../reducer';

export default ({ successCallback, values }) =>
    (dispatch) => {
        if (!values?.project_id) return;
        dispatch(actions[SAVE_DATA]());

        const newValues = {};

        const fields = Object.values(SOURCE_FIELD_KEYS);

        for (const field of fields) newValues[field] = values[field] || false;

        updateProjectFields(values?.project_id, newValues)
            .then(() => {
                dispatch(actions[SAVE_DATA_SUCCESS]());
                showToast();

                if (successCallback) successCallback();
            })
            .catch((error) => {
                dispatch(actions[SAVE_DATA_FAILURE](error));
                showFastApiErrorsAsAlert(dispatch, error?.response);
            });
    };
