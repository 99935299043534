import { filterProposals } from 'common/api/v1/proposals';
import { formatDate, parseDate } from 'common/utils/dates';

import {
    FETCH_PROPOSALS,
    FETCH_PROPOSALS_FAILURE,
    FETCH_PROPOSALS_SUCCESS,
} from '../actionTypes';
import { STORAGE_RANGES, SYSTEM_RANGES } from '../constants';
import { proposalsActions } from '../reducer';

export default ({
        createdBy = 'all',
        endDate = '',
        isArchived = 'False',
        orderBy = 'created_at',
        origin = 'all',
        page = 0,
        pageSize = 25,
        rateName = 'all',
        searchText,
        sortBy = null,
        startDate = '',
        status = 'all',
        storageRange = 'all',
        systemRange = 'all',
    } = {}) =>
    (dispatch) => {
        const _endDate = parseDate(endDate, 'dd/MM/yyyy');
        const _startDate = parseDate(startDate, 'dd/MM/yyyy');

        dispatch(proposalsActions[FETCH_PROPOSALS]());

        filterProposals({
            createdBy,
            endDate: formatDate(_endDate, 'yyyy-MM-dd'),
            isArchived,
            orderBy,
            origin,
            page: page + 1,
            perPage: pageSize,
            rateName,
            searchText,
            sortBy,
            startDate: formatDate(_startDate, 'yyyy-MM-dd'),
            status,
            storageRange: STORAGE_RANGES[storageRange] || 'all',
            systemRange: SYSTEM_RANGES[systemRange] || 'all',
        })
            .then((response) =>
                dispatch(
                    proposalsActions[FETCH_PROPOSALS_SUCCESS](response.data),
                ),
            )
            .catch((error) =>
                dispatch(
                    proposalsActions[FETCH_PROPOSALS_FAILURE](
                        error?.response?.data?.errors,
                    ),
                ),
            );
    };
