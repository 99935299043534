import { NAME } from './constants';

export const ADD_ZAP_RPU = `${NAME}/ADD_ZAP_RPU`;
export const ADD_ZAP_RPU_FAILURE = `${NAME}/ADD_ZAP_RPU_FAILURE`;
export const ADD_ZAP_RPU_SUCCESS = `${NAME}/ADD_ZAP_RPU_SUCCESS`;

export const DELETE_DATA = `${NAME}/DELETE_DATA`;
export const DELETE_DATA_FAILURE = `${NAME}/DELETE_DATA_FAILURE`;
export const DELETE_DATA_SUCCESS = `${NAME}/DELETE_DATA_SUCCESS`;

export const FETCH_CURRENCIES = `${NAME}/FETCH_CURRENCIES`;
export const FETCH_CURRENCIES_FAILURE = `${NAME}/FETCH_CURRENCIES_FAILURE`;
export const FETCH_CURRENCIES_SUCCESS = `${NAME}/FETCH_CURRENCIES_SUCCESS`;

export const FETCH_CUSTOMER_CONSUMPTION = `${NAME}/FETCH_CUSTOMER_CONSUMPTION`;
export const FETCH_CUSTOMER_CONSUMPTION_FAILURE = `${NAME}/FETCH_CUSTOMER_CONSUMPTION_FAILURE`;
export const FETCH_CUSTOMER_CONSUMPTION_SUCCESS = `${NAME}/FETCH_CUSTOMER_CONSUMPTION_SUCCESS`;

export const FETCH_DATA = `${NAME}/FETCH_DATA`;
export const FETCH_DATA_FAILURE = `${NAME}/FETCH_DATA_FAILURE`;
export const FETCH_DATA_SUCCESS = `${NAME}/FETCH_DATA_SUCCESS`;

export const FETCH_LOCATIONS_BY_ZIP_CODE = `${NAME}/FETCH_LOCATIONS_BY_ZIP_CODE`;
export const FETCH_LOCATIONS_BY_ZIP_CODE_FAILURE = `${NAME}/FETCH_LOCATIONS_BY_ZIP_CODE_FAILURE`;
export const FETCH_LOCATIONS_BY_ZIP_CODE_SUCCESS = `${NAME}/FETCH_LOCATIONS_BY_ZIP_CODE_SUCCESS`;

export const FETCH_POLITICAL_DIVISIONS = `${NAME}/FETCH_POLITICAL_DIVISIONS`;
export const FETCH_POLITICAL_DIVISIONS_FAILURE = `${NAME}/FETCH_POLITICAL_DIVISIONS_FAILURE`;
export const FETCH_POLITICAL_DIVISIONS_SUCCESS = `${NAME}/FETCH_POLITICAL_DIVISIONS_SUCCESS`;

export const FETCH_POLITICAL_DIVISIONS2 = `${NAME}/FETCH_POLITICAL_DIVISIONS2`;
export const FETCH_POLITICAL_DIVISIONS2_FAILURE = `${NAME}/FETCH_POLITICAL_DIVISIONS2_FAILURE`;
export const FETCH_POLITICAL_DIVISIONS2_SUCCESS = `${NAME}/FETCH_POLITICAL_DIVISIONS2_SUCCESS`;

export const FETCH_RATE_DIVISIONS = `${NAME}/FETCH_RATE_DIVISIONS`;
export const FETCH_RATE_DIVISIONS_FAILURE = `${NAME}/FETCH_RATE_DIVISIONS_FAILURE`;
export const FETCH_RATE_DIVISIONS_SUCCESS = `${NAME}/FETCH_RATE_DIVISIONS_SUCCESS`;

export const FETCH_RATES = `${NAME}/FETCH_RATES`;
export const FETCH_RATES_FAILURE = `${NAME}/FETCH_RATES_FAILURE`;
export const FETCH_RATES_SUCCESS = `${NAME}/FETCH_RATES_SUCCESS`;

export const FETCH_REGIONS = `${NAME}/FETCH_REGIONS`;
export const FETCH_REGIONS_FAILURE = `${NAME}/FETCH_REGIONS_FAILURE`;
export const FETCH_REGIONS_SUCCESS = `${NAME}/FETCH_REGIONS_SUCCESS`;

export const FETCH_SCHEDULE_RATE_CONFIGURATION = `${NAME}/FETCH_SCHEDULE_RATE_CONFIGURATION`;
export const FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE = `${NAME}/FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE`;
export const FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS = `${NAME}/FETCH_SCHEDULE_RATE_CONFIGURATION_SUCCESS`;

export const FETCH_SUMMER_MONTHS = `${NAME}/FETCH_SUMMER_MONTHS`;
export const FETCH_SUMMER_MONTHS_FAILURE = `${NAME}/FETCH_SUMMER_MONTHS_FAILURE`;
export const FETCH_SUMMER_MONTHS_SUCCESS = `${NAME}/FETCH_SUMMER_MONTHS_SUCCESS`;

export const FETCH_TEMPLATES = `${NAME}/FETCH_TEMPLATES`;
export const FETCH_TEMPLATES_FAILURE = `${NAME}/FETCH_TEMPLATES_FAILURE`;
export const FETCH_TEMPLATES_SUCCESS = `${NAME}/FETCH_TEMPLATES_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const INITIALIZE_SUNPAL_CONFIG = `${NAME}/INITIALIZE_SUNPAL_CONFIG`;

export const RESET_FORM = `${NAME}/RESET_FORM`;
export const RESET_SUNPAL_CONFIG_FORM = `${NAME}/RESET_SUNPAL_CONFIG_FORM`;

export const SAVE_DATA = `${NAME}/SAVE_DATA`;
export const SAVE_DATA_FAILURE = `${NAME}/SAVE_DATA_FAILURE`;
export const SAVE_DATA_SUCCESS = `${NAME}/SAVE_DATA_SUCCESS`;

export const SET_MODAL_FORM_OPEN_STATUS = `${NAME}/SET_MODAL_FORM_OPEN_STATUS`;

export const FETCH_SUNPAL_CONFIG = `${NAME}/FETCH_SUNPAL_CONFIG`;
export const FETCH_SUNPAL_CONFIG_FAILURE = `${NAME}/FETCH_SUNPAL_CONFIG_FAILURE`;
export const FETCH_SUNPAL_CONFIG_SUCCESS = `${NAME}/FETCH_SUNPAL_CONFIG_SUCCESS`;

export const SAVE_SUNPAL_CONFIG = `${NAME}/SAVE_SUNPAL_CONFIG`;
export const SAVE_SUNPAL_CONFIG_FAILURE = `${NAME}/SAVE_SUNPAL_CONFIG_FAILURE`;
export const SAVE_SUNPAL_CONFIG_SUCCESS = `${NAME}/SAVE_SUNPAL_CONFIG_SUCCESS`;
