import React from 'react';

import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Box, ListItemButton } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

const ResultItem = ({
    clearSearch,
    extraContent,
    icon = <PersonIcon />,
    mainContent,
    to,
}) => {
    return (
        <ListItemButton
            onClick={clearSearch}
            component={RouterLink}
            sx={{
                display: 'block',
                fontSize: '12px',
                fontWeight: 'bold',
                mb: 1,
                ':focus': { outline: '1px solid' },
            }}
            to={to}
            underline="none"
        >
            <Box sx={{ display: 'flex', gap: 1 }}>
                <Box>
                    <Avatar>{icon}</Avatar>
                </Box>
                <Box sx={{ color: 'secondary.main' }}>
                    <Box sx={{ fontSize: '14px', fontWeight: '600' }}>
                        {mainContent}
                    </Box>
                    <Box sx={{ fontSize: '10px', fontWeight: '500' }}>
                        {extraContent}
                    </Box>
                </Box>
            </Box>
        </ListItemButton>
    );
};

ResultItem.propTypes = {
    clearSearch: PropTypes.func,
    extraContent: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    icon: PropTypes.node,
    mainContent: PropTypes.node,
    to: PropTypes.node,
};

export default ResultItem;
