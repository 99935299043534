import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import {
    ReactHookFormSelect2,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';
import { getOppositeCurrency } from 'common/utils/helpers';

import * as multiCurrencySelectors from '../../multiCurrency/selectors';
import { getNewTotalCost } from '../helpers';

import FormAccordion from './FormAccordion';

const CurrencyFields = ({
    control,
    currencies,
    currenciesDictionary,
    currenciesForSelect,
    disabled,
    getValues,
    isLoadingCurrencies,
    selectedCurrency,
    setSelectedCurrency,
    setValue,
}) => {
    const { t } = useTranslation();
    const currency = useWatch({ control, name: 'currency' });
    const oppositeCurrency = getOppositeCurrency(currencies);

    useEffect(() => {
        setSelectedCurrency(currenciesDictionary?.[currency]);
    }, [currency, currenciesDictionary]);

    const handleOnChangeCurrency = (value) => {
        if (
            !value ||
            !currenciesDictionary ||
            !selectedCurrency ||
            value === selectedCurrency?.id
        )
            return;

        const newTotalCost = getNewTotalCost({
            currency: currenciesDictionary[value],
            getValues,
        });

        setValue('total_cost', newTotalCost);
    };

    return (
        <FormAccordion title={t('Currency')}>
            <Grid container>
                <Grid size={{ sm: 9, xs: 18 }}>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={disabled || isLoadingCurrencies}
                        isLoading={isLoadingCurrencies}
                        label={t('Currency')}
                        name="currency"
                        onChange={handleOnChangeCurrency}
                        options={currenciesForSelect}
                        variant="standard"
                    />
                </Grid>

                <Grid size={{ md: 9, xs: 18 }}>
                    <ReactHookFormIntlNumberInput
                        append={oppositeCurrency?.abbreviation}
                        control={control}
                        disabled={disabled}
                        fullWidth
                        label={t('Exchange rate')}
                        name="type_change"
                        variant="standard"
                    />
                </Grid>
            </Grid>
        </FormAccordion>
    );
};

const mapStateToProps = createStructuredSelector({
    currencies: multiCurrencySelectors.getDataFetchCurrencies,
    currenciesDictionary:
        multiCurrencySelectors.getDataFetchCurrenciesDictionary,
    currenciesForSelect: multiCurrencySelectors.getDataFetchCurrenciesForSelect,
    isLoadingCurrencies: multiCurrencySelectors.getIsFetchingCurrencies,
});

CurrencyFields.propTypes = {
    control: PropTypes.object,
    currencies: PropTypes.array,
    currenciesDictionary: PropTypes.object,
    currenciesForSelect: PropTypes.array,
    disabled: PropTypes.bool,
    getValues: PropTypes.func,
    isLoadingCurrencies: PropTypes.bool,
    selectedCurrency: PropTypes.object,
    setSelectedCurrency: PropTypes.func,
    setValue: PropTypes.func,
};

export default connect(mapStateToProps)(CurrencyFields);
