import { number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default (_, { maximumDiscount, maxDiscountByUnitPrice }) => {
    const { getMaxValueText, NUMBER_TYPE_TEXT, REQUIRED_TEXT } =
        getValidationTexts();

    return object().shape({
        discount_amount: number().when('type_discount', {
            is: 2,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .max(
                        maxDiscountByUnitPrice,
                        getMaxValueText(maxDiscountByUnitPrice),
                    )
                    .nullable(),
            otherwise: () => string().nullable(),
        }),
        discount_percentage: number().when('type_discount', {
            is: 0,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .max(maximumDiscount, getMaxValueText(maximumDiscount))
                    .nullable(),
            otherwise: () => string().nullable(),
        }),
    });
};
