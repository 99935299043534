import { parse } from 'date-fns';
import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

import { HIGH_TENSION_RATES, HOURLY_RATES } from 'common/constants/rates';
import {
    addDate,
    differenceInDaysDate,
    differenceInHoursDate,
    differenceInYearsDate,
    formatDate,
    parseDate,
    subDate,
} from 'common/utils/dates';
import { numberFormat } from 'common/utils/helpers';
import { getLocaleEquivalenceForDateFns } from 'common/utils/helpers/multiregion';
import {
    getFieldsInPeriod,
    getIsHourlyRate,
    getTierValue,
} from 'common/utils/helpers/rates';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';
import { hexToRgba } from 'common/utils/helpersChart';

import { CHART_COLORS } from '../afterSalesMonitoring/constants';
import { DATE_FORMATS } from '../afterSalesSettings/constants';

import { CHART_OPTIONS } from './components/Form/MonitoringStep';
import {
    ALLOWED_NEGATIVE_CONCEPTS,
    CAPTURE_MODE,
    DEMAND_CONCEPTS,
    DEMAND_METHODS,
    ENERGY_CONCEPTS,
    ENERGY_CONCEPTS_CONFIG,
    ENERGY_EQUATIONS,
} from './constants';

/** Queue  **/
class PriorityQueue {
    constructor(elements = []) {
        this.queue = [...elements];
        this.sort();
    }

    enqueue(element) {
        this.queue.push(element);
        this.sort();
    }

    dequeue() {
        if (this.isEmpty()) return null;
        return this.queue.shift();
    }

    sort() {
        this.queue.sort((a, b) => a.priority - b.priority);
    }

    isEmpty() {
        return this.queue.length === 0;
    }

    updatePriorities(updateFunction) {
        for (const element of this.queue)
            element.priority = updateFunction(element.value);

        this.sort();
    }
}

export const getPeriodLabel = (item) => {
    if (!item?.final_date) return 'N/A';
    const parsedFinalDate = parseDate(item.final_date, DATE_FORMATS.SUNWISE);
    const labelDate = subDate(parsedFinalDate, {
        days: item.is_bimonthly ? 30 : 15,
    });
    return formatDate(labelDate, 'MMM yy');
};

export function getEnergyConceptsConfigAsArray(concepts) {
    if (!concepts?.length) return [];

    const config = [];

    for (const concept of concepts) {
        const conceptConfig = ENERGY_CONCEPTS_CONFIG[concept];

        if (!conceptConfig) continue;

        config.push({ key: concept, label: conceptConfig.label });
    }

    return config;
}

/** Energy balance  **/
export const cleanValue = (value, allowDecimals = false) => {
    const _value = allowDecimals
        ? Number.parseFloat(value)
        : Number.parseInt(value);
    if (!_value && _value !== 0) return null;
    return _value;
};

export const resolveFieldValue = (field) => {
    if (!field) return null;
    return cleanValue(field.value) ?? cleanValue(field.placeholder);
};

const formatHistoryField = (field, oldField, allowDecimals) => {
    const value = cleanValue(oldField?.value, allowDecimals);
    return {
        capture_mode:
            value === null
                ? CAPTURE_MODE.USER
                : (oldField?.capture_mode ?? CAPTURE_MODE.AUTOMATIC),
        label: field?.label || '',
        placeholder: oldField?.placeholder || '0',
        tier: field?.tier,
        value,
    };
};

export const getConsumedBag = (totalValues, availablePool) => {
    if (!totalValues || !availablePool) return 0;

    let consumedPool = 0;

    const netConsumption = resolveFieldValue(
        totalValues?.[ENERGY_CONCEPTS.NET_CONSUMPTION],
    );

    if (netConsumption && netConsumption > 0)
        consumedPool += netConsumption || 0;

    return Math.min(consumedPool, availablePool);
};

export const getConsumptionHistoryFields = ({
    rate,
    rateConfiguration,
    values,
}) => {
    const keys = { demand: [], energy: [] };

    const fields = { demand: {}, energy: {}, no_solar_demand: {}, total: {} };

    if (!values?.final_date || !values?.initial_date || !rate)
        return { fields, keys };

    const { kW: demandFields, kWh: energyFields } = getFieldsInPeriod({
        finalDate: values.final_date,
        initialDate: values.initial_date,
        rate: { ...rate, isCertified: rate.certified },
        tiers_energy_distribution: rateConfiguration?.tiers_energy_distribution,
    });
    keys.energy = Object.keys(energyFields);
    keys.demand = Object.keys(demandFields);

    const energyConcepts = Object.values(ENERGY_CONCEPTS);

    for (const key of keys.energy) {
        let temp = {};
        for (const concept of energyConcepts) {
            temp[concept] = formatHistoryField(
                energyFields[key],
                values?.energy?.[key]?.[concept],
            );
        }

        const hasNetMetValues =
            temp[ENERGY_CONCEPTS.NET_CONSUMPTION]?.value !== null;
        let initialCalculated = [];

        if (hasNetMetValues) {
            const { conceptsCalculated, newValues } =
                calculateRawNetConsumption(temp);

            temp = newValues;
            initialCalculated = conceptsCalculated;
        }

        temp = calculateEnergyBalance({ initialCalculated, variables: temp });

        if (!hasNetMetValues) {
            temp = calculateNetConsumption(temp);
        }

        fields.energy[key] = temp;
    }

    let initialCalculated = [];
    let totalTemp = {};
    for (const concept of energyConcepts)
        totalTemp[concept] = formatHistoryField(null, values?.total?.[concept]);

    const hasNetMetValues =
        totalTemp[ENERGY_CONCEPTS.NET_CONSUMPTION]?.value !== null;

    if (hasNetMetValues) {
        const { conceptsCalculated, newValues } =
            calculateRawNetConsumption(totalTemp);
        totalTemp = newValues;
        initialCalculated = conceptsCalculated;
    }

    totalTemp = calculateEnergyBalance({
        initialCalculated,
        variables: totalTemp,
    });

    if (!hasNetMetValues) {
        totalTemp = calculateNetConsumption(totalTemp);
    }

    fields.total = totalTemp;

    for (const key of keys.demand) {
        fields.demand[key] = formatHistoryField(
            demandFields[key],
            values?.demand?.[key],
            true,
        );

        fields.no_solar_demand[key] = formatHistoryField(
            demandFields[key],
            values?.no_solar_demand?.[key],
            true,
        );
    }

    const contractedDemandTiers =
        rateConfiguration?.tiers_demand_distribution?.[0]?.tiers;

    if (!rate.certified && contractedDemandTiers?.length > 0) {
        fields.hourly_contracted_demand = {};
        keys.hourly_contracted_demand = [];
        for (const tier of contractedDemandTiers) {
            const tierName = tier?.name?.toLowerCase();

            fields.hourly_contracted_demand[tierName] = formatHistoryField(
                { label: tierName, tier: tier?.identifier },
                values?.hourly_contracted_demand?.[tierName],
                true,
            );
            keys.hourly_contracted_demand.push(tierName);
        }
    }

    if (HIGH_TENSION_RATES.includes(rate.name))
        fields.contracted_demand = values?.contracted_demand ?? null;

    return { fields, keys };
};

export const calculateEnergyBalance = ({
    initialCalculated = [],
    path,
    setValue,
    variables,
}) => {
    const scope = { ...variables };
    const placeholderCalculated = new Set(initialCalculated);

    // we use a priority queue to calculate the variables in order of importance
    const equationQueue = new PriorityQueue(
        ENERGY_EQUATIONS.map((equation, index) => ({
            priority: getUnknownsRatio(equation, scope, placeholderCalculated),
            value: index,
        })),
    );

    while (!equationQueue.isEmpty()) {
        const { value: equationIndex } = equationQueue.dequeue();
        const equation = ENERGY_EQUATIONS[equationIndex];
        const unknownVariables = [];
        let sum = 0;
        for (const variable in equation) {
            const value = cleanValue(scope[variable]?.value);
            const placeholder = cleanValue(scope[variable]?.placeholder);
            if (value !== null) {
                sum += equation[variable] * value;
            } else if (placeholderCalculated.has(variable)) {
                // if the current variable has been calculated in a previous equation, we use the placeholder value
                sum += equation[variable] * placeholder;
            } else {
                unknownVariables.push(variable);
            }
        }

        for (const variable of unknownVariables) {
            const newValue = -sum / equation[variable];

            if (!scope[variable]) scope[variable] = formatHistoryField();
            scope[variable].placeholder = '0';

            if (newValue > 0 || ALLOWED_NEGATIVE_CONCEPTS.includes(variable)) {
                sum += equation[variable] * newValue;
                scope[variable].placeholder = `${newValue}`;
            }

            if (setValue) setValue(`${path}.${variable}`, scope[variable]);
            placeholderCalculated.add(variable);
        }
        // we update the priorities of the remaining equations with the new values
        equationQueue.updatePriorities((value) =>
            getUnknownsRatio(
                ENERGY_EQUATIONS[value],
                scope,
                placeholderCalculated,
            ),
        );
    }

    return scope;
};

export const getHasBagEnergyField = (rate, compensationScheme) =>
    compensationScheme === 'NETMET' &&
    rate?.certified &&
    !HOURLY_RATES.includes(rate.name);

const getUnknownsRatio = (equation, scope, calculated) => {
    let count = 0;
    let total = 0;
    for (const variable in equation) {
        if (
            cleanValue(scope?.[variable]?.value) === null &&
            !calculated?.has(variable)
        )
            count++;
        total++;
    }
    return count / total;
};

export const calculateNetConsumption = (values, path, setValue) => {
    const fields = { ...values };
    const rawNetConsumption =
        resolveFieldValue(values?.[ENERGY_CONCEPTS.RAW_NET_CONSUMPTION]) ?? 0;

    if (!fields?.[ENERGY_CONCEPTS.NET_CONSUMPTION])
        fields[ENERGY_CONCEPTS.NET_CONSUMPTION] = formatHistoryField();
    if (!fields?.[ENERGY_CONCEPTS.BAG_ENERGY])
        fields[ENERGY_CONCEPTS.BAG_ENERGY] = formatHistoryField();

    if (rawNetConsumption >= 0) {
        fields[ENERGY_CONCEPTS.NET_CONSUMPTION].placeholder =
            `${rawNetConsumption}`;
        fields[ENERGY_CONCEPTS.BAG_ENERGY].placeholder = '0';
    } else {
        fields[ENERGY_CONCEPTS.BAG_ENERGY].placeholder =
            `${-rawNetConsumption}`;
        fields[ENERGY_CONCEPTS.NET_CONSUMPTION].placeholder = '0';
    }

    if (setValue) {
        setValue(
            `${path}.${ENERGY_CONCEPTS.NET_CONSUMPTION}`,
            fields[ENERGY_CONCEPTS.NET_CONSUMPTION],
        );
        setValue(
            `${path}.${ENERGY_CONCEPTS.BAG_ENERGY}`,
            fields[ENERGY_CONCEPTS.BAG_ENERGY],
        );
    }

    return fields;
};

export const calculateRawNetConsumption = (values, path, setValue) => {
    const newValues = { ...values };
    let conceptsCalculated = [];

    const netConsumption = cleanValue(
        values?.[ENERGY_CONCEPTS.NET_CONSUMPTION]?.value,
    );

    if (!newValues[ENERGY_CONCEPTS.NET_CONSUMPTION])
        newValues[ENERGY_CONCEPTS.NET_CONSUMPTION] = formatHistoryField();

    if (!newValues[ENERGY_CONCEPTS.RAW_NET_CONSUMPTION])
        newValues[ENERGY_CONCEPTS.RAW_NET_CONSUMPTION] = formatHistoryField();
    if (!newValues[ENERGY_CONCEPTS.BAG_ENERGY])
        newValues[ENERGY_CONCEPTS.BAG_ENERGY] = formatHistoryField();

    if (netConsumption === null || netConsumption < 0) {
        newValues[ENERGY_CONCEPTS.NET_CONSUMPTION].placeholder = '0';
        newValues[ENERGY_CONCEPTS.RAW_NET_CONSUMPTION].placeholder = '0';
        newValues[ENERGY_CONCEPTS.BAG_ENERGY].placeholder = '0';
    } else {
        newValues[ENERGY_CONCEPTS.RAW_NET_CONSUMPTION].placeholder =
            `${netConsumption}`;

        newValues[ENERGY_CONCEPTS.BAG_ENERGY].placeholder = '0';

        conceptsCalculated = [
            ENERGY_CONCEPTS.RAW_NET_CONSUMPTION,
            ENERGY_CONCEPTS.NET_CONSUMPTION,
            ENERGY_CONCEPTS.BAG_ENERGY,
        ];
    }

    if (setValue) {
        setValue(
            `${path}.${ENERGY_CONCEPTS.RAW_NET_CONSUMPTION}`,
            newValues[ENERGY_CONCEPTS.RAW_NET_CONSUMPTION],
        );
        setValue(
            `${path}.${ENERGY_CONCEPTS.NET_CONSUMPTION}`,
            newValues[ENERGY_CONCEPTS.NET_CONSUMPTION],
        );
        setValue(
            `${path}.${ENERGY_CONCEPTS.BAG_ENERGY}`,
            newValues[ENERGY_CONCEPTS.BAG_ENERGY],
        );
    }
    return { conceptsCalculated, newValues };
};

export const calculateTotalEnergyConcepts = (values, setValue) => {
    if (!values?.total || !values?.fields_keys?.energy?.length) return;
    const { energy, fields_keys } = values;
    const energyConcepts = Object.values(ENERGY_CONCEPTS);
    const newValues = {};

    for (const concept of energyConcepts) {
        let sumPlaceholder = 0;
        for (const key of fields_keys.energy) {
            const placeholder = resolveFieldValue(energy[key]?.[concept]);

            if (placeholder !== null) sumPlaceholder += placeholder;
        }
        const newField = {
            capture_mode: CAPTURE_MODE.AUTOMATIC,
            placeholder: `${sumPlaceholder}`,
            value: null,
        };

        if (!newValues[concept]) newValues[concept] = newField;
        if (setValue) setValue(`total.${concept}`, newField);
    }

    return newValues;
};

export const validateEnergyBalance = (values) => {
    if (!values) return {};
    const { energy, fields_keys, total } = values;
    const errors = {};
    let hasErrors = false;

    for (const equation of ENERGY_EQUATIONS) {
        for (const key of fields_keys.energy) {
            if (!errors[key]) errors[key] = {};
            const fieldGroup = energy[key];

            const fieldsHasErrors = setFieldsHasErrors(
                errors[key],
                equation,
                fieldGroup,
            );

            hasErrors = hasErrors || fieldsHasErrors;
        }

        if (!errors.total) errors.total = {};

        const fieldsHasErrors = setFieldsHasErrors(
            errors.total,
            equation,
            total,
        );

        hasErrors = hasErrors || fieldsHasErrors;
    }

    return { errors, hasErrors };
};

const validateNetConsumption = (values) => {
    const netConsumption =
        resolveFieldValue(values?.[ENERGY_CONCEPTS.NET_CONSUMPTION]) ?? 0;

    const pool = resolveFieldValue(values?.[ENERGY_CONCEPTS.BAG_ENERGY]) ?? 0;

    const totalConsumption =
        resolveFieldValue(values?.[ENERGY_CONCEPTS.CONSUMPTION]) ?? 0;

    const totalGeneration =
        resolveFieldValue(values?.[ENERGY_CONCEPTS.GENERATION]) ?? 0;

    return (
        pool >= 0 &&
        netConsumption >= 0 &&
        totalConsumption - totalGeneration === netConsumption - pool
    );
};

const setFieldsHasErrors = (errors, equation, fieldGroup) => {
    let sum = 0;
    const fieldsWithValues = [];
    const fieldsWithoutValues = [];
    let errorValue = false;

    for (const concept in equation) {
        if (concept === ENERGY_CONCEPTS.CONSUMPTION && !errorValue) {
            errorValue = !validateNetConsumption(fieldGroup) || errorValue;

            if (
                cleanValue(
                    fieldGroup?.[ENERGY_CONCEPTS.NET_CONSUMPTION]?.value,
                ) !== null &&
                !fieldsWithValues.includes(ENERGY_CONCEPTS.NET_CONSUMPTION)
            )
                fieldsWithValues.push(ENERGY_CONCEPTS.NET_CONSUMPTION);
        }

        const value = cleanValue(fieldGroup?.[concept]?.value);
        if (value < 0) {
            errors[concept] = true;
            errorValue = true;
        } else if (value !== null) {
            sum += equation[concept] * value;
            fieldsWithValues.push(concept);
        } else fieldsWithoutValues.push(concept);
    }

    if (
        fieldsWithoutValues.length === 1 &&
        !ALLOWED_NEGATIVE_CONCEPTS.includes(fieldsWithoutValues[0]) &&
        -sum / equation[fieldsWithoutValues[0]] < 0
    ) {
        errors[fieldsWithoutValues[0]] = true;
        errorValue = true;
    } else if (sum !== 0 && fieldsWithoutValues.length === 0) {
        errorValue = true;
    }

    for (const field of fieldsWithValues)
        if (!errors[field]) errors[field] = errorValue;

    return errorValue;
};

/** FORM ACTIONS **/
export const getSectionHasErrors = (errors, fields) => {
    if (!errors || !fields?.length) return false;
    return fields.some((key) => errors[key]);
};

const getDemandFieldsFromArray = (values, tiers) => {
    let temp = getFieldsObjectFromArray(values, tiers);
    if (!temp && values?.length > 0) {
        const firstDemand = values[0];
        temp = {
            peak: {
                capture_mode: firstDemand?.capture_mode,
                value: firstDemand?.value,
            },
        };
    }
    return temp;
};

export const getPPAPrice = ({
    initialDate,
    percentageIncrease,
    price,
    startDate,
}) => {
    if (!price || !initialDate || !startDate) return 0;

    const parsedInitialDate = parseDate(initialDate, DATE_FORMATS.SUNWISE);
    const parsedStartDate = parseDate(startDate, DATE_FORMATS.SUNWISE);

    const years = differenceInYearsDate(parsedInitialDate, parsedStartDate);
    if (years < 0) return 0;
    return price * (1 + (percentageIncrease || 0) / 100) ** years;
};

export const handleOnChangeEnergyFields = ({
    getValues,
    key,
    setEnergyBalanceErrors,
    setValue,
}) => {
    const initial_date = getValues('initial_date');
    const final_date = getValues('final_date');
    if (!initial_date || !final_date) return;

    let path = 'total';
    if (key) path = `energy.${key}`;

    let initialCalculated = [];
    let previousValues = getValues(path);
    const hasNetMetValues =
        previousValues?.[ENERGY_CONCEPTS.NET_CONSUMPTION]?.value !== null;

    if (hasNetMetValues) {
        const { conceptsCalculated, newValues } = calculateRawNetConsumption(
            previousValues,
            path,
            setValue,
        );
        initialCalculated = conceptsCalculated;
        previousValues = newValues;
    }

    previousValues = calculateEnergyBalance({
        initialCalculated,
        path,
        setValue,
        variables: previousValues,
    });
    if (!hasNetMetValues)
        calculateNetConsumption(previousValues, path, setValue);

    if (key) calculateTotalEnergyConcepts(getValues(), setValue);

    if (setEnergyBalanceErrors)
        setEnergyBalanceErrors(validateEnergyBalance(getValues()));
};

export const handleOnChangeNetConsumption = ({
    getValues,
    key,
    setEnergyBalanceErrors,
    setValue,
}) => {
    const initial_date = getValues('initial_date');
    const final_date = getValues('final_date');
    if (!initial_date || !final_date) return;
    let path = 'total';
    if (key) path = `energy.${key}`;

    const { conceptsCalculated: initialCalculated, newValues } =
        calculateRawNetConsumption(getValues(path), path, setValue);

    const energyBalance = calculateEnergyBalance({
        initialCalculated,
        path,
        setValue,
        variables: newValues,
    });
    if (!initialCalculated?.length)
        calculateNetConsumption(energyBalance, path, setValue);

    if (key) calculateTotalEnergyConcepts(getValues(), setValue);
    setEnergyBalanceErrors(validateEnergyBalance(getValues()));
};

export const resetEnergyFields = ({
    formValues,
    rateConfiguration,
    rate,
    reset,
}) => {
    if (isEmpty(rate)) return;

    const { fields, keys } = getConsumptionHistoryFields({
        rate,
        rateConfiguration,
        values: formValues,
    });
    const newValues = { ...formValues, ...fields, fields_keys: keys };
    if (reset) reset(newValues);
    return newValues;
};

export const buildInitialValues = (item, rateConfiguration) => {
    const values = {
        applied_bag: item?.applied_bag || 0,
        contracted_demand: item?.contracted_demand?.[0]?.value ?? null,
        demand_method: item?.demand_method || DEMAND_METHODS.MEASUREMENT_SOURCE,
        exported_energy_price: item?.exported_energy_price || 0,
        file: item?.file,
        final_date: item?.final_date,
        id: item?.id,
        initial_date: item?.initial_date,
        is_bimonthly: item?.is_bimonthly ? '1' : '0',
        netted_exported_generation_price:
            item?.netted_exported_generation_price || 0,
        periodicity_type: item?.periodicity_type,
        power_factor: item?.power_factor,
        ppa_active: item?.ppa_active,
        ppa_price: item?.ppa_price,
        project: item?.project?.id,
        provider_data: item?.provider_data || null,
        rate: item?.rate?.id,
        rate_division_summer: item?.rate_division_summer?.id,
        subsidy_rate: item?.subsidy_rate?.name,
        total: item?.total,
        upload_origin: item?.upload_origin ?? null,
    };

    const isHourlyRate = getIsHourlyRate({
        formType: item?.rate?.form_type,
        isCertified: item?.rate?.certified,
        name: item?.rate?.name,
        paymentType: item?.rate?.payment_type,
    });
    const energyTiers = isHourlyRate
        ? rateConfiguration?.[0]?.tiers_energy_distribution?.[0]?.tiers
        : null;
    const demandTiers = isHourlyRate
        ? rateConfiguration?.[0]?.tiers_demand_distribution?.[0]?.tiers
        : null;

    values.demand = getDemandFieldsFromArray(item?.demand, energyTiers);

    values.energy = getEnergyFieldsObjectFromArray(item?.energy, energyTiers);

    values.hourly_contracted_demand = getFieldsObjectFromArray(
        item?.contracted_demand,
        demandTiers,
    );
    values.no_solar_demand = getDemandFieldsFromArray(
        item?.no_solar_demand,
        energyTiers,
    );

    return values;
};

export const populateFieldsFromIntegration = ({
    formValues,
    fields,
    fieldsConfig,
    setValue,
}) => {
    if (!fields || !fieldsConfig) return;

    const { energy, total } = fields;

    const energyKeys = formValues?.fields_keys?.energy || [];

    if (energyKeys.length > 0) {
        for (const key of energyKeys) {
            for (const concept in energy[key]) {
                if (
                    !energy?.[key]?.[concept] ||
                    !fieldsConfig?.[`${key}-${concept}`]
                )
                    continue;
                setValue(`energy.${key}.${concept}`, energy[key][concept]);
            }
        }
    } else {
        for (const concept in total) {
            if (!total?.[concept] || !fieldsConfig?.[`total-${concept}`])
                continue;
            setValue(`total.${concept}`, total[concept]);
        }
    }
};

/** LISA **/
const getMappedFieldsFromLisa = (data) => {
    const fields = {};

    if (!data?.length) return fields;

    for (const field of data) {
        if (typeof field.tier !== 'string') continue;
        const tier = field.tier.toLowerCase();
        fields[tier] = {
            label: tier,
            capture_mode: CAPTURE_MODE.AUTOMATIC,
            value: field.consumption,
        };
    }

    return fields;
};

export const getFieldsFromIntegration = ({
    contractedDemandData,
    demandData,
    energyData,
    finalDate,
    generationData,
    initialDate,
    rate,
    rateConfiguration,
}) => {
    const keys = { demand: [], energy: [] };

    const fields = { demand: {}, energy: {}, total: {} };

    if (!finalDate || !initialDate || !rate) return { fields, keys };
    const hasNetConsumption =
        rate.certified && ['GDMTH', 'DIT', 'DIST'].includes(rate.name);
    const gridConsumptionKey = hasNetConsumption
        ? ENERGY_CONCEPTS.NET_CONSUMPTION
        : ENERGY_CONCEPTS.GRID_CONSUMPTION;
    const exportedEnergyKey = hasNetConsumption
        ? ENERGY_CONCEPTS.BAG_ENERGY
        : ENERGY_CONCEPTS.EXPORTED_ENERGY;

    const mappedEnergyData = getMappedFieldsFromLisa(energyData);
    const mappedDemandData = getMappedFieldsFromLisa(demandData);

    const {
        hasMissingFields,
        kW: demandFields,
        kWh: energyFields,
        missingFields,
    } = getFieldsInPeriod({
        discardValues: true,
        finalDate,
        initialDate,
        period: { kWh: mappedEnergyData, kW: mappedDemandData },
        rate: { ...rate, isCertified: rate.certified },
        tiers_energy_distribution: rateConfiguration?.tiers_energy_distribution,
    });
    keys.energy = Object.keys(energyFields);
    keys.demand = Object.keys(demandFields);

    let totalConsumption = 0;
    const hasEnergyTiers = keys.energy.length > 0;

    if (hasEnergyTiers)
        for (const key of keys.energy) {
            const field = energyFields[key];
            const temp = {};
            temp[gridConsumptionKey] = formatHistoryField(
                field,
                mappedEnergyData[key],
            );
            fields.energy[key] = temp;

            totalConsumption += temp[gridConsumptionKey]?.value || 0;
        }
    else {
        totalConsumption = Object.values(mappedEnergyData).reduce(
            (acc, field) => acc + (field?.value || 0),
            0,
        );
    }

    const totalTemp = {};

    totalTemp[gridConsumptionKey] = formatHistoryField(null, {
        capture_mode: CAPTURE_MODE.AUTOMATIC,
        value: totalConsumption,
    });
    totalTemp[exportedEnergyKey] = formatHistoryField(null, {
        capture_mode: CAPTURE_MODE.AUTOMATIC,
        value: generationData,
    });
    fields.total = calculateEnergyBalance({ variables: totalTemp });

    for (const key of keys.demand) {
        fields.demand[key] = formatHistoryField(
            demandFields[key],
            hasEnergyTiers
                ? mappedDemandData[key]
                : Object.values(mappedDemandData)[0],
            true,
        );
    }

    const contractedDemandTiers =
        rateConfiguration?.tiers_demand_distribution?.[0]?.tiers;

    if (!rate.certified && contractedDemandTiers?.length > 0) {
        fields.hourly_contracted_demand = {};
        keys.hourly_contracted_demand = [];
        const mappedContractedDemandData =
            getMappedFieldsFromLisa(contractedDemandData);

        for (const tier of contractedDemandTiers) {
            const tierName = tier?.name?.toLowerCase();

            fields.hourly_contracted_demand[tierName] = formatHistoryField(
                { label: tierName, tier: tier?.identifier },
                mappedContractedDemandData[tierName],
                true,
            );
            keys.hourly_contracted_demand.push(tierName);
        }
    }

    if (HIGH_TENSION_RATES.includes(rate.name))
        fields.contracted_demand = contractedDemandData[0]?.consumption ?? null;

    return { fields, hasMissingFields, keys, missingFields };
};

export const getFieldsObjectFromArray = (fields, tiers) => {
    if (!fields?.length || !tiers?.length) return null;

    const fieldsObject = {};

    const mappedTiers = tiers?.reduce((acc, tier) => {
        acc[tier.identifier] = tier;
        return acc;
    }, {});

    for (const field of fields) {
        const tier = mappedTiers[field.tier];
        if (!tier) continue;
        const tierName = tier?.name?.toLowerCase();
        fieldsObject[tierName] = field;
    }

    return fieldsObject;
};

export const getEnergyFieldsObjectFromArray = (fields, tiers) => {
    if (!fields?.length || !tiers?.length) return {};

    const fieldsObject = {};

    const mappedTiers = tiers?.reduce((acc, tier) => {
        acc[tier.identifier] = tier;
        return acc;
    }, {});

    for (const field of fields) {
        const tier = mappedTiers[field?.consumption?.tier];
        if (!tier) continue;
        const tierName = tier?.name?.toLowerCase();
        fieldsObject[tierName] = field;
    }

    return fieldsObject;
};

const getAvailableFieldConcepts = ({
    energyConceptsConfig,
    fieldGroup,
    tier,
}) => {
    if (!fieldGroup) return [];
    const availableFields = [];

    for (const [concept, { label }] of energyConceptsConfig) {
        const value = fieldGroup[concept]?.value;
        const tierName = fieldGroup[concept]?.label;

        if (!value && value !== 0) continue;

        availableFields.push({
            concept,
            conceptName: label,
            tier,
            tierName,
            value,
        });
    }

    return availableFields;
};

export const getAvailableFieldsByKey = ({ fields, fieldsKey }) => {
    if (!fields) return [];

    const energyConceptsConfig = Object.entries(ENERGY_CONCEPTS_CONFIG);

    return (fieldsKey?.length ? fieldsKey : [null]).flatMap((tier) => {
        const fieldGroup = tier ? fields[tier] : fields;
        const availableFields = getAvailableFieldConcepts({
            energyConceptsConfig,
            fieldGroup,
            tier,
        });
        return availableFields;
    });
};

/** MEASUREMENT SOURCE **/
const calculateAverageArray = (hasAverage, values) => {
    if (!hasAverage || !values?.length) return 0;
    return values.reduce((acc, demand) => acc + demand, 0) / values.length;
};

export const getDateDayAfter = (date) => {
    if (!date) return null;
    const parsedDate = parseDate(date, DATE_FORMATS.SUNWISE);
    return formatDate(addDate(parsedDate, { days: 1 }), DATE_FORMATS.SUNWISE);
};

const ensureValidOrDefault = (validate, value) => {
    if (!validate) return value;
    return validate(value) ? value : 0;
};

const getGroupedMeasuredDataByTier = ({
    fieldsToPopulate,
    rateConfiguration,
    timestamp,
    rate,
}) => {
    const groupedDataByTier = {};
    const maximums = {};
    let initialTierIndex = 0;
    let currentTierValue = 0;

    for (let index = 0; index < timestamp.length; index++) {
        /* We lie to the Date constructor to make it think that the date is UTC
       in order to avoid timezone issues */
        const isoDate = timestamp[index];
        const date = new Date(isoDate.includes('Z') ? isoDate : `${isoDate}Z`);

        const tierValue =
            getTierValue({
                day: date.getUTCDay(),
                hour: date.getUTCHours(),
                month: date.getUTCMonth(),
                tiers_distribution:
                    rateConfiguration?.tiers_energy_distribution?.[0],
            }) || 0;

        if (currentTierValue !== tierValue) {
            currentTierValue = tierValue;
            initialTierIndex = index;
        }

        if (!groupedDataByTier[tierValue]) groupedDataByTier[tierValue] = {};
        if (!maximums[tierValue]) maximums[tierValue] = {};

        for (const field of fieldsToPopulate) {
            const concept = field.concept;
            const value = Number(field.data?.[index]) || 0;

            if (!field.validation(value)) continue;

            if (!groupedDataByTier[tierValue][concept])
                groupedDataByTier[tierValue][concept] = 0;

            groupedDataByTier[tierValue][concept] += value;

            /* We calculate the average of the last 3 values for high tension 
            rates according to the CFE's methodology */
            const isAverageMaximum =
                rate?.certified && HIGH_TENSION_RATES.includes(rate.name);
            const hasAverageValues =
                field.data && index - 2 >= initialTierIndex;
            const _maximum = isAverageMaximum
                ? calculateAverageArray(hasAverageValues, [
                      ensureValidOrDefault(
                          field.validation,
                          field.data?.[index - 2],
                      ),
                      ensureValidOrDefault(
                          field.validation,
                          field.data?.[index - 1],
                      ),
                      value,
                  ])
                : value;

            if (!maximums[tierValue][concept]) maximums[tierValue][concept] = 0;
            if (_maximum > maximums[tierValue][concept])
                maximums[tierValue][concept] = _maximum;
        }
    }

    return { groupedDataByTier, maximums };
};

const convertKwToKwh = (value, minutes = 5) => (value * minutes) / 60;

const populateField = ({
    concept,
    convertToKWh,
    dataValues,
    field,
    isEnergy = true,
}) => {
    if (cleanValue(field?.value) !== null) return;
    const tier = field?.tier || 0;
    const newValue = dataValues?.[tier]?.[concept] ?? dataValues?.[concept];
    //We convert the value from kW to kWh at the end to reduce the number of operations
    const _value =
        isEnergy && convertToKWh ? convertKwToKwh(newValue) : newValue;
    const cleanedNewValue = cleanValue(_value, !isEnergy);

    if (!cleanedNewValue) return;

    const newField = {
        ...field,
        capture_mode: CAPTURE_MODE.AUTOMATIC,
        placeholder: '0',
        value: cleanedNewValue,
    };

    return newField;
};

const populateEnergyFields = ({
    convertToKWh,
    dataValues,
    fieldsToPopulate,
    formValues,
}) => {
    const fields = {};

    for (const { concept } of fieldsToPopulate) {
        fields[concept] = populateField({
            concept,
            convertToKWh,
            dataValues,
            field: formValues?.[concept],
        });
    }

    return fields;
};

const handlePopulateFields = ({
    fieldsToPopulate,
    formFields,
    groupedDataByTier,
    hasDemandValues,
    maximums,
    transformPowerToEnergy,
}) => {
    const { fields_keys, energy, total, demand } = formFields;

    const fields = {};

    if (fields_keys?.energy?.length > 0) {
        fields.energy = {};
        for (const key of fields_keys.energy)
            fields.energy[key] = populateEnergyFields({
                convertToKWh: transformPowerToEnergy,
                dataValues: groupedDataByTier,
                fieldsToPopulate,
                formValues: energy?.[key],
            });
    } else {
        fields.total = populateEnergyFields({
            convertToKWh: transformPowerToEnergy,
            dataValues: groupedDataByTier,
            fieldsToPopulate,
            formValues: total,
        });
    }

    if (fields_keys?.demand?.length > 0) {
        fields.demand = {};
        for (const key of fields_keys.demand)
            fields.demand[key] = populateField({
                concept: hasDemandValues
                    ? DEMAND_CONCEPTS.MEASURED
                    : ENERGY_CONCEPTS.GRID_CONSUMPTION,
                dataValues: maximums,
                field: demand?.[key],
                isEnergy: false,
            });
    }

    return fields;
};

export const populateFieldsFromMeasurements = ({
    data,
    getValues,
    rate,
    rateConfiguration,
    setValue,
}) => {
    const selectedFields = Object.entries(getValues('monitoring'))
        .map(([key, value]) => (value ? key : null))
        .filter(Boolean);

    if (!data?.timestamp?.length || !selectedFields?.length) return;

    const {
        total_consumption,
        generation,
        grid_consumption,
        self_consumption,
        timestamp,
    } = data;

    const fieldsToPopulate = [];

    const mappedData = {
        [ENERGY_CONCEPTS.CONSUMPTION]: total_consumption,
        [ENERGY_CONCEPTS.EXPORTED_ENERGY]: grid_consumption,
        [ENERGY_CONCEPTS.GENERATION]: generation,
        [ENERGY_CONCEPTS.GRID_CONSUMPTION]: grid_consumption,
        [ENERGY_CONCEPTS.SELF_CONSUMPTION]: self_consumption,
    };

    for (const field of selectedFields) {
        const isNegativeValue = field === ENERGY_CONCEPTS.EXPORTED_ENERGY;

        fieldsToPopulate.push({
            concept: field,
            data: mappedData[field],
            validation: (value) => (isNegativeValue ? value < 0 : value >= 0),
        });
    }

    const { groupedDataByTier, maximums } = getGroupedMeasuredDataByTier({
        fieldsToPopulate,
        rate,
        rateConfiguration,
        timestamp,
    });

    const fields = handlePopulateFields({
        fieldsToPopulate,
        formFields: getValues(),
        groupedDataByTier,
        maximums,
        transformPowerToEnergy: true,
    });

    for (const fieldGroup in fields) {
        if (!fields?.[fieldGroup]) continue;

        for (const tierGroup in fields[fieldGroup]) {
            if (!fields[fieldGroup]?.[tierGroup]) continue;

            for (const concept in fields[fieldGroup][tierGroup]) {
                if (!fields[fieldGroup][tierGroup]?.[concept]) continue;

                setValue(
                    `${fieldGroup}.${tierGroup}.${concept}`,
                    fields[fieldGroup][tierGroup][concept],
                );
            }
        }
    }
};

export const transformAfterSalesDateToSunwiseFormat = (date) => {
    if (!date || typeof date !== 'string') return null;
    return date.split('-').reverse().join('/');
};

export const transformSunwiseDateToAfterSalesFormat = (date) => {
    if (!date || typeof date !== 'string') return null;
    return date.split('/').reverse().join('-');
};

export const groupEnergyIndexesByDay = (timestamp) => {
    if (!timestamp?.length) return [];

    const firstDate = new Date(timestamp[0]);
    const lastDate = new Date(timestamp[timestamp.length - 1]);

    const daysDiff = differenceInDaysDate(lastDate, firstDate);

    const indexes = Array.from({ length: daysDiff + 1 }, () =>
        Array.from({ length: 24 * 12 }, () => null),
    );

    for (let i = 0; i < timestamp.length; i++) {
        const parsedDate = new Date(timestamp[i].replace('Z', ''));
        const dayIndex = differenceInDaysDate(parsedDate, firstDate);
        const minuteIndex = Math.floor(
            parsedDate.getMinutes() / 5 + parsedDate.getHours() * 12,
        );

        if (dayIndex < 0 || minuteIndex < 0) continue;

        indexes[dayIndex][minuteIndex] = i;
    }

    return indexes;
};

export const getGroupedDayLabel = ({ data, indexes }) => {
    const index = indexes?.find((v) => v !== null);
    if (!index && index !== 0) return;
    const parsedDate = new Date(data[index].replace('Z', ''));

    return formatDate(parsedDate, 'dd MMM');
};

const getSeriesLabels = ({ data, day, indexes, option }) => {
    if (!data?.length || !indexes?.length) return [];
    const labels = [];

    switch (option) {
        case 1: {
            for (const index of indexes[day]) {
                if (index === null) continue;

                const parsedDate = new Date(data[index].replace('Z', ''));

                labels.push(formatDate(parsedDate, 'HH:mm'));
            }
            break;
        }
        default: {
            for (const day of indexes) {
                const label = getGroupedDayLabel({ data, indexes: day });

                if (!label) continue;

                labels.push(label);
            }
            break;
        }
    }

    return labels;
};

const getSeriesData = ({ data, day, indexes, option, validation }) => {
    if (!data?.length || !indexes?.length) return [];
    const seriesData = [];

    switch (option) {
        case 1: {
            for (const index of indexes[day]) {
                if (index === null) continue;

                const isValid = !validation || validation(data[index]);

                seriesData.push(isValid ? Math.abs(data[index]) || 0 : 0);
            }
            break;
        }
        default: {
            for (const day of indexes) {
                let totalRow = null;
                for (const index of day) {
                    if (index === null) continue;
                    if (!totalRow) totalRow = 0;

                    if (validation && !validation(data[index])) continue;

                    totalRow += Math.abs(data[index]) || 0;
                }

                if (totalRow === null) continue;

                seriesData.push(totalRow);
            }
            break;
        }
    }

    if (option === CHART_OPTIONS.DAILY) return seriesData;
    return seriesData.map((v) => Math.floor(convertKwToKwh(v)));
};

export const getChartConfig = ({
    day,
    groupedIndexes,
    measuredEnergy,
    option,
}) => {
    const {
        estimated,
        generation,
        grid_consumption,
        self_consumption,
        timestamp,
        total_consumption,
    } = measuredEnergy || {};

    const countryCurrencyLocale = getCountryCurrencyLocale();

    const formatter = (val) =>
        numberFormat(val, {
            decimals: 2,
            locale: countryCurrencyLocale,
            style: 'decimal',
            unit: option === CHART_OPTIONS.DAILY ? 'kW' : 'kWh',
        });

    let tempSeries = [
        {
            data: generation,
            validation: (value) => value >= 0,
            key: ENERGY_CONCEPTS.GENERATION,
        },
        {
            data: total_consumption,
            validation: (value) => value >= 0,
            key: ENERGY_CONCEPTS.CONSUMPTION,
        },
        {
            data: self_consumption,
            validation: (value) => value >= 0,
            key: ENERGY_CONCEPTS.SELF_CONSUMPTION,
        },
        {
            data: grid_consumption,
            validation: (value) => value >= 0,
            key: ENERGY_CONCEPTS.GRID_CONSUMPTION,
        },
        {
            data: grid_consumption,
            validation: (value) => value < 0,
            key: ENERGY_CONCEPTS.EXPORTED_ENERGY,
        },
    ];

    const datasets = [];
    const totals = {};

    // Remove colors and series if no data
    for (let i = 0; i < tempSeries.length; i++) {
        const serie = tempSeries[i];

        if (!serie?.data?.length) continue;

        const baseColor =
            CHART_COLORS.measurement_source[
                i % CHART_COLORS.measurement_source.length
            ];

        datasets.push({
            backgroundColor: hexToRgba(baseColor, 0.8),
            borderColor: baseColor,
            data: getSeriesData({
                data: serie.data,
                day,
                indexes: groupedIndexes,
                option,
                validation: serie.validation,
            }),
            key: serie.key,
            fill: { opacity: 1 },
            label: i18next.t(ENERGY_CONCEPTS_CONFIG[serie.key]?.label),
        });

        const serieTotal = serie.data.reduce((acc, value) => {
            if (serie.validation && !serie.validation(value)) return acc;
            return acc + (Math.abs(value) || 0);
        }, 0);

        totals[serie.key] = Math.floor(convertKwToKwh(serieTotal));
    }

    const options = {
        interaction: { axis: 'x', intersect: false, mode: 'nearest' },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) =>
                        `${context.dataset.label}: ${formatter(
                            context.parsed.y,
                        )}`,
                    title: (context) => {
                        const ctx = context[0];
                        const label = ctx.label;
                        const index = ctx.dataIndex;
                        const estimatedCount = estimated?.[index];
                        if (!estimatedCount) return label;
                        return `${label} - ${i18next.t(
                            'Estimated',
                        )}: ${estimatedCount}`;
                    },
                },
            },
        },
        scales: {
            x: { grid: { display: false }, ticks: { maxTicksLimit: 12 } },
            y: {
                beginAtZero: true,
                ticks: { callback: formatter, maxTicksLimit: 4 },
            },
        },
        toolbar: { show: true },
    };

    return {
        data: {
            datasets,
            labels: getSeriesLabels({
                data: timestamp,
                day,
                indexes: groupedIndexes,
                option,
            }),
        },
        options,
        totals,
    };
};

/** CSV Importer */
export const validateCsvUploadColumn = (value) => {
    const parsedValue = Number(value);

    if ((!parsedValue && parsedValue !== 0) || parsedValue < 0)
        return {
            success: false,
            message: i18next.t('Minimum valid value is 0'),
        };

    return { success: true };
};

export const populateFieldsFromCSVData = ({
    columnsFormat,
    columnsMatch,
    getValues,
    rate,
    rateConfiguration,
    result,
}) => {
    let dateFormat = columnsFormat?.date || DATE_FORMATS.SUNWISE;
    const dateHasTime = dateFormat.includes('HH');
    const timeFormat = columnsFormat?.time?.trim() || '';

    if (!dateHasTime && timeFormat) dateFormat += ` ${timeFormat}`;

    let firstDate = null;
    let lastDate = null;
    const localeForDateFns = getLocaleEquivalenceForDateFns();
    const referenceDate = new Date();

    const fieldsToPopulate = [];
    const groupedDataByTier = {};
    const maximums = {};

    const energyConcepts = Object.values(ENERGY_CONCEPTS);

    for (const concept of energyConcepts) {
        if (!columnsMatch[concept]) continue;
        fieldsToPopulate.push({
            concept,
            data: [],
            validation: (value) => value >= 0,
        });
    }

    if (columnsMatch[DEMAND_CONCEPTS.MEASURED])
        fieldsToPopulate.push({
            concept: DEMAND_CONCEPTS.MEASURED,
            data: [],
            validation: (value) => value >= 0,
        });

    for (const row of result) {
        if (!row?.date) continue;

        for (const field of fieldsToPopulate)
            field.data.push(Number(row[field.concept]) || 0);

        const date =
            !dateHasTime && timeFormat ? `${row.date} ${row.time}` : row.date;

        const parsedDate = parse(date, dateFormat, referenceDate, {
            locale: localeForDateFns,
        });

        if (!firstDate || parsedDate < firstDate) firstDate = parsedDate;
        if (!lastDate || parsedDate > lastDate) lastDate = parsedDate;

        if (!fieldsToPopulate?.length) continue;

        /* We lie to the Date constructor to make it think that the date is UTC
       in order to avoid timezone issues */

        const timezoneOffset = parsedDate.getTimezoneOffset() * 60000; // 1 minute = 60000 milliseconds

        const utcDate = new Date(parsedDate.getTime() - timezoneOffset);

        const tierValue =
            getTierValue({
                day: utcDate.getUTCDay(),
                hour: utcDate.getUTCHours(),
                month: utcDate.getUTCMonth(),
                tiers_distribution:
                    rateConfiguration?.tiers_energy_distribution?.[0],
            }) || 0;

        const index = fieldsToPopulate[0]?.data?.length - 1 || 0;

        if (!groupedDataByTier[tierValue]) groupedDataByTier[tierValue] = {};
        if (maximums && !maximums[tierValue]) maximums[tierValue] = {};

        for (const field of fieldsToPopulate) {
            const concept = field.concept;
            const value = field.data[index];

            if (!field.validation(value)) continue;

            if (!groupedDataByTier[tierValue][concept])
                groupedDataByTier[tierValue][concept] = 0;

            groupedDataByTier[tierValue][concept] += value;

            if (
                ![
                    ENERGY_CONCEPTS.GRID_CONSUMPTION,
                    DEMAND_CONCEPTS.MEASURED,
                ].includes(concept)
            )
                continue;

            if (!maximums[tierValue][concept]) maximums[tierValue][concept] = 0;

            if (value > maximums[tierValue][concept])
                maximums[tierValue][concept] = value;
        }
    }

    firstDate.setDate(firstDate.getDate() - 1);
    const initialDate = formatDate(firstDate, DATE_FORMATS.SUNWISE);
    const finalDate = formatDate(lastDate, DATE_FORMATS.SUNWISE);

    const { fields, keys } = getConsumptionHistoryFields({
        rate,
        rateConfiguration,
        values: {
            ...getValues(),
            initial_date: initialDate,
            final_date: finalDate,
        },
    });

    const populatedFields = handlePopulateFields({
        fieldsToPopulate,
        formFields: { ...fields, fields_keys: keys },
        groupedDataByTier,
        maximums,
        hasDemandValues: columnsMatch[DEMAND_CONCEPTS.MEASURED],
    });

    return {
        fields: populatedFields,
        finalDate,
        initialDate,
        keys,
    };
};

export const getHasTimestampGaps = ({ finalDate, initialDate, timestamp }) => {
    if (!finalDate || !initialDate) return false;
    const stepsInHour = 12; // 5 minutes intervals

    const firstDate = parseDate(initialDate, DATE_FORMATS.SUNWISE);
    const lastDate = parseDate(finalDate, DATE_FORMATS.SUNWISE);
    const diffHours = differenceInHoursDate(lastDate, firstDate);

    const expectedLength = diffHours * stepsInHour;

    return timestamp?.length !== expectedLength;
};
