import React from 'react';

import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { Box, Divider, Typography } from 'sunwise-ui';

import { LEGACY_MODULES } from 'common/constants';
import { getModulesOfActiveSubscriptions } from 'common/utils/helpers';

import { PROPOSER_CATEGORY_UUID } from '../constants';

import GroupItem from './GroupItem';
import GroupItemSwitch from './GroupItemSwitch';

const ReduxFieldArray = ({
    addons,
    category,
    control,
    disabled,
    isOwner,
    proposerIndex,
    setValue,
    subscriptions,
    subscriptionVersion,
}) => {
    const { fields } = useFieldArray({
        control,
        name: 'permissions',
    });

    let modules = getModulesOfActiveSubscriptions(
        subscriptions.filter(({ version }) => version === 'v5'),
    );
    if (subscriptionVersion === 'v4') modules = [...addons, ...LEGACY_MODULES];

    return fields.map((item, catIndex) => {
        if (item.category !== category) return null;
        if (item.subcategories)
            return item.subcategories.map((subcategory, subIndex) => (
                <Box key={subcategory.id} py={1}>
                    <Typography fontWeight="bold" variant="body2">
                        {subcategory.name}
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    {subcategory?.groups.map((group, index) => {
                        if (category === PROPOSER_CATEGORY_UUID)
                            return (
                                <GroupItemSwitch
                                    addons={modules}
                                    control={control}
                                    disabled={disabled}
                                    index={index}
                                    item={group}
                                    key={`group-${group.id}`}
                                    name={`permissions.${catIndex}.subcategories.${subIndex}.groups.${index}.permission_checked`}
                                />
                            );
                        else
                            return (
                                <GroupItem
                                    addons={modules}
                                    categories={fields}
                                    control={control}
                                    disabled={disabled}
                                    index={index}
                                    isOwner={isOwner}
                                    item={group}
                                    key={`group-${group.id}`}
                                    name={`permissions.${catIndex}.subcategories.${subIndex}.groups.${index}.permission_level`}
                                    proposerIndex={proposerIndex}
                                    setValue={setValue}
                                />
                            );
                    })}
                </Box>
            ));
        if (item.groups)
            return item.groups.map((group, index) => (
                <GroupItem
                    addons={modules}
                    categories={fields}
                    control={control}
                    disabled={disabled}
                    index={index}
                    isOwner={isOwner}
                    item={group}
                    key={`group-${group.id}`}
                    name={`permissions.${catIndex}.groups.${index}.permission_level`}
                    proposerIndex={proposerIndex}
                    setValue={setValue}
                />
            ));
    });
};

ReduxFieldArray.propTypes = {
    addons: PropTypes.array,
    category: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    isOwner: PropTypes.bool,
    proposerIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    setValue: PropTypes.func,
    subscriptions: PropTypes.array,
    subscriptionVersion: PropTypes.string,
};

export default ReduxFieldArray;
