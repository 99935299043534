import React, { useContext, useEffect, useRef, useState } from 'react';

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { styled } from '@mui/material/styles';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { withTemplateCore, editionLevels } from 'sunwise-template-core';
import { Box, Grid, IconButton, Tooltip } from 'sunwise-ui';

import * as lisaFileActions from 'common/modules/lisaFiles/actions';
import { LoadingContext } from 'common/utils/contexts';
import { getCurrentLanguage } from 'common/utils/helpers/multiregion';
import { getToken } from 'common/utils/helpers/session';
import { scroll } from 'common/utils/mixins';

import * as actions from '../../multiTemplateReview/actions';
import {
    FinancingBanner,
    NotFound,
    PreviewContent,
} from '../../multiTemplateReview/components';
import { PDF_MEDIUM_QUALITY } from '../../multiTemplateReview/constants';
import {
    getIsDownloadingFileContextMessage,
    getIsLoadingContextMessage,
    getIsUpdatingContextMessage,
    handleClickDocumentItemBuild,
    handleClickDownloadBuild,
    handleClickElectricBillBuild,
    handleCloseDrawer,
} from '../../multiTemplateReview/helpers';
import * as selectors from '../../multiTemplateReview/selectors';

import SupportMenuContent from './SupportMenuContent';

const PageWrapper = styled(Box)(
    ({ theme }) => `
    background:  ${
        theme.palette.mode === 'dark' ? '#000' : 'rgb(248, 252, 255)'
    };
    height: calc(100vh - 195px);
    overflow-y: scroll;
    padding-top: 24px;
    position: relative;
    width: 100%;
    
    ${scroll.custom()}

    .apexcharts-theme-light {
        color: black;
    }
`,
);

const MenuWrapper = styled(Box)`
    height: calc(100vh - 195px);
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 10px;
    ${scroll.custom()}
`;

const Container = ({
    canModify,
    customTemplateErrors,
    downloadDatasheet,
    downloadElectricBill,
    downloadFile,
    downloadOfferCsv,
    downloadOfferHourlyCsv,
    downloadOfferPdf,
    downloadOfferSmartDocumentCsv,
    downloadOfferXLS,
    editionDisabled,
    fetchCustomTemplate,
    fetchElectricBill,
    fetchFinancingTemplate,
    initialize,
    initTemplate,
    isFetching,
    isFetchingCustomTemplate,
    isFetchingDocumentTemplates,
    isFetchingFinancingTemplate,
    isFetchingOfferCsv,
    isFetchingOfferDatasheets,
    isFetchingOfferHourlyCsv,
    isFetchingOfferSmartDocumentCsv,
    isFetchingOfferSmartDocuments,
    isFetchingOfferXLS,
    isFetchingPdfFile,
    isInitializing,
    isUpdatingCustomTemplate,
    offerDatasheets,
    offerInfoPatern,
    offerSmartDocuments,
    proposalId,
    reset,
    resetTemplate,
    selectedDocument,
    setEditionDisabled,
    setSelectedDocument,
    templateType,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const [downloadFormat, setDownloadFormat] = useState(
        parseInt(localStorage.getItem('downloadFormat')) || PDF_MEDIUM_QUALITY,
    );
    const [isCollapsed, setIsCollapsed] = useState(false);
    const sideBarRef = useRef(null);
    const swipeableDrawerRef = useRef(null);
    const proposalName = get(offerInfoPatern, 'proposalName', '');
    const isApproved = get(offerInfoPatern, 'isApproved', false);

    useEffect(() => () => reset(), []);

    useEffect(() => {
        if (!proposalId) return;
        resetTemplate();
        initialize(proposalId, initTemplate, templateType);
        setSelectedDocument({
            id: proposalId,
            name: proposalName,
            type: templateType,
        });
    }, [proposalId]);

    useEffect(() => {
        getIsLoadingContextMessage({
            isFetching,
            isFetchingCustomTemplate,
            isFetchingDocumentTemplates,
            isFetchingFinancingTemplate,
            isFetchingOfferDatasheets,
            isFetchingOfferSmartDocuments,
            isInitializing,
            loadingContext,
        });
    }, [
        isFetching,
        isFetchingCustomTemplate,
        isFetchingDocumentTemplates,
        isFetchingFinancingTemplate,
        isFetchingOfferDatasheets,
        isFetchingOfferSmartDocuments,
        isInitializing,
        isInitializing,
    ]);

    useEffect(() => {
        getIsDownloadingFileContextMessage({
            isFetchingOfferCsv,
            isFetchingOfferHourlyCsv,
            isFetchingPdfFile,
            isFetchingOfferXLS,
            isFetchingOfferSmartDocumentCsv,
            loadingContext,
        });
    }, [
        isFetchingOfferCsv,
        isFetchingOfferHourlyCsv,
        isFetchingPdfFile,
        isFetchingOfferXLS,
        isFetchingOfferSmartDocumentCsv,
    ]);

    useEffect(() => {
        getIsUpdatingContextMessage(
            editionDisabled,
            isUpdatingCustomTemplate,
            loadingContext,
            setEditionDisabled,
        );
    }, [isUpdatingCustomTemplate]);

    const handleClickDatasheetItem = (datasheet) => {
        resetTemplate();
        setSelectedDocument(datasheet);
        setDownloadFormat(PDF_MEDIUM_QUALITY);
        handleCloseDrawer(swipeableDrawerRef);
    };

    const handleClickDownload = () =>
        handleClickDownloadBuild(
            downloadDatasheet,
            downloadElectricBill,
            downloadFile,
            downloadFormat,
            downloadOfferCsv,
            downloadOfferHourlyCsv,
            downloadOfferPdf,
            downloadOfferSmartDocumentCsv,
            downloadOfferXLS,
            loadingContext,
            selectedDocument,
        );

    const handleClickDocumentItem = (document) =>
        handleClickDocumentItemBuild(
            document,
            fetchCustomTemplate,
            fetchFinancingTemplate,
            initTemplate,
            resetTemplate,
            selectedDocument,
            setDownloadFormat,
            setSelectedDocument,
            swipeableDrawerRef,
        );

    const handleClickElectricBillItem = (document) =>
        handleClickElectricBillBuild(
            document,
            fetchElectricBill,
            loadingContext,
            resetTemplate,
            setDownloadFormat,
            setSelectedDocument,
            swipeableDrawerRef,
        );

    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const supportMenu = (
        <SupportMenuContent
            canModify={canModify}
            downloadFormat={downloadFormat}
            editionDisabled={editionDisabled}
            handleClickDatasheetItem={handleClickDatasheetItem}
            handleClickDocumentItem={handleClickDocumentItem}
            handleClickDownload={handleClickDownload}
            handleClickElectricBillItem={handleClickElectricBillItem}
            isApproved={isApproved}
            isDownloading={
                isFetchingOfferCsv ||
                isFetchingOfferHourlyCsv ||
                isFetchingPdfFile ||
                isFetchingOfferXLS ||
                isFetchingOfferSmartDocumentCsv
            }
            isFetching={
                isFetching ||
                isFetchingCustomTemplate ||
                isFetchingDocumentTemplates ||
                isFetchingFinancingTemplate ||
                isFetchingOfferDatasheets ||
                isFetchingOfferSmartDocuments ||
                isInitializing
            }
            isUpdatingCustomTemplate={isUpdatingCustomTemplate}
            offerDatasheets={offerDatasheets}
            offerInfoPatern={offerInfoPatern}
            offerSmartDocuments={offerSmartDocuments}
            proposalId={proposalId}
            proposalName={proposalName}
            selectedDocument={selectedDocument}
            setDownloadFormat={setDownloadFormat}
        />
    );

    if (!isEmpty(customTemplateErrors))
        return <NotFound errors={customTemplateErrors} />;

    return (
        <>
            <Grid container spacing={0}>
                <Grid
                    size={{
                        lg: isCollapsed ? 17 : 14,
                        md: isCollapsed ? 17 : 12,
                        xs: 18,
                    }}
                >
                    <FinancingBanner
                        financing={get(offerInfoPatern, 'financing', {})}
                        isCertifiedRate={get(
                            offerInfoPatern,
                            'isCertifiedRate',
                            false,
                        )}
                        proposalId={proposalId}
                    />
                    <PageWrapper
                        sx={{ pb: { lg: 0, xs: '50px' } }}
                        toolbar={editionDisabled ? 'false' : 'true'}
                    >
                        <PreviewContent
                            editionDisabled={editionDisabled}
                            isFetchingCustomTemplate={isFetchingCustomTemplate}
                            isFetchingDocumentTemplates={
                                isFetchingDocumentTemplates
                            }
                            isFetchingFinancingTemplate={
                                isFetchingFinancingTemplate
                            }
                            isFetchingOfferSmartDocuments={
                                isFetchingOfferSmartDocuments
                            }
                            selectedDocument={selectedDocument}
                        />
                    </PageWrapper>
                </Grid>
                <Grid
                    ref={sideBarRef}
                    size={{
                        lg: isCollapsed ? 1 : 4,
                        md: isCollapsed ? 1 : 6,
                    }}
                    sx={{
                        display: { md: 'block', xs: 'none' },
                        position: 'relative',
                    }}
                >
                    <Tooltip title={isCollapsed ? t('Collapse') : t('Expand')}>
                        <IconButton
                            onClick={handleToggleCollapse}
                            sx={{
                                left: -8,
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                zIndex: 10,
                            }}
                        >
                            {isCollapsed ? (
                                <ArrowLeftIcon />
                            ) : (
                                <ArrowRightIcon />
                            )}
                        </IconButton>
                    </Tooltip>
                    <MenuWrapper>{!isCollapsed && supportMenu}</MenuWrapper>
                </Grid>
            </Grid>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    customTemplateErrors: selectors.getFetchCustomTemplateErrors,
    isFetchingCustomTemplate: selectors.getIsFetchingCustomTemplate,
    isFetchingDocumentTemplates: selectors.getIsFetchingDocumentTemplates,
    isFetchingFinancingTemplate: selectors.getIsFetchingFinancingTemplate,
    isFetchingOfferCsv: selectors.getIsFetchingOfferCsv,
    isFetchingOfferDatasheets: selectors.getIsFetchingOfferDatasheets,
    isFetchingOfferHourlyCsv: selectors.getIsFetchingOfferHourlyCsv,
    isFetchingOfferSmartDocumentCsv:
        selectors.getIsFetchingOfferSmartDocumentCsv,
    isFetchingOfferSmartDocuments: selectors.getIsFetchingOfferSmartDocuments,
    isFetchingOfferXLS: selectors.getIsFetchingOfferXLS,
    isFetchingPdfFile: selectors.getIsFetchingPdfFile,
    isInitializing: selectors.getIsInitializing,
    isUpdatingCustomTemplate: selectors.getIsUpdatingCustomTemplate,
    offerDatasheets: selectors.getOfferDatasheetsData,
    offerSmartDocuments: selectors.getOfferSmartDocumentsData,
    selectedDocument: selectors.getSelectedDocument,
});

const mapDispatchToProps = (dispatch) => ({
    downloadDatasheet: (datasheetName, openLoading, closeLoading) =>
        dispatch(
            actions.downloadDatasheet(datasheetName, openLoading, closeLoading),
        ),
    downloadElectricBill: (archive, fileName, loadingContext, name, urlType) =>
        dispatch(
            actions.downloadElectricBill(
                archive,
                fileName,
                loadingContext,
                name,
                urlType,
            ),
        ),
    downloadFile: (name, url, type) =>
        dispatch(actions.downloadFile(name, url, type)),
    downloadOfferCsv: (id, name) =>
        dispatch(actions.downloadOfferCsv(id, name)),
    downloadOfferHourlyCsv: (id, name) =>
        dispatch(actions.downloadOfferHourlyCsv(id, name)),
    downloadOfferPdf: (downloadFormat, id, name, type) =>
        dispatch(actions.downloadOfferPdf(downloadFormat, id, name, type)),
    downloadOfferSmartDocumentCsv: (id, name) =>
        dispatch(actions.downloadOfferSmartDocumentCsv(id, name)),
    downloadOfferXLS: (id, name, formatted) =>
        dispatch(actions.downloadOfferXLS(id, name, formatted)),
    fetchCustomTemplate: (id, initTemplate, templateType) =>
        dispatch(actions.fetchCustomTemplate(id, initTemplate, templateType)),
    fetchElectricBill: (file_name, onSuccess) =>
        dispatch(lisaFileActions.getFile(file_name, onSuccess)),
    fetchFinancingTemplate: (id, initTemplate) =>
        dispatch(actions.fetchFinancingTemplate(id, initTemplate)),
    initialize: (id, initTemplate, templateType) =>
        dispatch(actions.initialize(id, initTemplate, templateType)),
    reset: () => dispatch(actions.reset()),
    setSelectedDocument: (values) =>
        dispatch(actions.setSelectedDocument(values)),
});

Container.propTypes = {
    canModify: PropTypes.bool,
    customTemplateErrors: PropTypes.array,
    downloadDatasheet: PropTypes.func,
    downloadElectricBill: PropTypes.func,
    downloadFile: PropTypes.func,
    downloadOfferCsv: PropTypes.func,
    downloadOfferHourlyCsv: PropTypes.func,
    downloadOfferPdf: PropTypes.func,
    downloadOfferSmartDocumentCsv: PropTypes.func,
    downloadOfferXLS: PropTypes.func,
    editionDisabled: PropTypes.bool,
    fetchCustomTemplate: PropTypes.func,
    fetchElectricBill: PropTypes.func,
    fetchFinancingTemplate: PropTypes.func,
    initialize: PropTypes.func,
    initTemplate: PropTypes.func,
    isFetching: PropTypes.bool,
    isFetchingCustomTemplate: PropTypes.bool,
    isFetchingDocumentTemplates: PropTypes.bool,
    isFetchingFinancingTemplate: PropTypes.bool,
    isFetchingOfferCsv: PropTypes.bool,
    isFetchingOfferDatasheets: PropTypes.bool,
    isFetchingOfferHourlyCsv: PropTypes.bool,
    isFetchingOfferSmartDocumentCsv: PropTypes.bool,
    isFetchingOfferSmartDocuments: PropTypes.bool,
    isFetchingOfferXLS: PropTypes.bool,
    isFetchingPdfFile: PropTypes.bool,
    isInitializing: PropTypes.bool,
    isUpdatingCustomTemplate: PropTypes.bool,
    offerDatasheets: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    offerInfoPatern: PropTypes.object,
    offerSmartDocuments: PropTypes.array,
    proposalId: PropTypes.string,
    reset: PropTypes.func,
    resetTemplate: PropTypes.func,
    selectedDocument: PropTypes.object,
    setEditionDisabled: PropTypes.func,
    setSelectedDocument: PropTypes.func,
    templateType: PropTypes.number,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTemplateCore(() => ({
        application: 'sunwise',
        baseUrl: import.meta.env.VITE_API_URL,
        editionMode: editionLevels.PARTIAL_EDITION_MODE,
        froalaLicenseKey: import.meta.env.VITE_FROALA_LICENSE,
        googleApiKey: import.meta.env.VITE_GOOGLE_MAPS_KEY,
        language: getCurrentLanguage(),
        token: getToken(),
    })),
)(Container);
