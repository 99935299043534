import { saveAlerts } from "common/api/v2/rateAlerts";
import { showReponseErrorsAsAlert } from "common/utils/helpers";

import {
  SAVE_ALERTS,
  SAVE_ALERTS_FAILURE,
  SAVE_ALERTS_SUCCESS,
} from "../actionTypes";
import { rateAlertsActions } from "../reducer";

import setAlertsModalsOpen from "./setAlertsModalsOpen";

export default (data, rate) => (dispatch) => {
  if (!rate?.id || !data?.alerts?.length) return;

  dispatch(rateAlertsActions[SAVE_ALERTS]());

  let newValues = {};

  if (rate.isCertified) newValues.rate = rate.id;
  else newValues.custom_rate = rate.id;

  newValues.alerts = data.alerts.map((alert) => ({
    ...alert,
    message: alert.message || "",
    status: alert.status || false,
    value: alert.value || 0,
  }));

  saveAlerts(newValues)
    .then((response) => {
      dispatch(rateAlertsActions[SAVE_ALERTS_SUCCESS](response?.data?.data));
      dispatch(setAlertsModalsOpen(false));
    })
    .catch((error) => {
      dispatch(
        rateAlertsActions[SAVE_ALERTS_FAILURE](error?.response?.data?.errors),
      );

      showReponseErrorsAsAlert(dispatch, error?.response);
    });
};
