import { fetchSummaries } from 'common/api/v1/searchi';

import {
    FETCH_SUMMARIES,
    FETCH_SUMMARIES_FAILURE,
    FETCH_SUMMARIES_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (q, page = 1) =>
    (dispatch) => {
        if (!q) return;

        dispatch(actions[FETCH_SUMMARIES]());

        fetchSummaries({ q, page }).then(
            (response) => {
                dispatch(actions[FETCH_SUMMARIES_SUCCESS](response.data));
            },
            (error) => {
                dispatch(
                    actions[FETCH_SUMMARIES_FAILURE](error?.response?.data),
                );
            },
        );
    };
