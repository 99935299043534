import i18next from "i18next";

import { HIGH_TENSION_RATES } from "common/constants/rates";

import { INITIALIZE_ALERTS_MODAL_FORM } from "../actionTypes";
import { ALERT_TYPE_ITEMS, RATE_ALERT_TYPES } from "../constants";
import { rateAlertsActions } from "../reducer";

export default (data, rate) => (dispatch) => {
  const defaultAlerts = ALERT_TYPE_ITEMS.map((item) => ({
    alert_type: item.alertType,
    message: i18next.t(item.defaultMessage),
    status:
      (rate?.isCertified && item.alertType !== RATE_ALERT_TYPES.KW_LIMIT_AC) ||
      false,
    value: item.defaultValue,
  }));

  const filteredAlerts = defaultAlerts?.filter(
    (alert) =>
      (rate?.isCertified &&
        HIGH_TENSION_RATES.includes(rate?.name) &&
        alert.alert_type === RATE_ALERT_TYPES.CONTRACTED_DEMAND) ||
      alert.alert_type !== RATE_ALERT_TYPES.CONTRACTED_DEMAND,
  );

  const alerts = filteredAlerts?.map((alert) => {
    const dataAlert = data?.find(
      (dataAlert) => dataAlert?.alert_type === alert?.alert_type,
    );
    return dataAlert ? dataAlert : alert;
  });

  dispatch(rateAlertsActions[INITIALIZE_ALERTS_MODAL_FORM]({ alerts }));
};
