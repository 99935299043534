import React from 'react';

import CreditCardIcon from '@mui/icons-material/CreditCard';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Typography } from 'sunwise-ui';

import { SUBSCRIPTION_STATUS } from 'common/constants';
import { parseDate, formatDate } from 'common/utils/dates';

import * as profileSelectors from '../../../modules/profile/selectors';

import StyledMenuItem from './StyledMenuItem';

const SubscriptionMenuItem = ({
    handleCloseProfileMenu,
    match,
    redirect,
    subscriptions,
}) => {
    const { t } = useTranslation();

    const renderDetails = (subscription, index) => {
        if (isEmpty(subscription)) return null;
        const {
            billing_period_unit,
            credits,
            item_price_id,
            next_billing_at,
            product_type,
            version,
        } = subscription || {};
        const availableProjects = get(credits, 'available_projects', 0);
        const availableCreditsPeriod = get(
            credits,
            'available_credits_period',
            0,
        );
        const availablePrepaidCredits = get(
            credits,
            'available_prepaid_credits',
            0,
        );
        const billingPeriod = get(billing_period_unit, 'key', null);

        return (
            <Box
                display="flex"
                gap={1}
                key={`${item_price_id}-${index}`}
                sx={{ width: '100%' }}
            >
                <CreditCardIcon />
                <Box display="flex" flexDirection="column">
                    <Typography variant="body2">
                        {subscription?.plan_name}{' '}
                        {billingPeriod === 'year' ? t('Annual') : t('Monthly')}
                    </Typography>
                    {next_billing_at && (
                        <Typography variant="body2">
                            {version === 'v4'
                                ? t('Next billing')
                                : t('Renewal date')}
                            :{' '}
                            {formatDate(
                                parseDate(
                                    next_billing_at,
                                    'dd/MM/yyyy HH:mm:ss',
                                ),
                                'dd/MM/yyyy',
                            )}
                        </Typography>
                    )}
                    {version === 'v4' && (
                        <Typography variant="body2">
                            {t('Total projects available')}: {availableProjects}
                        </Typography>
                    )}
                    {version === 'v5' && product_type === 'sales' && (
                        <Typography variant="body2">
                            {t('Proposals available')}:{' '}
                            {availableCreditsPeriod + availablePrepaidCredits}
                        </Typography>
                    )}
                    {version === 'v5' && product_type === 'aftersales' && (
                        <Typography variant="body2">
                            {t('Available reports')}:{' '}
                            {availableCreditsPeriod + availablePrepaidCredits}
                        </Typography>
                    )}
                </Box>
            </Box>
        );
    };

    return (
        <StyledMenuItem
            onClick={() => {
                handleCloseProfileMenu();
                redirect('/profile/subscription');
            }}
            selected={match.path === '/profile'}
        >
            <Box display="flex" flexDirection="column" gap={1}>
                {subscriptions
                    .filter(
                        ({ status }) =>
                            get(status, 'key', null) !==
                            SUBSCRIPTION_STATUS.CANCELLED,
                    )
                    .map((sub, index) => renderDetails(sub, index))}
            </Box>
        </StyledMenuItem>
    );
};

SubscriptionMenuItem.propTypes = {
    handleCloseProfileMenu: PropTypes.func,
    match: PropTypes.object,
    redirect: PropTypes.func,
    subscriptions: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    subscriptions: profileSelectors.getSubscriptions,
});

export default connect(mapStateToProps, null)(SubscriptionMenuItem);
