import React, { useEffect } from 'react';

import ImageIcon from '@mui/icons-material/Image';
import { useTheme } from '@mui/material/styles';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
} from 'sunwise-ui';

import IconFinancial from 'common/components/icons/IconFinancial';
import { handleFileURL } from 'common/utils/helpers';

import FinancialProductImage from 'resources/financialProduct.png';

import SideMenuCard from '../../multiTemplateReview/components/SideMenuCard';
import { FINANCING_TYPE } from '../../multiTemplateReview/constants';

const FinancialCard = ({
    financing,
    handleClickDatasheetItem,
    handleClickDocumentItem,
    isDisabled,
    selectedDocumentId,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    const content = get(financing, 'content', {});
    const currentId = isEmpty(content)
        ? get(financing, 'name', null)
        : content?.id;
    const extFinancingFile = get(financing, 'proposal_financing_file', null);

    const handleClick = () => {
        if (extFinancingFile) {
            handleClickDatasheetItem({
                id: financing?.name,
                name: financing?.name,
                archive: extFinancingFile,
                type: FINANCING_TYPE,
            });
        } else if (!isEmpty(content)) {
            handleClickDocumentItem({
                id: content?.id,
                name: content?.name,
                type: FINANCING_TYPE,
            });
        }
        return false;
    };

    useEffect(() => {
        if (!isEmpty(financing)) handleClick();
    }, [financing]);

    if (isEmpty(financing)) return null;

    return (
        <SideMenuCard
            icon={<IconFinancial />}
            title={`${t('Proposal')} - ${financing.name}`}
        >
            <List disablePadding>
                <ListItem
                    disabled={isDisabled || currentId === selectedDocumentId}
                    disablePadding
                >
                    <ListItemButton
                        onClick={handleClick}
                        selected={currentId === selectedDocumentId}
                        sx={{
                            borderRadius: '8px',
                            backgroundColor:
                                currentId === selectedDocumentId
                                    ? isDarkMode
                                        ? '#000'
                                        : 'rgba(31, 60, 83, 0.13)'
                                    : 'transparent',
                            '&.Mui-selected': {
                                backgroundColor: isDarkMode
                                    ? '#000'
                                    : 'rgba(31, 60, 83, 0.13) !important',
                            },
                        }}
                    >
                        <ListItemAvatar>
                            {financing?.is_own_financing ? (
                                <Avatar
                                    src={FinancialProductImage}
                                    sx={{ height: 32, width: 32 }}
                                >
                                    <ImageIcon />
                                </Avatar>
                            ) : (
                                <Avatar
                                    src={handleFileURL(
                                        get(financing, 'image', ''),
                                        import.meta.env.VITE_S3_MEDIA_PATH,
                                    )}
                                    sx={{ height: 32, width: 32 }}
                                >
                                    <ImageIcon />
                                </Avatar>
                            )}
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${financing?.name} (${t('Proposal')})`}
                            sx={{
                                '.MuiListItemText-primary': {
                                    fontSize: '14px',
                                },
                            }}
                        />
                    </ListItemButton>
                </ListItem>
            </List>
        </SideMenuCard>
    );
};

FinancialCard.propTypes = {
    financing: PropTypes.object,
    handleClickDatasheetItem: PropTypes.func,
    handleClickDocumentItem: PropTypes.func,
    isDisabled: PropTypes.bool,
    selectedDocumentId: PropTypes.string,
};

export default FinancialCard;
