import fetchContacts from './fetchContacts';
import fetchProjects from './fetchProjects';
import fetchProposals from './fetchProposals';
import fetchReports from './fetchReports';
import fetchSummaries from './fetchSummaries';
import setRecentSearches from './setRecentSearches';

export default (q, permissions) => (dispatch) => {
    if (!q) return;

    let promises = [];

    if (permissions.canViewContacts) promises.push(dispatch(fetchContacts(q)));

    if (permissions.canViewProjects) promises.push(dispatch(fetchProjects(q)));

    if (permissions.canViewProposals)
        promises.push(dispatch(fetchProposals(q)));

    if (permissions.canViewReports) promises.push(dispatch(fetchReports(q)));

    if (permissions.canViewSummaries)
        promises.push(dispatch(fetchSummaries(q)));

    Promise.all(promises)
        .then(() => dispatch(setRecentSearches([q])))
        .catch();
};
