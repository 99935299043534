import React from 'react';

import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import PersonIcon from '@mui/icons-material/Person';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'sunwise-ui';

const OriginFormatter = ({ row }) => {
    const { t } = useTranslation();
    const { read_only } = row;
    let Icon = PersonIcon;
    let title = 'This proposal was generated by an agent and is editable';
    if (read_only) {
        Icon = LaptopMacIcon;
        title =
            'This proposal was generated via the web quote and is read only';
    }
    return (
        <Tooltip placement="top" title={t(title)}>
            <Icon color="info" fontSize="small" />
        </Tooltip>
    );
};

OriginFormatter.propTypes = {
    row: PropTypes.object,
};

export default OriginFormatter;
