import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Divider, Grid } from 'sunwise-ui';

import CollapsedBreadcrumbs from 'common/components/CollapsedBreadcrumbs';

import TitlePreview from './TitlePreview';
const HeaderPreview = ({
    breadcrumbLocations,
    currentBreadcrumb,
    editionDisabled,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Grid
                container
                spacing={0}
                sx={{ alignItems: 'center', p: '16px 16px 8px 16px' }}
            >
                <Grid size={18}>
                    <TitlePreview name={t('Preview')} />
                </Grid>

                {editionDisabled && (
                    <Grid size={{ xs: 18 }} sx={{ pl: { md: '40px' } }}>
                        <CollapsedBreadcrumbs
                            current={currentBreadcrumb}
                            locations={breadcrumbLocations}
                        />
                    </Grid>
                )}
            </Grid>

            <Divider sx={{ mb: 0 }} />
        </>
    );
};

HeaderPreview.propTypes = {
    breadcrumbLocations: PropTypes.array,
    currentBreadcrumb: PropTypes.string,
    editionDisabled: PropTypes.bool,
};

export default HeaderPreview;
