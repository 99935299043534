import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Typography } from 'sunwise-ui';

import SubscriptionDetailsV4 from './SubscriptionDetailsV4';

const UsageCardV4 = ({ activeUsers, addons, subscriptions }) => {
    const { t } = useTranslation();

    return (
        <Card>
            <Card.Header>
                <Typography variant="body1" fontWeight="bold">
                    {t('Usage')}
                </Typography>
            </Card.Header>
            <Card.Body sx={{ minHeight: '650px' }}>
                {subscriptions.map((sub, index) => (
                    <SubscriptionDetailsV4
                        key={`${sub?.item_price_id}-${index}`}
                        subscription={sub}
                        index={index}
                        activeUsers={activeUsers}
                        addons={addons}
                    />
                ))}
            </Card.Body>
        </Card>
    );
};

UsageCardV4.propTypes = {
    activeUsers: PropTypes.number,
    addons: PropTypes.array,
    subscriptions: PropTypes.array,
};

export default UsageCardV4;
