import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Grid, Paper } from 'sunwise-ui';

const StyledPaper = styled(Paper)`
    background-color: ${({ theme }) =>
        theme.palette.mode === 'dark' ? '#757575' : '#E0E0E0'};
`;

const GridLayout = ({ cols, gridRef, height, rows, spacing, sxPaper }) => {
    const gridItems = [];

    for (let row = 0; row < rows; row++) {
        for (let col = 0; col < cols; col++) {
            gridItems.push(
                <Grid key={`row-${row}-col-${col}`} size={{ lg: 3, xs: 18 }}>
                    <StyledPaper sx={{ height: height, ...sxPaper }} />
                </Grid>,
            );
        }
    }

    return (
        <Grid container mt={0} ref={gridRef} spacing={spacing}>
            {gridItems}
        </Grid>
    );
};

GridLayout.defaultProps = {
    cols: 6,
    height: 76,
    rows: 4,
    spacing: 2,
};

GridLayout.propTypes = {
    cols: PropTypes.number,
    gridRef: PropTypes.object,
    height: PropTypes.number,
    rows: PropTypes.number,
    spacing: PropTypes.number,
    sxPaper: PropTypes.object,
};

export default GridLayout;
