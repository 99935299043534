import React, { useEffect, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LinearProgress from '@mui/material/LinearProgress';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid, Typography } from 'sunwise-ui';

import { MONTHLY_FREQUENCY, YEARLY_FREQUENCY } from 'common/constants';

import * as profileSelectors from '../../../../modules/profile/selectors';
import * as wizardActions from '../../../../modules/selectBillingPlan/actions';
import {
    BenefitsCards,
    BenefitsCardsAfterSales,
    BenefitsCardsBundle,
    FrequencyOptions,
    PricingList,
} from '../../../../modules/selectBillingPlan/components';
import * as wizardSelectors from '../../../../modules/selectBillingPlan/selectors';
import * as actions from '../actions';
import { getFilteredPricing, getProductType } from '../helpers';
import * as selectors from '../selectors';

const Plans = ({
    addonName,
    fetchPricing,
    flluidView,
    handleChargebeeCheckout,
    handleClickBack,
    isFetchingPricing,
    isSavingSubscription,
    pricingData,
    selectedTheme,
    subscription,
    subscriptions,
}) => {
    const { t } = useTranslation();
    const [cbInstance, setCbInstance] = useState(null);
    const [pricings, setPricings] = useState([]);
    const [billingFrequency, setBillingFrequency] = useState(YEARLY_FREQUENCY);
    const [selectSubscription, setSelectSubscription] = useState(subscription);
    const selectedProductId = getProductType(addonName, subscription);
    const isBundle = selectedProductId === 'bundle';

    const billingPeriod = get(
        selectSubscription,
        'billing_period_unit.key',
        null,
    );

    useEffect(() => {
        if (import.meta.env.VITE_CHARGEBEE_KEY) {
            setCbInstance(
                window.Chargebee.init({
                    site: import.meta.env.VITE_CHARGEBEE_KEY,
                }),
            );
        }
        fetchPricing();
    }, []);

    useEffect(() => {
        if (selectedProductId && !isEmpty(subscriptions)) {
            const selectedSubscription = subscriptions.find(
                (sub) => sub.product_type === selectedProductId,
            );
            setSelectSubscription(selectedSubscription);
        }
    }, [selectedProductId, subscriptions]);

    useEffect(() => {
        if (billingPeriod === 'year') setBillingFrequency(YEARLY_FREQUENCY);
        else setBillingFrequency(MONTHLY_FREQUENCY);
    }, [billingPeriod]);

    useEffect(() => {
        const filteredPricing = getFilteredPricing({
            billingFrequency,
            pricings: pricingData[selectedProductId],
        });
        setPricings(filteredPricing);
    }, [billingFrequency, selectedProductId, pricingData]);

    const handleCheckout = (item_price_id) =>
        handleChargebeeCheckout({
            cbInstance,
            itemPriceId: item_price_id,
        });

    const renderText = () => {
        switch (selectedProductId) {
            case 'aftersales':
                return 'AfterSales';
            case 'bundle':
                return 'Bundle';
            default:
                return 'Sales';
        }
    };

    const renderBenefits = () => {
        switch (selectedProductId) {
            case 'aftersales':
                return (
                    <BenefitsCardsAfterSales selectedTheme={selectedTheme} />
                );
            case 'bundle':
                return <BenefitsCardsBundle selectedTheme={selectedTheme} />;
            default:
                return <BenefitsCards selectedTheme={selectedTheme} />;
        }
    };

    return (
        <>
            {isSavingSubscription && (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )}
            <Grid container justifyContent="center" my={2}>
                <Grid size={{ lg: flluidView ? 16 : 12, xs: 18 }}>
                    <Button
                        onClick={() => handleClickBack()}
                        startIcon={<ArrowBackIcon fontSize="small" />}
                        variant="text"
                    >
                        {t('Back')}
                    </Button>
                    <Box
                        alignItems="center"
                        display="flex"
                        gap={1}
                        justifyContent="center"
                        sx={{ svg: { height: 30, width: 30 } }}
                    >
                        <Typography variant="h1">Sunwise</Typography>
                        <Typography
                            component="span"
                            sx={{ color: 'primary.main' }}
                            variant="h1"
                        >
                            {renderText()}
                        </Typography>
                    </Box>
                    <Typography mt={1} textAlign="center" variant="h3">
                        {t('Plans and pricing')}
                    </Typography>
                    <Typography mt={1} textAlign="center" variant="body1">
                        {t(
                            'At Sunwise we offer you different plan options to grow your business',
                        )}
                        :
                    </Typography>
                    <FrequencyOptions
                        billingFrequency={billingFrequency}
                        isHidden={isBundle}
                        setBillingFrequency={setBillingFrequency}
                    />
                </Grid>
                <Grid size={{ lg: flluidView ? 18 : 15, xs: 18 }}>
                    <PricingList
                        flluidView={flluidView}
                        fromLanding
                        handleCheckout={handleCheckout}
                        isLoading={isFetchingPricing}
                        noSubscriptions={false}
                        pricings={pricings}
                        selectedProductId={selectedProductId}
                        subscription={selectSubscription}
                    />
                </Grid>
                <Grid size={{ lg: flluidView ? 16 : 12, xs: 18 }}>
                    {renderBenefits()}
                </Grid>
            </Grid>
        </>
    );
};

Plans.propTypes = {
    addonName: PropTypes.string,
    fetchPricing: PropTypes.func,
    flluidView: PropTypes.bool,
    handleChargebeeCheckout: PropTypes.func,
    handleClickBack: PropTypes.func,
    isFetchingPricing: PropTypes.bool,
    isSavingSubscription: PropTypes.bool,
    pricingData: PropTypes.object,
    selectedTheme: PropTypes.string,
    subscription: PropTypes.object,
    subscriptions: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    isFetchingPricing: wizardSelectors.getIsFetchingPricing,
    isSavingSubscription: selectors.getIsSavingSubscription,
    pricingData: wizardSelectors.getPricingData,
    selectedTheme: profileSelectors.getTheme,
    subscription: profileSelectors.getActiveSubscription,
    subscriptions: profileSelectors.getSubscriptions,
});

const mapDispatchToProps = (dispatch) => ({
    fetchPricing: () => dispatch(wizardActions.fetchPricing()),
    handleChargebeeCheckout: (data) =>
        dispatch(actions.handleChargebeeCheckout(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
