import React from 'react';

import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import { CB_PORTAL_SECTIONS, SUBSCRIPTION_STATUS } from 'common/constants';

import AfterSalesIcon from 'resources/aftersales.svg?react';
import BundleIcon from 'resources/IsoBundle.svg?react';
import SalesIcon from 'resources/sales.svg?react';

import {
    formatPeriodDate,
    getBuyButtonText,
    getProductNameText,
    getStatusName,
} from '../helpers';

import AddonsSubscriptionTable from './AddonsSubscriptionTable';
import NextSubsTable from './NextSubsTable';

const NextSubscripionDetails = ({
    addonsSubscriptions,
    canModify,
    handlePortal,
    payInvoices,
    paymentSources,
    subscription,
}) => {
    const { t } = useTranslation();

    if (isEmpty(subscription)) return null;

    const {
        due_invoices_count,
        next_billing_at,
        product_type,
        status,
        subscription_id,
        version,
    } = subscription || {};

    const hasPaymentDue = due_invoices_count > 0;
    const isSales = product_type === 'sales';
    const primaryPaymentSource = paymentSources.find((item) => item.is_primary);
    const last4 = primaryPaymentSource ? primaryPaymentSource.last4 : 'XXXX';
    const subscriptionStatus = get(status, 'key', null);
    const showOptions = [
        SUBSCRIPTION_STATUS.ACTIVE,
        SUBSCRIPTION_STATUS.NON_RENEWING,
    ].includes(subscriptionStatus);
    const isCancelled = subscriptionStatus === SUBSCRIPTION_STATUS.CANCELLED;

    const renderIcon = () => {
        switch (product_type) {
            case 'aftersales':
                return <AfterSalesIcon />;
            case 'bundle':
                return <BundleIcon />;
            default:
                return <SalesIcon />;
        }
    };

    return (
        <Box display="flex" flexDirection="column" mb={2}>
            <Box display="flex">
                <Box
                    alignItems="center"
                    display="flex"
                    gap={1}
                    sx={{ svg: { height: 30, width: 30 } }}
                >
                    {renderIcon()}
                    <Typography variant="h5">Sunwise</Typography>
                    <Typography
                        component="span"
                        sx={{ color: 'primary.main' }}
                        variant="h5"
                    >
                        {getProductNameText(product_type)}
                    </Typography>
                </Box>
                <DropdownCardMenu
                    items={[
                        {
                            handleClick: () =>
                                handlePortal(
                                    CB_PORTAL_SECTIONS.SUBSCRIPTION_CANCELLATION,
                                    subscription_id,
                                ),
                            label: t('Cancel subscription'),
                            visible:
                                subscriptionStatus ===
                                SUBSCRIPTION_STATUS.ACTIVE,
                        },
                        {
                            handleClick: () =>
                                handlePortal(
                                    CB_PORTAL_SECTIONS.SUBSCRIPTION_DETAILS,
                                    subscription_id,
                                ),
                            label: t('Active subscription'),
                            visible:
                                subscriptionStatus ===
                                SUBSCRIPTION_STATUS.NON_RENEWING,
                        },
                        {
                            handleClick: () => payInvoices(last4),
                            label: t('Pay subscription'),
                            visible: hasPaymentDue,
                        },
                        {
                            handleClick: () =>
                                handlePortal(
                                    CB_PORTAL_SECTIONS.EDIT_SUBSCRIPTION,
                                    subscription_id,
                                ),
                            label: getBuyButtonText(product_type, version),
                        },
                    ]}
                    sxIconButton={{ ml: 'auto' }}
                    visible={showOptions && canModify}
                />
            </Box>
            <Typography ml={2} mt={2} variant="body2">
                {t('Status')}: {getStatusName(hasPaymentDue, subscription)}
            </Typography>
            {!hasPaymentDue && (
                <Typography variant="body2" ml={2} mt={2}>
                    {isCancelled
                        ? t('Cancellation date on')
                        : t('Renewal date')}
                    : {formatPeriodDate(next_billing_at)}
                </Typography>
            )}
            <NextSubsTable subscription={subscription} />
            {isSales && !isEmpty(addonsSubscriptions) && (
                <Box display="flex" flexDirection="column" my={3} pl={2}>
                    <Typography fontWeight="bold" variant="body2">
                        {t('Other subscriptions')}
                    </Typography>
                    <AddonsSubscriptionTable
                        canModify={canModify}
                        handlePortal={handlePortal}
                        items={addonsSubscriptions}
                        last4={last4}
                        payInvoices={payInvoices}
                    />
                </Box>
            )}
        </Box>
    );
};

NextSubscripionDetails.propTypes = {
    addonsSubscriptions: PropTypes.array,
    canModify: PropTypes.bool,
    handlePortal: PropTypes.func,
    payInvoices: PropTypes.func,
    paymentSources: PropTypes.array,
    subscription: PropTypes.object,
};

export default NextSubscripionDetails;
