import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from 'sunwise-ui';

import AccordionCard from 'common/components/accordions/AccordionCard';
import { SUPPORTED_LANGUAGES } from 'common/constants/multiregion';

import { getSxCard } from '../helpers';

const BenefitsCardsAfterSales = ({ selectedTheme }) => {
    const { i18n, t } = useTranslation();
    const isMx = i18n.language === SUPPORTED_LANGUAGES.es;
    const sxExpandMoreIcon = {
        color: selectedTheme === 'dark' ? '#000' : '',
    };
    const sxTypography = {
        color: selectedTheme === 'dark' ? '#000' : '#ffffff',
    };

    return (
        <Grid container mt={3}>
            <Grid size={18}>
                <AccordionCard
                    customTitle={
                        <Typography
                            variant="body1"
                            fontWeight={700}
                            sx={sxTypography}
                        >
                            {t('Reports by use')}
                        </Typography>
                    }
                    isHidden
                    sxAccordionSummary={getSxCard()}
                    sxExpandMoreIcon={sxExpandMoreIcon}
                >
                    <Box
                        textAlign="center"
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            mt: 1,
                        }}
                    >
                        <img
                            src={
                                isMx
                                    ? 'https://cdn.prod.website-files.com/5ee80e144bcecc441f0c29be/678e713ad0e5f0c9cf420259_Packages_Movil_Esp.svg'
                                    : 'https://cdn.prod.website-files.com/5ee80e144bcecc441f0c29be/678e713a31433f69be55fefd_Packages_Movil_Eng.svg'
                            }
                        />
                    </Box>
                    <Box
                        textAlign="center"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            mt: 1,
                        }}
                    >
                        <img
                            src={
                                isMx
                                    ? 'https://cdn.prod.website-files.com/5ee80e144bcecc441f0c29be/678e713a7561e47e60941f40_Packages_Esp.svg'
                                    : 'https://cdn.prod.website-files.com/5ee80e144bcecc441f0c29be/678e713a68ec036acd7d5f03_Packages_Eng.svg'
                            }
                        />
                    </Box>
                </AccordionCard>
                <AccordionCard
                    customTitle={
                        <Typography
                            variant="body1"
                            fontWeight={700}
                            sx={{ color: '#ffffff' }}
                        >
                            {t('Included features')}
                        </Typography>
                    }
                    sxAccordionSummary={getSxCard()}
                    sxCard={{ mt: 2 }}
                >
                    <Box
                        textAlign="center"
                        sx={{ display: { xs: 'block', sm: 'none' } }}
                    >
                        <img
                            src={
                                isMx
                                    ? 'https://cdn.prod.website-files.com/5ee80e144bcecc441f0c29be/67c730934c669e7fc223f2a9_Features_Movil_Esp.svg'
                                    : 'https://cdn.prod.website-files.com/5ee80e144bcecc441f0c29be/67c730939c9d1774822c9fd4_Features_Movil_Eng.svg'
                            }
                        />
                    </Box>
                    <Box
                        textAlign="center"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                        <img
                            src={
                                isMx
                                    ? 'https://cdn.prod.website-files.com/5ee80e144bcecc441f0c29be/67c73093c35da43730d90f09_Features_AfterSales_Esp.svg'
                                    : 'https://cdn.prod.website-files.com/5ee80e144bcecc441f0c29be/67c73093bc84dd6d12d4edb1_Features_Aftrsales_Eng.svg'
                            }
                        />
                    </Box>
                </AccordionCard>
            </Grid>
        </Grid>
    );
};

BenefitsCardsAfterSales.propTypes = {
    selectedTheme: PropTypes.string,
};

export default BenefitsCardsAfterSales;
