import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Card, Grid, Skeleton } from 'sunwise-ui';

import * as companyGeneralSelectors from '../../companyGeneral/selectors';
import { VIEWS } from '../constants';
import * as selectors from '../selectors';

import DetailsDrawer from './DetailsDrawer';
import ItemsList from './ItemsList';
import MapContainer from './MapContainer';

const viewComponents = {
    [VIEWS.MAP]: MapContainer,
};

const Content = ({
    companyPositionLatLng,
    countryCurrencyLocale,
    filtersValues,
    monitoringProjects,
    sx,
    view,
}) => {
    const [isOpenDetailsDrawer, setIsOpenDetailsDrawer] = useState(false);
    const [timesLoaded, setTimesLoaded] = useState(0);
    const [selectedProject, setSelectedProject] = useState(null);

    useEffect(() => {
        if (!monitoringProjects || timesLoaded > 2) return;
        setTimesLoaded((prev) => prev + 1);
    }, [monitoringProjects]);

    const ViewComponent = viewComponents[view];

    if (!ViewComponent) return null;

    const handleSelectProject = (projectId) => {
        if (!projectId || !monitoringProjects?.length) {
            setSelectedProject(null);
            return;
        }
        const project = monitoringProjects.find((p) => p.id === projectId);
        if (!project) return;
        setSelectedProject(project);
        setIsOpenDetailsDrawer(true);
    };

    const handleCloseDetailsDrawer = () => {
        handleSelectProject(null);
        setIsOpenDetailsDrawer(false);
    };

    return (
        <Card sx={sx}>
            <Card.Body>
                <Grid container>
                    <Grid size={{ md: 13, xs: 18 }}>
                        {timesLoaded > 2 ? (
                            <ViewComponent
                                companyPositionLatLng={companyPositionLatLng}
                                countryCurrencyLocale={countryCurrencyLocale}
                                handleSelectProject={handleSelectProject}
                                monitoringProjects={monitoringProjects}
                            />
                        ) : (
                            <Skeleton
                                height="100%"
                                variant="rect"
                                width="100%"
                            />
                        )}
                    </Grid>

                    <Grid size={{ md: 5, xs: 18 }}>
                        <ItemsList
                            handleSelectProject={handleSelectProject}
                            items={monitoringProjects}
                            selectedProject={selectedProject}
                            showDot={view === VIEWS.CHART}
                        />
                    </Grid>
                </Grid>

                <DetailsDrawer
                    countryCurrencyLocale={countryCurrencyLocale}
                    filtersValues={filtersValues}
                    handleClose={handleCloseDetailsDrawer}
                    isOpen={isOpenDetailsDrawer}
                    selectedProject={selectedProject}
                />
            </Card.Body>
        </Card>
    );
};

const mapStateToProps = createStructuredSelector({
    companyPositionLatLng: companyGeneralSelectors.getCompanyPositionLatLng,
    monitoringProjects: selectors.getMonitoringProjects,
});

Content.propTypes = {
    companyPositionLatLng: PropTypes.object,
    countryCurrencyLocale: PropTypes.string,
    filtersValues: PropTypes.object,
    monitoringProjects: PropTypes.array,
    sx: PropTypes.object,
    view: PropTypes.string,
};

export default connect(mapStateToProps)(Content);
