import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid, Typography } from 'sunwise-ui';

import {
    ReactHookFormCheck,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';

const Alerts = ({ alerts, control, disabled }) => {
    const { t } = useTranslation();

    const typeOptions = [
        { disabled: true, label: t('Select'), value: '' },
        { label: t('Informative'), value: 'informative' },
        { label: t('Restrictive'), value: 'restrictive' },
    ];

    const getAlertTitle = (key) => {
        switch (key) {
            case 'supplier_VAT':
                return t('VAT supplier');
            case 'tax_incentive':
                return t('Tax incentive');
            case 'supplier_annual_increase':
                return t('Annual increase supplier');
            default:
                return 'OPEX';
        }
    };

    const convertAlertsToArray = (alerts) => {
        return Object.keys(alerts).map((key) => ({
            name: key,
            title: getAlertTitle(key),
        }));
    };

    const alertsArray = convertAlertsToArray(alerts);

    return (
        <Box>
            <Grid container mb={2}>
                <Grid size={18}>
                    <Typography fontWeight={600} variant="body2">
                        {t('Configure your alerts')}
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                </Grid>
            </Grid>
            {alertsArray.map(({ name, title }) => (
                <Grid alignItems="center" container key={name}>
                    <Grid size={{ lg: 1, md: 2, xs: 3 }} textAlign="center">
                        <ReactHookFormCheck
                            control={control}
                            disabled={disabled}
                            name={`alerts.${name}.is_active`}
                        />
                    </Grid>
                    <Grid size={{ md: 7, xs: 15 }}>
                        <Typography variant="body2" mb={2}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid size={{ lg: 4, md: 6, xs: 18 }}>
                        <ReactHookFormSelect
                            control={control}
                            disabled={disabled}
                            label={t('Type')}
                            name={`alerts.${name}.type`}
                            options={typeOptions}
                        />
                    </Grid>
                </Grid>
            ))}
        </Box>
    );
};

Alerts.propTypes = {
    alerts: PropTypes.object,
    control: PropTypes.object,
    disabled: PropTypes.bool,
};

export default Alerts;
