import React, { useEffect } from 'react';

import NearMeIcon from '@mui/icons-material/NearMe';
import { get, isNull, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { useGeolocated } from 'react-geolocated';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Grid, IconButton } from 'sunwise-ui';

import { ControlledMap } from 'common/components';
import { ReactHookFormInput } from 'common/components/form/bootstrap';
import {
    ItemGeocodeResult,
    ListGeocodeResult,
    WrapperGeocodeResult,
} from 'common/components/maps';
import { DEFAULT_MAP_PREVIEW_ZOOM } from 'common/constants';
import { handleOnChangeLatLng } from 'common/utils/helpers';

import * as companyGeneralActions from '../../companyGeneral/actions';
import MapWarning from '../../companyGeneral/components/MapWarning';
import * as companyGeneralselectors from '../../companyGeneral/selectors';
import * as selectors from '../selectors';

const Location = ({
    accountCountryPositionLatLng,
    control,
    fetchGeocodePoint,
    formValues,
    geocodePointData,
    geocodeResult,
    handleGeocode,
    initialValues,
    isFetchingGeocodePoint,
    setEmptyGeocodeResult,
    setShowGeocodeResults,
    setValue,
    showGeocodeResults,
}) => {
    const { t } = useTranslation();
    const { coords, getPosition, isGeolocationEnabled } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
        suppressLocationOnMount: true,
    });
    const position = get(formValues, 'position', {});
    const initLatitude = get(initialValues, 'latitude', null);
    const initLongitude = get(initialValues, 'longitude', null);
    const latitude = get(formValues, 'latitude', null);
    const longitude = get(formValues, 'longitude', null);

    useEffect(() => {
        if (coords) updateLocation(coords);
    }, [coords]);

    useEffect(() => {
        if (!isNull(geocodePointData))
            setValue('description', geocodePointData);
    }, [geocodePointData]);

    useEffect(() => {
        if (!position) return;
        const { latitude, longitude } = position;
        const latlng =
            latitude && longitude ? `${latitude}, ${longitude}` : null;
        setValue('latitude', latitude);
        setValue('latlng', latlng);
        setValue('longitude', longitude);
    }, [position]);

    useEffect(() => {
        if (latitude !== initLatitude || longitude !== initLongitude)
            fetchGeocodePoint(latitude, longitude);
    }, [latitude, longitude]);

    const isVisibleMapWarning = () => {
        if (isNull(latitude) || isNull(longitude)) return true;
        return false;
    };

    const updateLocation = (position) => {
        if (position) setValue('position', position);
    };

    return (
        <Box>
            <Grid container>
                <Grid size={{ xs: 'grow' }}>
                    <Box>
                        {geocodeResult &&
                            geocodeResult.length > 0 &&
                            showGeocodeResults && (
                                <WrapperGeocodeResult>
                                    <ListGeocodeResult
                                        style={{
                                            transform:
                                                'translate3d(0px, 38px, 0px)',
                                        }}
                                    >
                                        {geocodeResult.map((result, i) => (
                                            <ItemGeocodeResult
                                                key={i}
                                                onClick={() => {
                                                    setValue(
                                                        'description',
                                                        result.formatted_address,
                                                    );

                                                    const { lat, lng } =
                                                        result.geometry
                                                            .location;
                                                    updateLocation({
                                                        latitude: lat,
                                                        longitude: lng,
                                                    });
                                                }}
                                            >
                                                {result.formatted_address}
                                            </ItemGeocodeResult>
                                        ))}
                                    </ListGeocodeResult>
                                </WrapperGeocodeResult>
                            )}
                        <ReactHookFormInput
                            autoComplete="off"
                            control={control}
                            disabled={isFetchingGeocodePoint}
                            name="description"
                            onBlur={() => {
                                setTimeout(() => {
                                    setShowGeocodeResults(false);
                                }, 150);
                            }}
                            onChange={({ target }) => {
                                if (target.value.length >= 6)
                                    handleGeocode(target.value);
                                else setEmptyGeocodeResult();
                                setValue('description', target.value);
                            }}
                            onFocus={() => setShowGeocodeResults(true)}
                            placeholder={t('Location')}
                        />
                    </Box>
                </Grid>
            </Grid>

            <Grid container>
                <Grid size={{ xs: 'grow' }}>
                    <ReactHookFormInput
                        control={control}
                        fullWidth
                        id="business_general_lat_lng"
                        label={`${t('Latitude')}, ${t('Longitude')} (Ej: 19.43290, -99.1332)`}
                        name="latlng"
                        onChange={(e) => {
                            handleOnChangeLatLng({
                                setValue,
                                value: e.target.value,
                            });
                        }}
                    />
                </Grid>

                <Grid>
                    <IconButton
                        disabled={!isGeolocationEnabled}
                        onClick={() => {
                            if (!isUndefined(coords) && !isNull(coords))
                                updateLocation({
                                    latitude: coords.latitude,
                                    longitude: coords.longitude,
                                });
                            else getPosition();
                        }}
                        sx={{ mb: 2 }}
                    >
                        <NearMeIcon />
                    </IconButton>
                </Grid>
            </Grid>

            <Grid container>
                <Grid size={{ xs: 'grow' }}>
                    <Controller
                        control={control}
                        name="position"
                        render={({ field: { onChange, value } }) => (
                            <ControlledMap
                                center={accountCountryPositionLatLng}
                                disableDefaultUI={false}
                                heightMap="206px"
                                id="business_general_address"
                                onChange={onChange}
                                readOnly={false}
                                title={t('Location')}
                                value={value}
                                zoom={DEFAULT_MAP_PREVIEW_ZOOM}
                            />
                        )}
                    />
                </Grid>
            </Grid>

            <MapWarning visible={isVisibleMapWarning()} />
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    accountCountryPositionLatLng:
        companyGeneralselectors.getAccountCountryPositionLatLng,
    geocodeResult: companyGeneralselectors.getFetchGeocodeData,
    geocodePointData: companyGeneralselectors.getGeocodePointData,
    initialValues: selectors.getInitialValues,
    isFetchingGeocodePoint: companyGeneralselectors.getIsFetchingGeocodePoint,
    showGeocodeResults: companyGeneralselectors.showGeocodeResults,
});

const mapDispatchToProps = (dispatch) => ({
    fetchGeocodePoint: (lat, lng) =>
        dispatch(companyGeneralActions.fetchGeocodePoint(lat, lng)),
    handleGeocode: (str) =>
        dispatch(companyGeneralActions.fetchGeocodeResult(str)),
    setEmptyGeocodeResult: () =>
        dispatch(companyGeneralActions.setEmptyGeocodeResult()),
    setShowGeocodeResults: (value) =>
        dispatch(companyGeneralActions.setShowGeocodeResults(value)),
});

Location.propTypes = {
    accountCountryPositionLatLng: PropTypes.object,
    control: PropTypes.object,
    fetchGeocodePoint: PropTypes.func,
    formValues: PropTypes.object,
    geocodePointData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    geocodeResult: PropTypes.array,
    handleGeocode: PropTypes.func,
    initialValues: PropTypes.object,
    isFetchingGeocodePoint: PropTypes.bool,
    setEmptyGeocodeResult: PropTypes.func,
    setShowGeocodeResults: PropTypes.func,
    setValue: PropTypes.func,
    showGeocodeResults: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(Location);
