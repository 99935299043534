import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Divider, Grid } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import {
    PriceFields,
    ProductHeaderPreview,
    WarningEdition,
} from 'common/components/productForm';
import ShowErrors from 'common/components/ShowErrors';
import { COMPANY_PRODUCTS } from 'common/constants/index';
import BranchOfficeSelectTypeRHF from 'common/modules/multiBranches/components/BranchOfficeSelectTypeRHF';
import productDatasheets from 'common/modules/productDatasheets';
import { getCurrenciesToSelect } from 'common/utils/helpers';
import yupResolver from 'common/utils/yupResolver';

import PanelPlaceholder from 'resources/placeholders/panelPlaceholder.svg';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

const ProductForm = ({
    brandsToSelect,
    canDelete,
    canModify,
    currencies,
    errors,
    filterData,
    handleClickBulkItems,
    handleClickSave,
    handleCloseModalProduct,
    initializeForm,
    initialValues,
    isNewRecord,
    isSaving,
}) => {
    const { t } = useTranslation();
    const { control, getValues, handleSubmit, reset, setValue, watch } =
        useForm({
            defaultValues: initialValues,
            resolver: yupResolver(validate),
        });
    const [description, disabledForm, imageThumbnail, name, statusFlag] = watch(
        ['description', 'disabled', 'image_thumbnail', 'name', 'status_flag'],
    );
    useEffect(() => reset(initialValues), [initialValues]);

    const handleOnSave = (values) => handleClickSave(filterData, values);

    const isArchived = statusFlag === 'archived';
    const isDisabled = !canModify || disabledForm;

    return (
        <>
            {disabledForm && (
                <WarningEdition
                    handleClickEdit={() => setValue('disabled', false)}
                    show={canModify}
                />
            )}
            <ProductHeaderPreview
                description={description}
                name={name}
                placeholder={PanelPlaceholder}
                productThumbnail={imageThumbnail}
            />
            <productDatasheets.Container
                disabled={isDisabled}
                productValues={{
                    ...initialValues,
                    productType: COMPANY_PRODUCTS.PANELS,
                }}
            />
            <form>
                <ReactHookFormSelect
                    control={control}
                    disabled
                    label={t('Brand')}
                    name="brand"
                    options={brandsToSelect}
                />

                <ReactHookFormSelect
                    control={control}
                    disabled
                    label={t('Type')}
                    name="product_type"
                    options={[
                        {
                            label: t('Select a type'),
                            value: '',
                            disabled: true,
                        },
                        { label: t('Monocrystalline'), value: 0 },
                        { label: t('Polycrystalline'), value: 1 },
                        { label: t('Thin Film'), value: 2 },
                    ]}
                />

                <ReactHookFormInput
                    control={control}
                    disabled={isDisabled}
                    label={t('Name')}
                    name="name"
                    placeholder={t('Name')}
                />

                <ReactHookFormInput
                    control={control}
                    disabled={isDisabled}
                    name="description"
                    label={t('Description')}
                    placeholder={t('Description')}
                />

                <ReactHookFormInput
                    control={control}
                    disabled={isDisabled}
                    label={t('Product code')}
                    name="code"
                    placeholder={t('Product code')}
                />

                <ReactHookFormSelect
                    control={control}
                    disabled={isDisabled}
                    label={t('Currency')}
                    name="currency"
                    options={getCurrenciesToSelect(currencies, false)}
                />

                <BranchOfficeSelectTypeRHF
                    control={control}
                    disabled
                    label={t('Branch office')}
                    name="branch_offices"
                />

                <Divider />

                <PriceFields
                    canModify={canModify}
                    control={control}
                    currencies={currencies}
                    disabled={isDisabled}
                    getValues={getValues}
                    setValue={setValue}
                />

                <ShowErrors errors={errors} />

                <Grid container mt={1}>
                    <Grid
                        size={{ xs: 'grow' }}
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            gap: 2,
                            justifyContent: { xs: 'center', md: 'right' },
                        }}
                    >
                        {!isNewRecord && (
                            <>
                                <Button
                                    color="secondary"
                                    onClick={() =>
                                        handleClickBulkItems(
                                            [getValues('id')],
                                            'archived',
                                            filterData,
                                            () =>
                                                initializeForm({
                                                    ...getValues(),
                                                    status_flag: 'archived',
                                                }),
                                        )
                                    }
                                    sx={{
                                        '&.MuiButton-root': {
                                            mb: { xs: 'auto', md: 0 },
                                        },
                                        order: { xs: 2, md: 1 },
                                        width: { xs: '100%', md: 'auto' },
                                    }}
                                    type="button"
                                    variant="text"
                                    visible={canDelete && !isArchived}
                                >
                                    {t('Archive')}
                                </Button>
                                <Button
                                    color="secondary"
                                    onClick={() =>
                                        handleClickBulkItems(
                                            [getValues('id')],
                                            'active',
                                            filterData,
                                            () =>
                                                initializeForm({
                                                    ...getValues(),
                                                    status_flag: 'active',
                                                }),
                                        )
                                    }
                                    sx={{
                                        '&.MuiButton-root': {
                                            mb: { xs: 'auto', md: 0 },
                                        },
                                        order: { md: 1, xs: 2 },
                                        width: { xs: '100%', md: 'auto' },
                                    }}
                                    type="button"
                                    variant="text"
                                    visible={canDelete && isArchived}
                                >
                                    {t('Restore')}
                                </Button>
                                <Button
                                    color="error"
                                    onClick={() =>
                                        handleClickBulkItems(
                                            [getValues('id')],
                                            'deleted',
                                            filterData,
                                            () => handleCloseModalProduct(),
                                        )
                                    }
                                    sx={{
                                        '&.MuiButton-root': {
                                            mb: { xs: 'auto', md: 0 },
                                        },
                                        order: { md: 1, xs: 2 },
                                        width: { xs: '100%', md: 'auto' },
                                    }}
                                    type="button"
                                    variant="text"
                                    visible={canDelete && isArchived}
                                >
                                    {t('Delete')}
                                </Button>
                            </>
                        )}
                        <Button
                            disabled={isSaving}
                            onClick={handleSubmit(handleOnSave)}
                            sx={{
                                '&.MuiButton-root': {
                                    mb: { xs: 'auto', md: 0 },
                                },
                                order: { md: 2, xs: 1 },
                                width: { md: 'auto', xs: '100%' },
                            }}
                            type="button"
                            variant="outlined"
                            visible={canModify && !isArchived && !disabledForm}
                        >
                            {t('Save')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    brandsToSelect: selectors.getFetchBrandsToSelect,
    errors: selectors.getSaveItemErrors,
    initialValues: selectors.getInitialValues,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSavingItem,
    suppliersToSelect: selectors.getFetchSuppliersToSelect,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickSave: (filterData, values) =>
        dispatch(actions.save(filterData, values)),
});

ProductForm.propTypes = {
    brandsToSelect: PropTypes.array,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    currencies: PropTypes.array,
    errors: PropTypes.array,
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleClickSave: PropTypes.func,
    handleCloseModalProduct: PropTypes.func,
    initializeForm: PropTypes.func,
    initialValues: PropTypes.object,
    isNewRecord: PropTypes.bool,
    isSaving: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm);
