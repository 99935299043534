import get from 'lodash/get';

import * as profileSelectors from '../../profile/selectors';
import { getStepIndex } from '../helpers';

import setCurrentStep from './setCurrentStep';

export default (section) => (dispatch, getState) => {
    const subscription = profileSelectors.getActiveSubscription(getState());
    const productType = get(subscription, 'product_type', null);
    const isAftersales = productType === 'aftersales';
    const step = getStepIndex(isAftersales, section);
    dispatch(setCurrentStep(step));
};
