import React from 'react';

import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import { parseDate, formatDate } from 'common/utils/dates';

const CreditsTooltipContent = ({ subscription }) => {
    const { t } = useTranslation();
    const { credits, next_billing_at, product_type } = subscription || {};

    const getCreditsData = () => {
        if (product_type === 'bundle') {
            const proposals = get(credits, 'proposals', {});
            const reports = get(credits, 'reports', {});
            return {
                proposalsAvailable:
                    get(proposals, 'available_credits_period', 0) +
                    get(proposals, 'available_prepaid_credits', 0),
                reportsAvailable:
                    get(reports, 'available_credits_period', 0) +
                    get(reports, 'available_prepaid_credits', 0),
                additionalProposals: get(
                    proposals,
                    'pending_payment_credits',
                    0,
                ),
                additionalReports: get(reports, 'pending_payment_credits', 0),
            };
        }
        return {
            available:
                get(credits, 'available_credits_period', 0) +
                get(credits, 'available_prepaid_credits', 0),
            additional: get(credits, 'pending_payment_credits', 0),
        };
    };

    const creditsData = getCreditsData();

    const renderContent = () => {
        switch (product_type) {
            case 'bundle':
                return (
                    <>
                        <Box alignItems="center" display="flex" gap={2} mb={1}>
                            <Typography variant="caption">
                                {t('Proposals available')}:{' '}
                                {creditsData.proposalsAvailable}
                            </Typography>
                        </Box>
                        <Box alignItems="center" display="flex" gap={2} mb={1}>
                            <Typography variant="caption">
                                {t('Available reports')}:{' '}
                                {creditsData.reportsAvailable}
                            </Typography>
                        </Box>
                        {creditsData.additionalProposals > 0 && (
                            <Box alignItems="center" display="flex" gap={2}>
                                <Typography variant="caption">
                                    {t('Additional proposals')}:{' '}
                                    {creditsData.additionalProposals}
                                </Typography>
                            </Box>
                        )}
                        {creditsData.additionalReports > 0 && (
                            <Box alignItems="center" display="flex" gap={2}>
                                <Typography variant="caption">
                                    {t('Additional reports')}:{' '}
                                    {creditsData.additionalReports}
                                </Typography>
                            </Box>
                        )}
                    </>
                );
            case 'aftersales':
                return (
                    <>
                        <Box alignItems="center" display="flex" gap={2} mb={1}>
                            <Typography variant="caption">
                                {t('Available reports')}:{' '}
                                {creditsData.available}
                            </Typography>
                        </Box>
                        {creditsData.additional > 0 && (
                            <Box alignItems="center" display="flex" gap={2}>
                                <Typography variant="caption">
                                    {t('Additional', { count: 2 })}:{' '}
                                    {creditsData.additional}
                                </Typography>
                            </Box>
                        )}
                    </>
                );
            default:
                return (
                    <>
                        <Box alignItems="center" display="flex" gap={2} mb={1}>
                            <Typography variant="caption">
                                {t('Proposals available')}:{' '}
                                {creditsData.available}
                            </Typography>
                        </Box>
                        {creditsData.additional > 0 && (
                            <Box alignItems="center" display="flex" gap={2}>
                                <Typography variant="caption">
                                    {t('Additional', { count: 2 })}:{' '}
                                    {creditsData.additional}
                                </Typography>
                            </Box>
                        )}
                    </>
                );
        }
    };

    return (
        <Box flexDirection="column" my={1}>
            {renderContent()}
            <Box alignItems="center" display="flex" gap={2} mb={1}>
                <Typography variant="caption">
                    {t('Renewal date')}:{' '}
                    {formatDate(
                        parseDate(next_billing_at, 'dd/MM/yyyy HH:mm:ss'),
                        'dd/MM/yyyy',
                    )}
                </Typography>
            </Box>
        </Box>
    );
};

CreditsTooltipContent.propTypes = {
    subscription: PropTypes.object.isRequired,
};

export default CreditsTooltipContent;
