import { createSelector } from 'reselect';

import { NAME, RATE_TYPES } from './constants';
import { prepareRate } from './helpers';

export const getModel = (state) => state[NAME];

export const getInitialValuesRateConcepts = createSelector(
    getModel,
    (model) => model.initialValuesRateConcepts,
);

export const getInitialValuesRateTaxes = createSelector(
    getModel,
    (model) => model.initialValuesRateTaxes,
);

export const getIsRateFormModalOpen = createSelector(
    getModel,
    (model) => model.isRateFormModalOpen,
);

export const getRateConcepts = createSelector(
    getModel,
    (model) => model.rateConcepts,
);

export const getRateConceptsData = createSelector(
    getRateConcepts,
    (rateConcepts) => rateConcepts.data,
);

export const getRateComplements = createSelector(
    getRateConceptsData,
    (data) => data?.complements_offer,
);

export const getRateConceptsFormattedData = createSelector(
    getRateConceptsData,
    (data) => {
        const formattedData = {
            has_extra_concepts_alignment: data?.has_extra_concepts_alignment,
        };

        if (data?.current_rate)
            formattedData.current_rate = prepareRate(data.current_rate);

        if (data?.next_rate)
            formattedData.next_rate = prepareRate(
                data.next_rate,
                RATE_TYPES.NEXT,
            );

        return formattedData;
    },
);

export const getRateConceptsErrors = createSelector(
    getRateConcepts,
    (rateConcepts) => rateConcepts.errors,
);

export const getRateConceptsLoading = createSelector(
    getRateConcepts,
    (rateConcepts) => rateConcepts.loading,
);

export const getSaveRateConcepts = createSelector(
    getModel,
    (model) => model.saveRateConcepts,
);

export const getSaveRateConceptsData = createSelector(
    getSaveRateConcepts,
    (saveRateConcepts) => saveRateConcepts.data,
);

export const getSaveRateConceptsErrors = createSelector(
    getSaveRateConcepts,
    (saveRateConcepts) => saveRateConcepts.errors,
);

export const getSaveRateConceptsLoading = createSelector(
    getSaveRateConcepts,
    (saveRateConcepts) => saveRateConcepts.loading,
);

export const getSaveRateTaxes = createSelector(
    getModel,
    (model) => model.saveRateTaxes,
);

export const getSaveRateTaxesData = createSelector(
    getSaveRateTaxes,
    (saveRateTaxes) => saveRateTaxes.data,
);

export const getSaveRateTaxesErrors = createSelector(
    getSaveRateTaxes,
    (saveRateTaxes) => saveRateTaxes.errors,
);

export const getSaveRateTaxesLoading = createSelector(
    getSaveRateTaxes,
    (saveRateTaxes) => saveRateTaxes.loading,
);

export const getResetRateConcepts = createSelector(
    getModel,
    (model) => model.resetRateConcepts,
);

export const getResetRateConceptsErrors = createSelector(
    getResetRateConcepts,
    (resetRateConcepts) => resetRateConcepts.errors,
);

export const getResetRateConceptsLoading = createSelector(
    getResetRateConcepts,
    (resetRateConcepts) => resetRateConcepts.loading,
);
