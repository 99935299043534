import React from 'react';

import ReorderIcon from '@mui/icons-material/Reorder';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { Card, Grid, IconButton, Typography } from 'sunwise-ui';

import { PROPOSAL_SUMMARY_TYPE } from '../constants';

import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import ToggleSwitch from './ToggleSwitch';

const SorteableItem = ({
    companyCatalog,
    index,
    prepareDelete,
    prepareUpdate,
    showToggleSwitch,
    type,
    updateActiveStatus,
    updatingActiveStatusId,
}) => {
    const isDisabled = companyCatalog.type === PROPOSAL_SUMMARY_TYPE;
    return (
        <Draggable
            draggableId={companyCatalog.id}
            index={index}
            isDragDisabled={isDisabled}
        >
            {(provided) => (
                <Card
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <Card.Body>
                        <Grid container spacing={0}>
                            <Grid
                                alignItems="center"
                                display="flex"
                                gap="8px"
                                size={{ md: 15, xs: 14 }}
                            >
                                <IconButton disabled={isDisabled}>
                                    <ReorderIcon />
                                </IconButton>
                                <Typography variant="subtitle">
                                    {companyCatalog.name}
                                </Typography>
                            </Grid>

                            <Grid
                                alignItems="center"
                                display="flex"
                                gap="5px"
                                size={{ md: 3, xs: 4 }}
                                sx={{ ml: 'auto' }}
                            >
                                <EditButton
                                    companyCatalog={companyCatalog}
                                    prepareUpdate={prepareUpdate}
                                    type={type}
                                />
                                <ToggleSwitch
                                    companyCatalog={companyCatalog}
                                    showToggleSwitch={showToggleSwitch}
                                    updateActiveStatus={updateActiveStatus}
                                    updatingActiveStatusId={
                                        updatingActiveStatusId
                                    }
                                />
                                <DeleteButton
                                    companyCatalog={companyCatalog}
                                    prepareDelete={prepareDelete}
                                    showToggleSwitch={showToggleSwitch}
                                    type={type}
                                />
                            </Grid>
                        </Grid>
                    </Card.Body>
                </Card>
            )}
        </Draggable>
    );
};

SorteableItem.propTypes = {
    companyCatalog: PropTypes.object,
    index: PropTypes.number,
    prepareDelete: PropTypes.func,
    prepareUpdate: PropTypes.func,
    showToggleSwitch: PropTypes.bool,
    type: PropTypes.number,
    updateActiveStatus: PropTypes.func,
    updatingActiveStatusId: PropTypes.string,
};

export default SorteableItem;
